export class QrefBnkdelModel {
    vkey: string;
    vkeydel: string;
    pzn: string;
    uer: string;
    rgn: string;
    ind: string;
    tnp: string;
    nnp: string;
    adr: string;
    rkc: string;
    newnum: string;
    newks: string;
    namen: string;
    namep: string;
    permfo: string;
    srok: string;
    at1: string;
    at2: string;
    telef: string;
    regn: string;
    okpo: string;
    datedel: "2023-08-08T07:36:06.521Z";
    dtIzm: "2023-08-08T07:36:06.523Z";
    cks: string;
    ksnp: string;
    dateIn: "2023-08-08T07:36:06.523Z";

    constructor() {}
}
