import {AfterViewInit, Component, ElementRef, forwardRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from "@angular/core";
import {AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators} from "@angular/forms";
import {StringDateAdapterService, ValidatorService} from "@amlCore/services";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct";
import {NgbCalendar, NgbInputDatepicker} from "@ng-bootstrap/ng-bootstrap";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import {TypeNameSize, ValidatorParamsModel} from "@amlCore/models";
import {IFocus} from "@amlCore/interfaces";

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatepickerComponent),
    multi: true,
  }]
})
export class DatepickerComponent implements ControlValueAccessor, OnChanges, OnInit, AfterViewInit, IFocus {

  @Input() placeholder = "ДД.ММ.ГГГГ";
  @Input() size: TypeNameSize = "sm";
  @Input() allowInvalid = false;
  @Input() allowZero = false;
  @Input() customSeparator = '.';
  @ViewChild('dateInput') dateInput: ElementRef;
  @ViewChild('d') ngbDatepicker: NgbInputDatepicker;
  // Минимальная дата для ngbDatepicker
  @Input() minDate: NgbDateStruct = {year: 1900, month: 1, day: 1} as NgbDateStruct;
  // Максимальная дата для ngbDatepicker
  @Input() maxDate: NgbDateStruct;
  // Дата должна быть меньше текущей
  @Input() beforeToday: false;
  // Дата не может быть меньше текущей
  @Input() notBeforeToday: false;
  // Сравнивать с учётом времени
  @Input() compareWithTime: false;
  //Обязательое поле
  @Input() required = false;
  @Input() submitted = false;
  isDisabled = false;
  icons = {
    calendar: faCalendarAlt
  };

  /*сегодняшняя дата*/
  public today = this.calendar.getToday();
  /**
   * Форма для ввода данные с помощь инпута
   * FormGroup необходима для получения свойст валидности поля
   *  date - поле ипут с валидатором
   *  modelNgb - модель для выбора даты через иконку
   */
  form: FormGroup;
  onChange = (result: any) => {};
  onTouch = () => {};

  constructor(private dateAdapterSrv: StringDateAdapterService,
              private  formBuilder: FormBuilder,
              private validationSrv: ValidatorService,
              private calendar: NgbCalendar) {
  }


  ngOnInit(): void {
    if(this.beforeToday)
      this.maxDate = this.today;

    if(this.notBeforeToday){
      this.minDate = this.today;
    }
    
    this.form = this.formBuilder.group({
      date: [null, this.validationSrv.getValidation(this.getValidators())],
      modelNgb: null
    });

    if (this.isDisabled) {
      this.form.get("date").disable();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (typeof changes?.required?.currentValue === 'boolean') {
      this.form?.get('date').setValidators(changes?.required?.currentValue ? Validators.required : null);
      this.form?.get('date').updateValueAndValidity({ onlySelf: true });
    }
    if (changes?.maxDate?.currentValue !== changes?.maxDate?.previousValue) {
      this.form?.get('date').setValidators(this.validationSrv.getValidation(this.getValidators()));
      this.form?.get('date').updateValueAndValidity({ onlySelf: true });
    }

    if (changes?.minDate?.currentValue !== changes?.minDate?.previousValue) {
      this.form?.get('date').setValidators(this.validationSrv.getValidation(this.getValidators()));
      this.form?.get('date').updateValueAndValidity({ onlySelf: true });
    }
  }

  ngAfterViewInit() {
    const previousToggle = this.ngbDatepicker.toggle;
    this.ngbDatepicker.toggle = () => {
      previousToggle.bind(this.ngbDatepicker)();
      if (this.ngbDatepicker.isOpen()) {
        this.swapTitles();
      }
    };
  }

  //локализация всплывающих подсказок навигатора календаря, они не локализуются
  // вместе с остальными элементами Datepicker (https://github.com/ng-bootstrap/ng-bootstrap/issues/2977)
  swapTitles(): void {
    // @ts-ignore
    const selectMonth = this.ngbDatepicker._cRef.location.nativeElement.querySelector('select[title="Select month"]');
    selectMonth?.setAttribute('title', 'Выберите месяц');
    selectMonth?.setAttribute('aria-label', 'Выберите месяц');

    // @ts-ignore
    const selectYear = this.ngbDatepicker._cRef.location.nativeElement.querySelector('select[title="Select year"]');
    selectYear?.setAttribute('title', 'Выберите год');
    selectYear?.setAttribute('aria-label', 'Выберите год');

    // @ts-ignore
    const prevMonth = this.ngbDatepicker._cRef.location.nativeElement.querySelector('button[title="Previous month"]');
    prevMonth?.setAttribute('title', 'Предыдущий месяц');
    prevMonth?.setAttribute('aria-label', 'Предыдущий месяц');

    // @ts-ignore
    const nextMonth = this.ngbDatepicker._cRef.location.nativeElement.querySelector('button[title="Next month"]');
    nextMonth?.setAttribute('title', 'Следующий месяц');
    nextMonth?.setAttribute('aria-label', 'Следующий месяц');
  }

  validate(control: AbstractControl) {
    return (this.form.disabled || this.form.valid) ? null : { date: { valid: false } };
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /**
   * Получаем переменную из родительского компонента и преобразуем в вид который нам надо
   */
  writeValue(outsideValue: string) {
    // Получить из Forms API
    let newModel: NgbDateStruct = null;
    if (outsideValue) {
      // проставляем модель для пикера
      newModel = this.dateAdapterSrv.fromModel(outsideValue);
      // проставляем в поле
      this.form.setValue({
        date: outsideValue,
        modelNgb: newModel
      });
    } else {
      this.form.setValue({
        date: null,
        modelNgb: null
      });
    }
  }

  /**
   * Меняем с помощью пикера
   */
  updateValue(insideValue: NgbDateStruct) {
    // Сообщаем html новую переменную для отображения
    const newModel = this.dateAdapterSrv.toModel(insideValue, this.customSeparator);
    this.form.patchValue({
      date: newModel,
      modelNgb: insideValue
    });
    // Сообщаем Forms API
    this.onChange(newModel);
  }
  /**
   * Ввод в поле руками
   * Проверяем строку на валидность и праставляем в модель
   */
  onDateInput() {
    const date = this.form.get('date');
    if(!date.valid && this.allowInvalid || this.allowZero && date.value === '0'){
      this.onChange(date.value);
      return;
    }

    // проставляем модель для пикера
    let modelNgb = null;
    if (date.valid && date.value) {
      modelNgb = this.dateAdapterSrv.fromModel(date.value);
    }
    this.form.patchValue({modelNgb});
    // Сообщаем Forms API
    this.onChange(this.dateAdapterSrv.toModel(modelNgb, this.customSeparator));
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (this.isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  focus(): void {
    this.dateInput.nativeElement.focus();
  }

  get submittedForDocument() {
    return this.customSeparator === '/' ? this.submitted : true;
  }
  
  getValidators() {
    const validators: ValidatorParamsModel = {
      dateRange: [this.minDate, this.maxDate]
    };
    validators[this.allowZero ? 'isDateOrZero' : 'isDate'] = true;
    if (this.required)
      validators.isReq = true;
    return validators
  }
}
