import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'input-component',
  template: `
    <input [disabled]="disabled" [type]="type" [class]="class" [value]="value || ''">`
})
export class InputComponent {
  @Input() value: string;
  @Input() class: string;
  @Input() type: string;
  @Input() disabled: boolean;

}
