import {Component, OnInit, Input, forwardRef, OnDestroy} from '@angular/core';
import {faClock} from "@fortawesome/free-solid-svg-icons/faClock";
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Unsubscribable} from "rxjs";
import {ValidatorService} from "@amlCore/services";
import {TypeNameSize} from "../../models/typeNameSize.model";
import {CustomValidator} from "@amlCore/utils";

@Component({
  selector: "app-datetimepicker",
  templateUrl: "./datetimepicker.component.html",
  styleUrls: ["./datetimepicker.component.css"],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatetimepickerComponent),
    multi: true,
  },{
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => DatetimepickerComponent),
    multi: true,
  }]
})

export class DatetimepickerComponent implements ControlValueAccessor, OnInit, OnDestroy {

  @Input() dateFormat: DateTimeFormat = "DD.MM.YYYY HH:MM:SS";
  @Input() size: TypeNameSize = "sm";
  @Input() autocompleteTime: string; // признак автоматического заполнения времени значениeм
  @Input() isTimeFrom;
  // Дата должна быть меньше текущей
  @Input() beforeToday: false;
  // Дата не может быть меньше текущей
  @Input() notBeforeToday: false;
  // Сравнивать с учётом времени
  @Input() compareWithTime: false;
  placeholder = "ЧЧ:ММ:CC";
  mask = "Hh:m0:s0";
  formValueChanges: Unsubscribable;
  dateValueChanges: Unsubscribable;
  form: FormGroup;
  isDisabled = false;
  showError = false;
  icons = {
    time: faClock
  };
  onChange = (result: any) => {
  }
  onTouch = () => {
  }

  constructor(private  formBuilder: FormBuilder, private validationSrv: ValidatorService) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      date: null,
      time: [null, this.validationSrv.getValidation({
        isTime: this.dateFormat
      })]
    });
    if((this.beforeToday || this.notBeforeToday) && this.compareWithTime){
	   const beforeMoment = this.beforeToday ? true : false;
       this.form.setValidators(CustomValidator.dateTimeRange('date', 'time', beforeMoment));
    }
    switch (this.dateFormat) {
      case "DD.MM.YYYY HH:MM":
        this.placeholder = "ЧЧ:ММ";
        this.mask = "Hh:m0";
        break;
      case "DD.MM.YYYY HH:MM:SS":
        this.placeholder = "ЧЧ:ММ:CC";
        this.mask = "Hh:m0:s0";
        break;
    }

    this.formValueChanges = this.form.valueChanges.subscribe((data) => {
      // сообщаем forms API
      this.updateValue(data);
    });
    this.dateValueChanges = this.form.get('date').valueChanges.subscribe((data) => {
      if (!data) {
        this.form.get('time').setValue(null);
        this.form.get('time').disable();
      } else {
        this.form.get('time').enable();
        // если заполнено значение дата но значение времени не заполнено то выставляем значение для времени из autocompleteTime параметра
        if (this.form.get('time').value === null && this.autocompleteTime) {
          this.form.get('time').setValue(this.autocompleteTime);
        }
      }
    });
  }

  public onBlurTime() {
    if (this.form.get('time').value === "" && this.autocompleteTime) {
      this.form.get('time').setValue(this.autocompleteTime);
    }
  }

  public validate() {
    return this.form.valid ? null : { date: { valid: false } };
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /**
   * Получаем переменную из родительского компонента и преобразуем в вид который нам надо
   */
  writeValue(outsideValue: string) {
    let date = null;
    let time = null;
    // Получить из Forms API
    if (outsideValue) {
      const dateTime = outsideValue.split(' ');
      if (dateTime.length === 2) {
        date = dateTime[0];
        time = dateTime[1];
      } else {
        // не правильный формат
        this.formatError();
      }
    }
    this.form.setValue({date, time}, {emitEvent: false});
  }

  /**
   * Сообщаем Forms API
   */
  updateValue(insideValue: DateTimeType) {
    this.showError = false;
    const timeField = this.form.get('time');
    let newModel = null;
    const date = insideValue.date;
    const time = insideValue.time;
    if ((date && time && timeField.valid) || this.isDisabled) {
      newModel = `${date} ${time}`;
    }
    this.onChange(newModel);
  }

  formatError() {
    this.showError = true;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
      if(!this.form.get('date').value)
        this.form.get('time').disable();
    }
  }

  ngOnDestroy(): void {
    this.dateValueChanges.unsubscribe();
    this.formValueChanges.unsubscribe();
  }
}

/**
 * Модель поля дата и время
 */
export class DateTimeType {
  date: string;
  time: string;
}

/**
 * Доступные форматы
 * при расширении не забыть добавить в CustomValidator
 */
export type DateTimeFormat = 'DD.MM.YYYY HH:MM:SS' | 'DD.MM.YYYY HH:MM';
