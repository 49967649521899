import {NgModule} from '@angular/core';

import {AuthComponent} from './auth.component';
import {NoAuthGuard} from './no-auth-guard.service';
import {AuthRoutingModule} from './auth-routing.module';
import {CoreModule} from "../core";
import { ChangePasswordComponent } from './change-password/change-password.component';
import {NgxMaskModule} from "ngx-mask";

@NgModule({
  imports: [
    CoreModule,
    AuthRoutingModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    AuthComponent,
    ChangePasswordComponent
  ],
  providers: [
    NoAuthGuard
  ]
})
export class AuthModule {
}
