export class QrefSwiftModel {
  id: number;
  kokcm: string;
  alfa2: string;
  alfa3: string;
  ncmShort: string;
  ncm: string;

  constructor() {
  }
}

