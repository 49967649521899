import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {DossierAccessEnum} from "@amlCore/enums";
import {DossierService} from "../../../services";
import {DossierRequest} from "../../../models/dossier";
import {mergeMap} from "rxjs/operators";
import {IVersionModel} from "../../../interfaces";

@Injectable()
export class DossierPageResolver implements Resolve<any> {
  constructor(private dossierService: DossierService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | any {
    const typePage: DossierAccessEnum = route.data.typePage;
    const dossierId: any = route.parent.params.id;
    this.dossierService.saveDossierId(dossierId);
    const pageId = this.dossierService.getPageId();
    this.dossierService.setPageId("");
    if (dossierId) {
      if (pageId) {
        // Получение версии досье по id вкладки
        return this.loadDossierFromPageId(dossierId, typePage, pageId);
      } else {
        // Если досье отображает историю - ищем версию вкладки
        if (this.dossierService.getIsShowHistory()) {
          return this.loadDossierFromHistory(dossierId, typePage);
        }
        return this.loadDossier(dossierId, typePage);
      }
    }
    return null;
  }

  /**
   * Загрузка досье по pageId
   * pageId - передается из вне компонента досье (предустановка в сервис)
   * - Получении списка всех версий досье
   * -
   * @param dossierId - id досье
   * @param typePage - тип влкдаки
   * @param pageId - id вкладки
   */
  loadDossierFromPageId(dossierId, typePage: DossierAccessEnum, pageId): Observable<any> | null {
    return this.dossierService.getVersionByPageId(pageId).pipe(
      mergeMap(data => {
        // Получение всех версий досье
        return this.dossierService.getDocumentVersionList(dossierId, 'full').pipe(
          mergeMap((versionList: Array<IVersionModel>) => {
            // Выбор последней версии в истории
            const list = versionList.filter(item => item.partName === typePage && item.versionFull <= data.version);
            if (list && list.length > 0) {
              const pageInfo = list[0];
              // Проверка версии - является ли актуальной
              const isActual = list[0].versionFull === versionList[0].versionFull;
              this.dossierService.setIsShowHistory(!isActual, data.version);
              // В режиме истории проставляем признак сохраненных данных
              // тк после выхода из истории, данные сбрасываются в исходное состояние
              this.dossierService.setAllSaveData(true);
              // Загружаем информацию по вкладке в выбранной версии
              return this.dossierService.getDossierByType({
                id: dossierId,
                partName: typePage,
                version: pageInfo.versionSummary
              } as DossierRequest);
            } else {
              return null;
            }
          }));
      }));
  }

  /**
   * Загузка досье по истории версии
   * - В компоненте выбирается версия всего досье
   * - Получение номера версии вкладки по номеру всего досье
   * - Загрузка данных
   * @param dossierId - id досье
   * @param typePage - тип вкладки
   */
  loadDossierFromHistory(dossierId, typePage: DossierAccessEnum): Observable<any> | null {
    /**
     * Получении истории версии всего досье по типу вкладки и выбранной версии (выбирается в сервисе historyVersion)
     * У каждой вкладки предзагружен список версий для каждой версии всего досье
     */
    const version = this.dossierService.getVersionPartByVersionDossier(typePage);
    // На тот момент истории данных по вкладке не было - возвращаем пустые данные
    if (version === 0) {
      return null;
    } else {
      // В режиме истории проставляем признак сохраненных данных
      // тк после выхода из истории, данные сбрасываются в исходное состояние
      this.dossierService.setAllSaveData(true);
      // Грузим часть досье по найденной версии всего досье
      return this.dossierService.getDossierByType({
        id: dossierId,
        partName: typePage,
        model: null,
        version
      } as DossierRequest);
    }
  }

  /**
   * Обычная загрузка досье
   * @param dossierId - id досье
   * @param typePage - тип вкладки
   */
  loadDossier(dossierId, typePage: DossierAccessEnum): Observable<any> | null {
    /**
     * Проверяем загржены ли уже данные вкладки
     */
    if (this.dossierService.getLoadedData(typePage)) {
      return null;
    }
    return this.dossierService.getDossierByType({
      id: dossierId,
      partName: typePage,
      model: null
    } as DossierRequest);
  }

}
