import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DossierService} from '../../../../../services';
import {DictionaryService} from '@amlCore/services';
import {AlertPanelService, ITableEditorItem, ITableItem} from '@amlCore/components';
import {DictType, SvedAUType} from '../../../../../models/dossier';
import {AbstractControl, FormGroup} from '@angular/forms';
import {DossierBaseModel} from '../../dossierBaseModel';
import {DossierBaseSubComponent} from '../dossierBaseSub.component';
import {ClientTypeEnum} from '@amlCore/enums';
import { ClientInfoDossierComponent } from '../clientInfo';
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'app-au-info-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './auInfoDossier.component.html'
})
export class AuInfoDossierComponent extends DossierBaseSubComponent implements OnInit, OnDestroy, ITableItem {
  @Input() params: { clientType: () => ClientTypeEnum };
  private readonly _titlePanel = 'Сведения об учредителях/участниках';
  private readonly _stateFounderValue = '1';
  clientType: ClientTypeEnum; // Тип клиента из SvedClient
  stateIsboULList: DictType[] = [];
  showTitle = true;
  showState = false; // Признак отображения поля Статус
  showPart = false; // Признак отображения поля Доля участия
  isTableItem = false;
  bfTypeList: DictType[] = [];
  clientTypeChange: number;
  destroy$ = new Subject();
  constructor(protected dossierService: DossierService,
              protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  /**
   * Описание формы
   */
  getFormDescription(): FormGroup {
    return this.dossierBaseModel.getSvedAu();
  }

  /**
   * Инициализация FormGroup
   */
  initForm(): void {
    if (!this.isTableItem) {
      this.formGroupApp = this.getFormDescription();
    }
  }

  ngOnInit(): void {
    this.focusObject = this.generateFocusObject(new SvedAUType());
    this.stateIsboULList = this.getStaticData('stateIsboUL');
    this.clientType = this.params.clientType();
    this._initPartField(this.f('state').value);
    this._initStateField();
    this.f('state').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((state: DictType) => {
      this._initPartField(state)
    });
    this.initBfType();
    this.dossierService.clientTypeEmit.subscribe((data) => {
      this.clientTypeChange = data;
      this.initBfOwner();
    });

    this.f('part').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
      this.initBfOwner();
    });
  }

  private _initStateField(): void {
    const stateControl = this.f('state') as AbstractControl;
    if (this.clientType === ClientTypeEnum.FOREIGN_STRUCTURE) {
      this.showState = true;
      if(!this.isReadOnly){
         this.enableControls(stateControl);
      }
    } else {
      this.disableControls(stateControl);
    }
  }

  private _initPartField(state: DictType): void {
    const partControl = this.f('part') as AbstractControl;
    if (this.clientType !== ClientTypeEnum.FOREIGN_STRUCTURE
        || this.clientType === ClientTypeEnum.FOREIGN_STRUCTURE
        && state
        && state.code === this._stateFounderValue) {
      this.showPart = true;
      if(!this.isReadOnly){
        this.enableControls(partControl);
      }
    } else {
      this.showPart = false;
      this.disableControls(partControl);
    }
  }

  getTitle(): string {
    return this._titlePanel;
  }

  /**
   * Инициализируем компонент как элемент формы просмотра таблицы
   * @param param.form - форма для связи
   * @param param.params - дополнительные параметры
   */
  initTableItem(param: ITableEditorItem): FormGroup {
    this.initForm();
    this.isTableItem = true;
    if (param.model) {
      this.formGroupApp.patchValue(param.model);
    }
    this.showTitle = false;
    if (param.params && param.params.typePage) {
      this.typePage = param.params.typePage;
    } else {
      console.log('Необходимо указать typePage у компонента');
    }
    this.isReadOnly = param.isReadOnly;
    if (this.isReadOnly) {
      this.formGroupApp.disable();
    }
    return this.formGroupApp;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Инициализация доступности признака и вида идентификации бенефициара
   */
   initBfType(): void {
    this.bfTypeList = this.dictSrv.getStaticData("bfTypeList");
    this.showBfType = true;
  }

  initBfOwner() {
    if (!this.formGroupApp.disabled) {
      if (this.clientTypeChange != 2 || this.f('part').value < 25) {
        this.f('bfOwner').disable();
        this.f('bfOther').disable();
      } else {
        this.f('bfOwner').enable();
        this.f('bfOther').enable();
      }
    }
  }
}
