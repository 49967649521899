import {Injectable} from '@angular/core';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {faMinus} from '@fortawesome/free-solid-svg-icons/faMinus';
import {faUserPlus} from '@fortawesome/free-solid-svg-icons/faUserPlus';
import {faUserCheck} from '@fortawesome/free-solid-svg-icons/faUserCheck';
import {faUser} from '@fortawesome/free-solid-svg-icons/faUser';
import {faFileImport} from '@fortawesome/free-solid-svg-icons/faFileImport';
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons/faPaperPlane';
import {faUserTag} from '@fortawesome/free-solid-svg-icons/faUserTag';
import {faSave} from '@fortawesome/free-solid-svg-icons/faSave';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {faPen} from '@fortawesome/free-solid-svg-icons/faPen';
import {faSearch} from '@fortawesome/free-solid-svg-icons/faSearch';
import {faFolderOpen} from '@fortawesome/free-solid-svg-icons/faFolderOpen';
import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import {faEllipsisV} from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import {faFlag} from "@fortawesome/free-solid-svg-icons/faFlag";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {faFile} from "@fortawesome/free-solid-svg-icons/faFile";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";
import {faExternalLinkSquareAlt} from "@fortawesome/free-solid-svg-icons/faExternalLinkSquareAlt";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {faPrint} from "@fortawesome/free-solid-svg-icons/faPrint";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons/faChevronUp";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown";
import {faCopy} from "@fortawesome/free-solid-svg-icons/faCopy";
import {faSortAmountDown} from "@fortawesome/free-solid-svg-icons/faSortAmountDown";
import {faSortAmountUp} from "@fortawesome/free-solid-svg-icons/faSortAmountUp";

@Injectable()
export class IconsService {
  private _faIcons = {
    plus: faPlus,
    minus: faMinus,
    addUser: faUserPlus,
    users: faUserTag,
    Agreements: faUserCheck,
    Operator: faUser,
    Senders: faPaperPlane,
    save: faSave,
    close: faTimes,
    edit: faPen,
    print: faPrint,
    search: faSearch,
    folderOpen: faFolderOpen,
    calendar: faCalendarAlt,
    ellips: faEllipsisV,
    flag: faFlag,
    check: faCheck,
    file: faFile,
    exclamationTriangle: faExclamationTriangle,
    spinner: faSpinner,
    download: faDownload,
    externalLink: faExternalLinkSquareAlt,
    mail: faEnvelope,
    trash: faTrash,
    chevronUp: faChevronUp,
    chevronDown: faChevronDown,
    copy: faCopy,
    importFile: faFileImport,
    sortUp: faSortAmountUp,
    sortDown: faSortAmountDown
  };
  private _svgIcons = {
    attachSign: '../../assets/svg/attachSign.svg',
    attachFile: '../../assets/svg/attach.svg',
  }


  public getIcons(): { [key: string]: IconDefinition } {
    return this._faIcons;
  }

  public getSvgIcons(): { [key: string]: string } {
    return this._svgIcons;
  }
}
