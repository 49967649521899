import {Injectable} from '@angular/core';
import {CryptAPI, CryptVersionValidate} from "../../core";
import {ReplaySubject} from "rxjs";
import {CryptValidata} from "../core";

@Injectable({providedIn: "root"})
export class CryptoValidataService {
    /**
     * Информация по криптографии
     */
    private cryptInfoSubject = new ReplaySubject<CryptVersionValidate>(1);
    public cryptInfo = this.cryptInfoSubject.asObservable();
    /**
     * Признак что криптография запустилась
     */
    private isCryptEnableSubject = new ReplaySubject<{ state: boolean, textError?: string }>(1);
    public isCryptEnable = this.isCryptEnableSubject.asObservable();
    private isInitService = false;
    crypt: CryptValidata;

    constructor() {
        this.crypt = new CryptValidata();
    }

    init() {
      if (this.isInitService) {
        return;
      }
      const _this = this;
      const errorFn = (error) => {
        _this.cryptInfoSubject.next({
          typeError: "error",
          textError: error,
          isPluginWorked: false,
          isPluginLoaded: false,
          isPluginEnabled: false
        } as CryptVersionValidate);
        _this.isCryptEnableSubject.next({state: false, textError: error});
      };
      _this.crypt.CheckForPlugIn().then((cvi: CryptVersionValidate) => {
        _this.cryptInfoSubject.next(cvi);
        _this.isCryptEnableSubject.next({state: true});
        _this.isInitService = true;

      }, errorFn);
    }
}
