import { Injectable } from '@angular/core';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client/dist/sockjs';
import {Subject} from "rxjs";
import {UserSessionService} from "./user-session.service";
import {JwtService} from "./jwt.service";
import {AlertService} from "../components/alert/alert.service";

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private socket = null
  private stompClient = null
  isDestroySession$ = new Subject<boolean>()
  private isClosed = false
  // Регулярное выражение для удаления данных о передаваемом заголовке авторизации
  private consolePattern = /Authorization:Bearer[^\n]*\n/;
  private defCountOfRestore = '5'
  constructor(private userSession: UserSessionService, 
              private jwtService: JwtService,
              private alertSrv: AlertService,) {
  }

  initConnection() {
    this.socket = new SockJS(`/api/v1/authad/websocket`);
    this.stompClient = Stomp.over(this.socket);
    // Переопределение стандартного метода для удаления ненужной информации в консоли разработчика
    this.stompClient.debug = (message: string) => {
      console.log(message.replace(this.consolePattern, ''))
    };
    this.stompClient.connect({Authorization: `Bearer ${this.jwtService.getToken()}`}, () => {
      this.userSession.updateCountOfRestore()
      const sessionId = this.userSession.getUserSession().sessionId
      this.stompClient.subscribe(`/user/${sessionId}/queue/authad/closeSession`, () => {
        this.isDestroySession$.next(true)
      });
    });
    this.socket.onclose = () => {
      if (Number(this.userSession.getCountOfRestore()) > 0 && !this.isClosed) {
        this.alertUserRestore()
        setTimeout(() => {
          this.userSession.decreaseCountOfRestore()
          this.initConnection()
        }, 15000)
      } else {
        this.isDestroySession$.next(true)
        setTimeout(() => {
          this.alertSrv.error('Не удалось восстановить потерянное соединение. Вы были перенаправлены на главную страницу',
              undefined, undefined, undefined)
        }, 2000)
        this.isClosed = false
      }
    }
  }
  
  closeConnection() {
    this.stompClient?.disconnect()
    this.isClosed = true
    this.socket = null
  }
  
  alertUserRestore() {
    const defNumberOfRestore = Number(this.defCountOfRestore)
    const numberOfRestore = defNumberOfRestore -
        Number(this.userSession.getCountOfRestore());
    this.alertSrv.error(
      `Соединение потеряно. Выполняется попытка восстановить соединение: ${numberOfRestore + 1} из ${defNumberOfRestore}...`,
      undefined, undefined, 14000
    )
  }
}
