import {Component, ContentChild, forwardRef, Input, Output, TemplateRef, EventEmitter} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ICustomSelectMapper} from "../customSelect";
import {BigSelectViewComponent, IBigSelectItem} from "./bigSelectView";
import {NgbModal, NgbModalConfig} from "@ng-bootstrap/ng-bootstrap";
import {IErrorFLK} from "../../interfaces";
import {ErrorFLKItemType, ErrorFLKModel} from "@amlCore/models";

/**
 *
 * *Работает только с локально загруженными справочникам
 */
@Component({
  selector: 'app-big-select',
  templateUrl: './bigSelect.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => BigSelectComponent),
    multi: true,
  }],
})
export class BigSelectComponent implements ControlValueAccessor, IErrorFLK {

  @Input() titlePanel;
  @Input() isReadOnly;
  @Input() isHideSelectInput = false;
  @Input() isShowDefaultBtn = true;
  @Input() btnLabel = '';
  @Output() closeModal = new EventEmitter<boolean>();
  @Output() openModalDialog = new EventEmitter<boolean>();
  @Input() getDescriptor: ICustomSelectMapper;
  @ContentChild('labelTemplate') labelTemplate: TemplateRef<any>;
  @ContentChild('optionTemplate') optionTemplate: TemplateRef<any>;
  @ContentChild('labelTemplateInWindow') labelTemplateInWindow: TemplateRef<any>;
  @ContentChild('optionTemplateInWindow') optionTemplateInWindow: TemplateRef<any>;
  // Ключи по котором искать в поиске
  @Input() fieldsFromSearch: string | Array<string>;
  @Input() needSearch = true;
  @Input() errorFLKData: ErrorFLKItemType;
  @Input() paginationSticky = true;
  public errorFLKList: Array<ErrorFLKModel> = [];

  model: any;
  // Справочник аналогично items из ng-select
  @Input() items: Array<any>;
  // Имя переменой из объека, которая выступает в качесве name
  @Input() name = 'name';
  // Имя переменой из объека, которая выступает в качесве code
  @Input() code = 'code';
  @Input() multiple = false;

  isDisabled = false;

  // Подготавливаем пустые функции, в которые потом акссесор внедрит свои реализвации с помощью registerOnChange и registerOnTouched
  onChange = (result: any) => {
  }
  onTouch = () => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  constructor(private modalService: NgbModal) {
  }

  /**
   * Получаем переменную из родительского компонента и преобразуем в вид который нам надо
   */
  writeValue(outsideValue: any): void {
    this.model = !this.multiple && Array.isArray(outsideValue)
      ? outsideValue[0]
      : outsideValue;
  }

  /**
   * Меняем руками и сообщаем Forms API об обновлении
   */
  updateValue(insideValue: any): void {
    // Сообщаем Forms API
    this.onChange(insideValue ? insideValue : null);
  }

  /**
   * Открыть окно редактирования/просмотра
   */
  open(): void {
    const modalRef = this.modalService.open(BigSelectViewComponent, this.initConfigModalWindow());
    const items = [];
    if (this.items) {
      this.items.forEach(item => {
        const newItem = {};
        Object.assign(newItem, item);
        items.push(newItem);
      });
    }

    // Передаем данные формы и компонента для создания
    modalRef.componentInstance.open({
      isReadOnly: this.isReadOnly,
      items,
      selected: this.model,
      paginationSticky: this.paginationSticky,
      titlePanel: this.titlePanel,
      name: this.name,
      code: this.code,
      getDescriptor: this.getDescriptor,
      labelTemplate: this.labelTemplate,
      optionTemplate: this.optionTemplate,
      labelTemplateInWindow: this.labelTemplateInWindow,
      optionTemplateInWindow: this.optionTemplateInWindow,
      isMultiple: this.multiple,
      fieldsFromSearch: this.fieldsFromSearch,
      needSearch: this.needSearch,
      errorFLKData: this.errorFLKData,
      errorFLKList: this.errorFLKList
    } as IBigSelectItem);
    this.openModalDialog.emit();
    // Получаем ответ от формы
    modalRef.result.then((result: IBigSelectItem) => {
      if (result) {
        this.model = !this.multiple ? result.selected[0] : result.selected;
        this.updateValue(result.selected);
        this.closeModal.emit(true);
      }
    });
  }

  /**
   * Конфигурирование окна редактирования элемента
   */
  initConfigModalWindow(): NgbModalConfig {
    return {
      backdrop: 'static',
      size: 'xl',
      scrollable: true
    } as NgbModalConfig;
  }

}


