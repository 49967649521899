import {Component, OnDestroy, OnInit} from '@angular/core';
import {TableService, VisitedService} from "@amlCore/services";
import {Subject} from 'rxjs';
import {takeUntil} from "rxjs/operators";
import {DictionariesService} from "../../../service/dictionaries.service";
import {QrefOkwedModel} from "../../../models/qrefOkwed.model";
import {TableController} from "@amlCore/tableController";

@Component({
  selector: 'app-dictionary-qrefokwed',
  templateUrl: './qrefOkwed.component.html'
})
export class QrefOkwedComponent extends TableController implements OnInit, OnDestroy {

  constructor(protected dictSrv: DictionariesService,
              protected visitedService: VisitedService,
              protected tableService: TableService
  ) {
    super(visitedService, tableService);
  }
  isShowVisited = false;
  isSetFocus = false;
  data: QrefOkwedModel[] = [];

  private destroy$ = new Subject<void>();

  ngOnInit() {
    this.loadDict();
  }

  loadDict() {
    const params = {
      number: this.page-1,
      size: this.pageSize
    }
    this.dictSrv.getQrefOkwedList(params).pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.data = data.content;
      this.total = data.totalSize;
    });
  }
  onPageChange(params): void {
    this.loadDict();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}


