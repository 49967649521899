import {BaseFilterModel} from "./baseFilter.model";


export class MvkFilter extends BaseFilterModel {
  subjectType: string = null;  // тип субъекта
  name = ''; // наименование организации
  sname = '';
  gname = '';
  pname = '';
  birthDate = null;// дата рождения
  inn = '';    // ИНН
  series = '';  // серия
  number = ''; // номер
  ogrn = '';   // ОГРН
  idSubject = ''; // Идентификатор записи
}
