import {AccessJson} from "@amlCore/models";

export class User {
  id: string;
  fullName: string; // ФИО
  loginName: string;
  password: string;
  lastSystemLogon: any;
  created: any;
  lastIpLogon: string;
  department: string;
  locked: boolean;
  position: string;
  phone?: string;
  email: string;
  userRole: AccessJson;
  validLicense: Boolean;
  userType: 0 | 1;
  constructor() {
  }
}

