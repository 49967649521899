import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ValidatorParamsModel} from "@amlCore/models";
import {ValidatorService} from "@amlCore/services";
import {Injectable} from "@angular/core";
import {CustomValidator, CustomValidatorError} from "@amlCore/utils";
import {ClientTypeEnum, DossierAccessEnum} from "@amlCore/enums";
import {TypeOrg} from "../../../models/dossier";


@Injectable()
export class DossierBaseModel {

  constructor(protected formBuilder: FormBuilder, protected validationSrv: ValidatorService) {
  }

  /**
   * Сведения об учредителях/участниках
   */
  getSvedAu(param?: ValidatorParamsModel): FormGroup {
    return this.formBuilder.group({
      state: null,
      part: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 8
      })],
      clientInfo: this.getClientInfo(DossierAccessEnum.SVEDAU),
      bfOwner: null,
      bfOther: null,
      $partId: null,
      $checkedFlk: null,
      dateFrom: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      dateEnd: null,
      stopPowers: 0
    });
  }

  /**
   * Сведения о выгодоприобретателе
   */
   getSvedVPR(param?: ValidatorParamsModel): FormGroup {
    return this.formBuilder.group({
      clientInfo: this.getClientInfo(DossierAccessEnum.SVEDVPR),
      $partId: null,
      $checkedFlk: null,
      vprProfit: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      vprOther: [null, this.validationSrv.getValidation({
        max: 100
      })],
      reason: [null, this.validationSrv.getValidation({
        max: 250
      })],
      dateFrom: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      dateEnd: null,
      stopPowers: 0
    });
  }

   /**
   * Сведения о бенефициарном владельце
   */
    getSvedBv(param?: ValidatorParamsModel): FormGroup {
      return this.formBuilder.group({
        clientInfo: this.getClientInfo(DossierAccessEnum.SVEDBV),
        bfOwner: null,
        bfOther: null,
        reason: null,
        $partId: null,
        $checkedFlk: null,
        dateFrom: [null, this.validationSrv.getValidation({
          isReq: true
        })],
        dateEnd: null,
        stopPowers: 0
      });
    }

  /**
   * Сведения об исполнительном органе управления
   */
  getSvedIUO(param?: ValidatorParamsModel): FormGroup {
    return this.formBuilder.group({
      type: this.getDictType({isReq: true}),
      kolOU: this.getDictType({isReq: true}),
      otherOU: [null, this.validationSrv.getValidation({
        max: 250,
        isReq: true
      })],
      post: [null, this.validationSrv.getValidation({
        max: 250
      })],
      dateResh: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      numberResh: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 100
      })],
      clientInfo: this.getClientInfo(DossierAccessEnum.SVEDIOU),
      bfOwner: null,
      bfOther: null,
      bfSign: 0,
      $partId: null,
      $checkedFlk: null,
      dateFrom: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      dateEnd:null,
      stopPowers: 0
    }, this.validationSrv.getValidation(param));
  }

  /**
   * Сведения о представителях
   */
  getSvedPredst(param?: ValidatorParamsModel): FormGroup {
    return this.formBuilder.group({
      clientInfo: this.getClientInfo(DossierAccessEnum.SVEDPREDST),
      authority: this.getAuthority(),
      $partId: null,
      $checkedFlk: null,
    });
  }

  /**
   * Сведения о лице
   */
  getClientInfo(typePage: DossierAccessEnum): FormGroup {
    const regInfoValidator = new RegInfoValidator();
    const rezidentRf = new FormControl(false, {
      validators: regInfoValidator.callValidator.bind(regInfoValidator)
    });
    return this.formBuilder.group({
      clientType: [ClientTypeEnum.LEGAL_ENTITY, this.validationSrv.getValidation({
        isReq: true
      } as ValidatorParamsModel)],
      filial:  [1, this.validationSrv.getValidation({
        isReq: true
      })],
      rezidentRf: null,
      clientId: null,
      version: null,
      rezidentUsa: typePage === DossierAccessEnum.SVEDCLIENT ? false : null,
      rezidentFS: typePage === DossierAccessEnum.SVEDCLIENT ? false : null,
      svedUL: this.getSvedULType(typePage, rezidentRf),
      svedFL: this.getSvedFLType(typePage),
      svedIP: this.getSvedIPType(typePage, rezidentRf),
      svedFLCP: this.getSvedFLCPType(typePage),
      svedInbUL: this.getSvedInbULType(typePage),
      $partId: null,
      $checkedFlk: null
    });
  }

  /**
   * Цели установления деловых отношений
   */
  getSvedTarget(param?: ValidatorParamsModel): FormGroup {
    return this.formBuilder.group({
      charractDo: this.getDictType({isReq: true}),
      svedOperW: null,
      svedOperM: null,
      svedOperQ: null,
      svedOperY: null,
      orderCountW: [null, this.validationSrv.getValidation({
        max: 20
      })],
      orderCountM: [null, this.validationSrv.getValidation({
        max: 20
      })],
      orderCountQ: [null, this.validationSrv.getValidation({
        max: 20
      })],
      orderCountY: [null, this.validationSrv.getValidation({
        max: 20
      })],
      svedCashW: null,
      svedCashM: null,
      svedCashQ: null,
      svedCashY: null,
      ved: false,
      svedDBO: false,
      services: null
    }, this.validationSrv.getValidation(param));
  }

  /**
   * Адрес
   */
  getAddressType(type: AddressModelType): FormGroup {
    let countryValue = null;
    if (type === 'req') {
      countryValue = {
        name: "РОССИЙСКАЯ ФЕДЕРАЦИЯ",
        code: "643"
      };
    }
    return this.formBuilder.group({
      country: [countryValue, this.validationSrv.getValidation({
        isReq: true
      })],
      postIndex: [null, this.validationSrv.getValidation({
        max: 30
      })],
      region: null,
      area: [null, this.validationSrv.getValidation({
        max: 250
      })],
      city: [null, this.validationSrv.getValidation({
        max: 250
      })],
      locality: [null, this.validationSrv.getValidation({
        max: 250
      })],
      street: [null, this.validationSrv.getValidation({
        max: 250
      })],
      house: [null, this.validationSrv.getValidation({
        max: 60
      })],
      building: [null, this.validationSrv.getValidation({
        max: 50
      })],
      apartment: [null, this.validationSrv.getValidation({
        max: 100
      })],
      box: [null, this.validationSrv.getValidation({
        max: 50
      })],
    }, {validators: addressTypeValidator});
  }

  /**
   * Данные о регистрации
   */
  getRegInfoType(typeOrg: TypeOrg, rezidentRf: FormControl, typePage?: DossierAccessEnum): FormGroup {
    const regInfoValidator = new RegInfoValidator(this.validationSrv, typeOrg, rezidentRf, typePage);
    return this.formBuilder.group({
      ogrn: [null, this.validationSrv.getValidation({
        max: 15,
        isOGRN: true
      })],
      dateReg: null,
      dateRegIn: null,
      regNum: [null, this.validationSrv.getValidation({
        max: 50
      })],
      nameRegOrg: [null, this.validationSrv.getValidation({
        max: 250,
        min: 3
      })],
      // Поля подраздела "Данные об аккредитации филиалов (представительств)/регистрации ММПО, МНПО (AML-333)
      // Номер записи (НЗА)/реестровый номер
      regNumSub: [null, this.validationSrv.getValidation({
        max: 50,
      })],
      // Дата внесения записи
      dateRec: [null, this.validationSrv.getValidation({
        isDate: true,
      })],
      // Наименование учредительного документа (договора/соглашения)
      docName: [null, this.validationSrv.getValidation({
        max: 500,
      })]
    }, {validator: regInfoValidator.validator.bind(regInfoValidator)});
  }

  /**
   * Данные о регистрации частная практика
   */
  getRegInfoCPType(param?: ValidatorParamsModel): FormGroup {
    return this.formBuilder.group({
      vid: this.getDictType({isReq: true}),
      regNum: [null, this.validationSrv.getValidation({
        max: 250
      })],
      dateReg: [null, this.validationSrv.getValidation({
        isDate: true,
      })],
      otherVid: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 50
      })],
    }, this.validationSrv.getValidation(param));
  }

  /**
   * Показатель справочника
   */
  getDictType(param?: ValidatorParamsModel) {
    return [null, this.validationSrv.getValidation(param)];
  }

  /**
   * Наименования организации
   */
  getOrgNameType(): FormGroup {
    return this.formBuilder.group({
      orgNameFullRu: [null, this.validationSrv.getValidation({
        max: 2000
      })],
      orgNameRu: [null, this.validationSrv.getValidation({
        max: 500
      })],
      orgNameFullIn: [null, this.validationSrv.getValidation({
        max: 2000
      })],
      orgNameIn: [null, this.validationSrv.getValidation({
        max: 2000
      })]
    }, {validator: orgNameTypeValidator});
  }

  /**
   * Сведения о юридическом лице
   */
  getSvedULType(typePage: DossierAccessEnum, rezidentRf?: FormControl): FormGroup {
    return this.formBuilder.group(
      {
        orgName: this.getOrgNameType(),
        opf: this.getDictType({isReq: true}),
        // Валидация задается в компоненте (динамическая)
        inn: null,
        // Валидация задается в компоненте (динамическая)
        kpp: null,
        okpo: [null, this.validationSrv.getValidation({
          max: 8,
          min: 8,
          isOKPO: true
        })],
        oktmo: [null, this.validationSrv.getValidation({
          max: 11,
          min: 8,
          isOKTMO: true
        })],
        okfs: this.getDictType(),
        okved: this.getOkvedType('ul', typePage),
        regInfo: this.getRegInfoType('ul', rezidentRf, typePage),
        licenseInfo: this.getlicenseInfo(),
        addressInfo: this.getAddressInfo(),
        contactInfo: this.getContactType()
      });
  }

  /**
   * Сведения о физическом лице
   */
  getSvedFLType(typePage: DossierAccessEnum): FormGroup {
    return this.formBuilder.group({
      fio: this.getFIOType(),
      personalData: this.getPersonalDataType('fl'),
      documentInfo: this.getDocumentInfoType(typePage),
      addressInfo: this.getAddressInfo(),
      contactInfo: this.getContactType(),
      publicInfo: this.getPublicInfoType()
    }, this.validationSrv.getValidation({
      isReq: true
    }));

  }

  getAddressInfo(param?: ValidatorParamsModel) {
    return this.formBuilder.group({
      addressReg: this.getAddressType('req'),
      addressReal: this.getAddressType('real'),
      addressPost: this.getAddressType('post'),
      realAsReg: true,
      postAsReg: true,
    }, this.validationSrv.getValidation(param));
  }

  /**
   * Сведения об индивидуальном предпринимателе
   */
  getSvedIPType(typePage: DossierAccessEnum, rezidentRf?: FormControl): FormGroup {
    return this.formBuilder.group({
      fio: this.getFIOType(),
      personalData: this.getPersonalDataType('ip'),
      documentInfo: this.getDocumentInfoType(typePage),
      okved: this.getOkvedType('ip', typePage),
      regInfo: this.getRegInfoType('ip', rezidentRf, typePage),
      licenseInfo: this.getlicenseInfo(),
      addressInfo: this.getAddressInfo(),
      contactInfo: this.getContactType(),
      publicInfo: this.getPublicInfoType()
    });
  }

  /**
   * Сведения о физическом лице частная практика
   */
  getSvedFLCPType(typePage: DossierAccessEnum): FormGroup {
    return this.formBuilder.group({
      fio: this.getFIOType(),
      personalData: this.getPersonalDataType('flcp'),
      documentInfo: this.getDocumentInfoType(typePage),
      okved: this.getOkvedType('flcp', typePage),
      regInfoCP: this.getRegInfoCPType(),
      addressInfo: this.getAddressInfo(),
      contactInfo: this.getContactType(),
      publicInfo: this.getPublicInfoType()
    });
  }

  /**
   * Сведения об ИНБОЮЛ
   */
  getSvedInbULType(typePage: DossierAccessEnum): FormGroup {
    return this.formBuilder.group({
      orgName: this.getOrgNameType(),
      opf: this.getDictType({isReq: true}),
      inn: null, // Валидация задается в компоненте (динамическая)
      kpp: null, // Валидация задается в компоненте (динамическая)
      okved: this.getOkvedType('inbul', typePage),
      regInfoInbUL: this.getRegInfoInbULType(),
      licenseInfo: this.getlicenseInfo(),
      addressInfo: this.getAddressInfo(),
      contactInfo: this.getContactType()
    });
  }

  /**
   * Сведения о праве осуществления деятельности, подлежащей лицензированию
   */
  getlicenseInfo(param?: ValidatorParamsModel) {
    return null;
  }

  /**
   * Данные о регистрации ИНБОЮЛ
   */
  getRegInfoInbULType(param?: ValidatorParamsModel): FormGroup {
    return this.formBuilder.group({
      opf: this.getDictType({isReq: true}),
      iof: [null, this.validationSrv.getValidation({
        max: 250,
      })],
      regNum: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      nalogCod: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      dateReg: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      nameRegOrg: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 250
      })],
    }, this.validationSrv.getValidation(param));
  }

  /**
   * Контакты
   */
  getContactType(): FormGroup {
    return this.formBuilder.group({
      phoneInfo: [],
      emailInfo: [],
      website: [null, this.validationSrv.getValidation({
        max: 100
      })]
    });
  }

  /**
   * Сведения о праве осуществления деятельности, подлежащей лицензированию
   */
  getLicenseType(param?: ValidatorParamsModel): FormGroup {
    return this.formBuilder.group({
      type: [null, this.validationSrv.getValidation({
        max: 2000,
        isReq: true,
        min: 3
      })],
      number: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 250
      })],
      dateVid: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      dateStart: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      unlimited: false,
      dateEnd: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      action: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 2000,
        min: 3
      })],
      row: null
    }, this.validationSrv.getValidation(param));
  }

  /**
   * Фамилия, имя, отчество
   */
  getFIOType(): FormGroup {
    return this.formBuilder.group({
      lastName: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 100
      })],
      firstName: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 100
      })],
      middleName: [null, this.validationSrv.getValidation({
        max: 100
      })]
    });
  }

  /**
   * Личные данные
   */
  getPersonalDataType(typeOrg: TypeOrg): FormGroup {
    const innValidator: ValidatorParamsModel = {
      max: 12,
      min: 12,
      isINN: true
    };
    // Инн обязателен для ИП
    innValidator.isReq = typeOrg === 'ip';
    return this.formBuilder.group({
      inn: [null, this.validationSrv.getValidation(innValidator)],
      snils: [null, this.validationSrv.getValidation({
        max: 14,
        isSNILS: true
      })],
      policyOMC: [null, this.validationSrv.getValidation({
        max: 16,
        min: 16,
        isPolicyOMC: true
      })],
      country: [{
        name: "РОССИЙСКАЯ ФЕДЕРАЦИЯ",
        code: "643"
      }, this.validationSrv.getValidation({
        isReq: true
      })],
      birthDate: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      birthPlace: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 2000
      })],
    });
  }

  /**
   * Сведения о документе, удостоверяющем личность
   */
  getDocumentInfoType(typePage: DossierAccessEnum): FormGroup {
    const kodPodrValidator: KodPodrValidator = new KodPodrValidator(this.validationSrv);
    return this.formBuilder.group({
      nationality: this.getDictType({isReq: true}),
      docType: this.getDictType({isReq: true}),
      docOther: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      series: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      number: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      organ: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      dateDoc: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      kodPodr: [null, this.validationSrv.getValidation({
        max: 7
      })],
      migrationCardInfo: this.getMigrationCardInfoType(),
      residenceDocInfo: this.getResidenceDocInfoType(typePage)
    }, {validators: [docOtherDocumentInfoValidator, seriesDocumentInfoValidator, numberDocumentInfoValidator, migrationCardInfoValidator.bind(this,typePage), kodPodrValidator.validator.bind(kodPodrValidator)]});
  }

  /**
   * Виды экономической деятельности
   */
  getOkvedType(typeOrg: TypeOrg, typePage: DossierAccessEnum): FormGroup {
    const okvedValidator: OkvedValidator = new OkvedValidator(typeOrg, typePage);
    return this.formBuilder.group({
      okved: [],
      okved2: [],
      okvedAdd: [],
      okved2Add: []
    }, {validator:  okvedValidator.validator.bind(okvedValidator)});
  }

  /**
   * Принадлежность к публичным должностным лицам.
   */
  getPublicInfoType(param?: ValidatorParamsModel): FormGroup {
    return this.formBuilder.group({
      prv: false,
      characterPDL: this.getDictType({isReq: true}),
      rodPDL: this.getDictType({isReq: true}),
      fio: this.getFIOType(),
      postRPDL: this.getDictType({isReq: true}),
      postOther: null,
      post: [null, this.validationSrv.getValidation({isReq: true})],
      nameWork: [null, this.validationSrv.getValidation({isReq: true})],
      address: this.getAddressType('req'),
      istDochod: [],
      otherDochod: null

    }, {validators: publicInfoValidator});
  }

  /**
   * Данные миграционной карты
   */
  getMigrationCardInfoType(): FormGroup {
    return this.formBuilder.group({
      series: [null, this.validationSrv.getValidation({
        max: 50
      })],
      number: [null, this.validationSrv.getValidation({
        max: 50
      })],
      dateStart: null,
      dateEnd: null
    });
  }

  /**
   * Сведения о документе, подтверждающем право на пребывание (проживание) в РФ
   */
  getResidenceDocInfoType(typePage: DossierAccessEnum): FormGroup {
    //  Обязательность полей по типу вкладки
    const isFieldsReq = ![DossierAccessEnum.SVEDAU,
      DossierAccessEnum.SVEDUDS,
      DossierAccessEnum.SVEDVPR,
      DossierAccessEnum.SVEDBV,
      DossierAccessEnum.PARTNER].includes(typePage);
    if (isFieldsReq){
      return this.formBuilder.group({
        docType: [null, this.validationSrv.getValidation({
          isReq: true
        })],
        docOther: [null, this.validationSrv.getValidation({
          isReq: true,
          max: 250
        })],
        series: [null, this.validationSrv.getValidation({
          max: 50
        })],
        number: [null, this.validationSrv.getValidation({
          max: 50,
          isReq: true
        })],
        dateStart: [null, this.validationSrv.getValidation({
          isReq: true
        })],
        dateEnd: [null, this.validationSrv.getValidation({
          isReq: true
        })]
      });
    }
    return this.formBuilder.group({
      docType: null,
      docOther: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 250
      })],
      series: [null, this.validationSrv.getValidation({
        max: 50
      })],
      number: [null, this.validationSrv.getValidation({
        max: 50
      })],
      dateStart: null,
      dateEnd: null
    });
  }

  /**
   * Реквизиты счета
   */
  getAccountType(param?: ValidatorParamsModel): FormGroup {
    return this.formBuilder.group({
      type: this.getDictType({isReq: true}),
      currency: this.getDictType({isReq: true}),
      accountNumber: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 20
      })],
      dateStart: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      paycards: false,
      paycard: [],
      dateEnd: null,
      row: null,
      $checkedFlk: null,
    });
  }


  /**
   * Полномочия представителя
   */
  getAuthority(param?: ValidatorParamsModel): FormGroup {
    return this.formBuilder.group({
      docName: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 250
      })],
      series: [null, this.validationSrv.getValidation({
        max: 50
      })],
      number: [null, this.validationSrv.getValidation({
        max: 50
      })],
      dateReg: [null, this.validationSrv.getValidation({
        isReq: true,
      })],
      dateStart: [null, this.validationSrv.getValidation({
        isReq: true,
      })],
      dateEnd: [null, this.validationSrv.getValidation({
        isReq: true,
      })],
      stopPowers: 0
    }, this.validationSrv.getValidation(param));
  }


  /**
   * Реквизиты счета, открытого в другом банке
   */
  getOtherAccountType(param?: ValidatorParamsModel): FormGroup {
    return this.formBuilder.group({
      type: this.getDictType({isReq: true}),
      currency: this.getDictType({isReq: true}),
      accountNumber: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 40
      })],
      accountCorrNumber: [null, this.validationSrv.getValidation({
        max: 40
      })],
      country: [{
        name: "РОССИЙСКАЯ ФЕДЕРАЦИЯ",
        code: "643"
      }, this.validationSrv.getValidation({
        isReq: true,
        max: 40
      })],
      bik: [null, this.validationSrv.getValidation({
        max: 9,
        min: 9
      })],
      bank: [null, this.validationSrv.getValidation({
        max: 250
      })],
      swift: [null, this.validationSrv.getValidation({
        max: 11
      })],
      row: null,
      $checkedFlk: null,
    }, this.validationSrv.getValidation(param));
  }

  /**
   * Копии документов клиента
   */
  getDocumentDossier(param?: ValidatorParamsModel): FormGroup {
    return this.formBuilder.group({
      nameDoc: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 100
      })],
      idDoc: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 250
      })],
      typeDoc: this.getDictType({isReq: true}),
      typeInDoc: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 2000
      })],
      dateInDoc: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      registryId: null
    }, this.validationSrv.getValidation(param));
  }

  /**
   * Сведения об основных контрагентах
   */
  getPartner(param?: ValidatorParamsModel): FormGroup {
    return this.formBuilder.group({
      clientInfo: this.getClientInfo(DossierAccessEnum.PARTNER),
      role: this.getDictType(),
      $partId: null,
      $checkedFlk: null,
      dateFrom:null,
      dateEnd:null,
      stopPowers: 0
    }, this.validationSrv.getValidation(param));
  }

  /**
   * Сетевая информация о клиенте
   */
  getNetworkInfo(param?: ValidatorParamsModel): FormGroup {
    return this.formBuilder.group({
      ip: [null, this.validationSrv.getValidation({
        max: 39
      })],
      mac: [null, this.validationSrv.getValidation({
        max: 17
      })],
      row: null
    }, this.validationSrv.getValidation(param));
  }

  /**
   * Обновление анкеты клиента
   */
  getUpdateCardInfo(param?: ValidatorParamsModel): FormGroup {
    return this.formBuilder.group({
      dateEditPodFt: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      fio: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 100
      })],
      position: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 60
      })],
      reason: [null, this.validationSrv.getValidation({
	    isReq: true,
        max: 200
      })],
      userId: [null, this.validationSrv.getValidation({
	    isReq: true,
        max: 100
      })],
      row: null
    }, this.validationSrv.getValidation(param));
  }

  /**
   * Сетевая информация о клиенте (таблица)
   */
  getNetwork() {
    return null;
  }

  /**
   * Обновление анкеты клиента (таблица)
   */
  getUpdateCard() {
    return null;
  }

  /**
   * Дополнительные сведения
   */
  getDopInfo(): FormGroup {
    return this.formBuilder.group({
      organUpr: this.getDictType({isReq: true}),
      organUprOther: this.getDictType(),
      category: this.getDictType({isReq: true}),
      sho: false,
      orc: false,
      capital: null,
      leastCapital: null,
      asset: [null, this.validationSrv.getValidation({
        max: 2000
      })],
      estate: [null, this.validationSrv.getValidation({
        max: 4000
      })],
      staffCount: [null, this.validationSrv.getValidation({
        max: 5
      })],
      wageFund: null,
      accountsDep: this.getDictType(),
      activity: this.getDictType(),
      income: [null, this.validationSrv.getValidation({
        max: 2000
      })],
      reputation: [null, this.validationSrv.getValidation({
        max: 4000
      })],
      network: this.getNetwork()
    }, {validator: dopInfoValidator});
  }

  /**
   * Атрибуты регистрации досье
   */
  getAttributes(param?: ValidatorParamsModel): FormGroup {
    return this.formBuilder.group({
      identificationType: this.getDictType({isReq: true}),
      identificationLevel: this.getDictType({isReq: true}),
      dateStart: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      dateEnd: null,
      createDate: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      dateNextCheck: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      dateEndSign: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      dateEndCheckOtch: null,
      fio: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 100
      })],
      position: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 60
      })],
      fioIn: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 100
      })],
      positionIn: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 60
      })],
      updateCard: this.getUpdateCard()
    }, this.validationSrv.getValidation(param));
  }

  /**
   * Оценка уровня риска
   */
  getRiskAssess(param?: ValidatorParamsModel): FormGroup {
    return this.formBuilder.group({
      levelRisk: this.getDictType({isReq: true}),
      serviceRisk: this.getDictType({isReq: true}),
      descriptRisk: [null, this.validationSrv.getValidation({
        max: 4000
      })],
      userId: null,
      fio: null,
      row: null,
      creatDate: null
    }, this.validationSrv.getValidation(param));
  }

  /**
   * Cотрудник, ответственный за работу с клиентом
   */
  getAccessInfo(): FormGroup {
    return this.formBuilder.group({
      id: null,
      user: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      reason: [null, this.validationSrv.getValidation({
        isReq: true,
        max: 250
      })],
      dateFrom: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      dateEnd: [null, this.validationSrv.getValidation({
        isReq: true
      })],
      replacement: false,
      dossierIds: null,
      clientId: null,
      active: null,
      $partId: null
    },{
      validator: CustomValidator.dateNotGreaterThan('dateFrom', 'dateEnd', { 'dateCompare': true })
    });
  }

  getUserInfo() {
    return this.formBuilder.group({
      id: null,
      firstName: null,
      lastName: null,
      middleName: null,
      position: null,
      department: null,
      locked: false,
    });
  }
}
export function dopInfoValidator(control: AbstractControl): { [key: string]: CustomValidatorError } | null {
  const capital = control.get('capital');
  const leastCapital = control.get('leastCapital');

  if (!capital || !leastCapital) {
    return null;
  }

  capital.setErrors(null);
  if (!checkEmpty(capital) && !checkEmpty(leastCapital)) {
    // Поле capital не может быть меньше поля leastCapital
    if (Number(capital.value) < Number(leastCapital.value)) {
      const error = {
        customValidator: {
          errorMsg: 'Поле заполнено неверно',
          withBigMsg: true,
          withoutSubmitted: true,
          errorMsgBig: 'Поле "Сведения о величине зарегистрированного и оплаченного уставного (складочного) капитала или ' +
            'величине уставного фонда, имущества" не может быть меньше поля "Минимальный размер уставного капитала, ' +
            'определенного законодательством РФ или органами власти для отдельных видов деятельности"'
        } as CustomValidatorError
      };
      capital.setErrors(error);
    }
  }
  function checkEmpty(item) {
    return !(item || {}).value || item.value && item.value === "";
  }
  return null;
}
/**
 * Валидация для orgName
 * должно быть хотя бы одно из полей
 */
export function orgNameTypeValidator(control: AbstractControl): { [key: string]: CustomValidatorError } | null {
  let result = null;
  const orgNameFullRu = control.get('orgNameFullRu');
  const orgNameRu = control.get('orgNameRu');
  const orgNameFullIn = control.get('orgNameFullIn');
  const orgNameIn = control.get('orgNameIn');

  if (!orgNameFullRu || !orgNameRu || !orgNameFullIn || !orgNameIn) {
    return null;
  }

  const validator = [];
  if (checkEmpty(orgNameFullRu) && checkEmpty(orgNameRu) && checkEmpty(orgNameFullIn) && checkEmpty(orgNameIn)) {
    validator.push(Validators.required);
    result = {
      customValidator: {
        errorMsg: 'Необходимо заполнить одно из полей'
      } as CustomValidatorError
    };
  }
  orgNameFullRu.setValidators(validator);
  orgNameFullRu.updateValueAndValidity({onlySelf: true});
  orgNameRu.setValidators(validator);
  orgNameRu.updateValueAndValidity({onlySelf: true});
  orgNameFullIn.setValidators(validator);
  orgNameFullIn.updateValueAndValidity({onlySelf: true});
  orgNameIn.setValidators(validator);
  orgNameIn.updateValueAndValidity({onlySelf: true});
  function checkEmpty(item) {
    return !(item || {}).value || item.value && item.value === "";
  }
  return result;
}

/**
 * Валидация для part
 * Выставляем обязательное поле part по state
 */
export class PartValidator {

  constructor(protected validationSrv: ValidatorService){
  }
  validator(control: AbstractControl): { [key: string]: CustomValidatorError } | null  {
    const state = control.get('state');
    const part = control.get('part');
    const validateParam = {
      max: 6,
      maxNumber: 100,
      minNumber: 0
    } as ValidatorParamsModel;
    if (!state || !part) {
      return null;
    }
    if (state.value && state.value.code === '1') {
      validateParam.isReq = true;
    }
    const validator = this.validationSrv.getValidation(validateParam);
    part.setValidators(validator);
    part.updateValueAndValidity({onlySelf: true});
    return null;
  }
}

/**
 * Валидация для addressType
 * Выставляем обязательное поле region по country
 */
export function addressTypeValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const country = control.get('country');
  const region = control.get('region');
  const validator = [];

  if (!country || !region) {
    return null;
  }
  if (!region.value && country.value && country.value.code === "643") {
    validator.push(Validators.required);
  }
  region.setValidators(validator);
  region.updateValueAndValidity({onlySelf: true});
  return null;
}
/**
 * Валидация для docOther из DocumentInfo
 * Выставляем обязательное поле docOther по docType
 */
export function docOtherDocumentInfoValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const docOther = control.get('docOther');
  const docType = control.get('docType');
  const validator = [];

  if (!docOther || !docType) {
    return null;
  }
  if (docType.value && ['28', '35', '40', '99'].includes(docType.value.code)) {
    validator.push(Validators.required);
  }
  docOther.setValidators(validator);
  docOther.updateValueAndValidity({onlySelf: true});
  return null;
}

/**
 * Валидация для series из DocumentInfo
 * Выставляем обязательное поле series по docType
 */
export function seriesDocumentInfoValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const series = control.get('series');
  const docType = control.get('docType');
  const validator = [];

  if (!series || !docType) {
    return null;
  }
  if ('21' === docType.value?.code) {
    validator.push(Validators.required);
  }
  series.setValidators(validator);
  series.updateValueAndValidity({onlySelf: true});
  return null;
}

/**
 * Валидация для number из DocumentInfo
 * Выставляем обязательное поле number по docType
 */
export function numberDocumentInfoValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const number = control.get('number');
  const docType = control.get('docType');
  const validator = [];

  if (!number || !docType) {
    return null;
  }
  if (['21', '22', '26', '28', '31', '32', '34', '35', '37', '40'].includes(docType.value?.code)) {
    validator.push(Validators.required);
  }
  number.setValidators(validator);
  number.updateValueAndValidity({onlySelf: true});
  return null;
}

/**
 * Валидация для полей из migrationCardInfo
 * Выставляем обязательсть полей по nationality
 */
export function migrationCardInfoValidator(typePage: DossierAccessEnum, control: AbstractControl): { [key: string]: boolean } | null {
    const nationality = control.get('nationality');
    const migrationCardInfo = control.get('migrationCardInfo');
    const isFieldsReq = ![DossierAccessEnum.SVEDAU,
      DossierAccessEnum.SVEDUDS,
      DossierAccessEnum.SVEDVPR,
      DossierAccessEnum.SVEDBV,
      DossierAccessEnum.PARTNER].includes(typePage)&& ['2', '3'].includes(nationality.value?.code);
    migrationCardInfo.get('series')?.setValidators(this.validationSrv.getValidation({
      max: 50,
      isReq: isFieldsReq
    }));
    migrationCardInfo.get('number')?.setValidators(this.validationSrv.getValidation({
      max: 50,
      isReq: isFieldsReq
    }));
    migrationCardInfo.get('dateStart')?.setValidators(this.validationSrv.getValidation({
      isReq: isFieldsReq
    }));
    migrationCardInfo.get('dateEnd')?.setValidators(this.validationSrv.getValidation({
      isReq: isFieldsReq
    }));
    migrationCardInfo.updateValueAndValidity({onlySelf: true});
    return null;
}


/**
 * Валидация для группы полей в publicInfo
 * Выставляем обязательное поля postOther по postRPDL
 * Выставляем обязательное поля otherDochod по istDochod
 *
 */
export function publicInfoValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const postRPDL = control.get('postRPDL');
  const postOther = control.get('postOther');
  const istDochod = control.get('istDochod');
  const otherDochod = control.get('otherDochod');
  if (!postOther || !istDochod || !istDochod) {
    return null;
  }
  if (postRPDL.value && postRPDL.value.code === "99") {
    postOther.setValidators([Validators.required]);
    postOther.updateValueAndValidity({onlySelf: true});
  }
  if (istDochod.value && (istDochod.value as Array<any>).length > 0) {
    const isReqCode = (istDochod.value as Array<any>).find(item => item.code === "8");
    if (isReqCode) {
      otherDochod.setValidators([Validators.required]);
      otherDochod.updateValueAndValidity({onlySelf: true});
    }
  }
  return null;
}
/**
 * Валидация для okved
 * okved или okved2 должны быть для 'ul', 'ip', 'flcp'
 */
export class OkvedValidator {

  public WHITE_LIST_OKVED_CLIENT_TYPE = ['ul', 'ip', 'flcp'];
  constructor();
  constructor(typeOrg: TypeOrg, typePage: DossierAccessEnum)
  constructor(private typeOrg?: TypeOrg, private typePage?: DossierAccessEnum){
  }
  validator(control: AbstractControl): { [key: string]: CustomValidatorError } | null  {
    const typeOrgReq = this.WHITE_LIST_OKVED_CLIENT_TYPE.includes(this.typeOrg);
    let result = null;
    const okved = control.get('okved');
    const okved2 = control.get('okved2');

    if (!okved || !okved2) {
      return null;
    }

    const validator = [];
    if (typeOrgReq && getOkvedLen(okved.value) === 0 && getOkvedLen(okved2.value) === 0) {
      if (this.typePage === DossierAccessEnum.SVEDCLIENT) {
        // Валидация для поля оквед и оквед2
        validator.push(Validators.required);
        // Сообщение для группы полей
        result = {
          customValidator: {
            errorMsg: 'Необходимо заполнить одно из полей'
          } as CustomValidatorError
        };
      }
    }
    okved.setValidators(validator);
    okved2.setValidators(validator);
    okved.updateValueAndValidity({onlySelf: true});
    okved2.updateValueAndValidity({onlySelf: true});
    return result;

    function getOkvedLen(list: Array<any>) {
      if (!list) {
        return 0;
      } else {
        return list.length;
      }
    }
  }
}
/**
 * Валидация для kodPodr
 */
class KodPodrValidator {

  constructor(private validationSrv: ValidatorService){
  }
  validator(control: AbstractControl): { [key: string]: CustomValidatorError } | null  {
    const kodPodrField = control.get('kodPodr');
    const docTypeField = control.get('docType');
    if (!kodPodrField || !docTypeField) {
      return null;
    }
    const validator = {} as ValidatorParamsModel;
    const value = docTypeField.value;
    const isCode21 = value && value.code && value.code === '21';
    validator.max = isCode21 ? 7 : 50;
    validator.isReq = isCode21;
    kodPodrField.setValidators(this.validationSrv.getValidation(validator));
    kodPodrField.updateValueAndValidity({onlySelf: true});
    return null;
  }
}

export type AddressModelType = 'req' | 'real' | 'post';

/**
 * Валидация полей раздела (AML-333)
 * https://doc.comita.lan/pages/viewpage.action?pageId=55813321
 */
export class RegInfoValidator {
  constructor();
  constructor(validationSrv: ValidatorService, typeOrg: TypeOrg, rezidentRf: FormControl, typePage?: DossierAccessEnum);
  constructor(private validationSrv?: ValidatorService,
              private typeOrg?: TypeOrg,
              private rezidentRf?: FormControl,
              private typePage?: DossierAccessEnum) { }
  callValidator(control: AbstractControl) {
    if (!control.parent) {
      return null;
    }
    // либо у svedUL, либо у svedIP
    const svedUL = control.parent.get('svedUL');
    const svedIP = control.parent.get('svedIP');
    let regInfo;
    if (svedUL?.enabled) {
      regInfo = svedUL.get('regInfo');
    }
    if (svedIP?.enabled) {
      regInfo = svedIP.get('regInfo');
    }
    if (!regInfo) {
      return null;
    }
    // Вызываем обновление валидации компонента из родительского компонента
    setTimeout(() => {
      regInfo.updateValueAndValidity();
    });
    return null;
  }
  validator(control: AbstractControl) {
    const ogrn = control.get('ogrn');
    const dateReg = control.get('dateReg');
    const dateRegIn = control.get('dateRegIn');
    const regNum = control.get('regNum');
    const nameRegOrg = control.get('nameRegOrg');
    if (!ogrn || !dateReg || !dateRegIn || !regNum || !nameRegOrg || !this.rezidentRf) {
      return null;
    }
    const ogrnValidator: ValidatorParamsModel = {
      max: 15,
      isOGRN: true,
    };
    const dateRegValidator: ValidatorParamsModel = {};
    const dateRegInValidator: ValidatorParamsModel = {};
    const reqNumValidator: ValidatorParamsModel = {
      max: 50
    };
    const nameRegOrgValidator: ValidatorParamsModel = {
      max: 250,
      min: 3
    };
    this.validationSrv.getValidation({
      max: 250,
      min: 3,
      isReq: true
    });

    const conditionTypePage = [
      DossierAccessEnum.SVEDCLIENT,
      DossierAccessEnum.SVEDIOU,
      DossierAccessEnum.SVEDPREDST
    ].includes(this.typePage);

    if (this.rezidentRf.value) {
      if (conditionTypePage) {
        setReqField(ogrnValidator, dateRegValidator, ogrnValidator, dateRegValidator)
      }
    } else {
      if (conditionTypePage) {
        setReqField(reqNumValidator, dateRegInValidator)
      }
    }

    nameRegOrgValidator.isReq = isValue(ogrn) || isValue(regNum); // req(ogrn || regNum)
    // TODO вот это надо порефакторить!
    ogrn.setValidators(this.validationSrv.getValidation(ogrnValidator));
    dateReg.setValidators(this.validationSrv.getValidation(dateRegValidator));
    dateRegIn.setValidators(this.validationSrv.getValidation(dateRegInValidator));
    regNum.setValidators(this.validationSrv.getValidation(reqNumValidator));
    nameRegOrg.setValidators(this.validationSrv.getValidation(nameRegOrgValidator));
    ogrn.updateValueAndValidity({onlySelf: true});
    dateReg.updateValueAndValidity({onlySelf: true});
    dateRegIn.updateValueAndValidity({onlySelf: true});
    regNum.updateValueAndValidity({onlySelf: true});
    nameRegOrg.updateValueAndValidity({onlySelf: true});
    function isValue(field: AbstractControl) {
      return !(field.value === null || field.value === "");
    }

    function setReqField(...validatorParams: ValidatorParamsModel[]) {
      validatorParams.forEach((validatorParam: ValidatorParamsModel) => {
        validatorParam.isReq = true;
      })
    }

  }
}
