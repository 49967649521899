import {Component} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {CryptographService} from "../../../cryptography";
import {Observable} from "rxjs";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";

@Component({
  selector: 'app-sign-svetofor-window',
  templateUrl: './sign-svetofor.component.html',
  styleUrls: ['./sign-svetofor.component.scss']
})
export class SignSvetoforComponent {

  listSign: signList[] = [];
  newArr = [];
  getHash: (id, type, oid) => Observable<any>;
  sendSign: (id, type, signature) => Observable<any>;
  signProgress = false;
  isSign = false; // признак что подписывали документы
  stopSigned = false;
  icons = {
    spinner: faSpinner
  }
  constructor(public modal: NgbActiveModal, private cryptographService: CryptographService) {
  }


  open(param: any) {
    this.listSign = param.item;
    this.sendSign = param.sendSign;
    this.getHash = param.getHash;
    this.newArr = this.newArrFunc(this.listSign);
  }

  /**
   * Назад
   */
  goBack() {
    if (this.signProgress || this.existLocked()) {
      return;
    }
    this.modal.close(this.isSign);
  }

  stopSign(): void {
    this.stopSigned = true;
    this.signProgress = false;
  }

  getAllSignDocs(): signList[] {
    return this.listSign.filter(i => i.process && !i.lock);
  }

  getIdsSignDocs(): string[] {
    return Array.from(new Set( this.listSign.map(item => item.id)))
  }

  existLocked(): boolean {
    return this.listSign.filter(i => i.lock).length > 0;
  }

  signDocument() {
    this.stopSigned = false;
    const needSign = this.getAllSignDocs();
    if (needSign.length === 0) {
      return;
    }
    const cert = this.cryptographService.crypt.getSelectedCert();
    if (!cert) {
      needSign.forEach(doc => {doc.error = this.errorCatchCert(null) })
      return;
    }
    const _iterate = this.iteratorObject(needSign);
    const oid = this.cryptographService.crypt.getAlgorithmCert();
    this.signProgress = true;
    const that = this;
    const step = (iterate) => {
      const next = iterate.next();
      if (!next.done) {
        sign(iterate, next.object);
      } else {
        that.signProgress = false;
      }
    }
    this.isSign = true;
    const sign = (iterate, item) => {
      if (this.stopSigned || item.lock) {
        return;
      }
      item.lock = true;
      that.getHash(item.id, item.type, oid).subscribe(data => {
        that.cryptographService.crypt.generateSignature(data, cert).then(signature => {
          that.sendSign(item.id, item.type, signature).subscribe((result) => {
            item.signature = data;
            item.success = true;
            item.process = false;
            item.error = null;
            item.lock = false;
            step(iterate)
          }, error => {
            item.error = error;
            item.lock = false;
            item.process = false;
            step(iterate)
          })
        }, error => {
          item.error = this.errorCatchCert(error);
          item.lock = false;
          item.process = false;
          step(iterate)
        })
      }, error => {
        item.error = error;
        item.lock = false;
        item.process = false;
        step(iterate)
      })
    }
    sign(_iterate, _iterate.next().object)
  }

  errorCatchCert = (error) => {
    let result;
    if (error?.message?.indexOf('0x8010006E') > 0) {
      result = `Отсутствует закрытый ключ`
    } else if (error?.message?.indexOf('0x000004C7') > 0) {
      result = `Операцию с ключами или сертификатами была отменена пользователем`
    } else {
      result = `В профиле пользователя не назначен сертификат ключа проверки электронной подписи, или выбранный сертификат не валиден!`
    }
    return {message: result}
  }

  iteratorObject(array) {
    let nextIndex = 0;
    return {
      next: function (): any {
        return nextIndex < array.length ?
          {object: array[nextIndex++], done: false} :
          {done: true};
      }
    }
  }

  newArrFunc(arr: signList[]): any[] {
    const newArr = Array.from(new Set( arr.map(item => item.type)))
    let arr2 = [];
    let arr3 = [];

    for (let item1 of newArr) {
      arr2 = [];
      for (let item of arr ) {
        if (item.type === item1) {
          arr2.push(item)
        }
      }
      arr3.push(arr2)
    }
    return arr3;
  }
}

type signList = {
  id: string;
  process?: boolean;
  lock?: boolean;
  success?: boolean;
  error?: any;
  date?: string;
  name: string;
  type?: string;
}
