import {Injectable} from '@angular/core';
import {CookieService} from "ngx-cookie-service";

@Injectable()
export class UXService {
  constructor(private cookieSrv: CookieService) {
  }

  setTableUX(params: object, state: string) {
    try {
      this.cookieSrv.set(`table_${state}`, JSON.stringify(params));
    } catch (e) {
      console.warn(e);
    }
  }

  getTableUX(state: string): object {
    let params = null;
    try {
      params = JSON.parse(this.cookieSrv.get(`table_${state}`));
    } catch (e) {
      console.warn(e);
    }
    return params;
  }
}
