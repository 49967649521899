import { Component, OnInit } from "@angular/core";
import { BaseFilterController } from "../BaseFilterController";
import { FormBuilder } from "@angular/forms";
import { NoticeFilter } from "@amlCore/models";
import { DictionaryService, ResourceService, TableService } from "@amlCore/services";
import moment from "moment";
import { ActivatedRoute } from "@angular/router";


@Component({
  selector: "app-notice-filter",
  templateUrl: "./noticeFilter.component.html",
})

export class NoticeFilterComponent extends BaseFilterController implements OnInit {
  public hasCompareDateError = false;
  public errorMessageCompareDate: string;
  public filterObjectModel = new NoticeFilter();
  // справочники
  public selectObj = {
    // Тип субъекта
    typeSubjectsList: [],
    executerUserList: [],
    notifications: [],
  };
  placeholder = 'Поиск по ФИО, наименованию, ИНН, ОГРН клиента или Событию (по вхождению)';

  constructor(private fb: FormBuilder,
              protected tableSrv: TableService,
              private resourceSrv: ResourceService,
              private dictSrv: DictionaryService,
              protected activatedRoute: ActivatedRoute) {
    super(tableSrv, activatedRoute);
  }

  ngOnInit() {
    this.init();
    this.loadExecuterUserList();
    this.loadNotifications();
  }

  /**
   * Метод загрузки списка событий
   */
  loadNotifications(): void {
    this.dictSrv.getRemoteData('notifications').then((data) => {
      this.selectObj.notifications = data;
  });
  }

  /**
   * Метод создания формы
   * @return FormGroup
   */
  public createFormModel() {
    return this.fb.group(new NoticeFilter());
  }

  /**
   * Загрузка списка пользователей
   * @returns void
   */
  private loadExecuterUserList() {
    const customParams = {sort: "fullName", dir: "asc", page: "0", size: "200"};
    this.resourceSrv.getUserList(customParams).subscribe(({content}) => {
      this.selectObj.executerUserList = (content || []);
    });
  }

  getStartFilterModel(): any {
    return {
      subject: {
        clientType: '1'
      }
    } as NoticeFilter;
  }

  /**
   * Описания полей
   */
  public getFilterDescription(): any {
    return {
      userId: 'Исполнитель',
      eventDateFrom: 'Дата С',
      eventDateTo: 'Дата По',
      alert: 'событие',
      subject: {
        typePart : 'Тип субъекта',
        clientType: 'Тип организации / лица',
        legalEntity : {
          orgName: 'Наименование организации',
          ogrn: 'ОГРН',
          inn: 'ИНН',
          kpp: 'КПП'
        },
        individualPerson : {
          lastName: 'Фамилия',
          firstName: 'Имя',
          middleName: 'Отчество',
          birthDate: 'Дата рождения',
          series: 'Серия документа',
          numDoc: 'Номер документа',
          inn: 'ИНН',
          ogrn: 'ОГРН'
        },
        foreignStructure: {
          orgName: 'Наименование организации',
          regNum: 'Регистрационный номер'
        }
      }
    };
  }

}
