import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from '@angular/router';
import {AlertPanelComponent, AlertPanelService, ICustomSelectMapper} from "@amlCore/components";
import {DossierService} from "../../services";
import {IDictionaryItem, IDictItemType} from "@amlCore/interfaces";
import {DictionaryService, UserService, ResourceService} from "@amlCore/services";
import {IDopInfoItem} from "../../interfaces";
import {CheckTransaction, IClientData} from "../../models";
import {DossierAccessEnum} from "../../../core/enum";
import {CheckTransactionsFilter, User} from "../../../core/models";
import {ExecutionTransferWindowComponent} from "../executionTransfer";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subject} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ExecutorDataModel} from "../../models/executorData.model";
import {Utils} from "@amlCore/utils";
import {finalize, takeUntil} from "rxjs/operators";
import {TransactionSubject} from "../../models/transactionSubject.model";
import {GenerateFesComponent} from "../generateFes";

@Component({
  selector: "app-check-transaction-form",
  templateUrl: "./checkTransactionForm.component.html"
})

export class CheckTransactionFormComponent implements OnInit, OnDestroy {
  @ViewChild("alertObjSave") alertObjToSave: AlertPanelComponent;

  private id: string; // уникальный идентификатор анализа операции
  public checkSubject: TransactionSubject;
  public dictDate: string;
  public idSubject: string;
  public dictId: string;
  public dictRowId: string;
  public dictParentRowId: string;
  public dictNumber: string;
  public dictType: string;
  public dictName: string;
  public mvkResh: any;
  public msLead: any;
  public msLeadVisible = false;
  public msFound: any;
  public msFoundVisible = false;
  public fts: any;
  public ftsVisible = false;
  public msAdr: any;
  public msAdrVisible = false;
  public passport: any;
  public passportVisible = false;
  public dopNames: any[] = [];
  public formCheck: CheckTransaction; // модель формы просмотра
  public isReadOnlyAccess = false;
  public canEditForm = false;
  isLoaded = false; // Признак, что форма загружена
  checkResult = null;
  disabled = false;
  /*справочники*/
  public criticallists: IDictItemType[];

  public affiliationpdl: IDictionaryItem<{
    id: string;
    code: string;
    name: string;
  }>[]; // справочник характеров принадлежности к ПДЛ

  public country: IDictionaryItem<{
    id: number;
    kod: string;
    kodletter: string;
    kodletter2: string;
    name: string;
  }>[]; // справочник стран

  public codeMessage4936U: IDictionaryItem<{
    code: string;
    id: string;
    name: string;
  }>[];

  public npa: IDictionaryItem<{
    id: string;
    code: string;
    name: string;
  }>[]; // справочник оснований

  public resultCheckList: IDictionaryItem<{
    id: string;
    code: string;
    name: string;
  }>[]; // результаты проверок

  public typeSubjects: IDictionaryItem<{
    code: string;
    name: string;
  }>[] = [];
  public listView4936U: IDictItemType[] = [];
  public list5599u: IDictItemType[] = [];
  public usersWithPermissions: User[];
  isSaveForm = false; // Признак, что форма сохранена
  private modal;
  viewForm: FormGroup;
  $destroy = new Subject();
  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    private resourceSrv: ResourceService,
    protected alertPanelSrv: AlertPanelService,
    protected userSrv: UserService,
    protected dictSrv: DictionaryService,
    private dossierSrv: DossierService,
    private modalService: NgbModal,
    private fb: FormBuilder) {

  }

  ngOnInit() {
    this.isReadOnlyAccess = this.userSrv.getAccess(DossierAccessEnum.TRANSACTIONCHECKLIST).readOnly;

    this.dictSrv.getRemoteData("npa").then((result) => {
      const basisCodes = ["1", "2"];
      this.npa = result.filter((item) => basisCodes.includes(item.code));
    });
    this.dictSrv.getRemoteData("affiliationpdl").then((result) => {
      this.affiliationpdl = result;
    });
    this.dictSrv.getRemoteData("oksm").then((result) => {
      this.country = result;
    });
    this.dictSrv.getRemoteData("codeMessage4936U").then((result) => {
      this.codeMessage4936U = result;
    });
    this.dictSrv.getRemoteData("resultchecking").then((result) => {
      this.resultCheckList = result;
    });
    this.dictSrv.getRemoteData('typesubjects').then((result) => {
      this.typeSubjects = result;
    });
    this.dictSrv.getRemoteData('listView4936U').then((result) => {
      this.listView4936U = result;
    });
    this.dictSrv.getRemoteData('5599u').then((result) => {
      this.list5599u = result;
    });
    this.dictSrv.getRemoteData('criticallists').then((result) => {
      this.criticallists = result;
    });

    this.route.params.subscribe((params) => {
      this.id = params.id;
      this.loadCheckTransaction();
      this.resourceSrv.getUsersByTransactionCheck(this.id).subscribe((users) => {
        this.usersWithPermissions = users;
      });
    });

    this.viewForm = this.fb.group({
      otherCodes: [null],
      unusualCodes: [null],
      checkResult: [null, Validators.required],
      action: null,
      penalty: null
    });
    this.viewForm.get('checkResult').valueChanges.pipe(takeUntil(this.$destroy)).subscribe(value => {
      const penalty = this.viewForm.get('penalty');
      penalty.setValidators(value==='1' || value==='2' ? [Validators.required]:[]);
      penalty.updateValueAndValidity();
    });
  }

  ngOnDestroy(): void {
    if(!this.isReadOnlyAccess){
      this.dossierSrv.stopEditCheckTransaction(this.id);
    }
    this.$destroy.next();
    this.$destroy.complete();
  }

  /*Загрузка проверки по операции*/
  private loadCheckTransaction() {
    this.dossierSrv.getCheckTransactionById(this.id).subscribe((data: CheckTransaction) => {
      this.updateForm(data, true);
      this.checkSubject = this.formCheck.transaction[this.formCheck?.participantType ?? 'payer'];
      this.formCheck.dopInfos.forEach(item => {
        switch (item.code) {
          case "dictDate":
            this.dictDate = item.value;
            break;
          case "idSubject":
            this.idSubject = item.value;
            break;
          case "dictId":
            this.dictId = item.value;
            break;
          case "dictRowId":
            this.dictRowId = item.value;
            break;
          case "dictParentRowId":
            this.dictParentRowId = item.value;
            break;
          case "dictNumber":
            this.dictNumber = item.value;
            break;
          case "dictType":
            this.dictType = item.value;
            break;
        }
      });

      this.dopNames = this.formCheck.dopInfos.filter((item) => item.name);

      if (this.dictRowId) {
        if (this.dictType === "mvk") {
          this.resourceSrv.getMvkReshIdBySubjectId(this.dictRowId).subscribe((dataMvk) => {
            this.mvkResh = dataMvk;
          });
        }

        if (this.dictType === "mslead") {
          this.resourceSrv.getMsLead(this.dictRowId).subscribe((dataMslead) => {
            this.msLead = dataMslead;
          });
        }

        if (this.dictType === "msfound") {
          this.resourceSrv.getMsFound(this.dictRowId).subscribe((dataMsfound) => {
            this.msFound = dataMsfound;
          });
        }
        if (this.dictType === "fts") {
          this.resourceSrv.getFts(this.dictRowId).subscribe((dataFts) => {
            this.fts = dataFts;
          });
        }
        if (this.dictType === "ms_adr_info") {
          this.resourceSrv.getMsAdr(this.dictRowId).subscribe((dataMsadrinfo) => {
            this.msAdr = dataMsadrinfo;
          });
        }
        if (this.dictType === "passport") {
          this.resourceSrv.getPassport(this.dictRowId).subscribe((dataPassport) => {
            this.passport = dataPassport;
          });
        }
      }

      this.dictName = this.dictSrv.getDictNameByCode(this.dictType, this.criticallists);

    });
  }

  initEditMode(): void {
    if(!this.isReadOnlyAccess){
      this.isLoaded = null;
      this.dossierSrv.startEditCheckTransaction(this.id).pipe(finalize(()=> this.isLoaded = true)).subscribe(response => {
        if(!response?.result) {
          this.isReadOnlyAccess = true;
          this.canEditForm = false;
          this.viewForm.disable();
          this.alertObjToSave.open(
            this.alertPanelSrv.getErrorMsg(response?.message ?? "Ошибка редактирования документа", false)
          );
        }
      });
    }
  }

  public openDossier(check: IClientData): void {
    window.open(`dossier/${check.dossierId}/${check.typeSubject}`, '_blank');
  }

  isVisibleTitleSubject(): boolean {
    return this.isSubjectData(this.formCheck?.dossierInfo?.clientInfo?.typeSubject);
  }

  public isSubjectData(code: string): boolean {
    const subjectListCodes = [
      'svedAU', 'svedIOU', 'svedUDS',
      'svedClient', 'svedPredst', 'svedVPR',
      'svedBV', 'partner'];
    return subjectListCodes.includes(code);
  }

  public openDossierRoot(rootIdDossier: string): void {
    window.open(`dossier/${rootIdDossier}/svedClient`, '_blank');
  }

  public getClientInfo(type: string) {
    return this.formCheck.transaction[type].name;
  }

  public getDopInfoName(dopInfoItem: IDopInfoItem): string {
    if (dopInfoItem && dopInfoItem.code === "characterPDL") {
      const info = (this.affiliationpdl || []).find((item: any) => item.code === dopInfoItem.value);
      return (info?.name || "");
    }
    if (dopInfoItem && dopInfoItem.code === "country") {
      const info = (this.country || []).find((item: any) => item.kod === dopInfoItem.value);
      return (info?.name || "");
    }
    return (dopInfoItem?.value || "");
  }

  public getTypeSubjectName(code: string): string {
	   return this.dictSrv.getTypeSubjectName(code, this.typeSubjects);
  }

  public msLeadChange() {
    this.msLeadVisible = !this.msLeadVisible;
  }

  public msFoundChange() {
    this.msFoundVisible = !this.msFoundVisible;
  }

  public msAdrChange() {
    this.msAdrVisible = !this.msAdrVisible;
  }

  public ftsChange() {
    this.ftsVisible = !this.ftsVisible;
  }

  public passportChange() {
    this.passportVisible = !this.passportVisible;
  }

  /*Сохранить результаты проверки операции*/
  public saveCheckTransaction() {
    this.disabled = true;
    const editableData = Utils.clone(this.viewForm.value);
    editableData.id = this.formCheck.id;
    this.dossierSrv.saveCheckTransaction(editableData).subscribe((data) => {
      this.disabled = false;
      this.updateForm(data);
      this.viewForm.markAsPristine();
      this.isSaveForm = true;
      this.alertObjToSave.open(this.alertPanelSrv.getSuccessMsg('Данные успешно сохранены'));
    });
  }

  private _sendNoticeToUserEmail(data: ExecutorDataModel) {
    const body = { userId: data.userId, executionDate: data.date };
    const transactionId = this.formCheck.id;
    this.userSrv.checkTransactionExecutor(body, transactionId).subscribe(data => {
      this.updateForm(data);
      this?.modal?.componentInstance?.afterSave();
    });
  }

  /*Продолжить проверку*/
  public continueCheckTransaction() {
    this.dossierSrv.continueCheckTransaction(this.id).subscribe((data) => {
      this.updateForm(data);
      this.alertObjToSave.open(this.alertPanelSrv.getSuccessMsg("Проверка успешно возобновлена"));
    });
  }

  /*Завершить проверку по операции*/
  public finishCheckTransaction() {
    this.dossierSrv.finishCheckTransaction(this.id).subscribe((data) => {
      this.updateForm(data);
      this.alertObjToSave.open(this.alertPanelSrv.getSuccessMsg("Проверка завершена"));
    });
  }

  /*Просмотр формы транзакции*/
  public viewTransactionForm(transactionId: string) {
    // @ts-ignore
    window.open(`clientTransactionForm/${transactionId};whereOpenUrl=${'/cabinet/check-transactions'.replaceAll('\/', '%2F')}`, '_blank');
  }

  getExecutorData(): ExecutorDataModel {
    return {
      userId: this.formCheck.executorId,
      date: this.formCheck.executionDate
    };
  }

  /**
   * Передать на исполнение
   */
  changeExecutor(): void {
    this.modal = this.modalService.open(ExecutionTransferWindowComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'xl',
      scrollable: true
    });
    // Передаем данные в компонент
    this.modal.componentInstance.data=this.getExecutorData();
    this.modal.componentInstance.usersList=this.usersWithPermissions;
    this.modal.componentInstance.onSave.subscribe(value => {
      this._sendNoticeToUserEmail(value);
    });
  }

  updateForm(data?: CheckTransaction, isInit?: boolean): void{
    if(data){
      this.viewForm.patchValue(this.checkDataToModel(data));
      this.formCheck = data;
    }
    this.checkCanEditForm();
    if(isInit) {
      this.initEditMode();
    }
    if(this.formCheck.result || !this.canEditForm){
      this.viewForm.disable();
    } else{
      this.viewForm.enable();
    }

  }

  checkDataToModel(data: CheckTransaction){
    const model = Utils.clone(data);
    const dataCheckResult = data.checkResult != null ? data.checkResult : 1;
    model.checkResult = this.resultCheckList[dataCheckResult]?.code;
    return model;
  }

  codesSearchFn() {
    return Utils.searchByCodeOrName();
  }

  /**
   * метод получения описания Принятые меры
   */
  private getCurrCashTitle(): string {
    const value = this.viewForm.get('penalty').value;
    if (value) {
      return this.codeMessage4936U.find((item: any) => item.code === value)?.name || '';
    }
    return '';
  }

  /**
   * Сформировать ФЭС
   */
  public generateFes() {
    const ids = [this.formCheck.id];
    const modalRef = this.modalService.open(GenerateFesComponent,  {
      size: 'lg',
      backdrop: 'static',
      centered: true});
    modalRef.componentInstance.open(ids);
    modalRef.result.then((data) => {
      this.loadCheckTransaction();
    });
  }

  private checkCanEditForm(): void {
    this.canEditForm = !this.isReadOnlyAccess && (!this?.formCheck?.executorId || this.formCheck?.executorId == this.userSrv.getCurrentUser()?.id);
  }

}
