import {Injectable} from "@angular/core";
import {ApiService, StoreService} from "@amlCore/services";
import {Observable, of} from "rxjs";
import {
  ICodeDescription,
  ICodeName,
  IIdCodeDescription,
  IIdDescription,
  IIdType,
  IQrefAuthorizedPerson,
  IQrefBArx,
  IQrefBelongingAccounts,
  IQrefCardNotlegacy,
  IQrefClientinfo,
  IQrefCodmer,
  IQrefConsolidatedList,
  IQrefDokt245Fm,
  IQrefExtorgs,
  IQrefFArx,
  IQrefInfomer,
  IQrefInfOtkaz,
  IQrefKoPrpod,
  IQrefOrgAttrib,
  IQrefOrgs,
  IQrefOtkazUch,
  IQrefPrpod5599,
  IQrefSearchdocforfsfm,
  IQrefTerNotlegacy,
  IQrefViddocosnov,
  IQrefViddocosnov4936,
  IQrefVidOper,
  Page,
  QrefBancModel,
  QrefBnkdelModel,
  QrefCbBnkseekModel,
  QrefDokt4936Model,
  QrefFilModel,
  QrefFltype4936Model,
  QrefOblModel,
  QrefOgrnModel,
  QrefOkato1Model,
  QrefOkcmModel,
  QrefOkcmNotlegacyModel,
  QrefOkwed2Model,
  QrefOkwedModel,
  QrefPd5861Model,
  QrefPidTerUchModel,
  QrefPipdl4936Model,
  QrefSwiftModel,
  QrefTuCbModel,
  QrefValutModel,
} from "../models";
import {map, tap} from "rxjs/operators";
import { IDictionaryBodyParams } from "../models/dictionary-body-param.interface";

@Injectable({ providedIn: "root" })
export class DictionariesService {

  constructor(private api: ApiService,
              private store: StoreService) {
  }

  private BODY_DEFAULT_PARAMS : IDictionaryBodyParams = {
    "number": 0,
    "size": -1
  };

  // Добавляем в pipe проверку наличия и сохранение данных в sessionStorage
  private withSessionStorage<T>(key: string) {
    return (source: Observable<Page<T>>) => {
      const storedData = this.store.getSession(key) as Page<T>;

      return storedData
        ? of(storedData)
        : source.pipe(tap(response => this.store.setSession(key, response)));
    };
  }

  public getDictionariesList(): Observable<any> {
    return this.api.get('/api/v1/dictionary/list');
  }

  public getQrefBancList(params = this.BODY_DEFAULT_PARAMS): Observable<Page<QrefBancModel>> {
    return this.api.post(`/api/v1/dictionary/banc/list`, params)
      .pipe(this.withSessionStorage<QrefBancModel>('qrefBanc'));
  }

  public getQrefCbBnkseekList(params = this.BODY_DEFAULT_PARAMS): Observable<Page<QrefCbBnkseekModel>> {
    return this.api.post(`/api/v1/dictionary/cb-bnkseek/list`, params)
      .pipe(this.withSessionStorage<QrefCbBnkseekModel>('qrefCbBnkseek'));
  }

  public getQrefFilList(params = this.BODY_DEFAULT_PARAMS): Observable<Page<QrefFilModel>> {
    return this.api.post(`/api/v1/dictionary/fil/list`, params)
      .pipe(this.withSessionStorage<QrefFilModel>('qrefFil'));
  }

  public getQrefOblList(params = this.BODY_DEFAULT_PARAMS): Observable<Page<QrefOblModel>> { 
    return this.api.post(`/api/v1/dictionary/obl/list`, params)
      .pipe(this.withSessionStorage<QrefOblModel>('qrefObl'));
  }

  public getQrefOkato1List(params = this.BODY_DEFAULT_PARAMS): Observable<Page<QrefOkato1Model>> {
    return this.api.post(`/api/v1/dictionary/okato1/list`, params)
      .pipe(this.withSessionStorage<QrefOkato1Model>('qrefOkato1'));
  }

  public getQrefOgrnList(params = this.BODY_DEFAULT_PARAMS): Observable<Page<QrefOgrnModel>> {
    return this.api.post(`/api/v1/dictionary/ogrn/list`, params)
      .pipe(this.withSessionStorage<QrefOgrnModel>('qrefOgrn'));
  }

  public getQrefOkcmList(params = this.BODY_DEFAULT_PARAMS): Observable<Page<QrefOkcmModel>> { 
    return this.api.post(`/api/v1/dictionary/okcm/list`, params)
      .pipe(this.withSessionStorage<QrefOkcmModel>('qrefOkcm'));
  }

  public getQrefOkcmNotlegacyList(params = this.BODY_DEFAULT_PARAMS): Observable<Page<QrefOkcmNotlegacyModel>> {
    return this.api.post(`/api/v1/dictionary/okcm-notlegacy/list`, params)
      .pipe(this.withSessionStorage<QrefOkcmNotlegacyModel>('qrefOkcmNotlegacy'));
  }

  public getQrefOkwedList(params = this.BODY_DEFAULT_PARAMS): Observable<Page<QrefOkwedModel>> {
    return this.api.post(`/api/v1/dictionary/okwed/list`, params)
      .pipe(this.withSessionStorage<QrefOkwedModel>('qrefOkwed'));
  }

  public getQrefOkwed2List(params = this.BODY_DEFAULT_PARAMS): Observable<Page<QrefOkwed2Model>> {
    return this.api.post(`/api/v1/dictionary/okwed2/list`, params)
      .pipe(this.withSessionStorage<QrefOkwed2Model>('qrefOkwed2'));
  }

  public getQrefSwiftList(params = this.BODY_DEFAULT_PARAMS): Observable<Page<QrefSwiftModel>> {
    return this.api.post(`/api/v1/dictionary/swift/list`, params)
      .pipe(this.withSessionStorage<QrefSwiftModel>('qrefSwift'));
  }

  public getQrefTuCbList(params = this.BODY_DEFAULT_PARAMS): Observable<Page<QrefTuCbModel>> {
    return this.api.post(`/api/v1/dictionary/cb-tu/list`, params)
      .pipe(this.withSessionStorage<QrefTuCbModel>('qrefTuCb'));
  }

  public getQrefValutList(params = this.BODY_DEFAULT_PARAMS): Observable<Page<QrefValutModel>> {
    return this.api.post(`/api/v1/dictionary/valut/list`, params)
      .pipe(this.withSessionStorage<QrefValutModel>('qrefValut'));
  }

  //Для fm справочников

  public getQrefTucode4936List(params?): Observable<Page<ICodeName>> {
    return this.api.get(`/api/v1/dictionary5861/qrefTucode4936/list`, params)
      .pipe(this.withSessionStorage<ICodeName>('qrefTucode4936'));
  }

  public getQrefTucode4077List(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefTucode4077/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefTucode4077'));
  }

  public getQrefPidTerUchList(params?): Observable<Page<QrefPidTerUchModel>> {
    return this.api.get(`/api/v1/dictionary5861/qrefPidTerUch/list`, params)
      .pipe(this.withSessionStorage<QrefPidTerUchModel>('qrefPidTerUch'));
  }

  public getQrefPipdl4936List(params?): Observable<Page<QrefPipdl4936Model>> {
    return this.api.get(`/api/v1/dictionary5861/qrefPipdl4936/list`, params)
      .pipe(this.withSessionStorage<QrefPipdl4936Model>('qrefPipdl4936'));
  }

  public getQrefFltype4936List(params?): Observable<Page<QrefFltype4936Model>> {
    return this.api.get(`/api/v1/dictionary5861/qrefFltype4936/list`, params)
      .pipe(this.withSessionStorage<QrefFltype4936Model>('qrefFltype4936'));
  }

  public getQrefPrizndoc4936List(params?): Observable<Page<ICodeName>> {
    return this.api.get(`/api/v1/dictionary5861/qrefPrizndoc4936/list`, params)
      .pipe(this.withSessionStorage<ICodeName>('qrefPrizndoc4936'));
  }

  public getQrefDokt4936List(params?): Observable<Page<QrefDokt4936Model>> {
    return this.api.get(`/api/v1/dictionary5861/qrefDokt4936/list`, params)
      .pipe(this.withSessionStorage<QrefDokt4936Model>('qrefDokt4936'));
  }

  public getQrefAction4077List(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefAction4077/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefAction4077'));
  }

  public getQrefAction4936List(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefAction4936/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefAction4936'));
  }

  public getQrefPd5861List(params?): Observable<Page<QrefPd5861Model>> {
    return this.api.get(`/api/v1/dictionary5861/qrefPd5861/list`, params)
      .pipe(this.withSessionStorage<QrefPd5861Model>('qrefPd5861'));
  }

  public getQrefCodUch4077List(params?): Observable<Page<ICodeDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefCodUch4077/list`, params)
      .pipe(this.withSessionStorage<ICodeDescription>('qrefCodUch4077'));
  }

  public getQrefCoduch4936List(params?): Observable<Page<ICodeName>> {
    return this.api.get(`/api/v1/dictionary5861/qrefCoduch4936/list`, params)
      .pipe(this.withSessionStorage<ICodeName>('qrefCoduch4936'));
  }

  public getQrefBArxList(params?): Observable<Page<IQrefBArx>> {
    return this.api.get(`/api/v1/dictionary5861/qrefBArx/list`, params)
      .pipe(this.withSessionStorage<IQrefBArx>('qrefBArx'));
  }

  public getQrefBelongingAccountsList(params?): Observable<Page<IQrefBelongingAccounts>> {
    return this.api.get(`/api/v1/dictionary5861/qrefBelongingAccounts/list`, params)
      .pipe(this.withSessionStorage<IQrefBelongingAccounts>('qrefBelongingAccounts'));
  }

  public getQrefBnkdelList(params?): Observable<Page<QrefBnkdelModel>> {
    return this.api.get(`/api/v1/dictionary5861/qrefBnkdel/list`, params)
      .pipe(this.withSessionStorage<QrefBnkdelModel>('qrefBnkdel'));
  }

  public getQrefCardNotlegacyList(params?): Observable<Page<IQrefCardNotlegacy>> {
    return this.api.get(`/api/v1/dictionary5861/qrefCardNotlegacy/list`, params)
      .pipe(this.withSessionStorage<IQrefCardNotlegacy>('qrefCardNotlegacy'));
  }

  public getQrefClientinfoList(params?): Observable<Page<IQrefClientinfo>> {
    return this.api.get(`/api/v1/dictionary5861/qrefClientinfo/list`, params)
      .pipe(this.withSessionStorage<IQrefClientinfo>('qrefClientinfo'));
  }

  public getQrefCheckppassportList(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefCheckppassport/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefCheckppassport'));
  }

  public getQrefClienttypeList(params?): Observable<Page<IIdType>> {
    return this.api.get(`/api/v1/dictionary5861/qrefClienttype/list`, params)
      .pipe(this.withSessionStorage<IIdType>('qrefClienttype'));
  }

  public getQrefCodmerList(params?): Observable<Page<IQrefCodmer>> {
    return this.api.get(`/api/v1/dictionary5861/qrefCodmer/list`, params)
      .pipe(this.withSessionStorage<IQrefCodmer>('qrefCodmer'));
  }

  public getQrefConsolidatedListList(params?): Observable<Page<IQrefConsolidatedList>> {
    return this.api.get(`/api/v1/dictionary5861/qrefConsolidatedList/list`, params)
      .pipe(this.withSessionStorage<IQrefConsolidatedList>('qrefConsolidatedList'));
  }

  public getQrefDmet4936List(params?): Observable<Page<ICodeName>> {
    return this.api.get(`/api/v1/dictionary5861/qrefDmet4936/list`, params)
      .pipe(this.withSessionStorage<ICodeName>('qrefDmet4936'));
  }

  public getQrefDocTypeList(params?): Observable<Page<IIdType>> {
    return this.api.get(`/api/v1/dictionary5861/qrefDocType/list`, params)
      .pipe(this.withSessionStorage<IIdType>('qrefDocType'));
  }

  public getQrefDokt245FmList(params?): Observable<Page<IQrefDokt245Fm>> {
    return this.api.get(`/api/v1/dictionary5861/qrefDokt245Fm/list`, params)
      .pipe(this.withSessionStorage<IQrefDokt245Fm>('qrefDokt245Fm'));
  }

  public getQrefExtorgsList(params?): Observable<Page<IQrefExtorgs>> {
    return this.api.get(`/api/v1/dictionary5861/qrefExtorgs/list`, params)
      .pipe(this.withSessionStorage<IQrefExtorgs>('qrefExtorgs'));
  }

  public getQrefFArxList(params?): Observable<Page<IQrefFArx>> {
    return this.api.get(`/api/v1/dictionary5861/qrefFArx/list`, params)
      .pipe(this.withSessionStorage<IQrefFArx>('qrefFArx'));
  }

  public getQrefFamilyRelList(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefFamilyRel/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefFamilyRel'));
  }

  public getQrefFltypeList(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefFltype/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefFltype'));
  }

  public getQrefInboultypeList(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefInboultype/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefInboultype'));
  }

  public getQrefIndlList(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefIndl/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefIndl'));
  }

  public getQrefInfOtkazList(params?): Observable<Page<IQrefInfOtkaz>> {
    return this.api.get(`/api/v1/dictionary5861/qrefInfOtkaz/list`, params)
      .pipe(this.withSessionStorage<IQrefInfOtkaz>('qrefInfOtkaz'));
  }

  public getQrefInfomerList(params?): Observable<Page<IQrefInfomer>> {
    return this.api.get(`/api/v1/dictionary5861/qrefInfomer/list`, params)
      .pipe(this.withSessionStorage<IQrefInfomer>('qrefInfomer'));
  }

  public getQrefIssuertypeList(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefIssuertype/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefIssuertype'));
  }

  public getQrefKoPrpodList(params?): Observable<Page<IQrefKoPrpod>> {
    return this.api.get(`/api/v1/dictionary5861/qrefKoPrpod/list`, params)
      .pipe(this.withSessionStorage<IQrefKoPrpod>('qrefKoPrpod'));
  }

  public getQrefKodosnov4936List(params?): Observable<Page<ICodeName>> {
    return this.api.get(`/api/v1/dictionary5861/qrefKodosnov4936/list`, params)
      .pipe(this.withSessionStorage<ICodeName>('qrefKodosnov4936'));
  }

  public getQrefKodtypesecList(params?): Observable<Page<ICodeDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefKodtypesec/list`, params)
      .pipe(this.withSessionStorage<ICodeDescription>('qrefKodtypesec'));
  }

  public getQrefNationalVidList(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefNationalVid/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefNationalVid'));
  }

  public getQrefNationaltypeList(params?): Observable<Page<IIdType>> {
    return this.api.get(`/api/v1/dictionary5861/qrefNationaltype/list`, params)
      .pipe(this.withSessionStorage<IIdType>('qrefNationaltype'));
  }

  public getQrefNlsDepositList(params?): Observable<Page<ICodeDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefNlsDeposit/list`, params)
      .pipe(this.withSessionStorage<ICodeDescription>('qrefNlsDeposit'));
  }

  public getQrefNoList(params?): Observable<Page<ICodeDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefNo/list`, params)
      .pipe(this.withSessionStorage<ICodeDescription>('qrefNo'));
  }

  public getQrefOkopfList(params?): Observable<Page<IIdType>> {
    return this.api.get(`/api/v1/dictionary5861/qrefOkopf/list`, params)
      .pipe(this.withSessionStorage<IIdType>('qrefOkopf'));
  }
  /**
   * @deprecated справочник на удаление
   */
  public getQrefOrgAttribList(params?): Observable<Page<IQrefOrgAttrib>> {
    return this.api.get(`/api/v1/dictionary5861/qrefOrgAttrib/list`, params)
      .pipe(this.withSessionStorage<IQrefOrgAttrib>('qrefOrgAttrib'));
  }

  public getQrefOrgsList(params?): Observable<Page<IQrefOrgs>> {
    return this.api.get(`/api/v1/dictionary5861/qrefOrgs/list`, params)
      .pipe(this.withSessionStorage<IQrefOrgs>('qrefOrgs'));
  }

  public getQrefOtkazUchList(params?): Observable<Page<IQrefOtkazUch>> {
    return this.api.get(`/api/v1/dictionary5861/qrefOtkazUch/list`, params)
      .pipe(this.withSessionStorage<IQrefOtkazUch>('qrefOtkazUch'));
  }

  public getQrefPartList(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefPart/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefPart'));
  }

  public getQrefPipdlList(params?): Observable<Page<IIdCodeDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefPipdl/list`, params)
      .pipe(this.withSessionStorage<IIdCodeDescription>('qrefPipdl'));
  }

  public getQrefPnbsList(params?): Observable<Page<IIdCodeDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefPnbs/list`, params)
      .pipe(this.withSessionStorage<IIdCodeDescription>('qrefPnbs'));
  }

  public getQrefPodsList(params?): Observable<Page<IIdCodeDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefPods/list`, params)
      .pipe(this.withSessionStorage<IIdCodeDescription>('qrefPods'));
  }

  public getQrefPods4936List(params?): Observable<Page<ICodeName>> {
    return this.api.get(`/api/v1/dictionary5861/qrefPods4936/list`, params)
      .pipe(this.withSessionStorage<ICodeName>('qrefPods4936'));
  }

  public getQrefPrichOtkazList(params?): Observable<Page<ICodeDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefPrichOtkaz/list`, params)
      .pipe(this.withSessionStorage<ICodeDescription>('qrefPrichOtkaz'));
  }

  public getQrefPristList(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefPrist/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefPrist'));
  }

  public getQrefPrizesp4936List(params?): Observable<Page<ICodeName>> {
    return this.api.get(`/api/v1/dictionary5861/qrefPrizesp4936/list`, params)
      .pipe(this.withSessionStorage<ICodeName>('qrefPrizesp4936'));
  }

  public getQrefPrizninfList(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefPrizninf/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefPrizninf'));
  }

  public getQrefPrpod5599List(params?): Observable<Page<IQrefPrpod5599>> {
    return this.api.get(`/api/v1/dictionary5861/qrefPrpod5599/list`, params)
      .pipe(this.withSessionStorage<IQrefPrpod5599>('qrefPrpod5599'));
  }

  public getQrefRdlList(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefRdl/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefRdl'));
  }

  public getQrefRefusList(params?): Observable<Page<ICodeDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefRefus/list`, params)
      .pipe(this.withSessionStorage<ICodeDescription>('qrefRefus'));
  }

  public getQrefRiskList(params?): Observable<Page<ICodeDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefRisk/list`, params)
      .pipe(this.withSessionStorage<ICodeDescription>('qrefRisk'));
  }

  public getQrefSearchdocforfsfmList(params?): Observable<Page<IQrefSearchdocforfsfm>> {
    return this.api.get(`/api/v1/dictionary5861/qrefSearchdocforfsfm/list`, params)
      .pipe(this.withSessionStorage<IQrefSearchdocforfsfm>('qrefSearchdocforfsfm'));
  }

  public getQrefTerNotlegacyList(params?): Observable<Page<IQrefTerNotlegacy>> {
    return this.api.get(`/api/v1/cls/ctfb5016/list`, params)
      .pipe(this.withSessionStorage<IQrefTerNotlegacy>('qrefTerNotlegacy'));
  }

  public getQrefTerror4936List(params?): Observable<Page<ICodeName>> {
    return this.api.get(`/api/v1/dictionary5861/qrefTerror4936/list`, params)
      .pipe(this.withSessionStorage<ICodeName>('qrefTerror4936'));
  }

  public getQrefTypeblockds4936List(params?): Observable<Page<ICodeName>> {
    return this.api.get(`/api/v1/dictionary5861/qrefTypeblockds4936/list`, params)
      .pipe(this.withSessionStorage<ICodeName>('qrefTypeblockds4936'));
  }

  public getQrefTypeinfo3484010206List(params?): Observable<Page<ICodeDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefTypeinfo3484010206/list`, params)
      .pipe(this.withSessionStorage<ICodeDescription>('qrefTypeinfo3484010206'));
  }

  public getQrefTypeoperator4936List(params?): Observable<Page<ICodeName>> {
    return this.api.get(`/api/v1/dictionary5861/qrefTypeoperator4936/list`, params)
      .pipe(this.withSessionStorage<ICodeName>('qrefTypeoperator4936'));
  }

  public getQrefUchopList(params?): Observable<Page<ICodeDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefUchop/list`, params)
      .pipe(this.withSessionStorage<ICodeDescription>('qrefUchop'));
  }

  public getQrefUprtypeList(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefUprtype/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefUprtype'));
  }

  public getQrefUprtypeEdList(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefUprtypeEd/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefUprtypeEd'));
  }

  public getQrefUprtypeKollList(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefUprtypeKoll/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefUprtypeKoll'));
  }

  public getQrefVidInfoList(params?): Observable<Page<ICodeDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefVidInfo/list`, params)
      .pipe(this.withSessionStorage<ICodeDescription>('qrefVidInfo'));
  }

  public getQrefViddocosnovList(params?): Observable<Page<IQrefViddocosnov>> {
    return this.api.get(`/api/v1/dictionary5861/qrefViddocosnov/list`, params)
      .pipe(this.withSessionStorage<IQrefViddocosnov>('qrefViddocosnov'));
  }

  public getQrefViddocosnov4936List(params?): Observable<Page<IQrefViddocosnov4936>> {
    return this.api.get(`/api/v1/dictionary5861/qrefViddocosnov4936/list`, params)
      .pipe(this.withSessionStorage<IQrefViddocosnov4936>('qrefViddocosnov4936'));
  }

  public getQrefVidmerList(params?): Observable<Page<IIdCodeDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefVidmer/list`, params)
      .pipe(this.withSessionStorage<IIdCodeDescription>('qrefVidmer'));
  }

  public getQrefVidpds4936List(params?): Observable<Page<ICodeName>> {
    return this.api.get(`/api/v1/dictionary5861/qrefVidpds4936/list`, params)
      .pipe(this.withSessionStorage<ICodeName>('qrefVidpds4936'));
  }

  public getQrefVo4936List(params?): Observable<Page<ICodeName>> {
    return this.api.get(`/api/v1/dictionary5861/qrefVo4936/list`, params)
      .pipe(this.withSessionStorage<ICodeName>('qrefVo4936'));
  }

  public getQrefZr5861List(params?): Observable<Page<ICodeDescription>> {
    return this.api.get(`/api/v1/dictionary5861/qrefZr5861/list`, params)
      .pipe(this.withSessionStorage<ICodeDescription>('qrefZr5861'));
  }

  //методы для работы с уполномоченым сотрудником

  getQrefAuthorizedPersonList(params?): Observable<Page<IQrefAuthorizedPerson>> {
    return this.api.get(`/api/v1/dictionary5861/qrefAuthorizedPerson/list`, params);
  }

  deleteAuthorizedPerson(id: string) {
        return this.api.delete(`api/v1/dictionary5861/qrefAuthorizedPerson/${id}`);
    }

  addNewAuthorizedPerson(authorizedPerson: IQrefAuthorizedPerson) {
      return this.api.post(`api/v1/dictionary5861/qrefAuthorizedPerson/add`, authorizedPerson);
  }


  // --------------------------STRATEG----------------------------------//

  public getQrefClienttype213List(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionaryStrateg/qrefClienttype213/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefClienttype213'));
  }

  public getQrefAction443List(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionaryStrateg/qrefAction443/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefAction443'));
  }

  public getConttypeList(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionaryStrateg/qrefConttype/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefConttype'));
  }

  public getVidInfoOpList(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionaryStrateg/qrefVidinfoOp/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefVidinfoOp'));
  }

  public getKoOtkList(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionaryStrateg/qrefKoOtk/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefKoOtk'));
  }

  public getPriostList(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionaryStrateg/qrefPriost/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefPriost'));
  }

  public getPruvedList(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionaryStrateg/qrefPruved/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefPruved'));
  }

  public getPolTypeList(params?): Observable<Page<IIdDescription>> {
    return this.api.get(`/api/v1/dictionaryStrateg/qrefPolType/list`, params)
      .pipe(this.withSessionStorage<IIdDescription>('qrefPolType'));
  }
  // справочники aml-dictionary-response

  public getQrefVidOperList(params?): Observable<Page<IQrefVidOper>> {
    return this.api.get(`/api/v1/dictionaryResponse/qrefVidoper/list`, params);
  }

  public getQrefKodTypeDocList(params?): Observable<Page<IIdCodeDescription>> {
    return this.api.get(`/api/v1/dictionaryResponse/qrefKodTypeDoc/list`, params)
    .pipe(this.withSessionStorage<IIdCodeDescription>('qrefKodTypeDoc'));
  }


  /**
   * Получение наименования кредитной организации(филиала кред.организации)
   */
  public getFilName(regn: string, rnfl?: string): Observable<string> {
    if (!regn) return;
    if (regn?.length > 4 || rnfl?.length > 4)
      return of('Номер(-а) КО заполнены некорректно');

    let url = `/api/v1/dictionary/banc/name/${regn}`;
    if (rnfl && rnfl !== '0')
      url += `/${rnfl}`;
    return this.api.get(url, null, null).pipe(map(data => data.name
    ));
  }
}
