import { Injectable } from '@angular/core';
import {ApiService} from "@amlCore/services";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SignService {

  constructor(private api: ApiService) { }

  /**
   *  Информация по подписи
   */
  signDocumentsInfo(type: string): Observable<any> {
    return type ? this.api.post(`/api/v1/documents/${type}/sign/info`) : this.api.post(`/api/v1/documents/sign/info`);
  }

  /**
   *  Сигнал о завершении подписи (закрытие окна)
   */
  signDocumentsEnd(type: string): void {
    type ? this.api.post(`/api/v1/documents/${type}/sign/end`).subscribe(data => {}) :
        this.api.post(`/api/v1/documents/sign/end`).subscribe(data => {});
  }

  /**
   *  Экспорт результатов подписи документов
   */
  downloadSignResults(type:string,params?: any) {
    let url = type ? `/api/v1/documents/${type}/sign/download` : `/api/v1/documents/sign/download`;
    if (params) {
      url += `?docId=${encodeURIComponent(params)}`;
    }
    return this.api.byte(url);
  }
}
