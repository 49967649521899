import {DossierDate, DossierT60, DossierT100, DossierT200} from "./base.model";

export class UpdateCardInfo {
  dateEditPodFt: DossierDate = '';
  fio: DossierT100 = '';
  position: DossierT60 = '';
  reason: DossierT200= '';
  userId: DossierT100= '';
  row: number;
}
