export class QrefCbBnkseekModel {
  pzn: string;
  uer: string;
  rgn: string;
  ind: string;
  tnp: string;
  nnp: string;
  adr: string;
  rkc: string;
  namep: string;
  namen: string;
  newnum: string;
  newks: string;
  permfo: string;
  srok: string;
  at1: string;
  at2: string;
  telef: string;
  regn: string;
  okpo: string;
  cks: string;
  ksnp: string;
  vkey: string;
  vkeydel: string;
  dtIzm: string;
  dateIn: string;
  dateCh: string;
  real: string;

  constructor() {
  }
}

