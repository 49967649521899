import { Injectable } from '@angular/core';
import {ShortRole} from "@amlCore/models";
import {getSectionByPageName} from "@amlCore/utils";

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  
  private roles: ShortRole[] = []
  constructor() { }
  
  setUserRoles(roles: ShortRole[]) {
    this.roles = roles
  }
  
  get userRoles() {
    return this.roles
  }
  
  getIsReadOnlyRole(pageName: string) {
    const pageId = getSectionByPageName(pageName)
    const userRole = this.roles.find(item => item.id === pageId)
    if (userRole) {
      return userRole.readOnly
    }
    return false
  }
}
