import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from "@angular/forms";
import {DictionariesService} from "../../../service";
import {Observable} from "rxjs";

@Component({
  selector: 'app-dict-qref-banc',
  template: `
    <div [formGroup]="formGroupApp">
      <div class="mb-3 col-6 pr-0 pl-0">
        <label class="form-label">
          {{isInfBank
            ? 'Наименование кредитной организации (филиала кредитной организации)'
            : 'Наименование филиала кредитной организации'}}</label>
        <input class="form-control"
               type="text"
               [ngModel]="dictionary | async"
               [ngModelOptions]="{standalone: true}"
               [disabled]="true">
      </div>
      <div class="mb-3 col-6 pl-0 pr-0">
        <label class="form-label" style="width: max-content">Регистрационный номер кредитной организации
          <app-rq [isReadOnly]="isReadOnly" *ngIf="isFieldReq('regNomKO')"></app-rq>
        </label>
        <input #regNomKO
               type="text"
               class="form-control"
               maxlength="4"
               formControlName="regNomKO">
        <app-valid [logic]="field('regNomKO')" [submitted]="submitted"></app-valid>
      </div>
      <div class="mb-3 row justify-content-start">
        <div class="col-3 pr-0">
          <label class="form-label" style="width: max-content">
            {{isInfBank ? 'Номер' : 'Порядковый номер'}} филиала кредитной организации
            <app-rq [isReadOnly]="isReadOnly" *ngIf="isFieldReq(isInfBank ? 'nomFlPeredaiushchij' : 'nomFl')"></app-rq>
          </label>
          <input type="text"
                 class="form-control"
                 maxlength="4"
                 [formControlName]="isInfBank ? 'nomFlPeredaiushchij' : 'nomFl'">
          <app-valid [logic]="field(isInfBank ? 'nomFlPeredaiushchij' : 'nomFl')" [submitted]="submitted"></app-valid>
        </div>
        <button *ngIf="!isReadOnly"
                type="button"
                class="mt-auto ml-4 btn btn-outline-success"
                (click)="updateDictionary()">Заполнить Наименование Филиала
        </button>
      </div>
    </div>
  `
})
export class QrefBancNameViewComponent implements OnInit {
  @Input() formGroupApp: FormGroup;
  @Input() isInfBank: boolean;
  @Input() isReadOnly: boolean;
  @Input() submitted: boolean;
  dictionary: Observable<string>;
  constructor(private dictSrv: DictionariesService) {}

  ngOnInit() {
    this.updateDictionary();
    this.subscribeToValueChanges('regNomKO');
    this.subscribeToValueChanges('nomFlPeredaiushchij');
    this.subscribeToValueChanges('nomFl');
  }

  updateDictionary() {
    this.formGroupApp?.get("naimKO")?.patchValue(null);
    this.dictionary = this.dictSrv.getFilName(
      this.field('regNomKO').value,
      this.field(this.isInfBank ? 'nomFlPeredaiushchij' : 'nomFl').value
    );
    this.dictionary.subscribe((v) => {
      this.formGroupApp?.get("naimKO")?.patchValue(v);
    });
  }

  isFieldReq = (field: FormControl | string) => {
    return typeof field === 'string'
      ? this.isFieldReq(this.formGroupApp.get(field) as FormControl)
      : Boolean(field?.validator && field?.validator({} as AbstractControl)?.required);
  }

  field<T extends AbstractControl>(fieldName: string): T {
    return this.formGroupApp.get(fieldName) as T;
  }

  subscribeToValueChanges(controlName: string) {
    this.formGroupApp?.get(controlName)?.valueChanges.subscribe((v) => {
      this.dictionary = null;
      this.formGroupApp?.get("naimKO")?.patchValue(null);
    });
  }

}
