import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FiasAddressModel, FiasModel} from "@amlCore/models";
import {Observable, of, Subscription} from "rxjs";
import {FiasService} from "./services";

@Component({
  selector: 'app-fias-select',
  template: `
    <div *ngIf="isRu && !isReadOnly">
      <button (click)="showFiasSelect = !showFiasSelect"
              class="btn btn-link pl-0 mb-1">{{showFiasSelect ? 'Закрыть поиск по ФИАС' : 'Найти адрес в справочнике ФИАС?'}}</button>
      <div [hidden]="!showFiasSelect">
        <ng-select #selectFias [items]="items"
                   class="fias-select"
                   bindLabel="$modfullname"
                   [loading]="loader"
                   [loadingText]="'Идет поиск'"
                   (search)="loading($event)"
                   (change)="selected($event)"
                   (blur)="afterBlur($event)"
                   (clear)="clear()"
                   [minTermLength]="4"
                   placeholder="Начните вводить адрес, чтобы запустить поиск по справочнику ФИАС"
                   [searchFn]="customSearchFn"
                   [searchWhileComposing]="false"
                   [editableSearchTerm]="true">
          <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
            <div appHighlight
                 classToApply="aml-highlighted-decorator"
                 [search]="search"
                 [text]="replacer(item.$modfullname)"></div>
          </ng-template>
        </ng-select>


        <button (click)="sendAddress()" *ngIf="selectHouse || selectItem.aoguid" class="btn btn-link  pl-0">Заполнить адрес из ФИАС</button>
      </div>
    </div>
    <!--    <pre> select {{selectItem | json}}</pre>-->
    <!--    <pre> selecthouse {{selectHouse | json}}</pre>-->
  `
})
export class FiasComponent {
  @Input() model: FiasModel;
  @Input() isRu = false;
  @Input() isReadOnly = false;
  @Output('getAddress') getAddress: any = new EventEmitter<any>();
  @ViewChild("selectFias") selectFias: any;
  items: Array<FiasModel> = [];
  loader = false;
  timerId: number | any = undefined;
  subscription: Subscription = undefined;
  selectItem: FiasModel = {} as FiasModel;
  selectHouse: FiasModel = null;
  showFiasSelect = false;

  constructor(private fiasSrv: FiasService) {
  }

  replacer(text: string) {
    return text ? text.replace(/[,]/g, '') : '';
  }

  /**
   * Возвращаем true чтоб не фильтровать внутри существующего списка. А ждаь что пришлет сревре
   */
  customSearchFn() {
    return true;
  }

  /**
   * Отправить адрес для заполнения формы
   */
  sendAddress() {
    this.fiasSrv.getFIASAddress(this.selectItem.aoguid).subscribe((data) => {
        const result: FiasAddressModel = data.result;
        if (this.selectHouse) {
          const strucnum = this.selectHouse.strucnum;
          const strTextType = strucnum && this.selectHouse.strstatus === '1'
            ? this.fiasSrv.getStrStatus(this.selectHouse.strstatus) + strucnum : '';
          result.house = this.selectHouse.housenum;
          result.postalcode = this.selectHouse.postalcode;
          result.building = this.selectHouse.buildnum ? this.selectHouse.buildnum + ' ' + strTextType : strTextType;
        }
        console.log(result);
        this.getAddress.emit(result);
      }
    );
  }

  /**
   * Пока чисто тест. Удаление очищенной строки руками
   */
  afterBlur(el) {
    if (!el.target.value) {
      this.clear();
    }
  }

  /**
   * Полная очистка селекта и его компонентов
   */
  clear() {
    this.selectItem = {} as FiasModel;
    this.selectHouse = null;
    this.items = [];
  }

  /**
   * Выбираем записи из селекта.
   */
  selected($event) {
    if (!$event) {
      return;
    }
    // если в выбранной записи фигурирует дом, занчит мы выбрали номер дома
    if ($event.housenum) {
      this.selectHouse = $event;
    } else {
      this.selectHouse = null;
    }
    // если номер дома не выбран ищем дальше. Делаем уточняющий запрос для фильтрации списка
    if (!this.selectHouse) {
      this.loading({term: $event.fullname + ','});
      this.selectItem = $event;
    }
  }

  /**
   * Подготовливаем запрос, Если запись выбрана, то дополняем фильтр aoguid
   * @param body - тело запроса
   */
  private fillExtendFilter(body) {
    const extendParam = {aoguid: ''};
    if (this.selectItem && this.selectItem.aoguid) {
      extendParam.aoguid = this.selectItem.aoguid;
      Object.assign(body, {searchByField: extendParam});
    }
  }

  /**
   * Проверяем на вхождение полного имени выбранного элемента и поисковой строки.
   * Чтоб отследить факт когда поисковая строка станет отличаться от выбранного элемента
   * После это надо перестать искать дом, т.к ищут уже улицу
   * @param search - поисковый запрос
   * @param selectedFullName - полное имя выбранного элемента (город, край, улица)
   */
  private isSearchEntry(search, selectedFullName) {
    return search.includes(selectedFullName);
  }

  /**
   * Выбираем номер дома из запроса. Для разделение запросов на сервер.
   * @param search - что сейчас вбито в строку поиска
   * @param selected - что уже было выбрано. Важно для поиска дома
   */
  private getHouseNumber(search, selected) {
    // чистим и строки Город, дом, улицу. "Город,удица,23"replace(Город,удица,'') =,23
    // это необходимо сделать иначе улицы могут тоже содержать цифры
    search = search.replace(this.selectItem.fullname, '');
    // достаем из оставшейся строки номер дома (может быть с литерой)
    const matches = search.match(/\d+\S*/i);
    return matches && matches.length ? matches[0] : '';
  }

  /**
   * Производим склеивание строки если в списке пришел дом, склеиваем с предыдущем запросом улицы
   */
  fillFullName(items: Array<FiasModel>) {
    items.forEach((item) => {
      item.$modfullname = item.housenum ? this.selectItem.fullname + ', ' + item.fullname
        : (item.final ? item.fullname + ' ' : item.fullname + ', ');
    });
  }

  /**
   * Загрузка списков ФИАС
   * @param $event - event term - то что сейчас ищется в строке
   */
  loading($event) {
    if (this.timerId !== undefined) {
      window.clearTimeout(this.timerId);
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.loader = false;
    }
    if (!$event.term) {
      this.selectFias.clearModel();
      this.selectFias.clearEvent.emit();
      return;
    }
    this.timerId = setTimeout(() => {
      let body: any;
      let houseNumber = '';
      let observable: Observable<any>;
      body = {search: $event.term};
      this.fillExtendFilter(body);
      if (this.selectItem.aoguid && this.selectItem.final) { // если выбрана улица ,  то ищем дом встроке
        if (this.isSearchEntry(body.search, this.selectItem.fullname)) { // допроверям строки поиска, надо чтоб не удали улицу, город
          houseNumber = this.getHouseNumber(body.search, this.selectItem);
        }
      }
      if (houseNumber) {
        body.search = houseNumber;
        observable = this.fiasSrv.findHouse(body);
      } else {
        delete body.searchByField;
        observable = this.fiasSrv.findObj(body);
      }
      this.loader = true;
      if (!this.tryFindHouse(body)) {
        this.subscription = observable.subscribe(value => {
            this.fillItems(value, !!this.selectItem.aoguid);
          },
          error => this.loader = false
        );
      }
    }, 500);
  }

  fillItems(value, isOpenSelect?: boolean) {
    this.fillFullName(value.items);
    this.items = value.items;
    this.loader = false;
    if (isOpenSelect) {
      if (value.items.length > 0) {
        this.selectFias.open();
      }
    }
  }

  tryFindHouse(body) {
    let result = false;
    if (this.selectItem.aoguid && (this.selectItem.final || this.selectItem.aolevel === 7) && !this.selectHouse) {
      if (this.isSearchEntry(body.search, this.selectItem.fullname)) {
        body.search = '';
        this.fillExtendFilter(body);
        this.subscription = this.fiasSrv.findHouse(body).subscribe(resp => {
          this.fillItems(resp, true);
        });
        result = true;
      }
    }
    return result;
  }

}
