import { Component,
         OnInit,
         OnDestroy,
         forwardRef } from "@angular/core";
import { FormBuilder,
         FormGroup,
         ControlValueAccessor,
         NG_VALUE_ACCESSOR } from "@angular/forms";
import { Unsubscribable } from "rxjs";
import { ClientTypeEnum } from "@amlCore/enums";
import { IDictItemType } from "../../interfaces";
import { DictionaryService } from "@amlCore/services";

@Component({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RegisterSubjectComponent),
      multi: true,
    }
  ],
  selector: "app-register-subject",
  templateUrl: "./registerSubject.component.html",
})

export class RegisterSubjectComponent implements OnInit, OnDestroy, ControlValueAccessor {
  private subjectFormModelSubscrible: Unsubscribable;
  public subjectFormModel: FormGroup;
  public clientTypeEnum = ClientTypeEnum;
  public selectObj = {
    typeSubjectList: [] as IDictItemType[]
  };
  private subjectTypeUnsubscrible: Unsubscribable;

  onChange(result: any) {}
  onTouch() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  constructor(private fb: FormBuilder,
              private dictSrv: DictionaryService) {}

  ngOnInit() {
    this.dictSrv.loadTypeClient().then(result => {
      this.selectObj.typeSubjectList = result.filter(item => item.code != 0);
    });
    this.createSubjectFormModel();
  }

  /*
   * метод сборки и подписки реактивной формы.
   */
  private createSubjectFormModel() {
    const defaultClientTypeId = '1';
    this.subjectFormModel = this.fb.group({
      subjectType: [defaultClientTypeId], // тип субъекта
      legalEntity: this.fb.group({ // Юридическое лицо
        name: [null], // Наименование организации
        inn: [null],  // ИНН
        ogrn: [null], // ОГРН
      }),
      individualPerson: this.fb.group({ // Физицеское лицо
        name: [null],      // ФИО
        inn: [null],       // ИНН
        birthDate: [null], // Дата рождения
        series: [null],     // Серия
        number: [null],    // Номер
      }),
      individualEntrepreneur: this.fb.group({ // Индивидуальный предприниматель
        name: [null], // ФИО
        inn: [null],  // ИНН
        ogrn: [null], // ОГРН
        birthDate: [null], // Дата рождения
        series: [null],     // серия
        number: [null],    // номер
      }),
      foreignStructure: this.fb.group({ // Иностранная структура без образования юридического лица
        name: [null], // Наименование организации
        inboul: [null] // ИНБОЮЛ
      }),
    });

    this.subjectFormModelSubscrible = this.subjectFormModel.valueChanges.subscribe((data) => {
      this.updateValue(data);
    });
    this.subjectTypeUnsubscrible = this.subjectFormModel.get('subjectType').valueChanges.subscribe((typeCode) => {
      this.selectedClientType({type: (typeCode || '')});
    });
  }

  /**
   * Метод выбора тип субъекта
   * @params data object; параметр объект с
   * идентификатором типа клиента
   */
  public selectedClientType(data: {type: string}) {
    const code = data?.type;
    if (!code) {
      return;
    }
    // коллекция сущностей по которой будет проходит цикл
    const listSubjectTypes = [
      "legalEntity",
      "individualEntrepreneur",
      "foreignStructure",
      "individualPerson"
    ];
    /* карта для расшифровки числового
     * идентификатора выбранной сущности субъекта
     */
    const subjectTypeMap = {
      1: "legalEntity",
      3: "individualEntrepreneur",
      5: "foreignStructure"
    };

    const currentSubjectType = (subjectTypeMap[code] || "individualPerson");
    listSubjectTypes
      .filter(type => type !== currentSubjectType)
      .forEach(type => {
        this.subjectFormModel.get(type).disable();
      });
    this.subjectFormModel.get(currentSubjectType).enable();
  }

  writeValue(outsideValue) {
    if (outsideValue) {
      this.subjectFormModel.patchValue(outsideValue);
    } else {
      this.subjectFormModel.reset(this.subjectFormModel.value);
    }
  }

  updateValue(insideValue) {
    this.onChange(insideValue);
  }

  ngOnDestroy() {
    this.subjectFormModelSubscrible.unsubscribe();
    this.subjectTypeUnsubscrible.unsubscribe();
  }
}
