import {Injectable} from "@angular/core";
import {DictionaryService, ResourceService} from "@amlCore/services";

@Injectable()
export class CommonFilterService {
  tryLoaded = {
    accessUserList: false,
  }
  dictionary = this.dictSrv;
  constructor(private dictSrv: DictionaryService,
              private resourceSrv: ResourceService) {
  }

  loadUserAccessList(groupName: string): void {
    if (this.tryLoaded.accessUserList === false && this.dictSrv?.remoteDict?.[groupName]?.list?.length === 0) {
      const customParams = {sort: "fullName", dir: "asc", page: "0", size: "500"};
      this.resourceSrv.getUserList(customParams).subscribe(data => {
        this.dictSrv.remoteDict[groupName].list =  data.content;
      })
    }
    this.tryLoaded.accessUserList = true;
  }
}