import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-loading',
  template: `
    <div *ngIf="view == null" class="justify-content-center loading-wrap" style="text-align: center">
      <div class="spinner-grow text-primary" role="status">
        <span class="sr-only"></span>
      </div>
      <div class="spinner-grow text-secondary" role="status">
        <span class="sr-only"></span>
      </div>
      <div class="spinner-grow text-danger" role="status">
        <span class="sr-only"></span>
      </div>
    </div>`
})
export class LoadingComponent implements OnInit {
  @Input("view") view: any;
  ngOnInit() {
  }
}
