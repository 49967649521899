import {DossierBool, DossierT250} from "./base.model";
import {DictType} from "./dictType.model";
import {FIOType} from "./fioType.model";
import {AddressType} from "./addressType.model";

export class PublicInfoType {
  prv: DossierBool = false;
  characterPDL?: DictType = new DictType();
  rodPDL?: DictType = new DictType();
  fio?: FIOType = new FIOType();
  postRPDL?: DictType = new DictType();
  postOther?: DossierT250 = '';
  post?: DossierT250 = '';
  nameWork?: DossierT250 = '';
  address?: AddressType = new AddressType();
  istDochod?: Array<DictType>;
  otherDochod?: DossierT250 = '';

}
