import {DictType} from "./dictType.model";
import {ClientInfo} from "./clientInfo.model";
import {PartItemArray} from "../partItemArray.model";

export class PartnerType extends PartItemArray{
  clientInfo: ClientInfo = new ClientInfo();
  role: DictType = undefined;
  dateFrom = false;
  dateEnd = false;
}
