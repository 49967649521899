import {Injectable} from "@angular/core";
import {IAlertObj} from "./IAlertObj.interface";

/**
 * Сервис для генерации стандартных объектов для AlertPanelComponent
 */
@Injectable()
export class AlertPanelService {

  /**
   * Сгенерировать объект для успешного сообщения
   * @param msg - сообщение
   * @param close - закрываемый ли объект (по-умолчания - да)
   * @param time - время до автозакрытия (по-умолчанию - 5сек)
   */
  getSuccessMsg(msg: string, close = true, time = 5000): IAlertObj {
    return {
      type: 'success',
      message: msg,
      canClose: close,
      timeToClose: time
    } as IAlertObj;
  }
  /**
   * Сгенерировать объект для НЕ успешного сообщения
   * @param msg - сообщение
   * @param close - закрываемый ли объект (по-умолчания - да)
   * @param time - время до автозакрытия (по-умолчанию - 5сек)
   */
  getErrorMsg(msg: string, close = true, time = 5000): IAlertObj {
    return {
      type: 'danger',
      message: msg,
      canClose: close,
      timeToClose: time
    } as IAlertObj;
  }
  /**
   * Сгенерировать объект информационного сообщения
   * @param msg - сообщение
   * @param close - закрываемый ли объект (по-умолчания - нет)
   * @param time - время до автозакрытия
   */
  getInfoMsg(msg: string, close = false, time?): IAlertObj {
    return {
      type: 'primary',
      message: msg,
      canClose: close,
      timeToClose: time
    } as IAlertObj;
  }
  // TODO: реализовать getAlertObj, который генерирует объект по ответу сервера

}
