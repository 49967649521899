import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-valid',
  template: `
    <div *ngIf="this.logic && this.logic.errors" class="alert-danger rounded mt-1 p-1 app-valid">
      <div *ngIf="withoutSubmitted(validParam.isPattern) && logic.errors.pattern">
        Введенное значение не соответствует формату поля
      </div>
      <div *ngIf="withoutSubmitted(validParam.isEmail) && logic.errors.email">
        Введите корректный адрес электронной почты
      </div>
      <div *ngIf="withoutSubmitted(validParam.isRequired) && logic.errors.required">
        Обязательное поле
      </div>
      <div *ngIf="withoutSubmitted(validParam.isMinLength) && logic.errors.minlength">
        Минимальное количество символов в поле: {{logic.errors.minlength.requiredLength}}
      </div>
      <div *ngIf="withoutSubmitted(validParam.isMaxLength) && logic.errors.maxlength">
        Максимальное количество символов в поле: {{logic.errors.maxlength.requiredLength}}
      </div>
      <div *ngIf="withoutSubmitted(validParam.isMinNumber) && logic.errors.min">
        Минимальное значение поля {{logic.errors.min.min}}
      </div>
      <div *ngIf="withoutSubmitted(validParam.isMaxNumber) && logic.errors.max">
        Максимальное значение поля {{logic.errors.max.max}}
      </div>
      <div *ngIf="withoutSubmitted(logic.errors.customValidator?.withoutSubmitted) && logic.errors.customValidator">
        <div class="customValidator-block">
          <div class="customValidator-text">
            {{logic.errors.customValidator.errorMsg}}
          </div>
          <div *ngIf="logic.errors.customValidator.withBigMsg"
              class="customValidator-icon"
              container="body"
              ngbTooltip="{{logic.errors.customValidator.errorMsgBig}}"
              tooltipClass="aml-tooltip">
            <fa-icon [icon]="icons.info"></fa-icon>
          </div>
        </div>
      </div>
      <div *ngIf="withoutSubmitted(validParam.isContent)">
        <ng-content></ng-content>
      </div>
    </div>`
})
export class ValidComponent implements OnInit {
  @Input() logic: any;
  @Input() submitted: boolean;
  /**
   * Указывать какие проверку запускать сразу (без учета submitted)
   * true - если без учета
   */
  @Input() validParam: ValidParam = {
    isMaxLength: false,
    isMinLength: false,
    isMinNumber: false,
    isMaxNumber: false,
    isPattern: true,
    isRequired: false,
    isContent: false
  };

  icons = {
    info: ['fas', 'info-circle']
  };
  /**
   * Признак, что сообщение об ошибке нужно показывать сразу (без учета submitted)
   * @param value - true если без учета
   */
  withoutSubmitted(value?: any) {
    let submitted = this.submitted;
    if (value) {
      submitted = true;
    }
    return submitted;
  }

  ngOnInit() {
  }

}

/**
 * Параметризация отображения ошибок в компоненте
 */
export class ValidParam {
  isMaxLength?: boolean; // Ошибка максимальной длины
  isMinLength?: boolean; // Ошибка минимальной длины
  isMinNumber?: boolean; // Ошибка минимального значения
  isMaxNumber?: boolean; // Ошибка максимального значения
  isPattern?: boolean; // Ошибка паттерна
  isRequired?: boolean; // Ошибка обязательности
  isContent?: boolean; // Ошибка вставленая в ng-content
  isEmail?: boolean; // Ошибка email
}
