import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ClientInfo} from '../../../../models/dossier';
import {DossierService} from '../../../../services';
import {DossierBasePageComponent} from '../dossierBasePage.component';
import {IDossierSubService} from '../IDossierSub.service';
import {SvedClientDossierService} from './svedClientDossier.service';
import {UserService, DictionaryService} from '@amlCore/services';
import {AlertPanelService} from '@amlCore/components';
import {Utils} from '@amlCore/utils';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FileSaverService} from "ngx-filesaver";


@Component({
  selector: 'app-client-info-dossier',
  styleUrls: ['../dossier.css'],
  templateUrl: './svedClientDossier.component.html'
})
export class SvedClientDossierComponent extends DossierBasePageComponent implements OnInit {
  modelPage: ClientInfo;
  isTableTab = false;

  constructor(protected dossierService: DossierService,
              protected route: ActivatedRoute,
              private clientInfoDossierService: SvedClientDossierService,
              protected dictSrv: DictionaryService,
              protected router: Router,
              protected userService: UserService,
              protected alertPanelSrv: AlertPanelService,
              protected modalService: NgbModal,
              protected fSaver: FileSaverService) {
    super(dossierService, route, dictSrv, router, userService, alertPanelSrv, modalService, fSaver);
  }
  ngOnInit() {
    // Инициализация вкладки
    this.modelPage = this.initPage();
    this.focusObject = {
      svedClient: this.generateFocusObject(new ClientInfo())
    };
  }

  /**
   * Инициализация модели
   * Преобразуем в нужный формат
   * @param model - данные сервера
   */
  initModel(model) {
    let newModel;
    this.partId = null;
    if (model) {
      const partIdList: string[] = Object.keys(model);
      newModel = model[partIdList[0]];
      Utils.deleteEmptyObj(newModel);
      if(!newModel.hasOwnProperty('rezidentFS')){
         newModel.rezidentFS = null;
      }
    } else {
      newModel = null;
    }
    const formData = {};
    formData[this.typePage] = newModel;
    return formData;
  }

  getService(): IDossierSubService {
    return this.clientInfoDossierService;
  }

  destroy() {
    // ТУТ писать реализацию ngOnDestroy для компонента
  }
}
