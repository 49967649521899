import {DictType} from "./dictType.model";
import {DossierDate, DossierT100, DossierT250} from "./base.model";
import {ClientInfo} from "./clientInfo.model";
import {PartItemArray} from "../partItemArray.model";

export class SvedIOUInfoType extends PartItemArray{
  type: DictType = undefined;
  kolOU: DictType = undefined;
  otherOU: DossierT250 = '';
  post: DossierT250 = '';
  dateResh: DossierDate = '';
  numberResh: DossierT100 = '';
  clientInfo: ClientInfo = new ClientInfo();
  dateFrom = false;
  dateEnd = false;
}
