import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CryptBase} from "./core";
import {CryptGuard, CryptographService} from "./services";
import {CryptoValidataService} from "./validata/services";
import {CryptValidataCheckStateDirective} from "./validata/directives";


@NgModule({
  declarations: [
    CryptValidataCheckStateDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CryptValidataCheckStateDirective
  ],
  providers: []
})
export class CryptographyModule {
  /**
   * Для lazy-module создается новый экземпляр сервиса. Данная конструкция необходима что сервис был один на все модули
   * https://angular.io/guide/singleton-services
   */
  static forRoot(): ModuleWithProviders<CryptographyModule> {
    return {
      ngModule: CryptographyModule,
      providers: [
        {provide: CryptographService},
        {provide: CryptoValidataService},
        {provide: CryptGuard},
        {provide: CryptBase}
      ]
    };
  }
}
