import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DossierService} from "../../../../services";
import {DossierBasePageComponent} from "../dossierBasePage.component";
import {IDossierSubService} from "../IDossierSub.service";
import {BankAccountsDossierService} from "./bankAccountsDossier.service";
import {AccountDossierComponent} from "../components/account";
import {UserService, DictionaryService} from "@amlCore/services";
import {AlertPanelService, ITable, ITableCallBack, ITableItemDescriptor} from "@amlCore/components";
import {AccountType} from "../../../../models/dossier";
import {PartItemArray} from '../../../../models';
import {Utils} from "@amlCore/utils";
import {ErrorFLKModel} from "@amlCore/models";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FileSaverService} from "ngx-filesaver";


@Component({
  selector: 'app-bank-accounts-dossier',
  styleUrls: ['../dossier.css'],
  templateUrl: './bankAccountsDossier.component.html'
})
export class BankAccountsDossierComponent extends DossierBasePageComponent implements OnInit, ITable {

  modelPage: Array<AccountType>;
  itemDescriptor: ITableItemDescriptor;
  isTableTab = true;

  constructor(protected dossierService: DossierService,
              protected route: ActivatedRoute,
              private bankAccountsDossierService: BankAccountsDossierService,
              protected dictSrv: DictionaryService,
              protected router: Router,
              protected userService: UserService,
              protected alertPanelSrv: AlertPanelService,
              protected modalService: NgbModal,
              protected fSaver: FileSaverService) {
    super(dossierService, route, dictSrv, router, userService, alertPanelSrv, modalService, fSaver);
  }

  ngOnInit() {
    // Инициализация вкладки
    this.modelPage = this.initPage();
    // Описание элемента таблицы
    this.itemDescriptor = {
      create: this.bankAccountsDossierService.getAccountType.bind(this.bankAccountsDossierService),
      component: AccountDossierComponent,
      params: {
        typePage: this.typePage
      }
    } as ITableItemDescriptor;
  }

  /**
   * Инициализация модели
   * Преобразуем в нужный формат
   * @param model - данные сервера
   */
  initModel(model) {
    let newModel;
    this.partId = null;
    if (model) {
      const partIdList: string[] = Object.keys(model);
      newModel = model[partIdList[0]];
      this.partId = partIdList[0];
      Utils.deleteEmptyObj(newModel);
    } else {
      newModel = [];
    }
    const formData = {};
    formData[this.typePage] = newModel;
    return formData;
  }

  getService(): IDossierSubService {
    return this.bankAccountsDossierService;
  }

  callbackItemClosed(event: ITableCallBack) {
    if (event.editIndex != null) {
      this.saveDossierPage();
    }
  }

  deleteItem(event: ITableCallBack) {
    this.saveDossierPage();
  }

  destroy() {
    // ТУТ писать реализацию ngOnDestroy для компонента

  }

    /*TODO в дальнейшем возмонжо нужно будет сделать
    setErrorTableTab абстрактным методом и реализовать его в каждом классе компонента
    где наследуется DossierBasePageComponent так как появляются разделы с уникальной
    логикой простановки ошибок для таблицы вкладки.*/

  // @ovveride setErrorTableTab
  setErrorTableTab(key, pageError: ErrorFLKModel) {
    const form = this.f(this.typePage);
    const values = form.value as PartItemArray[];
    const rowErrorKeys = Object.keys(pageError.items);
    rowErrorKeys.forEach(row => {
      const item = values.find(formRowItem => formRowItem['row'] === parseInt(row));
      if (item) {
        item.$checkedFlk = true;
      }
    });
    form.patchValue(values);
  }


}
