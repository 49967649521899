import {DossierDate, DossierT250, DossierT50} from "./base.model";

export class AuthorityType {
  docName: DossierT250 = '';
  series?: DossierT50 = '';
  number: DossierT50 = '';
  dateReg: DossierDate = '';
  dateStart: DossierDate = '';
  dateEnd: DossierDate = '';
  stopPowers?: number;
}
