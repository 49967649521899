import {Component, OnInit} from "@angular/core";
import {DossierService} from "../../../../../services";
import {AlertPanelService, ICustomSelectMapper, ITableEditorItem, ITableItem} from "@amlCore/components";
import {AccountType, DictType} from "../../../../../models/dossier";
import {DictionaryService} from "@amlCore/services";
import {FormGroup} from "@angular/forms";
import {DossierBaseModel} from "../../dossierBaseModel";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'app-account-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './accountDossier.component.html'
})
export class AccountDossierComponent extends DossierBaseSubComponent implements OnInit, ITableItem {

  titlePanel = 'Реквизиты счета, открытого в банке';
  showTitle = true;
  accountTypeList: Array<DictType> = [];
  currencyMapper: ICustomSelectMapper;
  isTableItem = false;
  $destroy = new Subject();

  constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }
  /**
   * Описание формы
   */
  getFormDescription(): FormGroup {
    return this.dossierBaseModel.getAccountType();
  }

  /**
   * Инициализация FormGroup
   */
  initForm() {
    if (!this.isTableItem) {
      this.formGroupApp = this.getFormDescription();
    }
  }

  ngOnInit() {
    this.accountTypeList = this.dictSrv.getStaticData('accountType');
    this.loadRemoteData(['okv']);
    this.focusObject = this.generateFocusObject(new AccountType());
    this.currencyMapper = {
      toModel: this.currencyMapperToModel.bind(this)
    } as ICustomSelectMapper;
    this.f('paycards').valueChanges.pipe(takeUntil(this.$destroy)).subscribe(value => {
      this.formGroupApp.get('paycard')[value ? 'enable' : 'disable']();
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  setSubmitted(value: boolean) {
    this.submitted = value;
  }

  getTitle(): string {
    return this.titlePanel;
  }

  currencyMapperToModel(origin: any, isDictData: boolean): any {
    if (isDictData) {
      return {
        code: origin.iso_dig,
        name: origin.name
      };
    } else {
      return origin;
    }
  }

  /**
   * Инициализируем компонент как элемент формы просмотра таблицы
   * @param param.form - форма для связи
   * @param param.params - дополнительные параметры
   */
  initTableItem(param: ITableEditorItem): FormGroup {
    this.initForm();
    this.isTableItem = true;
    if (param.model) {
      this.formGroupApp.patchValue(param.model);
    }
    this.showTitle = false;
    if (param.params && param.params.typePage) {
      this.typePage = param.params.typePage;
    } else {
      console.log('Необходимо указать typePage у компонента');
    }
    this.isReadOnly = param.isReadOnly;
    if (this.isReadOnly){
      this.formGroupApp.disable();
    }
    return this.formGroupApp;
  }

}
