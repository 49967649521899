import { TransactionSubject } from "./transactionSubject.model";

/*
 * Класс транзакции
 */

export class Transaction {
  public amountCurr: number | string; // Сумма операции в валюте
  public amountRub: number | string;  // Сумма операции в рублях
  public currency: string;   // Код валюты
  public currencyName: string; // Наименование валюты
  public date: string;       // Дата совершения операции
  public dateDoc: string;    // Дата документа
  public espType: string;    // Использование электронного средства платежа
  public id: string;         // Уникальный идентификатор транзакции генерируемый на сервере
  public numberDoc: string;  // Номер документа
  public operId: string;     // Уникальный номер операции
  public operationCode: string; // Код вида операции
  public operationName: string; // Наименование вида операции
  public purpose: string;       // Назначение платежа
  public state: string;         // Статус операции
  public type: string;          // Вид операции (Код)
  public currCashType: string;      // Вид операции с наличной валютой
  public typeDoc: string;       // Код типа документа
  public typeDocName: string;   // Наименование типа документа
  public userName: string;      // Пользователь
  public payer: TransactionSubject; // Плательщик
  public recipient: TransactionSubject; // Получатель

  constructor() {
    this.amountCurr = 0;
    this.amountRub = 0;
    this.currency = "";
    this.currencyName = "";
    this.date = "";
    this.dateDoc = "";
    this.espType = "";
    this.id = "";
    this.numberDoc = "";
    this.operId = "";
    this.operationCode = "";
    this.operationName = "";
    this.purpose = "";
    this.state = "";
    this.type = "";
    this.currCashType = "";
    this.typeDoc = "";
    this.typeDocName = "";
    this.userName = "";
    this.payer = new TransactionSubject();
    this.recipient = new TransactionSubject();
  }
}
