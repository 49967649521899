import {Component, Input} from "@angular/core";
import {IAlertObj} from "./IAlertObj.interface";


/**
 * Типизированя информационная панель
 * ИСПОЛЬЗОВАНИЕ:
 * Определить в компоненте:
 * @ViewChild('alertObjXXX', {static: true}) alertObjToXXX: AlertPanelComponent;
 * В шаблоне комопнента:
 * <app-alert-panel #alertObjXXX></app-alert-panel>
 * ------------------------------
 * Запускать с помощью специального объекта типа IAlertObj:
 *  this.alertObjToXXX.open(obj);
 * Для стандратных сообщений ИСПОЛЬЗОВАТЬ специальный сервис AlertPanelService
 * * this.alertObjToXXX.open(this.alertPanelSrv.getSuccessMsg('Успешное сообщение'))
 * * this.alertObjToXXX.open(this.alertPanelSrv.getErrorMsg('Не успешное сообщение'))
 * * this.alertObjToXXX.open(this.alertPanelSrv.getInfoMsg('Информационное сообщение'))
 */
@Component({
  selector: 'app-alert-panel',
  templateUrl: './alertPanel.component.html',
})
export class AlertPanelComponent {
  show = false;
  @Input() alertObj: IAlertObj;

  model: IAlertObj;

  constructor() {
  }

  open(obj: IAlertObj) {
    this.show = true;
    this.model = obj;
    if (obj.canClose) {
      const timeToClose = obj.timeToClose ? obj.timeToClose : 5000;
      setTimeout(() => this.show = false, timeToClose);
    }
  }

  close() {
    this.show = false;
    this.model = null;
  }
}


