import {Component, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {DossierService} from "../../../../../services";
import {FormControl} from "@angular/forms";
import {DictionaryService} from "@amlCore/services";
import {Unsubscribable} from "rxjs";
import {DossierBaseModel} from "../../dossierBaseModel";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {LicenseInfoDossierComponent} from "../licenseInfo";
import {ErrorFLKItemType} from "@amlCore/models";
import {AlertPanelService} from "@amlCore/components";
import {DossierAccessEnum} from "@amlCore/enums";

@Component({
  selector: 'app-sved-ip-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './svedIPDossier.component.html'
})
export class SvedIPDossierComponent extends DossierBaseSubComponent implements OnInit, OnDestroy {


  @Input() rezidentRf: FormControl;
  isOkvedShow = false;
  isLicenseInfoShow = false;
  rezidentRfSub: Unsubscribable;
  @ViewChild('licenseInfo') licenseInfo: LicenseInfoDossierComponent;

  constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  ngOnInit(): void {
    this.initTypePage();
    // TODO: Убрал возможно старая доработка - уточнить по https://jira.comita.lan/browse/AML-136
    // Шаблон [hidden]="!rezidentRf?.value"
    // setTimeout(() => {
    //   if (this.rezidentRf) {
    //     this.rezidentRfSub = this.rezidentRf.valueChanges.subscribe(value => {
    //       this.initRezidentRf(value);
    //     });
    //     this.initRezidentRf(this.rezidentRf.value);
    //     this.addUnsubscribable(this.rezidentRfSub);
    //   }
    // });
  }

  /**
   * Инициализация typePage - скрываем некоторые части формы
   */
  initTypePage(): void {
    if ([DossierAccessEnum.SVEDCLIENT,
      DossierAccessEnum.SVEDIOU,
      DossierAccessEnum.SVEDPREDST,
      DossierAccessEnum.SVEDVPR,
      DossierAccessEnum.PARTNER].includes(this.typePage)) {
      this.isOkvedShow = true;
    }
    if ([DossierAccessEnum.SVEDCLIENT,
      DossierAccessEnum.SVEDPREDST,
      DossierAccessEnum.SVEDVPR,
      DossierAccessEnum.PARTNER].includes(this.typePage)) {
      this.isLicenseInfoShow = true;
    }
  }

  /**
   * Убираем блок из модели в зависимости от признакак rezidentRf
   * у нерезидента этого блока не будет
   * @param value - значение rezidentRf
   */
  initRezidentRf(value): void {
    if (!this.formGroupApp.disabled) {
      const regInfo = this.f('regInfo');
      if (value) {
        regInfo.enable();
      } else {
        regInfo.disable();
      }
    }
  }

  openLicenseInfo(event: ErrorFLKItemType): void {
    if (event) {
      this.licenseInfo.openWindow(this.dossierService.errorFLKTable);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribable();
  }

}
