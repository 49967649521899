import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DossierService} from "../../../../services";
import {DossierBasePageComponent} from "../dossierBasePage.component";
import {IDossierSubService} from "../IDossierSub.service";
import {SvedVPRDossierService} from "./svedVPRDossier.service";
import {
  AlertPanelService,
  ITable,
  ITableCallBack,
  ITableItemDescriptor,
  TableCompComponent
} from "@amlCore/components";
import {DictionaryService, UserService} from "@amlCore/services";
import {ClientInfo} from "../../../../models/dossier";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FileSaverService} from "ngx-filesaver";
import { VprInfoComponent } from './../components/vprInfo/vprInfo.component';

@Component({
  selector: 'app-sved-vpr-dossier',
  styleUrls: ['../dossier.css'],
  templateUrl: './svedVPRDossier.component.html'
})
export class SvedVPRDossierComponent extends DossierBasePageComponent implements OnInit, ITable {

  modelPage: Array<ClientInfo>;
  itemDescriptor: ITableItemDescriptor;
  isTableTab = true;
  @ViewChild('tableComp', {static: true}) tableComp: TableCompComponent;

  constructor(protected dossierService: DossierService,
              protected route: ActivatedRoute,
              private svedVPRDossierService: SvedVPRDossierService,
              protected dictSrv: DictionaryService,
              protected router: Router,
              protected userService: UserService,
              protected alertPanelSrv: AlertPanelService,
              protected modalService: NgbModal,
              protected fSaver: FileSaverService) {
    super(dossierService, route, dictSrv, router, userService, alertPanelSrv, modalService, fSaver);
  }

  ngOnInit() {
    // Инициализация вкладки
    this.modelPage = this.initPage();
    // Описание элемента таблицы
    this.itemDescriptor = {
      create: this.svedVPRDossierService.getSvedVPR.bind(this.svedVPRDossierService),
      component: VprInfoComponent,
      params: {
        typePage: this.typePage,
        titlePanel: 'Сведения о выгодоприобретателе',
        clientType: () => this.clientType,
      }
    } as ITableItemDescriptor;
  }

  getService(): IDossierSubService {
    return this.svedVPRDossierService;
  }

  callbackItemClosed(event: ITableCallBack) {
    this.saveTableItem(event);
  }

  destroy() {
    // ТУТ писать реализацию ngOnDestroy для компонента

  }

}
