import {Component, Input, OnInit, OnDestroy} from "@angular/core";
import {DossierService} from "../../../../../services";
import {AlertPanelService, ICustomSelectMapper, ITableEditorItem, ITableItem} from "@amlCore/components";
import {DictionaryService, ResourceService} from "@amlCore/services";
import {FormGroup, Validators} from "@angular/forms";
import {DossierBaseModel} from "../../dossierBaseModel";
import {StaffDossierEnum} from "../../../staffDossier";
import {map, takeUntil} from "rxjs/operators";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {Subject} from "rxjs";

@Component({
  selector: 'app-access-info',
  styleUrls: ['../../dossier.css'],
  templateUrl: './accessInfoDossier.component.html'
})
export class AccessInfoDossierComponent extends DossierBaseSubComponent implements OnInit, OnDestroy, ITableItem {

  titlePanel = 'Cотрудник, ответственный за работу с клиентом';
  showTitle = true;
  userMapper: ICustomSelectMapper;
  isTableItem = false;
  isEdit = false;
  StaffDossierEnum = StaffDossierEnum;
  @Input() state: StaffDossierEnum = StaffDossierEnum.ASSIGN;
  selectedUserIds: Array<string>;
  $destroy = new Subject();
  public accessUserList = [];
  constructor(protected dossierSrv: DossierService,
              protected dossierService: DossierService,
              protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected resourceSrv: ResourceService,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  /**
   * Описание формы
   */
  getFormDescription(): FormGroup {
    return this.dossierBaseModel.getAccessInfo();
  }

  /**
   * Загрузка списка пользователей, ответственных за работу с клиентом
   * @returns void
   */
  private loadAccessUserList() {
    const customParams = {};
    (this.state == StaffDossierEnum.ASSIGN ? this.resourceSrv.getDocsaccessUserList(customParams)
      : this.resourceSrv.getDocsaccessDocsUserList(this.formGroupApp.get("dossierIds").value)).subscribe((data) => {
      this.accessUserList = (data?.filter(item =>!this.selectedUserIds?.includes(item.id)) || []);
    });
  }

  /**
   * Инициализация FormGroup
   */
  initForm() {
    if (!this.isTableItem) {
      this.formGroupApp = this.getFormDescription();
    }
  }

  ngOnInit() {
    this.userMapper = {
      toModel: this.userMapperToModel.bind(this)
    } as ICustomSelectMapper;
    if (!this.isReadOnly){
      // setTimeout чтобы успела пройти проверка DOM
      setTimeout(() => { this.initDateEndIsReq(this.f('replacement').value); }, 0);
      this.f('replacement').valueChanges.pipe(takeUntil(this.$destroy)).subscribe(val => {
        this.initDateEndIsReq(val);
      });
    }
    this.loadAccessUserList();
  }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }

  userMapperToModel(origin: any, isDictData: boolean): any {
    return {
      id: origin.id,
      fullName: origin.fullName,
      department: origin.department,
      position: origin.position,
      state: origin.state,
      locked: origin.locked
    };
  }

  setSubmitted(value: boolean) {
    this.submitted = value;
  }

  getTitle(): string {
    return this.titlePanel;
  }

  /**
   * Инициализируем компонент как элемент формы просмотра таблицы
   * @param param.form - форма для связи
   * @param param.params - дополнительные параметры
   */
  initTableItem(param: ITableEditorItem): FormGroup {
    this.initForm();
    this.isTableItem = true;
    this.isEdit = param.editIndex != null;
    if (param.model) {
      this.formGroupApp.patchValue(param.model);
    }
    this.showTitle = false;
    const params = param.params;
    if (params) {
      if (params.typePage) {
        this.typePage = params.typePage;
      } else {
        console.log('Необходимо указать typePage у компонента');
      }
      if (params.selectedUserIds) {
        const selectedList = params.selectedUserIds();
        if (param.model && param.model.user) {
          // Для того что бы можно было перевыбрать пользователя исключаем его из списка выбранных
          this.selectedUserIds = selectedList.filter(item => {
            if (param.model && param.model.user) {
              return item !== param.model.user.id;
            }
          });
        } else {
          this.selectedUserIds = selectedList;
        }
      }
    }
    this.isReadOnly = param.isReadOnly || param?.model?.active === false || param?.model?.user?.locked === true;
    if (this.isReadOnly){
      this.formGroupApp.disable();
    }
    return this.formGroupApp;
  }
  initDateEndIsReq(value): void {
    if (!this.formGroupApp.disabled) {
      const dateEnd = this.f('dateEnd');
      dateEnd.setValidators(value === true ? [Validators.required]:[]);
      dateEnd.updateValueAndValidity();
    }
  }

  getSaveBtnText(): string{
    return this.isEdit ? "Сохранить" : "Назначить";
  }

  canDeleteAccess(): boolean {
    return this.f('active').value && (this.isReadOnly ? this.f('user').value.locked : this.isValidItem());
  }

}
