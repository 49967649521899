import {DossierT100, DossierT250} from "./base.model";
import {PhoneType} from "./phoneType.model";
import {EmailType} from "./emailType.model";

export class ContactType {
  phoneInfo?: Array<PhoneType> = undefined;
  emailInfo?: Array<EmailType> = undefined;
  phone: DossierT250 = '';
  fax?: DossierT250 = '';
  mobile?: DossierT250 = '';
  email?: DossierT250 = '';
  website?: DossierT100 = '';
}
