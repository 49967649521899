import {Input, OnChanges, SimpleChanges} from "@angular/core";
import {InputFormat} from "@amlCore/directives";
import {AbstractControl, ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validator} from "@angular/forms";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import {faMinusCircle} from "@fortawesome/free-solid-svg-icons/faMinusCircle";
import {FormGroupGetterFn} from "../../../arm";

/**
 * Базовый класс для FormArray[FormControl] или FormArray[FormGroup{FormControl, FormControl}]
 */
export abstract class MultiFieldBase implements ControlValueAccessor, Validator, OnChanges {
  // задаётся для FormArray[FormGroup{FormControl, FormControl...}]
  @Input() createFormGroup?: FormGroupGetterFn;
  @Input() appInputFormat?: InputFormat;
  @Input() formArray: FormArray;  // ссылка на входящий FormArray (при наличии)
  @Input() submitted: boolean;

  // для отображения компонента
  listItem: Array<any> | FormArray;
  isDisabled = false;
  icons = {
    plus: faPlusCircle,
    minus: faMinusCircle
  };
  page = 1;
  pageSize = 5;
  // Подготавливаем пустые функции, в которые потом акссесор внедрит свои реализации с помощью registerOnChange и registerOnTouched
  onChange = (result: any) => { };
  onTouch = () => { };
  registerOnChange = (fn: any): void => this.onChange = fn;
  registerOnTouched = (fn: any): void => this.onTouch = fn;
  registerOnValidatorChange(fn: () => void): void {}

  protected constructor(protected fb: FormBuilder) { }

  abstract add(data?): void;
  // Преобразуем модель в вид для отображения компонента
  abstract getModelFromView(outsideValue: Array<any> | FormArray): void;
  // Преобразить данные для передачи в родительский FormControl
  abstract getModelFromSave(modelView: Array<any>): any[];
  // что пушить в FormArray
  abstract currentAbstractControl(value): FormGroup | FormControl;

  // Инициализируем родительский контрол (вызывается когда передан formAbstractControlName и форма enable)
  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.formArray && control instanceof FormArray)
      this.initFormArray = control;
    return null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentValue = changes.formArray?.currentValue as FormArray;
    if (currentValue !== changes.formArray?.previousValue)
      this.initFormArray = currentValue;
  }

  /**
   * Меняем руками и сообщаем Forms API об обновлении (для входного FormControl)
   * @param index - можно передавать индекс для точечного редактирования напрямую FormArray (без передачи FormsAPI)
   */
  updateValue(index?: number): void {
    if (!this.formArray) { // если formArray не задан то считаем, что передан обычный formControl
      const newModel: Array<{}> | any[] = this._listItem.length > 0
        ? this.getModelFromSave(this._listItemValue)
        : [];
      this.onChange(newModel); // Сообщаем Forms API
    } else {
      if (index !== undefined) {
        const currentControl = this.formArray.at(index);
        const patchValue = this._listItem[index].value;
        currentControl
          ? currentControl.patchValue(patchValue)
          // для случая когда в FormArray еще не запушен нулевой AbstractControl
          : this._pushFormArray(patchValue);
        this.formArray.markAsDirty();
        this.formArray.updateValueAndValidity();
      } else {
        // уникальный случай, обновляем весь массив по новой
        this.formArray.clear();
        this._listItem.forEach(item => this._pushFormArray(item.value));
      }
    }
  }

  /**
   * Получаем значение родительского компонента и преобразуем в вид который нам надо
   */
  writeValue(outsideValue: Array<any> | FormArray): void {
    // this.initFormArray = outsideValue; (альтернативный метод)
    const length = Array.isArray(outsideValue)
      ? outsideValue?.length
      : outsideValue?.controls?.length;
    outsideValue && length > 0
      ? this.getModelFromView(outsideValue)
      : this.add(); // Добавляем пустое поле если список пустой при запуске
  }

  delete(index: number): void {
    Array.isArray(this.listItem)
      ? this.listItem.splice(index, 1)
      : this.listItem.removeAt(index);
    if (this.formArray) {
      this.formArray.removeAt(index);
      this.formArray.markAsDirty();
    } else {
      this.updateValue();
    }

    if (this._listItem.length === 0)
      this.add();
  }

  setDisabledState?(isDisabled: boolean): void {
    if (this.listItem instanceof FormArray)
      isDisabled
        ? this.listItem?.disable({ onlySelf: true })
        : this.listItem?.enable({ onlySelf: true });
    this.isDisabled = isDisabled;
  }

  isFieldReq(field: AbstractControl | string): boolean {
    return typeof field === 'string'
      ? this.isFieldReq(this.createFormGroup(null)?.get(field))
      : Boolean(field?.validator && field?.validator({} as AbstractControl)?.required);
  }

  // Возвращает true если хотя бы один контрол не заполнен (для disabled add-button)
  get isNotAllReqFieldsHasValue(): boolean {
    return this._listItem.some((item: FormGroup | FormControl | any) => {
      if (item instanceof FormGroup)
        return Object.values(item.controls)
          .some(formControl => !formControl.value && this.isFieldReq(formControl));

      return item instanceof FormControl
        ? !item.value && this.isFieldReq(item)
        : !item.value;
    });
  }

  // Задаём родительский FormArray (обновляет только @Input() т.к. используем formArray как ссылку)
  private set initFormArray(control: FormArray) {
    if (control) {
      this.formArray = control;
      this.writeValue(control);
      this.setDisabledState(control.disabled);
    }
  }

  private _pushFormArray(patchValue): void {
    this.formArray.push(this.currentAbstractControl(patchValue));
  }

  private get _listItemValue(): Array<any> {
    return Array.isArray(this.listItem)
      ? this.listItem
      : this.listItem.value;
  }

  private get _listItem(): Array<any> | AbstractControl[] {
    return Array.isArray(this.listItem)
      ? this.listItem
      : this.listItem.controls;
  }
}
