import {DossierK, DossierT100, DossierT250P, DossierT50, DossierT60P} from "./base.model";
import {DictType} from "./dictType.model";

export class AddressType {
  country: DictType = undefined;
  postIndex: DossierK = '';
  region: DictType = undefined;
  area: DossierT250P = '';
  city: DossierT250P = '';
  locality: DossierT250P = '';
  street: DossierT250P = '';
  house: DossierT60P = '';
  building: DossierT50 = '';
  apartment: DossierT100 = '';
  box: DossierT50 = '';
}
