/**
 * API по базовым справочникам АМЛ.
 * API по базовым справочникам АМЛ.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Данные записи справочника ОКАТО.
 */
export interface QrefOkato1 { 
    /**
     * Код ОКАТО. Ключевое поле таблицы.
     */
    ter?: string;
    /**
     * Код 1. Описание отсутствует.
     */
    kod1?: string;
    /**
     * Код 2. Описание отсутствует.
     */
    kod2?: string;
    /**
     * Код 3. Описание отсутствует.
     */
    kod3?: string;
    /**
     * Наименование.
     */
    name?: string;
    /**
     * Наименование применяемое в строке адреса центрального города.
     */
    dopInfo?: string;
}

