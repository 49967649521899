/**
 * Типы документов
 */
export enum DocumentTypeEnum {
  VERIFICATION_RESULT = "VERIFICATION_RESULT", // Сведения о результатах проверки
  FREEZING_FUNDS = "FREEZING_FUNDS", // Сведения о принятых мерах по замораживанию (блокированию)
  REFUSE_OPERATION = "REFUSE_OPERATION", //  Отказ в совершении операции
  OPERATIONS = "OPERATIONS", // Сведения об операциях
  OBSTRUCTION_FACTS = "OBSTRUCTION_FACTS", // ФЭС о фактах воспрепятствования
  SUSPICIOUS_ACTIVITY = "SUSPICIOUS_ACTIVITY", // Сведения о подозрительной деятельности
  REFUSE_CONTRACT = "REFUSE_CONTRACT", // Отказ от заключения договора (расторжение)
  REGISTRY_MEASURES = "REGISTRY_MEASURES", // Реестр принятых мер
  REGISTRY_CLIENTS = "REGISTRY_CLIENTS", // Реестр клиентов кредитной организации
  RISK_REPORT = "RISK_REPORT", // Реестр клиентов кредитной организации
  REGISTRY_REJECTION = "REGISTRY_REJECTION", // Реестр отказов(Реестр 764-П)
  KVIT_REJECTION = "KVIT_REJECTION", // Реестр отказов(Реестр 764-П)
  KVIT_ARJ_REJECTION = "KVIT_ARJ_REJECTION", // Реестр отказов(Реестр 764-П)
  P407_REQUESTS = "P407_REQUESTS", // Запрос 407 (Квитанции)
  P407_ANSWER = "P407_ANSWER", // Ответ 407
  REQUEST_171 = "REQUEST_171", // Запрос
  NFO_OPERATIONS = "NFO_OPERATIONS", // ФЭС об операциях, подлежащих обязательному контролю
  NFO_VERIFICATION_RESULT = "NFO_VERIFICATION_RESULT", // 4937-У. ФЭС о результатах проверки
  NFO_OBSTRUCTION_FACTS = "NFO_OBSTRUCTION_FACTS", // 4937_05 ФЭС о фактах воспрепятствования
  NFO_FREEZING_FUNDS = "NFO_FREEZING_FUNDS", // ФЭС о принятых мерах по замораживанию (блокированию)
  NFO_REFUSE_OPERATION = "NFO_REFUSE_OPERATION", // ФЭС о принятых мерах по замораживанию (блокированию)
  NFO_SUSPICIOUS_ACTIVITY = "NFO_SUSPICIOUS_ACTIVITY", // 4937-У. ФЭС о подозрительной деятельности
  OES_OTKAZ_GOZ = "OES_OTKAZ_GOZ", // 5392-У. ОЭС об отказе ГОЗ
  SD_KO_OTKR_ZAKR_CHET_DEPO = "SD_KO_OTKR_ZAKR_CHET_DEPO",  // 3732. ЭС об открытии(закрытии) счета депо
  SD_KO_SECURITIES = "SD_KO_SECURITIES",  //  3732. ЭС о приобретении(отчуждении) ценных бумаг
  SD_KO_IZM_CHET_DEPO = "SD_KO_IZM_CHET_DEPO",  // 3732. ЭС об изменении реквизитов счета депо
  SD_KO_OTKR_ZAKR_AKKR = "SD_KO_OTKR_ZAKR_AKKR",  // 3732. ЭС об открытии(закрытии) покрытого (депонированного) аккредитива
  SD_KO_IZM_AKKRED = 'SD_KO_IZM_AKKRED', // 3732. ЭС об изменении реквизитов аккредитивов
  SD_KO_IZM_CHET = 'SD_KO_IZM_CHET', // 2446-Y. ЭС об изменении реквизитов счета
  SD_KO_IZM_CHET_GOZ = 'SD_KO_IZM_CHET_GOZ', // ЭС об изменении реквизитов отдельного счета ГОЗ
  SD_KO_OTKR_ZAKR_CHET = 'SD_KO_OTKR_ZAKR_CHET', // 2446-У. Сведение банка об открытии (закрытии) счета
  SD_KO_OTKR_ZAKR_CHET_GOZ = 'SD_KO_OTKR_ZAKR_CHET_GOZ' // ЭС об открытии, закрытии отдельного счета ГОЗ
}

export enum CheckDocumentType {
  P407_REQUESTS = "p407",
  P407_ANSWER = "p407",
  STRATEG = 'strateg',
  SD_KO_OTKR_ZAKR_CHET_DEPO = "strateg",
  SD_KO_SECURITIES = "strateg",
  SD_KO_IZM_CHET_DEPO = "strateg",
  SD_KO_OTKR_ZAKR_AKKR = "strateg",
  SD_KO_IZM_AKKRED = 'strateg',
  SD_KO_IZM_CHET = 'strateg',
  SD_KO_IZM_CHET_GOZ = 'strateg',
  SD_KO_OTKR_ZAKR_CHET = 'strateg',
  SD_KO_OTKR_ZAKR_CHET_GOZ = 'strateg',
  OES_OTKAZ_GOZ = 'otkazgoz'
}
// ToDo скоро перестанет работать fm = '' и будет называться fm='sko115fz'
/**
 * Список групп разделов. Нужен для общих запросов в CommonService
 */
export enum GroupType {
  strateg655p = 'strateg',
  otkazgoz = 'otkazgoz',
  fm = '',
  svetofor = 'bank',
  elMsgFm = 'p407',
  rejection = 'registryRejection',
  strateg5392u = 'otkazgoz'
}

export const getGroupType = (type: string): string => {
  return GroupType[type] || ''
}
export const getCheckConfig = (type: CheckDocumentType): string => {
  return CheckDocumentType[type] || ''
}

export const getDocumentConfig = (type: DocumentTypeEnum): DocumentConfigService => {
  return DocumentConfig[type];
};

export const DocumentConfig = {
  VERIFICATION_RESULT: {
    url: "verificationResult5861_1",
    serviceName: "verificationResult",
    api: "/api/v1/documents/verificationResult/",
    tipInf:['4']
  },
  FREEZING_FUNDS: {
    url: "informFreeze5861_1",
    serviceName: "freezing",
    api: "/api/v1/documents/freezing/",
    tipInf:['3']
  },
  REFUSE_OPERATION: {
    url: "refuseOperation5861_1",
    serviceName: "refuseOperation",
    api: "/api/v1/documents/refuseOperation/",
    tipInf:['02']
  },
  OBSTRUCTION_FACTS: {
    url: "obstrFact5861_1",
    serviceName: "obstruction",
    api: "/api/v1/documents/obstruction/",
    tipInf:['5']
  },
  OPERATIONS: {
    url: "odu5861_1",
    serviceName: "operations",
    api: "/api/v1/documents/operations/",
    apiTable: "/api/v1/documents/operations/table/",
    tipInf:['1', '6']
  },
  SUSPICIOUS_ACTIVITY: {
    url: "suactive5861_1",
    serviceName: "suspicious",
    api: "/api/v1/documents/suspicious/",
    tipInf:['9']
  },
  REFUSE_CONTRACT: {
    url: "refContract5861_1",
    serviceName: "refuseContract",
    api: "/api/v1/documents/refuseContract/",
    tipInf:['01', '11']
  },
  REGISTRY_MEASURES: {
    url: "regMeasures794_1",
    serviceName: "registryMeasures",
    api: "/api/v1/documents/registryMeasures/",
  },
  REGISTRY_CLIENTS: {
    url: "regClients794_1",
    serviceName: "registryClients",
    api: "/api/v1/documents/registryClients/",
  },
  RISK_REPORT: {
    url: "riskReport",
    serviceName: "riskReport",
    api: "/api/v1/documents/registryRisks/",
  },
  REGISTRY_REJECTION: {
    url: "regRejection550_1",
    serviceName: "registryRejection",
    api: "/api/v1/documents/registryRejection/",
    unitType: 'rejection'
  },
  KVIT_REJECTION: {
    url: "kvitRejection550_1",
    serviceName: "registryRejection",
    api: "/api/v1/documents/registryRejection/",
    unitType: 'rejection'
  },
  KVIT_ARJ_REJECTION: {
    url: "kvitArjRejection550_1",
    serviceName: "registryRejection",
    api: "/api/v1/documents/registryRejection/",
    unitType: 'rejection'
  },
  P407_REQUESTS: {
    url: "p407requestsReceipt",
    serviceName: "requests/407p/receipt",
    api: "/api/v1/documents/requests/407p/receipt/",
    unitType: "elMsgFm"
  },
  P407_ANSWER: {
    url: "p407answer",
    serviceName: "p407answer",
    api: "/api/v1/documents/p407answer/",
    unitType: "elMsgFm"
  },
  REQUEST_171: {
    url: "request171",
    serviceName: "request171",
    api: "/api/v1/documents/requests/407p/",
  },
  NFO_OPERATIONS: {
    url: "nfoOperations4937",
    serviceName: "nfo/operations",
    api: "/api/v1/documents/nfo/operations/",
  },
  NFO_VERIFICATION_RESULT: {
    url: "nfoVerification4937",
    serviceName: "nfo/verification",
    api: "/api/v1/documents/nfo/verification/",
  },
  NFO_OBSTRUCTION_FACTS: {
    url: "nfoObstruction4937",
    serviceName: "nfo/obstruction",
    api: "/api/v1/documents/nfo/obstruction/",
  },
  NFO_FREEZING_FUNDS: {
    url: "nfoFreeze4937",
    serviceName: "nfo/freezing",
    api: "/api/v1/documents/nfo/freezing/",
  },
  NFO_REFUSE_OPERATION: {
    url: "nfoRefuseOperation4937",
    serviceName: "nfo/refuseOperation",
    api: "/api/v1/documents/nfo/refuseOperation/",
  },
  NFO_SUSPICIOUS_ACTIVITY: {
    url: "nfoSuspiciousActivity4937",
    serviceName: "nfo/suspicious",
    api: "/api/v1/documents/nfo/suspicious/",
  },
  OES_OTKAZ_GOZ: {
    url: "strateg5392u",
    serviceName: "strateg",
    api: "/api/v1/documents/oes/otkaz/goz/",
    tipInf: ['ГОЗРФМ'],
    unitType: "strateg5392u"
  },
  SD_KO_OTKR_ZAKR_CHET_DEPO: {
    url: "",
    serviceName: "otkr/zakr/chetDepo",
    api: "/api/v1/documents/otkr/zakr/chetDepo/",
  },
  SD_KO_SECURITIES: {
    url: "",
    serviceName: "sd/ko/securities",
    api: "/api/v1/documents/sd/ko/securities/",
  },
  SD_KO_IZM_CHET_DEPO: {
    url: "",
    serviceName: "sd/ko/izm/chet/depo",
    api: "/api/v1/documents/sd/ko/izm/chet/depo/",
  },
  SD_KO_OTKR_ZAKR_AKKR: {
    url: "",
    serviceName: "sd/ko/otkr/zakr/akkr",
    api: "/api/v1/documents/sd/ko/otkr/zakr/akkr/",
  },
  SD_KO_IZM_AKKRED: {
    url: "",
    serviceName: "izm/akkred",
    api: "/api/v1/documents/izm/akkred/",
  },
  SD_KO_IZM_CHET: {
    url: "",
    serviceName: "izm/chet",
    api: "/api/v1/documents/izm/chet/",
  },
  SD_KO_IZM_CHET_GOZ: {
    url: "",
    serviceName: "izm/chetGoz",
    api: "/api/v1/documents/izm/chetGoz/",
  },
  SD_KO_OTKR_ZAKR_CHET: {
    url: "",
    serviceName: "otkr/zakr/chet",
    api: "/api/v1/documents/otkr/zakr/chet/",
  },
  SD_KO_OTKR_ZAKR_CHET_GOZ: {
    url: "",
    serviceName: "otkr/zakr/chetGoz",
    api: "/api/v1/documents/otkr/zakr/chetGoz/",
  },
};

export type DocumentConfigService = {
  url: string;
  serviceName: string;
  api: string;
  apiTable?: string;
  tipInf?: string[];
  unitType?: string; // часть пусти в урл адресе (fm, rejection и т.д в зависимости что указано в роутерах где прописаны пути к документу)
};
