import {DictType} from "./dictType.model";
import {DossierDate, DossierT60, DossierT100, DossierT4000} from "./base.model";
import {UpdateCardInfo} from "./updateCardInfo.model";

export class AttributesType {
  identificationType: DictType = undefined;
  identificationLevel: DictType = undefined;
  dateStart: DossierDate = '';
  dateEnd?: DossierDate = '';
  createDate: DossierDate = '';
  dateEditPodFt: DossierDate = '';
  dateEndCheck: DossierDate = '';
  dateNextCheck?: DossierDate = '';
  dateEndSign?: DossierDate = '';
  dateEndCheckOtch: DossierDate = '';
  fio: DossierT100 = '';
  position: DossierT60 = '';
  fioIn: DossierT100 = '';
  positionIn: DossierT60 = '';
  updateCard: Array<UpdateCardInfo> = undefined;
}
