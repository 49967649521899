/**
 * API по базовым справочникам АМЛ.
 * API по базовым справочникам АМЛ.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Данные записи справочника КГРКО.
 */
export interface NameValue { 
    /**
     * Найденное значение имени. Возможно пусто, если не найдена запись или в поле пустое значение.
     */
    name: string;
}

