import {Component, OnInit, OnDestroy} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {DictionaryService, TableService, VisitedService} from "@amlCore/services";
import {DossierService} from "../../services";
import {INotice} from "../../interfaces";
import {IDictionaryItem} from "@amlCore/interfaces";
import { TableReactiveUrlController } from "@amlCore/tableReactive";
import {NoticeService} from "./notice.service";

@Component({
  selector: "app-notice-list",
  templateUrl: "./noticeList.component.html",
})

export class NoticeListComponent extends TableReactiveUrlController implements OnInit, OnDestroy {
  public isShowVisited = true;
  public noticeEntries: INotice[];
  public  typeSubjects: IDictionaryItem<{
    code: string;
    name: string;
  }>[] = [];

  constructor(protected activatedRoute: ActivatedRoute,
              protected router: Router,
              protected visitedService: VisitedService,
              protected tableService: TableService,
              private noticeSrv: NoticeService,
              private dossierSrv: DossierService,
              protected dictSrv: DictionaryService) {
    super(router, activatedRoute, visitedService, tableService,  {page: 0, sort: 'eventDate', dir: 'desc', pageSize: 100});
  }

  ngOnInit(): void {
      this.createUniqueKeyForStore();
      this.activatedRoute.queryParams.subscribe((params) => {
          this.loadNoticesEntries(this.syncParams(params));
      });
      this.dictSrv.getRemoteData('typesubjects').then((result) => {
        this.typeSubjects = result;
      });
  }

  /**
   * Метод загрузки оповещений
   */
  public loadNoticesEntries(params) {
    const queryParams = this.getParamsv2(params);
    if (typeof this.storeService.get(this.keyFilterResult) === "boolean") {
      queryParams["result"] = !this.storeService.get(this.keyFilterResult);
    }
    this.noticeSrv.getNoticesList(queryParams).subscribe(({content, totalElements}) => {
      this.noticeEntries = content;
      this.total = totalElements;
      this.scrollRun();
    });
  }

  /**
   * Метод простого поиска
   */
  public onSearch() {
    const params = this.getSearchPage(this.filter);
    this.onPageChange(params);
  }

  /**
   * Метод поиска при переключении
   * переключателя все/требующие-решения
   */
  public onSwitchResult(value: boolean) {
    if (this.getFocusRowId()) {
      this.tableService.clear();
    }
    this.storeService.save(this.keyFilterResult, value);
    this.filter.result = value;
    this.onPageChange(this.getParams(this.filter));
  }

  onPageChange(params) {
    this.navigate({...params, ...this.getParams(this.filter)});
    this.resetSelectedAll();
  }

  /**
   * метод получения объекта плоского фильтра
   * @param data объект фильтра с полями
   */
  public getFilterObject(data) {
    const filter = this.tableService.getFilterSubject(data);
    return this.getFilterPlanObject(filter);
  }

  public getTypeSubjectName(code: string): string {
    return this.dictSrv.getTypeSubjectName(code, this.typeSubjects);
  }

  public wrapStringNotice(str) {
    if (str.length > 100) {
      return `${str.slice(0, 100)}...`;
    }
    return str;
  }

  public openNoticeRoot(notice: any) {
    if (notice.dossierInfo.rootClient.state !== 1) {
      this.dossierSrv.setPageId(notice.dossierInfo.rootClient.partId);
    }
    window.open(`dossier/${notice.dossierInfo.rootClient.dossierId}/${notice.dossierInfo.rootClient.typeSubject}`, '_blank');
  }

  public openNotice(notice: any) {
    if (notice.dossierInfo.clientInfo.state !== 1) {
      this.dossierSrv.setPageId(notice.dossierInfo.clientInfo.partId);
    } else {
      this.dossierSrv.setPageId(null);
    }
    window.open(`dossier/${notice.dossierInfo.clientInfo.dossierId}/${notice.dossierInfo.clientInfo.typeSubject}`, '_blank');
  }

  public viewForm(notice: any) {
    this.tableService.saveScrollPosition(this.commonKeyStore);
    this.router.navigate(['noticeForm', notice.id]);
  }

  ngOnDestroy() {
    this.checkScrollOptions();
  }

}
