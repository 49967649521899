import {IFilter} from "./IFilter.model";
import {faFilter} from "@fortawesome/free-solid-svg-icons/faFilter";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import {faLockOpen} from "@fortawesome/free-solid-svg-icons/faLockOpen";
import {faLock} from "@fortawesome/free-solid-svg-icons/faLock";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {
  faDownload,
  faPlus,
  faMinus,
  faHandPaper,
  faEdit,
  faArchive,
  faExclamationTriangle,
  faBan
} from "@fortawesome/free-solid-svg-icons";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import {TableService, VisitedService} from "@amlCore/services";
import {faCogs} from "@fortawesome/free-solid-svg-icons/faCogs";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import {SelectedItemModel} from "@amlCore/models";
import {Utils} from "@amlCore/utils";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";

export abstract class TableController {
  public isShowFilter = false;
  abstract isShowVisited; // Отобразить посещенные элементы (модель объекта должна быть наследована от VisitedLinkModel)
  abstract isSetFocus; // Фокусировка на элементе при выходе из формы просмотра
  public page = 1;
  public pageSize = 20;
  public total = null;
  public searchInput = "";
  public localSearch = "";
  public sort = "";
  public dir = "asc";
  public filter: IFilter | any;
  public localed = false; // локальный поиск
  /**
   * Признак выбора всех записей
   * При использовании НЕОБХОДИМО добавить вызов resetSelectedAll() в метод onPageChange для сброса флага при поиске итд
   * getSelectedModel - метод получаения списка выбранных записей
   * selectAll - метод снятия/выбора всех элементов автоматически
   * setSelectAll - метод снятия/выбора всех элементов в ручную
   * selectRow - выделить/снять строку
   * checkSizeSelectedModel - проверка выбрано ли что-то
   * getSelectedParam - получить параметры модели
   */
  public isSelectedAll = false;
  public icons = {
    trash: faTrash,
    plus: faPlus,
    minus: faMinus,
    download: faDownload,
    handPaper: faHandPaper,
    check: faCheck,
    filter: faFilter,
    search: faSearch,
    circle: faTimesCircle,
    unlocked: faLockOpen,
    locked: faLock,
    checkCircle: faCheckCircle,
    gear: faCogs,
    edit: faEdit,
    archive: faArchive,
    spinner: faSpinner,
    waring: faExclamationTriangle,
    closed: faBan
  };

  constructor(protected visitedService: VisitedService, protected tableService: TableService, params?: any) {
    if (TableController.isValidObject(params)) {
      this.pageSize = params.pageSize || this.pageSize;
      this.sort = params.sort || this.sort;
      this.dir = params.dir || this.dir;
      if (params.filter) {
        this.filter = params.filter;
      }
    }
  }

  static isValidObject(params) {
    return params && typeof params === "object" && Object.keys(params).length > 0;
  }

  /**
   * Новый метод получения списка параметров фильтрации
   * формирующий объект фиьтрации по структре
   * {
   *  page: 1,
   *  size: 100,
   *  filter: {
   *    firstName: 'test name',
   *    inn: '123'
   *  }
   * }
   */
  getParamsv2(params?: object): object {
    let result;
    if (TableController.isValidObject(params)) {
      result = Utils.clone(params);
      // Заполняем result.filter по интерфейсу this.filter для отправки на сервер
      Object.keys(this.filter).forEach(item => {
        if (result[item]) {
          if (!result.filter) {
            result.filter = {};
          }
          result.filter[item] = result[item];
          delete result[item];
        }
      });
    } else {
      result = {
        page: this.page - 1,
        size: this.pageSize,
        search: this.searchInput ? this.searchInput.replace(/\s+/g, ' ').trim() : '',
        sort: this.sort,
        dir: this.dir
      };
      if (this.filter) {
        result.filter = {};
        Object.assign(result.filter, this.filter.get());
      }
    }

    return result;
  }

  /**
   * Старый метод получения списка параметров фильтрации
   * необходим для работы с GET запросами, т.к есть справочники которые работают
   * через GET и отправлять объект filter в queryPerametrs не получится
   * так как объект сворачивается в строку [object, object]
   * {
   *  page: 1,
   *  size: 100,
   *  filter: [object, object]
   * }
   */
  // TODO когда перейдем на GET во всех местах где есть таблицы, метод можно будет удалить
  getParams(params?: object): object {
    let result;
    if (TableController.isValidObject(params)) {
      result = Utils.clone(params);
    } else {
      result = {
        page: this.page - 1,
        size: this.pageSize,
        search: this.searchInput ? this.searchInput.replace(/\s+/g, ' ').trim() : '',
        sort: this.sort,
        dir: this.dir
      };
      if (this.filter) {
        Object.assign(result, this.filter.get());
      }
    }
    // Очистка поля поиска при расширенном поиске
    if (result['$fa']) {
      result.search = null;
    }
    return result;
  }

  /**
   * Формируем параметры для навигации - проставка в url
   * @param params
   * TODO: возможно проще реализовать через один метод и параметрами указывать как формировать этот filter
   * TODO: либо искать когда что запускается, и авто подставлять (сложно для поддержки)
   */
  getParamsForNavigate(params?: object): object {
    let result;
    if (TableController.isValidObject(params)) {
      result = Utils.clone(params);
      if (result.filter) {
        Object.assign(result, result.filter);
        delete result.filter;
      }
    } else {
      // TODO: всякие общие части еще вынести можно)
      result = {
        page: this.page - 1,
        size: this.pageSize,
        search: this.searchInput ? this.searchInput.replace(/\s+/g, ' ').trim() : '',
        sort: this.sort,
        dir: this.dir
      };
      if (this.filter) {
        Object.assign(result, this.filter.get());
      }
    }
    return result;
  }

  showFilter(show ?: boolean) {
    this.isShowFilter = show || !this.isShowFilter;
  }

  protected resetPagination(): void {
    this.page = 1;
  }

  getSearchPage() {
    this.resetPagination();
    return this.getParams();
  }

  abstract onPageChange(params): void;

  onSearch() {
    if (this.localed) {
      this.localSearch = this.searchInput;
    } else {
      this.onPageChange(this.getSearchPage());
    }
  }

  onSort(params) {
    if (params.sort) {
      if (this.sort !== params.sort) {
        this.dir = 'asc';
      } else {
        this.dir = params.dir === 'asc' ? 'desc' : 'asc';
      }
      this.sort = params.sort;
      this.onPageChange(Object.assign(this.getParams()));
    }
  }

  openRowId(id) {
    if (this.isSetFocus) {
      this.tableService.setFocusRowId(id);
    }
    if (this.isShowVisited) {
      this.visitedService.setVisitedId(id);
    }
  }

  getFocusRowId() {
    return this.tableService.focusRowId;
  }

  /**
   * Выбрать все отображаемые записи
   * @param list - список элементов
   */
  selectAll(list: Array<SelectedItemModel>) {
    this.isSelectedAll = !this.isSelectedAll;
    list.forEach(item => item.$selected = this.isSelectedAll);
  }

  /**
   * Получить выбранные записи
   * @param list - список, из которого выбрать записи
   */
  getSelectedModel(list: Array<SelectedItemModel>): Array<SelectedItemModel> {
    return list.filter(item => item.$selected === true);
  }

  /**
   * Проверка выбрано ли что-то
   * @param list - список
   */
  checkSizeSelectedModel(list: Array<SelectedItemModel>): boolean {
    return this.getSelectedModel(list).length > 0;
  }

  /**
   * Получить параметры выбранной модели
   * признак выбрано все, список выбранных и тд
   * @param list - список, из которого отобрать выбранные
   */
  getSelectedParam(list: Array<SelectedItemModel>) {
    return {
      isSelectedAll: this.isSelectedAll,
      selectedModel: this.getSelectedModel(list)
    };
  }

  /**
   * Сбростить флаг "Выделить все"
   */
  resetSelectedAll() {
    this.isSelectedAll = false;
  }

  /**
   * Установить признка выделения и снять/выбрать все элементы
   * @param list - список
   * @param value - значение снять/выбрать
   */
  setSelectAll(list: Array<SelectedItemModel>, value: boolean) {
    this.isSelectedAll = value;
    list.forEach(item => item.$selected = value);
  }

  /**
   * Выбор строки снять/выдеилть
   * @param item - строка
   */
  selectRow(item: SelectedItemModel) {
    item.$selected = !item.$selected;
  }
}
