import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertPanelComponent, AlertPanelService, ICustomSelectMapper} from '@amlCore/components';
import {IDictionaryItem, IDictItemType} from '@amlCore/interfaces';
import {DictionaryService, UserService, ValidatorService} from '@amlCore/services';
import {ConfirmDeactivate, DossierService} from '../../services';
import {faAddressCard} from '@fortawesome/free-solid-svg-icons/faAddressCard';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import {CountryItemModel, DictType, DossierList, Transaction} from '../../models';
import {Utils} from '@amlCore/utils';
import {MemberStatusEnum} from '../../enums';
import {DictionariesService} from "../../../dictionaries/service";
import {map} from "rxjs/operators";
import {IQrefViddocosnov4936} from "../../../dictionaries/models";

@Component({
  selector: 'app-client-transaction-form',
  templateUrl: './clientTransactionForm.component.html'
})

export class ClientTransactionFormComponent implements OnInit, OnDestroy, ConfirmDeactivate  {

  @ViewChild("selectPayerClient") selectPayerClient: any;
  @ViewChild("selectRecipientClient") selectRecipientClient: any;
  @ViewChild("alertObjSave") alertObjToSave: AlertPanelComponent;

  public icons = {
    dossier: faAddressCard,
    spinner: faSpinner,
    warning: faExclamationTriangle
  };
  public currencyMapper: ICustomSelectMapper;  // Маппер для справочника кодов валюты
  public countryMapper: ICustomSelectMapper;   // Маппер для справочника кодов страны
  public issuerCountryMapper: ICustomSelectMapper; // Маппер для справочника кодов страны
  public typeDocMapper: ICustomSelectMapper;   // Маппер для кодов типов документа
  public typeOperVid: ICustomSelectMapper;     // Маппер для Вид операции (Код)
  public timerPayerId: number; // таймер плательщик
  public timerRecipientId: number; // таймер получатель
  public formModel: FormGroup;
  public isSearchPayer = false; // признак поиска клиента плательщика
  public isSearchRecipient = false; // признак поиска клиента получателя
  public isLoadingPayer = false; // флаг процесса загрузки клиентов плательщика
  public isLoadingRecipient = false; // флаг процесса загрузки клиентов получателя
  public submitted = false;
  public transactionPanelShow = false;
  public loadingCheckTransactions = false;
  public savingClientTransactions = false;
  public checkTransactionCount = 0;
  whereOpenUrl: string; // URL откуда был открыт компонент

  // TODO это должно быть в соотв. сервисе!
  /*справочники*/
  public selectObj = {
    typeOfAccountTransaction: [
      {id: '1', name: "Дебет"},
      {id: '2', name: "Кредит"},
    ],
    typePayments: [
      {id: '1', name: "Система \"Интернет-Банк\""},
      {id: '2', name: "Платежная карта"},
      {id: '3', name: "Электронный кошелек"}
    ],
    currencyList: [] as IDictionaryItem<{ name: string; id: string; }>[],
    kindsCodeTransactions: [] as IDictionaryItem<{ name: string; id: string; }>[],
    clientTypeList: [] as IDictItemType[],
    oksm: [] as IDictionaryItem<{ name: string; code: string; }>[],
    clientsPayerList: [] as IDictionaryItem<{ id: string; fio: string; }>[],
    clientsRecipientList: [] as IDictionaryItem<{ id: string; fio: string; }>[],
    vidDocs: [] as IQrefViddocosnov4936[], // Справочник видов документов
    bankopervid: [] as IDictionaryItem<{ name: string; code: string; }>[], // справочник банковских операций
    currcash: [] as IDictionaryItem<{ name: string; code: string; }>[], // справочник Вид операции с наличной валютой
    participantState: [] as IDictionaryItem<{ name: string; code: string; }>[] // справочник статус участника
  };

  private accessObject = this.userSrv.getAccess("transactionslist");
  public transactionId = null; // id транзакции генерируемый на сервере.

  /*Мап списки полей которые содержат
    коды справочников на уровне корня
    объекта модели формы*/
  private listRootCodesNameMap = new Map([
    ["currency", "currencyName"],
    ["typeDoc", "typeDocName"],
    ["issuerCountryKod", "issuerCountryName"]
  ]);

  /*Мап списки полей которые содержат
    коды справочников на уровне субъектов
    плательщика и получателя объекта модели формы*/
  private listSubjectCodesNameMap = new Map([
    ["countryKod", "countryName"],
    ["bankCountryKod", "bankCountryName"]
  ]);

  // поля на уровне корня модели
  private rootFieldList = ["currency", "typeDoc", "issuerCountryKod"];
  // поля на уровне плательщика
  private payerFieldList = ["countryKod", "bankCountryKod"];
  // поля на уровне получателя
  private recipientFieldList = ["countryKod", "bankCountryKod"];

  constructor(
    private fb: FormBuilder,
    private dictSrv: DictionaryService,
    private dictsSrv: DictionariesService,
    private dossierSrv: DossierService,
    private userSrv: UserService,
    private validatorSrv: ValidatorService,
    protected alertPanelSrv: AlertPanelService,
    protected activeRoute: ActivatedRoute,
    protected router: Router,
  ) {}

  ngOnInit() {
    this.createFormModel();
    this.loadCurrency();
    this.loadOKSM();
    this.dictSrv.loadTypeClient().then((result => {
      this.selectObj.clientTypeList = result;
    }));
    this.checkEditableAccess();

    this.currencyMapper = {
      toModel: this.currencyMapperToModel.bind(this)
    } as ICustomSelectMapper;

    this.countryMapper = {
      toModel: this.countryMapperToModel.bind(this)
    } as ICustomSelectMapper;

    this.issuerCountryMapper = {
      toModel: this.issuerCountryMapperToModel.bind(this)
    } as ICustomSelectMapper;

    this.typeDocMapper = {
      toModel: this.typeDocMapperToModel.bind(this)
    } as ICustomSelectMapper;

    this.typeOperVid = {
      toModel: this.typeOperVidToModel.bind(this)
    } as ICustomSelectMapper;

    this.activeRoute.params.subscribe((url) => {
      if (url.whereOpenUrl) {
        this.whereOpenUrl = url.whereOpenUrl
      }

      // если открыли существующую транзакцию
      if (url.id) {
        this.transactionId = url.id;
        this.dossierSrv.getClientTransactionById(url.id).subscribe((data: Transaction) => {
          this.formModel.patchValue(this.getStructuringData(data));
        });
      }
    });

    /*Загрузка справочника Кодов видов документов*/
    this.dictsSrv.getQrefViddocosnov4936List({sort:'name'}).pipe(map(page => page.content)).subscribe((dataList) => {
      this.selectObj.vidDocs = dataList;
    });

    /*Загрузка справочника видов банковских операций*/
    this.dictSrv.getRemoteData("bankopervid").then((dataList) => {
      this.selectObj.bankopervid = dataList;
    });

     /*Загрузка справочника Видов операции с наличной валютой*/
     this.dictSrv.getRemoteData("currcash").then((dataList) => {
      this.selectObj.currcash = dataList;
    });

    this.dictSrv.getRemoteData("participant_state").then((dataList) => {
      this.selectObj.participantState = dataList;
    });

  }

  /**
   * нужно ли подтверждение для выхода из формы
   */
  needConfirmDeactivate(): boolean {
    return this.formModel?.dirty;
  }

  goBack(): void {
    if (history.length === 1) {
      this.router.navigateByUrl(this.whereOpenUrl)
      return;
    }
    history.back();
  }

  /**
   * Метод преобразующий коды справочников из значения строк в объекты
   * fieldCode: { code:codeName, name: codeValue }
   * fieldName: value string;
   */
  public getStructuringData(data: Transaction): Transaction {
    const formModel: Transaction = Utils.clone(data);
    /*
    * преобразование из строки в объект {code: value, name: value}
    * для полей объекта формы на уроне корня
    */
    this.rootFieldList.forEach((nameCodeField) => {
      const codeValue = formModel[nameCodeField];
      if(codeValue){
        const nameValue = formModel[this.listRootCodesNameMap.get(nameCodeField)];
        formModel[nameCodeField] = {code: codeValue, name: nameValue};
      }
    });

    /**
     * преобзование из строки в объект {code: value, name: value}
     * для полей субъекта, на уровне объекта формы плательщик
     */
    this.payerFieldList.forEach((nameCodeField) => {
      const codeValue = formModel.payer[nameCodeField];
      const nameValue = formModel.payer[this.listSubjectCodesNameMap.get(nameCodeField)];
      formModel.payer[nameCodeField] = {code: codeValue, name: nameValue};
    });

    /**
     * преобзование из строки в объект {code: value, name: value}
     * для полей субъекта, на уровне объекта формы получатель
     */
    this.recipientFieldList.forEach((nameCodeField) => {
      const codeValue = formModel.recipient[nameCodeField];
      const nameValue = formModel.recipient[this.listSubjectCodesNameMap.get(nameCodeField)];
      formModel.recipient[nameCodeField] = {code: codeValue, name: nameValue};
    });
    return formModel;
  }

  /**
   * Метод получения данных из сущности
   * получатель или плательщик
   * @param entity - тип сущности, payer или recipient
   * @param field - имя поля по которуму считывается значение из модели формы
   */
  public fSubject(entity: string, field: string) {
    return {
      val: (() => {
        return this.formModel.get(entity).get(field).value;
      }).call(this) as string
    };
  }

  /**
   * Маппер для код валюты
   */
  private currencyMapperToModel(origin: any, isDictData: boolean): any {
    if (isDictData) {
      return {
        code: origin.iso_dig,
        name: origin.name
      };
    } else {
      return origin;
    }
  }

  /**
   * Маппер для Кода страны, Код страны банка, в плательщие и получателе
   */
  private countryMapperToModel(origin: CountryItemModel, isDictData: boolean): CountryItemModel | DictType {
    if (isDictData) {
      return {
        code: origin.kod,
        name: origin.name
      };
    } else {
      return origin;
    }
  }

    /**
   * Маппер для Кода страны банка-эмитента
   */
     private issuerCountryMapperToModel(origin: CountryItemModel,  isDictData: boolean ): CountryItemModel | DictType {
      if (isDictData) {
        return {
          code: origin.kod,
          name: origin.name
        };
      } else {
        return origin;
      }
    }

  /**
   * Маппер для кодов типов документов
   */
  private typeDocMapperToModel(origin: any, isDictData: boolean): any {
    return origin;
  }

  /**
   * Маппер для Вид операции (Код)
   */
  private typeOperVidToModel(origin: any, isDictData: boolean): any {
    if (isDictData) {
      return {
        code: origin.code,
        name: origin.name
      };
    } else {
      return origin;
    }
  }

  /**
   * Метод проверки доступа пользователя к редакированию формы
   */
  private checkEditableAccess() {
    if (this.accessObject && this.accessObject.readOnly) {
      this.formModel.disable();
    }
  }

  /**
   * Метод проверки редактируемости полей на форме
   */
  public isAccessControl() {
    return (this.accessObject && this.accessObject.readOnly);
  }

  /**
   * Метод создания реактивной формы
   */
  // TODO вынести в сервис
  public createFormModel() {
    this.formModel = this.fb.group({
      id: [''],          // Уникальный Id транзакции генерируемый на сервере не редактируемый
      statusPay: [null], // статус плательщика
      oktmo: [''],       // ОКТМО
      kbk: [''],         // КБК
      basisPay: [''],    // основание платежа
      taxPeriod: [''],   // Налоговый период
      taxNum: [''],      // Номер налогового документа
      taxDate: [''],     // Дата налогового документа
      operId: ['', this.validatorSrv.getValidation({
        isReq: true,
        min: 3,
        max: 100
      })], // Уникальный номер операции
      date: ['', this.validatorSrv.getValidation({
        isReq: true
      })], // Дата совершения операции
      type: [''], // Вид операции (Код)
      currCashType: [''], //Вид операции с наличной валютой
      espType: [''], // Использование электронного средства платежа
      currency: ['', this.validatorSrv.getValidation({
        isReq: true
      })], // Код валюты
      currencyName: [''], // Наименование валюты
      amountCurr: ['', this.validatorSrv.getValidation({
        isReq: true
      })], // Сумма операции в валюте
      amountRub: ['', this.validatorSrv.getValidation({
        isReq: true
      })], // Сумма операции в рублях
      operationName: [''], // Наименование вида операции
      issuerCountryKod: [''], // код страны банка-эмитента
      issuerCountryName: [''], // нименование страны банка-эмитента
      purpose: ['', this.validatorSrv.getValidation({
        min: 3,
        max: 210
      })], // Назначение платежа
      dateDoc: ['', this.validatorSrv.getValidation({
        isReq: true
      })], // Дата документа
      numberDoc: ['', this.validatorSrv.getValidation({
        isReq: true,
        max: 255
      })], // Номер документа
      typeDoc: ['', this.validatorSrv.getValidation({
        isReq: true,
      })], // Код тип документа
      typeDocName: [''], // Наименование типа документа
      // *ПЛАТЕЛЬЩИК
      payer: this.fb.group({
        clientRegistry: [''], /* rootCleintInfoId Id досье
                                 клиента заполняется, если клиент найден */
        clientType: ['', this.validatorSrv.getValidation({
          isReq: true
        })], // Тип участника
        name: ['', this.validatorSrv.getValidation({
          isReq: true,
          min: 3,
          max: 160
        })], // Наименование/ФИО
        inn: ['', this.validatorSrv.getValidation({
          max: 12
        })], // ИНН (КИО)
        kpp: ['', this.validatorSrv.getValidation({
          max: 9
        })], // КПП
        countryKod: ['', this.validatorSrv.getValidation({
          isReq: true
        })], // Код страны
        countryName: [''], // Наименование страны
        address: ['', this.validatorSrv.getValidation({
          min: 3,
          max: 255
        })], // Адрес
        bic: ['', this.validatorSrv.getValidation({
          min: 9,
          max: 9
        })], // БИК банка
        swift: ['', this.validatorSrv.getValidation({
          min: 8,
          max: 11
        })], // SWIFT-код банка
        corrAccount: ['', this.validatorSrv.getValidation({
          min: 10,
          max: 40
        })], // Номер корреспондентского счета
        account: ['', this.validatorSrv.getValidation({
          isReq: true,
          min: 10,
          max: 40
        })], // 	Номер счета
        bankCountryKod: ['', this.validatorSrv.getValidation({
          isReq: true,
        })], // Код страны банка
        bankCountryName: [''], // Наименование страны банка
        bankAddress: ['', this.validatorSrv.getValidation({
          min: 3,
          max: 255
        })], // Адрес банка
        bank: ['', this.validatorSrv.getValidation({
          isReq: true,
          min: 3,
          max: 255
        })], // Наименование банка
        vid: ['', this.validatorSrv.getValidation({
          isReq: true
        })], // Вид операции по счету
        memberStatus: [MemberStatusEnum.Payer, this.validatorSrv.getValidation({
          isReq: true
        })] // статус участника
      }),
      // *ПОЛУЧАТЕЛЬ
      recipient: this.fb.group({
        clientRegistry: [''], /* rootCleintInfoId Id досье
                                 клиента заполняется программно
                                 если клиента найден */
        clientType: ['', this.validatorSrv.getValidation({
          isReq: true
        })], // Тип участника
        name: ['', this.validatorSrv.getValidation({
          isReq: true,
          min: 3,
          max: 160
        })], // Наименование/ФИО
        inn: ['', this.validatorSrv.getValidation({
          max: 12
        })], // ИНН (КИО)
        kpp: ['', this.validatorSrv.getValidation({
          max: 9
        })], // КПП
        countryKod: ['', this.validatorSrv.getValidation({
          isReq: true
        })], // Код страны
        countryName: [''], // Наименование страны
        address: ['', this.validatorSrv.getValidation({
          min: 3,
          max: 255
        })], // Адрес
        bic: ['', this.validatorSrv.getValidation({
          min: 9,
          max: 9
        })], // БИК банка
        swift: ['', this.validatorSrv.getValidation({
          min: 8,
          max: 11
        })], // SWIFT-код банка
        corrAccount: ['', this.validatorSrv.getValidation({
          min: 10,
          max: 40
        })], // Номер корреспондентского счета
        account: ['', this.validatorSrv.getValidation({
          isReq: true,
          min: 10,
          max: 40
        })], // 	Номер счета
        bankCountryKod: ['', this.validatorSrv.getValidation({
          isReq: true,
        })], // Код страны банка
        bankCountryName: [''], // Наименование страны банка
        bankAddress: ['', this.validatorSrv.getValidation({
          min: 3,
          max: 255
        })], // Адрес банка
        bank: ['', this.validatorSrv.getValidation({
          isReq: true,
          min: 3,
          max: 255
        })], // Наименование банка
        vid: ['', this.validatorSrv.getValidation({
          isReq: true
        })], // Вид операции по счету
        memberStatus: [MemberStatusEnum.Recipient, this.validatorSrv.getValidation({
          isReq: true
        })] // статус участника
      }),
    });
  }

  /**
   * Метод преобразующий данные кодов справочников
   * из объекта в строки
   * fieldCode: string value
   * fieldName: string value
   */
  public getDestructuringData(): Transaction {
    const formModel: Transaction = Utils.clone(this.formModel.value);
    /**
     * Преобразование из объектов в строки на уровне
     * корня модели формы
     * fieldCode: {}
     * fieldName: string
     * ->
     * fieldCode: stringValue
     * fieldName: stringValue
     */
    this.rootFieldList.forEach((nameCodeField) => {
      const valueObject = (formModel[nameCodeField] || {code: '', name: ''});
      formModel[this.listRootCodesNameMap.get(nameCodeField)] = valueObject.name;
      formModel[nameCodeField] = valueObject.code;
    });

    /**
     * Преобразования из объекта в строки на уровне
     * плательщика и получателя в модели формы
     * fieldCode: {}
     * fieldName: string
     * ->
     * fieldCode: stringValue
     * fieldName: stringValue
     */
    this.payerFieldList.forEach((nameCodeField) => {
      const valueObject = (formModel["payer"][nameCodeField] || {name: '', code: ''});
      formModel["payer"][this.listSubjectCodesNameMap.get(nameCodeField)] = valueObject.name;
      formModel["payer"][nameCodeField] = valueObject.code;
    });

    this.recipientFieldList.forEach((nameCodeField) => {
      const valueObject = (formModel["recipient"][nameCodeField] || {name: '', code: ''});
      formModel["recipient"][this.listSubjectCodesNameMap.get(nameCodeField)] = valueObject.name;
      formModel["recipient"][nameCodeField] = valueObject.code;
    });

    Utils.deleteEmptyObj(formModel);

    formModel.amountCurr = parseFloat(formModel.amountCurr as string);
    formModel.amountRub = parseFloat(formModel.amountRub as string);

    return formModel;
  }


  /**
   * метод сохранения данных транзакции клиента
   */
  public saveClientTransaction() {
    this.submitted = true;
    if (this.formModel.invalid) {
      return;
    }
    this.savingClientTransactions = true;
    this.submitted = false;
    // update transaction
    if (this.transactionId || this.formModel.value.id) {
      const requestData = this.getDestructuringData();
      this.dossierSrv.updateTransaction(requestData).subscribe((data) => {
        this.formModel.patchValue(this.getStructuringData(data));
        this.savingClientTransactions = false;
        this.alertObjToSave.open(this.alertPanelSrv.getSuccessMsg("Данные успешно обновлены"));
        this.formModel.markAsPristine();
      }, error => {
        this.savingClientTransactions = false;
      });
    }

    // create new transaction
    if (!this.transactionId && !this.formModel.value.id) {
      const requestData =  this.getDestructuringData();
      this.dossierSrv.addTransaction(requestData).subscribe((data) => {
        this.formModel.patchValue(this.getStructuringData(data));
        this.savingClientTransactions = false;
        this.alertObjToSave.open(this.alertPanelSrv.getSuccessMsg("Данные успешно сохранены"));
        this.formModel.markAsPristine();
      }, error => {
        this.savingClientTransactions = false;
      });
    }
  }

  /**
   * Метод переключающй признак поиска клиента в досье
   */
  public toggleSearch(control) {
    this[control] = !this[control];
  }

  /**
   * Метод поиска для плательщика. когда пользователь что то вводит в строку
   */
  public searchPayerClient(data: { term: any }) {
    if (this.timerPayerId !== undefined) {
      window.clearTimeout(this.timerPayerId);
    }

    if (data.term.length < 5 && this.selectObj.clientsPayerList.length > 0) {
      this.clear("clientsPayerList");
    }

    if (data.term.length >= 5) {
      const queryParams = {
        dir: "desc",
        page: 0,
        search: data.term,
        size: 200,
        sort: "created"
      };

      this.isLoadingPayer = true;
      this.dossierSrv.getDossierList(queryParams).subscribe((clients: any) => {
        this.timerPayerId = setTimeout(() => {
          this.isLoadingPayer = false;
          this.selectObj.clientsPayerList = clients.content;
          this.selectPayerClient.open();
        });
      });

    } else {
      this.isLoadingPayer = false;
    }
  }

  /**
   * Полная очистка селекта и его компонентов
   */
  clear(typeList: string) {
    this.selectObj[typeList] = [];
  }

  /**
   * Метод выбора значения в плательщике
   * data - выбранное досье клиента
   * проставляем его в clientRegistry
   */
  public selectedPayer(data: DossierList) {
    const value = {
      payer: {
        clientRegistry: '',
        name: '',
        inn: '',
        clientType: '',
      }
    };

    if (data !== undefined) {
      value.payer = { clientRegistry: data.id, name: data.fio, inn: data?.inn, clientType: data.clientType };
    }
    this.formModel.patchValue(value);
  }

  /**
   * Метод поиска для получателя. когда пользователь что то вводит в строку
   */
  public searchRecipientClient(data: { term: any }) {
    if (this.timerRecipientId !== undefined) {
      window.clearTimeout(this.timerRecipientId);
    }

    if (data.term.length < 5 && this.selectObj.clientsRecipientList.length > 0) {
      this.clear("clientsRecipientList");
    }

    if (data.term.length >= 5) {
      const queryParams = {
        dir: "desc",
        page: 0,
        search: data.term,
        size: 200,
        sort: "created"
      };

      this.isLoadingRecipient = true;
      this.dossierSrv.getDossierList(queryParams).subscribe((clients: any) => {
      this.timerRecipientId = setTimeout(() => {
          this.selectObj.clientsRecipientList = clients.content;
          this.isLoadingRecipient = false;
          this.selectRecipientClient.open();
        });
      });

    } else {
      this.isLoadingRecipient = false;
    }
  }

  /**
   * Метод выбора значения в получателе
   * data - выбранное досье клиента
   * проставляем его в clientRegistry
   */
  public selectedRecipient(data: DossierList) {
    const value = {
      recipient: {
        clientRegistry: '',
        name: '',
        inn: '',
        clientType: ''
      }
    };

    if (data !== undefined) {
      value.recipient = { clientRegistry: data.id, name: data.fio, inn: data?.inn,  clientType: data.clientType};
    }
    this.formModel.patchValue(value);
  }

  /**
   * Метод открытия досье клиента
   * @param typePart плательщик или получатель
   */
  public openDossierClient(typePart: string) {
    const clientId = this.formModel.value[typePart].clientRegistry;
    this.router.navigate([`dossier/${clientId}/svedClient`]);
  }

  /**
   * метод получения описания Вид операции (код)
   */
  public getBankOperVidTitle(): string {
    const typeCode = this.formModel.get('type').value;
    if (typeCode) {
      const codeObject = this.selectObj.bankopervid.find((item: any) => item.code === typeCode);
      return (codeObject?.name || '');
    }
    return '';
  }

   /**
   * метод получения описания Вид операции с наличной валютой
   */
    public getCurrCashTitle(): string {
      const typeCode = this.formModel.get('currCashType').value;
      if (typeCode) {
        const codeObject = this.selectObj.currcash.find((item: any) => item.code === typeCode);
        return (codeObject?.name || '');
      }
      return '';
    }

  public checkTransations() {
    this.transactionPanelShow = false;
    this.loadingCheckTransactions = true;
    const selectedTransactionList = [{checkObjectId: this.transactionId}];

    this.dossierSrv.checkTransactions(selectedTransactionList).subscribe(data => {
      if (data) {
        this.checkTransactionCount = 0;
        this.transactionPanelShow = true;
        (data as Array<any>).forEach(item => {
          this.checkTransactionCount += item.totalCount;
        });
      }
      this.loadingCheckTransactions = false;
    }, (error) => {
      this.loadingCheckTransactions = true;
    });
  }

  public checkAccess115FZ() {
    return this.userSrv.getAccess("startinganalysis115fz").readOnly;
  }

  /**
   * Метод загрузки справочника валют по okv
   */
  private loadCurrency() {
    const russianRubCode = "810";
    const stateRubCurrency = "ПРИЗНАК РОССИЙСКОГО РУБЛЯ";
    this.dictSrv.getRemoteData("okv").then((content) => {
      this.selectObj.currencyList = (content || []).filter((item) => {
        return ((item.iso_dig === russianRubCode && item.name !== stateRubCurrency) ||
                !Utils.dateNowIsAfter(item.cb_date));
      });
    });
  }

  /**
   * Метод загрузки стран мира по общероссийскому классификатору
   */
  private loadOKSM() {
    this.dictSrv.getRemoteData("oksm").then(result => {
      this.selectObj.oksm = result;
    });
  }

  ngOnDestroy() {}

}
