import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DictType, RiskAssessType} from '../../../../models/dossier';
import {DossierService} from '../../../../services';
import {DossierBasePageComponent} from '../dossierBasePage.component';
import {IDossierSubService} from '../IDossierSub.service';
import {RiskAssessDossierService} from './riskAssessDossier.service';
import {DictionaryService, UserService} from '@amlCore/services';
import {AlertPanelService, ITableItemDescriptor, ITableCallBack, TableCompComponent} from '@amlCore/components';
import {Utils} from '@amlCore/utils';
import {DossierPageResolver} from "../dossierPageResolver";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FileSaverService} from "ngx-filesaver";
import { RiskAssessInfoDossierComponent } from '../components/riskAccessInfo';

@Component({
  selector: 'app-risk-assess-dossier',
  styleUrls: ['../dossier.css'],
  templateUrl: './riskAssessDossier.component.html'
})
export class RiskAssessDossierComponent extends DossierBasePageComponent implements OnInit {

  isTableTab: boolean = false;
  modelPage: Array<RiskAssessType>;
  itemDescriptor: ITableItemDescriptor;

  constructor(protected dossierService: DossierService,
              protected route: ActivatedRoute,
              private riskAssessDossierService: RiskAssessDossierService,
              protected dictSrv: DictionaryService,
              protected router: Router,
              protected userService: UserService,
              protected alertPanelSrv: AlertPanelService,
              protected modalService: NgbModal,
              protected fSaver: FileSaverService) {
    super(dossierService, route, dictSrv, router, userService, alertPanelSrv, modalService, fSaver);
  }

  ngOnInit() {
    // Инициализация вкладки
    this.modelPage = this.initPage();
    this.itemDescriptor = {
      create: this.riskAssessDossierService.getRiskAssess.bind(this.riskAssessDossierService),
      component: RiskAssessInfoDossierComponent,
      params: {
        typePage: this.typePage
      }
    } as ITableItemDescriptor;

  }
  /**
   * Инициализация модели
   * Преобразуем в нужный формат
   * @param model - данные сервера
   */
  initModel(model): any {
    let newModel;
    this.partId = null;
    if (model && model['no_id'] === undefined) {
      const partIdList: string[] = Object.keys(model);
      newModel = model[partIdList[0]];
      this.partId = partIdList[0];
      Utils.deleteEmptyObj(newModel);
    } else {
      newModel = [];
    }
    const formData = {};
    formData[this.typePage] = newModel;
    return formData;
  }

  getService(): IDossierSubService {
    return this.riskAssessDossierService;
  }

  /**
   * Своя обработка фокусировки на ошибках для раздела
   * @param key - путь до поля с ошибкой
   */
  public isFocus(key: string): boolean {
    return this.focusObject.riskAssess ? this.focusObject.riskAssess[key] : false;
  }

  callbackItemClosed(event: ITableCallBack): void {
    this.saveTableItem(event);
  }

  destroy() {
    // ТУТ писать реализацию ngOnDestroy для компонента

  }
  // удаление записи в таблице
  deleteItem(event: ITableCallBack): void {
    this.saveDossierPage();
  }
}
