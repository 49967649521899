import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from "@angular/core";
import {DossierService} from "../../../../../services";
import {AbstractControl, FormControl, Validators} from "@angular/forms";
import {Unsubscribable} from "rxjs";
import {DictionaryService} from "@amlCore/services";
import {TypeOrg} from "../../../../../models/dossier";
import {DossierBaseModel} from "../../dossierBaseModel";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {AlertPanelService} from "@amlCore/components";
import {DossierAccessEnum} from "@amlCore/enums";
import {delay} from "rxjs/operators";

@Component({
  selector: 'app-reg-info-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './regInfoDossier.component.html'
})
export class RegInfoDossierComponent extends DossierBaseSubComponent implements OnInit, OnDestroy{
  // TODO не очень хорошо передавать контрол формы через @Input() в такой реализации.
  //  Это приводит к ошибкам вида "ExpressionChangedAfterItHasBeenCheckedError"
  //  Если это исправить, то уже не надо будет использовать setTimeOut() или delay.
  @Input() rezidentRf: FormControl;
  @Input() typeOrg: TypeOrg;
  rezidentRfSub: Unsubscribable;
  formGroupValueChangesSub: Unsubscribable;
  ogrnLength = 13;
  dossierAccessEnum = DossierAccessEnum;

  constructor(protected dossierService: DossierService,
              protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  ngOnInit(): void {
    setTimeout( () => {
      this.rezidentRfSub = this.rezidentRf.valueChanges.pipe(delay(0)).subscribe(() => {
        this.initFields();
      });
      if (this.rezidentRf) {
        this.initFields();
        this.formGroupValueChangesSub = this.formGroupApp.valueChanges.subscribe(() => {
          this.initFields();
        });
        this.addUnsubscribable(this.rezidentRfSub);
        this.addUnsubscribable(this.formGroupValueChangesSub);
      }
    });
    // TODO странное использование таких значений typeOrg, у нас в системе везде значения из ClientTypeEnum
    this.ogrnLength = this.typeOrg === 'ul' ? 13 : 15;
  }

  private updateFieldValidator = (isReq: boolean, fieldName: string) => {
    const control = this.formGroupApp.get(fieldName);
    if (isReq) { control.setValidators([Validators.required]); }
    if (!isReq) { control.setValidators([]); }
    control.updateValueAndValidity({emitEvent: false});
  };

  initFields(): void {
    const isRezidentRf = this.rezidentRf.value;
    if (!this.formGroupApp.disabled) {
      const ogrn = this.f('ogrn') as FormControl; // ОГРН
      const dateReg = this.f('dateReg') as FormControl; // Дата государственной регистрации
      const regNum = this.f('regNum') as FormControl; // Регистрационный номер
      const dateRegIn = this.f('dateRegIn') as FormControl; // Дата регистрации
      const regNumSub = this.f('regNumSub') as FormControl; // Номер записи (НЗА)/реестровый номер
      const dateRec = this.f('dateRec') as FormControl; // Дата внесения записи
      const docName = this.f('docName') as FormControl; // Наименование учредительного документа (договора/соглашения)

      const conditionTypePage = [
        DossierAccessEnum.SVEDCLIENT,
        DossierAccessEnum.SVEDIOU,
        DossierAccessEnum.SVEDPREDST
      ].includes(this.typePage);

      if (isRezidentRf) {
        this.enableControls(ogrn, dateReg);
        this.disableControls(regNum, dateRegIn, regNumSub, dateRec, docName)
        this._setReqFields(conditionTypePage || isValue(ogrn), 'dateReg');
        this._setReqFields(conditionTypePage || isValue(dateReg), 'ogrn');
        this._setReqFields(isValue(ogrn), 'nameRegOrg');
      } else {
        this.enableControls(regNum, dateRegIn, regNumSub, dateRec, docName);
        this.disableControls(ogrn, dateReg);
        this._setReqFields(conditionTypePage, 'regNum', 'dateRegIn')
        this._setReqFields(isValue(regNum), 'nameRegOrg');
      }

      function isValue(control: FormControl){
        return !(control.value === null || control.value === "");
      }
    }
  }

  private _setReqFields(value: boolean, ...fields): void {
    fields.forEach(fieldName => {
      this.updateFieldValidator(value, fieldName);
    });
  }

  getTitleGosReg(): string {
    return this.typeOrg === 'ul' ? 'Данные о государственной регистрации (для российских юридических лиц)'
      : 'Данные о государственной регистрации';
  }

  ngOnDestroy(): void {
    this.unsubscribable();
  }

}
