export enum CheckDictEnum {
  MVK = 'mvk',
  TERROR = 'terror',
  FROMU = 'fromu',
  OTKAZ = 'otkaz',
  FTS = 'fts',
  RISK_GROUP = 'riskGroup',
  MSFOUND = 'msfound',
  MSLEAD = 'mslead',
  MS_ADR_INFO = 'ms_adr_info',
  PASSPORT = 'passport',
}
