import {PublicInfoType} from "./publicInfoType.model";
import {ContactType} from "./contactType.model";
import {RegInfoType} from "./regInfoType.model";
import {OkvedType} from "./okvedType.model";
import {DocumentInfoType} from "./documentInfoType.model";
import {PersonalDataType} from "./personalDataType.model";
import {FIOType} from "./fioType.model";
import {AddressInfoType} from "./addressInfoType.model";
import {LicenseType} from "./licenseType.model";

export class SvedIPType {
  fio: FIOType = new FIOType();
  personalData: PersonalDataType = new PersonalDataType();
  documentInfo: DocumentInfoType = new DocumentInfoType();
  okved: OkvedType = undefined;
  regInfo: RegInfoType = new RegInfoType();
  licenseInfo?: Array<LicenseType>;
  addressInfo: AddressInfoType = new AddressInfoType();
  contactInfo: ContactType = new ContactType();
  publicInfo?: PublicInfoType = new PublicInfoType();
}
