import {Directive, HostListener} from "@angular/core";
import {NgControl} from "@angular/forms";
/**
 * Для денежного формата использовать слудующие атрибуты:
 * thousandSeparator разделитель разрядов
 * separatorLimit - количество десятков целой части
 * separator.n - разряды дробной части (mask="separator.2" - для двух знаков после запятой)
 * appMoney - директива для денежных типов
 * @example mask="separator.0" thousandSeparator=" " separatorLimit="100000000000000000" appMoney
 */
@Directive({selector: '[appMoney]'})
export class MoneyDirective {


  constructor(private control: NgControl) {
  }

  @HostListener('blur', ['$event'])
  onBlur(event) {
    let value = event.target.value;
    if (value) {
      value = value.toString();
      // Проверка на 00 в конце
      const part = value.split(".");
      if (part.length > 1) {
        const zeroCheck = Number(part[1]);
        if (zeroCheck === 0) {
          value = part[0];
          this.control.control.patchValue(value);
        }
      }
      // Проверка на . в конце
      if (value.charAt(value.length - 1) === '.') {
        value = value.substring(0, value.length - 1);
        this.control.control.patchValue(value);
      }
    }
    // Если пусто - ставим null
    if (value === "") {
      this.control.control.patchValue(null);
    }
  }
}


