// Предписанные поля для 407-Положение
export const prescribedFields_P407_ANSWER = [
    //Вкладка Информационная часть
    "infChast.svedIuL.adrIuL.region",
    "infChast.svedIuL.inNaimIuL",
    "infChast.svedIuL.sokrNaimIuL",
    "infChast.svedIuL.adrIuL.indeks",
    "infChast.svedIuL.adrIuL.dom",
    "infChast.svedIuL.adrIuL.kodSubieektaPoOKATO",
    "infChast.svedIuL.adrIuL.ulitsa",
    "infChast.svedIuL.adrIuL.korp",
    "infChast.svedIuL.adrIuL.of",
    "infChast.svedIuL.adrIuL.region",
    //Вкладка Сведения об адресах ФЛ/ЮЛ/ИП
    "infChast.razdel1.adrReg.region",
    "infChast.razdel1.adrReg.indeks",
    "infChast.razdel1.adrReg.kodSubieektaPoOKATO",
    "infChast.razdel1.adrReg.ulitsa",
    "infChast.razdel1.adrReg.dom",
    "infChast.razdel1.adrReg.korp",
    "infChast.razdel1.adrReg.of",
    //Вкладка Сведения о бенефициарном владельце
    "infChast.razdel4.mestoRozhdFL",
    "infChast.razdel5.razdel51.svedBenefits.innfl",
    "infChast.razdel5.razdel51.svedBenefits.mestoRozhdFL.kodSubieektaPoOKATO",
    "infChast.razdel5.razdel51.benefitsAdrReg.region",
    "infChast.razdel5.razdel51.svedBenefits.mestoRozhdFL.region",
  ]