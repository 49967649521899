import {ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot} from "@angular/router";
import { Location } from '@angular/common';
import {DossierComponent} from "../components/clientDossierList/dossier";
import {DossierService} from "./dossier.service";
import {Injectable} from "@angular/core";
import {ConfirmComponent} from "@amlCore/modals";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

/**
 * Гуард проверки выхода с досье с не сохраненными вкладками
 */
@Injectable()
export class ConfirmExitDossierGuard implements CanDeactivate<DossierComponent> {

  nextState: RouterStateSnapshot;

  constructor(private dossierService: DossierService,
              private modalService: NgbModal,
              private readonly router: Router,
              private readonly location: Location) {
  }


  canDeactivate(component: DossierComponent,
                currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot,
                nextState?: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      /**
       * Проверяем все ли вкладки сохранены
       */
      if (!this.dossierService.checkSaveDataDossier()) {
        this.nextState = nextState;
        const modal = this.modalService.open(ConfirmComponent);
        modal.componentInstance.text = "Вы точно хотите выйти из досье? Не все вкладки сохранены!";
        modal.componentInstance.isOutputText = false;
        modal.result.then(data => {
          this.dossierService.stopEdit(component.dossierId);
          this.dossierService.removeDossierIdStore()
          resolve(true)
        }, cancel => {

          //обход бага Angular https://github.com/angular/angular/issues/13586#issuecomment-402250031
          const currentUrlTree = this.router.createUrlTree([], currentRoute);
          const currentUrl = currentUrlTree.toString();
          this.location.go(currentUrl);

          resolve(false);
        });
      } else {
        this.dossierService.getToken() && this.dossierService.stopEdit(component.dossierId);
        this.dossierService.removeDossierIdStore()
        resolve(true);
      }
    });
  }
}
