import {Injectable} from '@angular/core';
import {ApiService} from "@amlCore/services";


@Injectable()
export class FiasService {
  private strStatus = {
    1: "стр.",
    2: "соор.",
    3: "литера "
  };

  constructor(private api: ApiService) {
  }

  findHouse(body) {
    return this.api.post('/rest/fias/findhouse', body);
  }

  getFIASAddress(aoguid: string) {
    return this.api.get('/rest/fias/address/' + aoguid);
  }

  getFIASAddressAsList(aoguid: string) {
    return this.api.get('/rest/fias/obj/' + aoguid);
  }

  findObj(body) {
    return this.api.post('/rest/fias/findObj', body);
  }

  getStrStatus(type) {
    const result = this.strStatus[type];
    return result ? result : '';
  }
}
