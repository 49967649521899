import {DossierT250, DossierT50, DossierDate} from "./base.model";
import {DictType} from "./dictType.model";
import {ResidenceDocInfoType} from "./residenceDocInfoType.model";
import {MigrationCardInfoType} from "./migrationCardInfoType.model";

export class DocumentInfoType {
  nationality: DictType = undefined;
  docType: DictType = undefined;
  docOther: DossierT250 = '';
  series?: DossierT50 = '';
  number?: DossierT50 = '';
  organ: DossierT250 = '';
  dateDoc: DossierDate = '';
  kodPodr?: DossierT250 = '';
  migrationCardInfo?: MigrationCardInfoType = new MigrationCardInfoType();
  residenceDocInfo?: ResidenceDocInfoType = new ResidenceDocInfoType();
}
