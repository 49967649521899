import {DossierINNType, DossierKPPType, DossierOKPOULType, DossierT11} from "./base.model";
import {ContactType} from "./contactType.model";
import {RegInfoType} from "./regInfoType.model";
import {OkvedType} from "./okvedType.model";
import {DictType} from "./dictType.model";
import {OrgNameType} from "./orgNameType.model";
import {AddressInfoType} from "./addressInfoType.model";
import {LicenseType} from "./licenseType.model";

export class SvedULType {
  orgName: OrgNameType = new OrgNameType();
  opf: DictType = undefined;
  inn: DossierINNType = '';
  kpp: DossierKPPType = '';
  okpo: DossierOKPOULType = '';
  okfs: DictType = undefined;
  okved: OkvedType = new OkvedType();
  oktmo?: DossierT11 = '';
  regInfo: RegInfoType = new RegInfoType();
  licenseInfo?: Array<LicenseType> = undefined;
  addressInfo: AddressInfoType = new AddressInfoType();
  contactInfo: ContactType = new ContactType();
}
