import {Component, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {DossierService} from "../../../../../services";
import {DictionaryService, ValidatorService} from "@amlCore/services";
import {FormControl, Validators} from "@angular/forms";
import {Unsubscribable} from "rxjs";
import {DossierBaseModel} from "../../dossierBaseModel";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {LicenseInfoDossierComponent} from "../licenseInfo";
import {ErrorFLKItemType} from "@amlCore/models";
import {AlertPanelService} from "@amlCore/components";

@Component({
  selector: 'app-sved-inbul-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './svedInbULDossier.component.html'
})
export class SvedInbULDossierComponent extends DossierBaseSubComponent implements OnInit, OnDestroy {
  @Input() rezidentRf: FormControl;
  rezidentRfValueChanges: Unsubscribable;
  isInnReq = false; // признак необходимости заполенения поля ИНН (КИО)
  isKppReq = false;
  isOkvedShow = false;
  isLicenseInfoShow = false;
  @ViewChild('licenseInfo') licenseInfo: LicenseInfoDossierComponent;

  constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
              private validationSrv: ValidatorService, protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  ngOnInit(): void {
    this.initTypePage();
    this.loadRemoteData(['okopf']);
    this.initRezidentRf(this.rezidentRf.value);
    this.rezidentRfValueChanges = this.rezidentRf.valueChanges.subscribe((value) => {
      this.initRezidentRf(value);
    });
    this.addUnsubscribable(this.rezidentRfValueChanges);
  }

  /**
   * Инициализация typePage - скрываем некоторые части формы
   */
  initTypePage(): void {
    if (['svedClient', 'svedPredst', 'svedVPR', 'partner'].includes(this.typePage)) {
      this.isOkvedShow = true;
      this.isLicenseInfoShow = true;
    }
  }

  initRezidentRf(isRezidentRf: boolean): void {
    const validatorInn = this.validationSrv.getValidation({
      max: 10,
      isINN: 'INN_KIO'
    });
    const validatorKpp = this.validationSrv.getValidation({
      max: 9
    });
    this.isInnReq = false;
    this.isKppReq = false;
    if (isRezidentRf) {
      // Поле "ИНН" и "КПП" обязательное если rezidentRf = true
      validatorInn.push(Validators.required);
      validatorKpp.push(Validators.required);
      this.isKppReq = true;
      this.isInnReq = true;
    }
    const inn = this.f('inn') as FormControl;
    const kpp = this.f('kpp') as FormControl;
    inn.setValidators(validatorInn);
    kpp.setValidators(validatorKpp)
    inn.updateValueAndValidity({onlySelf: true});
    kpp.updateValueAndValidity({onlySelf: true});
  }

  ngOnDestroy(): void {
    this.unsubscribable();
  }

  openLicenseInfo(event: ErrorFLKItemType): void {
    if (event) {
      this.licenseInfo.openWindow(this.dossierService.errorFLKTable);
    }
  }

}
