import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DictType, DopInfoType} from "../../../../models/dossier";
import {DossierService} from "../../../../services";
import {IDossierSubService} from "../IDossierSub.service";
import {DossierBasePageComponent} from "../dossierBasePage.component";
import {DopInfoDossierService} from "./dopInfoDossier.service";
import {DictionaryService, UserService} from "@amlCore/services";
import {AlertPanelService} from "@amlCore/components";
import {Subject, Unsubscribable} from "rxjs";
import {ErrorFLKItemType} from "@amlCore/models";
import {NetworkDossierComponent} from "../components/network";
import {Utils} from "@amlCore/utils";
import {Validators} from "@angular/forms";
import {ClientTypeEnum} from "@amlCore/enums";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FileSaverService} from "ngx-filesaver";
import {takeUntil} from "rxjs/operators";


@Component({
  selector: 'app-dop-info-dossier',
  styleUrls: ['../dossier.css'],
  templateUrl: './dopInfoDossier.component.html'
})
export class DopInfoDossierComponent extends DossierBasePageComponent implements OnInit, OnDestroy {

  modelPage: DopInfoType;
  isTableTab = false;

  companyCategoryList: Array<DictType> = [];
  accountingServiceTypeList: Array<DictType> = [];
  taxationSystemList: Array<DictType> = [];
  organUprList: Array<DictType> = [];
  isLeastCapitalReq = false;
  isCapitalReq = false; // необходимо заполнить сведения уставного капитала. если значение поля true;
  isRequiredFieldOrganUpr = true;
  isRequiredFieldCategory = true;
  $destroy = new Subject();
  @ViewChild('network') network: NetworkDossierComponent;

  constructor(protected dossierService: DossierService,
              protected route: ActivatedRoute,
              private dopInfoDossierService: DopInfoDossierService,
              protected dictSrv: DictionaryService,
              protected router: Router,
              protected userService: UserService,
              protected alertPanelSrv: AlertPanelService,
              protected modalService: NgbModal,
              protected fSaver: FileSaverService) {
    super(dossierService, route, dictSrv, router, userService, alertPanelSrv, modalService, fSaver);
  }

  ngOnInit() {
    // Инициализация вкладки
    this.modelPage = this.initPage();
    // Загрузка справочников
    this.companyCategoryList = this.dictSrv.getStaticData('companyCategory');
    this.accountingServiceTypeList = this.dictSrv.getStaticData('accountingServiceType');
    this.dictSrv.getRemoteDataCodeSort("taxationsystem").then((result => {
      this.taxationSystemList = result;
    }));
    this.organUprList = this.dictSrv.getStaticData('organUpr');
    this.f('dopInfo.organUpr').valueChanges.pipe(takeUntil(this.$destroy)).subscribe(value => {
      this.initOrganUpr(value);
    });
    const organUprValue = this.f('dopInfo.organUpr').value as DictType;
    if (organUprValue && organUprValue.code) {
      this.initOrganUpr(organUprValue);
    }
    this.focusObject = {
      dopInfo: this.generateFocusObject(new DopInfoType())
    };

    //настройка формы в зависимости от типа клиента
    //при обновлении страницы с открытым подразделом досье, тип клиента может подгрузиться не сразу
    if(this.clientType)
      this.initFormByClientType();
    else{
      const svedClientSubscription = this.dossierService.svedClientDataSubject.pipe(takeUntil(this.$destroy)).subscribe((data) => {
        if(data!=null){
          this.initFormByClientType();
          svedClientSubscription.unsubscribe();
        }
      });
    }
  }

  private initFormByClientType(): void {
    if (ClientTypeEnum.INDIVIDUAL_ENTREPRENEUR === this.clientType) {
      this.setControlValidator('dopInfo.category', true);
      this.isRequiredFieldOrganUpr = false;
      this.isRequiredFieldCategory = true;
      const organUpr = this.f('dopInfo.organUpr');
      organUpr.setValidators([]);
      organUpr.updateValueAndValidity();
    } else if (ClientTypeEnum.LEGAL_ENTITY === this.clientType) {
      this.isRequiredFieldOrganUpr = true;
      this.isRequiredFieldCategory = true;
      this.setControlValidator('dopInfo.organUpr', true);
      this.setControlValidator('dopInfo.category', true);

      this.isLeastCapitalReq = true;
      this.isCapitalReq = true;
      this.setControlValidator('dopInfo.leastCapital', this.isLeastCapitalReq);
      this.setControlValidator('dopInfo.capital', this.isCapitalReq);
    } else {
      this._setNotReqFields();
    }
  }

  private _setNotReqFields(): void {
    this.isRequiredFieldCategory = false;
    this.isRequiredFieldOrganUpr = false;
    const organUpr = this.f('dopInfo.organUpr');
    const category = this.f('dopInfo.category');
    organUpr.setValidators([]);
    category.setValidators([]);
    organUpr.updateValueAndValidity();
    category.updateValueAndValidity();
  }


  /**
   * Инициализация модели
   * Преобразуем в нужный формат
   * @param model - данные сервера
   */
  initModel(model) {
    let newModel;
    this.partId = null;
    if (model) {
      const partIdList: string[] = Object.keys(model);
      newModel = model[partIdList[0]];
      Utils.deleteEmptyObj(newModel);
    } else {
      newModel = null;
    }
    const formData = {};
    formData[this.typePage] = newModel;
    return formData;
  }

  initOrganUpr(value) {
    if (!this.panelForm.disabled) {
      this.f('dopInfo.organUprOther').disable();
      if (value) {
        const type = value.code;
        if (type) {
          if (type === "3") {
            this.f('dopInfo.organUprOther').enable();
          }
        }
      }
    }
  }

  setControlValidator(path: string, isReq: boolean): void {
    if (isReq) {
      const control = this.f(path);
      control.setValidators([Validators.required]);
      control.updateValueAndValidity({onlySelf: true});
    }
  }

  openNetwork(event: ErrorFLKItemType) {
    if (event) {
      this.network.openWindow(this.dossierService.errorFLKTable);
    }
  }

  getService(): IDossierSubService {
    return this.dopInfoDossierService;
  }

  /**
   * Своя обработка фокусировки на ошибках для раздела
   * @param key - путь до поля с ошибкой
   */
  public isFocus(key: string): boolean {
    return this.focusObject.dopInfo ? this.focusObject.dopInfo[key] : false;
  }

  destroy() {
    // ТУТ писать реализацию ngOnDestroy для компонента
    this.$destroy.next();
    this.$destroy.complete();
  }

}
