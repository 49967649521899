import {Component, OnInit, OnDestroy, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {FormGroup, AbstractControl} from "@angular/forms";
import {DossierService} from "../../services";
import {DictionaryService, ResourceService} from "@amlCore/services";
import {INotice} from "../../interfaces";
import {IDictionaryItem} from "@amlCore/interfaces";
import {Observable, Unsubscribable} from "rxjs";
import {UserService} from '@amlCore/services';
import {AlertPanelComponent, AlertPanelService} from "@amlCore/components";
import {IClientData} from "../../models";
import {NoticeService} from "./notice.service";
import {Utils} from "@amlCore/utils";
import {DossierAccessEnum} from "@amlCore/enums";
import {User} from "../../../core/models";
import {ExecutorDataModel} from "../../models/executorData.model";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ExecutionTransferWindowComponent} from "../executionTransfer";
import {finalize} from "rxjs/operators";

@Component({
    selector: "app-notice-list-notice-form",
    templateUrl: "./noticeForm.component.html"
})

export class NoticeFormComponent implements OnInit, OnDestroy {
    @ViewChild('alertObj') alertPanelComponent: AlertPanelComponent;
    notice: INotice;
    isFormDirty = false; // Признак изменений на форме (умный dirty по хэшу формы)
    isSaveForm = false; // Признак, что форма сохранена
    viewForm: FormGroup;
    isReadOnlyAccess = this.userSrv.getAccess('notices').readOnly;
    isLoaded = false; // Признак, что форма загружена
    private viewFormSub: Unsubscribable;
    submitted = false;
    userInfo = this.userSrv.getUserInfo();
    dossierAccessEnum = DossierAccessEnum;
    typeSubjects: IDictionaryItem<{
       code: string;
       name: string;
    }>[] = [];
    public usersWithPermissions: User[] = [];
    private modal;
    constructor(private route: ActivatedRoute,
                protected router: Router,
                private dossierSrv: DossierService,
                private noticeSrv: NoticeService,
                private alertPanelSrv: AlertPanelService,
                private _resourceSrv: ResourceService,
                private userSrv: UserService,
                private modalService: NgbModal,
                protected dictSrv: DictionaryService) {
    }

    ngOnInit(): void {
        this._createViewForm();
        if (this.route.snapshot.params.id) {
            this._loadNotice(this.route.snapshot.params.id);
            this._resourceSrv.getUsersByNoticeId(this.route.snapshot.params.id).subscribe((users) => {
                this.usersWithPermissions = users;
            });
        }
        this.dictSrv.getRemoteData('typesubjects').then((result) => {
          this.typeSubjects = result;
        });
    }

    ngOnDestroy(): void {
        if(!this.isReadOnlyAccess){
            this.noticeSrv.stopEdit(this.route.snapshot.params.id);
        }
        this.viewFormSub.unsubscribe();
    }

    initEditMode(): void {
        if(!this.isReadOnlyAccess){
            this.isLoaded = null;
            this.noticeSrv.startEdit(this.route.snapshot.params.id).pipe(finalize(()=> this.isLoaded = true)).subscribe(response => {
                if(!response?.result) {
                    this.isReadOnlyAccess = true;
                    this._checkAccessUser();
                    this.alertPanelComponent.open(
                      this.alertPanelSrv.getErrorMsg(response?.message ?? "Ошибка редактирования документа", false)
                    );
                }
            });
        }
    }

    f(filed: string): AbstractControl {
        return this.viewForm.get(filed);
    }

    getUserName(): string {
        return this.userInfo.fio;
    }

    /**
     * Метод открытия досье
     * @param clientInfo - данные по клиенту досье
     */
    openDossier(clientInfo: IClientData): void {
        if (clientInfo.state !== 1) {
            this.dossierSrv.setPageId(clientInfo.partId);
        }
        window.open(`dossier/${clientInfo.dossierId}/${clientInfo.typeSubject}`, '_blank');
    }

    /**
     * Подсказка для кнопки "Исполнено"
     */
    getTitleResolveBtn(): string {
        return (!this.isSaveForm || this.isFormDirty) ? 'Необходимо сохранить введенные данные' : '';
    }

    getTypeSubjectName(code: string): string {
        return this.dictSrv.getTypeSubjectName(code, this.typeSubjects);
    }

    /**
     * Передать на исполнение
     */
    changeExecutor(): void {
        this.modal = this.modalService.open(ExecutionTransferWindowComponent, {
            backdrop: 'static',
            keyboard: false,
            size: 'xl',
            scrollable: true
        });
        // Передаем данные в компонент
        this.modal.componentInstance.data=this.getExecutorData();
        this.modal.componentInstance.usersList=this.usersWithPermissions;
        this.modal.componentInstance.onSave.subscribe(value => {
            this._sendNoticeToUserEmail(value);
        });
    }

    /**
     * Метод сохранения оповещения
     */
    saveNotice(): void {
        if (this.viewForm.invalid) {
            this.submitted = true;
            this.alertPanelComponent.open(this.alertPanelSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме'));
            return;
        }
        const editableDataNotice = Utils.clone(this.viewForm.value);
        editableDataNotice.id = this.notice.id;
        this.noticeSrv.saveDataNotice(editableDataNotice).subscribe(() => {
            // обновляем контрольную хеш сумму формы после сохранения
            this.updateForm();
            this.isSaveForm = true;
        });
    }

    /**
     * Метод установки оповещения в статус исполнено
     */
    resolveNotice(): void {
        if (this.viewForm.invalid) {
            this.submitted = true;
            this.alertPanelComponent.open(this.alertPanelSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме'));
            return;
        }
        this.isSaveForm = false;
        this.noticeSrv.resolveNoticeById(this.notice.id).subscribe((data: INotice) => {
            this.updateForm(data);
        });
    }

    /**
     * Метод формирования реактивной формы
     * и запуск методов формирующих подписки
     * общую для формы и полей ввода
     */
    private _createViewForm(): void {
        this.viewForm = this.noticeSrv.getForm();
        this._initSubscribesFormControl();
    }

    private _checkAccessUser(): void {
        if (this.isReadOnlyAccess) {
            this.viewForm.disable();
        }
    }

    /**
     * Метод инициализации подписок для полей ввода на форме
     * с целью ввода данных в модель.
     */
    private _initSubscribesFormControl(): void {
        this.viewFormSub = this.viewForm.valueChanges.subscribe(() => {
            this._checkHashSumFormModel();
        });
    }

    /**
     * Метод загрузки оповещения по id
     * @param id String
     */
    private _loadNotice(id: string): void {
        this.noticeSrv.getNoticeById(id).subscribe((data: INotice) => {
            this.updateForm(data, true);
        });
    }

    private _sendNoticeToUserEmail(data: ExecutorDataModel): void {
        const body = { userId: data.userId, executionDate: data.date };
        const noticeId = this.notice.id;
        this.userSrv.checkNoticeExecutor(body, noticeId).subscribe(() => {
            this.notice.executorId = data.userId;
            this.notice.executionDate = data.date;
            this?.modal?.componentInstance?.afterSave();
        });
    }

    /**
     * Установка признака dirty по хэшу
     */
    private _checkHashSumFormModel(): void {
        this.isFormDirty = !this.noticeSrv.checkFormDataCache(this.viewForm.value);
    }

    getExecutorData(): ExecutorDataModel {
        return {
            userId: this.notice.executorId,
            date: this.notice.executionDate
        };
    }

    getExecutorName(executorId: String){
        return this.usersWithPermissions.find(u=>u.id == executorId)?.fullName || executorId;
    }

    updateForm(data?: INotice, isInit?: boolean): void{
        if(data){
            this.viewForm.patchValue(data);
            this.notice = data;
        }

        if(this.notice.result){
            this.isReadOnlyAccess = true;
        }
        if(isInit){
            this.initEditMode();
        }
        this._checkAccessUser();
        // создание контрольной хеш суммы
        this.noticeSrv.setFormDataCache(this.viewForm.value);
        this._checkHashSumFormModel();
    }
}
