import {Component, forwardRef, Input, OnDestroy} from "@angular/core";
import {ControlValueAccessor, FormArray, FormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator} from "@angular/forms";
import {MultiFieldBase} from "./multiFieldBase";
import {ValidatorService} from "@amlCore/services";

@Component({
    selector: 'app-multiple-filed',
    templateUrl: './multipleFiled.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => MultipleFiledComponent),
        multi: true
      },
      {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => MultipleFiledComponent),
        multi: true
      }, ValidatorService]
})
export class MultipleFiledComponent extends MultiFieldBase
  implements ControlValueAccessor, Validator, OnDestroy {
    @Input() maxlengthApp: string | number = '2000';
    @Input() minlengthApp: string | number = '';
    // для получении валидации FormControl in FormArray
    @Input() formArrayName: string;
    listItem: Array<any> = [];

    constructor(protected fb: FormBuilder,
                protected validSrv: ValidatorService) {
      super(fb);
    }

    currentAbstractControl = (value = null) =>
      this.fb.control(value, this.validSrv.getValidation(this.formArrayName))

    getModelFromView(oldModel: Array<string> | FormArray): void {
      Array.isArray(oldModel)
        ? oldModel.forEach(value => this.listItem.push({ value }))
        : oldModel.controls.forEach(formControl => this.listItem.push({ value: formControl.value }));
    }

    add(): void {
      this.listItem.push({ value: "" });
      if (!this.formArray) this.updateValue();
    }

    /**
     * Преобразить данные для передачи в родительский FormControl в виде Array<string>
     * @param modelView - данные
     */
    getModelFromSave(modelView: Array<any>): string[] {
      const list: string[] = [];
      modelView.forEach((item) => {
        if (!(item?.value && item?.value?.length > 0)) return;
        list.push(item.value);
      });
      return list;
    }

    /**
     * При сохранении формы удаляем пустые поля
     */
    ngOnDestroy() {
      this.formArray?.controls?.forEach((formControl, index) => {
        if (!formControl.value) this.formArray.removeAt(index);
      });
    }
}
