import {DossierN3, DossierT100, DossierT250} from "./base.model";
import {ClientInfo} from "./clientInfo.model";
import {PartItemArray} from "../partItemArray.model";

export class SvedVPRType extends PartItemArray{
  clientInfo: ClientInfo = new ClientInfo();
  part: DossierN3 = '';
  dateFrom = false;
  dateEnd = false;
  vprProfit: DossierN3 = '';
  vprOther: DossierT100 = '';
  reason: DossierT250 = '';
}
