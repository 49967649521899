import {Directive, EventEmitter, HostListener, OnInit, Output} from '@angular/core';

/**
 * Сортировка таблицы. Директива работает вместе с компонентом SortComponent.
 * Директива указывается в <tr с указанием метода сортировки.
 *  <tr appSortTable (sort)="onSort($event)">
 *  <th scope="col"><app-sort sn="name" [sort]="sort" [dir]="dir">Наименование/ФИО</app-sort></th>
 *  ....
 *  <td>{{ el.name }}</td>
 */
@Directive({selector: '[appSortTable]'})
export class SortTableDirective implements OnInit {
  constructor() {
  }

  @Output('sort') click: any = new EventEmitter<any>();

  @HostListener('click', ['$event.target']) onClick(event) {
    let element = null;
    let result = null;
    if (event.localName === 'th') {
      return;
      // element = event.firstElementChild; // todo убрать return если надо будет сортировать по клику на всю плашку
    } else {
      element = event.offsetParent ? event.offsetParent.firstElementChild : null;
    }
    if (element) {
      result = {
        sort: element.getAttribute('sn'),
        dir: element.getAttribute('ng-reflect-dir')
      };
      this.click.emit(result);
    }
  }

  ngOnInit() {
  }

}
