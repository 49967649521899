import {Component, OnInit, ViewChild} from "@angular/core";
import {DossierService} from "../../../../../services";
import {DictionaryService} from "@amlCore/services";
import {AlertPanelService, ITable, ITableCallBack, ITableItemDescriptor, TableCompComponent} from "@amlCore/components";
import {DossierBaseModel} from "../../dossierBaseModel";
import {NetworkInfoDossierComponent} from "../networkInfo";
import {FormBuilder} from "@angular/forms";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {ErrorFLKModel} from "@amlCore/models";
import {NetworkInfo} from "../../../../../models/dossier";

@Component({
  selector: 'app-network-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './networkDossier.component.html'
})
export class NetworkDossierComponent extends DossierBaseSubComponent implements OnInit, ITable {

  @ViewChild('tableComp') tableComp: TableCompComponent;
  itemDescriptor: ITableItemDescriptor;

  constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
              private dossierBaseModel: DossierBaseModel, protected formBuilder: FormBuilder,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  ngOnInit() {
    // Описание элемента таблицы
    this.itemDescriptor = {
      create: this.dossierBaseModel.getNetworkInfo.bind(this.dossierBaseModel),
      component: NetworkInfoDossierComponent,
      params: {
        typePage: this.typePage
      }
    } as ITableItemDescriptor;
  }

  callbackItemOpened(event: ITableCallBack){
    if(event?.editIndex != null && this.errorFLKData && this.errorFLKData[event.editIndex]) {
      this.tableComp.initErrorFLK(this.errorFLKData[event.editIndex]);
    }
  }

  callbackItemClosed(event: ITableCallBack) {
    if (event.editIndex !== null && event.editIndex !== -1) {
      // При закрытие элемента думаем, что ошибки поправлены
      const row = (this.formGroupApp.value[event.editIndex] as NetworkInfo).row;
      this.setValidFlkError(row);
    }
  }

  openWindow(error: ErrorFLKModel){
    let index = 0;
    // Считываем row и ищем по нему index
    if (error && error.row !== null) {
      (this.formGroupApp.value as Array<NetworkInfo>).forEach((value, i) => {
        if (value.row === error.row) {
          index = i;
        }
      });
    }
    this.tableComp.showFlKError(index, error);
  }

}
