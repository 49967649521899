export class FiasModel {
  aoguid: string;
  formalname: string;
  regioncode: string;
  postalcode: null;
  shortname: string;
  fullname: string;
  aolevel: number;
  parentguid: string;
  aoid: string;
  actstatus: number;
  centstatus: number;
  livestatus: number;
  buildnum: string;
  strucnum: string;
  strstatus: string;
  houseid: string;
  housenum: string;
  final: boolean;
  $modfullname: string;
}
