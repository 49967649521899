import {Component, forwardRef, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {ViewDictionaryBase} from "../view-dictionary-base";
import {ICodeName} from "../../../models";

@Component({
  selector: 'app-dict-qref-static[items]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => QrefStaticViewComponent),
    multi: true
  }],
  template: `
    <label class="form-label">{{title}}
      <app-rq *ngIf="isFieldReq(formControl)" [isReadOnly]="isDisabled"></app-rq>
    </label>
    <app-custom-select [items]="items"
                       [returnOnlyId]="true"
                       [isDisabled]="readonly"
                       [formControl]="formControl"
                       [isInvalid]="submitted && formControl.errors">
      <ng-template let-item #labelTemplate>
        <b> {{ item.code }} </b>
        {{ item.name }}
      </ng-template>
      <ng-template let-item let-search="search" #optionTemplate>
        <div style="white-space: normal; min-height: 24px;">
          <b> {{ item.code }} </b>
          {{ item.name }}
        </div>
      </ng-template>
    </app-custom-select>
    <app-valid [logic]="formControl" [submitted]="submitted"></app-valid>
  `
})
export class QrefStaticViewComponent extends ViewDictionaryBase<ICodeName> {
  @Input() items: Array<ICodeName>;
  @Input() readonly: boolean;
  getDictionary = null;
}
