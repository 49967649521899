import {Component, OnDestroy, OnInit} from "@angular/core";
import {DossierService} from "../../../../../services";
import {DictionaryService} from "@amlCore/services";
import {OrgNameType} from "../../../../../models/dossier";
import {Unsubscribable} from "rxjs";
import {DossierBaseModel} from "../../dossierBaseModel";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {AlertPanelService} from "@amlCore/components";

@Component({
  selector: 'app-org-name-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './orgNameDossier.component.html'
})
export class OrgNameDossierComponent extends DossierBaseSubComponent implements OnInit, OnDestroy {
  /**
   * Валидация для полей через validObjects не нужна, тк валидация на уровне компонента
   */
  isOrgNameReq = true;
  orgNameValueChanges: Unsubscribable;

  constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  ngOnInit() {
    this.orgNameValueChanges = this.formGroupApp.valueChanges.subscribe((group: OrgNameType) => {
      this.initOrgName(group);
    });
    this.addUnsubscribable(this.orgNameValueChanges);
  }

  /**
   * Должно быть заполнено хотя бы одно поле
   * все объекты группы обязательны если не заполнено ни одно поле
   */
  initOrgName(group: OrgNameType) {
    this.isOrgNameReq = false;
    if (!group.orgNameFullIn && !group.orgNameFullRu && !group.orgNameIn && !group.orgNameRu) {
      this.isOrgNameReq = true;
    }
  }

  ngOnDestroy() {
    this.unsubscribable();
  }

}
