import {Component, OnDestroy, OnInit} from "@angular/core";
import {DossierService} from "../../../../../services";
import {AlertPanelService, ITableEditorItem, ITableItem} from "@amlCore/components";
import {Unsubscribable} from "rxjs";
import {DictionaryService} from "@amlCore/services";
import {FormGroup} from "@angular/forms";
import {DossierBaseModel} from "../../dossierBaseModel";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {LicenseType} from "../../../../../models/dossier";

@Component({
  selector: 'app-license-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './licenseDossier.component.html'
})
export class LicenseDossierComponent extends DossierBaseSubComponent implements OnInit, OnDestroy, ITableItem {

  titlePanel = 'Сведения о праве осуществления деятельности, подлежащей лицензированию';
  showTitle = true;
  unlimitedSub: Unsubscribable;
  isDateEndReq = false;
  isTableItem = false;

  constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  /**
   * Описание формы
   */
  getFormDescription(): FormGroup {
    return this.dossierBaseModel.getLicenseType();
  }

  /**
   * Инициализация FormGroup
   */
  initForm() {
    if (!this.isTableItem) {
      this.formGroupApp = this.getFormDescription();
    }
  }

  ngOnInit() {
    this.unlimitedSub = this.f('unlimited').valueChanges.subscribe(value => {
      this.initUnlimited(value);
    });
    this.initUnlimited(this.f('unlimited').value);
    this.addUnsubscribable(this.unlimitedSub);
    this.focusObject = this.generateFocusObject(new LicenseType());
    setTimeout(() => this.initErrorFLKSimpleField());
  }

  initUnlimited(value) {
    if (!this.formGroupApp.disabled) {
      if (value) {
        this.f('dateEnd').disable();
        this.isDateEndReq = false;
      } else {
        this.f('dateEnd').enable();
        this.isDateEndReq = true;
      }
    }
  }

  setSubmitted(value: boolean) {
    this.submitted = value;
  }

  getTitle(): string {
    return this.titlePanel;
  }

  /**
   * Инициализируем компонент как элемент формы просмотра таблицы
   * @param param.form - форма для связи
   * @param param.params - дополнительные параметры
   */
  initTableItem(param: ITableEditorItem): FormGroup {
    this.initForm();
    this.isTableItem = true;
    if (param.model) {
      this.formGroupApp.patchValue(param.model);
    }
    this.showTitle = false;
    if (param.params && param.params.typePage) {
      this.typePage = param.params.typePage;
    } else {
      console.log('Необходимо указать typePage у компонента');
    }
    this.isReadOnly = param.isReadOnly;
    if (this.isReadOnly) {
      this.formGroupApp.disable();
    }
    if (param.params.getListError) {
      // Ошибки по всей таблице
      const errorAll = param.params.getListError();
      const row = param.model ? param.model.row : null;
      this.initErrorFLKInTable(errorAll, row, false);
    }
    return this.formGroupApp;
  }

  ngOnDestroy() {
    this.unsubscribable();
  }
}
