import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ConfirmDeactivateGuard, ConfirmExitDossierGuard, NoAuthGuard} from './services';
import {ClientComponent} from "./client.component";
import {
  AccessDossierComponent,
  AttributesDossierComponent,
  BankAccountsDossierComponent,
  CheckClientFormComponent,
  CheckClientsComponent,
  CheckTransactionFormComponent,
  CheckTransactionsComponent,
  CleintsTransactionsComponent,
  ClientDossierListComponent,
  ClientTransactionFormComponent,
  DokReestrDossierComponent,
  DopInfoDossierComponent,
  DossierComponent,
  DossierFLKResolver,
  DossierPageResolver,
  DossierStatusResolver,
  OtherAccountsDossierComponent,
  PartnerDossierComponent,
  RiskAssessDossierComponent,
  SpkComponent,
  SvedAUDossierComponent,
  SvedBVDossierComponent,
  SvedClientDossierComponent,
  SvedIOUDossierComponent,
  SvedPredstDossierComponent,
  SvedTargetDossierComponent,
  SvedVPRDossierComponent,
} from './components';
import {DossierAccessEnum, getDossierAccessList, typePageEnum} from '@amlCore/enums';
import {AccessRouteGuard} from '@amlCore/services';
import {NoticeFormComponent, NoticeListComponent} from './components/notice';
import {ProfileComponent} from "./components/profile";

const accessDossier = getDossierAccessList();
const childRoutes: Routes = [
  {
    path: '',
    component: ClientComponent
  },
  {
    path: '',
    loadChildren: () => import('../registries/registries.module').then(m => m.RegistriesModule)
  },
  {
    path: 'dossier/list',
    component: ClientDossierListComponent,
    canActivate: [NoAuthGuard],
    data: {
      pageTitle: "Досье клиентов",
      typePage: typePageEnum.DOSSIER
    }
  },
  {
    path: 'dossier/check',
    component: CheckClientsComponent,
    canActivate: [NoAuthGuard],
    data: {
      pageTitle: "Результаты проверки",
      typePage: typePageEnum.DOSSIER_CHECK
    }
  },
  {
    path: 'dossier/notice',
    component: NoticeListComponent,
    canActivate: [NoAuthGuard],
    data: {
      pageTitle: "Результаты контроля дат",
      typePage: typePageEnum.NOTICE
    }
  },
  {
    path: 'clients-transactions',
    component: CleintsTransactionsComponent,
    canActivate: [NoAuthGuard],
    data: {
      pageTitle: "Операции клиентов",
      typePage: typePageEnum.CLEINTS_TRANSACTIONS
    }
  },
  {
    path: 'check-transactions',
    component: CheckTransactionsComponent,
    canActivate: [NoAuthGuard],
    data: {
      pageTitle: "Результаты анализа операций",
      typePage: typePageEnum.CHECK_TRANSACTIONS
    }
  },
  {
    path: 'spk',
    component: SpkComponent,
    canActivate: [NoAuthGuard],
    data: {
      pageTitle: "Проверка по перечням",
    }
  }
];
const routesDossier: Routes = [
  {
    path: 'svedClient',
    component: SvedClientDossierComponent,
    data: {
      typePage: DossierAccessEnum.SVEDCLIENT
    },
    resolve: {
      pageData: DossierPageResolver,
      errorFLK : DossierFLKResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'svedAU',
    component: SvedAUDossierComponent,
    data: {
      typePage: DossierAccessEnum.SVEDAU,
      refreshBeforeSave: true
    },
    resolve: {
      pageData: DossierPageResolver,
      errorFLK : DossierFLKResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'svedIOU',
    component: SvedIOUDossierComponent,
    data: {
      typePage: DossierAccessEnum.SVEDIOU,
      refreshBeforeSave: true
    },
    resolve: {
      pageData: DossierPageResolver,
      errorFLK : DossierFLKResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'svedPredst',
    component: SvedPredstDossierComponent,
    data: {
      typePage: DossierAccessEnum.SVEDPREDST,
      refreshBeforeSave: true
    },
    resolve: {
      pageData: DossierPageResolver,
      errorFLK : DossierFLKResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'svedVPR',
    component: SvedVPRDossierComponent,
    data: {
      typePage: DossierAccessEnum.SVEDVPR,
      refreshBeforeSave: true
    },
    resolve: {
      pageData: DossierPageResolver,
      errorFLK : DossierFLKResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'svedBV',
    component: SvedBVDossierComponent,
    data: {
      typePage: DossierAccessEnum.SVEDBV,
      refreshBeforeSave: true
    },
    resolve: {
      pageData: DossierPageResolver,
      errorFLK : DossierFLKResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'svedTarget',
    component: SvedTargetDossierComponent,
    data: {
      typePage: DossierAccessEnum.SVEDTARGET
    },
    resolve: {
      pageData: DossierPageResolver,
      errorFLK : DossierFLKResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'bankAccounts',
    component: BankAccountsDossierComponent,
    data: {
      typePage: DossierAccessEnum.BANKACCOUNTS,
      refreshBeforeSave: true
    },
    resolve: {
      pageData: DossierPageResolver,
      errorFLK : DossierFLKResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'dopInfo',
    component: DopInfoDossierComponent,
    data: {
      typePage: DossierAccessEnum.DOPINFO
    },
    resolve: {
      pageData: DossierPageResolver,
      errorFLK : DossierFLKResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'otherAccounts',
    component: OtherAccountsDossierComponent,
    data: {
      typePage: DossierAccessEnum.OTHERACCOUNTS
    },
    resolve: {
      pageData: DossierPageResolver,
      errorFLK : DossierFLKResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'attributes',
    component: AttributesDossierComponent,
    data: {
      typePage: DossierAccessEnum.ATTRIBUTES
    },
    resolve: {
      pageData: DossierPageResolver,
      errorFLK : DossierFLKResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'riskAssess',
    component: RiskAssessDossierComponent,
    data: {
      typePage: DossierAccessEnum.RISKASSESS
    },
    resolve: {
      pageData: DossierPageResolver,
      errorFLK : DossierFLKResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'dokReestr',
    component: DokReestrDossierComponent,
    data: {
      typePage: DossierAccessEnum.DOKREESTR
    },
    resolve: {
      pageData: DossierPageResolver,
      errorFLK : DossierFLKResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'checkClient',
    component: CheckClientsComponent,
    data: {
      isDossierPart: true
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'clients-transactions',
    component: CleintsTransactionsComponent,
    data: {
      isDossierPart: true
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'check-transactions-client',
    component: CheckTransactionsComponent,
    data: {
      isDossierPart: true
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'partner',
    component: PartnerDossierComponent,
    data: {
      typePage: DossierAccessEnum.PARTNER,
      refreshBeforeSave: true
    },
    resolve: {
      pageData: DossierPageResolver,
      errorFLK : DossierFLKResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'dossierAccess',
    component: AccessDossierComponent,
    data: {
      typePage: DossierAccessEnum.DOSSIERACCESS,
      refreshBeforeSave: true
    },
    resolve: {
      pageData: DossierPageResolver,
      errorFLK : DossierFLKResolver
    },
    runGuardsAndResolvers: 'always'
  }

];
const routes: Routes = [
  {
    path: 'registries',
    loadChildren: () => import('../registries/registries.module').then(m => m.RegistriesModule)
  },
  {
    path: 'cabinet',
    component: ClientComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'cabinet',
    component: ClientComponent,
    children: childRoutes
  },
  {
    path: 'create/dossier',
    component: DossierComponent,
    canActivate: [NoAuthGuard, AccessRouteGuard],
    canDeactivate: [ConfirmExitDossierGuard],
    data: {access: accessDossier},
    resolve: {
      statusDossier: DossierStatusResolver
    },
    children: routesDossier
  },
  {
    path: 'dossier/:id',
    component: DossierComponent,
    canActivate: [NoAuthGuard, AccessRouteGuard],
    canDeactivate: [ConfirmExitDossierGuard],
    data: {access: accessDossier},
    resolve: {
      statusDossier: DossierStatusResolver
    },
    children: routesDossier
  },
  {
    path: 'checkForm/:id',
    component: CheckClientFormComponent,
    canActivate: [AccessRouteGuard]
  },
  {
    path: 'noticeForm/:id',
    component: NoticeFormComponent,
    canActivate: [AccessRouteGuard]
  },
  {
    path: 'checkTransactionForm/:id',
    component: CheckTransactionFormComponent,
    canActivate: [AccessRouteGuard]
  },
  {
    path: 'clientTransactionForm/:id',
    component: ClientTransactionFormComponent,
    canActivate: [AccessRouteGuard],
    canDeactivate: [ConfirmDeactivateGuard]
  },
  {
    path: 'clientTransactionForm',
    component: ClientTransactionFormComponent,
    canActivate: [AccessRouteGuard],
    canDeactivate: [ConfirmDeactivateGuard]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    data: {access: 'cryptography'},
    canActivate: [AccessRouteGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
  onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class ClientRoutingModule {
}
