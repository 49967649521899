/**
 * API по базовым справочникам АМЛ.
 * API по базовым справочникам АМЛ.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Данные записи специализированного справочника стран.
 */
export interface QrefOkcm { 
    /**
     * Код. Ключевое поле таблицы.
     */
    kokcm?: string;
    /**
     * Двухзначных код.
     */
    alfa2?: string;
    /**
     * Трехзначный код.
     */
    alfa3?: string;
    /**
     * Страна.
     */
    ncmShort?: string;
    /**
     * Полное наименование.
     */
    ncm?: string;
    /**
     * Цифровой код страны (3 знака).
     */
    kokcm3?: string;
}

