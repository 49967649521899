import {Component, OnDestroy, OnInit} from '@angular/core';
import {DossierService} from '../../../../../services';
import {DictType} from '../../../../../models/dossier';
import {Unsubscribable} from 'rxjs';
import {DictionaryService} from '@amlCore/services';
import {DossierBaseModel} from '../../dossierBaseModel';
import {DossierBaseSubComponent} from '../dossierBaseSub.component';
import {AlertPanelService} from '@amlCore/components';
import {FormGroup} from '@angular/forms';
import {pairwise} from "rxjs/operators";

@Component({
  selector: 'app-document-info-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './documentInfoDossier.component.html'
})
export class DocumentInfoDossierComponent extends DossierBaseSubComponent implements OnInit, OnDestroy {

  nationalityList: Array<DictType> = [];
  docTypeList: Array<DictType> = [];
  docTypeValueChanges: Unsubscribable;
  nationalityValueChanges: Unsubscribable;

  constructor(protected readonly dossierService: DossierService,
              protected readonly dictSrv: DictionaryService,
              protected readonly dossierBaseModel: DossierBaseModel,
              protected readonly alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  ngOnInit() {
    Promise.all([
      this.dictSrv.getRemoteData('typecitizenship'),
      this.dictSrv.getRemoteData('documenttypecodes')
    ]).then(([_typecitizenship, _documenttypecodes]) => {
      this.nationalityList = _typecitizenship;
      this.docTypeList = _documenttypecodes;

      this.initDocOther(this.f('docType').value);
      this.docTypeValueChanges = this.f('docType').valueChanges.pipe(pairwise()).subscribe(([prev, next]: [DictType, DictType]) => {
        if(this.isPassportOfCitizenRF() && prev && prev.code!=next.code){
          this.formGroupApp.get('kodPodr').reset();
        }
        this.initDocOther(next);
      });

      this.initNationality(this.f('nationality').value);
      this.nationalityValueChanges = this.f('nationality').valueChanges.subscribe(value => {
        this.initNationality(value);
      });

      this.addUnsubscribable(this.docTypeValueChanges);
      this.addUnsubscribable(this.nationalityValueChanges);
    });
  }

  initDocOther(value) {
    if (!this.formGroupApp.disabled) {
      const docOther = this.f('docOther');
      if (this.isDocOtherEnabled(value?.code)) {
        docOther.enable();
      } else {
        docOther.disable();
      }
    }
  }

  isDocOtherEnabled(docTypeCode?: string): boolean{
    return ['28', '35', '40', '99'].includes(docTypeCode || this.f('docType')?.value?.code);
  }

  isSeriesRequired (docType: string) {
    return docType === '21';
  }

  isDocNumberRequired (docType: string) {
    return ['21', '22', '26', '28', '31', '32', '34', '35', '37', '40'].includes(docType);
  }

  initNationality(value) {
    if (!this.formGroupApp.disabled) {
      const migrationCardInfo = this.f('migrationCardInfo');
      const residenceDocInfo = this.f('residenceDocInfo');
      // TODO последствия асинхронной работы с реактивной формой!
      setTimeout(() => {
        migrationCardInfo.disable();
        residenceDocInfo.disable();
      });
      if (value) {
        const type = value.code;
        if (type && type !== '1') {
          setTimeout(() => {
            migrationCardInfo.enable();
            residenceDocInfo.enable();
          });
        }
      }
    }
  }

  onCustomCheckForm(form: FormGroup): boolean {
    const excludeControls = [
      'migrationCardInfo',
      'residenceDocInfo'
    ];
    return Object.keys(form.controls).some((key: string) => {
      if (excludeControls.includes(key)) {
        return;
      }
      return form.get(key).invalid;
    });
  }

  /*является ли документ паспортом гражданина РФ*/
  isPassportOfCitizenRF(): boolean {
    const value = this.f('docType').value;
    return value && value.code && value.code === '21';
  }

  ngOnDestroy() {
    this.unsubscribable();
  }

}
