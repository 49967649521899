import {DictType} from "./dictType.model";
import {DossierBool, DossierM50, DossierN5, DossierT2000, DossierT250, DossierT4000} from "./base.model";
import {NetworkInfo} from "./networkInfo.model";

export class DopInfoType {

  organUpr: DictType = undefined;
  organUprOther?: DossierT250 = '';
  category?: DictType = undefined;
  orc?: DossierBool = false;
  leastCapital?: DossierM50 = '';
  sho?: DossierBool = '';
  capital?: DossierM50 = '';
  asset: DossierT2000 = '';
  estate?: DossierT2000 = '';
  staffCount?: DossierN5 = '';
  wageFund?: DossierM50 = '';
  accountsDep?: DictType = undefined;
  activity?: DictType = undefined;
  income?: DossierT2000 = '';
  reputation?: DossierT4000 = '';
  network: Array<NetworkInfo> = undefined;
}
