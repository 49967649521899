import {Component, OnInit} from "@angular/core";
import {DossierService} from "../../../../../services";
import {DictType} from "../../../../../models/dossier";
import {DictionaryService} from "@amlCore/services";
import {DossierBaseModel} from "../../dossierBaseModel";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {AlertPanelService} from "@amlCore/components";
import {DossierAccessEnum} from "@amlCore/enums";
import {AbstractControl} from "@angular/forms";

@Component({
  selector: 'app-reg-info-cp-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './regInfoCPDossier.component.html'
})
export class RegInfoCPDossierComponent extends DossierBaseSubComponent implements OnInit {
  private readonly _codeVidViewOther = '0';
  dossierAccessEnum = DossierAccessEnum;
  vidList: DictType[] = [];
  isViewOtherField = false;

  constructor(protected dossierService: DossierService,
              protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  ngOnInit(): void {
    this._initOtherField();
    this.dictSrv.getRemoteData('typeactivityprivateentrepreneur').then((result: DictType[]) => {
      this.vidList = result.filter(r => r.code !=0).concat(result.filter(r => r.code == 0));
    });

    this.f('vid').valueChanges.subscribe((value: DictType) => {
      this.isViewOtherField = this._viewOtherField(value);
      setTimeout(() => this._toggleFieldOther(this.isViewOtherField));
    })
  }

  private _initOtherField(): void {
    const vidValue = this.f('vid').value;
    this.isViewOtherField = vidValue ? this._viewOtherField(vidValue) : false;
    this._toggleFieldOther(this.isViewOtherField);
  }

  private _viewOtherField(otherValue: DictType): boolean {
    if (!otherValue) {
      return false;
    }
    return this._codeVidViewOther === otherValue.code;
  }

  private _toggleFieldOther(value: boolean): void {
    const otherControl = this.f('otherVid') as AbstractControl;
    value && !this.isReadOnly ? otherControl.enable() : otherControl.disable();
  }
}
