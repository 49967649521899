export * from './banc.service';
import { BancService } from './banc.service';
export * from './cbBnkseek.service';
import { CbBnkseekService } from './cbBnkseek.service';
export * from './cbTu.service';
import { CbTuService } from './cbTu.service';
export * from './common.service';
import { CommonService } from './common.service';
export * from './fil.service';
import { FilService } from './fil.service';
export * from './obl.service';
import { OblService } from './obl.service';
export * from './ogrn.service';
import { OgrnService } from './ogrn.service';
export * from './okato1.service';
import { Okato1Service } from './okato1.service';
export * from './okcm.service';
import { OkcmService } from './okcm.service';
export * from './okcmNotlegacy.service';
import { OkcmNotlegacyService } from './okcmNotlegacy.service';
export * from './okwed.service';
import { OkwedService } from './okwed.service';
export * from './okwed2.service';
import { Okwed2Service } from './okwed2.service';
export * from './swift.service';
import { SwiftService } from './swift.service';
export * from './valut.service';
import { ValutService } from './valut.service';
export const APIS = [BancService, CbBnkseekService, CbTuService, CommonService, FilService, OblService, OgrnService, Okato1Service, OkcmService, OkcmNotlegacyService, OkwedService, Okwed2Service, SwiftService, ValutService];
