import {Component, Input, OnInit} from "@angular/core";
import {DossierService} from "../../../../../services";
import {DictionaryService} from "@amlCore/services";
import {TypeOrg} from "../../../../../models/dossier";
import {DossierBaseModel} from "../../dossierBaseModel";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {AlertPanelService} from "@amlCore/components";

@Component({
  selector: 'app-personal-data-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './personalDataDossier.component.html'
})
export class PersonalDataDossierComponent extends DossierBaseSubComponent implements OnInit {

  isInnReq = false;
  @Input() typeOrg: TypeOrg;

  constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  ngOnInit() {
    this.loadRemoteData(['oksm']);
    this.isInnReq = this.typeOrg === 'ip';
  }

}
