import {CryptUtils} from "./utils";
import {CryptAPI, CryptCertObject, CryptVersionValidate} from "./interface";
import {ALGORITHMs, MINVERSION} from "./constants";


export class CryptAsync implements CryptAPI {
    pluginMinVersion = MINVERSION;
    cvi = {} as CryptVersionValidate;
    private certificate = null;
    private algorithm = null;
    private readonly CADESCOM_CADES_BES = 1;

    public get cadesplugin() {
        return CryptUtils.getCades();
    }


    constructor() {
    }

    getSelectedCert() {
        return this.certificate;
    }

    getAlgorithmCert() {
        return this.algorithm;
    }

    resetSelectedCert(){
       this.certificate = null;
       this.algorithm = null;
    }
    
    selectedCert(cert): Promise<any> {
        const _this = this;
        return new Promise((resolve) => {
            _this.certificate = cert;
            _this.getAlgorithm(cert).then((data) => {
                _this.algorithm = data.code;
                return resolve(_this.certificate);
            });
        });
    }

    CheckForPlugIn(): Promise<CryptVersionValidate> {
        const _this = this;
        return new Promise((resolve) => {
            this.cadesplugin.async_spawn(function* () {
                const oAbout = yield _this.cadesplugin.CreateObjectAsync("CAdESCOM.About");
                _this.VersionCompare(_this.pluginMinVersion, yield oAbout.PluginVersion);
                _this.cvi.isPluginEnabled = true;
                _this.cvi.isPluginLoaded = true;
                _this.cvi.isPluginWorked = true;
                resolve(_this.cvi);
            });
        });
    }

    VersionCompare(minVersion, ObjectVersion) {
        if (typeof (ObjectVersion) === "string") {
            return -1;
        }
        const _this = this;
        const versionArr = minVersion.split('.');
        let isActualVersion = true;
        this.cadesplugin.async_spawn(function* () {
            if ((yield ObjectVersion.MajorVersion) === parseInt(versionArr[0], 10)) {
                if ((yield ObjectVersion.MinorVersion) === parseInt(versionArr[1], 10)) {
                    if ((yield ObjectVersion.BuildVersion) === parseInt(versionArr[2], 10)) {
                        isActualVersion = true;
                    } else if ((yield ObjectVersion.BuildVersion) < parseInt(versionArr[2], 10)) {
                        isActualVersion = false;
                    }
                } else if ((yield ObjectVersion.MinorVersion) < parseInt(versionArr[1], 10)) {
                    isActualVersion = false;
                }
            } else if ((yield ObjectVersion.MajorVersion) < parseInt(versionArr[0], 10)) {
                isActualVersion = false;
            }
            _this.cvi.isActualVersion = isActualVersion;
            if (!isActualVersion) {
                _this.cvi.textError = "Плагин загружен, но есть более свежая версия.";
                _this.cvi.typeError = "warning";
            }
            const oAbout = yield _this.cadesplugin.CreateObjectAsync("CAdESCOM.About");
            const ver = yield oAbout.CSPVersion("", 80);
            _this.cvi.cryptoCSPVersion = (yield ver.MajorVersion) + "." + (yield ver.MinorVersion) + "." + (yield ver.BuildVersion);
            _this.cvi.currentPluginVersion = (yield ObjectVersion.MajorVersion) + "." + (yield ObjectVersion.MinorVersion)
                + "." + (yield ObjectVersion.BuildVersion);
            try {
                _this.cvi.cryptoCSPVersion = yield oAbout.CSPName(80);
            } catch (err) {
                _this.cvi.cryptoCSPVersion = "Версия криптопровайдера не определена.";
                console.warn(err);
            }
            return;
        });
    }

    findCertificate(certHash): Promise<any> {
        const _this = this;
        return new Promise((resolve, reject) => {
            _this.cadesplugin.async_spawn(function* () {
                try {


                    const oStore = yield _this.cadesplugin.CreateObjectAsync("CAdESCOM.Store");
                    try {
                        yield oStore.Open();
                    } catch (err) {
                        throw new Error('Ошибка при октрытии хранилища CAdESCOM.Store: ' + err.number);
                    }
                    const allCerts = yield oStore.Certificates;
                    let certCnt;
                    let certs;
                    try {
                        certs = yield oStore.Certificates;
                        certCnt = yield certs.Count;
                    } catch (ex) {
                        throw new Error("Ошибка при открытии хранилища: " + _this.cadesplugin.getLastError(ex));
                    }
                    if (certCnt === 0) {
                        throw new Error("Не найдено сертификатов");
                    }
                    let cert;
                    let found = 0;
                    for (let i = 1; i <= (yield allCerts.Count); i++) {
                        try {
                            cert = yield allCerts.Item(i);
                        } catch (ex) {
                            throw new Error("Ошибка при перечислении сертификатов: " + _this.cadesplugin.getLastError(ex));
                        }
                        try {
                            const thumbprint = yield cert.Thumbprint;
                            if (certHash.toUpperCase() === thumbprint) {
                                found = i;
                                break;
                            }
                        } catch (ex) {
                            throw new Error("Ошибка при получении сертификата: " + _this.cadesplugin.getLastError(ex));
                        }
                    }
                    if (found === 0) {
                        yield oStore.Close();
                        throw new Error("Сертификат не найден");
                    }
                    cert = yield allCerts.Item(found);
                    yield oStore.Close();
                    resolve(cert);
                } catch (e) {
                    reject(e);
                }
            });
        });
    }

    getCertsStore(): Promise<Array<CryptCertObject>> {
        const _this = this;
        return new Promise((resolve, reject) => {
            _this.cadesplugin.async_spawn(function* () {
                try {
                    const oStore = yield _this.cadesplugin.CreateObjectAsync("CAdESCOM.Store");
                    const filteredCertificates = [];
                    const today = new Date();
                    try {
                        yield oStore.Open();
                    } catch (ex) {
                        throw new Error("Ошибка при открытии хранилища: " + _this.cadesplugin.getLastError(ex));
                    }

                    let certCnt;
                    let certs;

                    try {
                        certs = yield oStore.Certificates;
                        certCnt = yield certs.Count;
                    } catch (ex) {
                        throw new Error("Ошибка при открытии хранилища: " + _this.cadesplugin.getLastError(ex));
                    }

                    if (certCnt === 0) {
                        throw new Error("Не найдено сертификатов");
                    }

                    for (let i = 1; i <= certCnt; i++) {
                        let cert;
                        try {
                            cert = yield certs.Item(i);
                        } catch (ex) {
                            throw new Error("Ошибка при перечислении сертификатов: " + _this.cadesplugin.getLastError(ex));
                        }
                        const certObj = {} as CryptCertObject;
                        certObj.isLocal = false;
                        certObj.hasPrivateKey = false;
                        const thumb = yield cert.Thumbprint;
                        certObj.isLocal = true;
                        const Cert2 = yield cert;
                        const sName = yield cert.SubjectName;
                        const certDate = yield cert.ValidFromDate;
                        const ValidToDate = new Date((yield cert.ValidToDate));
                        const ValidFromDate = new Date(certDate);
                        certObj.isActual = ValidFromDate < today && ValidToDate > today;
                        if (certObj.isActual) {
                            certObj.validFrom = ValidToDate;
                            certObj.validTo = ValidToDate;
                            certObj.thumb = thumb;
                            certObj.certInfo = CryptUtils.getCertInfo(sName);
                            certObj.certCAPICOM = Cert2;
                            try {
                                certObj.hasPrivateKey = yield cert.HasPrivateKey();
                                try {
                                    certObj.subjectName
                                        = CryptUtils.getCertInfoString(sName, certDate);
                                } catch (e) {
                                    certObj.subjectName = certObj.thumb;
                                    console.warn(e);
                                    // ignored
                                }
                                filteredCertificates.push(certObj);
                              console.log(certObj)
                            } catch (ex) {
                                throw new Error(_this.cadesplugin.getLastError(ex));
                            }
                        }
                    }
                    oStore.Close();
                    filteredCertificates.sort(CryptUtils.sortByName(true));
                    resolve(filteredCertificates);
                } catch (e) {
                    reject(e);
                }
            });
        });
    }

    getCertsFromUser(thumbs): Promise<Array<CryptCertObject>> {
        const _this = this;
        return new Promise((resolve, reject) => {
            _this.cadesplugin.async_spawn(function* () {
                try {
                    const oStore = yield _this.cadesplugin.CreateObjectAsync("CAdESCOM.Store");
                    const filteredCertificates = [];
                    const today = new Date();
                    try {
                        yield oStore.Open();
                    } catch (ex) {
                        throw new Error("Ошибка при открытии хранилища: " + _this.cadesplugin.getLastError(ex));
                    }

                    let certCnt;
                    let certs;

                    try {
                        certs = yield oStore.Certificates;
                        certCnt = yield certs.Count;
                    } catch (ex) {
                        throw new Error("Ошибка при открытии хранилища: " + _this.cadesplugin.getLastError(ex));
                    }

                    if (certCnt === 0) {
                        throw new Error("Не найдено сертификатов");
                    }

                    for (let i = 1; i <= certCnt; i++) {
                        let cert;
                        try {
                            cert = yield certs.Item(i);
                        } catch (ex) {
                            throw new Error("Ошибка при перечислении сертификатов: " + _this.cadesplugin.getLastError(ex));
                        }
                        const certObj = {} as CryptCertObject;
                        certObj.isLocal = false;
                        certObj.hasPrivateKey = false;
                        const thumb = yield cert.Thumbprint;
                        if (thumb && thumbs.indexOf(thumb.toUpperCase()) > -1) {
                            certObj.isLocal = true;
                            const Cert2 = yield cert;
                            const ValidToDate = new Date((yield cert.ValidToDate));
                            const ValidFromDate = new Date((yield cert.ValidFromDate));
                            certObj.certObject = yield cert.data;
                            certObj.thumb = yield thumb;
                            certObj.certInfo = yield CryptUtils.getCertInfo(yield cert.SubjectName);
                            certObj.isActual = ValidFromDate < today && ValidToDate > today;
                            certObj.certCAPICOM = yield Cert2;
                            try {
                                certObj.hasPrivateKey = yield cert.HasPrivateKey();
                                if (certObj.hasPrivateKey) {
                                    try {
                                        certObj.subjectName
                                            = yield CryptUtils.getCertInfoString(yield cert.SubjectName, yield cert.ValidFromDate);
                                    } catch (e) {
                                        certObj.subjectName = certObj.thumb;
                                        console.warn(e);
                                        // ignored
                                    }
                                }
                            } catch (ex) {
                                throw new Error(_this.cadesplugin.getLastError(ex));
                            }
                            filteredCertificates.push(certObj);
                        }
                    }
                    oStore.Close();
                    filteredCertificates.sort(CryptUtils.sortByName(true));
                    resolve(filteredCertificates);
                } catch (e) {
                    reject(e);
                }
            });
        });
    }

    getAlgorithm(cert): Promise<{ name: string, code: string }> {
        const _this = this;
        return new Promise((resolve) => {
            _this.cadesplugin.async_spawn(function* () {
                const pk = yield cert.PublicKey();
                const algorithm = yield pk.Algorithm;
                resolve({name: yield algorithm.FriendlyName, code: yield algorithm.Value});
            });
        });
    }

    generateSignature(sHashValue, cert): Promise<string> {
        const _this = this;
        return new Promise((resolve, reject) => {
            _this.cadesplugin.async_spawn(function* () {
                try {
                    const pk = yield cert.PublicKey();
                    const algorithm = yield pk.Algorithm;
                    const code = yield algorithm.Value;
                    const hashAlg = ALGORITHMs[code];
                    const oHashedData = yield _this.cadesplugin.CreateObjectAsync("CAdESCOM.HashedData");

                    yield oHashedData.propset_Algorithm(hashAlg);
                    yield oHashedData.SetHashValue(sHashValue);

                    const oSigner = yield _this.cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
                    yield oSigner.propset_Certificate(cert);
                    yield oSigner.propset_Options(_this.cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_END_ENTITY_ONLY);

                    const oSignedData = yield _this.cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
                    const sSignedMessage = oSignedData.SignHash(oHashedData, oSigner, _this.CADESCOM_CADES_BES);
                    resolve(sSignedMessage);
                } catch (e) {
                    reject(e);
                }
                });
            }
        );
    }
    signDataBase64(sHashBase64, cert): Promise<string> {
        const _this = this;
        return new Promise((resolve, reject) => {
            _this.cadesplugin.async_spawn(function* () {
                try {
                    const oSigner = yield _this.cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
                    yield oSigner.propset_Options(_this.cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_END_ENTITY_ONLY);
                    yield oSigner.propset_Certificate(cert);
                    const oSignedData = yield _this.cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
                    yield oSignedData.propset_ContentEncoding(_this.cadesplugin.CADESCOM_BASE64_TO_BINARY);
                    yield oSignedData.propset_Content(sHashBase64);
                    const signature = yield oSignedData.SignCades(oSigner, 1);
                    resolve(signature);
                } catch (e) {
                    reject(e);
                }
            });
            }
        );
    }

    signFile(oFile, oCertificate): Promise<string> {
        const _this = this;
        return new Promise((resolve, reject) => {
            _this.cadesplugin.async_spawn(function* () {
                try {
                    // Проверяем, работает ли File API
                    CryptUtils.doCheckFileApi();
                    // @ts-ignore
                    const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
                    const chunkSize = 3 * 1024 * 1024; // 3MB
                    const chunks = Math.ceil(oFile.size / chunkSize);
                    let currentChunk = 0;
                    const pk = yield oCertificate.PublicKey();
                    const algorithm = yield pk.Algorithm;
                    const code = yield algorithm.Value;
                    const hashAlg = ALGORITHMs[code];

                    const oHashedData = yield _this.cadesplugin.CreateObjectAsync("CAdESCOM.HashedData");
                    yield oHashedData.propset_Algorithm(hashAlg);
                    yield oHashedData.propset_DataEncoding(1);

                    const frOnload = (e) => {
                        const header = ";base64,";
                        const sFileData = e.target.result;
                        const sBase64Data = sFileData.substr(sFileData.indexOf(header) + header.length);

                        oHashedData.Hash(sBase64Data);
                        currentChunk++;

                        if (currentChunk < chunks) {
                            loadNext();
                        } else {
                            _this.cadesplugin.async_spawn(function* () {
                                const oStore = yield _this.cadesplugin.CreateObjectAsync("CAdESCOM.Store");
                                yield oStore.Open(_this.cadesplugin.CAPICOM_CURRENT_USER_STORE, _this.cadesplugin.CAPICOM_MY_STORE,
                                    _this.cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);
                                const oSigner = yield _this.cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
                                yield oSigner.propset_Certificate(oCertificate);
                                yield oSigner.propset_Options(_this.cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_END_ENTITY_ONLY);
                                yield oSigner.propset_CheckCertificate(false);

                                const oSignedData = yield _this.cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
                                oSignedData.propset_ContentEncoding(1);

                                try {
                                    resolve(yield oSignedData.SignHash(oHashedData, oSigner, 1));
                                } catch (err) {
                                    reject(_this.cadesplugin.getLastError(err));
                                }
                                yield oStore.Close();
                            });
                        }
                    };


                    function loadNext() {
                        const fileReader = new FileReader();
                        fileReader.onload = frOnload;
                        fileReader.onerror = () => {
                            reject("Не удалось выпонить загрузку файлов");
                        };

                        const start = currentChunk * chunkSize;
                        const end = ((start + chunkSize) >= oFile.size)
                            ? oFile.size : start + chunkSize;

                        fileReader.readAsDataURL(blobSlice.call(oFile, start, end));
                    }

                    loadNext();
                } catch (e) {
                    reject(e);
                }
            });
        });
    }

    getCertBase64(hash) {
        const _this = this;
        return new Promise((resolve, reject) => {
            this.findCertificate(hash).then((cert) => {
                _this.cadesplugin.async_spawn(function* () {
                    resolve(yield cert.Export(0));
                });
            }, (e) => {
                reject(e);
            });
        });
    }
}
