export class QrefOblModel {
  name: string;
  oldname1: string;
  oldname2: string;
  code: string;
  gninmb: string;
  okato: string;

  constructor() {
  }
}

