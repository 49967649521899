import {BaseFilterModel} from "./baseFilter.model";

export class InternalFilter extends BaseFilterModel {
  subjectType = null; // Тип организации
  name = ''; // Наименование ЮЛ|ИНБОЮЛ
  inn = ''; // ИНН ВСЕ
  ogrn = ''; // ОГРН (ОГРНИП) ЮЛ|ИП
  sname = ''; // Фамилия ФЛ|ИП|ФЛЧП
  gname = ''; // Имя ФЛ|ИП|ФЛЧП
  pname = ''; // Отчество ФЛ|ИП|ФЛЧП
  birthDate = ''; // Дата регистрации/Рождения ВСЕ
  snils = ''; // СНИЛС ФЛ|ИП|ФЛЧП TODO
  series = ''; // Серия ФЛ|ИП|ФЛЧП
  number = ''; // Номер ФЛ|ИП|ФЛЧП
  inboul = ''; // Регистрационный номер
}

