import {DossierN9, DossierT11, DossierT250, DossierT40} from "./base.model";
import {DictType} from "./dictType.model";
import {PartItemArray} from "../partItemArray.model";
export class OtherAccountType extends PartItemArray{
  type: DictType = undefined;
  currcash: DictType = undefined;
  currency: DictType = undefined;
  accountNumber: DossierT40 = '';
  accountCorrNumber?: DossierT40 = '';
  country?: DictType = undefined;
  bik?: DossierN9 = '';
  bank?: DossierT250 = '';
  swift?: DossierT11 = '';
  row: number;
}
