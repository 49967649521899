import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SvedAUType} from '../../../../models/dossier';
import {DossierService} from '../../../../services';
import {SvedAUDossierService} from './svedAUDossier.service';
import {DossierBasePageComponent} from '../dossierBasePage.component';
import {IDossierSubService} from '../IDossierSub.service';
import {UserService, DictionaryService} from '@amlCore/services';
import {
  AlertPanelService,
  ITable,
  ITableCallBack,
  ITableItemDescriptor,
  TableCompComponent
} from '@amlCore/components';
import {AuInfoDossierComponent} from '../components/auInfo';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FileSaverService} from "ngx-filesaver";


@Component({
  selector: 'app-sved-au-dossier',
  styleUrls: ['../dossier.css'],
  templateUrl: './svedAUDossier.component.html'
})
export class SvedAUDossierComponent extends DossierBasePageComponent implements OnInit, ITable {

  modelPage: Array<SvedAUType>;
  itemDescriptor: ITableItemDescriptor;
  isTableTab = true;
  @ViewChild('tableComp', {static: true}) tableComp: TableCompComponent;

  constructor(protected dossierService: DossierService,
              protected route: ActivatedRoute,
              private svedAUService: SvedAUDossierService,
              protected dictSrv: DictionaryService,
              protected router: Router,
              protected userService: UserService,
              protected alertPanelSrv: AlertPanelService,
              protected modalService: NgbModal,
              protected fSaver: FileSaverService) {
    super(dossierService, route, dictSrv, router, userService, alertPanelSrv, modalService, fSaver);
  }

  ngOnInit(): void {
    // Инициализация вкладки
    this.modelPage = this.initPage();
    // Описание элемента таблицы
    this.itemDescriptor = {
      create: this.svedAUService.getSvedAu.bind(this.svedAUService),
      component: AuInfoDossierComponent,
      params: {
        typePage: this.typePage,
        clientType: () => this.clientType,
      }
    } as ITableItemDescriptor;
  }

  getService(): IDossierSubService {
    return this.svedAUService;
  }

  destroy(): void {
    // ТУТ писать реализацию ngOnDestroy для компонента
  }

  callbackItemClosed(event: ITableCallBack): void {
    this.saveTableItem(event);
  }

  getBfOwnerName(code: string) {
    return this.dictSrv.getStaticDataValue('bfTypeList', code);
  }
}
