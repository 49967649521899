import { Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons/faMinusCircle";
import {DossierBaseSubComponent} from "../../dossierBaseSub.component";
import {DossierService} from "../../../../../../services";
import {DictionaryService} from "@amlCore/services";
import {AlertPanelService, ICustomSelectMapper} from "@amlCore/components";
import {AccountType} from "../../../../../../models/dossier";
import {IFocus} from "@amlCore/interfaces";

@Component({
    selector: 'app-account-paycard',
    templateUrl: './accountPaycard.component.html',
    styleUrls: ['./accountPaycard.component.css'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => AccountPaycardComponent),
        multi: true
    }]
})

export class AccountPaycardComponent extends DossierBaseSubComponent implements ControlValueAccessor, IFocus {
    @Input() hasIndexed = false;

    listItem: Array<any> = [];
    isDisabled = false;
    public rowIcons = {
        plus: faPlusCircle,
        minus: faMinusCircle
    };

    ngOnInit() {
        this.loadRemoteData(['paycard_class','paycard_type']);
    }

    onChange = (result: any) => {};
    onTouch = () => {};

    constructor(protected dossierService: DossierService,
                          protected dictSrv: DictionaryService,
                          protected alertPanelService: AlertPanelService) {
        super(dossierService, dictSrv, alertPanelService);
    }


    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    /**
     * Получаем переменную из родительского компонента и преобразуем в вид который нам надо
     */
    writeValue(outsideValue: Array<any>): void {
        this.listItem = [];
        // Получить из Forms API
        if (outsideValue && outsideValue.length > 0) {
            this.getModelFromView(outsideValue);
        } else {
            // Добавляем пустое поле если список пустой при запуске
            setTimeout(() => {
                this.add();
            });
        }
    }

    /**
     * Меняем руками и сообщаем Forms API об обновлении
     */
    updateValue(): void {
        // Сообщаем Forms API
        this.onChange(this.listItem );
    }

    /**
     * Преобразуем модель в вид для отображения компонента
     */
    getModelFromView(oldModel: Array<{}>): void {
        oldModel.forEach((value) => {
            this.listItem.push(value);
        });
    }

    /**
     * Добавить новое поле
     */
    add(): void {
        let item = {};

        if(this.hasIndexed){
            item['row'] = this.listItem.length === 0 ? 0 : this._createUniqueId();
        }
    
        this.listItem.push(item);

        if (this.hasIndexed) {
            this.listItem.sort((a, b) =>  a.row - b.row);
        }

        this.updateValue();
    }

    private _createUniqueId(): number {
        let rowId = 0;
        const ids = [];

        this.listItem.forEach((item) => {
            ids.push(item['row']);
        });

        while(ids.includes(rowId)) {
          rowId++;
        }

        return rowId;
    }
      

    /**
     * Удаить поле по индексу
     * @param index - индекс поля
     */
    delete(index: number): void {
        this.listItem.splice(index, 1);
        // добавляем пустое поле если удалили все поля
        if (this.listItem.length === 0) {
            this.add();
        } else {
            this.updateValue();
        }
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    focus(): void {
        const error = this.dossierService.errorFLKTable;
        if (error) {
            const fieldEl: any = document.querySelector(`#item_${error.path}_${error.row} > div.ng-select-container input`);
            if (fieldEl && fieldEl.focus) {
                setTimeout(() => {
                    window.setTimeout(() => fieldEl.focus() , 0);
                });
            }
        }
    }

}