import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UserService} from "@amlCore/services";


@Component({
  selector: 'app-cert-check',
  templateUrl: './certificateCheck.component.html'
})
export class CertificateCheckComponent implements OnInit {
  @Input()
  state: null
  textError = ''

  constructor(private userSrv: UserService) {
  }

  ngOnInit(): void {
    if (this.state) {
      this.textError = this.userSrv.checkCryptInstall(this.state);
    }
  }
}
