import { Injectable } from "@angular/core";
import { Subject } from "rxjs"

@Injectable({
    providedIn: "root"
})

export class GlobalDataProviderService {
    public roleAccessModel = new Subject();

    private serverResponseDataSub = new Subject();
    serverResponseDataObs$ = this.serverResponseDataSub.asObservable();
    setServerResponseDataSub(value) {
      this.serverResponseDataSub.next(value);
    }
}