import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CheckClients, DictType} from "../../models";
import {DossierService} from "../../services";
import {DictionaryService, ResourceService, UserService, ValidatorService} from "@amlCore/services";
import {AlertPanelComponent, AlertPanelService} from "@amlCore/components";
import {CheckDopInfo} from "../../models/checkClients";
import {IDictionaryItem, IDictItemType} from "@amlCore/interfaces";
import {forkJoin, Subject} from 'rxjs';
import {DossierAccessEnum} from '../../../core/enum';
import {User} from '../../../core/models';
import {ExecutionTransferWindowComponent} from "../executionTransfer";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ExecutorDataModel} from "../../models/executorData.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Utils} from "@amlCore/utils";
import {finalize, takeUntil} from "rxjs/operators";

// TODO создать задачу что бы избавиться от any
@Component({
  selector: 'app-client-check-client-form',
  templateUrl: './checkClientForm.component.html'
})
export class CheckClientFormComponent implements OnInit, OnDestroy {
    dictDate: string;
    idSubject: string;
    dictId: string;
    dictRowId: string;
    dictParentRowId: string;
    dictNumber: string;
    dictType: string;
    dictName: string;
    mvkResh: any;
    msLead: any;
    msLeadVisible = false;
    msFound: any;
    msFoundVisible = false;
    fts: any;
    ftsVisible = false;
    msAdr: any;
    msAdrVisible = false;
    passport: any;
    dopNames: CheckDopInfo[];
    okvedInfo: object = {};
    okved2Info: object = {};
    countryInfo: object = {};
    npaInfo: DictType;
    dictNPA: DictType[];
    usersWithPermissions: User[] = [];
    isSaveForm = false; // Признак, что форма сохранена
    isLoaded = false; // Признак, что форма загружена
    isEdit = false; // Признак, что форма редактируется
    private modal;
    viewForm: FormGroup;
    $destroy = new Subject();
    constructor(
        protected router: Router,
        protected alertPanelSrv: AlertPanelService,
        protected dictSrv: DictionaryService,
        private _resourceSrv: ResourceService,
        private _route: ActivatedRoute,
        private _dossierSrv: DossierService,
        private modalService: NgbModal,
        private fb: FormBuilder,
        private validationSrv: ValidatorService,
        private _userSrv: UserService
    ) {}

    @ViewChild('alertObjSave') alertObjToSave: AlertPanelComponent;
    item: CheckClients;
    id: string;
    resultCheckList: IDictionaryItem<{
        id: string;
        code: string;
        name: string;
    }>[];
    npa: IDictionaryItem<{
        id: string;
        code: string;
        name: string;
    }>[]; // справочник оснований
    affiliationpdl: IDictionaryItem<{
        id: string;
        code: string;
        name: string;
    }>[]; // справочник характеров принадлежности к ПДЛ
    typeaccountingservice: IDictItemType[]; // справочник Тип бухалтерской службы
    riskgroup: IDictItemType[] = []; // справочник Группа риска
    country: IDictionaryItem<{
        id: number;
        kod: string;
        kodletter: string;
        kodletter2: string;
        name: string;
    }>[]; // справочник стран
    types: IDictionaryItem<{
        id: string;
        name: string;
    }>[] = [];
    checkDictType: IDictionaryItem<{
        id: string;
        name: string;
    }>[] = this.dictSrv.getStaticData('checkDictType');
    codeMessage4936U: IDictItemType[] = [];
    criticallists: IDictItemType[] = [];
    okved: IDictItemType[] = [];
    okved2: IDictItemType[] = [];
    typeSubjects: IDictItemType[] = [];
    private riskLevels: IDictItemType[] = [];

    isReadOnlyAccess = this._userSrv.getAccess(DossierAccessEnum.RISKVIEW).readOnly;
    public canEditForm = false;

    ngOnInit(): void {

        forkJoin({
            okved: this.dictSrv.getRemoteData('okved'),
            okved2: this.dictSrv.getRemoteData('okved2'),
            oksm: this.dictSrv.getRemoteData("oksm"),
            affiliationpdl: this.dictSrv.getRemoteData("affiliationpdl"),
            typeaccountingservice: this.dictSrv.getRemoteData("typeaccountingservice"),
            riskgroup: this.dictSrv.getRemoteData("riskgroup"),
            types: this.dictSrv.getRemoteData('risktype'),
            npa: this.dictSrv.getRemoteData("npa"),
            typeSubjects: this.dictSrv.getRemoteData("typesubjects"),
            riskLevels: this.dictSrv.getRemoteData("risklevel"),
            codeMessage4936U: this.dictSrv.getRemoteData("codeMessage4936U"),
            criticallists: this.dictSrv.getRemoteData("criticallists")
        }).subscribe(({okved, okved2, oksm, affiliationpdl, typeaccountingservice, riskgroup, types, npa, typeSubjects, riskLevels, codeMessage4936U, criticallists}) => {
            const basisCodes = ["4", "5"];
            this.okved = okved;
            this.okved2 = okved2;
            this.country = oksm;
            this.affiliationpdl = affiliationpdl;
            this.typeaccountingservice = typeaccountingservice;
            this.riskgroup = riskgroup;
            this.types = types.map((item: any) => {return {id: item.code, name: item.name};});
            this.npa = npa.filter(item => basisCodes.includes(item.code));
            this.dictNPA = npa;
            this.typeSubjects = typeSubjects;
            this.riskLevels = riskLevels;
            this.codeMessage4936U = codeMessage4936U;
            this.criticallists = criticallists;
            this._route.params.subscribe(params => {
                this.id = params.id;
                this.dictSrv.getRemoteData('resultchecking').then(result => {
                    this.resultCheckList = result;
                });
                this.loadCheckClient();
                this._resourceSrv.getUsersByCheckClient(this.id).subscribe((users) => {
                    this.usersWithPermissions = users;
                });
            });
        });

        this.viewForm = this.fb.group({
            penalty: null,
            checkResult: null,
            action: null
        });

        this.viewForm.get('checkResult').valueChanges.pipe(takeUntil(this.$destroy)).subscribe(value => {
            const penalty = this.viewForm.get('penalty');
            penalty.setValidators(this.isPenaltyRequired() ? [Validators.required]:[]);
            penalty.updateValueAndValidity();
        });
    }

    ngOnDestroy(): void {
        if(this.isEdit){
            this._dossierSrv.stopEditCheck(this.id);
        }
        this.$destroy.next();
        this.$destroy.complete();
    }

    isPenaltyRequired(): boolean {
        return ['1', '2'].includes(this.viewForm.get('checkResult').value);
    }

    loadCheckClient(): void {
        this._dossierSrv.getCheckClientById(this.id).subscribe(data => {
            this.updateForm(data, true);
            if (this.item.npa && this.dictNPA.length > 0) { this.npaInfo = this.dictNPA.find(itemNpa => itemNpa.code === this.item.npa); }
            this.item.listDopInfo.forEach((item) => {
                const fn = ({
                    dictDate: () => this.dictDate = item.value,
                    idSubject: () => this.idSubject = item.value,
                    dictId: () => this.dictId = item.value,
                    dictRowId: () => this.dictRowId = item.value,
                    dictParentRowId: () => this.dictParentRowId = item.value,
                    dictNumber: () => this.dictNumber = item.value,
                    dictType: () => this.dictType = item.value,
                    okwed: () => {
                      if (!this.okved) { return; }

                        const dataOkved = this.okved.find((dictItem: any) => dictItem.code === item.value);
                        this.okvedInfo[item.value] = `${item.value} ${dataOkved ? dataOkved.name : ''}`;
                    },
                    okwed2: () => {
                        if (!this.okved2) { return; }
                        const dataOkved2 = this.okved2.find((dictItem: any) => dictItem.code === item.value);
                        this.okved2Info[item.value] = `${item.value} ${dataOkved2 ? dataOkved2.name : ''}`;
                    },
                    country: () => {
                        if (!this.country) { return; }
                        const dataCountry = this.country.find((dictItem: any) => dictItem.kod === item.value);
                        this.countryInfo[item.value] = `${item.value} ${dataCountry ? dataCountry.name : ''}`;
                    }
                })[item.code];
                if (fn instanceof Function) { fn(); }
            });
            this.dopNames = this.item.listDopInfo.filter(item => item.name);
            if (this.dictRowId) {
                switch(this.dictType) {
                    case 'mvk':
                        this._resourceSrv.getMvkReshIdBySubjectId(this.dictRowId).subscribe(result => {
                            this.mvkResh = result;
                        });
                        break;
                    case 'mslead':
                        this._resourceSrv.getMsLead(this.dictRowId).subscribe(result => {
                            this.msLead = result;
                        });
                        break;
                    case 'msfound':
                        this._resourceSrv.getMsFound(this.dictRowId).subscribe(result => {
                            this.msFound = result;
                        });
                        break;
                    case 'fts':
                        this._resourceSrv.getFts(this.dictRowId).subscribe(result => {
                            this.fts = result;
                        });
                        break;
                    case "ms_adr_info":
                        this._resourceSrv.getMsAdr(this.dictRowId).subscribe(result => {
                            this.msAdr = result;
                        });
                    break;
                }
            }

            this.dictName = this.dictSrv.getDictNameByCode(this.dictType, this.criticallists);
        });
    }

    initEditMode(): void {
        if(this.canEditForm || (!this.isReadOnlyAccess && !this.item.result)){
            this.isLoaded = null;
            this._dossierSrv.startEditCheck(this.id).pipe(finalize(() => this.isLoaded = true)).subscribe(response => {
                if(!response?.result) {
                    this.isReadOnlyAccess = true;
                    this.canEditForm = false;
                    this.viewForm.disable();
                    this.alertObjToSave.open(
                      this.alertPanelSrv.getErrorMsg(response?.message ?? "Ошибка редактирования документа", false)
                    );
                } else {
                    this.isEdit = true;
                }
            });
        }
    }

    updateForm(data?: CheckClients, isInit?: boolean): void{
        if(data){
            this.viewForm.patchValue(this.checkDataToModel(data));
            this.item = data;
        }
        this.checkCanEditForm();
        if(isInit) {
            this.initEditMode();
        }
        if(this.item.result || !this.canEditForm){
            this.viewForm.disable();
        } else{
            this.viewForm.enable();
        }
    }

    checkDataToModel(data: CheckClients){
        const model = Utils.clone(data);
        model.checkResult = String(data.checkResult);
        return model;
    }

    save(): void {
        const editableData = Utils.clone(this.viewForm.value);
        this._dossierSrv.saveCheckClient(this.id, editableData).subscribe(data => {
            this.updateForm(data);
            this.viewForm.markAsPristine();
            this.isSaveForm = true;
            this.alertObjToSave.open(this.alertPanelSrv.getSuccessMsg('Данные сохранены'));
        });
    }


    continue(): void {
        this._dossierSrv.continueCheckClient(this.id, this.item).subscribe(data => {
            this.updateForm(data);
            this.alertObjToSave.open(this.alertPanelSrv.getSuccessMsg('Данные сохранены'));
        });
    }

    finishCheck(): void {
        this._dossierSrv.finishCheckClient(this.id).subscribe(data => {
            this.updateForm(data);
            this.alertObjToSave.open(this.alertPanelSrv.getSuccessMsg('Проверка завершена'));
        });
    }

    openDossier(check: CheckClients): void {
        if (check.dossierInfo.clientInfo.state !== 1) {
            this._dossierSrv.setPageId(check.dossierInfo.clientInfo.partId);
        }
        window.open(`dossier/${check.dossierInfo.clientInfo.dossierId}/${check.dossierInfo.clientInfo.typeSubject}`, '_blank');
    }

    openDossierRoot(check: CheckClients): void {
        if (check.dossierInfo.rootClient.state !== 1) {
            this._dossierSrv.setPageId(check.dossierInfo.rootClient.partId);
        }
        window.open(`dossier/${check.dossierInfo.rootClient.dossierId}/${check.dossierInfo.rootClient.typeSubject}`, '_blank');
    }

    getDopInfoName(dopInfoItem): string {
        if (dopInfoItem?.code === "characterPDL") {
            return this.dictSrv.getDictNameByCode(dopInfoItem.value, this.affiliationpdl);
        } else if (dopInfoItem?.code === "accountsDep") {
            return this.dictSrv.getDictNameByCode(dopInfoItem.value, this.typeaccountingservice);
        } else if (['mainrisk', 'addrisk1', 'addrisk2', 'addrisk3'].includes(dopInfoItem?.code) && dopInfoItem.value) {
            return `${dopInfoItem.value} ${this.dictSrv.getDictNameByCode(dopInfoItem.value, this.riskgroup)}`;
        }
        return ({
            okwed: this.okvedInfo,
            okwed2: this.okved2Info,
            country: this.countryInfo
        })[dopInfoItem.code]?.[dopInfoItem?.value] || (dopInfoItem?.value || "");
    }

    getTypeSubjectName(code: string): string {
        return this.dictSrv.getTypeSubjectName(code, this.typeSubjects);
    }

    getRiskLevelName(code: string): string {
        return this.dictSrv.getDictNameByCode(code, this.riskLevels);
    }

    isSubjectData(code: string): boolean {
        const SubjectListCodes = ['svedAU', 'svedIOU'];
        return SubjectListCodes.includes(code);
    }

    openSubjectDossier(subject: CheckDopInfo): void {
        const rootIdDossier = this.item.dossierInfo.rootClient.dossierId;
        const typePartDossier = subject.code;
        window.open(`dossier/${rootIdDossier}/${typePartDossier}`, '_blank');
    }

    msLeadChange(): void {
        this.msLeadVisible = !this.msLeadVisible;
    }

    msFoundChange(): void {
        this.msFoundVisible = !this.msFoundVisible;
    }

    msAdrChange(): void {
        this.msAdrVisible = !this.msAdrVisible;
    }

    ftsChange(): void {
        this.ftsVisible = !this.ftsVisible;
    }

    getUrlRisk(id: string): string {
      // @ts-ignore TODO надо обновить конфиг ts, метод replaceAll существует
      return `registries/riskfactorCountry/${id};whereOpenUrl=${this.router.url.replaceAll('\/', '%2F')}`;
    }

    private _sendNoticeToUserEmail(data: ExecutorDataModel): void {
        const body = { userId: data.userId, executionDate: data.date };
        const checkClientId = this.id;
        this._userSrv.checkClientExecutor(body, checkClientId).subscribe((result) => {
            this.updateForm(result);
            this?.modal?.componentInstance?.afterSave();
        });
    }

    getExecutorName(executorId: string): string{
        return this.usersWithPermissions.find(u=>u.id == executorId)?.fullName || executorId;
    }

    getExecutorData(): ExecutorDataModel {
        return {
            userId: this.item.executorId,
            date: this.item.executionDate
        };
    }

    /**
     * Передать на исполнение
     */
    changeExecutor(): void {
        this.modal = this.modalService.open(ExecutionTransferWindowComponent, {
            backdrop: 'static',
            keyboard: false,
            size: 'xl',
            scrollable: true
        });
        // Передаем данные в компонент
        this.modal.componentInstance.data=this.getExecutorData();
        this.modal.componentInstance.usersList=this.usersWithPermissions;
        this.modal.componentInstance.onSave.subscribe(value => {
            this._sendNoticeToUserEmail(value);
        });
    }


    /**
     * метод получения описания Принятые меры
     */
    private getCurrCashTitle(): string {
        const value = this.viewForm.get('penalty').value;
        if (value) {
            return this.codeMessage4936U.find((item: any) => item.code === value)?.name || '';
        }
        return '';
    }

    private checkCanEditForm(): void {
        this.canEditForm = !this.isReadOnlyAccess && (!this?.item?.executorId || this.item?.executorId == this._userSrv.getCurrentUser()?.id);
    }
}
