import {AfterViewInit, Component, ElementRef, forwardRef, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-custom-checkbox',
  template: `
    <div class="aml-checkbox-form" style="align-items: center;" [ngClass]="{disabled: isReadOnly}">
      <label class="aml-checkbox">
        <input #checkbox
               [id]="id"
               type="checkbox"
               class="form-control input-checkbox"
               [formControl]="formControl"
               [checked]="checked"
               (blur)="blur($event)"
               (change)="onChange(checkbox.checked)"
               [attr.disabled]="isReadOnly? true : null">
        <span class="success"></span>
      </label>
      <div class="aml-checkbox-text">
        <label [for]="id">
          <ng-content></ng-content>
        </label>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomCheckboxComponent),
      multi: true,
    },
  ],
})
export class CustomCheckboxComponent implements ControlValueAccessor, AfterViewInit {
  @Input() formControl: FormControl;
  @Input() isReadOnly: boolean;
  @Input() id: string;
  @ViewChild('checkbox', {static: true}) checkbox: ElementRef;
  checked = false;
  blur: ((_) => void);
  private onChangeCallback: ((_) => {});

  onChange(value: boolean) {
    this.checked = value;
    this.onChangeCallback(value ? '1' : '0');
  }

  writeValue(value: any) {
    this.checked = value === '1';
  }

  ngAfterViewInit() {
    this.checkbox.nativeElement.checked = this.checked;
  }

  registerOnChange(callback) {
    this.onChangeCallback = callback;
  }

  registerOnTouched(callback: (_) => void) {
    this.blur = callback;
  }
}
