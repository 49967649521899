import {Component, OnInit, Input} from '@angular/core';
@Component({
    selector: 'app-svg-icon',
    templateUrl: './svgIcon.component.svg',
})

export class SvgIconComponent implements OnInit {
    @Input() widthVal: string;
    @Input() widthSVG?: string; // управление контейнером SVG, если требуются отдельные размеры. Экспериментально
    @Input() heightSVG?: string; // управление контейнером SVG, если требуются отдельные размеры. Экспериментально
    @Input() heightVal: string;
    @Input() viewBoxVal: string;
    @Input() url: string;
    @Input() styleVal: string;

    constructor() {}

    ngOnInit() {}
}
