import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {DossierService} from "../../../../../services";
import {DictionaryService} from "@amlCore/services";
import {DossierBaseModel} from "../../dossierBaseModel";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {Unsubscribable} from "rxjs";
import {AlertPanelService} from "@amlCore/components";
import {DossierAccessEnum} from "@amlCore/enums";

@Component({
  selector: 'app-address-info-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './addressInfoDossier.component.html'
})
export class AddressInfoDossierComponent extends DossierBaseSubComponent implements OnInit, OnDestroy {

  @Input() isForeign: boolean = false;
  @Output() addressRegChange: any = new EventEmitter<any>();
  realAsRegValueChanges: Unsubscribable;
  postAsRegValueChanges: Unsubscribable;
  dossierAccessEnum = DossierAccessEnum;
  constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  ngOnInit() {
    setTimeout(() => {
      this.initAddres(this.f('realAsReg').value, 'addressReal');
      this.initAddres(this.f('postAsReg').value, 'addressPost');
      this.realAsRegValueChanges = this.f('realAsReg').valueChanges.subscribe(value => {
        this.initAddres(value, 'addressReal');
      });
      this.postAsRegValueChanges = this.f('postAsReg').valueChanges.subscribe(value => {
        this.initAddres(value, 'addressPost');
      });
      this.addUnsubscribable(this.realAsRegValueChanges);
      this.addUnsubscribable(this.postAsRegValueChanges);
    });
  }

  initAddres(value, key) {
    if (value) {
      this.fGroup(key).disable();
    } else if(!this.isReadOnly){
      this.fGroup(key).enable();
    }
  }

  addressRegChangeFn(value) {
    this.addressRegChange.emit(value);
  }

  ngOnDestroy() {
    this.unsubscribable();
  }
}
