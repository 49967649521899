export const MINVERSION = "2.0.13771";
export const ALGORITHMs = {
    "GOST3411": 100,
    "1.2.643.2.2.19": 100,

    "GOST3411_2012_256": 101,
    "1.2.643.7.1.1.1.1": 101,

    "GOST3411_2012_512": 102,
    "1.2.643.7.1.1.1.2": 102
};
