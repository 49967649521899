import {DossierBaseModel} from "../dossierBaseModel";
import {IDossierSubService} from "../IDossierSub.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ValidatorService} from "@amlCore/services";
import {Injectable} from "@angular/core";

@Injectable()
export class AccessDossierService extends DossierBaseModel implements IDossierSubService {
  /**
   * Форма вкладки
   */
  form: FormGroup;

  constructor(protected formBuilder: FormBuilder, protected validationSrv: ValidatorService) {
    super(formBuilder, validationSrv);
  }


  /**
   * Инициализация формы
   * isNewForm - вернуть новую форму
   */
  getForm(isNewForm?: boolean): FormGroup {
    if (!this.form || isNewForm) {
      this.newForm();
    }
    return this.form;
  }

  /**
   * Создание новой формы
   */
  newForm() {
    this.form = this.formBuilder.group({
      dossierAccess: null
    });
  }
}
