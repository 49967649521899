import {Component, OnInit} from "@angular/core";
import {DossierService} from "../../../../../services";
import {DictionaryService} from "@amlCore/services";
import {DossierBaseModel} from "../../dossierBaseModel";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {AlertPanelService} from "@amlCore/components";

@Component({
  selector: 'app-sved-flcp-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './svedFLCPDossier.component.html'
})
export class SvedFLCPDossierComponent extends DossierBaseSubComponent implements OnInit {

  isOkvedShow = false;

  constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  ngOnInit() {
    this.initTypePage();
  }

  /**
   * Инициализация typePage - скрываем некоторые части формы
   */
  initTypePage() {
    if (['svedClient', 'svedPredst', 'svedVPR', 'partner'].includes(this.typePage)) {
      this.isOkvedShow = true;
    }
  }

}
