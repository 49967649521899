import {Injectable, EventEmitter} from "@angular/core";
import {CryptographService, CryptUtils} from "../../cryptography";
import {ApiService} from "@amlCore/services";

@Injectable()
export class ProfileService {
  public userRoleDesc$: EventEmitter<string> = new EventEmitter();
  constructor(private cryptography: CryptographService, private api: ApiService) {
  }

  verifyCertSignature(data, cert)  {
    const that = this;
    return this.cryptography.crypt.signDataBase64(CryptUtils.Base64.encode(data), cert).then((value) => {
      return that.api.post('/api/v1/authad/profile/user/verifySignature',
        {
          signature: value,
          source: data
        }
      ).toPromise()
    })
  }
  getCertificatesProfile() {
    return this.api.get('/api/v1/authad/profile/user/certificates')
  }

  addCertificateProfile(certB64) {
    return this.api.sendFile('/api/v1/authad/profile/user/certificate', {
      certInBase64: encodeURIComponent(certB64)
    });
  }

  /*
   * получение информации о пользователе
   */
  getUserInfo() {
    return this.api.get('/api/v1/authad/profile/user');
  }
}
