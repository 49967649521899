import {Component, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {DictionariesService} from "../../../service";
import {ViewDictionaryBase} from "../view-dictionary-base";
import {IIdDescription} from "../../../models";

@Component({
  selector: 'app-dict-qref-pruved',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => QrefPruvedViewComponent),
    multi: true
  }],
  template: `
      <label class="form-label">{{title}}
        <app-rq *ngIf="isFieldReq(formControl)" [isReadOnly]="isDisabled"></app-rq>
      </label>
      <app-custom-select *ngIf="dictionary | async as dict; else loading"
                         code="id"
                         name="description"
                         fieldsFromSearch="description"
                         [items]="dict"
                         [returnOnlyId]="true"
                         [formControl]="formControl"
                         [isInvalid]="submitted && formControl.errors">
        <ng-template let-item #labelTemplate>
          <b> {{ item.id }} </b>
          {{ item.description }}
        </ng-template>
        <ng-template let-item let-search="search" #optionTemplate>
          <div style="white-space: normal">
            <b> {{ item.id }} </b>
            {{ item.description }}
          </div>
        </ng-template>
      </app-custom-select>
      <ng-template #loading>
        ...загрузка
      </ng-template>
      <app-valid [logic]="formControl" [submitted]="submitted"></app-valid>
  `
})
export class QrefPruvedViewComponent extends ViewDictionaryBase<IIdDescription> {
  getDictionary = this.dictSrv.getPruvedList();
  constructor(private dictSrv: DictionariesService) {
    super();
  }
}
