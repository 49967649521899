export enum RiskAccessEnum {
  RISK_CLASSES_FACTOR = 'risk_classes', // Классы проверок "Досье"
  RISK_CLASSES_OPERATIONS = 'risk_classes_operations', // Классы проверок "Операции"
  RISK_LIST = 'risk_config', // Настройка факторов - Список факторов
  RISK_RUN = 'risk_run' // Настройка факторов - Список факторов
}

export function getRiskAccessList(): Array<string> {
  return Object.values(RiskAccessEnum);
}

