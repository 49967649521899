export class QrefFilModel {

  namef: string;
  adres: string;
  rnbn: number;
  rnfl: number;

  constructor() {
  }
}

