import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TableService, VisitedService, DictionaryService } from "@amlCore/services";
import { TableReactiveUrlController } from "@amlCore/tableReactive";
import { DossierService } from "../../services";
import { CheckTransactionsFilter } from "@amlCore/models";
import {CheckTransaction} from "../../models";
import {AlertPanelComponent} from "@amlCore/components";
import {IDossierPage} from "../../interfaces";
import {ConfirmComponent} from "@amlCore/modals";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {saveAs} from "file-saver";
import {Utils} from "@amlCore/utils";
import {Observable} from "rxjs"
import {GenerateFesComponent} from "../generateFes";

@Component({
  selector: "app-check-transactions",
  templateUrl: "./checkTransactions.component.html",
})

export class CheckTransactionsComponent extends TableReactiveUrlController implements OnInit, OnDestroy, IDossierPage {
  private readonly MAX_EXPORTS_CHECKS = 1000; // Макс. кол-во выгружаемых результатов проверок
  participantState = [];
  isShowVisited = true;
  loadingExcelFile = false;
  checkTransactionsList: CheckTransaction[] = []; // анализ клиентских операций
  isDossierPart = false; // Признак, что раздел в досье
  idDossier: string; // id досье если isDossierPart === true

  constructor(protected activatedRoute: ActivatedRoute,
              protected router: Router,
              protected visitedService: VisitedService,
              protected tableService: TableService,
              private dossierSrv: DossierService,
              private dictSrv: DictionaryService,
              private readonly modalService: NgbModal) {
    super(router, activatedRoute, visitedService, tableService, {sort: 'checkDate', dir: 'desc'});
  }

  ngOnInit(): void {
    this.isDossierPart = this.activatedRoute.snapshot.data.isDossierPart;
    this.idDossier = this.activatedRoute.snapshot.parent.params.id;
    /* если находимся в новом досье которое еще только создается, то список операций не запрашиваем */
    if (this.activatedRoute.routeConfig.data.hasOwnProperty('isDossierPart') && !this.idDossier) {
      this.total = 0;
      return;
    }
    this.activatedRoute.queryParams.subscribe((data: CheckTransactionsFilter) => {
      this._loadList(this.syncParams(data));
    });
    // todo тут необходимо пределить получение справочника для статусов учтастника
    this.dictSrv.getRemoteData('participant_state').then((stateList) => {
      this.participantState = stateList;
    });
  }

  onPageChange(params: CheckTransactionsFilter): void {
    this.navigate({...params, ...this.getParams(this.filter)});
  }

  onSearch(): void {
    const params = this.getSearchPage(this.filter) as CheckTransactionsFilter;
    this.onPageChange(params);
  }

  private _loadList(params?: CheckTransactionsFilter): void {
    const queryParams = {...this.params, ...this.getParamsv2(params)};
    if (typeof this.storeService.get(this.keyFilterResult) === "boolean") {
      queryParams["result"] = !this.storeService.get(this.keyFilterResult);
    }
    if (this.idDossier) {
      this.dossierSrv.getTransactionCheckList(this.idDossier, queryParams).subscribe((data) => {
        this.isSelectedAll = false;
        this.checkTransactionsList =  data.content;
        this.total = data.totalElements;
        this.scrollRun();
      });
    } else {
      this.dossierSrv.getCheckTransactions(queryParams).subscribe((data) => {
        this.isSelectedAll = false;
        this.checkTransactionsList = data.content;
        this.total = data.totalElements;
        this.scrollRun();
      });
    }
  }

  getMemberStatus(status: string): string {
    return (this.participantState.find((item) => item.code === status) || {}).name;
  }

  openDossierRoot(id: string): void {
    window.open(`dossier/${id}/svedClient`, '_blank');
  }

  /**
   * Метод открытия формы транзакции
   * id - уникальный идентификатор транзакции
   * transaction -> id
   */
  openTransactionForm(id: string): void {
    // @ts-ignore
    window.open(`clientTransactionForm/${id};whereOpenUrl=${'/cabinet/check-transactions'.replaceAll('\/', '%2F')}`, '_blank');
  }

  /**
   * метод получения объекта плоского фильтра
   * @param data объект фильтра с полями
   */
  getFilterObject(data: CheckTransactionsFilter): object {
    const filter = this.tableService.getFilterSubject(data) as CheckTransactionsFilter;
    return this.getFilterPlanObject(filter);
  }

  // @ovveride метод кастомной обработки плоского объекта фильтра
  // содержащего параметры запроса в виде объекта
  protected customPatchPlanObject(result, filter, key): void {
    // обработка случая для справочника валют
    if (key === 'currency' && filter[key]) {
      result[key] = filter[key].code;
    }
  }

  /**
   * Метод открытия формы проверки операции
   * id - уникальный идентификатор операции
   */
  viewForm(id: string): void {
    this.tableService.saveScrollPosition(this.commonKeyStore);
    this.router.navigate(["checkTransactionForm", id]);
  }

  onSwitchResult(value: boolean): void {
    if (this.getFocusRowId()) {
      this.tableService.clear();
    }
    this.storeService.save(this.keyFilterResult, value);
    this.filter.result = value;
    this.onPageChange(this.getParams(this.filter) as CheckTransactionsFilter);
  }

  ngOnDestroy(): void {
    this.checkScrollOptions();
  }

  setAlertPanel(alertObjToSave: AlertPanelComponent): void {
  }

  exportExcelHandler(): void {
    const selected = this.getSelectedModel(this.checkTransactionsList) as Array<CheckTransaction>;
    if (selected.length == 0 && this.total > this.MAX_EXPORTS_CHECKS) {
      const modal = this.modalService.open(ConfirmComponent);
      modal.componentInstance.text =
        'Результаты поиска превышают допустимое количество записей для выгрузки. \n' +
        `В случае продолжения в файл будут выгружены первые ${this.MAX_EXPORTS_CHECKS} найденных записей.`;
      modal.result.then(() => {
        this.exportExcelFile()
      }, () => {})
      return;
    }
    this.exportExcelFile(selected);
  }

  /**
   * Экспорт в EXCEL
   */
  public exportExcelFile(selected?: Array<CheckTransaction>) {
    const successStatusId = 4;
    this.loadingExcelFile = true;
    this.getExportMethod(selected).subscribe(data => {
      if (data.type === successStatusId) {
        this.loadingExcelFile = false;
        saveAs(data.body, Utils.getFileNameFromContentDisposition(data));
      }
    }, (error) => {
      console.warn(error);
      this.loadingExcelFile = false;
    });
  }

  private getExportMethod(selected?: Array<CheckTransaction>): Observable<any>{
    if(selected?.length>0){
      const ids = selected.map(s => s.id);
      return this.dossierSrv.exportCheckTransactionListExcel(ids);
    } else {
      const filter = this.getFilterObject(this.filter);
      const queryParams = this.getParamsv2({...this.params, ...filter});
      queryParams["size"] = this.MAX_EXPORTS_CHECKS;
      const resultValue = this.storeService.get(this.keyFilterResult);
      if (typeof resultValue === "boolean") {
        queryParams["result"] = !resultValue;
      }
      return this.dossierSrv.exportCheckTransactionsExcel(queryParams, this.idDossier);
    }
  }

  /**
   * Сформировать ФЭС
   */
  public generateFes() {
    const ids = (this.getSelectedModel(this.checkTransactionsList) as Array<CheckTransaction>).map(s => s.id);
    const modalRef = this.modalService.open(GenerateFesComponent,  this._MODAL_CONFIG2);
    modalRef.componentInstance.open(ids);
    modalRef.result.then((data) => {
      this.onPageChange(this.getParams(this.filter) as CheckTransactionsFilter);
      this.isSelectedAll = false;
    });
  }
}
