import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {Unsubscribable} from "rxjs";
import {IDossierPanelInfo} from "../../interfaces";
import {DossierService} from "../../services";
import {DossierAccessEnum} from "@amlCore/enums";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";


@Component({
  selector: 'app-informant-panel',
  styleUrls: ['./informantPanel.component.css'],
  templateUrl: './informantPanel.component.html'
})
export class InformantPanelComponent implements OnInit, OnDestroy {

  isSaveData = false;
  isErrorFLK = false;
  isReadOnly = false;
  @Input() typePage: DossierAccessEnum;
  panelInfo: IDossierPanelInfo;
  isSaveDataSub: Unsubscribable;
  isReadOnlySub: Unsubscribable;
  isErrorFLKSub: Unsubscribable;
  icons = {
    checkCircle: faCheckCircle,
    eye: faEye,
    exclamationCircle: faExclamationCircle
  };
  constructor(private dossierService: DossierService) {
  }

  ngOnInit(): void {
    this.panelInfo = this.dossierService.getPanelInfo(this.typePage);
    if (this.panelInfo) {
      this.isSaveData = this.panelInfo.isSaveData.getValue();
      this.isReadOnly = this.panelInfo.isReadOnly.getValue();
      this.isErrorFLK = this.panelInfo.isErrorFLK.getValue();
      this.isSaveDataSub = this.panelInfo.isSaveData.subscribe(value => {
        this.isSaveData = value;
      });
      this.isReadOnlySub = this.panelInfo.isReadOnly.subscribe(value => {
        this.isReadOnly = value;
      });
      this.isErrorFLKSub = this.panelInfo.isErrorFLK.subscribe(value => {
        this.isErrorFLK = value;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.isSaveDataSub) {
      this.isSaveDataSub.unsubscribe();
    }
    if (this.isReadOnlySub) {
      this.isReadOnlySub.unsubscribe();
    }
    if (this.isErrorFLKSub) {
      this.isErrorFLKSub.unsubscribe();
    }
  }

}
