import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-copy-notification',
  templateUrl: './copy-notification.component.html',
  styleUrls: ['./copy-notification.component.css']
})
export class CopyNotificationComponent implements OnInit {

  @Input() items: any;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    
  }

}
