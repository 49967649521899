import {Component, OnInit} from '@angular/core';
import {ProfileService} from "../../services";
import {User} from "@amlCore/models";
import {FormGroup} from "@angular/forms";
import {UserService} from "@amlCore/services";


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {
  formUserControl: FormGroup;
  constructor(public profileService: ProfileService,
              public userService: UserService) {
  }
  ngOnInit(): void {
    this.initFormControl();
    this.loadUserInfo();
  }

  private loadUserInfo() {
    this.profileService.getUserInfo().subscribe((data: User) => {
      this.formUserControl.patchValue(data);
    });
  }

  initFormControl(): void {
    this.formUserControl = this.userService.getUserForm();
  }
}
