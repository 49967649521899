import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {DossierService} from "../../../services";
import {ImportDossierListResultModel} from "../../../models/importDossierListResult.model";
import {Utils} from "@amlCore/utils";
import {FileSaverService} from "ngx-filesaver";

@Component({
  selector: 'app-dossier-import-result',
  templateUrl: './importDossierResult.component.html'
})
export class ImportDossierResultComponent implements OnInit {
  @Output() importOkved = new EventEmitter<boolean>();
  result: ImportDossierListResultModel;

  constructor(private activeModal: NgbActiveModal,
              private dossierService: DossierService,
              protected fSaver: FileSaverService) {
  }

  ngOnInit(): void {
  }

  /**
   * Установить параметры компонента
   */
  open(list: ImportDossierListResultModel) {
    this.result = list;
  }

  /**
   * Назад
   */
  goBack() {
    this.activeModal.close(null);
  }

  /**
   * Импортировать досье с некорректными оквэд
   */
  importOkvedFn() {
    this.importOkved.emit();
  }

  /**
   * Сформировать отчет
   */
  downloadReport() {
    this.dossierService.downloadDossierImportReport(this.result).subscribe(data => {
      if (data.type === 4) {
        this.fSaver.save(data.body, Utils.getFileNameFromContentDisposition(data));
      }
    });
  }

  /**
   * Есть ли ошибки импорта
   */
  hasErrors() {
    return this.result?.xsdError?.length
      || this.result?.doubleClientError?.length
      || this.result?.clientIdError?.length
      || this.result?.okvedError?.length
      || this.result?.lockError?.length;
  }
}
