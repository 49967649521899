export class QrefOkcmNotlegacyModel {
  kod: number;
  kokcm: string;
  ncmShort: string;
  ncm: string;
  ddEnd: any;
  ddBegin: any;

  constructor() {
  }
}

