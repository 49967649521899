import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CryptCertObject, CryptographService} from "../../../cryptography";
import {ApiService} from "@amlCore/services";
import {ProfileService} from "../../services";
import {CertificateUsers} from "@amlCore/interfaces";


@Component({
  selector: 'app-cert-select',
  templateUrl: './certificateSelect.component.html',
  styleUrls: ['./certificateSelect.component.scss']
})
export class CertificateSelectComponent implements OnInit {
  @Input()
  preloaderCrypt = true;
  @Input()
  activeCert: CertificateUsers = null;
  @Input()
  addCert: (cert) => void;
  @Input()
  certs: CryptCertObject[] = null
  selectCert = null;
  showSelect = false;
  get placeholder() {
    return this.certs?.length ? 'Выберите сертификат из списка' : 'В хранилище "Личные" нет действующих сертификатов'
  }
  emptyText = 'На рабочем месте в хранилище "Личные" не найдено ни одного действующего сертификата. ' +
    'Для получения сертификата обратитесь в Удостоверяющий Центр. \n' +
    'Если Вы уже получили новый сертификат, установите его в хранилище "Личные", обновите страницу и выберите сертификат из списка. '

  ngOnInit(): void {

  }

  constructor(private cryptographService: CryptographService) {
  }






}
