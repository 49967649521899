import {Injectable} from '@angular/core';
@Injectable()
export class JwtService {
    constructor() {
    }

    /**
     * Получение токена
     */
    getToken(): string {
        return window.sessionStorage.getItem('Auth-Token');
    }

    /**
     * Получение рефреш токена
     */
    getRefreshToken(): string {
        return window.sessionStorage.getItem('Refresh-Token');
    }

    /**
     * Сохранения токена
     */
    saveToken(token: string, refreshToken: string | null): void {
        token && window.sessionStorage.setItem('Auth-Token', token)
        refreshToken && window.sessionStorage.setItem('Refresh-Token', refreshToken)
    }

    /**
     * Уничтожение токена
     */
    destroyToken(): void {
        window.sessionStorage.removeItem('Auth-Token');
        window.sessionStorage.removeItem('Refresh-Token');
    }
}
