import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'truncate'
})
/**
 * Пайп для сокращения текста по кол-ву символов и в конце выставляется спец. символ
 * по умолчанию - это '...'
 * Example 1:
 *  ...
 *  {{ riskfactorClass.info | truncate:[100] }}
 *  ...
 * Example 2:
 * ...
 * {{ riskfactorClass.info | truncate:[100, '***'] }}
 * ...
 */
export class TruncatePipe implements PipeTransform {
  private readonly _defaultSpecialSymbol = '...';
  transform(value: string, args: any[]): string {
    const limit = args.length > 0 ? parseInt(args[0], 10) : 20;
    const trail = args.length > 1 ? args[1] : this._defaultSpecialSymbol;
    if (!value) {
      return '';
    }
    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}
