import {BaseFilterModel} from "./baseFilter.model";


export class FmDraftFilter extends BaseFilterModel {
  public documentType = null;
  public documentStatus = null;
  public dateFrom? = null;
  public dateTo? = null;
  public signDateFrom? = null;
  public signDateTo? = null;
  public createDateFrom? = null;
  public createDateTo? = null;
  public sendDateFrom? = null;
  public sendDateTo? = null;
  public requestDateFrom? = null;
  public requestDateTo? = null;
  public ticketDateFrom? = null;
  public ticketDateTo? = null;
  public containerName? = null;
  public creatorId? = null;
  public inn? = null;
}
