import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from "@angular/core";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {Observable} from "rxjs";
import {CustomSelectComponent} from "../customSelect";

/**
 * Компонент списков работающих с удаленными данными
 *
 * itemsSource ОБЯЗАТЕЛЕН.
 * loadData - метод загрузки данных
 * param - параметры для сервера
 * @example itemsSource:
 * getList() {
 *  return (param: any) => this.loadData(param);
 * }
 */
@Component({
  selector: 'app-custom-source-select',
  templateUrl: './customSourceSelect.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomSourceSelectComponent),
    multi: true,
  }],
})
export class CustomSourceSelectComponent extends CustomSelectComponent implements OnInit {

  /**
   * Метод для загрузки
   */
  @Input() itemsSource: (param: any) => Observable<any>;
  page = 0;
  @Input() size = 20;
  search = '';
  items = [];
  /**
   * Возвращаем выбранный элемент
   */
  @Output() selectedItem: any = new EventEmitter<any>();

  ngOnInit(): void {
    if (!this.itemsSource) {
      console.error('Необходиом указать itemsSource');
    }
    this.loadData(this.size, this.page, this.search);
  }

  /**
   * Грузим след страницу при прокрутке до конца
   */
  scrollToEnd() {
    this.page++;
    this.loadData(this.size, this.page, this.search);
  }

  /**
   * Загрузка данных
   * @param size - кол-во элементов
   * @param page - страница
   * @param search - поле поиска
   * @param clearOld - флаг нужно ли сбрасыать старый результат
   */
  loadData(size, page, search, clearOld = false) {
    const param = {size, page, search: search};
    this.itemsSource(param).subscribe(data => {
      if (clearOld) {
        this.items = data.content ? data.content : data;
      } else {
        this.items.push(...data.content ? data.content : data);
      }
      this.items = [...this.items];
    });
  }

  /**
   * Поиск в строке
   * @param $event - event term - то что сейчас ищется в строке
   */
  searchFn($event) {
    this.search = $event.term;
    this.page = 0;
    this.loadData(this.size, this.page, this.search, true);
  }

  /**
   * Сбрасываем поле поиска, страницы. Грузим список по-умолчанию
   */
  afterBlur($event) {
    this.search = '';
    this.page = 0;
    this.loadData(this.size, this.page, this.search, true);
  }

  selected($event) {
    this.selectedItem.emit($event);
  }

  /**
   * Возвращаем true чтоб не фильтровать внутри существующего списка. А ждать что пришлет сревре
   */
  customSourceSearchFn(): boolean {
    return true;
  }

}
