import {BaseFilterModel} from "./baseFilter.model";

export class FromuFilter extends BaseFilterModel {
  subjectType: string = null; // тип субъекта
  name = ''; // ФИО / наименование организации
  sname = '';
  gname = '';
  pname = '';
  birthDate = null; // Дата рождения
  inn = ''; // ИНН
  series = ''; // серия документа удост.личность
  number = ''; // номер документа удост.личность
  ogrn = ''; // ОГРН
  idSubject = ''; // Идентификатор записи
}
