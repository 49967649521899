import {Component, forwardRef, Input} from '@angular/core';
import {DictionariesService} from "../../../service";
import {QrefValutModel} from "../../../models";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {ViewDictionaryBase} from "../view-dictionary-base";

@Component({
  selector: 'app-dict-qref-valut',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => QrefValutViewComponent),
    multi: true
  }],
  template: `
    <div class="mb-3 pl-0 pr-0" [ngClass]="{ 'col-3': col3 }">
      <label class="form-label">{{title}}
        <app-rq *ngIf="isFieldReq(formControl)" [isReadOnly]="isDisabled"></app-rq>
      </label>
      <app-custom-select *ngIf="dictionary | async as dict; else loading"
                         code="kodVal"
                         name="naimVal"
                         [items]="dict"
                         [returnOnlyId]="true"
                         [formControl]="formControl"
                         [fieldsFromSearch]="['naimVal','kodVal']"
                         [isInvalid]="submitted && formControl.errors">
        <ng-template let-item #labelTemplate>
          <b> {{ item.kodVal }} </b>
          {{ item.naimVal }}
        </ng-template>
        <ng-template let-item let-search="search" #optionTemplate>
          <div style="white-space: normal">
            <b> {{ item.kodVal }} </b>
            {{ item.naimVal }}
          </div>
        </ng-template>
      </app-custom-select>
      <ng-template #loading>
        ...загрузка
      </ng-template>
      <app-valid [logic]="formControl" [submitted]="submitted"></app-valid>
    </div>
  `
})
export class QrefValutViewComponent extends ViewDictionaryBase<QrefValutModel> {
  getDictionary = this.dictSrv.getQrefValutList();
  @Input() col3 = true;
  constructor(private dictSrv: DictionariesService) {
    super();
  }
}
