import {Component, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-save-error-modal',
  templateUrl: './save-error-modal.component.html',
  styleUrls: ['./save-error-modal.component.scss']
})
export class SaveErrorModalComponent implements OnInit {

  @Input() flkErrors;
  @Input() logInvalidFieldsResult;
  

  constructor(public modal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }
}
