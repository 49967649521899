import {Component} from "@angular/core";
import {DossierService} from "../../../../../services";
import {DictionaryService} from "@amlCore/services";
import {DossierBaseModel} from "../../dossierBaseModel";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {AlertPanelService} from "@amlCore/components";

@Component({
  selector: 'app-authority-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './authorityDossier.component.html'
})
export class AuthorityDossierComponent extends DossierBaseSubComponent {

  constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }
  checked() {
    this.formGroupApp.get('stopPowers').patchValue(Number(this.formGroupApp.get('stopPowers').value));
  }
}
