/**
 * API по базовым справочникам АМЛ.
 * API по базовым справочникам АМЛ.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Данные записи справочника БИК.
 */
export interface QrefCbBnkseek { 
    /**
     * БИК. Ключевое поле таблицы.
     */
    newnum?: string;
    /**
     * Код типа участников расчетов.
     */
    pzn?: string;
    /**
     * Код типа участника системы электронных расчетов.
     */
    uer?: string;
    /**
     * Код территории РФ.
     */
    rgn?: string;
    /**
     * Индекс.
     */
    ind?: string;
    /**
     * Тип населеного пункта.
     */
    tnp?: string;
    /**
     * Наименование населеного пункта.
     */
    nnp?: string;
    /**
     * Адрес.
     */
    adr?: string;
    /**
     * БИК РКЦ.
     */
    rkc?: string;
    /**
     * Наименование.
     */
    namep?: string;
    /**
     * Краткое наименование.
     */
    namen?: string;
    /**
     * Кор.сч.
     */
    newks?: string;
    /**
     * Первый БИК.
     */
    permfo?: string;
    /**
     * Срок прохождения документов.
     */
    srok?: string;
    /**
     * Абонентский телеграф 1.
     */
    at1?: string;
    /**
     * Абонентский телеграф 2.
     */
    at2?: string;
    /**
     * Номер телефона.
     */
    telef?: string;
    /**
     * Регистрационный номер.
     */
    regn?: string;
    /**
     * ОКПО.
     */
    okpo?: string;
    /**
     * Номер становки центра коммутации сообщений.
     */
    cks?: string;
    /**
     * Номер счета.
     */
    ksnp?: string;
    /**
     * Ключ.
     */
    vkey?: string;
    /**
     * Уникальный внутренний код приемника.
     */
    vkeydel?: string;
    /**
     * Дата изменения.
     */
    dtIzm?: string;
    /**
     * Дата добавления.
     */
    dateIn?: string;
    /**
     * Дата контроля.
     */
    dateCh?: string;
    /**
     * Отсутствует описание. На данный момент не и
     */
    real?: string;
}

