import {Component, OnInit, ViewChild} from "@angular/core";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup} from "@angular/forms";
import {DossierAccessEnum} from '@amlCore/enums';
import {AccessDossierTypeModel, DossierList, StaffDossierModel} from "../../../models";
import {SelectedModelParamModel, DossierFilter} from "@amlCore/models";
import {ConfirmComponent} from "@amlCore/modals";
import {Observable} from "rxjs";
import {DossierService} from "../../../services";
import {Utils} from "@amlCore/utils";
import {ValidatorService} from "@amlCore/services";
import {AlertPanelComponent, AlertPanelService} from "@amlCore/components";
import {DossierBaseModel} from "../dossier/dossierBaseModel";

@Component({
  selector: 'app-staff-dossier',
  templateUrl: './staffDossier.component.html'
})
export class StaffDossierComponent implements OnInit {

  state: StaffDossierEnum;
  filterDossier: DossierFilter | any;
  searchDossier: string;
  totalDossier: number;
  selectedModelParamDossier: SelectedModelParamModel;
  form: FormGroup;
  submitted = false;
  isReadOnly = false;
  DossierAccessEnum = DossierAccessEnum;
  StaffDossierEnum = StaffDossierEnum;
  /**
   * Объект для отображения информации по сохранению
   */
  @ViewChild('alertObjSave', {static: true}) alertObjToSave: AlertPanelComponent;

  constructor(private activeModal: NgbActiveModal, private formBuilder: FormBuilder, private modalService: NgbModal,
              private dossierService: DossierService, protected validationSrv: ValidatorService, private alertPanelSrv: AlertPanelService,
              protected dossierBaseModel: DossierBaseModel) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      data: this.dossierBaseModel.getAccessInfo()
    });
    const accessForm = this.form.controls['data'];
    accessForm.get('dossierIds').setValue(this.selectedModelParamDossier.selectedModel.map((item: DossierList) => item.id))
  }

  /**
   * Установить параметры компонента
   */
  open(param: StaffDossierModel) {
    this.state = param.state;
    this.filterDossier = param.filter;
    this.searchDossier = param.search;
    this.totalDossier = param.total;
    this.selectedModelParamDossier = param.selectedModelParam;
  }

  /**
   * Назад
   */
  goBack() {
    this.activeModal.close(null);
  }

  /**
   * Назначить / Удалить назначения в зависимости от state
   * Передаем список Id досье и данные для прав
   */
  doSave(state: StaffDossierEnum) {
    const form = this.form.controls['data'];
    if (state === StaffDossierEnum.ASSIGN && !form.valid || state === StaffDossierEnum.DELETE && !form.get('user').valid) {
      this.submitted = true;
      this.alertObjToSave.open(this.alertPanelSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме'));
      return;
    }
    const saveData = form.value as AccessDossierTypeModel;
    const count = saveData.dossierIds.length;
    let message;
    if (state === StaffDossierEnum.DELETE) {
      message = `Указанный сотрудник будет исключен из списка ответственных сотрудников для выбранных клиентов.`;
    } else if (state === StaffDossierEnum.ASSIGN) {
      message = `Указанный сотрудник будет назначен ответственным для выбранных клиентов. Если сотрудник ` +
        `уже назначен ответственным за клиента, то информация по ответственному сотруднику будет изменена.`;
    }

    const modal = this.modalService.open(ConfirmComponent);
    modal.componentInstance.text = message;
    modal.componentInstance.isOutputText = false;
    modal.result.then(data => this.save(state, saveData), cancel => {});
  }

  private save(state: StaffDossierEnum, saveData: AccessDossierTypeModel) {
    // Удаляем системные переменные
    Utils.removeKeysFromObj(saveData, ['$fa', '$partId', '$checkedFlk']);
    let saveOrDeleteObservable: Observable<any>;
    if (state === StaffDossierEnum.DELETE) {
      saveOrDeleteObservable = this.dossierService.deleteDossierStaff(saveData);
    } else if (state === StaffDossierEnum.ASSIGN) {
      saveOrDeleteObservable = this.dossierService.saveDossierStaff(saveData);
    }
    saveOrDeleteObservable.subscribe(data => {
      this.goBack();
    }, error => {
      console.log(error);
    });
  }

}

/**
 * Тип назначения ответственного сотрудника
 */
export enum StaffDossierEnum {
  DELETE = 'delete', // для удаления
  ASSIGN = 'assign' // для назначения
}
