import { Subject } from './subject.model';
import {BaseFilterModel} from "./baseFilter.model";

export class CheckClientsFilter  extends BaseFilterModel {
  result = false;           // Все/требующие решения
  checkResult = null;      // Результат проверки
  dictType = null;         // Вид перечня
  mvkInfo = '';          // Версия перечня
  terrorInfo = '';       // Версия перечня
  fromuInfo = '';        // Версия перечня
  otkazInfo = '';        // Версия перечня
  ftsInfo = '';          // Версия перечня
  msFoundInfo = '';      // Версия перечня
  msLeadInfo = '';       // Версия перечня
  msAdrInfo = '';        // Версия перечня
  passportInfo = '';     // Версия перечня
  paycardInfo = '';     // Версия перечня
  riskGroupInfo = '';     // Версия перечня
  type = null;             // Тип риска
  level = null;            // Уровень риска
  penalty = null;        // Принятые меры
  periodCheckStart = ''; // Период проверки C
  periodCheckEnd = '';   // Период проверки ПО
  subject = new Subject();
}
