import {BaseFilterModel} from "./baseFilter.model";

export class CheckTransactionsFilter  extends BaseFilterModel {
  result = '';              // переключатель все/требующие решения
  checkDateFrom = '';       // период проверки С
  checkDateTo = '';         // период проверки По
  user = null;              // Пользователь
  dateFrom = '';            // Дата операции С
  dateTo = '';              // Дата операции По
  currency = '';            // Код валюты, справочник валют
  operId = '';              // Уникальный номер операции
  dateDocFrom = '';         // Дата документа С
  dateDocTo = '';           // Дата документа По
  typeDoc = null;           // Тип документа
  numberDoc = '';           // Номер документа
  amountCurrFrom = '';      // Сумма операции в валюте С
  amountCurrTo = '';        // Сумма операции в валюте По
  amountRubFrom = '';       // Сумма операции в рублях С
  amountRubTo = '';         // Сумма операции в рублях По
  purpose = '';             // Назначение платежа
  account = '';
  clientType = null;        // Тип клиента
  name = '';                // Наименование/ФИО
  inn = '';                 // ИНН (КИО)
  className = '';           // Код проверки
  checkResult = null;       // Результат проверки
  memberStatus = null;      // Статус участника
  vid = null;               // Роль
}
