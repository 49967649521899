export class QrefOkcmModel {
  id: number;
  kokcm: string;
  alfa2: string;
  alfa3: string;
  ncmShort: string;
  ncm: string;
  kokcm3: string;

  constructor() {
  }
}

