import { BaseFilterModel } from "./baseFilter.model";

export class RequestsgaUnstrustFilter extends BaseFilterModel {
  public status = null;
  public dateFrom? = null;
  public dateTo? = null;
  public requestSender? = null;
  public requestRegNum? = null;
  public replyRegNum? = null;
  public creatorId? = null;
}
