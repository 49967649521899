import {Component, OnInit, ViewChild} from '@angular/core';
import {
  AlertPanelService,
  ITable,
  ITableCallBack,
  ITableItemDescriptor,
  TableCompComponent
} from '@amlCore/components';
import {AccessDossierTypeModel, DossierRequest} from '../../../../models/dossier';
import {AccessInfoDossierComponent} from '../components/accessInfo';
import {DossierBasePageComponent} from '../dossierBasePage.component';
import {DossierService} from '../../../../services';
import {ActivatedRoute, Router} from '@angular/router';
import {DictionaryService, UserService} from '@amlCore/services';
import {IDossierSubService} from '../IDossierSub.service';
import {AccessDossierService} from './accessDossier.service';
import {Utils} from '@amlCore/utils';
import {DossierAccessEnum} from '@amlCore/enums';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FileSaverService} from "ngx-filesaver";

@Component({
  selector: 'app-access-dossier',
  styleUrls: ['../dossier.css'],
  templateUrl: './accessDossier.component.html'
})
export class AccessDossierComponent extends DossierBasePageComponent implements OnInit, ITable {

  modelPage: Array<AccessDossierTypeModel>;
  itemDescriptor: ITableItemDescriptor;
  dossierAccessEnum = DossierAccessEnum;
  isTableTab = true;
  @ViewChild('tableComp', {static: true}) tableComp: TableCompComponent;

  constructor(protected dossierService: DossierService,
              protected route: ActivatedRoute,
              protected accessDossierService: AccessDossierService,
              protected dictSrv: DictionaryService,
              protected router: Router,
              protected userService: UserService,
              protected alertPanelSrv: AlertPanelService,
              protected modalService: NgbModal,
              protected fSaver: FileSaverService) {
    super(dossierService, route, dictSrv, router, userService, alertPanelSrv, modalService, fSaver);
  }

  ngOnInit() {
    // Инициализация вкладки
    this.modelPage = this.initPage();
    // Описание элемента таблицы
    this.itemDescriptor = {
      create: this.accessDossierService.getAccessInfo.bind(this.accessDossierService),
      component: AccessInfoDossierComponent,
      params: {
        typePage: this.typePage,
        selectedUserIds: this.getSelectedUserIds.bind(this) // .bind(this) необходим для правильного понимания контекста this
      }
    } as ITableItemDescriptor;
    this.isReadOnly = this.isReadOnly || !this.userService.checkAccess(DossierAccessEnum.DOSSIER_ACCESS_ALL);
  }

  /**
   * Инициализация модели
   * Преобразуем в нужный формат
   * @param model - данные сервера
   */
  initModel(model) {
    let newModel;
    this.partId = null;
    if (model) {
      newModel = [];
      model.content.forEach(item => {
        item.$partId = item.id; // id части, сохраняем для удаления элемента
        newModel.push(item);
      });
      Utils.deleteEmptyObj(newModel);
    } else {
      newModel = [];
    }
    const formData = {};
    formData[this.typePage] = newModel;
    return formData;
  }

  getService(): IDossierSubService {
    return this.accessDossierService;
  }

  callbackItemClosed(event: ITableCallBack) {
    if (typeof event.editIndex === 'number') {
      this.saveTableItem(event);
    }
  }

  /**
   * Получаем id выбранных пользователей с активным назначением
   */
  getSelectedUserIds(): Array<string> {
    return (this.panelForm.controls[this.typePage].value as Array<AccessDossierTypeModel>).filter(item => item.active).map(item => item.user.id);
  }

  /**
   * @override переопределяем метод из базового класса
   * @param event - событие
   */
  deleteTableItem(event: ITableCallBack): void {
    const paramDelete = {} as DossierRequest;
    paramDelete.partName = this.typePage;
    paramDelete.id = this.dossierId;
    const dataCurrentPage = this.panelForm.get(this.typePage).value;
    let obj = dataCurrentPage[event.editIndex];
    paramDelete.model = [obj ? obj['$partId'] : null];
    if (paramDelete.model.some((e) => !e)) {
      this.route.parent.params.subscribe((data: { id: string }) => {
        if (data && data.id) {
          paramDelete.model = [data.id];
          this._deletePartDossierByType(paramDelete, event);
        }
      })
    } else {
      this._deletePartDossierByType(paramDelete, event);
    }
    // Закрываем все окна
    this.modalService.dismissAll();
  }

  private _deletePartDossierByType(paramDelete: DossierRequest, event: ITableCallBack): void {
    this.dossierService.deletePartDossierByType(paramDelete).subscribe(() => {
      // Из таблицы НЕ удаляем элемент и обновляем весь список, т.к. мб поменялся признак Active
      this.loadList();
      this.alertPanelComponent.open(this.alertPanelSrv.getSuccessMsg('Данные по вкладке сохранены'));
    });
  }

  loadList(): void {
    this.route.parent.params.subscribe((data: { id: string }) => {
      if (data && data.id) {
        this.dossierService.getDossierByType({
          id: data.id,
          partName: this.typePage,
          model: null
        } as DossierRequest).subscribe((data) => {
          this.panelForm.get(this.typePage).patchValue(data.content);
          this.panelForm.get(this.typePage).updateValueAndValidity();
        })
      }
    });
  }

  destroy() {
    // ТУТ писать реализацию ngOnDestroy для компонента
  }
}
