import {Directive, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {ErrorFLKItemType} from "@amlCore/models";
import {IFocus} from "@amlCore/interfaces";

/**
 * Автофокус на поле для ошибок флк
 * Скролл при указание scrollIntoElement true
 */
@Directive({
  selector: "[appFocusFLK]"
})
export class AutoFocusFlkDirective {
  scrollIntoElement = false;
  @Output() openWindow: any = new EventEmitter<ErrorFLKItemType>();
  @Input() offsetFocusFLK = 80; // размер отступа от оригинального скролла (необходим при перекрытии sticky компонентами)
  @Input() errorFLKData: ErrorFLKItemType;
  // Объект фокусировки - ссылка на самого себя (только для кастомных или бутстрап компонентов)
  @Input() focusField: IFocus;

  constructor(private el: ElementRef) {
  }

  @Input() set appFocusFLK(scroll: boolean) {
    this.scrollIntoElement = scroll;
    if (this.scrollIntoElement && typeof this.scrollIntoElement === 'boolean') {
      if (this.focusField) {
        this.focusField.focus();
      } else {
        this.el.nativeElement.focus();
      }
      this.el.nativeElement.scrollIntoView({block: "start"});
      // TODO: для модальных окон некорректный переход (window - это главное окно)
      window.scrollBy(0, -this.offsetFocusFLK);
      if (this.openWindow) {
        this.openWindow.emit(this.errorFLKData);
      }
    }
  }

}
