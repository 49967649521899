import {Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {SpkSearch} from "../../models/spk";
import {TableService} from "@amlCore/services";


@Component({
  selector: 'app-client-spk',
  templateUrl: './spk.component.html'
})
export class SpkComponent implements OnInit {

  filter: SpkSearch = new SpkSearch();

  constructor(
    private activatedRoute: ActivatedRoute,
    protected tableSrv: TableService
  ) {
  }

  ngOnInit() {
    this.tableSrv.sharedMenu().emit(this.activatedRoute.snapshot.data.pageTitle);
  }
}
