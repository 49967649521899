import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CertificateUsers} from "@amlCore/interfaces";


@Component({
  selector: 'app-cert-table',
  templateUrl: './certificateTable.component.html'
})
export class CertificateTableComponent implements OnInit {
  @Input()
  certs: CertificateUsers[] = [];
  ngOnInit(): void {
  }

  constructor() {
  }

}

