import {Component, OnDestroy, OnInit} from "@angular/core";
import {DossierService} from "../../../../../services";
import {DictType, SvedIOUInfoType} from "../../../../../models/dossier";
import {AlertPanelService, ITableEditorItem, ITableItem} from "@amlCore/components";
import {DictionaryService} from "@amlCore/services";
import {Unsubscribable} from "rxjs";
import {DossierBaseModel} from "../../dossierBaseModel";
import {FormGroup} from "@angular/forms";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {ClientTypeEnum} from "@amlCore/enums";

@Component({
  selector: 'app-iou-info-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './iouInfoDossier.component.html'
})
export class IouInfoDossierComponent extends DossierBaseSubComponent implements OnInit, OnDestroy, ITableItem {

  titlePanel = 'Сведения об исполнительном органе управления';
  showTitle = true;
  kolOUList: Array<DictType> = [];
  ispOrgList: Array<DictType> = [];
  isPostShow = false;
  isTableItem = false;
  typeValueChanges: Unsubscribable;
  kolOUValueChanges: Unsubscribable;
  bfTypeList: DictType[] = [];
  clientTypeChange: number;

  constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  /**
   * Описание формы
   */
  getFormDescription(): FormGroup {
    return this.dossierBaseModel.getSvedIUO();
  }

  /**
   * Инициализация FormGroup
   */
  initForm(): void  {
    if (!this.isTableItem) {
      this.formGroupApp = this.getFormDescription();
    }
  }

  ngOnInit(): void  {
    this.kolOUList = this.getStaticData('kolOU');
    this.ispOrgList = this.getStaticData('ispOrg');
    this.initType(this.f('type').value);
    this.typeValueChanges = this.f('type').valueChanges.subscribe(val => {
      this.initType(val);
    });
    this.initKolOU(this.f('kolOU').value);
    this.kolOUValueChanges = this.f('kolOU').valueChanges.subscribe(val => {
      this.initKolOU(val);
    });
    this.addUnsubscribable(this.typeValueChanges);
    this.addUnsubscribable(this.kolOUValueChanges);
    this.focusObject = this.generateFocusObject(new SvedIOUInfoType());
    this.initBfType();
    this.dossierService.clientTypeEmit.subscribe((data) => {
      this.clientTypeChange = data;
      this.initBfFields();
    });
  }


  setSubmitted(value: boolean): void  {
    this.submitted = value;
  }


  getTitle(): string {
    return this.titlePanel;
  }

  /**
   * Инициализируем компонент как элемент формы просмотра таблицы
   * @param param.form - форма для связи
   * @param param.params - дополнительные параметры
   */
  initTableItem(param: ITableEditorItem): FormGroup {
    this.initForm();
    this.isTableItem = true;
    if (param.model) {
      this.formGroupApp.patchValue(param.model);
    }
    this.showTitle = false;
    if (param.params && param.params.typePage) {
      this.typePage = param.params.typePage;
    } else {
      console.log('Необходимо указать typePage у компонента');
    }
    this.isReadOnly = param.isReadOnly;
    if (this.isReadOnly){
      this.formGroupApp.disable();
    }
    return this.formGroupApp;
  }

  initType(val): void {
    if (!this.formGroupApp.disabled) {
      this.f('kolOU').disable();
      if (val) {
        const type = val.code;
        if (type && type === "2") {
          this.f('kolOU').enable();
        }
      }
      this.initBfFields();
    }
  }

  initKolOU(val): void {
    if (!this.formGroupApp.disabled) {
      this.f('otherOU').disable();
      if (val) {
        const type = val.code;
        if (type) {
          const typeFieldValue = this.f('type').value;
          if (type === "4" && typeFieldValue && typeFieldValue.code === "2") {
            this.f('otherOU').enable();
          }
        }
      }
    }
  }

  initPost(clientType: ClientTypeEnum): void {
    const whiteListClientsType = [ClientTypeEnum.INDIVIDUAL_PERSON, ClientTypeEnum.INDIVIDUAL_ENTREPRENEUR];
    this.f('post').disable();
    this.isPostShow = false;
    if (clientType && whiteListClientsType.includes(clientType)) {
	  if(!this.isReadOnly){
        this.f('post').enable();
      }
      this.isPostShow = true;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribable();
  }

  /**
   * Инициализация доступности признака и вида идентификации бенефициара
   */
   initBfType(): void {
    this.bfTypeList = this.dictSrv.getStaticData("bfTypeList");
    this.showBfType = true;
  }
  /**
   * Checkbox Признан бенефициарным владельцем
   * @param e
   */
  resetBfSign(e) {
    this.f('bfSign').setValue(e.target.checked ? 1: 0);
    this.initBfSign()
  }

  initBfSign() {
    if (this.f('bfSign').value) {
      this.f('bfOwner').enable();
      this.f('bfOther').enable();
    } else {
      this.f('bfOwner').disable();
      this.f('bfOther').disable();
    }
  }

  initBfFields() {
    if (!this.formGroupApp.disabled) {
      if (this.clientTypeChange != 2 || this.f('type').value?.code != '1') {
        this.f('bfSign').disable();
        this.f('bfOwner').disable();
        this.f('bfOther').disable();
      } else {
        this.f('bfSign').enable();
        this.initBfSign();
      }
    }
  }
}
