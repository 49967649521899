import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { DossierService } from "../../../../../services";
import { DictionaryService } from "@amlCore/services";
import { Unsubscribable } from "rxjs";
import { AlertPanelService, ICustomSelectMapper } from "@amlCore/components";
import { FormGroup } from "@angular/forms";
import { AddressModelType, DossierBaseModel } from "../../dossierBaseModel";
import { DossierBaseSubComponent } from "../dossierBaseSub.component";

@Component({
  selector: 'app-address-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './addressDossier.component.html'
})
export class AddressDossierComponent extends DossierBaseSubComponent implements OnInit, OnDestroy {

  countryValueChanges: Unsubscribable;
  formGroupValueChanges: Unsubscribable;
  regionMapper: ICustomSelectMapper;
  isRegionReq = false;
  @Input() typeAddress: AddressModelType;
  @Output() modelChange: any = new EventEmitter<any>();

  constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
    protected dossierBaseModel: DossierBaseModel,
    protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  /**
   * Описание формы
   */
  getFormDescription(): FormGroup {
    return this.dossierBaseModel.getAddressType(this.typeAddress);
  }

  /**
   * Инициализация FormGroup
   */
  initForm() {
    this.formGroupApp = this.getFormDescription();
  }

  ngOnInit() {
    this.loadRemoteData(['oksm', 'fiasregion']);
    setTimeout(() => {
      this.initCountry(this.f('country').value);
      this.countryValueChanges = this.f('country').valueChanges.subscribe(value => {
        this.initCountry(value);
      });
      this.formGroupValueChanges = this.formGroupApp.valueChanges.subscribe(data => {
        this.modelChange.emit(data);
      });
      this.addUnsubscribable(this.countryValueChanges);
      this.addUnsubscribable(this.formGroupValueChanges);
    });
    this.regionMapper = {
      toModel: this.regionMapperToModel.bind(this)
    } as ICustomSelectMapper;
  }

  initCountry(value) {
    this.isRegionReq = false;
    if (value) {
      const code = value.code;
      if (code) {
        if (code === "643") {
          this.isRegionReq = true;
        }
      }
    }
  }

  /**
   * Описание преобразования объекта списка регионов для модели
   * @param origin - данные элемента списка
   * @param isDictData - если true, то origin - данные справочника
   *                          false, то origin - преобразованные данные модели (DictType)
   */
  regionMapperToModel(origin: any, isDictData: boolean): any {
    if (isDictData) {
      return {
        code: origin.code,
        name: origin.formalname + ' ' + origin.shortname
      };
    } else {
      return origin;
    }
  }

  /**
   * Заполняем адрес из фиаса
   */
  fillAddress(data) {
    if (data) {
      this.formGroupApp.patchValue({
        postIndex: data.postalcode,
        region: {
          name: data.region,
          code: data.regionCode
        },
        area: data.area,
        city: data.city,
        locality: data.locality,
        street: data.street,
        house: data.house,
        building: data.building
        // apartment: data.,
        // box: data.,
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribable();
  }

}
