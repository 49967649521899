import {Directive, ElementRef, OnInit, OnDestroy} from "@angular/core";
import {CryptoValidataService} from "../services";
import {Subscription} from "rxjs";

@Directive({selector: '[appCryptValidataCheckState]'})
export class CryptValidataCheckStateDirective implements OnInit, OnDestroy {
    isCryptEnableSub: Subscription;

    constructor(
        private elementRef: ElementRef,
        private cryptographService: CryptoValidataService) {
    }

    ngOnInit(): void {
        this.isCryptEnableSub = this.cryptographService.isCryptEnable.subscribe(({state}) => {
            state ? this._enableElement() : this._disableElement();
        });
    }

    /**
     * необходимо для @AutoUnsub
     */
    ngOnDestroy(): void {
        if (this.isCryptEnableSub) {
            this.isCryptEnableSub.unsubscribe();
        }
    }

    private _disableElement(): void {
        this.elementRef.nativeElement.disabled = true;
        this.elementRef.nativeElement.style.cursor = "not-allowed";

    }

    private _enableElement(): void {
        this.elementRef.nativeElement.disabled = false;
        this.elementRef.nativeElement.style.cursor = "pointer";
    }
}
