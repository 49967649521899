import {Directive, HostListener, Input} from '@angular/core';
import {ApiService} from "@amlCore/services";
import {FileSaverService} from "ngx-filesaver";
import {Utils} from "@amlCore/utils";

@Directive({
  selector: '[appSaveFile]'
})
export class SaveFileDirective {

  @Input() fileUrl = ''
  @Input() fileName = ''
  constructor(private api: ApiService, private fileSaver: FileSaverService) { }

  @HostListener('click')
  SaveFile() {
    this.api.byteGet(this.fileUrl).subscribe(data => {
      this.fileSaver.save(data.body, this.fileName || Utils.getFileNameFromContentDisposition(data))
    })
  }
}
