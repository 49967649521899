import {Component, Input, OnInit} from '@angular/core';
import {faSortAmountDown} from "@fortawesome/free-solid-svg-icons/faSortAmountDown";
import {faSortAmountUp} from "@fortawesome/free-solid-svg-icons/faSortAmountUp";

@Component({
  selector: 'app-sort',
  template: `
    <span class="aml-cursor-pointer" [ngStyle]="{'text-decoration': textDecorType}" id="app-sort-{{sn}}">
      <ng-content></ng-content>
      <span *ngIf="sort === sn && isNeedIcon">
        <fa-icon [icon]="icons.sortDown" [classes]="['ml-2']" *ngIf="dir === 'asc'"></fa-icon>
        <fa-icon [icon]="icons.sortUp" [classes]="['ml-2']"  *ngIf="dir === 'desc'"></fa-icon>
      </span>
    </span>`
})
export class SortComponent implements OnInit {
  @Input() dir: string; // active dir in TableController
  @Input() sort: string; // active sortName in TableController
  @Input() sn: string; // sortName to server
  @Input() textDecorType: 'underline' | 'none' = 'underline'
  @Input() isNeedIcon: boolean = true
  icons = {
    sortDown: faSortAmountDown,
    sortUp: faSortAmountUp
  };

  ngOnInit() {
  }

}
