export class QrefBancModel {
  status: string;
  tip: string;
  namemax: string;
  namemax1: string;
  namer: string;
  name: string;
  datePrb: string;
  typePrb: string;
  ustF: number;
  ufOld: number;
  ustFi: number;
  kolf: number;
  kolfd: number;
  dataReg: string;
  regn: number;
  priz: string;
  dateNam: string;
  dateAdr: string;
  dataIzmud: string;
  licGold: string;
  licGold1: string;
  licRub: string;
  ogran: string;
  ogran1: string;
  licVal: string;
  vv: string;
  rei: string;
  no: string;
  opp: string;
  ko: string;
  kp: string;
  adres: string;
  adres1: string;
  telefon: string;
  fax: string;
  fioPrPr: string;
  fioGlB: string;
  dataOtz: string;
  dataPri: string;
  dateFiz: string;
  pricOtz: string;
  numsrf: string;
  egr: string;

  constructor() {
  }
}

