import {Component, OnDestroy, OnInit} from '@angular/core';
import {TableService, VisitedService} from "@amlCore/services";
import {Subject} from 'rxjs';
import {takeUntil} from "rxjs/operators";
import {DictionariesService} from "../../../service/dictionaries.service";
import {QrefFilModel} from "../../../models/qrefFil.model";
import { TableController } from "@amlCore/tableController";
import {Utils} from "@amlCore/utils";

@Component({
  selector: 'app-dictionary-qref-fil',
  templateUrl: './qrefFil.component.html'
})
export class QrefFilComponent extends TableController implements OnInit, OnDestroy {

  constructor(protected dictSrv: DictionariesService,
              protected visitedService: VisitedService,
              protected tableService: TableService
  ) {
    super(visitedService, tableService);
  }
  isShowVisited = false;
  isSetFocus = false;

  public goBack = Utils.goBack;

  data: QrefFilModel[] = [];
  private destroy$ = new Subject<void>();

  ngOnInit() {
    this.loadDict();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  loadDict() {
    // Данные справочника при последующих вызовах берутся из sessionStorage
    this.dictSrv.getQrefFilList().pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.data = data.content;
    });
  }
  onPageChange(params): void {
    this.loadDict();
  }
}


