import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DictType, SvedTargetType} from "../../../../models/dossier";
import {DossierService} from "../../../../services";
import {DossierBasePageComponent} from "../dossierBasePage.component";
import {IDossierSubService} from "../IDossierSub.service";
import {SvedTargetDossierService} from "./svedTargetDossier.service";
import {AlertPanelService, BigSelectComponent} from "@amlCore/components";
import {DictionaryService, UserService} from "@amlCore/services";
import {Utils} from "@amlCore/utils";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FileSaverService} from "ngx-filesaver";


@Component({
  selector: 'app-sved-target-dossier',
  styleUrls: ['../dossier.css'],
  templateUrl: './svedTargetDossier.component.html'
})
export class SvedTargetDossierComponent extends DossierBasePageComponent implements OnInit {

  modelPage: SvedTargetType;
  isTableTab = false;

  servicesBankList: Array<DictType> = [];
  charractDoList: Array<DictType> = [];
  @ViewChild('services') services: BigSelectComponent;

  constructor(protected dossierService: DossierService,
              protected route: ActivatedRoute,
              private svedTargetDossierService: SvedTargetDossierService,
              protected dictSrv: DictionaryService,
              protected router: Router,
              protected userService: UserService,
              protected alertPanelSrv: AlertPanelService,
              protected modalService: NgbModal,
              protected fSaver: FileSaverService) {
    super(dossierService, route, dictSrv, router, userService, alertPanelSrv, modalService, fSaver);
  }

  ngOnInit(): void {
    // Инициализация вкладки
    this.modelPage = this.initPage();
    // Загрузка справочников
    this.dictSrv.getRemoteData('bankservice').then((result) => {
      this.servicesBankList = result;
    });
    this.charractDoList = this.dictSrv.getStaticData('charractDo');
    this.focusObject = this.generateFocusObject(new SvedTargetType());
  }

  /**
   * Инициализация модели
   * Преобразуем в нужный формат
   * @param model - данные сервера
   */
  initModel(model) {
    let newModel;
    this.partId = null;
    if (model) {
      const partIdList: string[] = Object.keys(model);
      newModel = model[partIdList[0]];
      Utils.deleteEmptyObj(newModel);
    } else {
      newModel = null;
    }
    const formData = {};
    formData[this.typePage] = newModel;
    return formData;
  }

  openServices(event): void {
    this.services.open();
  }

  getService(): IDossierSubService {
    return this.svedTargetDossierService;
  }

  /**
   * Своя обработка фокусировки на ошибках для раздела
   * @param key - путь до поля с ошибкой
   */
  public isFocus(key: string): boolean {
    return this.focusObject.svedTarget ? this.focusObject.svedTarget[key] : false;
  }

  destroy(): void {
    // ТУТ писать реализацию ngOnDestroy для компонента
  }
}
