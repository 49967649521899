import {DictType} from "./dictType.model";
import {DossierDate, DossierT250, DossierT50} from "./base.model";

export class RegInfoInbULType {
  opf: DictType = new DictType();
  iof?: DossierT250 = '';
  regNum: DossierT50 = '';
  nalogCod: DossierT50 = '';
  nameRegOrg: DossierT250 = '';
  dateReg: DossierDate = '';
}
