import {AbstractControl, ControlValueAccessor, FormControl} from "@angular/forms";
import {Input, OnInit} from "@angular/core";
import {Observable} from "rxjs";
import {Page} from "../../models";
import {map} from "rxjs/operators";

export abstract class ViewDictionaryBase<T> implements ControlValueAccessor, OnInit {
  @Input() title: string;
  @Input() submitted: boolean;
  @Input() formControl: FormControl;
  abstract getDictionary: Observable<Page<T>>;
  dictionary: Observable<T[]>;
  isDisabled: boolean = false;

  ngOnInit() {
    if (this.getDictionary)
      this.dictionary = this.getDictionary.pipe(map(page => {
        if((page as any)?.pageInfo?.totalSize  == 0 || page?.totalSize == 0) {
          return [];
        }  else {
          return page.content;
        }
      }));
  }

  // Подготавливаем пустые функции
  onChange = (result: any) => { };
  onTouch = () => { };
  registerOnChange = (fn: any): void => this.onChange = fn;
  registerOnTouched = (fn: any): void => this.onTouch = fn;
  writeValue(outsideValue: string): void {}

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  isFieldReq = (field: FormControl) =>
    Boolean(field?.validator && field?.validator({} as AbstractControl)?.required)
}
