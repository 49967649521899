import {DictType} from "./dictType.model";
import {DossierBool, DossierT250, DossierM50} from "./base.model";

export class SvedTargetObject {
  charractDo: DictType = undefined;
  svedOperW?: DossierT250 = '';
  svedOperM?: DossierT250 = '';
  svedOperQ?: DossierT250 = '';
  svedOperY?: DossierT250 = '';
  orderCountW?: DossierT250 = '';
  orderCountM?: DossierT250 = '';
  orderCountQ?: DossierT250 = '';
  orderCountY?: DossierT250 = '';
  svedCashW?: DossierT250 = '';
  svedCashM?: DossierT250 = '';
  svedCashQ?: DossierT250 = '';
  svedCashY?: DossierT250 = '';
  ved?: DossierBool = '';
  svedDBO: DossierBool = '';
  services?: Array<DictType> = undefined;
}
