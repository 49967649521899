import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {AccessRouteGuard} from "@amlCore/services";
import {
  DictionaryListComponent, QrefBancComponent, QrefCbBnkseekComponent, QrefFilComponent, QrefOblComponent,
  QrefOgrnComponent,
  QrefOkato1Component,
  QrefOkcmComponent,
  QrefOkcmNotlegacyComponent,
  QrefOkwed2Component,
  QrefOkwedComponent,
  QrefSwiftComponent,
  QrefTuCbComponent,
  QrefValutComponent
} from "./components";

const routes: Routes = [
  {
    path: 'dictionaries',
    children: [
      {
        path: 'dictionaryList',
        component: DictionaryListComponent,
        data: {access: 'aml_common_dictionary'},
        canActivate: [AccessRouteGuard]
      },
      {
        path: 'view/banc',
        component: QrefBancComponent
      },
      {
        path: 'view/cb-bnkseek',
        component: QrefCbBnkseekComponent
      },
      {
        path: 'view/fil',
        component: QrefFilComponent
      },
      {
        path: 'view/obl',
        component: QrefOblComponent
      },
      {
        path: 'view/ogrn',
        component: QrefOgrnComponent
      },
      {
        path: 'view/okato1',
        component: QrefOkato1Component
      },
      {
        path: 'view/okcm',
        component: QrefOkcmComponent
      },
      {
        path: 'view/okcm-notlegacy',
        component: QrefOkcmNotlegacyComponent
      },
      {
        path: 'view/okwed',
        component: QrefOkwedComponent
      },
      {
        path: 'view/okwed2',
        component: QrefOkwed2Component
      },
      {
        path: 'view/swift',
        component: QrefSwiftComponent
      },
      {
        path: 'view/cb-tu',
        component: QrefTuCbComponent
      },
      {
        path: 'view/valut',
        component: QrefValutComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DictionariesRoutingModule {
}
