import {CustomValidatorError, Utils} from "@amlCore/utils";
import {DictionaryService} from "@amlCore/services";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {faLockOpen} from "@fortawesome/free-solid-svg-icons/faLockOpen";
import {faLock} from "@fortawesome/free-solid-svg-icons/faLock";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import {ErrorFLKItemType, ErrorFLKModel, ErrorTableFLKModel} from "@amlCore/models";
import {FormGroup} from "@angular/forms";
import {Input} from "@angular/core";
import {DossierService} from "../../../services";
import {DossierRequest} from "../../../models/dossier";
import {DossierAccessEnum} from "@amlCore/enums";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import {AlertPanelComponent, AlertPanelService} from "@amlCore/components";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons/faTimesCircle";

/**
 * Базовый класс для всех компонентов и вкладок в досье
 */
// TODO переименовать, т.к. не является компонентом
export abstract class DossierBaseComponent {
  // Объект для отображения информации по сохранению
  public alertPanelComponent: AlertPanelComponent;
  @Input() submitted = false;
  @Input() errorFLKData: ErrorFLKItemType;
  @Input() focusObject: any;
  getFieldFromClientInfo = Utils.getFieldFromClientInfo;
  getFieldFromAuthority = Utils.getFieldFromAuthority;
  isLoadingCheckFLK = false;

  dictionary: { [name: string]: Array<any>; } = {};
  isOpenFocus = false;
  public icons = {
    trash: faTrash,
    spinner: faSpinner,
    download: faDownload,
    unlocked: faLockOpen,
    locked: faLock,
    exclamationCircle: faExclamationCircle,
    checkCircle: faCheckCircle,
    circle: faTimesCircle,
  };
  // Ошибки по ФЛК
  public errorFLKList: Array<ErrorFLKModel> = [];

  loadRemoteData(types: Array<string>): void {
    const RussianRubCode = "810";
    const stateRubCurrency = "ПРИЗНАК РОССИЙСКОГО РУБЛЯ";

    types.forEach(type => {
      this.dictionary[type] = [];
      this.dictSrv.getRemoteData(type).then(result => {
        // TODO это надо делать на стороне сервера.
        //  Переделать после рефакторинга DictionaryService!!!
        if (type === 'okv') {
          this.dictionary[type] = (result || []).filter((item) => {
            return ((item.iso_dig === RussianRubCode && item.name !== stateRubCurrency) ||
              !Utils.dateNowIsAfter(item.cb_date));
          });
        } else {
            this.dictionary[type] = result || [];
        }
      });
    });
  }

  setSubmitted(value: boolean): void {
    this.submitted = true;
  }

  /**
   * Очистка ошибок по ФЛК для простых полей вкладки
   */
  clearErrorFLKListFields(): void {
    if (this.errorFLKList) {
      this.errorFLKList.forEach(error => {
        if (!error.items) {
          const field = this.f(error.path);
          if (field) {
            setTimeout(() => field.setErrors(null));
          }
        }
      });
    }
  }

  /**
   * Инициализация ошибок по ФЛК для простых полей вкладки
   */
  initErrorFLKSimpleField(): void {
    if (this.errorFLKList) {
      // Проставляем ошибки для простых полей
      this.errorFLKList.forEach(error => {
        // items есть только у ошибок в таблицах или множественных полях
        if (!error.items) {
          // Просто подсвечиваем ошибки без перехода
          this.showFLKError(error, false);
        }
      });
    }
  }

  /**
   * Инициализация ошибок по ФЛК для элемента таблицы
   * @param errorAll - Ошибки по всей таблице
   * @param row - номер в списке из модели (вместо id для однозначной связи с сервером)
   * @param isShowError - показывать ошибки при открытии
   */
  initErrorFLKInTable(errorAll, row, isShowError): void {
    if (errorAll) {
      // Ошибки по текущей записи
      if (row !== null) {
        this.errorFLKList = errorAll[row];
      }
      if (isShowError) {
        this.initErrorFLKSimpleField();
      }
    }
  }

  /**
   * Отобразить ошибочное поле
   * * Выделить ошибку с помощью билдера
   * * Перейти на ошибку (фокус)
   * @param error - данные ошибки
   * @param withFocus - Осуществить переход?
   */
  showFLKError(error: ErrorFLKModel, withFocus: boolean = true): void {
    const result = {
      customValidator: {
        errorMsg: error.msg,
        errorMsgBig: error.reason,
        withBigMsg: true,
        withoutSubmitted: true
      } as CustomValidatorError
    };
    const field = this.f(error.path);

    if (field) {
      setTimeout(() => field.setErrors(result));
      if (withFocus) {
        this.setFocus(error.path);
      }
    } else {
      throw new Error(`Неизвестный путь ${error.path}`);
    }
  }

  /**
   * Отобразить таблицу с ошибочным полем
   * * Запись ошибки в сервис (компонент сам ее получит)
   * * Перейти на таблицу (фокус)
   * * Сброс временной переменной (нужна только для получения row, сама ошибка в таблицу передается через itemDescription)
   * @param data - данные ошибки
   * data.error - данные ошибки
   * tableError - общие данные ошибок для таблицы
   */
  showFLKTableError(data: ErrorTableFLKModel): void {
    const error = data.error;
    // Устанавливаем переменную для считывания компонентом
    this.dossierService.errorFLKTable = error;
    const tableError = data.tableError;
    this.setFocus(tableError.path);
    // Очистка временной переменной
    setTimeout(() => this.dossierService.errorFLKTable = null, 100);
  }

  /**
   * Функция проброса ошибок до конечного компонента
   * @param path - путь до конечного компонента
   */
  public getErrorFLKData(path: string): any {
    const filed = this.errorFLKData ? path.split('.').reduce((prev, curr) => prev && prev[curr], this.errorFLKData as any) : null;
    return filed ? filed : null;
  }

  /**
   * Функция проброса объекта фокусировки до конечного компонента
   * @param key - текущий ключ компонента
   */
  public getFocusObject(key: string): any {
    const filed = this.focusObject ? this.focusObject[key] : null;
    return filed ? filed : null;
  }

  /**
   * Проверка нужно ли перейти на элемент
   * * Если isFocus вернут true будет осуществлен переход
   * @param key - ключ проверяемого объекта
   */
  public isFocus(key: string): boolean {
    return this.focusObject ? this.focusObject[key] : false;
  }

  /**
   * Перейти на ошибочный элемент
   * Переход через директиву appFocusFLK
   * @param path - путь до элемента
   */
  public setFocus(path: string): void {
    const pathList = path.split('.');
    let findObjectToFocus = this.focusObject;
    let findKey = '';
    // Ищем объект фокуса по пути объекта
    pathList.forEach((key, index) => {
      // Ищем предпоследний элемент, чтобы потом получить через data[key] и обновлять по ссылке, а не по значению
      if (index === pathList.length - 1) {
        findKey = key;
        return;
      }
      findObjectToFocus = findObjectToFocus[key];
    });
    const isField = typeof findObjectToFocus[findKey] === "boolean";
    const isGroup = typeof findObjectToFocus[findKey] === "object";
    if (isField) {
      // Выставляем для перехода к полю
      findObjectToFocus[findKey] = true;
    } else if (isGroup) {
      // Выставляем для перехода к группе
      findObjectToFocus[findKey + 'Group'] = true;
    } else {
      console.log('Не найден объект для фокусировки ', findKey);
    }
    // Сбрасываем (Переход уже отработал)
    setTimeout(() => {
      if (isField) {
        findObjectToFocus[findKey] = false;
      } else if (isGroup) {
        findObjectToFocus[findKey + 'Group'] = false;
      }
    }, 100);
  }

  /**
   * Генерация объекта по модели со всеми значениям boolean = false
   * При установки true в определенное поле, будет произведен фокус на это поле
   * Доступна фокусировка на группы, обращение как - <имя группы> + Group
   */
  generateFocusObject(obj) {
    const result = {};
    Object.keys(obj).forEach(key => {
      // для фокуса на поле
      result[key] = false;
      if (typeof obj[key] === "object") {
        // Объект для фокуса на группу
        result[key + 'Group'] = false;
        // Фокусировка на поля внутри объекта
        result[key] = this.generateFocusObject(obj[key]);
      }
    });
    return result;
  }


  /**
   * При ручном открытии аккордиона фокусируемся на компоненте
   */
  openAccordion(closed): void {
    this.isOpenFocus = !closed;
  }

  protected constructor(protected dossierService: DossierService,
                        protected dictSrv: DictionaryService,
                        protected alertPanelSrv: AlertPanelService) {
  }


  getStaticData(type: string) {
    return this.dictSrv.getStaticData(type);
  }

  abstract f(field: string);

  abstract fGroup(field: string): FormGroup;

  /**
   * Инициализация ошибок ФЛК
   */
  initErrorFLK(errorFLKList: Array<ErrorFLKModel>): void {
    if (!errorFLKList) {
      return;
    }
    // Записываем объект для проставки ошибок в простые поля
    this.errorFLKList = errorFLKList;
    // Ошибки по таблицам
    this.errorFLKData = {};
    // Отбираем ошибки для таблиц и составных компонентов
    errorFLKList.filter(error => error.items && Object.keys(error.items).length > 0).forEach(error => {
      /**
       * Получаем объекты и записываем в виде
       * this.errorFLKData = {
       *   svedClient: {
       *     svedUL: {
       *       ...
       *     }
       *   }
       * }
       */
      this.errorFLKData = generObj(this.errorFLKData, error.path, error.items);
    });


    /**
     * Генерация объекта из строки 'a.b.c'
     * @param obj - начальный объект для генерации или {}
     * @param path - строка пути
     * @param value - значение
     */
    function generObj(obj, path, value = null) {
      path = typeof path === 'string' ? path.split('.') : path;
      let current = obj;
      while (path.length > 1) {
        const [head, ...tail] = path;
        path = tail;
        if (current[head] === undefined) {
          current[head] = {};
        }
        current = current[head];
      }
      current[path[0]] = value;
      return obj;
    }
  }

  getPatchStructureResponseError(response) {
    const [{items}] = response;
    const [rowNum] = Object.keys(items);
    return items[parseInt(rowNum, 10)];
  }

  /**
   * Проверка вкладки по ФЛК
   * вызывает в шаблоне через tableItem.
   */
  checkFLKBase(form: FormGroup, typePage: DossierAccessEnum, dossierId?: string): void {
    const values = form.value;
    const model = Utils.clone(values);
    Utils.deleteEmptyObj(model);
    // Удаляем поля $partId, $checkedFlk
    Utils.removeKeysFromObj(model, ['$partId', '$checkedFlk']);
    this.isLoadingCheckFLK = true;
    this.dossierService.checkFLK({partName: typePage, model, dossierId, partId: values.$partId} as DossierRequest).subscribe((response: ErrorFLKModel[]) => {
      try {
        if (response) {
          // Сохраняем состояние формы
          const oldDirty = form.dirty;
          // Сброс предыдущих ошибок по ФЛК
          // Если форма была грязная, восстанавливаем состояние после reset
          if (oldDirty) {
            form.markAsDirty();
          }
          this.clearErrorFLKListFields();

          if (response.length > 0) {
            this.alertPanelComponent.open(this.alertPanelSrv.getErrorMsg('Обнаружены ошибки форматно логического контроля.'));
            this.initErrorFLK(response);
            this.initErrorFLKSimpleField();
          } else {
            this.errorFLKList = [];
            this.alertPanelComponent.open(this.alertPanelSrv.getSuccessMsg('Ошибок форматно-логического контроля не обнаружено.'));
          }
          // Установка признака проверки вкладки по ФЛК
          // if ([DossierAccessEnum.SVEDCLIENT, // todo - не нужно? выставляем признак ошибок ФЛК для всех вкладок
          //   DossierAccessEnum.SVEDTARGET,
          //   DossierAccessEnum.DOPINFO,
          //   DossierAccessEnum.ATTRIBUTES].includes(typePage)) {
            this.dossierService.setErrorFLK(typePage, response.length > 0);
          // }
        }
      } finally {
        this.isLoadingCheckFLK = false;
      }
    }, error => {
      console.log(error)
      this.isLoadingCheckFLK = false;
    });
  }

  /**
   * Получить признак загрузки ошибок по ФЛК
   * вызывает: в шаблоне компонентов табли через tableItem.
   *           в плашке кнопок досье
   */
  loadingCheckFLK(): boolean {
    return this.isLoadingCheckFLK;
  }

  /**
   * Сброс ошибок по строке таблицы
   * @param row - ключ ошибки в списке ошибок
   */
  setValidFlkError(row: number): void {
    if(this.errorFLKData)
      this.errorFLKData[row] = [];
  }

  /**
   * Установка ссылки на внешний объект для сообщений по сохранению
   * @param alertPanelComponent - объект для сохранения
   */
  setAlertPanel(alertPanelComponent: AlertPanelComponent): void {
    this.alertPanelComponent = alertPanelComponent;
  }

  getForm(): Promise<FormGroup> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve((this as any).panelForm);
      });
    });
  }

  getCustomPatterns(): object {
    return Utils.customPatterns;
  }
}
