import {CheckTransaction} from "./checkTransaction.model";

/**
 * Класс субъекта транзакции
 * плательщик, получатель
 */

export class TransactionSubject {
  public account: string; // Номер счета
  public address: string; // Адрес
  public bank: string;    // Наименование банка
  public bankAddress: string; // Адрес банка
  public bankCity: string;    // Город банка
  public bankCountryKod: string;  // Код страны банка
  public bankCountryName: string; // Наименование страны банка
  public bic: string;             // БИК-банка
  public city: string;            // Город
  public clientRegistry: string;  // уникальный идентификатор досье rootCleintInfoId
  public clientType: string;      // Тип клиента
  public corrAccount: string;     // Номер корреспондентского счета
  public countryKod: string;      // Код страны
  public countryName: string;     // Наименование страны
  public id: string;              // уникальный идентификатор плательщика/получателя
  public inn: string;             // ИНН
  public kpp: string;             // КПП
  public name: string;            // Наименование / ФИО
  public swift: string;           // SWIFT-код банка
  public vid: string;             // Вид операции по счету
  public memberStatus: string;    // Статус участника

  constructor() {
    this.account = "";
    this.address = "";
    this.bankAddress = "";
    this.bankCity = "";
    this.bankCountryKod = "";
    this.bankCountryName = "";
    this.bank = "";
    this.bic = "";
    this.city = "";
    this.clientRegistry = "";
    this.clientType = "";
    this.corrAccount = "";
    this.countryKod = "";
    this.countryName = "";
    this.id = "";
    this.inn = "";
    this.name = "";
    this.swift = "";
    this.vid = "";
    this.memberStatus = "";
  }
}
