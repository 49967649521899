/**
 * API по базовым справочникам АМЛ.
 * API по базовым справочникам АМЛ.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Типы справочников.
 */
export type CodeEnum = 'cb-bnkseek' | 'banc' | 'fil' | 'obl' | 'ogrn' | 'okato1' | 'okcm' | 'okcm-notlegacy' | 'okwed2' | 'okwed' | 'swift' | 'cb-tu' | 'valut';

export const CodeEnum = {
    CbBnkseek: 'cb-bnkseek' as CodeEnum,
    Banc: 'banc' as CodeEnum,
    Fil: 'fil' as CodeEnum,
    Obl: 'obl' as CodeEnum,
    Ogrn: 'ogrn' as CodeEnum,
    Okato1: 'okato1' as CodeEnum,
    Okcm: 'okcm' as CodeEnum,
    OkcmNotlegacy: 'okcm-notlegacy' as CodeEnum,
    Okwed2: 'okwed2' as CodeEnum,
    Okwed: 'okwed' as CodeEnum,
    Swift: 'swift' as CodeEnum,
    CbTu: 'cb-tu' as CodeEnum,
    Valut: 'valut' as CodeEnum
};

