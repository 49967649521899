import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { BaseFilterController } from "../BaseFilterController";
import { FormBuilder } from "@angular/forms";
import { RequestsgaUnstrustFilter } from "@amlCore/models";
import {
  DictionaryService,
  ResourceService,
  StringDateAdapterService,
  TableService,
} from "@amlCore/services";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-requestsga-unstrust-list-filter",
  templateUrl: "./requestsgaUnstrustFilter.component.html",
})
export class RequestsgaUnstrustFilterComponent
  extends BaseFilterController
  implements OnInit
{
  public filterObjectModel = new RequestsgaUnstrustFilter();
  placeholder = "Поиск по отправителю, Регистрационному номеру";
  creatorList = [];

  constructor(
    private readonly fb: FormBuilder,
    private readonly resourceSrv: ResourceService,
    private dictSrv: DictionaryService,
    protected tableSrv: TableService,
    public dateSrv: StringDateAdapterService,
    protected readonly activatedRoute: ActivatedRoute
  ) {
    super(tableSrv, activatedRoute);
  }

  ngOnInit() {
    this.init();
    this.loadCreatorList();
  }

  //Получение списка пользователей
  private loadCreatorList() {
    const customParams = {
      sort: "fullName",
      dir: "asc",
      page: "0",
      size: "200",
    };
    this.resourceSrv.getUserList(customParams).subscribe(({ content }) => {
      this.creatorList = content || [];
    });
  }

  /**
   * Метод создания формы
   * @return FormGroup
   */
  public createFormModel() {
    const formConfig = new RequestsgaUnstrustFilter();
    return this.fb.group(formConfig);
  }

  getStartFilterModel(): RequestsgaUnstrustFilter {
    return {
      status: null,
      requestSender: null,
    } as RequestsgaUnstrustFilter;
  }

  /**
   * Описания полей
   */
  public getFilterDescription(): any {
    return {
      status: "Статус",
      dateFrom: "Дата документа с",
      dateTo: "Дата по",
      requestSender: "Отправитель",
      requestRegNum: "Регистрационный номер запроса",
      replyRegNum: "Регистрационный номер ответа",
      creatorId: "Владелец",
    };
  }

  // todo вынос на сервер или энум если оставим тут
  docStatuses = [
    { id: 0, name: "DRAFT", title: "Черновик" },
    { id: 1, name: "ONEXECUTION", title: "На исполнении" },
    { id: 2, name: "EXECUTED", title: "Исполнен" },
  ];

  //TODO спросить про владельца
  get users() {
    return this.dictSrv?.remoteDict?.usersFM?.list || [];
  }
}
