/**
 * API по базовым справочникам АМЛ.
 * API по базовым справочникам АМЛ.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Данные записи справочника филиалов банков.
 */
export interface QrefFil { 
    /**
     * Регистроционный номер КО. Ключевое поле таблицы.
     */
    rnbn?: number;
    /**
     * Регистроционный номер филиала. Ключевое поле таблицы.
     */
    rnfl?: number;
    /**
     * Наименование филиала.
     */
    namef?: string;
    /**
     * Наименование головной КО.
     */
    nam?: string;
    /**
     * Адрес.
     */
    adres?: string;
    /**
     * ФИО руководителя филиала.
     */
    fioPrFl?: string;
    /**
     * ФИО Главного бухгалтера филиала.
     */
    fioGlB?: string;
    /**
     * Номер телефона.
     */
    telefon1?: string;
    /**
     * Номер факса.
     */
    fax?: string;
    /**
     * Адрес головной КО.
     */
    adresp?: string;
    /**
     * Код ОКАТО ТУ филиала.
     */
    numpsrf?: string;
    /**
     * Код ОКАТО головной КО.
     */
    numpsrb?: string;
    /**
     * Код страны.
     */
    country?: number;
    /**
     * Дата регистрации.
     */
    dataReg?: string;
}

