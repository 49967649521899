import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

/**
 * Автофокус на поле. Стандартный [autofocus] не всегда работает.
 * Если укащать scrollIntoElement true, то вместе с фокусом будет прокрутка до элемента
 */
@Directive({
  selector: "[appFocus]"
})
export class AutoFocusDirective implements AfterViewInit {
  scrollIntoElement = false;

  constructor(private el: ElementRef) {
  }

  @Input() set appFocus(scroll: boolean) {
    this.scrollIntoElement = scroll;
  }

  ngAfterViewInit() {
    this.el.nativeElement.focus();
    if (this.scrollIntoElement) {
      setTimeout(() => {
        this.el.nativeElement.scrollIntoView({block: "start"});
      }, 100);

    }

  }

}
