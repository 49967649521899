import {Directive, Input, SimpleChanges, Renderer2, ElementRef, OnChanges} from '@angular/core';

@Directive({
  selector: '[appHighlight]'
})
export class HighlightDirective implements OnChanges {
  @Input() search: string;
  @Input() text: string;
  @Input() classToApply: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const searchedWords = (this.search? this.search.replace(/[,]/g, '') : '').trim().split(' ');

    if (!searchedWords || !searchedWords.length || !this.classToApply) {
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.text);
      return;
    }

    this.renderer.setProperty(
      this.el.nativeElement,
      'innerHTML',
      this.getFormattedText(searchedWords)
    );
  }

  getFormattedText(searchedWordsArray) {
    const re = new RegExp(`(${searchedWordsArray.join('|')})`, 'ig');

    return this.text.replace(re, `<span class="${this.classToApply}">$1</span>`);
  }
}
