import {DictType} from "./dictType.model";
import {DossierT4000} from "./base.model";

export class RiskAssessType {
  levelRisk: DictType = undefined;
  serviceRisk: DictType = undefined;
  descriptRisk?: DossierT4000 = '';
  userId: number;
  creatDate: Date;
  row:number;
  fio: string;
}
