export const DossierAccessEnumValue = ['svedClient', 'svedAU', 'svedUDS', 'svedIOU', 'svedPredst', 'svedVPR', 'svedBV', 'svedTarget',
  'bankAccounts', 'dopInfo', 'otherAccounts', 'attributes', 'riskAssess', 'partner'];

  // не относится к досье:
  // | 'risk_view' | 'notices' | 'dossierAccessAll' | 'dossierAccess'  | 'transactionslist' | 'dokReestr' | 'transactionchecklist'

export enum DossierAccessEnum {
  SVEDCLIENT = 'svedClient',
  SVEDAU = 'svedAU',
  SVEDUDS = 'svedUDS',
  SVEDIOU = 'svedIOU',
  SVEDPREDST = 'svedPredst',
  SVEDVPR = 'svedVPR',
  SVEDBV = 'svedBV',
  SVEDTARGET = 'svedTarget',
  BANKACCOUNTS = 'bankAccounts',
  DOPINFO = 'dopInfo',
  OTHERACCOUNTS = 'otherAccounts',
  ATTRIBUTES = 'attributes',
  RISKASSESS = 'riskAssess',
  DOKREESTR = 'dokReestr',
  PARTNER = 'partner',
  DOSSIERACCESS = 'dossierAccess',
  RISKVIEW = 'risk_view',
  TRANSACTIONSLIST = 'transactionslist',
  TRANSACTIONCHECKLIST = 'transactionchecklist',
  DOSSIER_ACCESS_ALL = 'dossierAccessAll',
  // TODO лучше вынести общие права в др. enum
  NOTICES = 'notices', // Оповещания
}

export function getDossierAccessList(): Array<string> {
  return Object.values(DossierAccessEnum);
}

