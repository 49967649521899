import {AddressType} from "./addressType.model";
import {DossierBool} from "./base.model";

export class AddressInfoType {
  addressReg: AddressType = new AddressType();
  addressReal?: AddressType = new AddressType();
  addressPost?: AddressType = new AddressType();
  realAsReg: DossierBool = true;
  postAsReg: DossierBool = true;

  constructor() {
  }
}
