import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {VisitedLinkModel} from "@amlCore/models";
import {VisitedService} from "@amlCore/services";

@Injectable()
export class VisitedInterceptor implements HttpInterceptor {
  constructor(private visitedService: VisitedService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap(
      event => {
        if (event) {
          const body = event["body"];
          const keyId = req.headers.get('visited');
          if (body && body.content && keyId) {
            (body.content as Array<VisitedLinkModel>).forEach(item => {
              if (this.visitedService.getVisitedId(item[keyId])) {
                item.$visited = true;
              }
            });
          }
        }
      }
    ));
  }
}
