import {NgModule} from '@angular/core';
import {DictionariesRoutingModule} from "./dictionaries-routing.module";
import * as components from "./components";
import {NgJsonEditorModule} from "ang-jsoneditor";
import {CommonModule} from "@angular/common";
import {AccessRouteGuard, CoreModule, StoreService} from "../core";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {NgSelectModule} from "@ng-select/ng-select";
import {UiSwitchModule} from "ngx-ui-switch";
import {ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import {DictionariesService} from "./service";

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    NgSelectModule,
    NgbModule,
    DictionariesRoutingModule,
    NgJsonEditorModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    UiSwitchModule.forRoot({
      size: 'medium',
      color: '#ae0000'
    }),
  ],
  declarations: [
    components.DictionaryListComponent,
    components.QrefBancComponent,
    components.QrefCbBnkseekComponent,
    components.QrefFilComponent,
    components.QrefOblComponent,
    components.QrefOkato1Component,
    components.QrefOgrnComponent,
    components.QrefOkcmComponent,
    components.QrefOkcmNotlegacyComponent,
    components.QrefOkwedComponent,
    components.QrefOkwed2Component,
    components.QrefSwiftComponent,
    components.QrefTuCbComponent,
    components.QrefValutComponent,
    components.QrefValutViewComponent,
    components.QrefBancNameViewComponent,
    components.QrefOkcmViewComponent,
    components.QrefOkato1ViewComponent,
    components.QrefOkatoByOblViewComponent,
    components.QrefTucode4936ViewComponent,
    components.QrefPidTerUchViewComponent,
    components.QrefPipdl4936ViewComponent,
    components.QrefFltype4936ViewComponent,
    components.QrefPrizndoc4936ViewComponent,
    components.QrefDokt4936ViewComponent,
    components.QrefAction4936ViewComponent,
    components.QrefAction4077ViewComponent,
    components.QrefPd5861ViewComponent,
    components.QrefCoduch4936ViewComponent,
    components.QrefCodUch4077ViewComponent,
    components.QrefBArxViewComponent,
    components.QrefBelongingAccountsViewComponent,
    components.QrefBnkdelViewComponent,
    components.QrefCardNotlegacyViewComponent,

    components.QrefCheckppassportViewComponent,
    components.QrefClientinfoViewComponent,
    components.QrefClienttypeViewComponent,
    components.QrefCodmerViewComponent,
    components.QrefConsolidatedListViewComponent,

    components.QrefDmet4936ViewComponent,
    components.QrefDocTypeViewComponent,
    components.QrefDokt245FmViewComponent,
    components.QrefExtorgsViewComponent,
    components.QrefFArxViewComponent,

    components.QrefFamilyRelViewComponent,
    components.QrefFltypeViewComponent,
    components.QrefInboultypeViewComponent,
    components.QrefIndlViewComponent,
    components.QrefInfOtkazViewComponent,

    components.QrefInfomerViewComponent,
    components.QrefIssuertypeViewComponent,
    components.QrefKoPrpodViewComponent,
    components.QrefKodosnov4936ViewComponent,
    components.QrefKodtypesecViewComponent,

    components.QrefNationalVidViewComponent,
    components.QrefNationaltypeViewComponent,
    components.QrefNlsDepositViewComponent,
    components.QrefNoViewComponent,
    components.QrefOkopfViewComponent,

    components.QrefOrgAttribViewComponent,
    components.QrefOrgsViewComponent,
    components.QrefOtkazUchViewComponent,
    components.QrefPartViewComponent,
    components.QrefPipdlViewComponent,

    components.QrefPnbsViewComponent,
    components.QrefPodsViewComponent,
    components.QrefPods4936ViewComponent,
    components.QrefPrichOtkazViewComponent,
    components.QrefPristViewComponent,

    components.QrefPrizesp4936ViewComponent,
    components.QrefPrizninfViewComponent,
    components.QrefPrpod5599ViewComponent,
    components.QrefRdlViewComponent,
    components.QrefRefusViewComponent,

    components.QrefRiskViewComponent,
    components.QrefSearchdocforfsfmViewComponent,
    components.QrefTerNotlegacyViewComponent,
    components.QrefTerror4936ViewComponent,
    components.QrefTucode4077ViewComponent,

    components.QrefTypeblockds4936ViewComponent,
    components.QrefTypeinfo3484010206ViewComponent,
    components.QrefTypeoperator4936ViewComponent,
    components.QrefUchopViewComponent,
    components.QrefUprtypeViewComponent,

    components.QrefUprtypeEdViewComponent,
    components.QrefUprtypeKollViewComponent,
    components.QrefVidInfoViewComponent,
    components.QrefViddocosnovViewComponent,
    components.QrefViddocosnov4936ViewComponent,
    components.QrefVidmerViewComponent,
    components.QrefVidpds4936ViewComponent,
    components.QrefVo4936ViewComponent,
    components.QrefZr5861ViewComponent,
    components.QrefSwiftViewComponent,
    components.QrefTuCbViewComponent,
    components.QrefOkwed2ViewComponent,
    components.QrefOkwedViewComponent,
    components.QrefOkcmNotlegacyViewComponent,
    components.QrefOgrnViewComponent,
    components.QrefOblViewComponent,
    components.QrefFilViewComponent,
    components.QrefCbBnkseekViewComponent,
    components.QrefBancNameViewComponent,

    components.QrefStaticViewComponent,

    // STRATEG
    components.QrefClienttype213ViewComponent,
    components.QrefAction443ViewComponent,
    components.QrefConttypeViewComponent,
    components.QrefVidInfoOpViewComponent,
    components.QrefKoOtkViewComponent,
    components.QrefPriostViewComponent,
    components.QrefPruvedViewComponent,
    components.QrefPolTypeViewComponent,

    components.QrefVidOperViewComponent,

    // ElMsgFm
    components.QrefKodTypeDocViewComponent,
  ],
  exports: [
    components.QrefValutViewComponent,
    components.QrefOkcmViewComponent,
    components.QrefOkato1ViewComponent,
    components.QrefOkatoByOblViewComponent,
    components.QrefTucode4936ViewComponent,
    components.QrefPidTerUchViewComponent,
    components.QrefPipdl4936ViewComponent,
    components.QrefFltype4936ViewComponent,
    components.QrefPrizndoc4936ViewComponent,
    components.QrefDokt4936ViewComponent,
    components.QrefAction4936ViewComponent,
    components.QrefAction4077ViewComponent,
    components.QrefPd5861ViewComponent,
    components.QrefCoduch4936ViewComponent,
    components.QrefCodUch4077ViewComponent,
    components.QrefBArxViewComponent,
    components.QrefBelongingAccountsViewComponent,
    components.QrefBnkdelViewComponent,
    components.QrefCardNotlegacyViewComponent,
    components.QrefCheckppassportViewComponent,
    components.QrefClientinfoViewComponent,
    components.QrefClienttypeViewComponent,
    components.QrefCodmerViewComponent,
    components.QrefConsolidatedListViewComponent,
    components.QrefDmet4936ViewComponent,
    components.QrefDocTypeViewComponent,
    components.QrefDokt245FmViewComponent,
    components.QrefBancNameViewComponent,
    components.QrefExtorgsViewComponent,
    components.QrefFArxViewComponent,
    components.QrefFamilyRelViewComponent,
    components.QrefFltypeViewComponent,
    components.QrefInboultypeViewComponent,
    components.QrefIndlViewComponent,
    components.QrefInfOtkazViewComponent,
    components.QrefInfomerViewComponent,
    components.QrefIssuertypeViewComponent,
    components.QrefKoPrpodViewComponent,
    components.QrefKodosnov4936ViewComponent,
    components.QrefKodtypesecViewComponent,
    components.QrefNationalVidViewComponent,
    components.QrefNationaltypeViewComponent,
    components.QrefNlsDepositViewComponent,
    components.QrefNoViewComponent,
    components.QrefOkopfViewComponent,
    components.QrefOrgAttribViewComponent,
    components.QrefOrgsViewComponent,
    components.QrefOtkazUchViewComponent,
    components.QrefPartViewComponent,
    components.QrefPipdlViewComponent,
    components.QrefPnbsViewComponent,
    components.QrefPodsViewComponent,
    components.QrefPods4936ViewComponent,
    components.QrefPrichOtkazViewComponent,
    components.QrefPristViewComponent,
    components.QrefPrizesp4936ViewComponent,
    components.QrefPrizninfViewComponent,
    components.QrefPrpod5599ViewComponent,
    components.QrefRdlViewComponent,
    components.QrefRefusViewComponent,
    components.QrefRiskViewComponent,
    components.QrefSearchdocforfsfmViewComponent,
    components.QrefTerNotlegacyViewComponent,
    components.QrefTerror4936ViewComponent,
    components.QrefTucode4077ViewComponent,
    components.QrefTypeblockds4936ViewComponent,
    components.QrefTypeinfo3484010206ViewComponent,
    components.QrefTypeoperator4936ViewComponent,
    components.QrefUchopViewComponent,
    components.QrefUprtypeViewComponent,
    components.QrefUprtypeEdViewComponent,
    components.QrefUprtypeKollViewComponent,
    components.QrefVidInfoViewComponent,
    components.QrefViddocosnovViewComponent,
    components.QrefViddocosnov4936ViewComponent,
    components.QrefVidmerViewComponent,
    components.QrefVidpds4936ViewComponent,
    components.QrefVo4936ViewComponent,
    components.QrefZr5861ViewComponent,
    components.QrefSwiftViewComponent,
    components.QrefTuCbViewComponent,
    components.QrefOkwed2ViewComponent,
    components.QrefOkwedViewComponent,
    components.QrefOkcmNotlegacyViewComponent,
    components.QrefOgrnViewComponent,
    components.QrefOblViewComponent,
    components.QrefFilViewComponent,
    components.QrefCbBnkseekViewComponent,
    components.QrefBancNameViewComponent,

    components.QrefStaticViewComponent,

    // STRATEG
    components.QrefClienttype213ViewComponent,
    components.QrefAction443ViewComponent,
    components.QrefConttypeViewComponent,
    components.QrefVidInfoOpViewComponent,
    components.QrefKoOtkViewComponent,
    components.QrefPriostViewComponent,
    components.QrefPruvedViewComponent,
    components.QrefPolTypeViewComponent,
    components.QrefVidOperViewComponent,

    // ElMsgFm
    components.QrefKodTypeDocViewComponent,
  ],
  providers: [
    AccessRouteGuard,
    DictionariesService,
    StoreService
  ]
})
export class DictionariesModule {
}
