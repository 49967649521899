export class AccessJson {
  readOnly: boolean;
  name: string;
  id: string;
  description: string;
  child: Array<AccessJson>;

  constructor() {
  }
}

