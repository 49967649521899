import {Component, OnDestroy, OnInit} from "@angular/core";
import {BaseFilterController} from "../BaseFilterController";
import {FormBuilder, FormGroup} from "@angular/forms";
import {InternalFilter} from "@amlCore/models";
import {DictionaryService, TableService} from "@amlCore/services";
import {ClientTypeEnum} from "@amlCore/enums";
import {Unsubscribable} from "rxjs";
import { ActivatedRoute } from "@angular/router";
import {IDictItemType} from "@amlCore/interfaces";

@Component({
  selector: "app-internal-list-filter",
  templateUrl: "./internalListFilter.component.html"
})

export class InternalListFilterComponent extends BaseFilterController implements OnInit, OnDestroy {

  public selectObj = {
    clientTypeList: [] as IDictItemType[]
  };
  ClientTypeEnum = ClientTypeEnum;
  public filterObjectModel = new InternalFilter();
  placeholder = 'Поиск по ФИО, наименованию, ИНН, СНИЛС';
  clientTypeField = { // Поля для разблокировки по группе
    1: ['name', 'ogrn'], // Юридическое лицо
    2: ['sname', 'gname', 'pname', 'snils', 'series', 'number'], // Физическое лицо
    3: ['sname', 'gname', 'pname', 'snils', 'series', 'number', 'ogrn'], // Индивидуальный предприниматель
    4: ['sname', 'gname', 'pname', 'snils', 'series', 'number'], // Физическое лицо, занимающееся частной практикой
    5: ['name', 'inboul'] // Иностранная структура без образования юридического лица
  };
  // Все поля для блокировки
  allFiled = ['sname', 'gname', 'pname', 'snils', 'series', 'number', 'ogrn', 'name', 'inboul'];
  subjectTypeSub: Unsubscribable;

  constructor(private fb: FormBuilder,
              protected tableSrv: TableService,
              private dictSrv: DictionaryService,
              protected activatedRoute: ActivatedRoute) {
    super(tableSrv, activatedRoute);
  }

  ngOnInit(): void {
    this.init();
    this.dictSrv.loadTypeClient().then(result => {
      this.selectObj.clientTypeList = result.filter(item => item.code != 0);
    });
    this.subjectTypeSub = this.f('subjectType').valueChanges.subscribe(subjectType => {
      const resetValue = new InternalFilter();
      resetValue.subjectType = subjectType;
      this.formModel.reset(resetValue, {emitEvent: false});
      this.allFiled.forEach(field => this.f(field).disable());
      if (subjectType) {
        this.clientTypeField[subjectType].forEach(field => this.f(field).enable());
      }
    });
  }

  /**
   * Метод создания формы
   */
  public createFormModel(): FormGroup {
    return this.fb.group(new InternalFilter());
  }

  getStartFilterModel(): InternalFilter {
    return {
      subjectType: '1'
    } as InternalFilter;
  }

  /**
   * Описания полей
   */
  public getFilterDescription(): any {
    return {
      subjectType: 'Тип субъекта',
      name: 'Название',
      inn: 'ИНН',
      ogrn: 'ОГРН',
      sname: 'Фамилия',
      gname: 'Имя',
      pname: 'Отчество',
      birthDate: 'Дата рождения/регистрации',
      series: 'Серия',
      number: 'Номер',
      inboul: 'Регистрационный номер'
    };
  }

  /**
   * Переопределение ngOnDestroy
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subjectTypeSub.unsubscribe();
  }

}
