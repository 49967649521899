import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { BaseFilterController } from '../BaseFilterController';
import { FormBuilder } from '@angular/forms';
import { DossierFilter } from '@amlCore/models';
import { ResourceService, DictionaryService, TableService} from '@amlCore/services';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-dossier-filter',
  templateUrl: './dossierFilter.component.html',
})

export class DossierFilterComponent extends BaseFilterController implements OnInit {
  public dictionaries = {
    identificationTypeList: [],
    responsibleEmployees: []
  };
  placeholder = 'Поиск по ФИО, наименованию, ИНН, ОГРН клиента';
  public isVisibleIdentificationType = false;
  public filterObjectModel = new DossierFilter();

  constructor(private readonly fb: FormBuilder,
              private readonly _changeDetectorRef: ChangeDetectorRef,
              private readonly dictSrv: DictionaryService,
              protected readonly tableSrv: TableService,
              private readonly resourceSrv: ResourceService,
              protected readonly activatedRoute: ActivatedRoute) {
    super(tableSrv, activatedRoute);
  }

  /**
   * Fix error type ExpressionChangedAfterItHasBeenCheckedError
   */
  ngAfterViewChecked(): void {
    this._changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    this.init();
    this.dictSrv.getRemoteData('typeidentification').then(data => {
      this.dictionaries.identificationTypeList = data;
    });
    this.loadResponsibleEmployees();
  }

  /**
   * Метод создания формы
   * @return FormGroup
   */
  public createFormModel() {
    return this.fb.group(new DossierFilter());
  }

 /**
  * метод загрузки отвественных сотрудников
  */
  private loadResponsibleEmployees() {
    const customParams = {sort: "fullName", dir: "asc", page: "0", size: "200"};
    this.resourceSrv.getUserList(customParams).subscribe(({content}) => {
      this.dictionaries.responsibleEmployees = (content || []);
    });
  }

  getStartFilterModel(): any {
    return {
      identificationType: null, // Вид идентификации клиента
      subject: {
        clientType: null // Тип организации / лица
      }
    } as DossierFilter;
  }

  public setStateIdentificationType(state: boolean): void {
    this.isVisibleIdentificationType = state;
  }

  /**
   * Описания полей
   */
  public getFilterDescription(): any {
    return {
      userId: 'Ответственный сотрудник',
      identificationType: 'Вид идентификации',
      result: '', // все/требующие решения
      subject: {
        typePart: 'Тип субъекта',
        clientType: 'Тип организации / лица',
        legalEntity: {
          orgName: 'Наименование организации',
          ogrn: 'ОГРН',
          inn: 'ИНН',
          kpp: 'КПП',
        },
        individualPerson: {
          lastName: 'Фамилия',
          firstName: 'Имя',
          middleName: 'Отчество',
          birthDate: 'Дата рождения',
          series: 'Серия документа',
          numDoc: 'Номер документа',
          inn: 'ИНН',
          ogrn: 'ОГРН',
        },
        foreignStructure: {
          orgName: 'Наименование организации',
          regNum: 'Регистрационный номер',
        }
      }
    };
  }

}
