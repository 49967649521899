import {Component, OnDestroy, OnInit} from "@angular/core";
import { BaseFilterController } from "../BaseFilterController";
import { FormBuilder } from "@angular/forms";
import { ClientsTransactionsFilter } from "@amlCore/models";
import {DictionaryService, TableService} from '../../services';
import {IDictionaryItem, IDictItemType} from '../../interfaces';
import { ResourceService } from "@amlCore/services";
import { Utils } from "@amlCore/utils";
import { ICustomSelectMapper } from '@amlCore/components';
import {Unsubscribable} from "rxjs";
import { ActivatedRoute } from "@angular/router";
import {CountryItemModel, DictType} from "../../../client/models";

@Component({
  selector: "app-clients-transactions-filter",
  templateUrl: "./clientsTransactionsFilter.component.html"
})

export class ClientsTransactionsFilterComponent extends BaseFilterController implements OnInit, OnDestroy {
  public filterObjectModel = new ClientsTransactionsFilter();
  public currencyMapper: ICustomSelectMapper;
  public bankopervidMapper: ICustomSelectMapper;
  public currcashMapper: ICustomSelectMapper;
  public issuerCountryMapper: ICustomSelectMapper; // Маппер для справочника кодов страны
  unsubList: Unsubscribable[] = [];
  vidShow = false; // Отображение поля vid
  vidShowFields = ['name', 'inn']; // Поля по котором отображать поле vid + еще по clientType (своя обработка)
  /*справочники*/
  public selectObj = {
    transactionsStatuses: this.dictSrv.getStaticData("transactionsStatuses") as IDictionaryItem<{
      id: string;
      name: string;
    }>[],
    clientTypeList: [] as IDictItemType[],
    users: [] as IDictionaryItem<{ id: string; personName: string; }>[],
    currencyList: [] as IDictionaryItem<{ id: number; name: string; }>[],
    viddoc: [] as IDictionaryItem<{ code: string; name: string; }>[],
    bankopervid: [] as IDictionaryItem<{ code: string; name: string; }>[],
    currcash: [] as IDictionaryItem<{ code: string; name: string; }>[],
    /*Роль*/
    partnerRole: this.dictSrv.getStaticData("partnerRole") as IDictionaryItem<{ code: string; name: string; }>[],
    participantState: [] as IDictionaryItem<{ code: string; name: string; }>[],
    oksm: [] as IDictItemType[]
  };
  placeholder = 'Поиск по ФИО, наименованию, ИНН, номеру счета, уникальному № операции, назначению платежа';

  constructor(private fb: FormBuilder,
              private dictSrv: DictionaryService,
              protected tableSrv: TableService,
              private resourceSrv: ResourceService,
              protected activatedRoute: ActivatedRoute) {
    super(tableSrv, activatedRoute);
  }

  ngOnInit() {
    this.init();
    this.loadUsers();
    this.loadCurrency();
    this.loadDocTypes();
    this.loadBankOperationsType();
    this.loadCurrCashType();
    this.dictSrv.loadTypeClient().then(result => {
      this.selectObj.clientTypeList = result;
    });
    this.currencyMapper = {
      toModel: this.currencyMapperToModel.bind(this)
    } as ICustomSelectMapper;
    this.bankopervidMapper = {
      toModel: this.bankopervidMapperToModel.bind(this)
    } as ICustomSelectMapper;
    this.currcashMapper = {
      toModel: this.currcashMapperToModel.bind(this)
    } as ICustomSelectMapper;
    this.issuerCountryMapper = {
      toModel: this.issuerCountryMapperToModel.bind(this)
    } as ICustomSelectMapper;

    this.initVid();
    const clientTypeSub = this.formModel.get('clientType').valueChanges.subscribe(clientType => {
      // Проверка отображения поля vid
      this.initVid();
    });
    this.unsubList.push(clientTypeSub);
    /**
     * Запуск проверки для поля vid по vidShowFields
     */
    this.vidShowFields.forEach(key => {
      const sub = this.formModel.get(key).valueChanges.subscribe(data => {
        this.initVid();
      });
      this.unsubList.push(sub);
    });

    this.dictSrv.getRemoteData('participant_state').then(stateList => {
      this.selectObj.participantState = stateList;
    });

    this.dictSrv.getRemoteData('oksm').then(stateList => {
      this.selectObj.oksm = stateList;
    });
  }
  /**
   * Инициализация поля Vid
   * видно и доступно для заполнения только при наличии полей [vidShowFields] или clientType
   */
  private initVid(): void {
    const vidFiled = this.formModel.get('memberStatus');
    let result = false;
    ['clientType', ...this.vidShowFields].forEach(filedKey => {
      const value = this.formModel.get(filedKey).value;
      if (!result) {
        result = value && value !== '';
      }
    });
    this.vidShow = result;
    if (result) {
      vidFiled.enable();
    } else {
      vidFiled.disable();
    }
  }

  public createFormModel() {
    return this.fb.group(new ClientsTransactionsFilter());
  }

  /**
   * Маппер для код валюты
   */
  private currencyMapperToModel(origin: any, isDictData: boolean): any {
    if (isDictData) {
      return {
        code: origin.iso_dig,
        name: origin.name
      };
    } else {
      return origin;
    }
  }

  /**
   * Маппер для вид операции (код)
   */
  private bankopervidMapperToModel(origin: any, isDictData: boolean): any {
    if (isDictData) {
      return {
        code: origin.code,
        name: origin.name
      };
    } else {
      return origin;
    }
  }

   /**
   * Маппер для Вид операции с наличной валютой
   */
    private currcashMapperToModel(origin: any, isDictData: boolean): any {
      if (isDictData) {
        return {
          code: origin.code,
          name: origin.name
        };
      } else {
        return origin;
      }
    }

  /**
   * Маппер для Кода страны банка-эмитента
   */
  private issuerCountryMapperToModel(origin: CountryItemModel,  isDictData: boolean ): CountryItemModel | DictType {
    if (isDictData) {
      return {
        code: origin.kod,
        name: origin.name
      };
    } else {
      return origin;
    }
  }

  /**
   * метод загрузки пользователя, под которым выполнена загрузка
   */
  private loadUsers() {
    const queryParams = {sort: "fullName", dir: "asc", page: "0", size: "200"};
    this.resourceSrv.getUserList(queryParams).subscribe(({content}) => {
      this.selectObj.users = (content || []);
    });
  }

  /**
   * Метод загрузки справочника валют по okv
   */
  private loadCurrency() {
    const russianRubCode = "810";
    const stateRubCurrency = "ПРИЗНАК РОССИЙСКОГО РУБЛЯ";
    this.dictSrv.getRemoteData("okv").then((content) => {
      this.selectObj.currencyList = (content || []).filter((item) => {
        return ((item.iso_dig === russianRubCode && item.name !== stateRubCurrency) ||
                !Utils.dateNowIsAfter(item.cb_date));
      });
    });
  }

  /**
   * Метод загрузки типов документов
   */
  private loadDocTypes() {
    this.dictSrv.getRemoteDictData('viddoc', {
      size: 20000,
      page: 0,
      sort: 'id',
      dir: 'ASC'
    }, 1 ).subscribe((result) => {
      this.selectObj.viddoc = result;
    });
  }

  /**
   * Метод загрузки видов банковских операций
   */
  private loadBankOperationsType() {
    this.dictSrv.getRemoteData("bankopervid").then((result) => {
      this.selectObj.bankopervid = result;
    });
  }

   /**
   * Метод загрузки Видов операции с наличной валютой
   */
    private loadCurrCashType() {
      this.dictSrv.getRemoteData("currcash").then((result) => {
        this.selectObj.currcash = result;
      });
    }

  getStartFilterModel(): ClientsTransactionsFilter {
    return {
      basisPay: null, // Основание платежа
      taxPeriod: null, // Налоговый период
    } as ClientsTransactionsFilter;
  }

  customDescFields = [
    {
    fieldName: 'currency',
    customDescription: () => 'Код валюты'
    },
    {
      fieldName: 'type',
      customDescription: () => 'Вид операции (Код)'
    },
    {
      fieldName: 'currCashType',
      customDescription: () => 'Вид операции с наличной валютой'
    }
    ]

  /**
   * Описания полей
   */
  public getFilterDescription(): any {
    return {
      state: 'Статус операции',
      createdFrom: 'Дата и время создания С',
      createdTo: 'Дата и время создания По',
      user: 'Пользователь',
      vid: 'Роль',
      operId: 'Уникальный номер операции',
      dateFrom: 'Дата совершения операции С',
      dateTo: 'Дата совершения операции По',
      amountCurrFrom: 'Сумма операции в валюте С',
      amountCurrTo: 'Сумма операции в валюте По',
      amountRubFrom: 'Сумма операции в рублях С',
      amountRubTo: 'Сумма операции в рублях По',
      purpose: 'Назначение платежа',
      clientType: 'Тип клиента',
      name: 'Наименование/ФИО',
      inn: 'ИНН (КИО)',
      account: 'Номер счета',
      typeDoc: 'Тип документа',
      numberDoc: 'Номер документа',
      dateDocFrom: 'Дата документа С',
      dateDocTo: 'Дата документа По',
      memberStatus: 'Статус участника',
      statusPay: 'Статус плательщика',
      basisPay: 'Основание платежа',
      taxPeriod: 'Налоговый период',
      issuerCountryKod: 'Код страны регистрации банка-эмитента платежной карты',
      bankCountryKod: 'Код страны банка участника',
      countryKod: 'Код страны участника'
    };
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.unsubList.forEach(sub => sub.unsubscribe());
  }

}
