import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UnblockDocumentsService {

  constructor(private apiService: ApiService) { }

  // ToDo модернизировать разблокировку при добавлении новых эндпоинтов
  /**
   * Разблокировка документов
   */
  unblockDocumentsAfterCloseSession(): Observable<any> {
    const documentId = window.sessionStorage.getItem('documentId')
    return this.apiService.post('/api/v1/documents/unblock', {documents: [documentId]})
  }

  /**
   * Разблокировка досье
   */
  unblockDossierAfterCloseSession(): Observable<any> {
    const dossierId = window.sessionStorage.getItem('dossierId')
    return this.apiService.get(`/api/v1/docs/stopEdit/${dossierId}`)
  }
}
