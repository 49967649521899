import {NgModule} from '@angular/core';
import {ClientRoutingModule} from './client-routing.module';
import {ClientComponent} from './client.component';
import * as components from './components';
import * as services from './services';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxMaskModule} from 'ngx-mask';
import {CoreModule} from '../core';
import {UiSwitchModule} from 'ngx-ui-switch';
import {ReactiveFormsModule} from '@angular/forms';
import {NoticeFormComponent} from './components/notice';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CryptographService, CryptographyModule, CryptoValidataService} from "../cryptography";
import {DictionariesModule} from "../dictionaries";

@NgModule({
    imports: [
    CommonModule,
        CoreModule,
        NgbModule,
        ClientRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgSelectModule,
        NgxMaskModule.forRoot(),
        UiSwitchModule.forRoot({
            size: 'medium',
            color: '#ae0000'
        }),
        CryptographyModule,
        DictionariesModule
    ],
  declarations: [
    ClientComponent,
    components.CheckClientsComponent,
    components.CheckClientFormComponent,
    components.CheckRiskAssessComponent,
    components.CheckRiskAssessFormComponent,
    components.ClientDossierListComponent,
    components.NavCabComponent,
    components.DossierComponent,
    components.AccountDossierComponent,
    components.AccountPaycardComponent,
    components.BankAccountsDossierComponent,
    components.OtherAccountsDossierComponent,
    components.DopInfoDossierComponent,
    components.AttributesDossierComponent,
    components.RiskAssessDossierComponent,
    components.SvedBVDossierComponent,
    components.PartnerInfoDossierComponent,
    components.SvedVPRDossierComponent,
    components.SvedClientDossierComponent,
    components.AuthorityDossierComponent,
    components.DokReestrDossierComponent,
    components.SvedIOUDossierComponent,
    components.DokReestrInfoDossierComponent,
    components.SvedAUDossierComponent,
    components.RegInfoInbULDossierComponent,
    components.SvedTargetDossierComponent,
    components.SvedPredstDossierComponent,
    components.OkvedDossierComponent,
    components.SvedULDossierComponent,
    components.SvedIPDossierComponent,
    components.SvedFLDossierComponent,
    components.SvedInbULDossierComponent,
    components.SvedFLCPDossierComponent,
    components.OrgNameDossierComponent,
    components.RegInfoDossierComponent,
    components.AddressInfoDossierComponent,
    components.AddressDossierComponent,
    components.ContactInfoDossierComponent,
    components.LicenseDossierComponent,
    components.FioDossierComponent,
    components.DocumentInfoDossierComponent,
    components.PersonalDataDossierComponent,
    components.PublicInfoDossierComponent,
    components.ResidenceDocInfoDossierComponent,
    components.MigrationCardInfoDossierComponent,
    components.SpkComponent,
    components.LicenseInfoDossierComponent,
    components.RegInfoCPDossierComponent,
    components.AccessDossierComponent,
    components.OtherAccountDossierComponent,
    components.PredstInfoDossierComponent,
    components.AccessInfoDossierComponent,
    components.IouInfoDossierComponent,
    components.NetworkInfoDossierComponent,
    components.NetworkDossierComponent,
    components.UpdateCardDossierComponent,
    components.UpdateCardInfoDossierComponent,
    components.InvalidPanelComponent,
    components.InformantPanelComponent,
    components.GenerateFesComponent,
    components.VersionComponent,
    components.PartnerDossierComponent,
    components.ClientInfoDossierComponent,
    components.AuInfoDossierComponent,
    components.BvInfoDossierComponent,
    components.StaffDossierComponent,
    components.ImportDossierResultComponent,
    components.NoticeListComponent,
    components.ProfileComponent,
    NoticeFormComponent,
    components.CleintsTransactionsComponent,
    components.ClientTransactionFormComponent,
    components.CheckTransactionsComponent,
    components.CheckTransactionFormComponent,
    components.SetupFLKComponent,
    components.SetupFLKWindowComponent,
    components.ExecutionTransferWindowComponent,
    components.CertificateCheckComponent,
    components.CertificateTableComponent,
    components.CertificateSelectComponent,
    components.CertificateContainerComponent,
    components.DateFromDateEndDossierComponent,
    components.StopPowersComponent,
    components.VprInfoComponent,
    components.RiskAssessInfoDossierComponent
  ],
  providers: [
    services.NoAuthGuard,
    services.DossierService,
    services.ProfileService,
    services.ConfirmExitDossierGuard,
    services.ConfirmDeactivateGuard,
    components.SvedClientDossierService,
    components.SvedAUDossierService,
    components.SvedBVDossierComponent,
    components.SvedIOUDossierService,
    components.SvedPredstDossierService,
    components.SvedBVDossierService,
    components.SvedVPRDossierService,
    components.SvedTargetDossierService,
    components.BankAccountsDossierService,
    components.DopInfoDossierService,
    components.OtherAccountsDossierService,
    components.AttributesDossierService,
    components.AccessDossierService,
    components.DokReestrDossierService,
    components.PartnerDossierService,
    components.DossierBaseModel,
    components.DossierPageResolver,
    components.DossierFLKResolver,
    components.DossierStatusResolver,
    components.NoticeService,
    components.NavCabService
  ],
  entryComponents: [
    components.LicenseDossierComponent,
    components.AccountDossierComponent,
    components.AccessInfoDossierComponent,
    components.IouInfoDossierComponent,
    components.PredstInfoDossierComponent,
    components.OtherAccountDossierComponent,
    components.PartnerInfoDossierComponent,
    components.DokReestrInfoDossierComponent,
    components.NetworkInfoDossierComponent,
    components.UpdateCardInfoDossierComponent,
    components.ClientInfoDossierComponent,
    components.AuInfoDossierComponent,
    components.BvInfoDossierComponent,
    components.StaffDossierComponent,
    components.VprInfoComponent
  ]
})
export class ClientModule {
  constructor(private cryptographService: CryptographService, private cryptoValidataService: CryptoValidataService) {
    this.cryptographService.init();
  }
}
