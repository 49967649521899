import {AccessJson} from "@amlCore/models";

export class Role {
  name: string;
  description: string;
  access: Array<AccessJson> = [];

  constructor() {
  }
}
