import {Pipe, PipeTransform} from '@angular/core';
import {formatCurrency, getCurrencySymbol} from '@angular/common';

@Pipe({
  name: 'currencyRub'
})
// TODO переименовать файл currencyRub.pipe.ts
export class CurrencyRubPipe implements PipeTransform {
    transform(
      value: number,
      currencyCode: string = 'RUB',
      display:
          | 'code'
          | 'symbol'
          | 'symbol-narrow'
          | string
          | boolean = 'symbol',
      digitsInfo: string = '1.2-2',
      locale: string = 'RU',
  ): string | null {

      if(value==null)
        return '—';

      return formatCurrency(
        value,
        locale,
        getCurrencySymbol('', null),
        currencyCode,
        digitsInfo,
      );
  }
}
