import {Component, ViewChild} from "@angular/core";
import {DossierService} from "../../../../../services";
import {DictionaryService} from "@amlCore/services";
import {DossierBaseModel} from "../../dossierBaseModel";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {AlertPanelService, MultipleFiledDescriptionComponent} from "@amlCore/components";
import {DossierAccessEnum} from "@amlCore/enums";
import {ErrorFLKItemType} from "@amlCore/models";

@Component({
    selector: 'app-contact-info-dossier',
    styleUrls: ['../../dossier.css'],
    templateUrl: './contactInfoDossier.component.html'
})
export class ContactInfoDossierComponent extends DossierBaseSubComponent {
    dossierAccessEnum = DossierAccessEnum;
    placeholderStuffMap = new Map([
        ['phone', 'Телефон'],
        ['phoneOwn', 'Примечание'],
        ['email', 'E-mail'],
        ['emailOwn', 'Примечание']
    ]);
    @ViewChild('phoneInfo') phoneInfo: MultipleFiledDescriptionComponent;
    @ViewChild('emailInfo') emailInfo: MultipleFiledDescriptionComponent;
    constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
                protected dossierBaseModel: DossierBaseModel,
                protected alertPanelService: AlertPanelService) {
      super(dossierService, dictSrv, alertPanelService);
    }

    focusPhoneError(): void {
        const error = this.dossierService.errorFLKTable;
        if (error) {
            this.phoneInfo.focusField(error.path, error.row);
        }
    }

    focusEmailError(): void {
        const error = this.dossierService.errorFLKTable;
        if (error) {
            this.emailInfo.focusField(error.path, error.row);
        }
    }

}
