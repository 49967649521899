
import {SvedAUType} from "./svedAUType.model";
import {SvedUDSInfoType} from "./svedUDSInfoType.model";
import {SvedIOUInfoType} from "./svedIOUInfoType.model";
import {SvedPredstInfoType} from "./svedPredstInfoType.model";
import {SvedTargetObject} from "./svedTargetObject.model";
import {DopInfoType} from "./dopInfoType.model";
import {AttributesType} from "./attributesType.model";
import {ClientInfo} from "./clientInfo.model";
import {PartnerType} from "./partnerType.model";
import {AccountType} from "./accountType.model";
import {OtherAccountType} from "./otherAccountType.model";
import {DocumentDossier} from "./documentDossier";
import {RiskAssessType} from "./riskAssessType.model";

export class DossierType {
  svedClient: ClientInfo;
  svedAU: Array<SvedAUType>;
  svedUDS: Array<SvedUDSInfoType>;
  svedIOU: Array<SvedIOUInfoType>;
  svedPredst: Array<SvedPredstInfoType>;
  svedVPR: Array<ClientInfo>;
  svedBV: Array<ClientInfo>;
  svedTarget: SvedTargetObject;
  bankAccounts?: Array<AccountType>;
  dopInfo?: DopInfoType;
  otherAccounts?: Array<OtherAccountType>;
  attributes?: AttributesType;
  riskAssess?: Array<RiskAssessType>;
  dokReestr: Array<DocumentDossier>;
  partner: Array<PartnerType>;

  constructor() {
  }
}
