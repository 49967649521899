import {Component, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {DossierService} from "../../../../../services";
import {DictionaryService} from "@amlCore/services";
import {AlertPanelService, BigSelectComponent, ICustomSelectMapper} from "@amlCore/components";
import {DossierBaseModel, OkvedValidator} from "../../dossierBaseModel";
import {Unsubscribable} from 'rxjs';
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {TypeOrg} from "../../../../../models/dossier";
import {DossierAccessEnum} from "@amlCore/enums";

@Component({
  selector: 'app-okved-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './okvedDossier.component.html'
})
export class OkvedDossierComponent extends DossierBaseSubComponent implements OnInit, OnDestroy{
  @Input() typeOrg: TypeOrg;
  okvedValueChanges: Unsubscribable;
  okvedMapper: ICustomSelectMapper;
  public isOkvedReq = false; // флаг необходимости заполнения ОКВЭД
  private okvedValidator: OkvedValidator = new OkvedValidator();

  @ViewChild('okved') okved: BigSelectComponent;
  @ViewChild('okved2') okved2: BigSelectComponent;

  constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  ngOnInit() {
    this.loadRemoteData(['okved', 'okved2']);
    setTimeout(() => {
      this.okvedValueChanges = this.formGroupApp.valueChanges.subscribe(() => {
        this.initOkved();
      });
      this.initOkved();
      this.addUnsubscribable(this.okvedValueChanges);
    });
    this.okvedMapper = {
      toModel: this.okvedMapperToModel.bind(this)
    } as ICustomSelectMapper;
  }

  initOkved() {
    const typeOrgReq = this.okvedValidator.WHITE_LIST_OKVED_CLIENT_TYPE.includes(this.typeOrg);
    const typePageReq = this.typePage === DossierAccessEnum.SVEDCLIENT;
    const okved = this.f('okved');
    const okved2 = this.f('okved2');
    this.isOkvedReq = typeOrgReq && typePageReq && getOkvedLen(okved.value) === 0 && getOkvedLen(okved2.value) === 0;

    function getOkvedLen(list: Array<any>) {
      if (!list) {
        return 0;
      } else {
        return list.length;
      }
    }
  }

  okvedMapperToView(item: any): string {
    return item.code + ' - ' + item.name;
  }

  okvedMapperToViewOption(item: any): string {
    return item.code + ' - ' + item.name;
  }

  okvedMapperToModel(origin: any, isDictData: boolean): any {
    if (isDictData) {
      return {
        code: origin.code,
        name: origin.name
      };
    } else {
      return origin;
    }
  }
  openOkved(event){
    this.okved.open();
  }
  openOkved2(event){
    this.okved2.open();
  }

  ngOnDestroy() {
    this.unsubscribable();
  }
}
