/**
 * API по базовым справочникам АМЛ.
 * API по базовым справочникам АМЛ.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Данные записи специализированного справочника ОКЦМ.
 */
export interface QrefOkcmNotlegacy { 
    /**
     * Код ОКЦМ. Ключевое поле таблицы.
     */
    kod?: number;
    /**
     * Код.
     */
    kokcm?: string;
    /**
     * Страна.
     */
    ncmShort?: string;
    /**
     * Полное наименование.
     */
    ncm?: string;
    /**
     * Дата окончания применения.
     */
    ddEnd?: string;
    /**
     * Дата начала применения.
     */
    ddBegin?: string;
}

