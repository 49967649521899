import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DossierService} from "../../../../../services";
import {DictionaryService, ResourceService} from "@amlCore/services";
import {IDictItemType} from "@amlCore/interfaces";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-check-risk-assess-form-dossier',
  templateUrl: './checkRiskAssessForm.component.html'
})
export class CheckRiskAssessFormComponent implements OnInit {
    @Input()
    checkData: any = [];
    @Output() close = new EventEmitter<boolean>();
    constructor(
        protected router: Router,
        protected dictSrv: DictionaryService,
        private _resourceSrv: ResourceService,
        private _route: ActivatedRoute
    ) {}

    id: string;
    private riskGroupList: IDictItemType[] = [];

    ngOnInit(): void {
        this.dictSrv.getRemoteData('riskgroup').then((result) => {
            this.riskGroupList = result;
        });
    }

    ngOnDestroy(): void {
    }

    private getRiskGroupName(code: string): string {
        return this.dictSrv.getDictNameByCode(code, this.riskGroupList);
    }

    /**
     * Закрыть
     */
    goBack() {
        this.close.emit(true);
    }
}
