import {Component, Input, Output, OnDestroy, OnInit, EventEmitter, ViewChild} from "@angular/core";
import { User } from "../../../core/models/user.model";
import {ExecutorDataModel} from "../../models/executorData.model";
import {DossierService} from "../../services";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Utils} from "@amlCore/utils";
import {AlertPanelComponent, AlertPanelService} from "@amlCore/components";

@Component({
    selector: 'app-execution-transfer-window',
    templateUrl: './executionTransferWindow.component.html'
})

export class ExecutionTransferWindowComponent implements OnInit, OnDestroy {
    @Input() usersList: User[] = [];
    @Input() data: ExecutorDataModel = { userId: '', date: ''};
    @Output() onSave = new EventEmitter();
    /**
     * Объект для отображения информации по сохранению
     */
    @ViewChild('alertObjSave', {static: true}) alertObjToSave: AlertPanelComponent;

    form: FormGroup;
    saveInProcess = false;
    constructor(private formBuilder: FormBuilder, private modal: NgbActiveModal,
                private alertPanelSrv: AlertPanelService,) {}

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            userId: ['', Validators.required],
            date: ['', Validators.required]
        });
        this.form.patchValue(this.data);
    }

    ngOnDestroy(): void {}

    /**
     * Сохранить
     */
    save(){
        this.saveInProcess = true;
        this.onSave.emit(Utils.clone(this.form.value));
    }

    afterSave(){
        this.saveInProcess = false;
        this.form.markAsPristine();
        this.alertObjToSave.open(this.alertPanelSrv.getSuccessMsg("Данные по вкладке сохранены, уведомление отправлено"));
    }

    /**
     * Закрываем модальное окно
     */
    goBack() {
        this.modal.dismiss(false);
    }
}
