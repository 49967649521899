import {faFilter} from "@fortawesome/free-solid-svg-icons/faFilter";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import {faLockOpen} from "@fortawesome/free-solid-svg-icons/faLockOpen";
import {faLock} from "@fortawesome/free-solid-svg-icons/faLock";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {faCopy} from "@fortawesome/free-solid-svg-icons/faCopy";
import {
  faArchive,
  faBan,
  faDownload,
  faEdit,
  faExclamationCircle,
  faExclamationTriangle,
  faHandPaper,
  faMinus,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import {FormModelService, TableService, VisitedService} from "@amlCore/services";
import {faCogs} from "@fortawesome/free-solid-svg-icons/faCogs";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import {SelectedItemModel} from "@amlCore/models";
import {iteratorObject, Utils} from "@amlCore/utils";
import {FormGroup} from '@angular/forms';
import {StoreService} from "../services";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {catchError} from "rxjs/operators";
import {of} from "rxjs";
import {CommonService} from "@amlDocument/services";
import {Optional} from "@angular/core";
import {getGroupType} from "src/app/arm/documentForm/enums/documentTypeEnum";
import {CommonList} from "../../arm/documentForm/model";

export abstract class TableReactiveController {
  abstract isShowVisited; // Отобразить посещенные элементы (модель объекта должна быть наследована от VisitedLinkModel)
  protected filter: any; // Кеш с данными фильтра из компоненты фильтра.
  protected formModelService: FormModelService; // сервис для работы с реактивной формой
  public formModel: FormGroup; // Общая модель формы.
  public page = 1;
  public pageSize = 20;
  public total = null;
  public sort = "";
  public dir = "asc";
  /**
   * Признак выбора всех записей
   * При использовании НЕОБХОДИМО добавить вызов resetSelectedAll() в метод onPageChange для сброса флага при поиске итд
   * getSelectedModel - метод получаения списка выбранных записей
   * selectAll - метод снятия/выбора всех элементов автоматически
   * setSelectAll - метод снятия/выбора всех элементов в ручную
   * selectRow - выделить/снять строку
   * checkSizeSelectedModel - проверка выбрано ли что-то
   * getSelectedParam - получить параметры модели
   */
  public isSelectedAll = false;
  public icons = {
    trash: faTrash,
    plus: faPlus,
    minus: faMinus,
    download: faDownload,
    handPaper: faHandPaper,
    check: faCheck,
    filter: faFilter,
    search: faSearch,
    circle: faTimesCircle,
    unlocked: faLockOpen,
    locked: faLock,
    checkCircle: faCheckCircle,
    gear: faCogs,
    edit: faEdit,
    archive: faArchive,
    spinner: faSpinner,
    waring: faExclamationTriangle,
    copy: faCopy,
    exclamationCircle: faExclamationCircle,
    closed: faBan,
  };
  protected storeService = new StoreService();

  // константа - вермя ожидания загрузки данных.
  protected TIMER_DELAY = 2000;
  /* карта ключей, хранящая состояние
   * открыта или закрыта форма просмотра данных
   * для каждой записи в таблице
   */
  public toggleIdMap = new Map();
  /* Карта хранящая признак загрузки
   * данных для каждой записи в таблице
   */
  public hasLoadingIdMap = new Map();
  /**
   * Карта хранящая признак наличия
   * загруженных данных
   * true - данные уже загружены
   * false - данные еще не загружены
   */
  private hasDataIdMap = new Map();
  /**
   * Карта id таймера для каждой записи
   */
  private timerIdMap = new Map();
  protected readonly _MODAL_CONFIG2 = {
    size: 'lg',
    backdrop: 'static',
    centered: true,
  } as NgbModalOptions;
  constructor(protected visitedService: VisitedService,
              protected tableService: TableService,
              params?: any,
              protected modalDialog?: NgbModal,
              @Optional() protected commonService?: CommonService) {
    if (TableReactiveController.isValidObject(params)) {
      this.pageSize = params.pageSize || this.pageSize;
      this.sort = params.sort || this.sort;
      this.dir = params.dir || this.dir;
    }

  }

  static isValidObject(params) {
    return params && typeof params === "object" && Object.keys(params).length > 0;
  }

  getParams(params?: object): object {
    let result = {};
    const baseParams = {
      page: this.page - 1,
      size: this.pageSize,
      sort: this.sort,
      dir: this.dir,
    };
    if (TableReactiveController.isValidObject(params)) {
      result = Utils.clone(params);
      Object.assign(result, baseParams);
    } else {
      Object.assign(result, baseParams);
    }
    return result;
  }

  /**
   * Метод формирования модели фильтра
   * структуры { filter: {} }
   * @param params параметры фильтра
   */
  getParamsv2(params?: any): object {
    const filterObject = {
      filter: {}
    };
    const baseParams = {
      page: this.page - 1,
      size: this.pageSize,
      sort: this.sort,
      dir: this.dir,
    };
    const exceptionFilterList = ["page", "size", "sort",
                                  "dir", "search", "result"];
    if (!params || Object.keys(params).length === 0) {
      return baseParams;
    }

    Object.keys(params)
      .filter((key) => !exceptionFilterList.includes(key))
      .forEach((key) => filterObject.filter[key] = params[key]);

    if (params.hasOwnProperty("search")) {
      filterObject["search"] = params["search"];
    }
    if (params.hasOwnProperty("result")) {
      filterObject["result"] = params["result"];
    }

    return {...baseParams, ...filterObject};
  }

  /**
   * Метод получения плоского объекта
   */
  protected getFilterPlanObject(filter: any) {
    let result = {};
    const entitysType = ["legalEntity", "individualPerson", "individualEntrepreneur", "foreignStructure"];
    Object.keys(filter).forEach((key) => {
      if (typeof filter[key] !== "object" && filter[key] !== "") {
        result[key] = filter[key];
      } else if (typeof filter[key] === 'object' && entitysType.includes(key)) {
          let entity = {};
          Object.keys(filter[key]).forEach((fieldKey: string) => {
            if (filter[key][fieldKey]) {
              entity[fieldKey] = filter[key][fieldKey];
            }
          });
          result = {...result, ...entity};
      }
      // кастомная логика обработки плоского объекта.
      // реализуемая на уровне класса компоненты
      this.customPatchPlanObject(result, filter, key);
    });
    return result;
  }

  protected resetPagination(): void {
    this.page = 1;
  }

  getSearchPage(filterData: object) {
    this.resetPagination();
    return this.getParams(filterData);
  }

  /**
   * Метод получения текущих данных фильтра
   * из компоненты фильтра
   */
  public getFilter(data) {
    this.filter = data;
  }

  abstract onPageChange(params): void;

  /*
   * метод реализуемый на уровне компоненты
   * в случаях индивидуальной обработки некторых
   * данных плоского объекта фильтра.
  */
  protected customPatchPlanObject(result?, filter?, key?) {}

  onSearch(data) {
    this.onPageChange(this.getSearchPage(data));
  }

  onSort(params) {
    if (params.sort) {
      if (this.sort !== params.sort) {
        this.dir = 'asc';
      } else {
        this.dir = params.dir === 'asc' ? 'desc' : 'asc';
      }
      this.sort = params.sort;
      this.onPageChange(this.getParams(this.filter));
    }
  }

  saveRowId(id: string) {
    if (this.isShowVisited) {
      this.visitedService.setVisitedId(id);
    }
  }

  getFocusRowId() {
    return this.tableService.focusRowId;
  }

  /**
   * Выбрать все отображаемые записи
   * @param list - список элементов
   */
  selectAll(list: Array<SelectedItemModel>) {
    this.isSelectedAll = !this.isSelectedAll;
    list.forEach(item => {
      item.$selected = this.isSelectedAll;
    });
  }

  /**
   * Получить выбранные записи
   * @param list - список, из которого выбрать записи
   */
  getSelectedModel(list: Array<SelectedItemModel>): Array<SelectedItemModel> {
    return list.filter(item => item.$selected === true);
  }

  /**
   * Проверка выбрано ли что-то
   * @param list - список
   */
  checkSizeSelectedModel(list: Array<SelectedItemModel>): boolean {
    return this.getSelectedModel(list).length > 0;
  }

  /**
   * Получить параметры выбранной модели
   * признак выбрано все, список выбранных и тд
   * @param list - список, из которого отобрать выбранные
   */
  getSelectedParam(list: Array<SelectedItemModel>) {
    return {
      isSelectedAll: this.isSelectedAll,
      selectedModel: this.getSelectedModel(list)
    };
  }

  /**
   * Сбростить флаг "Выделить все"
   */
  resetSelectedAll() {
    this.isSelectedAll = false;
  }

  /**
   * Установить признка выделения и снять/выбрать все элементы
   * @param list - список
   * @param value - значение снять/выбрать
   */
  setSelectAll(list: Array<SelectedItemModel>, value: boolean) {
    this.isSelectedAll = value;
    list.forEach(item => item.$selected = value);
  }

  /**
   * Выбор строки снять/выдеилть
   * @param item - строка
   */
  selectRow(item: SelectedItemModel) {
    item.$selected = !item.$selected;
  }

  /**
   * Выбор строки снять
   * @param item - строка
   */
  unSelectRow(item: SelectedItemModel) {
    setTimeout(() => {item.$selected = false;}, 3000);
  }

  // ---- инструменты для вывода данных под строкой в таблице ---- //
  /**
   * Метод переключения информационного блока
   * @param id - уникальный идентификатор
   * true - показать блок с информацией
   * false - скрыть блок с информацией
   */
  public toggleView(id: string) {
    const status = !this.toggleIdMap.get(id);
    this.toggleIdMap.set(id, status);
    this.setStatusDataIdMap(id);
  }

  /**
   * Метод устанавливающий признак начала загрузки данных в
   * карту hasLoadingIdMap
   * @param id записи
   * @param status статус процесса загрузки
   * true - загрузка данных началась
   */
  public setStatusStartLoading(id: string, status: boolean) {
    /* запуск таймера через ~2 секунды, выполняется проверка
       если (загрузка началась и данных нет) то
       для данной записи по id отображается spinner */
    const timerId = setTimeout(() => {
      if (status === true && !this.hasDataIdMap.get(id)) {
        this.hasLoadingIdMap.set(id, status);
      }
    }, this.TIMER_DELAY);
    this.timerIdMap.set(id, timerId);
  }

  /**
   * Метод устанавливающий признак завершения загрузки данных в
   * карту hasLoadingIdMap
   * @param id записи
   * @param status статус процесса загрузки
   * false - загрузка данных завершилась
   */
  public setStatusFinishLoading(id: string, status: boolean) {
    // отключаем отображение спинера для данной id записи
    this.hasLoadingIdMap.set(id, status);
    // записываем в карту данных что по данной id записи уже загружены данные.
    this.hasDataIdMap.set(id, !status);
    // убераем timer для данной id записи
    if (this.timerIdMap.get(id)) {
      clearTimeout(this.timerIdMap.get(id));
    }
  }

  /**
   * Метод инициализирующий карту статусов
   * @param content данные коллекция id
   */
  protected initIdsMap({content}) {
    content?.forEach((item) => {
      this.hasLoadingIdMap.set(item.id, false);
      this.hasDataIdMap.set(item.id, false);
      this.toggleIdMap.set(item.id, false);
    });
  }

  /**
   * Метод устанавливающий по данной id записи
   * true - данные загружены.
   * false - данные не загружены.
   * @param id уникальный идентификатор записи
   */
  protected setStatusDataIdMap(id: string) {
    if (this.toggleIdMap.get(id) === false) {
      this.hasDataIdMap.set(id, false);
    }
  }

  /**
   * Перемещение в папку "Удаленные"
   */
  protected moveToDeleted(dataList, importLoad, group = ''): void {
    const selected = this.getSelectedModel(dataList)
    const _iterate = iteratorObject(selected)
    let docsToDeleted: string[] = []
    const step = (iterate) => {
      const next = iterate.next();
      if (!next.done) {
        blockDocuments(iterate, next.object);
      } else {
        moveDocs();
      }
    }

    const blockDocuments = (iterate, item) => {
      this.commonService.blockDocuments([item.id], getGroupType(group)).pipe(
          catchError(() => {
            step(iterate)
            return of()
          })
      ).subscribe(() => {
        docsToDeleted.push(item.id)
        step(iterate)
      })
    }

    const moveDocs = () => {
      if (docsToDeleted.length !== selected.length) {
        this.tableService.emitAlertMessage({type: 'error', message: 'Один или несколько документов заблокированы другим пользователем'})
      }

      if (docsToDeleted.length) {
        this.commonService.moveToDeleted(docsToDeleted, group).subscribe((data) => {
          importLoad();
          this.isSelectedAll = false;
        });
      }
    }

    blockDocuments(_iterate, _iterate.next().object)
  }

  /**
   * Проверка что все выбранные документы имеют checked=true
   * @param list - список
   */
  selectedListChecked(list: Array<any>): boolean {
    const selected = list.filter(item => item.$selected === true)
    return selected.every(selectedItem => selectedItem.checked === true)
  }

  protected archiveDocuments(dataList, importLoad: () => void, group = '') {
    const selected = this.getSelectedModel(dataList) as Array<CommonList>
    this.commonService.moveToArchive(selected.map(i => i.id), group).subscribe(() => {
      importLoad();
      this.isSelectedAll = false;
    });
  }
}
