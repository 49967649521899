export class SpkSearch {
  typeOrg: string;
  ogrn: string;
  family: any;
  name: any;
  sname: string;
  birthDate: any;
  regnum: string;
  docType: string;
  seria: string;
  number: string;
  fullName: string;
  viewDelete: boolean;
  useFullSearch: boolean;
  reesrt: boolean;
  terr: boolean;
  mvk: boolean;
  fromu: boolean;

  constructor() {
  }
}
