export * from './dossierRequest.model';
export * from './accountType.model';
export * from './addressInfoType.model';
export * from './addressType.model';
export * from './attributesType.model';
export * from './riskAssessType.model';
export * from './authorityType.model';
export * from './base.model';
export * from './contactType.model';
export * from './dictType.model';
export * from './dictListType.model';
export * from './documentInfoType.model';
export * from './dopInfoType.model';
export * from './dossierType.model';
export * from './fioType.model';
export * from './licenseType.model';
export * from './migrationCardInfoType.model';
export * from './okvedType.model';
export * from './orgNameType.model';
export * from './otherAccountType.model';
export * from './personalDataType.model';
export * from './publicInfoType.model';
export * from './regInfoCPType.model';
export * from './regInfoInbULType.model';
export * from './regInfoType.model';
export * from './residenceDocInfoType.model';
export * from './svedAUType.model';
export * from './svedBVType.model';
export * from './svedFLCPType.model';
export * from './svedFLType.model';
export * from './svedInbULType.model';
export * from './svedIOUInfoType.model';
export * from './svedIPType.model';
export * from './svedPredstInfoType.model';
export * from './svedTargetType.model';
export * from './svedUDSInfoType.model';
export * from './svedULType.model';
export * from './documentDossier';
export * from './partnerType.model';
export * from './clientInfo.model';
export * from './networkInfo.model';
export * from './updateCardInfo.model';
export * from './accessDossierType.model';
export * from './svedTargetObject.model';
export * from './svedVPRType.model';
