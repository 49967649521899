import {ContactType} from "./contactType.model";
import {LicenseType} from "./licenseType.model";
import {RegInfoInbULType} from "./regInfoInbULType.model";
import {OkvedType} from "./okvedType.model";
import {DossierINNType, DossierKPPType} from "./base.model";
import {DictType} from "./dictType.model";
import {OrgNameType} from "./orgNameType.model";
import {AddressInfoType} from "./addressInfoType.model";

export class SvedInbULType {
  orgName: OrgNameType = new OrgNameType();
  opf: DictType = new DictType();
  inn: DossierINNType = '';
  kpp: DossierKPPType = '';
  okved: OkvedType = undefined;
  regInfoInbUL: RegInfoInbULType = new RegInfoInbULType();
  licenseInfo?: Array<LicenseType>;
  addressInfo: AddressInfoType = new AddressInfoType();
  contactInfo: ContactType = new ContactType();

}
