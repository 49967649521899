import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ErrorFLKItemType, ErrorFLKModel, ErrorTableFLKModel} from "@amlCore/models";
import {Utils} from "@amlCore/utils";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-flk-error-panel',
  templateUrl: './flkErrorPanel.component.html',
  styleUrls: ['./flkErrorPanel.component.css']
})
export class FlkErrorPanelComponent implements OnInit {
  @Input() errorFLKList: Array<ErrorFLKModel> = [];
  @Input() errorFLKData: ErrorFLKItemType;
  @Output() showFLKError: any = new EventEmitter<ErrorFLKModel>();
  @Output() showFLKTableError: any = new EventEmitter<ErrorTableFLKModel>();
  isOpen = true;
  icons = {
    close: faTimes,
  };

  ngOnInit() {
    // При наличии ошибки по табличной части выводим как общую группу фиктивного элемента
    if (this.errorFLKList && this.errorFLKList.length === 0 && this.errorFLKData) {
      this.errorFLKList.push({
        msg: 'Ошибки:',
        path: '',
        items: this.errorFLKData
      });
    }
  }

  /**
   * Перейти к ошибке в простом полей
   * @param error - данные ошибки
   */
  showError(error: ErrorFLKModel) {
    if (this.showFLKError) {
      this.showFLKError.emit(error);
    }
  }

  /**
   * Перейти к ошибке в таблицу
   * @param error - ошибка
   * @param tableError - таблица
   */
  showTableError(error: ErrorFLKModel, tableError: ErrorFLKModel) {
    if (this.showFLKTableError) {
      this.showFLKTableError.emit({error, tableError} as ErrorTableFLKModel);
    }
  }

  /**
   * Получение структуры вложенных ошибок второго уровня
   * TODO: сделать рекурсивный проход на случай 3 уровня
   * @param error - общая ошибка
   * error.items - объект ошибок (key - row списка, значение - ошибки этого элемента)
   */
  getError(error: ErrorFLKModel): Array<ErrorFLKModel> {
    const listError = [];
    if (error.items) {
      Object.keys(error.items).forEach(row => {
        const item = error.items[row];
        item.forEach(errorItem => {
          const itemAdd = Utils.clone(errorItem);
          itemAdd.row = Number(row);
          listError.push(itemAdd);
        });
      });
    }
    return listError;
  }

  /**
   * Проверка есть ли объекты ошибок
   * @param items - объект ошибок
   */
  checkLengthFLKItem(items: ErrorFLKItemType): boolean {
    const copyItem = Utils.clone(items);
    Utils.deleteEmptyObj(copyItem);
    return Object.keys(copyItem).length > 0;
  }

  initErrorFLKSimpleField() {
    // Проставляем ошибки для простых полей
    this.errorFLKList.forEach(error => {
      // items есть только у ошибок в таблицах или множественных полях
      if (!error.items) {
        // TODO: добавить передачу false - Просто подсвечиваем ошибки без перехода
        this.showFLKError.emit(error);
      }
    });
  }

  closePanel() {
    this.errorFLKList = [];
  }
}
