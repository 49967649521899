import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {DossierService} from "../../../services";
import {DossierStatusEnum} from "./dossierStatusEnum";
import {DossierStatusModel} from "../../../models";

/**
 * Получение статуса досье
 */
@Injectable()
export class DossierStatusResolver implements Resolve<any> {
  constructor(private dossierService: DossierService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DossierStatusModel> | DossierStatusModel {
    const dossierId = route.params.id;
    if (dossierId) {
      return this.dossierService.getStatus(dossierId);
    }
    return {
      status: DossierStatusEnum.DRAFT
    } as DossierStatusModel;
  }
}
