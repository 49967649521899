/**
 * Проверка, является ли функцией
 * @param fn - параметр, который должен быть проверен
 */
const isFunction = fn => typeof fn === "function";

const doUnsubscribe = subscription => {
    if (subscription && isFunction(subscription.unsubscribe)) {
        subscription.unsubscribe();
    }
};

const doUnsubscribeIfArray = subscriptionsArray => {
    if (Array.isArray(subscriptionsArray)) {
        subscriptionsArray.forEach(doUnsubscribe);
    }
};

/**
 * Декоратор, который выполняет массовую отписку от всех Observable объектов в классе.
 * Так же можно отписываться при других событиях жизненного цикла компонента, например ngOnInit и т.д.
 * @param blackListSub - массив Имен подписок, от которых НЕ нужно отписываться (по умолчанию: '').
 * @param arrayNameSub - массив Имен подписок, от которых нужно отписаться (по умолчанию: '').
 * @param event - событие при котором происходит отписка (по умолчанию: ngOnDestroy).
 * Пример использования:
 * @AutoUnsub()
 * export class ExampleComponent implements OnDestroy {
 *      ...
 *       ngOnDestroy(): void {}
 *      ...
 * }
 * Если нужно передать параметры (передаем в объекте, значения которого имеют тип string)
 * Пример:
 * @AutoUnsub({
 *     blackListSub: 'exampleParam',
 *     arrayNameSub: 'exampleParam',
 *     event: 'exampleParam',
 * })
 */
export function AutoUnsub({
                              blackListSub = '',
                              arrayNameSub = '',
                              event = 'ngOnDestroy'
                          } = {}) {
    return constructor => {
        const original = constructor.prototype[event];
        /**
         * Проверка, являются ли значение параметров типом string
         */
        const isStringParam = [blackListSub, arrayNameSub, event].every(value => typeof value === 'string');
        if (!isStringParam) {
            throw new Error(
                'При использовании @AutoUnsub необходимо передавать параметры в объекте типом string'
            );
        }
        /**
         * Проверка, имлеминитровано ли событие в классе компонента
         */
        if (!isFunction(original)) {
            throw new Error(
                `${constructor.name} при использовании @AutoUnsub вы забыли имплементировать ${event}`
            );
        }

        constructor.prototype[event] = function () {
            if (isFunction(original)) {
                original.apply(this, arguments);
            }

            if (arrayNameSub) {
                doUnsubscribeIfArray(this[arrayNameSub]);
                return;
            }

            for (const propName in this) {
                if (blackListSub.includes(propName)) {
                    continue;
                }

                const property = this[propName];
                doUnsubscribe(property);
            }
        };
    };

}
