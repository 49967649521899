export class Access {
  id: string;
  parent: Access;
  childer: Array<Access>;
  descreiption: string;

  constructor() {
  }
}

