/**
 * API по базовым справочникам АМЛ.
 * API по базовым справочникам АМЛ.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Тип ошибки.
 */
export type ErrorType = 'conversion' | 'entityFieldName' | 'notFound' | 'internal';

export const ErrorType = {
    Conversion: 'conversion' as ErrorType,
    EntityFieldName: 'entityFieldName' as ErrorType,
    NotFound: 'notFound' as ErrorType,
    Internal: 'internal' as ErrorType
};

