import {Component, OnInit, OnDestroy} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {TableReactiveUrlController} from "@amlCore/tableReactive";
import {CheckClients} from "../../../../../models/checkClients";
import {DossierService} from "../../../../../services";
import {TableService} from "@amlCore/services";
import {IDictItemType} from "@amlCore/interfaces";
import {DictionaryService, VisitedService} from "@amlCore/services";
import {IDossierPage} from "../../../../../interfaces";
import {AlertPanelComponent} from "@amlCore/components";
import {CheckDictEnum} from "@amlCore/enums";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CheckRiskAssessFormComponent} from "./checkRiskAssessForm.component";
import { map } from "rxjs/operators";

@Component({
  selector: 'app-check-risk-assess-dossier',
  templateUrl: './checkRiskAssess.component.html'
})
export class CheckRiskAssessComponent extends TableReactiveUrlController implements OnInit, OnDestroy {

  public checkClientsList: CheckClients[] = []; // список результатов проверок
  public isShowVisited = true;
  isDossierPart = false; // Признак, что раздел в досье
  idDossier: string; // id досье если isDossierPart === true
  private riskLevelDict: IDictItemType[] = [];
  constructor(protected dictSrv: DictionaryService,
              protected activatedRoute: ActivatedRoute,
              protected visitedService: VisitedService,
              protected tableService: TableService,
              protected router: Router,
              private dossierSrv: DossierService,
              private readonly modalService: NgbModal) {
    super(router, activatedRoute, visitedService, tableService,
      {});
  }

  ngOnInit() {
    this.dictSrv.getRemoteData('risklevel').then(result => {
      this.riskLevelDict = result;
    });
    this.isDossierPart = this.activatedRoute.snapshot.data.isDossierPart;
    this.idDossier = this.activatedRoute.snapshot.parent.params.id;
    this.createUniqueKeyForStore();
    /* если находимся в новом досье которое еще только создается, то список проверок не запрашиваем */
    if (this.activatedRoute.routeConfig.data.hasOwnProperty('isDossierPart') && !this.idDossier) {
      this.total = 0;
      return;
    }
    this.activatedRoute.queryParams.subscribe(() => {
      this.loadList();
    });
  }

  /**
   * метод получения объекта плоского фильтра
   * @param data объект фильтра с полями
   */
  public getFilterObject(data): object {
    const filter = this.tableService.getFilterSubject(data);
    return this.getFilterPlanObject(filter);
  }

  /**
   * Загрузка списка результатов проверок по отнесению к группе для данного досье
   */
  loadList(): void {
    this.dossierSrv.getCheckClientsByIdAndDictType(this.idDossier, CheckDictEnum.RISK_GROUP)
    .pipe(map((data)=>[...data].reverse()))
    .subscribe(data => {
      this.checkClientsList = data;
      this.total = data?.length;
      this.scrollRun();
    });
  }

  /**
   * Открытие формы просмотра с кодами критериев отнесения клиентов к группе риска
   * @param check - результат проверки, для которого выводим коды
   */
  viewForm(check: CheckClients): void {
    let modalRef = this.modalService.open(CheckRiskAssessFormComponent, {
      backdrop: 'static',
      size: 'lg',
      scrollable: true,
      centered: true
    });
    modalRef.componentInstance.checkData = this.getCheckFormData(check);
    modalRef.componentInstance.close.subscribe(() => {
      modalRef.close();
    });
  }


  /**
   *  Подготовка данных для формы просмотра кодов результата проверки
   * @param check
   */
  getCheckFormData(check: CheckClients): object {
    return {
      mainrisk: this.getDopInfoValue(check,'mainrisk'),
      addrisk1: this.getDopInfoValue(check,'addrisk1'),
      addrisk2: this.getDopInfoValue(check,'addrisk2'),
      addrisk3: this.getDopInfoValue(check,'addrisk3')
    }
  }

  getRiskLevelName(check: CheckClients): string {
    return this.dictSrv.getDictNameByCode(check.checkResult == 3 ? '0': this.getDopInfoValue(check,'risk_level'), this.riskLevelDict);
  }

  ngOnDestroy() {
    this.checkScrollOptions();
  }

  getDopInfoValue(checkResult: CheckClients, code: string): string {
    return this.dossierSrv.getCheckDopInfoValue(checkResult, code);
  }

  onPageChange(params): void {
  }

}
