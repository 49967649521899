import {Component, OnInit} from "@angular/core";
import {DossierService} from "../../../../../services";
import {AlertPanelService, ITableEditorItem, ITableItem} from "@amlCore/components";
import {DictionaryService} from "@amlCore/services";
import {DossierBaseModel} from "../../dossierBaseModel";
import {FormGroup} from "@angular/forms";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {SvedPredstInfoType} from "../../../../../models/dossier";

@Component({
  selector: 'app-predst-info-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './predstInfoDossier.component.html'
})
export class PredstInfoDossierComponent extends DossierBaseSubComponent implements ITableItem, OnInit {

  titlePanel = 'Сведения о представителе';
  showTitle = true;
  isTableItem = false;

  constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }
  /**
   * Описание формы
   */
  getFormDescription(): FormGroup {
    return this.dossierBaseModel.getSvedPredst();
  }

  /**
   * Инициализация FormGroup
   */
  initForm() {
    if (!this.isTableItem) {
      this.formGroupApp = this.getFormDescription();
    }
  }

  ngOnInit() {
    this.focusObject = this.generateFocusObject(new SvedPredstInfoType());
  }

  setSubmitted(value: boolean) {
    this.submitted = value;
  }


  getTitle(): string {
    return this.titlePanel;
  }

  /**
   * Инициализируем компонент как элемент формы просмотра таблицы
   * @param param.form - форма для связи
   * @param param.params - дополнительные параметры
   */
  initTableItem(param: ITableEditorItem): FormGroup {
    this.initForm();
    this.isTableItem = true;
    if (param.model) {
      this.formGroupApp.patchValue(param.model);
    }
    this.showTitle = false;
    if (param.params && param.params.typePage) {
      this.typePage = param.params.typePage;
    } else {
      console.log('Необходимо указать typePage у компонента');
    }
    this.isReadOnly = param.isReadOnly;
    if (this.isReadOnly){
      this.formGroupApp.disable();
    }
    return this.formGroupApp;
  }
}
