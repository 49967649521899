import {Directive, ElementRef, OnInit, OnDestroy} from "@angular/core";
import {Subscription} from "rxjs";
import {UserService} from "@amlCore/services";
import {CryptographService} from "../../cryptography/services";

@Directive({selector: '[appCryptCheckState]'})
export class CryptCheckStateDirective implements OnInit, OnDestroy {
    isCryptEnableSub: Subscription;

    constructor(
        private elementRef: ElementRef,
        private userService: UserService,
        private cryptographService: CryptographService) {
    }

    ngOnInit(): void {
        if (!this.userService.checkAccess('cryptographyAndSignatureAccess')) {
            this._disableElement()
            this.elementRef.nativeElement.title = "Отсутствует доступ к подписи документов. Обратитесь к администратору";
        } else{
            this.isCryptEnableSub = this.cryptographService.isCryptEnable.subscribe(({state}) => {
                if(!state){
                    this._disableElement();
                }
            });
        }
    }

    /**
     * необходимо для @AutoUnsub
     */
    ngOnDestroy(): void {
        if (this.isCryptEnableSub) {
            this.isCryptEnableSub.unsubscribe();
        }
    }

    private _disableElement(): void {
        this.elementRef.nativeElement.disabled = true;
        this.elementRef.nativeElement.style.cursor = "not-allowed";
    }

    private _enableElement(): void {
        this.elementRef.nativeElement.disabled = false;
        this.elementRef.nativeElement.style.cursor = "pointer";
    }
}
