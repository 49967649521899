export enum RegistriesAccessEnum {
  QREF_TERROR = 'qref_terror',
  QREF_FROMU = 'qref_fromu',
  QREF_MVK = 'qref_mvk',
  QREF_OTKAZ = 'qref_otkaz',
  QREF_MSLEAD = 'qref_mslead',
  QREF_MSFOUND = 'qref_msfound',
  QREF_MSADR = 'qref_msadr',
  QREF_FTS = 'qref_fts',
  QREF_PASSP = 'qref_passp',
  QREF_INTERNALORG = 'qref_internalorg',
  CLS_CTFB_5016 = 'cls_ctfb_5016',
  CLS_CTFB = 'cls_ctfb',
  QREF_RISKGROUP = 'qref_riskgroup'
}

export function getRegistriesAccessList(): Array<string> {
  return Object.values(RegistriesAccessEnum);
}
