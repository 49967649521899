import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {Unsubscribable} from "rxjs";
import {DossierAccessEnum} from "@amlCore/enums";
import {IDossierPanelInfo} from "../../interfaces";
import {DossierService} from "../../services";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons/faExclamationCircle";

@Component({
  selector: 'app-invalid-panel',
  styleUrls: ['./invalidPanel.component.css'],
  templateUrl: './invalidPanel.component.html'
})
export class InvalidPanelComponent implements OnInit, OnDestroy {

  // Объект за которым следим
  @Input() formGroupApp: FormGroup;
  @Input() typePage: DossierAccessEnum;
  @Output() setSubmitted: any = new EventEmitter<boolean>();
  @Input() statusInvalid = false;
  // Передаем ссылку на метод, который определяет валидность формы
  @Input() customCheckForm: (form: FormGroup) => boolean;
  panelInfo: IDossierPanelInfo;
  isReadOnly = false;
  isReadOnlySub: Unsubscribable;
  icons = {
    exclamationCircle: faExclamationCircle
  };
  formGroupAppStatusChanges: Unsubscribable;

  constructor(private dossierService: DossierService) {
  }

  clickIconInvalid() {
    this.setSubmitted.emit(true);
  }

  ngOnInit(): void {
    if (this.formGroupApp) {
      this.statusInvalid = this.formGroupApp.invalid;
      this.formGroupAppStatusChanges = this.formGroupApp.statusChanges.subscribe(value => {
        this.statusInvalid = this.customCheckForm ? this.customCheckForm(this.formGroupApp) : value === 'INVALID';
      });
    }
    this.panelInfo = this.dossierService.getPanelInfo(this.typePage);
    if (this.panelInfo) {
      this.isReadOnly = this.panelInfo.isReadOnly.getValue();
      this.isReadOnlySub = this.panelInfo.isReadOnly.subscribe(value => {
        this.isReadOnly = value;
      });
    }
  }

  ngOnDestroy(): void {
    this.formGroupAppStatusChanges.unsubscribe();
    this.isReadOnlySub.unsubscribe();

  }
}
