﻿(function () {
    var vcert ={};
    var dir = "VCERT_plugin";
    var isRequired = function(){ throw  new Error('param is required'); };
    var msgID = 0;
    var tabID =null;
    var done = 0
    var msgL = [];
	var hostISDEAD = false;
	var hostISDEADMSG = {};
    window.vcert_promise = new Promise(function(resolve, reject){
        function onPluginMessage(event){
            //console.log(JSON.stringify(event.data));
            if (event.source == window &&
                event.data.direction &&
                event.data.direction == "fromVCERT_plugin" && event.data.msg.tabID && done ==0){
                tabID = event.data.msg.tabID;
                console.log("Connected to background with tabID = " + tabID);
                done++;
                window.removeEventListener("message", onPluginMessage);
                initAPI();
                initConst();
                initPrototypes();
                initErrors();
                window.vcert = vcert;
				resolve();
            }
        };
        window.addEventListener("message", onPluginMessage);
    });

    
    function initConst(){
            vcert.FLAG_PKCS7 = 1 << 0;
            vcert.FLAG_DETACHED = 1 << 1;
            vcert.FLAG_SIGN_SENDCERT = 1 << 3;
            vcert.FLAG_SIGN_MARK = 1 << 5;
            vcert.FLAG_INIT_NOCRLUPDATE = (1 << 0);
            vcert.FLAG_INIT_CHECKEXPIRED = (1 << 1);
            vcert.FLAG_INIT_NOLDAP=     (1 << 2);
            vcert.FLAG_INIT_NOKEYUNLOAD=(1 << 3);
            vcert.FLAG_INIT_CHECKEXPIRED_REQ = (1 << 4);
            vcert.FLAG_INIT_NOSAVECACHE = (1 << 5);
            vcert.FLAG_INIT_REGISTRY =    (1 << 6);
            vcert.FLAG_INIT_FORCE_CACHE_CERTS = (1 << 7);
            vcert.FLAG_INIT_ALLOW_RA_IN_CHAIN = (1 << 8);
            vcert.FLAG_INIT_LDAP_CHAIN_SEARCH = (1 << 9);
            vcert.FLAG_INIT_USE_AIA_CDP = (1 << 10);
            vcert.FLAG_INIT_SILENT_KEYLOAD = (1 << 11);
            vcert.FLAG_INIT_VERIFYCONTEXT = (0x80000000);
            /** Поле с серийным номером */
            vcert.FIELD_SERIAL = (1 << 0);
            /** Поле издателя сертификата */
            vcert.FIELD_ISSUER  = (1 << 1);
            /** Поле владельца сертификата */
            vcert.FIELD_SUBJECT = (1 << 2);
            vcert.FIELD_ISSUERUID = (1 << 3);
            vcert.FIELD_SUBJECTUID = (1 << 4);
            /** Поле с датой начала действия сертификата */
            vcert.FIELD_NOTBEFORE = (1 << 5);
            /** Поле с датой окончания действия сертификата */
            vcert.FIELD_NOTAFTER = (1 << 6);
            /** Поле с возможной областью применения сертификата */
            vcert.FIELD_KEYUSAGE = (1 << 7);
            /** Поле с альтернативным именем издателя сертификата */
            vcert.FIELD_ISSUERALTNAME = (1 << 8);
            /** Поле с альтернативным именем владельца сертификата */
            vcert.FIELD_SUBJECTALTNAME = (1 << 9);
            /** Поля с расширенной областью использования сертификата */
            vcert.FIELD_EXTKEYUSAGE = (1 << 10);
            /** Поля с политиками использования сертификата */
            vcert.FIELD_POLICY = (1 << 11);
            /** Поля с расширениями сертификата */
            vcert.FIELD_EXTENSIONS = (1 << 12);
            /** Поле с датой начала действия секретного ключа сертификата */
            vcert.FIELD_NOTBEFOREPRIVATE = (1 << 13);
            /** Поле с датой окончания действия секретного ключа сертификата */
            vcert.FIELD_NOTAFTERPRIVATE = (1 << 14);
            /** Поле с идентификатором ключа, соответствующего сертификату = (может использоваться для уникального выбора сертификата); */
            vcert.FIELD_KEYID = (1 << 15);
            /** Сертификат в DER-кодировке */
            vcert.FIELD_CERTENCODED = (1 << 16);
            /** Хэш сертификата = (может использоваться для уникального выбора сертификата); */
            vcert.FIELD_CERTHASH = (1 << 17);
            /** Алгоритм сертификата */
            vcert.FIELD_ALGORITHM = (1 << 18);
            /** Все поля сертификата */
            vcert.FIELD_ALL = (vcert.FIELD_SERIAL | vcert.FIELD_ISSUER | vcert.FIELD_SUBJECT | vcert.FIELD_ISSUERUID | vcert.FIELD_SUBJECTUID | vcert.FIELD_NOTBEFORE | vcert.FIELD_NOTAFTER | vcert.FIELD_KEYUSAGE | vcert.FIELD_ISSUERALTNAME | vcert.FIELD_SUBJECTALTNAME | vcert.FIELD_EXTKEYUSAGE | vcert.FIELD_POLICY | vcert.FIELD_EXTENSIONS | vcert.FIELD_NOTBEFOREPRIVATE | vcert.FIELD_NOTAFTERPRIVATE | vcert.FIELD_KEYID | vcert.FIELD_CERTENCODED | vcert.FIELD_CERTHASH | vcert.FIELD_ALGORITHM)
            vcert.FIELD_ALGORITHM_OID_FORMAT = (1 << 31)
            /** Удалить подпись при проверки ЭЦП */
            vcert.FLAG_VERIFY_DELSIGN = (1 << 2);
            /** Проверять использование ключа сертификата */
            vcert.FLAG_VERIFY_KEYUSAGE = (1 << 3);
            /** Проверять политику сертификата */
            vcert.FLAG_VERIFY_POLICY = (1 << 4);
            /** Проверять расширенное использование */
            vcert.FLAG_VERIFY_EXTKEYUSAGE = (1 << 5);
            /** Проверять расширения - не реализован
             vcert.FLAG_VERIFY_EXTENSIONS = (1 << 6); */
            /** Проверять минимальное количество ЭЦП */
            vcert.FLAG_VERIFY_MINSIGNS = (1 << 7);
            /** Показывать результат проверки ЭЦП - не реализован
             vcert.FLAG_VERIFY_RESULTUI = (1 << 8); */
            /** Не проверять вхождение в СОС - не реализован
             vcert.FLAG_VERIFY_NOCRLCHECK = (1 << 9); */
            /** Не проверять времена действия сертификата/СОС */
            vcert.FLAG_VERIFY_NOTIMECHECK = (1 << 10);
            /** Не добавлять автоматически сертификаты из сообщения в справочник */
            vcert.FLAG_VERIFY_NOCACHECERT = (1 << 11);
            /** Не использовать сертификаты из сообщения */
            vcert.FLAG_VERIFY_NOATTACHEDCERT = (1 << 12);
            /** Использовать время отзыва сертификата из СОС при проверке цепочки */
            vcert.FLAG_VERIFY_USEREVTIME = (1 << 13);
            /** Добавлять сертификат из подписанного PKCS7 в локальный справочник */
            vcert.FLAG_VERIFY_NOADDCER_FRM_PKCS7 = (1 << 14);
            /** Удалить все подписи */
            vcert.DELETE_ALL_SIGNS = (-1);
            /**< ЭЦП. */
            vcert.KEYUSAGE_DIGITAL_SIGNATURE = (1 << 0);
            /**< неотречение */
            vcert.KEYUSAGE_NON_REPUDIATION = (1 << 1);
            /**< шифрование ключа. */
            vcert.KEYUSAGE_KEY_ENCIPHERMENT = (1 << 2);
            /**< шифрование данных. */
            vcert.KEYUSAGE_DATA_ENCIPHERMENT = (1 << 3);
            /**< выработка сессионного ключа */
            vcert.KEYUSAGE_KEY_AGREEMENT = (1 << 4);
            /**< подпись сертификата. */
            vcert.KEYUSAGE_KEY_CERT_SIGN = (1 << 5);
            /**< подпись СОС. */
            vcert.KEYUSAGE_CRL_SIGN = (1 << 6);

            vcert.KEYUSAGE_ENCIPHER_ONLY = (1 << 7);

            vcert.KEYUSAGE_DECIPHER_ONLY = (1 << 8);
            /** Искать только сертификаты, для которых есть секретный ключ */
            vcert.FLAG_FIND_MY = (1 << 0);
            /** Искать все сертификаты = (deprecated - используется всегда)
             vcert.FLAG_FIND_ALL = (1 << 1) */
            /** Искать также в сетевом справочнике */
            vcert.FLAG_FIND_REMOTE = (1 << 2);
            /** Показывать диалог выбора сертификата при нахождении нескольких сертификатов (только для локальной криптографии) */
            vcert.FLAG_FIND_SELECTUI   = (1 << 3);
            /** Не проверять времена действия сертификата */
            vcert.FLAG_FIND_NOTIMECHECK = (1 << 4);
            /** Не проверять действительность сертификата */
            vcert.FLAG_FIND_NOVERIFY = (1 << 5);
            /** Не кэшировать поиск - не реализован
             vcert.FLAG_FIND_NOCACHE = (1 << 6) */
            /** Добавлять сертификат найденный в LDAP в локальный справочник */
            vcert.FLAG_FIND_ADD_LDAP2LOCAL = (1 << 7);
            /** Не проверять времена действия секретного ключа */
            vcert.FLAG_FIND_NOKEYTIMECHECK = (1 << 8);
            /** Имя субъекта сертификата в шаблоне ука;зано частично */
            vcert.FLAG_FIND_PARTIAL_SUBJECT = (1 << 9);
            /** Не выполнять поиск среди кэшированных объектов */
            vcert.FLAG_FIND_IGNORE_CACHED = (1 << 10);
            /** Не выполнять поиск в локальном справочнике */
            vcert.FLAG_FIND_IGNORE_LOCAL = (1 << 11);
            /** Имя субъекта сертификата в шаблоне должно совпадать с атрибутом LDAP */
            vcert.FLAG_FIND_SUBJECT_ATTRIBUTE = (1 << 12);
            /** Шифровать на все найденные сертификаты (== FLAG_FIND_ALL) (deprecated - используется всегда)
             vcert.FLAG_ENCRYPT_ALL = (1 << 1); */
            /** Искать сертификаты в LDAP справочнике = (== FLAG_FIND_REMOTE) */
            vcert.FLAG_ENCRYPT_REMOTE = (1 << 2);
            /** Показывать диалог выбора сертификата при нахождении нескольких сертификатов = (только для локальной криптографии) (== FLAG_FIND_SELECTUI) - не реализован
            vcert.FLAG_ENCRYPT_SELECTUI = (1 << 3) ;*/
            /** Не проверять времена действия сертификата (== FLAG_FIND_NOTIMECHECK) */
            vcert.FLAG_ENCRYPT_NOTIMECHECK = (1 << 4);
            /** Не проверять действительность сертификата (== FLAG_FIND_NOVERIFY) */
            vcert.FLAG_ENCRYPT_NOVERIFY = (1 << 5);
            /** Не кэшировать поиск (== FLAG_FIND_NOCACHE) - не реализован
             vcert.FLAG_ENCRYPT_NOCACHE = (1 << 6); */
            /** Добавлять сертификат найденный в LDAP в локальный справочник (== FLAG_FIND_ADD_LDAP2LOCAL) */
            vcert.FLAG_ENCRYPT_ADD_LDAP2LOCAL = (1 << 7);
            /** Не проверять времена действия секретного ключа = (== FLAG_FIND_NOKEYTIMECHECK) */
            vcert.FLAG_ENCRYPT_NOKEYTIMECHECK = (1 << 8);
            /** Имя субъекта сертификата в шаблоне указано частично = (== FLAG_FIND_PARTIAL_SUBJECT) */
            vcert.FLAG_ENCRYPT_PARTIAL_SUBJECT = (1 << 9);
            /** Не выполнять поиск среди кэшированных объектов = (== FLAG_FIND_IGNORE_CACHED) */
            vcert.FLAG_ENCRYPT_IGNORE_CACHED = (1 << 10);
            /** Не выполнять поиск в локальном справочнике; = (== FLAG_FIND_IGNORE_LOCAL) */
            vcert.FLAG_ENCRYPT_IGNORE_LOCAL = (1 << 11);
            /** Имя субъекта сертификата в шаблоне должно совпадать с атрибутом LDAP = (== FLAG_FIND_SUBJECT_ATTRIBUTE) */
            vcert.FLAG_ENCRYPT_SUBJECT_ATTRIBUTE = (1 << 12);
            /** Не кэшировать найденные сертификаты */
            vcert.FLAG_DECRYPT_NOCACHE=(1 << 1);
            /** Не проверять наличие сертификата отправителя в СОС */
            vcert.FLAG_DECRYPT_NOCRLCHECK=(1 << 2);
            /** Добавлять сертификат найденный в LDAP в локальный справочник (== FLAG_FIND_ADD_LDAP2LOCAL) */
            vcert.FLAG_DECRYPT_ADD_LDAP2LOCAL=(1 << 7);
            /** Не проверять отзыв сертификата - не реализован
             vcert.FLAG_POLICY_NOREVCHECK = (1 << 1); */
            /** Не проверять времена действия сертификата/СОС в цепочке */
            vcert.FLAG_POLICY_NOTIMECHECK = (1 << 2);
            /** Использовать время отзыва сертификата при проверке цепочки - не реализован
             vcert.FLAG_POLICY_USEREVTIME = (1 << 3); */
            /** Не выполнять обновление истекших объектов при проверке цепочки - не реализован
             vcert.FLAG_POLICY_NOONLINEUPDATE = (1 << 4); */
            /** Проверять подпись, срок действия и цепочку СОС */
            vcert.FLAG_POLICY_VERIFY_CRL = (1 << 5);
            /** Не проверять времена действия секретного ключа */
            vcert.FLAG_POLICY_NOKEYTIMECHECK = (1 << 6);
            /** Показывать пользовательский интерфейс */
            vcert.FLAG_EXPORT_UI = (1 << 0);
            /** Экспортировать в ASN.1 = (по умолчанию в PKCS#7); */
            vcert.FLAG_EXPORT_ASN1 = (1 << 1);
            /** Создать запрос на новый сертификат = (и сгенерировать ключ); */
            vcert.FLAG_EXPORT_NEWREQUEST = (1 << 2);
            /** Создать запрос на отзыв сертификата */
            vcert.FLAG_EXPORT_REVREQUEST = (1 << 3);
            /** Выполнить резервное копирование ключа после создания запроса */
            vcert.FLAG_EXPORT_BACKUPKEY = (1 << 4);
            /** Выполнить генерацию закрытого ключа с помощью СКЗИ КриптоПро eToken CSP */
            vcert.FLAG_EXPORT_ETOKEN_GOST = (1 << 5);
            /** Выполнить генерацию закрытого ключа с помощью СКЗИ КриптоПро Rutoken CSP */
            vcert.FLAG_EXPORT_RUTOKEN_GOST = (1 << 6);
            /** Выполнить генерацию закрытого ключа для квалифицированного сертификата по ГОСТ Р 34.10-2001 */
            vcert.FLAG_EXPORT_GOST_R_34_10_2001 = (1 << 7);
            /** Выполнить генерацию закрытого ключа для квалифицированного сертификата по ГОСТ Р 34.10-2012 */
            vcert.FLAG_EXPORT_GOST_R_34_10_2012 = (1 << 8); 
            /** Показывать пользовательский интерфейс */
            vcert.FLAG_IMPORT_UI = (1 << 0);
            /** Импортировать сертификат в DER-кодировке */
            vcert.FLAG_IMPORT_CERTIFICATE = (1 << 1);
            /** Импортировать СОС в DER-кодировке */
            vcert.FLAG_IMPORT_CRL = (1 << 2);
            /** Импортировать свой новый сертификат и установить как рабочий */
            vcert.FLAG_IMPORT_MY_CERTIFICATE = (1 << 3);
            /** Импортировать обновление от Центр Регистрации */
            vcert.FLAG_IMPORT_UPDATE = (1 << 4);
            /** Импортировать объекты из PKCS#7 - не реализован
             vcert.FLAG_IMPORT_PKCS7 = (1 << 5); */
             /** Использовать для генерации закрытого ключа запроса СКЗИ КриптоПро eToken CSP */
            vcert.FLAG_XML_REQUEST_ETOKEN_GOST = (1 << 1);
            /** Создать XML запрос без генерации нового закрытого ключа */
            vcert.FLAG_XML_REQUEST_DO_NOT_GENERATE = (1 << 2);
            /** Использовать для генерации закрытого ключа запроса СКЗИ КриптоПро Rutoken CSP */
            vcert.FLAG_XML_REQUEST_RUTOKEN_GOST = (1 << 3);
            /** Генерировать закрытый ключ запроса для квалифицированного сертификата по ГОСТ Р 34.10-2001 */
            vcert.FLAG_XML_REQUEST_GOST_R_34_10_2001 = (1 << 4);
            /** Генерировать закрытый ключ запроса для квалифицированного сертификата по ГОСТ Р 34.10-2012 */
            vcert.FLAG_XML_REQUEST_GOST_R_34_10_2012 = (1 << 5);
            vcert.FLAG_CREATE_REGISTRY_PROFILE_OVER =  (1 << 1);
            /** Добавлять NONCE в запрос на получение штампа времени */
            vcert.FLAG_TSP_REQUEST_ADD_NONCE        = (1 << 1);
            /** Запросить сертификат подписанта в запросе на получение штампа времени */
            vcert.FLAG_TSP_REQUEST_CERT_REQUEST     = (1 << 2);
            /** Добавлять имя TSA при подписывании запроса на получение штампа времени */
            vcert.FLAG_TSP_RESPONSE_ADD_TSA_NAME  =  (1 << 1);
            /** Не использовать сертификат из сообщения для проверки ЭЦП */
            vcert.FLAG_TSP_VERIFY_NOATTACHEDCERT   =  (1 << 1);

    }
    function initPrototypes(){
        function IssuerAndSerial(issuer, serialNumber){
            if(typeof(issuer) != "string" || typeof(serialNumber) !="string"){
                throw  new TypeError("Параметры должны быть строковыми");
            }
            this.issuer = issuer;
            this.serialNumber = serialNumber;
        }
        function Extension(oid,type,critical,data){
            this.oid = oid;        
            this.type = type;       
            this.critical = critical;          
            this.data = data;     
        }
        function AltName(){
            this.emailAddress = null;     
            this.DNS = null;              
            this.URI = null;             
            this.IP = null;              
            this.organizationName = null; 
            this.registredAddress = null;
            this.surname = null;         
            this.businessCategory = null;
            this.telephoneNumber = null;
            this.description = null;
            this.account_number = null;
            this.bank_id = null;
            this.physicalDelivery = null;
            this.exchange_address = null;
        }
        function Certificate(fields){
            this.fields = fields;         
            this.issuer = null;        
            this.serialNumber = null;  
            this.subject = null;       
            this.algorithm = null;      
            this.notBefore = null;     
            this.notAfter = null;      
            this.issuerUID = null;
            this.subjectUID = null;
            this.keyUsage = null;           
            this.notBeforePrivate = null;   
            this.notAfterPrivate = null;    
            this.issuerAltName = null;      
            this.subjectAltName = null;     
            this.keyId = null;                    
            this.policies = null;           
            this.extKeyUsage = null;         
            this.extensions = null;       
            this.certEncoded = null;        
            this.certHash = null;           
        }
        function CertID(ias, keyId, certHash){
            if((certHash != undefined && typeof(certHash != "string")) ||
                (ias != undefined && !(ias instanceof IssuerAndSerial)) ||
               ( keyId !=undefined && typeof(keyId))){
                throw  new TypeError("Неправильный тип параметров");
               }
            this.certHash = certHash;
            this.keyId = keyId;
            this.ias = ias;
        }
        function SignParam(flag = 0){
            if((typeof(flag) != "number" && (flag%1)===0)){
                throw  new TypeError("Неправильный тип параметров");
            }
             this.flag = flag;
             this.mycert = null;
        }
        function Policy(oid,org_name=null,text=null){
            if( (typeof(oid) != "string") || 
                (org_name !=null && typeof(org_name) != "string") ||
                (text != null && typeof(text) != string)){
                   throw  new TypeError("Неправильный тип параметров");
                }
                this.oid = oid;
                this.org_name = org_name;
                this.text = text;
        };
        function ExtKeyUsage(oid){
            if(typeof(oid) != "string"){
               throw  new TypeError("Неправильный тип параметров");
            }
            this.oid = oid;
        }
        function VerifyParam(flag =0, keyUsage = 0, policies = null, extKeyUsage = null, nSignToDelete= 0, minsigns = 0, info = 0){
            /*if(arguments.length != 8){
                throw Error("Не достаточно аргументов");
            }*/
            if( typeof(flag) != "number"                                ||
                typeof(keyUsage) != "number"                            ||
                (policies != null && !(Array.isArray(policies)))        ||
                (extKeyUsage != null && !(Array.isArray(extKeyUsage)))  ||
                typeof(nSignToDelete) != "number"                       ||
                typeof(minsigns) != "number"                            ||
                typeof(info) != "number"                                ){
                    throw  new TypeError("Неправильный тип параметров");
                }
           
            this.flag = flag;
            this.mycert =null;
            this.keyUsage = keyUsage;
            this.policies = policies;
            this.extKeyUsage = extKeyUsage;
            this.nSignToDelete = nSignToDelete;
            this.minsigns=minsigns;
            this.info = info;
        }
        function FindParam(certTemplate, flag = 0,  certInfo = 0){
            if((typeof(flag)!= "number") ||
                (typeof(certInfo)!= "number")){
                    throw  new TypeError("Неправильный тип параметров");
                }
            if(typeof(certTemplate.fields)!="number"){
                throw  new TypeError("Некорректно задан сертификат");
            }
                this.flag = flag;
                this.mycert = null;
                this.certTemplate = certTemplate;
                this.certInfo = certInfo;
        }
        function EncryptParam(recievers, flag = 0){
            if(!Array.isArray(recievers) || recievers.length == 0){
                throw  new TypeError("Некорректно заданы получатели");
            }
            if(typeof(flag) != "number"){
                throw  new TypeError("Неправильный тип параметров");
            }
            this.flag = flag;
            this.mycert = null;
            this.recievers = recievers;
        }
        function DecryptParam(flag = 0, info = 0){
            if(typeof(flag) != "number" || typeof(info) != "number" ){
                throw  new TypeError("Неправильный тип параметров");
            }
            this.flag = flag;
            this.mycert = null;
            this.info = info;
        }
        function VerifyPolicyParam(flag = 0,check_time = 0,keyusage = 0,extKeyUsage = null,policies = null){
             if( typeof(flag) != "number"                               ||
                typeof(check_time) != "number"                          ||
                (policies != null && !(Array.isArray(policies)))        ||
                (extKeyUsage != null && !(Array.isArray(extKeyUsage)))  ||
                typeof(keyusage) != "number"                            ){
                    throw  new TypeError("Неправильный тип параметров");
                }
            this.flag = flag;
            this.mycert =null;
            this.keyusage = keyusage;
            this.policies = policies;
            this.extkeyusages = extKeyUsage;
            this.check_time = check_time;
        }
        function ImportParam(flag = 0){
             if( typeof(flag) != "number"){
                    throw  new TypeError("Неправильный тип параметров");
                }
            this.flag = flag;
            this.mycert =null;
        }
        function ExportParam(flag = 0){
             if( typeof(flag) != "number"){
                    throw  new TypeError("Неправильный тип параметров");
                }
            this.flag = flag;
            this.mycert =null;
        }
        function TSPRequestParam(index,flag= 0){
            if( typeof(flag) != "number" ||
                (typeof(index) != "number")){
                    throw  new TypeError("Неправильный тип параметров");
            }
            this.flag = flag;
            this.mycert = null;
            this.index = index;

        }
        function TSPResponseParam(flag= 0){
             if( typeof(flag) != "number"){
                    throw  new TypeError("Неправильный тип параметров");
                }
                this.flag = flag;
                this.mycert = null;
        }
        function TSPVerifyParam(index, fields = 0,flag = 0){
            if( typeof(flag) != "number" ||
                (typeof(index) != "number")|| typeof(fields) != "number"){
                    throw  new TypeError("Неправильный тип параметров");
            }
            this.index = index;
            this.flag = flag;
            this.mycert = null;
            this.info = fields;
        }
        function OCSPRequestParam(flag = 0){
            if( typeof(flag) != "number"){
                throw  new TypeError("Неправильный тип параметров");
            }
            this.flag = 0;
            this.mycert = null;
        }
        function OCSPResponseParam(flag = 0){
            if( typeof(flag) != "number"){
                throw  new TypeError("Неправильный тип параметров");
            }
            this.flag = 0;
            this.mycert = null;
        }
        function OCSPVerifyParam(flag = 0, info){
            if( typeof(flag) != "number" || typeof(info) != "number"){
                throw  new TypeError("Неправильный тип параметров");
            }
            this.flag = 0;
            this.mycert = null;
            this.info = info;
        }
        vcert.AltName = AltName;
        vcert.Extension = Extension;
        vcert.IssuerAndSerial = IssuerAndSerial;
        vcert.Certificate = Certificate;
        //vcert.CertID = CertID;
        vcert.SignParam = SignParam;
        vcert.Policy = Policy;
        vcert.ExtKeyUsage = ExtKeyUsage;
        vcert.VerifyParam = VerifyParam;
        vcert.FindParam = FindParam;
        vcert.EncryptParam = EncryptParam;
        vcert.DecryptParam = DecryptParam;
        vcert.VerifyPolicyParam = VerifyPolicyParam;
        vcert.ImportParam = ImportParam;
        vcert.ExportParam = ExportParam;
        vcert.TSPRequestParam = TSPRequestParam;
        vcert.TSPResponseParam= TSPResponseParam;
        vcert.TSPVerifyParam = TSPVerifyParam;
        vcert.OCSPRequestParam = OCSPRequestParam;
        vcert.OCSPResponseParam = OCSPResponseParam;
        vcert.OCSPVerifyParam = OCSPVerifyParam;
        
    }
    function initAPI(){
          function sendMessage(msg, callback){
          if(tabID == null){
            return Promise.reject("Плагин не установлен или не отвечает");
          }
		  if(hostISDEAD){
			return Promise.reject(hostISDEADMSG);
		  }
            msg.tabID = tabID;
            msgID++;
            msg.msgID = msgID;
            var MSG = {
                direction: dir,
                message: msg
            }
            window.postMessage(MSG, "*");
            delete MSG.message;
            return new Promise(function(resolve, reject){
            msgL[msgID] = function(event){
            var localID = msgID; //иначе принимает не правильное значение
            
                if (event.source == window && event.data.direction && event.data.direction == "fromVCERT_plugin" && event.data.msg && (event.data.msg.msgID == localID || event.data.msg.disconnected)){            
                    event.stopPropagation();
                    event.preventDefault();
					if(event.data.msg.disconnected){
						hostISDEAD = true;
						hostISDEADMSG = {text: "native host disconnected", code:-1};
						reject(hostISDEADMSG);
					}
                    else if(event.data.msg.result.status == "error"){
                       //console.log(JSON.stringify(event.data));
                        reject(event.data.msg.result);
						window.removeEventListener("message", msgL[msgID]);
                    }else if(event.data.msg.result.status == "OK"){
                    //console.log(JSON.stringify(event.data));
                        resolve(event.data.msg.result);
						window.removeEventListener("message", msgL[msgID]);
                    }else if(event.data.msg.result.status == "update"){
						if(typeof callback === 'function')callback(event.data.msg.result);
					}
                    

                }
            }
            window.addEventListener("message",msgL[msgID]);
            });
        };
        function Initialize(p = "MY", f = 0) {
            if (typeof (p) != "string") {
                throw  new TypeError("Имя профиля должно быть строкой");
            }
            if (typeof (f) != "number") {
                throw  new TypeError("flag должен быть числом");
            }
            var msg = {
                vcert_call: "Init",
                params: { profile: p, flag: f }
            };
            return sendMessage(msg); 
       
        };
        function Deinitialize(){
            var msg = {
                vcert_call: "DeInit",
                params: {temp: 0}
            }
            return sendMessage(msg);
        }    
        function Sign(sp, base64data, base64sign, df = 0) {
            if(!(sp instanceof vcert.SignParam)){
                throw  new TypeError("Параметры для подписи должны быть типа vcert.SignParam");
            }
            if(base64sign == undefined && (base64data == null || base64data == undefined)){
                throw  new Error("Необходимо указать данные для подписи");
            }
            if( (base64data != null || base64data != undefined) && typeof(base64data) != "string"){
                throw  new Error("Данные должны быть строкой");
            }
            if(base64sign != undefined && typeof(base64sign) != "string"){
                throw  new Error("Данные должны быть строкой");
            }
            var msg = {
                vcert_call: "SignMem",
                params: { data: base64data, data_type:df,sign_param: sp, sign_in : base64sign}
            };
            return sendMessage(msg);    
        };
        function Hash(base64data, df = 0){
             if(base64data == undefined || base64data == null){
            throw  new Error("Необходимо указать данные для подписи в base64 формате");
        }
            if(typeof(base64data) != "string"){
                throw  new Error("Данные должны быть в base64 формате");
            }
            var msg = {
                vcert_call: "HashMem",
                params: { data: base64data, data_type: df }
            };
            return sendMessage(msg);    
        };
        function Verify(vp,base64data,base64signin, df = 0, of = 0){
            if(!(vp instanceof vcert.VerifyParam)                           || 
                ((base64data != null) && (typeof(base64data) != "string"))      ||
                (typeof(base64signin) != "string")  ){
                    throw  new TypeError("Неправильный тип параметра");
                }
                var msg = {
                vcert_call: "VerifyMem",
                params: { data: base64data, data_type:df, verify_param : vp, sign_in : base64signin, out_type: of}
                }
                return sendMessage(msg);
        }
        function VerifyAndSign(vp,sp, base64data, base64signin, df = 0){
            if( !(vp instanceof vcert.VerifyParam)                              ||
                !(sp instanceof vcert.SignParam)                                || 
                ((base64data != null) && (typeof(base64data) != "string"))      ||
                (typeof(base64signin) != "string")  ){
                    throw  new TypeError("Неправильный тип параметра");
                }
                var msg = {
                vcert_call: "VerifyAndSignMem",
                params: { data: base64data,data_type:df,verify_param : vp,sign_param:sp, sign_in : base64signin}
                }
                return sendMessage(msg);            
        }
        function SignedInfo(base64data){
            if(typeof(base64data) != "string"){
                    throw  new TypeError("Данные должны быть в base64 формате");
                }
                var msg = {
                vcert_call: "SignedInfoMem",
                params: { data: base64data}
                }
                return sendMessage(msg);
        }
        function FindCert(fp){
            if(!fp instanceof vcert.FindParam){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "FindCert",
                params: { find_param: fp}
                }
                return sendMessage(msg);
        }
        function Encrypt(ep,base64data, df = 0){
            if(!(ep instanceof vcert.EncryptParam)||
                typeof(base64data) != "string"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "EncryptMem",
                params: { encrypt_param: ep, data: base64data, data_type: df}
                }
            return sendMessage(msg);
        }
        function Decrypt(dp, base64data, of = 0){
            if(!(dp instanceof vcert.DecryptParam)||
                typeof(base64data) != "string"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "DecryptMem",
                params: { decrypt_param: dp, data: base64data, out_type: of}
                }
            return sendMessage(msg);
        }
        function EncryptedInfo(base64data){
            if(typeof(base64data) != "string"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "EncryptInfoMem",
                params: {data: base64data}
                }
            return sendMessage(msg);
        }
        function SignAndEncrypt(sp,ep,base64data, df = 0){
            if(!(sp instanceof vcert.SignParam) || !(ep instanceof vcert.EncryptParam) || typeof(base64data)!="string"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "SignAndEncryptMem",
                params: {sign_param:sp,encrypt_param:ep,data: base64data,data_type: df}
                }
            return sendMessage(msg);
        }
        function DecryptAndVerify(dp,vp, base64data, of = 0){
            if(!(dp instanceof vcert.DecryptParam) || !(vp instanceof vcert.VerifyParam) || typeof(base64data)!="string"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "DecryptAndVerifyMem",
                params: {decrypt_param:dp,verify_param:vp,data: base64data, out_type : df}
                }
            return sendMessage(msg);
        }
        function StreamHashInit(al){
            if(typeof(al)!="string"){
                throw  new TypeError("Неверный формат параметров");
            }
             var msg = {
                vcert_call: "StrHashInitMem",
                params: {algorithm: al}
                }
            return sendMessage(msg);
        }
        function StreamHashUpdate(id, base64data){
            if(typeof(base64data)!="string" || typeof(id)!="number"){
                throw  new TypeError("Неверный формат параметров");
            }
             var msg = {
                vcert_call: "StrHashUpdateMem",
                params: {data: base64data,context: id}
                }
            return sendMessage(msg);
        }
        function StreamHashFinal(id){
            if(typeof(id)!="number"){
                throw  new TypeError("Неверный формат параметров");
            }
             var msg = {
                vcert_call: "StrHashFinalMem",
                params: {context: id}
                }
            return sendMessage(msg);
        }
        function StreamSignInit(sp,base64sign = null){
            if(!(sp instanceof vcert.SignParam) || (base64sign!=null && typeof(base64sign) != "string")){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "StrSignInitMem",
                params: {sign_param: sp, sign_in: base64sign}
                }
            return sendMessage(msg);
        }
        function StreamSignUpdate(id, sp, base64data){
             if(!(sp instanceof vcert.SignParam) || (base64data!=null && typeof(base64data) != "string") || typeof(id) != "number"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "StrSignUpdateMem",
                params: {sign_param: sp, data: base64data, context: id}
                }
            return sendMessage(msg);
        }
        function StreamSignFinal(id,sp){
            if(typeof(id) != "number" || !(sp instanceof vcert.SignParam)){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "StrSignFinalMem",
                params: {context: id, sign_param : sp}
                }
            return sendMessage(msg);
        }
        function StreamVerifyInit(vp, base64sign){
            if(!(vp instanceof vcert.VerifyParam) ||typeof(base64sign) != "string"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "StrVerifyInitMem",
                params: {verify_param : vp, sign_in : base64sign}
                }
            return sendMessage(msg);
        }
        function StreamVerifyUpdate(id,vp,base64data){
            if(!(vp instanceof vcert.VerifyParam) || (base64data!=null && typeof(base64data) != "string") || typeof(id) != "number"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "StrVerifyUpdateMem",
                params: {context: id, verify_param : vp, data: base64data}
                }
            return sendMessage(msg);
        }
        function StreamVerifyFinal(id, vp){
            if(!(vp instanceof vcert.VerifyParam) || typeof(id) != "number"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "StrVerifyFinalMem",
                params: {context: id, verify_param : vp}
                }
            return sendMessage(msg);
        }
        function StreamEncryptInit(ep){
            if(!(ep instanceof vcert.EncryptParam)){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "StrEncryptInitMem",
                params: {encrypt_param : ep}
                }
            return sendMessage(msg);
        }
        function StreamEncryptUpdate(id,ep,base64data){
        if(!(ep instanceof vcert.EncryptParam) || typeof(id) != "number" || typeof(base64data) != "string"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "StrEncryptUpdatetMem",
                params: {context: id, encrypt_param : ep, data: base64data}
                }
            return sendMessage(msg);
        }
        function StreamEncryptFinal(id,ep){
            if(!(ep instanceof vcert.EncryptParam) || typeof(id) != "number"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "StrEncryptFinalMem",
                params: {context: id, encrypt_param : ep}
                }
            return sendMessage(msg);
        }
        function StreamDecryptInit(dp,base64data){
             if(!(dp instanceof vcert.DecryptParam) || typeof(base64data) != "string"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "StrDecryptInitMem",
                params: {decrypt_param : dp, data:base64data}
                }
            return sendMessage(msg);
        }
        function StreamDecryptUpdate(id,dp,base64data){
            if(!(dp instanceof vcert.DecryptParam) || typeof(id) != "number" || typeof(base64data) != "string"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "StrDecryptUpdateMem",
                params: {context: id, decrypt_param : dp, data: base64data}
                }
            return sendMessage(msg);
        }
        function StreamDecryptFinal(id,dp){
            if(!(dp instanceof vcert.DecryptParam) || typeof(id) != "number"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "StrDecryptFinalMem",
                params: {context: id, decrypt_param : dp}
                }
            return sendMessage(msg);
        }
        function StreamEncryptInfo(base64data){
            if(typeof(base64data) != "string"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "StrEncryptInfoMem",
                params: {data: base64data}
                }
            return sendMessage(msg);
        }
        function SignHash(base64data){
            if(typeof(base64data) != "string"){
                throw  new TypeError("Неверный формат параметров");
            }
             var msg = {
                vcert_call: "SignHashMem",
                params: {data: base64data, mycerFindParamt: null}
                }
            return sendMessage(msg); 
        }
        function VerifyHash(sender, hash, sign){
            if(typeof(sender) != "string" || typeof(hash) != "string" || typeof(sign) != "string"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "VerifyHashMem",
                params: {sender: sender, data:hash, sign : sign, mycert : null}
            }
            return sendMessage(msg);
        }
        function VerifyCert(vp,base64data){
            if(typeof(base64data) != "string" || !(vp instanceof vcert.VerifyParam)){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "VerifyCert",
                params: {data: base64data, verify_param : vp}
                }
            return sendMessage(msg);
        }
        function VerifyCertificatePolicy(vp,base64data){
            if(typeof(base64data) != "string" || !(vp instanceof vcert.VerifyPolicyParam)){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "VerifyCertPolicy",
                params: {data: base64data, verify_param : vp}
                }
             return sendMessage(msg);   
        }
         function VerifyCrlPolicy(vp,base64data){
            if(typeof(base64data) != "string" || !(vp instanceof vcert.VerifyPolicyParam)){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "VerifyCrlPolicy",
                params: {data: base64data, verify_param : vp}
                }
             return sendMessage(msg);   
        }
        function DetachSign(base64data,of = 0){
            if(typeof(base64data) != "string"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "DetachSignMem",
                params: {data: base64data, out_type: of}
                }
            return sendMessage(msg);
        }
        function AttachSign(base64data, base64sign, df =0){
            if(typeof(base64data) != "string" || typeof(base64sign) != "string"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "AttachSignMem",
                params: {data: base64data, sign:base64sign,data_type:df}
                }
            return sendMessage(msg);
        }
        function Import(ip,base64data){
            if(typeof(base64data) != "string" || !(ip instanceof vcert.ImportParam)){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "ImportMem",
                params: {data: base64data, import_param:ip}
                }
            return sendMessage(msg);
        }
        function Export(ep){
            if(!(ep instanceof vcert.ExportParam)){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "ExportMem",
                params: {export_param:ep}
                }
            return sendMessage(msg);
        }
        function ParseCert(flag, base64data){
            if(typeof(base64data) != "string" || typeof(flag) != "number"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "ParseCert",
                params: {data: base64data, info:flag}
                }
            return sendMessage(msg);
        }
        function ParseCrl(flag, base64data){
            if(typeof(base64data) != "string" || typeof(flag) != "number"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "ParseCrl",
                params: {data: base64data, info:flag}
                }
            return sendMessage(msg);
        }
        function ParseAltnameEx(base64data){
            if(typeof(base64data) != "string"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "ParseAltnameEx",
                params: {data: base64data}
                }
            return sendMessage(msg);
        }
        function ParseBasicConstraintsEx(base64data){
            if(typeof(base64data) != "string"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "ParseBasicConstraintsEx",
                params: {data: base64data}
                }
            return sendMessage(msg);
        }
        function getPKCS7Info(base64data){
            if(typeof(base64data) != "string"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "GetPKCS7InfoMem",
                params: {data: base64data}
                }
            return sendMessage(msg);
        }
        function CheckExpiringCertKey(d){
            if(typeof(d) != "number"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "CheckExpiringCertKey",
                params: {days: d}
                }
            return sendMessage(msg);   
        }
        function ShowCertificate(base64data){
            if(typeof(base64data) != "string"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "ShowCertificate",
                params: {data: base64data}
                }
            return sendMessage(msg);
        }
        function ShowCRL(base64data){
            if(typeof(base64data) != "string"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "ShowCrl",
                params: {data: base64data}
                }
            return sendMessage(msg);
        }
        function UpdateCRLs(){
            var msg = {
                vcert_call: "UpdateCrls",
                params: {}
                }
            return sendMessage(msg);
        }
        function CreateXmlRequest(flag,cert){
            if(typeof(cert.fields)!="number"){
                throw  new TypeError("Некорректно задан сертификат");
            }
            if(typeof(flag) != "number"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "CreateXmlRequest",
                params: {flag:flag,template : cert}
                }
            return sendMessage(msg);
        }
        function IssueCertificateStore(base64data, localstore){
            if(typeof(base64data) != "string" || typeof(localstore) != "string"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "IssueCertificateStore",
                params: {data: base64data, localstore : localstore}
                }
            return sendMessage(msg);
        }
        function CreateCertificateStore(certs,crls){
            if (!(Array.isArray(certs)) || !(Array.isArray(crls))){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "CreateCertificateStore",
                params: {certs: certs,crls:crls}
                }
            return sendMessage(msg);
        }
        function CreateRegistryProfile(profile,psestore,flag){
            if(typeof(profile) != "string" || typeof(flag) != "number"|| typeof(psestore) != "string"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "CreateRegistryProfile",
                params: {profile: profile, psestore:psestore,flag:flag}
            }
            return sendMessage(msg);
        }
        function QueryRegistryProfile(index){
            if(typeof(index) != "number"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "QueryRegistryProfile",
                params: {index:index}
            }
            return sendMessage(msg);
        }
        function TspRequestFromPkcs7(rp,base64data){
            if(!(rp instanceof vcert.TSPRequestParam) || typeof(base64data) != "string"){
                 throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "TspRequestFromPkcs7",
                params: {request_param : rp, data : base64data}
            }
            return sendMessage(msg);
        }
        function TspSignResponse(rp, base64data){
            if(!(rp instanceof vcert.TSPResponseParam) || typeof(base64data) != "string"){
                 throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "TspSignResponse",
                params: {response_param : rp, data : base64data}
            }
            return sendMessage(msg);
        }
        function TspVerifyResponse(vp,base64data){
            if(!(vp instanceof vcert.TSPVerifyParam) || typeof(base64data) != "string"){
                 throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "TspVerifyResponse",
                params: {verify_param : vp, data : base64data}
            }
            return sendMessage(msg);
        }
        function TspUrlStampPkcs7(rp,base64data,url){
            if(!(rp instanceof vcert.TSPRequestParam) || typeof(base64data) != "string" || typeof(url)!="string"){
                 throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "TspUrlStampPkcs7Mem",
                params: {request_param : rp, data : base64data, url:url}
            }
            return sendMessage(msg);
        }
        function TspVerifyPkcs7(vp,base64data){
            if(!(vp instanceof vcert.TSPVerifyParam) || typeof(base64data) != "string"){
                 throw  new TypeError("Неверный формат параметров");
            }
             var msg = {
                vcert_call: "TspVerifyPkcs7Mem",
                params: {verify_param : vp, data : base64data}
            }
            return sendMessage(msg);
        }
        function OcspCreateRequest(rp, base64data){
            if(!(rp instanceof vcert.OCSPRequestParam) || typeof(base64data) != "string"){
                 throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "OcspCreateRequest",
                params: {request_param : rp, data : base64data}
            }
            return sendMessage(msg);
        }
        function OcspSignResponse(rp, base64data){
            if(!(rp instanceof vcert.OCSPResponseParam) || typeof(base64data) != "string"){
                 throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "OcspSignResponse",
                params: {response_param : rp, data : base64data}
            }
            return sendMessage(msg);
        }
        function OcspUrlObtainResponse(rp,base64data,url){
             if(!(rp instanceof vcert.OCSPRequestParam) || typeof(base64data) != "string" || typeof(url) != "string"){
                 throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "OcspUrlObtainResponse",
                params: {request_param : rp, data : base64data, url : url}
            }
            return sendMessage(msg);
        }
        function OcspVerifyResponse(vp,base64data){
            if(!(vp instanceof vcert.OCSPVerifyParam) || typeof(base64data) != "string"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "OcspVerifyResponse",
                params: {verify_param : vp, data : base64data}
            }
            return sendMessage(msg);
        }
        function GetErrorText(code){
            if(typeof(code) != "number"){
                throw  new TypeError("Неверный формат параметров");
            }
            var msg = {
                vcert_call: "GetErrorText",
                params: {code: code}
            }
            return sendMessage(msg);
        }
		
		function TestCall(){
            
            var msg = {
                vcert_call: "testCall",
                params: {msg: "test test test"}
            }
            return sendMessage(msg);
        }
		
        function ping(text){
             var msg = {
                vcert_call: "ping",
                params: {text : text}
            };
            return sendMessage(msg);
        }
		
		function getModules(){
			var msg = {
				vcert_call: "GetLoadedModules",
				params:{msg:"lul"}
				
			};
			return sendMessage(msg);
		}
		function getClientsideFile(name){
			if(typeof(name) != "string"){
				throw  new TypeError("Неверный формат параметров");
			}
			var msg = {
				vcert_call: "GetClientsideFile",
				params:{name:name}
				
			};
			return sendMessage(msg);
		}
        function _base64ToUInt8Array(base64) {
            var binary_string =  window.atob(base64);
            var len = binary_string.length;
            var bytes = new Uint8Array( len );
            for (var i = 0; i < len; i++)        {
                bytes[i] = binary_string.charCodeAt(i);
            }
            return bytes;
         }
         function base64toBlob(base64Data) {
            contentType = '';
            var sliceSize = 1024;
            var byteCharacters = atob(base64Data);
            var bytesLength = byteCharacters.length;
            var slicesCount = Math.ceil(bytesLength / sliceSize);
            var byteArrays = new Array(slicesCount);

            for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
                var begin = sliceIndex * sliceSize;
                var end = Math.min(begin + sliceSize, bytesLength);

                var bytes = new Array(end - begin);
                for (var offset = begin, i = 0 ; offset < end; ++i, ++offset) {
                    bytes[i] = byteCharacters[offset].charCodeAt(0);
                }
                byteArrays[sliceIndex] = new Uint8Array(bytes);
            }
            return new Blob(byteArrays, { type: contentType });
        }
        vcert.initialize = Initialize;
        vcert.sign = Sign;
        vcert.hash = Hash;
        vcert.verify = Verify;
        vcert.verifyAndSign =  VerifyAndSign;
        vcert.signedInfo = SignedInfo;
        vcert.findCert = FindCert;
        vcert.encrypt = Encrypt;
        vcert.decrypt = Decrypt;
        vcert.encryptedInfo = EncryptedInfo;
        vcert.signAndEncrypt = SignAndEncrypt;
        vcert.decryptAndVerify = DecryptAndVerify;
        /*vcert.streamHashInit = StreamHashInit;
        vcert.streamHashUpdate = StreamHashUpdate;
        vcert.streamHashFinal = StreamHashFinal;
        vcert.streamSignInit =StreamSignInit;
        vcert.streamSignUpdate = StreamSignUpdate;
        vcert.streamSignFinal = StreamSignFinal;
        vcert.streamVerifyInit = StreamVerifyInit;
        vcert.streamVerifyUpdate = StreamVerifyUpdate;
        vcert.streamVerifyFinal = StreamVerifyFinal;
        vcert.streamEncryptInit = StreamEncryptInit;
        vcert.streamEncryptUpdate = StreamEncryptUpdate;
        vcert.streamEncryptFinal = StreamEncryptFinal;
        vcert.streamDecryptInit = StreamDecryptInit;
        vcert.streamDecryptUpdate = StreamDecryptUpdate;
        vcert.streamDecryptFinal = StreamDecryptFinal;
        vcert.streamEncryptInfo = StreamEncryptInfo;*/
        vcert.signHash = SignHash;
        vcert.verifyCert = VerifyCert;
        vcert.verifyCertificatePolicy = VerifyCertificatePolicy;
        vcert.verifyCrlPolicy = VerifyCrlPolicy;
        vcert.detachSign = DetachSign;
        vcert.attachSign = AttachSign;
        vcert.import = Import;
        vcert.export = Export;
        vcert.parseCert = ParseCert;
        vcert.parseCrl = ParseCrl;
        vcert.parseAltnameEx = ParseAltnameEx;
        vcert.parseBasicConstraintsEx = ParseBasicConstraintsEx;
        vcert.getPKCS7Info = getPKCS7Info;
        //vcert.checkExpiringCertKey = CheckExpiringCertKey;
        vcert.showCertificate = ShowCertificate;
        vcert.showCRL = ShowCRL;
		vcert.queryRegistryProfile = QueryRegistryProfile;
      /*  vcert.createXmlRequest = CreateXmlRequest;
        vcert.issueCertificateStore = IssueCertificateStore;
        vcert.createCertificateStore = CreateCertificateStore;
        
        vcert.tspRequestFromPkcs7 = TspRequestFromPkcs7;
        vcert.tspSignResponse = TspSignResponse;
        vcert.tspVerifyResponse = TspVerifyResponse;
        vcert.tspUrlStampPkcs7 = TspUrlStampPkcs7;
        vcert.tspVerifyPkcs7 = TspVerifyPkcs7;
        vcert.ocspCreateRequest = OcspCreateRequest;
        vcert.ocspSignResponse = OcspSignResponse;
        vcert.ocspUrlObtainResponse = OcspUrlObtainResponse;
        vcert.ocspVerifyResponse = OcspVerifyResponse;*/
        vcert.ping = ping;
        vcert.base64ToUInt8Array = _base64ToUInt8Array;
        vcert.base64ToBlob = base64toBlob;
        vcert.deinitialize = Deinitialize;
        vcert.verifyHash = VerifyHash;
        vcert.getErrorText = GetErrorText;
		vcert.testCall = TestCall;
		vcert.getModules = getModules;
		vcert.sendMessage = sendMessage;
		vcert.getClientsideFile = getClientsideFile;
    }
    function initErrors(){
        vcert.VCERT_E_GENERIC                        = 0xE0700001
        vcert.VCERT_E_INVALID_PARAMETER              = 0xE0700002
        vcert.VCERT_E_INVALID_CONTEXT                = 0xE0700003
        vcert.VCERT_E_OPERATION_NOT_SUPPORTED        = 0xE0700004
        vcert.VCERT_E_INVALID_FLAG                   = 0xE0700005
        vcert.VCERT_E_NO_MEMORY                      = 0xE0700006
        vcert.VCERT_E_HASH                           = 0xE0700007
        vcert.VCERT_E_CERT_USAGE                     = 0xE0700008
        vcert.VCERT_E_CERT_FIND_PRIVATE_KEY          = 0xE0700009
        vcert.VCERT_E_PKCS7_SET_TYPE                 = 0xE070000A
        vcert.VCERT_E_PKCS7_ADD_SIGNATURE            = 0xE070000C
        vcert.VCERT_E_PKCS7_ADD_CERTIFICATE          = 0xE070000D
        vcert.VCERT_E_PKCS7_CONTENT_NEW			     = 0xE070000E
        vcert.VCERT_E_PKCS7_D2I                      = 0xE070000F
        vcert.VCERT_E_PKCS7_I2D                      = 0xE0700010
        vcert.VCERT_E_PKEY_NOT_GOST                  = 0xE0700011
        vcert.VCERT_E_SIGN                           = 0xE0700012
        vcert.VCERT_E_VERIFY_POLICY                  = 0xE0700013
        vcert.VCERT_E_VERIFY_EXTKEYUSAGE             = 0xE0700014
        vcert.VCERT_E_PKCS7_READ                     = 0xE0700015
        vcert.VCERT_E_OVERFLOW                       = 0xE0700016
        vcert.VCERT_E_REQ_DAMAGED                    = 0xE0700017
        vcert.VCERT_E_REVREQ_DAMAGED                 = 0xE0700018
        vcert.VCERT_E_VERIFY                         = 0xE0700019
        vcert.VCERT_E_VERIFY_FORMAT                  = 0xE070001A
        vcert.VCERT_E_DELETE_SIGN                    = 0xE070001B
        vcert.VCERT_E_PKCS7_SET_CIPHER               = 0xE070001D
        vcert.VCERT_E_PKCS7_SET_CIPHER_INFO          = 0xE070001E
        vcert.VCERT_E_PKCS7_ADD_RECIPIENT            = 0xE070001F
        vcert.VCERT_E_PKCS7_ENCRYPT                  = 0xE0700020
        vcert.VCERT_E_ENCRYPT                        = 0xE0700021
        vcert.VCERT_E_PKCS7_WRONG_TYPE               = 0xE0700022
        vcert.VCERT_E_EVP_GET_CIPHER                 = 0xE0700023
        vcert.VCERT_E_DECRYPT_NO_RECIPIENTS          = 0xE0700024
        vcert.VCERT_E_DECRYPT_NO_SENDER              = 0xE0700025
        vcert.VCERT_E_DECRYPT_NO_RECIPIENT           = 0xE0700026
        vcert.VCERT_E_PKCS7_DECRYPT                  = 0xE0700027
        vcert.VCERT_E_DECRYPT                        = 0xE0700028
        vcert.VCERT_E_RANDOM                         = 0xE0700029
        vcert.VCERT_E_OPEN_CONFIG                    = 0xE071002A
        vcert.VCERT_E_READ_CONFIG                    = 0xE071002B
        vcert.VCERT_E_NO_DEFAULT_CONFIG              = 0xE071002C
        vcert.VCERT_E_OPEN_PSE                       = 0xE070002D
        vcert.VCERT_E_OPEN_LOCALSTORE                = 0xE070002E
        vcert.VCERT_E_VERIFY_STORE_USAGE             = 0xE070002F
        vcert.VCERT_E_VERIFY_STORE                   = 0xE0700030
        vcert.VCERT_E_OPEN_LDAPSTORE                 = 0xE0700031
        vcert.VCERT_E_NO_STREAM_DATA                 = 0xE0700032
        vcert.VCERT_E_BAD_STREAM_EOC                 = 0xE0700033
        vcert.VCERT_E_VERIFY_CERT                    = 0xE0700034
        vcert.VCERT_E_CERT_MISSING                   = 0xE0700035
        vcert.VCERT_E_CERT_EXPIRED                   = 0xE0700036
        vcert.VCERT_E_CERT_DAMAGED                   = 0xE0700037
        vcert.VCERT_E_CERT_BROKEN_CONSTRAINT         = 0xE0700038
        vcert.VCERT_E_CERT_REVOKED                   = 0xE0700039
        vcert.VCERT_E_CERT_UNTRUSTED                 = 0xE070003A
        vcert.VCERT_E_CRL_MISSING                    = 0xE070003B
        vcert.VCERT_E_CRL_EXPIRED                    = 0xE070003C
        vcert.VCERT_E_CRL_DAMAGED                    = 0xE070003D
        vcert.VCERT_E_CERT_BROKEN_HIERARCHY          = 0xE070003E
        vcert.VCERT_E_CHAIN_ERROR                    = 0xE070003F
        vcert.VCERT_E_TOO_MUCH_CERT                  = 0xE0700040
        vcert.VCERT_E_INVALID_USAGE                  = 0xE0700041
        vcert.VCERT_E_INVALID_SIGNATURE              = 0xE0700042
        vcert.VCERT_E_OPENKEY_NOT_FOUND              = 0xE0700043
        vcert.VCERT_E_SELFCHECK                      = 0xE0700044
        vcert.VCERT_E_UPDATECRL                      = 0xE0700045
        vcert.VCERT_E_CERT_NOT_FOUND                 = 0xE0700046
        vcert.VCERT_E_CERT_NOT_YET_VALID             = 0xE0700047
        vcert.VCERT_E_REQ_NOT_FOUND                  = 0xE0700048
        vcert.VCERT_E_PKCS7_WRONG_ALGORITHM          = 0xE0700049
        vcert.VCERT_E_KEY_EXPIRED                    = 0xE070004C
        vcert.VCERT_E_KEY_NOT_YET_VALID              = 0xE070004D
        vcert.VCERT_E_CRL_NOT_YET_VALID              = 0xE070004E
        vcert.VCERT_E_INIT_CSP                       = 0xE070004F
        vcert.VCERT_E_SIGN_INIT                      = 0xE0700053
        vcert.VCERT_E_CRYPTO_INIT                    = 0xE0700054
        vcert.VCERT_E_OPEN_PROFILES_KEY              = 0xE070005F
        vcert.VCERT_E_FIND_SESSION                   = 0xE0700081
        vcert.VCERT_E_SIGNLEN                        = 0xE0700082
        vcert.VCERT_E_DECRYPT_FORMAT                 = 0xE0700083
        vcert.VCERT_E_ADD_OBJECT                     = 0xE0700087
        vcert.VCERT_E_TOO_MANY_CERTS_FOUND           = 0xE0720089
        vcert.VCERT_E_USER_CANCEL                    = 0xE072008A
        vcert.VCERT_E_OPEN_INFILE                    = 0xE070008B
        vcert.VCERT_E_OPEN_OUTFILE                   = 0xE070008C
        vcert.VCERT_E_READ_FILE                      = 0xE070008D
        vcert.VCERT_E_WRITE_FILE                     = 0xE070008E
        vcert.VCERT_E_FILE_LENGTH                    = 0xE070008F
        vcert.VCERT_E_FILE_MAPPING                   = 0xE0700090
        vcert.VCERT_E_DELETE_OBJECT                  = 0xE0700091
        vcert.VCERT_E_INSUFFICIENT_SIGNS             = 0xE0700092
        vcert.VCERT_E_VERIFY_CRL                     = 0xE0700093
        vcert.VCERT_E_GET_PUBKEY                     = 0xE0700094
        vcert.VCERT_E_REQ_NEW                        = 0xE0700098
        vcert.VCERT_E_REQ_SIGN                       = 0xE070009A
        vcert.VCERT_E_MAKE_REVREQ                    = 0xE070009B
        vcert.VCERT_E_SIGN_REVREQ                    = 0xE070009C
        vcert.VCERT_E_LOAD_PRIVATE_KEY               = 0xE070009D
        vcert.VCERT_E_ADD_SIGNER                     = 0xE070009E
        vcert.VCERT_E_PKCS7_DATA_INIT                = 0xE070009F
        vcert.VCERT_E_BIO_WRITE                      = 0xE07000A0
        vcert.VCERT_E_OPEN_IDP                       = 0xE07000A1
        vcert.VCERT_E_READ_IDP                       = 0xE07000A2
        vcert.VCERT_E_SESSIONCORRUPTED               = 0xE07000A3
        vcert.VCERT_E_MAC                            = 0xE07000A4
        vcert.VCERT_E_CRYPT                          = 0xE07000A5
        vcert.VCERT_E_SPOOFING                       = 0xE07000A6
        vcert.VCERT_E_CERTDONOTMATCH                 = 0xE07000A7
        vcert.VCERT_E_XMLINIT                        = 0xE07000A8
        vcert.VCERT_E_INVALID_CREDENTIALS            = 0xE02000A8
        vcert.VCERT_E_ACCESS_DENIED                  = 0xE02000A9
        vcert.VCERT_E_SESSION_BLOCKED                = 0xA02000AA
        vcert.VCERT_E_CLIENT_INFO                    = 0xE02000AB
        vcert.VCERT_E_UNSECURE_CREDENTIALS           = 0xE02000AC
        vcert.VCERT_E_RESIGN_PROHIBIT                = 0xE02000AD
        vcert.VCERT_E_TSP_HASH_LENGTH                = 0xE0700100
        vcert.VCERT_E_TSP_HASH_ALGORITHM             = 0xE0700101
        vcert.VCERT_E_TSP_CERT_PURPOSE               = 0xE0700102
        vcert.VCERT_E_TSP_SIGN_FAILED                = 0xE0700103
        vcert.VCERT_E_TSP_NO_DIGEST                  = 0xE0700104
        vcert.VCERT_E_TSP_INVALID_SIGNER_NUM         = 0xE0700105
        vcert.VCERT_E_TSP_NO_TST_INFO                = 0xE0700106
        vcert.VCERT_E_TSP_RESP_D2I                   = 0xE0700107
        vcert.VCERT_E_TSP_RESP_NOT_ISSUED            = 0xE0700108
        vcert.VCERT_E_TSP_DIGEST_MISMATCH            = 0xE0700109
        vcert.VCERT_E_OCSP_CERT_PURPOSE              = 0xE0700140
        vcert.VCERT_E_OCSP_SIGN_FAILED               = 0xE0700141
        vcert.VCERT_E_OCSP_RESP_D2I                  = 0xE0700142
        vcert.VCERT_E_OCSP_RESP_NOT_ISSUED           = 0xE0700143
        vcert.VCERT_E_OCSP_NOT_BASICRESP             = 0xE0700144
        vcert.VCERT_E_OCSP_CERTID_MISMATCH           = 0xE0700145
        vcert.VCERT_E_OCSP_ISSUER_MISMATCH           = 0xE0700146
    }
    
})();