import {DossierDate, DossierT100, DossierT2000, DossierT250} from "./base.model";
import {DictType} from "./dictType.model";

export class DocumentDossier {
  nameDoc: DossierT100;
  idDoc: DossierT250;
  typeDoc: DictType;
  typeInDoc: DossierT2000;
  dateInDoc: DossierDate;
  registryId: any;
  row: number;

  constructor() {
  }
}
