import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BaseRoutingModule} from "./base-routing.module";
import {HomePageComponent} from "./layout/homePage.component";
import {NgxMaskModule} from "ngx-mask";
import {NgSelectModule} from "@ng-select/ng-select";
import {CoreModule} from "../core";
import {SearchHeaderComponent} from "./searchHeader";



@NgModule({
  imports: [
    CoreModule,
    BaseRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NgbModule,
    NgSelectModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    SearchHeaderComponent
  ],
  declarations: [
    HomePageComponent, SearchHeaderComponent
  ]
})
export class BaseModule {
}
