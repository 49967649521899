import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {Utils} from "@amlCore/utils";
import {Injectable} from "@angular/core";

@Injectable()
export class StringDateAdapterService {
  public fromModel(value: string): NgbDateStruct {
    let newDate: NgbDateStruct = null;
    if (value) {
      const separator = value.includes('/') ? '/' : '.';
      const dateParts = value.trim().split(separator);
      if (dateParts.length === 3) {
        newDate = {day: Number(dateParts[0]), month: Number(dateParts[1]), year: Number(dateParts[2])};
      }
    }
    return newDate;
  }

  public toModel(date: NgbDateStruct, separator = '.'): string {
    let result = null;
    if (date) {
      result = `${typeof date.day === 'number' ? Utils.padNumber(date.day) : ''}${separator}` +
        `${typeof date.month === 'number' ? Utils.padNumber(date.month) : ''}${separator}${date.year}`;
    }
    return result;
  }
}
