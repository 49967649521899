import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DossierService} from "../../../../services";
import {IDossierSubService} from "../IDossierSub.service";
import {DossierBasePageComponent} from "../dossierBasePage.component";
import {OtherAccountsDossierService} from "./otherAccountsDossier.service";
import {AlertPanelService, ITable, ITableCallBack, ITableItemDescriptor} from "@amlCore/components";
import {OtherAccountDossierComponent} from "../components/otherAccount";
import {UserService, DictionaryService} from "@amlCore/services";
import {OtherAccountType, PartItemArray} from "../../../../models";
import {Utils} from "@amlCore/utils";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FileSaverService} from "ngx-filesaver";

@Component({
  selector: 'app-other-accounts-dossier',
  styleUrls: ['../dossier.css'],
  templateUrl: './otherAccountsDossier.component.html'
})
export class OtherAccountsDossierComponent extends DossierBasePageComponent implements OnInit, ITable {

  modelPage: Array<OtherAccountType>;
  itemDescriptor: ITableItemDescriptor;
  isTableTab = true;

  constructor(protected dossierService: DossierService,
              protected route: ActivatedRoute,
              private otherAccountsDossierService: OtherAccountsDossierService,
              protected dictSrv: DictionaryService,
              protected router: Router,
              protected userService: UserService,
              protected alertPanelSrv: AlertPanelService,
              protected modalService: NgbModal,
              protected fSaver: FileSaverService) {
    super(dossierService, route, dictSrv, router, userService, alertPanelSrv, modalService, fSaver);
  }

  ngOnInit(): void {
    // Инициализация вкладки
    this.modelPage = this.initPage();
    // Описание элемента таблицы
    this.itemDescriptor = {
      create: this.otherAccountsDossierService.getOtherAccountType.bind(this.otherAccountsDossierService),
      component: OtherAccountDossierComponent,
      params: {
        typePage: this.typePage
      }
    } as ITableItemDescriptor;
  }

  /**
   * Инициализация модели
   * Преобразуем в нужный формат
   * @param model - данные сервера
   */
  initModel(model) {
    let newModel;
    this.partId = null;
    if (model) {
      const partIdList: string[] = Object.keys(model);
      newModel = model[partIdList[0]];
      this.partId = partIdList[0];
      Utils.deleteEmptyObj(newModel);
    } else {
      newModel = [];
    }
    const formData = {};
    formData[this.typePage] = newModel;
    return formData;
  }

  getService(): IDossierSubService {
    return this.otherAccountsDossierService;
  }

  callbackItemClosed(event: ITableCallBack): void {
    if (event.editIndex != null) {
      this.saveDossierPage();
    }
  }

  deleteItem(event: ITableCallBack): void {
    this.saveDossierPage();
  }

  destroy(): void {
    // ТУТ писать реализацию ngOnDestroy для компонента

  }

    /*TODO в дальнейшем возмонжо нужно будет сделать
    setErrorTableTab абстрактным методом и реализовать его в каждом классе компонента
    где наследуется DossierBasePageComponent так как появляются разделы с уникальной
    логикой простановки ошибок для таблицы вкладки.*/

  // @ovveride setErrorTableTab
  setErrorTableTab(key, validationInfo) {
    const form = this.f(this.typePage);
    const values = form.value as PartItemArray[];
    const {items} = validationInfo;
    const rowErrorKeys = Object.keys(items);
    rowErrorKeys.forEach(rowErrorkey => {
      const formRow = values.find(formRowItem => formRowItem['row'] === parseInt(rowErrorkey), 10);
      formRow.$checkedFlk = true;
    });
    form.patchValue(values);
  }

}
