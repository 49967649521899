import {CryptUtils} from "./utils";
import {CryptAPI, CryptCertObject, CryptVersionValidate} from "./interface";
import {ALGORITHMs, MINVERSION} from "./constants";


export class CryptNpapi implements CryptAPI {
    PluginBaseVersion = MINVERSION;
    cvi = {
        isPluginLoaded: false,
        isPluginWorked: false,
        isActualVersion: false,
        isPluginEnabled: false
    } as CryptVersionValidate;

    private certificate = null;
    private algorithm = null;
    private readonly CADESCOM_CADES_BES = 1;

    public get cadesplugin() {
        return CryptUtils.getCades();
    }


    constructor() {
    }


    MakeVersionString(oVer) {
        if (typeof (oVer) !== "string") {
            oVer = oVer.MajorVersion + "." + oVer.MinorVersion + "." + oVer.BuildVersion;
        }
        this.cvi.currentPluginVersion = oVer;
    }

    VersionCompare(StringVersion, ObjectVersion) {
        if (typeof (ObjectVersion) === "string") {
            return -1;
        }
        const arr = StringVersion.split('.');
        if (ObjectVersion.MajorVersion === parseInt(arr[0], 10)) {
            if (ObjectVersion.MinorVersion === parseInt(arr[1], 10)) {
                if (ObjectVersion.BuildVersion === parseInt(arr[2], 10)) {
                    return 0;
                } else if (ObjectVersion.BuildVersion < parseInt(arr[2], 10)) {
                    return -1;
                }
            } else if (ObjectVersion.MinorVersion < parseInt(arr[1], 10)) {
                return -1;
            }
        } else if (ObjectVersion.MajorVersion < parseInt(arr[0], 10)) {
            return -1;
        }
        return 1;
    }

    GetCSPName() {
        try {
            const oAbout = this.cadesplugin.CreateObject("CAdESCOM.About");
            this.cvi.cryptoCSPName = oAbout.CSPName(80);
        } catch (err) {
            this.cvi.cryptoCSPName = "Имя криптопровайдера не определено.";
            console.warn(err);
        }
    }

    GetCSPVersion() {
        try {
            const oAbout = this.cadesplugin.CreateObject("CAdESCOM.About");
            const ver = oAbout.CSPVersion("", 80);
            this.cvi.cryptoCSPVersion = ver.MajorVersion + "." + ver.MinorVersion + "." + ver.BuildVersion;
        } catch (err) {
            this.cvi.cryptoCSPVersion = "Версия криптопровайдера не определена.";
            console.warn(err);
        }
    }

    ShowCSPVersion(CurrentPluginVersion) {
        this.MakeVersionString(CurrentPluginVersion);
        this.GetCSPVersion();
        this.GetCSPName();
    }

    GetLatestVersion(CurrentPluginVersion) {
        if (this.cvi.isPluginWorked) { // плагин работает, объекты создаются
            this.cvi.isActualVersion = true;
            if (this.VersionCompare(this.PluginBaseVersion, CurrentPluginVersion) < 0) {
                this.cvi.isActualVersion = false;
                this.cvi.textError = "Плагин загружен, но есть более свежая версия.";
                this.cvi.typeError = 'warning';
            }
        } else { // плагин не работает, объекты не создаются
            if (this.cvi.isPluginLoaded) { // плагин загружен
                if (!this.cvi.isPluginEnabled) { // плагин загружен, но отключен
                    this.cvi.textError = "Плагин загружен, но отключен в настройках браузера.";
                    this.cvi.typeError = 'error';
                } else { // плагин загружен и включен, но объекты не создаются
                    this.cvi.textError = "Плагин загружен, но не удается создать объекты. Проверьте настройки браузера.";
                    this.cvi.typeError = 'error';
                }
            } else { // плагин не загружен
                this.cvi.textError = "Плагин не загружен.";
                this.cvi.typeError = 'error';
            }
            this.cvi.isPluginWorked = false;
        }
    }

    CheckForPlugIn(): Promise<CryptVersionValidate> {
        const _this = this;
        let CurrentPluginVersion;
        return new Promise<CryptVersionValidate>((resolve) => {
            try {
                const oAbout = _this.cadesplugin.CreateObject("CAdESCOM.About");
                _this.cvi.isPluginLoaded = true;
                _this.cvi.isPluginEnabled = true;
                _this.cvi.isPluginWorked = true;

                CurrentPluginVersion = oAbout.PluginVersion;
                if (typeof (CurrentPluginVersion) === "undefined") {
                    CurrentPluginVersion = oAbout.Version;
                }
                _this.ShowCSPVersion(CurrentPluginVersion);
            } catch (err) {
                // Объект создать не удалось, проверим, установлен ли
                // вообще плагин. Такая возможность есть не во всех браузерах
                const mimetype = navigator.mimeTypes["application/x-cades"];
                if (mimetype) {
                    _this.cvi.isPluginLoaded = true;
                    const plugin = mimetype.enabledPlugin;
                    if (plugin) {
                        _this.cvi.isPluginEnabled = true;
                    }
                }
            }
            _this.GetLatestVersion(CurrentPluginVersion);
            resolve(this.cvi);
        });
    }

    getSelectedCert() {
        return this.certificate;
    }

    getAlgorithmCert() {
        return this.algorithm;
    }

    resetSelectedCert(){
       this.certificate = null;
       this.algorithm = null;
    }
    
    selectedCert(cert): Promise<any> {
        const _this = this;
        return new Promise((resolve) => {
            _this.certificate = cert;
            _this.getAlgorithm(cert).then((data) => {
                _this.algorithm = data.code;
                return resolve(_this.certificate);
            });
        });
    }

    getCertsStore(): Promise<Array<CryptCertObject>> {
        const _this = this;
        return new Promise((resolve, reject) => {
                try {
                    const oStore = _this.cadesplugin.CreateObject("CAdESCOM.Store");
                    const filteredCertificates = [];
                    const today = new Date();
                    try {
                         oStore.Open();
                    } catch (ex) {
                        throw new Error("Ошибка при открытии хранилища: " + _this.cadesplugin.getLastError(ex));
                    }

                    let certCnt;
                    let certs;

                    try {
                        certs = oStore.Certificates;
                        certCnt = certs.Count;
                    } catch (ex) {
                        throw new Error("Ошибка при открытии хранилища: " + _this.cadesplugin.getLastError(ex));
                    }

                    if (certCnt === 0) {
                        throw new Error("Не найдено сертификатов");
                    }

                    for (let i = 1; i <= certCnt; i++) {
                        let cert;
                        try {
                            cert = certs.Item(i);
                        } catch (ex) {
                            throw new Error("Ошибка при перечислении сертификатов: " + _this.cadesplugin.getLastError(ex));
                        }
                        const certObj = {} as CryptCertObject;
                        certObj.isLocal = false;
                        certObj.hasPrivateKey = false;
                        const thumb =  cert.Thumbprint;
                        certObj.isLocal = true;
                        const Cert2 = cert;
                        const ValidToDate = new Date((cert.ValidToDate));
                        const ValidFromDate = new Date((cert.ValidFromDate));
                        certObj.isActual = ValidFromDate < today && ValidToDate > today;
                        if (certObj.isActual) {
                            certObj.certObject = cert.data;
                            certObj.thumb = thumb;
                            certObj.certInfo = CryptUtils.getCertInfo(cert.SubjectName);
                            certObj.certCAPICOM = Cert2;
                            try {
                                certObj.hasPrivateKey = cert.HasPrivateKey();
                                try {
                                    certObj.subjectName
                                        = CryptUtils.getCertInfoString(cert.SubjectName, cert.ValidFromDate);
                                } catch (e) {
                                    certObj.subjectName = certObj.thumb;
                                    console.warn(e);
                                    // ignored
                                }
                                filteredCertificates.push(certObj);
                            } catch (ex) {
                                throw new Error(_this.cadesplugin.getLastError(ex));
                            }
                        }
                    }
                    oStore.Close();
                    filteredCertificates.sort(CryptUtils.sortByName(true));
                    resolve(filteredCertificates);
                } catch (e) {
                    reject(e);
                }

        });
    }

    getCertsFromUser(thumbs): Promise<Array<CryptCertObject>> {
        const _this = this;
        return new Promise((resolve, reject) => {
            try {
                const oStore = _this.cadesplugin.CreateObject("CAdESCOM.Store");
                const filteredCertificates = [];
                const today = new Date();

                try {
                    oStore.Open();
                } catch (ex) {
                    throw new Error("Ошибка при открытии хранилища: " + _this.cadesplugin.getLastError(ex));
                }

                let certCnt;
                let certs;

                try {
                    certs = oStore.Certificates;
                    certCnt = certs.Count;
                } catch (ex) {
                    throw new Error("Ошибка при открытии хранилища: " + _this.cadesplugin.getLastError(ex));
                }

                if (certCnt === 0) {
                    throw new Error("Не найдено сертификатов");
                }

                for (let i = 1; i <= certCnt; i++) {
                    let cert;
                    try {
                        cert = certs.Item(i);
                    } catch (ex) {
                        throw new Error("Ошибка при перечислении сертификатов: " + _this.cadesplugin.getLastError(ex));
                    }
                    const certObj = {} as CryptCertObject;
                    certObj.isLocal = false;
                    certObj.hasPrivateKey = false;
                    const thumb = cert.Thumbprint;
                    if (thumb && thumbs.indexOf(thumb.toUpperCase()) > -1) {
                        certObj.isLocal = true;
                        const Cert2 = cert;
                        certObj.certObject = cert.data;
                        certObj.thumb = thumb;
                        certObj.certInfo = CryptUtils.getCertInfo(cert.SubjectName);
                        certObj.isActual = (Cert2.ValidFromDate < today && Cert2.ValidToDate > today);
                        certObj.certCAPICOM = Cert2;
                        try {
                            certObj.hasPrivateKey = Cert2.HasPrivateKey();
                            if (certObj.hasPrivateKey) {
                                try {
                                    certObj.subjectName = CryptUtils.getCertInfoString(cert.SubjectName, cert.ValidFromDate);
                                } catch (e) {
                                    certObj.subjectName = thumb;
                                    console.warn(e);
                                    // ignored
                                }

                            }
                        } catch (ex) {
                            throw new Error(_this.cadesplugin.getLastError(ex));
                        }
                        filteredCertificates.push(certObj);
                    }
                }
                oStore.Close();
                filteredCertificates.sort(CryptUtils.sortByName(true));
                resolve(filteredCertificates);
            } catch (e) {
                reject(e);
            }
        });
    }

    findCertificate(certHash): Promise<any> {
        const _this = this;
        return new Promise((resolve, reject) => {
            try {
                const oStore = _this.cadesplugin.CreateObject("CAdESCOM.Store");
                try {
                    oStore.Open();
                } catch (err) {
                    throw new Error('Ошибка при октрытии хранилища CAdESCOM.Store: ' + err.number);
                }
                const allCerts = oStore.Certificates;
                let certCnt;
                let certs;
                try {
                    certs = oStore.Certificates;
                    certCnt = certs.Count;
                } catch (ex) {
                    throw new Error("Ошибка при открытии хранилища: " + _this.cadesplugin.getLastError(ex));
                }

                if (certCnt === 0) {
                    throw new Error("Не найдено сертификатов");
                }

                let cert;
                let found = 0;
                for (let i = 1; i <= (allCerts.Count); i++) {
                    try {
                        cert = allCerts.Item(i);
                    } catch (ex) {
                        throw new Error("Ошибка при перечислении сертификатов: " + _this.cadesplugin.getLastError(ex));
                    }

                    try {
                        const thumbprint = cert.Thumbprint;
                        if (certHash.toUpperCase() === thumbprint) {
                            found = i;
                            break;
                        }
                    } catch (ex) {
                        throw new Error("Ошибка при получении сертификата: " + _this.cadesplugin.getLastError(ex));
                    }
                }

                if (found === 0) {
                    oStore.Close();
                    throw new Error("Сертификат не найден");
                }
                cert = allCerts.Item(found);
                oStore.Close();
                resolve(cert);
            } catch (e) {
                reject(e);
            }
        });
    }

    generateSignature(sHashValue, cert): Promise<string> {
        const _this = this;
        return new Promise((resolve, reject) => {
            try {
                const pk = cert.PublicKey();
                const algorithm = pk.Algorithm;
                const oSigner = _this.cadesplugin.CreateObject("CAdESCOM.CPSigner");
                oSigner.Certificate = cert;
                oSigner.Options = _this.cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_END_ENTITY_ONLY;

                const oHashedData = _this.cadesplugin.CreateObject("CAdESCOM.HashedData");
                oHashedData.Algorithm = ALGORITHMs[algorithm.Value];
                oHashedData.SetHashValue(sHashValue);

                const oSignedData = _this.cadesplugin.CreateObject("CAdESCOM.CadesSignedData");
                const signature = oSignedData.SignHash(oHashedData, oSigner, _this.CADESCOM_CADES_BES);
                resolve(signature);
            } catch (e) {
                reject(e);
            }
            }
        );
    }

    getAlgorithm(cert): Promise<{ name: string; code: string }> {
        return new Promise((resolve, reject) => {
            if (!cert) {
                reject('Сертификат не найден');
            }
            const pk = cert.PublicKey();
            const algorithm = pk.Algorithm;
            resolve({name: algorithm.FriendlyName, code: algorithm.Value});
        });
    }
    signDataBase64(sHashBase64, cert): Promise<string> {
        return Promise.resolve("empty");
    }
    signFile(oFile, oCertificate): Promise<string> {
        const _this = this;
        return new Promise((resolve, reject) => {
            try {
                // Проверяем, работает ли File API
                CryptUtils.doCheckFileApi();
                // @ts-ignore
                const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
                const chunkSize = 3 * 1024 * 1024; // 3MB
                const chunks = Math.ceil(oFile.size / chunkSize);
                let currentChunk = 0;

                const pk = oCertificate.PublicKey();
                const algorithm = pk.Algorithm;
                const code = algorithm.Value;
                const hashAlg = ALGORITHMs[code];
                const oHashedData = _this.cadesplugin.CreateObject("CAdESCOM.HashedData");
                oHashedData.Algorithm = hashAlg;
                oHashedData.DataEncoding = 1;


                const frOnload = (e) => {
                    const header = ";base64,";
                    const sFileData = e.target.result;
                    const sBase64Data = sFileData.substr(sFileData.indexOf(header) + header.length);

                    oHashedData.Hash(sBase64Data);
                    currentChunk++;

                    if (currentChunk < chunks) {
                        loadNext();
                    } else {
                        const oStore = _this.cadesplugin.CreateObject("CAdESCOM.Store");
                        oStore.Open(_this.cadesplugin.CAPICOM_CURRENT_USER_STORE, _this.cadesplugin.CAPICOM_MY_STORE,
                            _this.cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);

                        const oSigner = _this.cadesplugin.CreateObject("CAdESCOM.CPSigner");
                        oSigner.Certificate = oCertificate;
                        oSigner.Options = _this.cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_END_ENTITY_ONLY;
                        oSigner.CheckCertificate = false;

                        const oSignedData = _this.cadesplugin.CreateObject("CAdESCOM.CadesSignedData");
                        oSignedData.ContentEncoding = 1;

                        try {
                            resolve(oSignedData.SignHash(oHashedData, oSigner, 1));
                        } catch (err) {
                            reject(_this.cadesplugin.getLastError(err));
                        }
                        oStore.Close();
                    }
                };


                function loadNext() {
                    const fileReader = new FileReader();
                    fileReader.onload = frOnload;
                    fileReader.onerror = () => {
                        reject("Не удалось выпонить загрузку файлов");
                    };

                    const start = currentChunk * chunkSize;
                    const end = ((start + chunkSize) >= oFile.size) ? oFile.size : start + chunkSize;

                    fileReader.readAsDataURL(blobSlice.call(oFile, start, end));
                }

                loadNext();
            } catch (e) {
                reject(e);
            }
        });
    }
    getCertBase64(hash) {
        return Promise.resolve(0);
    }
}
