import { Component, Input, OnInit } from '@angular/core';
import { IconsService} from "@amlCore/services";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-import-dictionary',
  templateUrl: './import-dictionary.component.html',
  styleUrls: ['./import-dictionary.component.scss']
})
export class ImportDictionaryComponent implements OnInit {

  @Input() url: string;
  @Input() code: string;
  
  icons = this._iconsService.getIcons();
  loading = false;
  accept: string = '.xml';

  uploadError: string;
  successUploadResult: string;

  constructor(private _iconsService: IconsService, private _activeModal: NgbActiveModal,) { }

  ngOnInit(): void {}

  startUpload(event) {
    this.loading = true;
    this.uploadError = null;
    this.successUploadResult = null;
  }
  
  successUpload(result) {
    this.successUploadResult = result.rowCountUpdated;
    this.loading = false;
    const keysMap = {
      'banc': 'qrefBanc',
      'cb-bnkseek': 'qrefCbBnkseek',
      'fil': 'qrefFil',

    };
    // Удалить из sessionStorage при успешном обновлении справочника
    if (JSON.parse(sessionStorage.getItem(keysMap[this.code]))) {
      sessionStorage.removeItem(keysMap[this.code]);
    }
  }
  
  callBackError(error) {
    this.uploadError = error.message;
    this.loading = false;
  }

  onClose(): void {
    this._activeModal.dismiss();
    this.loading = false;
  }

}
