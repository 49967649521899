/**
 * API по базовым справочникам АМЛ.
 * API по базовым справочникам АМЛ.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Данные записи справочника областей.
 */
export interface QrefObl { 
    /**
     * Код области. Ключевое поле таблицы.
     */
    code?: string;
    /**
     * Наименование области.
     */
    name?: string;
    /**
     * Старое наименование.
     */
    oldname1?: string;
    /**
     * Старое наименование 2.
     */
    oldname2?: string;
    /**
     * Описание отсутствует.
     */
    gninmb?: string;
    /**
     * Код ОКАТО.
     */
    okato?: string;
}

