import {CryptUtils} from "../../core/utils";
import {CryptAPI, CryptCertObject, CryptVersionValidate} from "../../core/interface";
import {ALGORITHMs, MINVERSION} from "../../core/constants";


export class CryptValidata {
    cvi = {} as CryptVersionValidate;
    private certificate = null;
    private algorithm = null;

    public get vcert() {
        return CryptUtils.getValidata();
    }

    constructor() {
    }

    getSelectedCert() {
        return this.certificate;
    }

    getAlgorithmCert() {
        return this.algorithm;
    }

    //Проверка состояния плагина
    CheckForPlugIn(): Promise<CryptVersionValidate> {
        const _this = this;

        return new Promise((resolve, reject) => {
            const initPlugin = () => {
                return _this.vcert.initialize().then(function(result){
                    _this.cvi.isPluginEnabled = true;
                    _this.cvi.isPluginLoaded = true;
                    _this.cvi.isPluginWorked = true;
                    _this.updateMyCert().then(()=> resolve(_this.cvi));
                },function(error){
                    _this.cvi.textError = error.text;
                    _this.cvi.typeError = "error";
                    reject(_this.cvi.textError);
                });
            }
            setTimeout(() => {
                if(!_this?.vcert) {
                    _this.cvi.textError = "Плагин не загружен. Для работы с электронной подписью необходимо скачать и установить Browser plug-in для СКАД Сигнатура";
                    _this.cvi.typeError = "error";
                    reject(_this.cvi.textError);
                } else {
                    return initPlugin();
                }
            }, 1000);
        });
    }

    //Проверка рабочего сертфиката
    private updateMyCert() {
        const _this = this;
        let cert = new _this.vcert.Certificate(0);
        let sp = new _this.vcert.FindParam(cert,this.vcert.FLAG_FIND_MY,this.vcert.FIELD_CERTENCODED | this.vcert.FIELD_SUBJECT);
        return this.vcert.findCert(sp).then(function(result){
            _this.certificate = result.find_result.certs[0];
        },this.processError);
    }

    private processError(error){
        const _this = this;
        _this.cvi.textError = error.text;
        _this.cvi.typeError = "error";
        return Promise.reject(error.text)
    }

    //Поиск сертификата по отпечатку
    findCertificate(certHash): Promise<any> {
        const _this = this;
        return new Promise((resolve, reject) => {
            try {
                const fields = this.vcert.FIELD_CERTHASH;
                let cert = new this.vcert.Certificate(fields);
                cert.certHash = certHash;
                let fp = new this.vcert.FindParam(cert);
                fp.flag = this.vcert.FLAG_FIND_REMOTE | this.vcert.FLAG_FIND_SELECTUI;
                fp.info = this.vcert.FIELD_SUBJECT | this.vcert.FIELD_KEYID | this.vcert.FIELD_CERTHASH;
                this.vcert.findCert(fp).then((result) => {
                    if(result.num == 0)
                        throw new Error("Не найдено сертификатов");
                    const certs = result.find_result.certs;
                    console.log(result);
                    resolve(certs[0]);
                });
            } catch (e) {
                reject(e);
            }
        });
    }

    getCertsStore(): Promise<Array<CryptCertObject>> {
        const _this = this;
        return new Promise((resolve, reject) => {
            try {
                const fp = new this.vcert.FindParam({fields : 0}, this.vcert.FLAG_MY, null, this.vcert.FIELD_ALL);
                _this.vcert.findCert(fp).then((result) => {
                    const certs = result.find_result.certs;
                    const certCnt = result.num;
                    if(certCnt == 0)
                        throw new Error("Не найдено сертификатов");

                    const filteredCertificates = [];
                    for (let i = 1; i <= certCnt; i++) {
                        try {
                            filteredCertificates.push(_this.toCryptCertObject(certs[i]));
                        } catch (ex) {
                            throw new Error("Ошибка при получении сертификатов");
                        }
                    }
                    filteredCertificates.sort(CryptUtils.sortByName(true));
                    resolve(filteredCertificates);
                });
            } catch (e) {
                reject(e);
            }
        });
    }

    getCertsFromUser(thumbs): Promise<Array<CryptCertObject>> {
        const _this = this;
        return new Promise((resolve, reject) => {
            try {
                const fields = _this.vcert.FIELD_CERTHASH;
                let cert = new _this.vcert.Certificate(fields);
                cert.certHash = thumbs;
                let fp = new _this.vcert.FindParam(cert);
                fp.flag = _this.vcert.FLAG_FIND_REMOTE | _this.vcert.FLAG_FIND_SELECTUI;
                fp.info = _this.vcert.FIELD_SUBJECT | _this.vcert.FIELD_KEYID | _this.vcert.FIELD_CERTHASH;
                _this.vcert.findCert(fp).then((result) => {
                    const certs = result.find_result.certs;
                    const certCnt = result.num;
                    if(certCnt == 0)
                        throw new Error("Не найдено сертификатов");
                    const filteredCertificates = [];
                    for (let i = 1; i <= certCnt; i++) {
                        try {
                            filteredCertificates.push(_this.toCryptCertObject(certs[i]));
                        } catch (ex) {
                            throw new Error("Ошибка при получении сертификатов");
                        }
                    }
                    filteredCertificates.sort(CryptUtils.sortByName(true));
                    resolve(filteredCertificates);

                });
            } catch (e) {
                reject(e);
            }
        });
    }

    private toCryptCertObject(cert): CryptCertObject{
        const today = new Date();
        const certObj = {} as CryptCertObject;
        certObj.isLocal = false;
        certObj.hasPrivateKey = false;
        const thumb = cert.certHash;
        certObj.isLocal = true;
        const sName = cert.subject;
        const certDate = cert.notBefore;
        const ValidToDate = new Date(cert.notAfter);
        const ValidFromDate = new Date(certDate);
        certObj.isActual = ValidFromDate < today && ValidToDate > today;
        if (certObj.isActual) {
            certObj.validFrom = ValidToDate;
            certObj.validTo = ValidToDate;
            certObj.thumb = thumb;
            certObj.certInfo = CryptUtils.getCertInfo(sName);
            certObj.certCAPICOM = cert;
            certObj.hasPrivateKey = cert.keyId != null;
            try {
                certObj.subjectName
                  = CryptUtils.getCertInfoString(sName, certDate);
            } catch (e) {
                certObj.subjectName = certObj.thumb;
                console.warn(e);
            }
        }
        return certObj;
    }

    //генерация подписи для хэша
    generateSignature(sHashValue): Promise<string> {
        const _this = this;
        return new Promise((resolve, reject) => {
            try {
                _this.vcert.signHash(sHashValue).then(function(result){
                    if (result.status == "OK")
                        resolve(result.sign_out);
                    else {
                        reject(result.text);
                    }
                },_this.processError);
            } catch (e) {
                reject(e);
            }
        });
    }

    //генерация подписи для данных в base64
    signDataBase64(sHashBase64): Promise<string> {
        const _this = this;
        return new Promise((resolve, reject) => {
            try {
                let sp = new _this.vcert.SignParam();
                _this.vcert.sign(sp, sHashBase64).then(function(result){
                    if(result.status == "OK")
                        resolve(result.sign_out);
                    else{
                        reject(result.text);
                    }
                },_this.processError);
            } catch (e) {
                reject(e);
            }
        });
    }

    //подпись файла
    signFile(oFile): Promise<string> {
        const _this = this;
        return this.getDataFromFile(oFile).then(data=> {
            return _this.generateSignature(data);
        },_this.processError);
    }

    private getDataFromFile(file, start = 0, end = 0) {
        const fReader = new FileReader();
        return  new Promise(function(resolve, reject){
            if(end == 0)
                end = file.size;
            fReader.onloadend = function (evt) {
                if(evt.target.readyState == FileReader.DONE){
                    const header = ";base64,";
                    const fileData = evt.target.result;
                    if (typeof fileData === "string") {
                        // @ts-ignore
                        delete evt.target?.result;
                        const base64Data = fileData.substr(fileData.indexOf(header) + header.length);
                        resolve(base64Data);
                    }

                }
            }
            let blob = file;
            blob = file.slice(start,end,file.type);
            fReader.readAsDataURL(blob);
        });
    }

    getCertBase64(hash) {
        const _this = this;
        return new Promise((resolve, reject) => {
            this.findCertificate(hash).then((cert) => {
                    resolve(cert.Export(0));
            }, (e) => {
                reject(e);
            });
        });
    }
}
