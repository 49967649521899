import {Component, OnDestroy, OnInit} from "@angular/core";
import {DossierService} from "../../../../../services";
import {DictType} from "../../../../../models/dossier";
import {DictionaryService} from "@amlCore/services";
import {DossierBaseModel} from "../../dossierBaseModel";
import {Unsubscribable} from "rxjs";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {AlertPanelService} from "@amlCore/components";
import {DossierAccessEnum} from "@amlCore/enums";
import {Validators} from "@angular/forms";

@Component({
  selector: 'app-reg-info-inb-ul-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './regInfoInbULDossier.component.html'
})
export class RegInfoInbULDossierComponent extends DossierBaseSubComponent implements OnInit, OnDestroy {

  opfInbULList: Array<DictType> = [];
  opfValueChanges: Unsubscribable;
  dossierAccessEnum = DossierAccessEnum;

  constructor(protected readonly dossierService: DossierService,
              protected readonly dictSrv: DictionaryService,
              protected readonly dossierBaseModel: DossierBaseModel,
              protected readonly alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  ngOnInit(): void {
    this.dictSrv.getRemoteData("orgforminboul").then((list) => {
      this.opfInbULList = list;
      this.loadRemoteData(['oksm']);
      this.initOpf(this.f('opf').value);
      this.opfValueChanges = this.f('opf').valueChanges.subscribe(val => {
        this.initOpf(val);
      });
      this.addUnsubscribable(this.opfValueChanges);
    });
  }

  initOpf(value): void {
    if (!this.formGroupApp.disabled) {
      const iof = this.f('iof');
      iof.disable();
      if (value && value.code === "4") {
        iof.setValidators(Validators.required);
        iof.enable();
      }
      this.formGroupApp.updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribable();
  }
}
