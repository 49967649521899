import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

import {map, take} from 'rxjs/operators';
import {CryptographService} from "./cryptograph.service";

@Injectable({providedIn: "root"})
export class CryptGuard implements CanActivate {
  constructor(private router: Router,
              private crypt: CryptographService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> {

    return this.crypt.isCryptEnable.pipe(take(1), map(data => {
      return data.state;
    }));

  }
}
