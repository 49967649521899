export class QrefOkato1Model {
  ter: string;
  kod1: string;
  kod2: string;
  kod3: string;
  name: string;
  dopInfo: string;

  constructor() {
  }
}

