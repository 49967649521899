import {CryptCertInfo} from "../interface";
import {CryptCertObject} from "../interface";

export class CryptUtils {
    static Base64 = {
        _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
        encode(input) {
            let output = "";
            // tslint:disable-next-line:one-variable-per-declaration
            let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
            let i = 0;

            input = CryptUtils.Base64._utf8_encode(input);

            while (i < input.length) {

                chr1 = input.charCodeAt(i++);
                chr2 = input.charCodeAt(i++);
                chr3 = input.charCodeAt(i++);
                // tslint:disable-next-line:no-bitwise
                enc1 = chr1 >> 2;
                // tslint:disable-next-line:no-bitwise
                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                // tslint:disable-next-line:no-bitwise
                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                // tslint:disable-next-line:no-bitwise
                enc4 = chr3 & 63;

                if (isNaN(chr2)) {
                    enc3 = enc4 = 64;
                } else if (isNaN(chr3)) {
                    enc4 = 64;
                }
                output = output + this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2)
                    + this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);

            }

            return output;
        },


        decode(input) {
            let output = "";
            // tslint:disable-next-line:one-variable-per-declaration
            let chr1, chr2, chr3;
            // tslint:disable-next-line:one-variable-per-declaration
            let enc1, enc2, enc3, enc4;
            let i = 0;

            input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

            while (i < input.length) {

                enc1 = this._keyStr.indexOf(input.charAt(i++));
                enc2 = this._keyStr.indexOf(input.charAt(i++));
                enc3 = this._keyStr.indexOf(input.charAt(i++));
                enc4 = this._keyStr.indexOf(input.charAt(i++));
                // tslint:disable-next-line:no-bitwise
                chr1 = (enc1 << 2) | (enc2 >> 4);
                // tslint:disable-next-line:no-bitwise
                chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
                // tslint:disable-next-line:no-bitwise
                chr3 = ((enc3 & 3) << 6) | enc4;

                output = output + String.fromCharCode(chr1);

                if (enc3 !== 64) {
                    output = output + String.fromCharCode(chr2);
                }
                if (enc4 !== 64) {
                    output = output + String.fromCharCode(chr3);
                }

            }

            output = CryptUtils.Base64._utf8_decode(output);

            return output;

        },

        _utf8_encode(str) {
            str = str.replace(/\r\n/g, "\n");
            let utfText = "";

            for (let n = 0; n < str.length; n++) {

                const c = str.charCodeAt(n);

                if (c < 128) {
                    utfText += String.fromCharCode(c);
                } else if ((c > 127) && (c < 2048)) {
                    // tslint:disable-next-line:no-bitwise
                    utfText += String.fromCharCode((c >> 6) | 192);
                    // tslint:disable-next-line:no-bitwise
                    utfText += String.fromCharCode((c & 63) | 128);
                } else {
                    // tslint:disable-next-line:no-bitwise
                    utfText += String.fromCharCode((c >> 12) | 224);
                    // tslint:disable-next-line:no-bitwise
                    utfText += String.fromCharCode(((c >> 6) & 63) | 128);
                    // tslint:disable-next-line:no-bitwise
                    utfText += String.fromCharCode((c & 63) | 128);
                }

            }

            return utfText;
        },

        _utf8_decode(utftext) {
            let str = "";
            let i = 0;
            // tslint:disable-next-line:one-variable-per-declaration
            let c, c1, c2, c3;
            c = c1 = c2 = 0;

            while (i < utftext.length) {

                c = utftext.charCodeAt(i);

                if (c < 128) {
                    str += String.fromCharCode(c);
                    i++;
                } else if ((c > 191) && (c < 224)) {
                    c2 = utftext.charCodeAt(i + 1);
                    // tslint:disable-next-line:no-bitwise
                    str += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                    i += 2;
                } else {
                    c2 = utftext.charCodeAt(i + 1);
                    c3 = utftext.charCodeAt(i + 2);
                    // tslint:disable-next-line:no-bitwise
                    str += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                    i += 3;
                }

            }

            return str;
        }

    };

    static isIE() {
        return (("Microsoft Internet Explorer" === navigator.appName) || // IE < 11
            navigator.userAgent.match(/Trident\/./i));
    }

    static getCades() {
        // @ts-ignore
        return window.cadesplugin;
    }

    static getValidata() {
        // @ts-ignore
        return window.vcert;
    }

    static doCheckFileApi() {
        if (!window.FileReader) {
            throw new Error("Загрузка файлов не поддерживается вашим браузером! Попробуйте другой браузер!");
        }
        const fileReader = new FileReader();
        if (typeof (fileReader.readAsDataURL) !== "function") {
            throw new Error("Загрузка файлов не поддерживается вашим браузером! Попробуйте другой браузер!");
        }
    }

    /**
     * Разбор сертификата. Получение объекта с параметрами.
     */
    static getCertInfo(subjectName): CryptCertInfo {
        subjectName = subjectName.replace(/=\"/g, "=").replace(/\"\",/g, "\",");
        const innVal = convertCertField(subjectName.match(/ИНН=[0-9\s]{1,}/g) || subjectName.match(/1.2.643.3.131.1.1=[0-9\s]{1,}/g));
        // установка типа лица в зависимости от длинны ИНН
        const fio = convertCertField(subjectName.match(/CN=[А-Яа-яA-Za-zё0-9,. '-_«»]{1,}?(,\s)/g));
        const io = convertCertField(subjectName.match(/G=[А-Яа-яA-Za-zё0-9,. '-_«»]{1,}?(,\s)/g));
        let ioArr;
        const email = convertCertField(subjectName.match(/E=[@\w\.\-]{1,}?(,\s)/g));
        const lastName = convertCertField(subjectName.match(/SN=[А-Яа-яёA-Za-z0-9,. '-_«»]{1,}?(,\s)/g));
        const post = convertCertField(subjectName.match(/(?!STREET")T=[А-Яа-яёA-Za-z0-9,. -_]{1,}?(,\s)/g));
        if (io) {
            ioArr = parseIO(io);
        } else if (fio) {
            ioArr = parseIO(fio.substring(fio.indexOf(" ") + 1));
        } else {
            ioArr = ["", ""];
        }
        return {
            lastName,
            firstName: ioArr[0] ? ioArr[0] : "",
            middleName: ioArr[1] ? ioArr[1] : "",
            inn: innVal,
            email,
            post
        } as CryptCertInfo;


        function convertCertField(matchList) {
            if (!matchList) {
                return "";
            }
            const str = matchList[0];
            let val = str.substr(str.indexOf('=') + 1);
            // убираем ведущие нули
            if ((str.indexOf('ИНН') !== -1 || str.indexOf('1.2.643.3.131.1.1') !== -1) && val.indexOf('00') === 0) {
                val = val.substr(2);
            }
            val = val.replace(/\"\"/g, "\"").replace(/\'\'/g, "\'");
            if (val.slice(-2) === ", ") {
                val = val.slice(0, -2);
            }
            return val;
        }

        function parseIO(str) {
            let result;
            if (str.indexOf(" ") !== -1) {
                result = str.split(" ");
            } else {
                result = [str, ""];
            }
            return result;
        }
    }

    static getCertInfoString(certSubjectName, certFromDate) {

        const nameOrg = CryptUtils.extractCertInfo(certSubjectName, 'CN=').replace(/"""/g,'"').replace(/""/g,'"');
        const fio = `${CryptUtils.extractCertInfo(certSubjectName, 'SN=')} ${CryptUtils.extractCertInfo(certSubjectName, 'G=')}`.trim();
        let resultName = `${nameOrg}, ${fio}`;
        if (nameOrg === fio) { // если равны, то ИП и выводим только что-то одно
            resultName = nameOrg;
        }
        return `${resultName}, выдан ${CryptUtils.getCertDate(certFromDate)}`;
    }

    static extractCertInfo(from, what): string {
        let certName = "";

        const begin = from.indexOf(what);

        if (begin >= 0) {
            const end = from.indexOf(', ', begin);
            certName = (end < 0) ? from.substr(begin) : from.substr(begin, end - begin);
        }

        if (certName) {
            certName = certName.replace(/\"\"/g, "\"").replace(/\'\'/g, "\'")
            certName = certName.replace(what, '');
        }
        return certName;
    }

    static getCertDate(paramDate) {
        const certDate = new Date(paramDate);
        return CryptUtils.print2Digit(certDate.getUTCDate()) + "."
            + CryptUtils.print2Digit(certDate.getMonth() + 1)
            + "." + certDate.getFullYear() + " " +
            CryptUtils.print2Digit(certDate.getHours())
            + ":" + CryptUtils.print2Digit(certDate.getUTCMinutes())
            + ":" + CryptUtils.print2Digit(certDate.getUTCSeconds());
    }

    static print2Digit(digit) {
        return (digit < 10) ? "0" + digit : digit;
    }

    static sortByName(asc: boolean): any {
        return (a: CryptCertObject, b: CryptCertObject) => {
            if (a.subjectName > b.subjectName) {
                return asc ? 1 : -1;
            }
            if (a.subjectName < b.subjectName) {
                return asc ? -1 : 1;
            }
            return 0;
        };
    }
}
