/**
 * API по базовым справочникам АМЛ.
 * API по базовым справочникам АМЛ.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Данные записи общероссийского классификатора видов экономической деятельности.
 */
export interface QrefOkwed { 
    /**
     * Код. Ключевое поле таблицы.
     */
    kod?: string;
    /**
     * Описание.
     */
    name?: string;
}

