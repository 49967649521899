import {Component, OnInit} from "@angular/core";
import {DossierService} from "../../../../../services";
import {DictType} from "../../../../../models/dossier";
import {DictionaryService} from "@amlCore/services";
import {DossierBaseModel} from "../../dossierBaseModel";
import {DossierAccessEnum} from "@amlCore/enums";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {AlertPanelService} from "@amlCore/components";
import {Validators} from "@angular/forms";
import {Unsubscribable} from "rxjs";

@Component({
  selector: 'app-residence-doc-info-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './residenceDocInfoDossier.component.html'
})
export class ResidenceDocInfoDossierComponent extends DossierBaseSubComponent implements OnInit {

  docTypeList: Array<DictType> = [];
  // Обязательность полей по типу вкладки
  isFieldsReq = false;
  private docTypeValueChanges: Unsubscribable;

  constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  ngOnInit() {
    this.dictSrv.getRemoteData('documenttypecodes').then((result) => {
      this.docTypeList = result;
    });
    this.isFieldsReq = ![DossierAccessEnum.SVEDAU,
      DossierAccessEnum.SVEDUDS,
      DossierAccessEnum.SVEDVPR,
      DossierAccessEnum.SVEDBV,
      DossierAccessEnum.PARTNER].includes(this.typePage);

    this.updateDocOther();
    this.docTypeValueChanges = this.f('docType').valueChanges.subscribe(() => {
      this.updateDocOther();
    });
    this.addUnsubscribable(this.docTypeValueChanges);

  }

  private updateDocOther(){
    const control = this.f('docOther');
    if(this.f('docType')?.value?.code == '40'){
      control.enable();
    } else {
      control.disable();
    }
  }
}
