import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IconsService } from '@amlCore/services';
import {DossierService} from "../../services";
import {SendToFmInfo} from "../../models/sentToFmInfo.model";
import {Utils} from "@amlCore/utils";
import {FileSaverService} from "ngx-filesaver";


@Component({
  selector: 'app-generate-fes-documents',
  templateUrl: './generate-fes.component.html',
  styleUrls: ['./generate-fes.component.scss'],
})
export class GenerateFesComponent implements OnInit {

  icons = this._iconsService.getIcons();
  ids: [];
  docsInfo: object;
  containers: object;
  data: SendToFmInfo;
  disabled = false;
  success: boolean;
  loader = false;
  error = '';
  stopSended = false;
  sendProgress = false;

  constructor(private _activeModal: NgbActiveModal,
              private _iconsService: IconsService,
              private dossierSrv: DossierService,
              private fSaver: FileSaverService) { }

  ngOnInit(): void {
  }

  onClose(): void {
    this._activeModal.close(this.success);
  }

  open(ids: any) {
    this.ids = ids;
    this.sendDocuments();
  }

  stopSend() {
    this.stopSended = true;
    this.sendProgress = false;
  }

  /* Отправка списка контейнеров*/
  sendDocuments(): void {
    this.disabled = true;
    this.loader = true;

    const _iterate = this.iteratorObject(this.data);
    this.sendProgress = true;

    this.dossierSrv.generateTransactionsFes(this.ids).subscribe((result) => {
      this.data = result;
      this.docsInfo = this.data.docsInfo;
      this.containers = this.data.containers;
      if (this.stopSended) {
        this.stopSend();
        return;
      }

      if (this.data.completed) {
        this.stopSend();
        this.disabled = false; this.success = true; this.loader = false;
        return;
      }

      const step = (iterate) => {
        const next = iterate.next();
        if (!next.done || !this.stopSended) {
          setTimeout(() => info(iterate), 1000)
        } else {
          this.sendProgress = false;
        }
      }

      const info = (iterate) => {
        // проверяем информацию по отправке
        this.dossierSrv.generateFesInfo().pipe(
          ).subscribe((data) => {

            if (data.error !== null) {
              this.stopSend();
              this.error = data.error;
            }
            this.data = data;
            // если отправлен контейнер (completed=true)
            if (data.completed) {
              this.stopSend();
              this.disabled = false; this.success = true;this.loader = false;
            } else {
              step(iterate);
            }
          },
          err => {
            this.stopSended = true;
            this.error = err;
            this.disabled = false; this.success = true;this.loader = false;
          })
      }
      // информация по отправке
      info(_iterate)
    },
    (result) => {
      this.disabled = false;
      this.success = false;
      this.error = result.error;
      },
    () => { }
    );

  }

  iteratorObject(array) {
    let nextIndex = 0;
    return {
      next: function (): any {
        return nextIndex < array.length ?
          {object: array[nextIndex++], done: false} :
          {done: true};
      }
    }
  }

  /**
   * Сформировать отчет
   */
  downloadReport() {
    this.dossierSrv.downloadGenerateFesReport().subscribe(data => {
      if (data.type === 4) {
        this.fSaver.save(data.body, Utils.getFileNameFromContentDisposition(data));
      }
    });
  }
}

