import {Input} from '@angular/core';
import {DossierAccessEnum} from '@amlCore/enums';
import {AbstractControl, FormGroup} from '@angular/forms';
import {DossierService} from '../../../../services';
import {DictionaryService} from '@amlCore/services';
import {DossierBaseComponent} from '../dossierBase.component';
import {Unsubscribable} from 'rxjs';
import {AlertPanelService} from '@amlCore/components';

/**
 * Базовый класс для всех под компонентов в досье
 */
// TODO переименовать, т.к. не является компонентом
export abstract class DossierBaseSubComponent extends DossierBaseComponent {
  @Input() isReadOnly;
  @Input() typePage: DossierAccessEnum;
  @Input() formGroupApp: FormGroup;
  unsubList: Array<Unsubscribable> = [];
  isDisabled: boolean;
  showBfType = false;
  protected constructor(protected dossierService: DossierService,
                        protected dictSrv: DictionaryService,
                        protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  f(field: string) {
    return this.formGroupApp.get(field);
  }

  fGroup(field: string): FormGroup {
    return this.formGroupApp.get(field) as FormGroup;
  }

  /**
   * Проверка компонента
   * вызывает в шаблоне через tableItem.
   * @param dossierId root досье id
   * @deprecated
   */
  checkFLKComponent(dossierId?: string): void {
    this.checkFLKBase(this.formGroupApp, this.typePage, dossierId ?? this.dossierService.getDossierId());
  }

  /**
   * Проврка для валидности компонента для ITableItem
   * *реализуется в дочерних классах
   * @deprecated
   */
  isValidItem(): boolean {
    return this.formGroupApp.enabled ? this.formGroupApp.valid : true;
  }

  /**
   * Выключение контролов формы
   * @param controls - контролы
   */
  disableControls(...controls: AbstractControl[]): void {
    controls.forEach((control: AbstractControl) => control.disable({onlySelf: true}));
  }

  /**
   * Включение контролов формы
   * @param controls - контролы
   */
  enableControls(...controls: AbstractControl[]): void {
    controls.forEach((control: AbstractControl) => control.enable({onlySelf: true}));
  }

  /**
   * Записываем все подписки
   */
  addUnsubscribable(item: Unsubscribable): void {
    this.unsubList.push(item);
  }

  /**
   * При закрытии компонента отписываемся
   * Добавить unsubscribable() в ngOnDestroy
   */
  unsubscribable(): void {
    this.unsubList.forEach(item => item.unsubscribe());
  }

  isFieldReq(fieldName): boolean{
    const control = this.f(fieldName);
    return control?.validator && control.validator({} as AbstractControl)?.required;
  }

  /**
   * функция убирает пробел в начале текста
   * @param value значение
   * @param field поле FormControl
   */
  startSpaceRemove(value, field: string) {
    this.formGroupApp.get(field).patchValue(value.replace(/ +/g, ' ').trimStart());
  }
}
