import {CryptAPI} from "./interface";
import {CryptNpapi} from "./crypt.npapi";
import {CryptAsync} from "./crypt.async";
import {Injectable} from "@angular/core";
import {CryptUtils} from "./utils";

@Injectable({providedIn: "root"})
export class CryptBase {
    crypt: CryptAPI;
    constructor() {
        if (CryptUtils.isIE()) {
            this.crypt = new CryptNpapi();
        } else {
            this.crypt = new CryptAsync();
        }
    }
}
