import {Injectable} from '@angular/core';
import {CryptAPI, CryptBase, CryptVersionValidate} from "../core";
import {ReplaySubject} from "rxjs";

@Injectable({providedIn: "root"})
export class CryptographService {
    /**
     * Инофрмация по крипртографии (версия, свежесть версии, работоспособность)
     */
    private cryptInfoSubject = new ReplaySubject<CryptVersionValidate>(1);
    public cryptInfo = this.cryptInfoSubject.asObservable();
    /**
     * Признак что криптография запустилась
     */
    private isCryptEnableSubject = new ReplaySubject<{ state: boolean, textError?: string }>(1);
    public isCryptEnable = this.isCryptEnableSubject.asObservable();
    private isInitService = false;
    crypt: CryptAPI;

    constructor(private cryptBase: CryptBase) {
        this.crypt = cryptBase.crypt;
    }

    init() {
      try {
        if (this.isInitService) {
            return;
        }
        const _this = this;
        // @ts-ignore
        const canPromise = window.cadesplugin?.then; // в IE при разных подходах загрузки, может не успеть загрузиться polyfills Promise
        const errorFn = (error) => {
            _this.cryptInfoSubject.next({
                typeError: "error",
                textError: error,
                isPluginWorked: false,
                isPluginLoaded: false,
                isPluginEnabled: false
            } as CryptVersionValidate);
            _this.isCryptEnableSubject.next({state: false, textError: error});
        };
        if (canPromise) {
            // @ts-ignore
            window.cadesplugin?.then(() => {
                    _this.cryptBase.crypt.CheckForPlugIn().then((cvi: CryptVersionValidate) => {
                        _this.initSubject(cvi);
                    }, errorFn);
                },
                errorFn
            );
        } else {
            window.addEventListener("message", (event) => {
                    if (event.data === "cadesplugin_loaded") {
                        _this.cryptBase.crypt.CheckForPlugIn().then((cvi: CryptVersionValidate) => {
                            _this.initSubject(cvi);
                        }, errorFn);
                    } else if (event.data === "cadesplugin_load_error") {
                        errorFn("Криптоплагин не загружен");
                    }
                },
                false);
            window.postMessage("cadesplugin_echo_request", "*");
        }
      } catch (e) {
        console.warn(e)
      }
    }

    private initSubject(cvi: CryptVersionValidate) {
        this.cryptInfoSubject.next(cvi);
        this.isCryptEnableSubject.next({state: true});
        this.isInitService = true;
    }

  checkBrowser() {
    var ua = navigator.userAgent, tem,
      M = ua.match(/(opera|yabrowser|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return {name: 'IE', version: (tem[1] || '')};
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edg|YaBrowser)\/(\d+)/);
      if (tem != null)
        return {name: tem[1].replace('OPR', 'Opera'), version: tem[2]};
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null)
      M.splice(1, 1, tem[1]);
    return {name: M[0], version: M[1]};
  }
  // выводим только сообщение без ссылки, функционал имеется и может быть использован AML-750
  getMessageAfterInit(state) {
    // @ts-ignore
    const linkInstruction = '{urlFile}/cadesplugin.exe'
    if (state.textError === 'Истекло время ожидания загрузки плагина') {
      return `Плагин не загружен. Для работы с электронной подписью необходимо скачать и установить КриптоПро ЭЦП Browser plug-in.`
    } else if (state.textError === 'Плагин недоступен') {
      const browserType = this.checkBrowser();
      let link = linkInstruction
      switch (browserType.name.toLowerCase()) {
        case 'chrome' : {
          link = 'https://chrome.google.com/webstore/detail/cryptopro-extension-for-c/iifchhfnnmpdbibifmljnfjhpififfog?utm_source=chrome-app-launcher-info-dialog'
          break;
        }
        case 'firefox' : {
          link = 'href="https://www.cryptopro.ru/sites/default/files/products/cades/extensions/firefox_cryptopro_extension_latest.xpi'
          break;
        }
        case 'opera' : {
          link = 'https://addons.opera.com/extensions/download/cryptopro-extension-for-cades-browser-plug-in'
          break;
        }
        case 'yabrowser' : {
          link = 'https://addons.opera.com/ru/search/?developer=cryptopro'
          break;
        }
        case 'edg' : {
          link = 'https://chrome.google.com/webstore/detail/cryptopro-extension-for-c/iifchhfnnmpdbibifmljnfjhpififfog'
          break;
        }
      }
      return `Для работы с электронной подписью необходимо скачать и установить расширение для браузера КриптоПро ЭЦП.`
    }
  }
}
