import {Injectable} from "@angular/core";
import {ApiService, ValidatorService} from "@amlCore/services";
import {Observable} from "rxjs";
import {INotice} from "../../interfaces";
import {PageContentModel} from "@amlCore/models";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Utils} from "@amlCore/utils";

@Injectable()
export class NoticeService {
  /**
   * Кэш формы - для более гибкой работы проверки изменений формы
   */
  private formDataCache: string;

  constructor(private apiService: ApiService,
              private fb: FormBuilder,
              private validatorService: ValidatorService) {
  }

  /**
   * Получение оповещения по id
   */
  getNoticeById(id: string): Observable<INotice> {
    return this.apiService.get(`/api/v1/notices/${id}`);
  }

  /**
   * Метод перевода оповещения в статус исполнено
   */
  resolveNoticeById(id: string): Observable<INotice> {
    return this.apiService.get(`/api/v1/notices/${id}/resolve`);
  }

  /**
   * Сохранение текущего оповещения
   */
  saveDataNotice(params: any): Observable<INotice> {
    return this.apiService.post(`/api/v1/notices/save`, params);
  }

  /**
   * Получить список оповещений
   */
  getNoticesList(params: any): Observable<PageContentModel<INotice>> {
    return this.apiService.post("/api/v1/notices/get", params, 'id');
  }

  /**
   * Запрос признака наличия оповещений требующих разрешения
   */
  checkNoticesToBeResolve(): Observable<any> {
    return this.apiService.get("/api/v1/notices/isNeedSolutions");
  }

  /**
   * Форма оповещения
   */
  getForm(): FormGroup {
    return this.fb.group({
      action: [null, this.validatorService.getValidation({
        isReq: true
      })],
      user: this.fb.group({
        id: [null]
      }),
      executorId: null,
      executionDate: null
    });
  }

  /**
   * Сравниваем закешированные данные формы и текущие
   * @param data - текущие данные
   *
   * клонируем данные объекта (разрываем связь)
   * удаляем пустые и системные переменные
   * сортируем ключи и записываем представление в строке
   */
  checkFormDataCache(data: any): boolean {
    data = Utils.clone(data);
    Utils.deleteEmptyObj(data);
    const dataStr = JSON.stringify(Utils.sortObjectKey(data));
    return this.formDataCache === dataStr;
  }

  /**
   * Кешируем данные
   * @param data - данные
   *
   * клонируем данные объекта (разрываем связь)
   * удаляем пустые и системные переменные
   * сортируем ключи и записываем представление в строке
   */
  setFormDataCache(data: any): void {
    data = Utils.clone(data);
    Utils.deleteEmptyObj(data);
    this.formDataCache = JSON.stringify(Utils.sortObjectKey(data));
  }

  /**
   * Блокировка оповещения для редактирования
   * @param id - id оповещения
   */
  startEdit(id: string): Observable<any> {
    return this.apiService.get(`/api/v1/notices/startEdit/${id}`);
  }

  /**
   * Разблокировка оповещения для редактирования
   * @param id - id оповещения
   */
  stopEdit(id: string): void {
     this.apiService.get(`/api/v1/notices/stopEdit/${id}`).subscribe(data => {});
  }
}
