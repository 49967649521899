export interface DocTypeSearch {
    id: number;
    name: string;
    title: string;
}

export interface DocType {
    [key: string]: DocTypeSearch[];
}

interface FilterDescription {
    [key: string]: Object;
}

// ToDo доплнять при необходимости
const docTypesConstants: DocType = {
    strateg655p: [
        {
            id: 18,
            name: "SD_KO_OTKR_ZAKR_CHET_DEPO",
            title: "3732-У. ЭС об открытии(закрытии) счета депо",
        },
        {
            id: 19,
            name: "SD_KO_SECURITIES",
            title: "3732-У. ЭС о приобретении(отчуждении) ценных бумаг",
        },
        {
            id: 20,
            name: "SD_KO_IZM_CHET_DEPO",
            title: "3732-У. ЭС об изменении реквизитов счета депо",
        },
        {
            id: 21,
            name: "SD_KO_OTKR_ZAKR_AKKR",
            title: "3732-У. ЭС об открытии(закрытии) покрытого (депонированного) аккредитива",
        },
        {
            id: 22,
            name: "SD_KO_IZM_AKKRED",
            title: "3732-У. ЭС об изменении реквизитов покрытого (депонированного) аккредитива",
        },
        {
            id: 23,
            name: "SD_KO_IZM_CHET",
            title: "2446-У. Электронное сообщение об изменении отдельного счета",
        },
        {
            id: 24,
            name: "SD_KO_IZM_CHET_GOZ",
            title: "2446-У. Сообщение об изменении реквизитов счета (ГОЗ)",
        },
        {
            id: 25,
            name: "SD_KO_OTKR_ZAKR_CHET",
            title: "2446-У. Электронное сообщение об открытии/закрытии отдельного счета",
        },
        {
            id: 26,
            name: "SD_KO_OTKR_ZAKR_CHET_GOZ",
            title: "2446-У. Сведение банка об открытии (закрытии) счета (ГОЗ)",
        },
    ],
    strateg5392u: [{ id: 17, name: "OTKAZ_GOZ", title: "5392-У. ОЭС об отказе ГОЗ" }],
    fm: [
        { id: 0, name: "OPERATIONS", title: "5861-У. Сведения об операциях" },
        {
            id: 1,
            name: "OBSTRUCTION_FACTS",
            title: "5861-У. ФЭС о фактах воспрепятствования",
        },
        {
            id: 2,
            name: "SUSPICIOUS_ACTIVITY",
            title: "5861-У. ФЭС о подозрительной деятельности",
        },
        {
            id: 3,
            name: "REFUSE_CONTRACT",
            title: "5861-У. Отказ от заключения договора (расторжение)",
        },
        {
            id: 4,
            name: "REFUSE_OPERATION",
            title: "5861-У. Отказ в совершении операции",
        },
        {
            id: 5,
            name: "VERIFICATION_RESULT",
            title: "5861-У. Сведения о результатах проверки",
        },
        {
            id: 6,
            name: "FREEZING_FUNDS",
            title: "5861-У. Сведения о принятых мерах по замораживанию (блокированию)",
        },
    ],
    elMsgFm: [
        { id: 10, name: "P407_ANSWER", title: "Электронное сообщение" },
        {
            id: 0,
            name: "REGISTRY_REPORT",
            title: "Электронное сообщение Росфинмониторинга",
        },
        {
          id: 9,
          name: "P407_REQUEST",
          title: "Квитанция о принятии/непринятии электронного сообщения Росфинмониторинга",
      },
    ],
};

// ToDo доплнять при необходимости
const docStatuses: DocType = {
    created: [{ id: 0, name: "CREATED", title: "Создан" }],
    deleted: [{ id: 8, name: "DELETED", title: "Удален" }],
    fmSend: [
        { id: 2, name: "SENDED", title: "Отправлен" },
        { id: 3, name: "ACCEPTED", title: "Принят" },
        { id: 4, name: "ACCEPTEDPART", title: "Принят частично" },
        { id: 5, name: "REJECTED", title: "Отвергнут" },
        { id: 6, name: "UPLOADEDSHIPPING", title: "Выгружен для отправки" },
    ],
    fmArchive: [
      { id: 2, name: "SENDED", title: "Отправлен" },
      { id: 3, name: "ACCEPTED", title: "Принят" },
      { id: 4, name: "ACCEPTEDPART", title: "Принят частично" },
      { id: 9, name: "FROM_EXTERNAL", title: "Принят ВС" },
      { id: 5, name: "REJECTED", title: "Отвергнут" },
      { id: 6, name: "UPLOADEDSHIPPING", title: "Выгружен для отправки" },
    ],
    strategSend: [
        { id: 2, name: "SENDED", title: "Отправлен" },
        { id: 3, name: "ACCEPTED", title: "Принят" },
        { id: 5, name: "REJECTED", title: "Отвергнут" },
        { id: 6, name: "UPLOADEDSHIPPING", title: "Выгружен для отправки" },
    ],
    strateg655pArchive: [
        { id: 2, name: "SENDED", title: "Отправлен" },
        { id: 3, name: "ACCEPTED", title: "Принят" },
        { id: 4, name: "ACCEPTEDPART", title: "Принят частично" },
        { id: 9, name: "FROM_EXTERNAL", title: "Принят из ВС" },
        { id: 5, name: "REJECTED", title: "Отвергнут" },
        { id: 6, name: "UPLOADEDSHIPPING", title: "Выгружен для отправки" },
    ],
    strateg5392uArchive: [
        { id: 2, name: "SENDED", title: "Отправлен" },
        { id: 3, name: "ACCEPTED", title: "Принят" },
        { id: 4, name: "ACCEPTEDPART", title: "Принят частично" },
        { id: 9, name: "FROM_EXTERNAL", title: "Принят из ВС" },
        { id: 5, name: "REJECTED", title: "Отвергнут" },
        { id: 6, name: "UPLOADEDSHIPPING", title: "Выгружен для отправки" },
    ],
    elMsgFmArchive: [
        { id: 2, name: "SENDED", title: "Отправлен" },
        { id: 99, name: "ACCEPTEDTU", title: "Принят ТУ" },
        { id: 98, name: "REJECTEDTU", title: "Отвергнут ТУ" },
        { id: 4, name: "ACCEPTEDPART", title: "Принят частично" },
        { id: 5, name: "REJECTED", title: "Отвергнут" },
        { id: 6, name: "UPLOADEDSHIPPING", title: "Выгружен для отправки" },
    ],
};

const filterDescription: FilterDescription = {
    strateg655pArchive: {
        documentType: "Документ",
        documentStatus: "Статус",
        dateFrom: "Дата сообщения с",
        dateTo: "Дата сообщения по",
        creatorId: "Владелец документа",
        containerName: 'Наименование транспортного контейнера',
    },
    strateg5392uArchive: {
      documentType: "Документ",
      documentStatus: "Статус",
      dateFrom: "Дата сообщения с",
      dateTo: "Дата сообщения по",
      creatorId: "Владелец документа",
    },
    fmArchive: {
      documentType: "Документ",
      documentStatus: "Статус",
      dateFrom: "Дата сообщения с",
      dateTo: "Дата сообщения по",
      creatorId: "Владелец документа",
      search: "Идентификатор",
      containerName: "Контейнер",
    },
    elMsgFmArchive: {
      documentType: "Документ",
      documentStatus: "Статус",
      dateFrom: "Дата сообщения с",
      dateTo: "Дата сообщения по",
      creatorId: "Владелец документа",
    },
}

export const getDocTypes = (type: string) => docTypesConstants[type] || [];
export const getDocStatuses = (type: string) => docStatuses[type] || [];
export const getFilterDesc = (type: string) => filterDescription[type] || [];
