import {ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot} from "@angular/router";
import { Location } from '@angular/common';
import {DossierComponent} from "../components/clientDossierList/dossier";
import {DossierService} from "./dossier.service";
import {Injectable} from "@angular/core";
import {ConfirmComponent} from "@amlCore/modals";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Observable} from "rxjs";

/**
 * Гуард проверки выхода из компонента
 */
export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  canDeactivate(component: CanComponentDeactivate,
                route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot) {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}