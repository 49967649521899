import {ClientTypeType, DossierBool} from "./base.model";
import {SvedULType} from "./svedULType.model";
import {SvedFLType} from "./svedFLType.model";
import {SvedIPType} from "./svedIPType.model";
import {SvedFLCPType} from "./svedFLCPType.model";
import {SvedInbULType} from "./svedInbULType.model";
import {PartItemArray} from "../partItemArray.model";

export class ClientInfo extends PartItemArray {
  clientType: ClientTypeType = '';
  rezidentRf: DossierBool = false;
  rezidentUsa?: DossierBool = false;
  rezidentFS?: DossierBool = false;
  clientId: any = '';
  version: any = '';
  // items choice
  svedUL?: SvedULType = new SvedULType();
  svedFL?: SvedFLType = new SvedFLType();
  svedIP?: SvedIPType = new SvedIPType();
  svedFLCP?: SvedFLCPType = new SvedFLCPType();
  svedInbUL?: SvedInbULType = new SvedInbULType();
}
