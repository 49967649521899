import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-modal-confirm',
  template: `
    <div class="modal-header">
      <h4 class="modal-title" id="modal-title">{{title}}</h4>
      <button [hidden]="hideCloseBtn" type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
              (click)="modal.dismiss(false)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
      <div [hidden]="isOutputText" class="modal-body">
        <fa-icon *ngIf="showWarningIcon" [icon]="icons.warning" class="text-warning"></fa-icon>
        <ng-container *ngIf="!isNeedCustomText; else custom">{{text}}</ng-container>
      </div>
      <div [hidden]="!isOutputText" class="modal-body">
        <label for="comment">{{text}} <span class="aml-rq-red">*</span>:</label>
        <textarea class="form-control" placeholder="Комментарий" rows="5" [(ngModel)]="outputText"></textarea>
      </div>
    <div class="modal-footer">
      <button type="button" ngbAutofocus class="btn btn-success" [disabled]="isOutputText && !outputText"
              (click)="modal.close(outputText || true)">{{okBtn}}
      </button>
      <button *ngIf="!hideCancelBtn" type="button" class="btn btn-outline-secondary" (click)="modal.dismiss(false)">{{cancelBtn}}</button>
    </div>
    <ng-template #custom>
      Сессия пользователя <strong>{{text}}</strong> будет завершена
    </ng-template>
  `
})
export class ConfirmComponent {
  @Input() text;
  @Input() title = "Сообщение";
  @Input() okBtn = "Ок";
  @Input() cancelBtn = "Отмена";
  @Input() isOutputText = false;
  @Input() hideCloseBtn = false;
  @Input() showWarningIcon = false;
  @Input() isNeedCustomText = false
  @Input() hideCancelBtn = false;
  outputText: string;
  icons = {
    warning: faExclamationTriangle
  };
  constructor(public modal: NgbActiveModal) {
  }
}
