import { Component, OnInit } from "@angular/core";
import { BaseFilterController } from "../BaseFilterController";
import { FormBuilder } from "@angular/forms";
import { CheckClientsFilter } from "@amlCore/models";
import {DictionaryService, ResourceService, TableService} from "@amlCore/services";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-check-clients-filter",
  templateUrl: "./checkClientsFilter.component.html",
})

export class CheckClientsFilterComponent extends BaseFilterController implements OnInit {
  public selectObj = {
    resultCheckList: [],
    msAdrInfoList: [],
    msFoundInfoList: [],
    msLeadInfoList: [],
    passportInfoList: [],
    paycardInfoList: [],
    fromuInfoList: [],
    mvkInfoList: [],
    terrorInfoList: [],
    otkazInfoList: [],
    riskGroupInfoList: [],
    ftsInfoList: [],
    criticallists: [],
    typesRiskList: [],
    levelsRiskList: [],
    codeMessage4936U: [],
  };
  public filterObjectModel = new CheckClientsFilter();
  public dictTypeInfoList = ['mvk', 'terror', 'fromu',
                             'otkaz', 'fts', 'msfound',
                             'mslead', 'ms_adr_info', 'passport', 'paycard', 'riskGroup'];
  placeholder = 'Поиск по ФИО, наименованию, ИНН, ОГРН';

  constructor(private fb: FormBuilder,
              private dictSrv: DictionaryService,
              protected tableSrv: TableService,
              private resourceSrv: ResourceService,
              protected activatedRoute: ActivatedRoute) {
    super(tableSrv, activatedRoute);
  }

  ngOnInit() {
    this.init();
    this.dictSrv.getRemoteData('criticallists').then(result => {
      this.selectObj.criticallists = result;
    });
    this.dictSrv.getRemoteData('resultchecking').then(result => {
      this.selectObj.resultCheckList = result;
    });
    this.dictSrv.getRemoteData('codeMessage4936U').then(result => {
      this.selectObj.codeMessage4936U = result;
    });
    this.dictSrv.getRemoteData('risktype').then((result) => {
      this.selectObj.typesRiskList = result.map((item: any) => {return {id: item.code, name: item.name};});
    });
    this.dictSrv.getRemoteData('risklevel').then((result) => {
      this.selectObj.levelsRiskList = result.map((item: any) => {return {id: item.code, name: item.name};})
                                            .sort(this._sortAsc);
    });
    if (this.urlData && (this.urlData as any).dictType) {
      this.onLoadVersion({id: (this.urlData as any).dictType});
    }
  }

  /**
   * Метод создания формы
   */
  public createFormModel() {
    return this.fb.group(new CheckClientsFilter());
  }

  /**
   * Метод загрузки версий перечня
   * @params id тип загружаемого справочника
   */
  public onLoadVersion(data) {
    const code = data?.code;
    const getDescription = (mapper) => ({
        id: mapper.id,
        name: `Дата перечня ${mapper.dateImport}`
      });

    const getDescriptionWithDate = (mapper, type) => ({
      id: mapper.id,
      name: `Дата перечня ${mapper[type]}. Дата и время импорта ${mapper.sysRegDate || mapper.uploaded || mapper.date}`
    });

    if (code) {
      this.formModelService.clearFields([
        "msAdrInfo",
        "msFoundInfo",
        "msLeadInfo",
        "passportInfo",
        "paycardInfo",
        "riskGroupInfo",
        "fromuInfo",
        "mvkInfo",
        "terrorInfo",
        "otkazInfo",
        "ftsInfo",
      ]);

      const fnLoad = ({terror: this.loadVersionData.bind(this, "terrorInfoList", "getTerroristFiles",
        (mapper) => (getDescriptionWithDate.call(this, mapper, "data"))),
        mvk: this.loadVersionData.bind(this, "mvkInfoList", "getMvkInfoList",
            (mapper) => getDescriptionWithDate.call(this, mapper, "dataMvk")),
        fromu: this.loadVersionData.bind(this, "fromuInfoList", "getFromuInfoList",
            (mapper) => getDescriptionWithDate.call(this, mapper, "dataFromu")),
        otkaz: this.loadVersionData.bind(this, "otkazInfoList", "getBounceInfoList",
            (mapper) => getDescriptionWithDate.call(this, mapper, "dateotkaz")),
        riskGroup: this.loadVersionData.bind(this, "riskGroupInfoList", "getRiskGroupInfo",
            (mapper) => getDescriptionWithDate.call(this, mapper, "risksReportDate")),
        fts: this.loadVersionData.bind(this, "ftsInfoList", "getFtsInfo", getDescription),
        msfound: this.loadVersionData.bind(this, "msFoundInfoList", "getMassFoundInfo", getDescription),
        mslead: this.loadVersionData.bind(this, "msLeadInfoList", "getMassLeadInfo", getDescription),
        ms_adr_info: this.loadVersionData.bind(this, "msAdrInfoList", "getMassAdrInfo", getDescription),
        passport: this.loadVersionData.bind(this, "passportInfoList", "getPassportInfo", getDescription),
        paycard: this.loadVersionData.bind(this, "paycardInfoList", "getPaycards", getDescription),
        internal: this.loadVersionData.bind(this, "passportInfoList", "getPassportInfo", getDescription)
      })[code];
      if (fnLoad instanceof Function) { fnLoad(); }
    }

  }

  private _sortAsc(itemA: {id: string; name: string}, itemB: {id: string; name: string}): number {
    return parseInt(itemA.id, 10) - parseInt(itemB.id, 10);
  }

  private loadVersionData(dictionary: string, resource: string, getDescriptionFn: (mapper: any) => object) {
    if (this.selectObj[dictionary] && this.selectObj[dictionary].length > 0) {
      return;
    }
    this.resourceSrv[resource]({}).subscribe((data) => {
      this.selectObj[dictionary] = (data?.content || data).map((mapper) => {
        return getDescriptionFn(mapper);
      });
    });
  }

  getStartFilterModel(): any {
    return null;
  }

  /**
   * Описания полей
   */
  public getFilterDescription(): any {
    return {
      checkResult : 'Результат проверки',
      dictType : 'Вид перечня',
      mvkInfo : 'Версия перечня',
      terrorInfo : 'Версия перечня',
      fromuInfo : 'Версия перечня',
      otkazInfo : 'Версия перечня',
      ftsInfo : 'Версия перечня',
      msFoundInfo : 'Версия перечня',
      msLeadInfo : 'Версия перечня',
      msAdrInfo : 'Версия перечня',
      passportInfo : 'Версия перечня',
      type : 'Тип риска',
      level : 'Уровень риска',
      periodCheckStart : 'Период проверки C',
      periodCheckEnd : 'Период проверки ПО',
      subject: {
        typePart : 'Тип субъекта',
        clientType: 'Тип организации / лица',
        legalEntity : {
          orgName: 'Наименование организации',
          ogrn: 'ОГРН',
          inn: 'ИНН',
          kpp: 'КПП'
        },
        individualPerson : {
          lastName: 'Фамилия',
          firstName: 'Имя',
          middleName: 'Отчество',
          birthDate: 'Дата рождения',
          series: 'Серия документа',
          numDoc: 'Номер документа',
          inn: 'ИНН',
          ogrn: 'ОГРН'
        },
        foreignStructure: {
          orgName: 'Наименование организации',
          regNum: 'Регистрационный номер'
        }
      }
    }
  }
}
