import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {DossierService} from "../../../../../services";
import {AbstractControl} from "@angular/forms";
import {DictType} from "../../../../../models/dossier";
import {Unsubscribable} from "rxjs";
import {DictionaryService} from "@amlCore/services";
import {DossierBaseModel} from "../../dossierBaseModel";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {AlertPanelService, BigSelectComponent} from "@amlCore/components";
import {DossierAccessEnum} from "@amlCore/enums";

@Component({
  selector: 'app-public-info-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './publicInfoDossier.component.html'
})
export class PublicInfoDossierComponent extends DossierBaseSubComponent implements OnInit, OnDestroy {
  private readonly _characterPDLPublicOfficial = '1';
  listComp: Array<AbstractControl> = [];
  dochodsList: Array<any> = [];
  characterPDLList: Array<DictType> = [];
  rodPDLList: Array<DictType> = [];
  postRPDLList: Array<DictType> = [];
  postRPDLValueChanges: Unsubscribable;
  prvValueChanges: Unsubscribable;
  characterPDLValueChanges: Unsubscribable;
  istDochodValueChanges: Unsubscribable;
  dossierAccessEnum = DossierAccessEnum;
  isViewFIO = true;

  otherDochodShow = false;
  @ViewChild('istDochod') istDochod: BigSelectComponent;

  constructor(protected dossierService: DossierService,
              protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  ngOnInit() {
    this.dochodsList = this.dictSrv.getStaticData('dochods');
    this.dictSrv.getRemoteData('affiliationpdl', {
      size: 20000,
      page: 0,
      sort: 'key.code',
      dir: 'ASC'
    }).then(result => {
      this.characterPDLList = result;
    });
    this.dictSrv.getRemoteData("postRPDL").then((result) => {
      this.postRPDLList = result;
    });
    this.rodPDLList = this.dictSrv.getStaticData('rodPDL');
    setTimeout(() => {
      this.initListPvr();
      this.prvValueChanges = this.f('prv').valueChanges.subscribe(value => this.initPvr(value));
      this.initPvr(this.f('prv').value);
      this.addUnsubscribable(this.prvValueChanges);
      this.characterPDLValueChanges = this.f('characterPDL').valueChanges.subscribe(value => {
        setTimeout(() => {
          this.initCharacterPDL(value);
          this._initFIO();
        });
      });
      if (this.f('characterPDL').value && this.f('characterPDL').value.code) {
        this.initCharacterPDL(this.f('characterPDL').value);
      }
      this.addUnsubscribable(this.characterPDLValueChanges);
      this.initPostRPDL(this.f('postRPDL').value);
      this.postRPDLValueChanges = this.f('postRPDL').valueChanges.subscribe(val => {
        this.initPostRPDL(val);
      });
      this.addUnsubscribable(this.postRPDLValueChanges);
      this.initIstDochod(this.f('istDochod').value);
      this.istDochodValueChanges = this.f('istDochod').valueChanges.subscribe(value => {
        this.initIstDochod(value);
      });
      this.addUnsubscribable(this.istDochodValueChanges);
    });
    this.focusObject.istDochod = false;
  }

  /**
   * Проверяем список istDochod
   * если есть код 8 необходимо отобразить поле otherDochod
   * @param values - список выбранных значений в istDochod
   */
  initIstDochod(values: Array<DictType>) {
    if (!this.isReadOnly) {
      const otherDochod = this.f('otherDochod');
      otherDochod.disable();
      this.otherDochodShow = false;
      if (!values) {
        return;
      }
      values.forEach(value => {
        if (value.code === '8') {
          otherDochod.enable();
          this.otherDochodShow = true;
        }
      });
    }
  }

  private _initFIO(): void {
    const characterPDLValue = this.f('characterPDL').value;
    this.isViewFIO = characterPDLValue && characterPDLValue.code !== this._characterPDLPublicOfficial;
  }

  initPostRPDL(value) {
    if (!this.isReadOnly) {
      const other = this.f('postOther');
      other.disable();
      if (value) {
        const type = value.code;
        if (type) {
          if (type === "99") {
            other.enable();
          }
        }
      }
    }
  }

  /**
   * В зависимости от значение prv скрываем/показываем удаляем поля из модели
   * @param value - значение prv
   */
  initPvr(value) {
    if (!this.isReadOnly) {
     setTimeout(() => {
       this.listComp.forEach((item) => {
         if (value === false) {
           item.disable();
         } else {
           item.enable();
         }
       });
     });
    }
  }

  /**
   * Инициализация списка полей зависящих от prv
   */
  private initListPvr() {
    this.listComp.push(this.f('characterPDL'));
    this.listComp.push(this.f('rodPDL'));
    this.listComp.push(this.f('postRPDL'));
    this.listComp.push(this.f('postOther'));
    this.listComp.push(this.f('post'));
    this.listComp.push(this.f('nameWork'));
    this.listComp.push(this.f('address'));
    this.listComp.push(this.f('istDochod'));
    this.listComp.push(this.f('otherDochod'));
    this.listComp.push(this.f('fio'));
  }

  /**
   * Удаляем поля из модели зависящие от characterPD
   * @param value - значение characterPD
   */
  initCharacterPDL(value) {
    if (!this.isReadOnly) {
      this.f('rodPDL').disable();
      this.f('postRPDL').disable();
      this.f('post').disable();
      this.f('fio').disable();
      this.f('istDochod').disable();
      this.f('otherDochod').disable();
      if (value) {
        const type = value.code;
        if (type) {
          if (["2", "4", "6"].includes(type)) {
            this.f('rodPDL').enable();
            this.f('fio').enable();
          }
          if (["3", "4"].includes(type)) {
            this.f('postRPDL').enable();
            this.f('istDochod').enable();
            this.f('otherDochod').enable();
          }
          if (["1", "2", "5", "6"].includes(type)) {
            this.f('post').enable();
          }
        }
      }
    }
  }
  openIstDochod(event){
    this.istDochod.open();
  }
  ngOnDestroy() {
    this.unsubscribable();
  }

}

