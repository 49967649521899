import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SharedMenuService {
  private menuSubject = new ReplaySubject<boolean>();
  public menuObservable = this.menuSubject.asObservable();

  public emit(isPinned: any) {
    this.menuSubject.next(isPinned);
  }

  public getSubscribe(): Observable<boolean> {
    return this.menuObservable;
  }
}
