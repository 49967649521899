import {Injectable} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {filter} from 'rxjs/operators';

import {Alert, AlertType} from './alert.model';

@Injectable({providedIn: 'root'})
export class AlertService {
  private subject = new Subject<Alert>();
  private keepAfterRouteChange = false;

  constructor(private router: Router) {
    // сбрасываем предупреждающие сообщения при изменении маршрута, если флаг keepAfterRouteChange не установлен
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          //  сохранить только для одного изменения маршрута
          this.keepAfterRouteChange = false;
        } else {
          // clear alert messages
          this.clear();
        }
      }
    });
  }

  // enable subscribing to alerts observable
  onAlert(alertId?: string): Observable<Alert> {
    return this.subject.asObservable().pipe(filter(x => x && x.alertId === alertId));
  }

  success(message: string, alertId?: string) {
    this.alert(new Alert({message, type: AlertType.Success, alertId}));
  }

  error(message: string, errorText?: string, alertId?: string, closeTimer?: number, keepAfterRouteChange?: boolean) {
    this.alert(new Alert({message, type: AlertType.Error, alertId, errorText, closeTimer, keepAfterRouteChange}));
  }

  errorForm(messageObject: Array<object>, alertId?: string) {
    this.alert(new Alert({messageObject, type: AlertType.Error, alertId}));
  }

  info(message: string, alertId?: string) {
    this.alert(new Alert({message, type: AlertType.Info, alertId}));
  }

  warn(message: string, alertId?: string) {
    this.alert(new Alert({message, type: AlertType.Warning, alertId}));
  }

  alert(alert: Alert) {
    this.keepAfterRouteChange = alert.keepAfterRouteChange;
    this.subject.next(alert);
  }

  clear(alertId?: string) {
    this.subject.next(new Alert({alertId}));
  }

  point(name) {
    const invalidControl: any = document.querySelector('[formcontrolname="' + name + '"]');
    if (invalidControl && invalidControl.focus) {
      invalidControl.focus();
    }
  }
}
