import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PartnerType} from "../../../../models/dossier";
import {DossierService} from "../../../../services";
import {DossierBasePageComponent} from "../dossierBasePage.component";
import {IDossierSubService} from "../IDossierSub.service";
import {
  AlertPanelService,
  ITable,
  ITableCallBack,
  ITableItemDescriptor,
  TableCompComponent
} from "@amlCore/components";
import {PartnerInfoDossierComponent} from "../components/partnerInfo";
import {DictionaryService, UserService} from "@amlCore/services";
import {PartnerDossierService} from "./partnerDossier.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FileSaverService} from "ngx-filesaver";


@Component({
  selector: 'app-partner-dossier',
  styleUrls: ['../dossier.css'],
  templateUrl: './partnerDossier.component.html'
})
export class PartnerDossierComponent extends DossierBasePageComponent implements OnInit, ITable {

  modelPage: Array<PartnerType>;
  itemDescriptor: ITableItemDescriptor;
  isTableTab = true;
  @ViewChild('tableComp', {static: true}) tableComp: TableCompComponent;

  constructor(protected dossierService: DossierService,
              protected route: ActivatedRoute,
              private partnerDossierService: PartnerDossierService,
              protected dictSrv: DictionaryService,
              protected router: Router,
              protected userService: UserService,
              protected alertPanelSrv: AlertPanelService,
              protected modalService: NgbModal,
              protected fSaver: FileSaverService) {
    super(dossierService, route, dictSrv, router, userService, alertPanelSrv, modalService, fSaver);
  }

  ngOnInit(): void {
    // Инициализация вкладки
    this.modelPage = this.initPage();
    // Описание элемента таблицы
    this.itemDescriptor = {
      create: this.partnerDossierService.getPartner.bind(this.partnerDossierService),
      component: PartnerInfoDossierComponent,
      params: {
        typePage: this.typePage
      }
    } as ITableItemDescriptor;
  }

  getService(): IDossierSubService {
    return this.partnerDossierService;
  }

  callbackItemClosed(event: ITableCallBack): void {
    this.saveTableItem(event);
  }

  destroy(): void {
    // ТУТ писать реализацию ngOnDestroy для компонента

  }
}
