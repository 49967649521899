import {Component, OnInit, ViewChild} from "@angular/core";
import {DossierService} from "../../../../../services";
import {DictionaryService} from "@amlCore/services";
import {AlertPanelService, ITable, ITableCallBack, ITableItemDescriptor, TableCompComponent} from "@amlCore/components";
import {DossierBaseModel} from "../../dossierBaseModel";
import {FormBuilder} from "@angular/forms";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {ErrorFLKModel} from "@amlCore/models";
import {UpdateCardInfo} from "../../../../../models/dossier";
import {UpdateCardInfoDossierComponent} from "../updateCardInfo";

@Component({
  selector: 'app-update-card-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './updateCardDossier.component.html'
})
export class UpdateCardDossierComponent extends DossierBaseSubComponent implements OnInit, ITable {

  @ViewChild('tableComp') tableComp: TableCompComponent;
  itemDescriptor: ITableItemDescriptor;

  constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
              private dossierBaseModel: DossierBaseModel, protected formBuilder: FormBuilder,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  ngOnInit() {
    // Описание элемента таблицы
    this.itemDescriptor = {
      create: this.dossierBaseModel.getUpdateCardInfo.bind(this.dossierBaseModel),
      component: UpdateCardInfoDossierComponent,
      params: {
        typePage: this.typePage
      }
    } as ITableItemDescriptor;
  }

  callbackItemClosed(event: ITableCallBack) {
  }
  openWindow(error: ErrorFLKModel){
    let index = 0;
    // Считываем row и ищем по нему index
    if (error && error.row !== null) {
      (this.formGroupApp.value as Array<UpdateCardInfo>).forEach((value, i) => {
        if (value.row === error.row) {
          index = i;
        }
      });
    }
    this.tableComp.showFlKError(index, error);
  }

}