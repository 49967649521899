import {Component, ContentChild, forwardRef, Input, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {IFocus} from "@amlCore/interfaces";
import {NgSelectComponent} from "@ng-select/ng-select";

/**
 * Компонент списков работающих с объектом типа DictType или по описанию маппера
 *
 * Через параметры name и code указываются имена переменных в объекте списка
 */
@Component({
    selector: 'app-custom-select',
    templateUrl: './customSelect.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CustomSelectComponent),
        multi: true,
    }],
})
export class CustomSelectComponent implements ControlValueAccessor, IFocus, OnInit {
    model: any;
    // Исходный массив аналогично items из ng-select
    @Input() items: Array<any>;
    // Имя переменой из объека, которая выступает в качесве name
    @Input() name = 'name';
    // Имя переменой из объека, которая выступает в качесве code
    @Input() code = 'code';
    @Input() multiple = false;
    @Input() appendTo = 'body';
    @Input() isInvalid;
    @Input() getDescriptor: ICustomSelectMapper;
    @Input() returnOnlyId = false;

    // Массив аналогично items из ng-select для отображения
    filteredItems: Array<any>;

    @ContentChild('labelTemplate') labelTemplate: TemplateRef<any>;
    @ContentChild('optionTemplate') optionTemplate: TemplateRef<any>;
    @ViewChild('ngSelect') ngSelect: NgSelectComponent;
    // Ключи по котором искать в поиске
    @Input() fieldsFromSearch: string | Array<string>;
    @Input() isDisabled = false;
    isChange = false;
    // Подготавливаем пустые функции, в которые потом акссесор внедрит свои реализвации с помощью registerOnChange и registerOnTouched

    ngOnInit(): void {
        this.filteredItems = this.items
    }
    onChange = (result: any) => {
    }
    onTouch = () => {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }


    /**
     * Получаем переменную из родительского компонента и преобразуем в вид который нам надо
     */
    writeValue(outsideValue: any) {
        this.isChange = false;
        // Получить из Forms API
        let newModel = null;
        if (outsideValue || (outsideValue instanceof Array && outsideValue.length > 0)) {
            newModel = this.getNewModel(outsideValue);
            // TODO: поднимать переменную по части из списка (если указан только code)
        }
        this.model = newModel;
    }

    /**
     * Меняем руками и сообщаем Forms API об обновлении
     */
    updateValue(insideValue: any) {
        this.isChange = true;
        // Сообщаем html новую переменную для отображения
        // this.model = insideValue;//добавить при необходимостри
        let newModel = null;
        if (insideValue) {
            // для multiple списков
            if (insideValue instanceof Array) {
                newModel = [];
                if (insideValue.length > 0) {
                    newModel = this.getNewModel(insideValue);
                    
                    // Сброс инпут поля при выборе в селекте
                    if (!!this.ngSelect.searchInput.nativeElement.value) {
                        this.ngSelect.searchInput.nativeElement.value = ''
                        this.ngSelect.searchEvent.emit({
                            term: '',
                            items: this.items
                        })
                    }
                }
            } else {
                // для обычного списка
                newModel = this.getNewModel(insideValue);
            }
        }
        // Сообщаем Forms API
        this.onChange(newModel);
    }

    /**
     * Преобразуем модель
     */
    getNewModel(oldModel) {
        // возвращаем только id выбранного значения
        if (this.returnOnlyId) {
          return oldModel;
        }
        // обработка для multiple списков
        if (oldModel instanceof Array) {
            const newArray = [];
            oldModel.forEach((item) => {
                newArray.push(this.getNewObj(item));
            });
            return newArray;
        }

        return this.getNewObj(oldModel);
    }

    /**
     * преобразуем в формат:
     * {name:value1, code:value2}
     * или по описанию mapObj
     * @param origin - полный объект
     */
    private getNewObj(origin) {
        let newObj: any;
        newObj = {
            name: origin[this.name],
            code: origin[this.code]
        };
        if (this.getDescriptor) {
            newObj = this.getDescriptor.toModel(origin, this.isChange);
        }
        return newObj;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    /**
     * Свой поиск
     * обернули в функцию что бы получить конекст this
     */
    customSearchFn() {
        /**
         * @param term - строка поиска из select
         * @param item - элемент списка
         */
        return (term: string, item: any) => {
            if (term && item) {
                term = term.toLocaleLowerCase();
                let result: boolean;
                if (this.fieldsFromSearch) {
                    result = this.searchByCustomField(term, item);
                } else {
                    result = item.name.toLocaleLowerCase().indexOf(term) > -1;
                }
                return result;
            }
            return true;
        };
    }

    /**
     * Поиск по заданым именам объекта
     * @param term - строка поиска из select
     * @param item - элемент списка
     */
    searchByCustomField(term: string, item: any): boolean {
        let result = false;
        if (this.fieldsFromSearch instanceof Array) {
            this.fieldsFromSearch.forEach(searchField => {
                if (!result) {
                    result = item[searchField].toLocaleLowerCase().indexOf(term) > -1;
                }
            });
        } else {
            result = item[this.fieldsFromSearch].toLocaleLowerCase().indexOf(term) > -1;
        }
        return result;
    }

    focus(): void {
        this.ngSelect.focus();
    }

    get tooltip(): string | undefined {
      // возвращаем название объекта по пришедшему id (model)
      return this.items?.find(
        ((item) => item?.[this.code] && item?.[this.code] === this.model)
      )?.[this.name];
    }
    onSearch(event: {term: string, items: Array<any>}) {
        const term = event.term
        const customFunc = this.customSearchFn()
        this.filteredItems = this.items.filter(item => customFunc(term, item))
    }

    onFocus($event: Event) {
        this.filteredItems = this.items
	}
}

/**
 * Интерфейс описания своего маппера для app-custom-select
 */
export interface ICustomSelectMapper {
    /**
     * Функция преобразования объекта списка для модели
     * Использовать с .bind(this) для правильного понимания контекста this
     * * где this Класс реализующий метод
     * @param origin - объект списка
     * @param isDictData - признак того, что работаем с элементом списка, а не с данными модели
     */
    toModel: (origin: any, isDictData: boolean) => any;
    // Методы toView и toViewOption больше не нужны - используем <ng-template>
}
