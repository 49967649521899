import {BaseFilterModel} from "./baseFilter.model";

export class BounceFilter  extends BaseFilterModel {
  id = '';
  otkazKod = null; // Код отказа
  subject = {
    subjectType: null, // Тип субъекта
    legalEntity: { // Юридическое лицо
      name: '',
      inn: '',
      ogrn: '',
    },
    individualPerson: { // Физическое лицо
      name: '',
      inn: '',
      birthDate: '',
      series: '',
      number: '',
    },
    individualEntrepreneur: { // Индивидуальный предприниматель
      name: '',
      inn: '',
      ogrn: '',
      birthDate: '',
      series: '',
      number: '',
    },
    foreignStructure: { // Иностранная структура
      name: '',
      inboul: '',
    }
  };
}
