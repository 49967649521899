export * from './dictionary-list.component';
export * from './views/common';
export * from './tables/qrefCbBnkseek';
export * from './tables/qrefBanc';
export * from './tables/qrefFil';
export * from './tables/qrefObl';
export * from './tables/qrefOkato1';
export * from './tables/qrefOgrn';
export * from './tables/qrefOkcm';
export * from './tables/qrefOkcmNotlegacy';
export * from './tables/qrefOkwed';
export * from './tables/qrefOkwed2';
export * from './tables/qrefSwift';
export * from './tables/qrefTuCb';
export * from './tables/qrefValut';
export * from './views/qref-tucode4936.component';
export * from './views/qref-pidTerUch.component';
export * from './views/qref-pipdl4936.component';
export * from './views/qref-fltype4936.component';
export * from './views/qref-prizndoc4936.component';
export * from './views/qref-dokt4936.component';
export * from './views/qref-action4936.component';
export * from './views/qref-action4077.component';
export * from './views/qref-pd5861.component';
export * from './views/qref-coduch4936.component';
export * from './views/qref-codUch4077.component';
export * from './views/qref-bArx.component';
export * from './views/qref-belongingAccounts.component';
export * from './views/qref-bnkdel.component';
export * from './views/qref-cardNotlegacy.component';
export * from './views/qref-сheckppassport.component';
export * from './views/qref-сlientinfo.component';

export * from './views/qref-clienttype.component';
export * from './views/qref-codmer.component';
export * from './views/qref-сonsolidatedList.component';

export * from './views/qref-cmet4936.component';
export * from './views/qref-docType.component';
export * from './views/qref-dokt245Fm.component';

export * from './views/qref-extorgs.component';
export * from './views/qref-fArx.component';
export * from './views/qref-familyRel.component';

export * from './views/qref-fltype.component';
export * from './views/qref-inboultype.component';
export * from './views/qref-indl.component';

export * from './views/qref-infOtkaz.component';
export * from './views/qref-infomer.component';
export * from './views/qref-issuertype.component';

export * from './views/qref-koPrpod.component';
export * from './views/qref-kodosnov4936.component';
export * from './views/qref-kodtypesec.component';

export * from './views/qref-nationalVid.component';
export * from './views/qref-nationaltype.component';
export * from './views/qref-nlsDeposit.component';

export * from './views/qref-no.component';
export * from './views/qref-okopf.component';
export * from './views/qref-orgAttrib.component';

export * from './views/qref-orgs.component';
export * from './views/qref-otkazUch.component';
export * from './views/qref-part.component';

export * from './views/qref-pipdl.component';
export * from './views/qref-pnbs.component';
export * from './views/qref-pods.component';

export * from './views/qref-pods4936.component';
export * from './views/qref-prichOtkaz.component';
export * from './views/qref-prist.component';

export * from './views/qref-prizesp4936.component';
export * from './views/qref-prizninf.component';
export * from './views/qref-prpod5599.component';

export * from './views/qref-rdl.component';
export * from './views/qref-refus.component';
export * from './views/qref-risk.component';

export * from './views/qref-searchdocforfsfm.component';
export * from './views/qref-terNotlegacy.component';
export * from './views/qref-terror4936.component';

export * from './views/qref-tucode4077.component';
export * from './views/qref-typeblockds4936.component';
export * from './views/qref-typeinfo3484010206.component';

export * from './views/qref-typeoperator4936.component';
export * from './views/qref-uchop.component';
export * from './views/qref-uprtype.component';

export * from './views/qref-uprtypeEd.component';
export * from './views/qref-uprtypeKoll.component';
export * from './views/qref-vidInfo.component';

export * from './views/qref-viddocosnov.component';
export * from './views/qref-viddocosnov4936.component';
export * from './views/qref-vidmer.component';

export * from './views/qref-vidpds4936.component';
export * from './views/qref-vo4936.component';
export * from './views/qref-zr5861.component';


// --------------STRATEG-------------//

export * from './views/strateg/qref-clienttype-213.component';
export * from './views/strateg/qref-action-443.component';
export * from './views/strateg/qref-conttype.component';
export * from './views/strateg/qref-vidInfo-op.component';
export * from './views/strateg/qref-ko-otk.component';
export * from './views/strateg/qref-priost.component';
export * from './views/strateg/qref-pruved.component';
export * from './views/strateg/qref-pol-type.component';

// --------------ElMsgFm-------------//
export * from './views/arm407/qref-kodTypeDoc.component'
