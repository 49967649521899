export class FiasAddressModel {
  region: string;
  regionCode: string;
  area: string;
  city: string;
  locality: string;
  postalcode: string;
  street: string;
  house: string;
  building: string;
}
