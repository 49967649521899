import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {UserService} from "../services";
import {AlertService} from "../components/alert";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private userSrv: UserService, private alertSrv: AlertService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap(
      event => {
      },
      err => {
        if (err instanceof HttpErrorResponse) {
          try {
            let message: any = "";
            let errorText = '';
            let isObject = false;
            const status: any = err.status;
            const blockedEndpoints = [
              '/api/v1/documents/block',
              '/api/v1/documents/otkazgoz/block',
              '/api/v1/documents/strateg/block',
              '/api/v1/documents/p407/block'
            ];


            if (err.url.indexOf('/api/v1/dictionary/banc/update/by-banc') !== -1 || err.url.indexOf('/api/v1/dictionary/fil/update/by-fil') !== -1 || err.url.indexOf('/api/v1/dictionary/cb-bnkseek/update/by-ed807') !== -1) {
              return;
            }

            if (status === 401 || status === 403) {
              message = err.error && (err.error.message || err.error.error) || 'Ошибка';
              this.userSrv.clearAuth(true);
            } else if (status === 504) {
              message = 'Сервер не отвечает';
            } else if (status === 500) {
              message = 'На сервере произошла ошибка';
              errorText = err.error && err.error.error;
            }else if (status === 405) {
              message = err.error && (err.error.error || err.error.message)
            } else if (status === 403) {
              if (blockedEndpoints.some(endpoint => err.url.includes(endpoint))) {
                return;
              }
              message = 'Доступ запрещен'
            } else if (status === 400) {

              if (blockedEndpoints.some(endpoint => err.url.includes(endpoint))) {
                return;
              }

              if (err.url.match(/\/copy/)) {
                return;
              }

              message = err.error && err.error.error;
              if (typeof message === "object") {
                isObject = true;
              }

              if (err.url.indexOf('/api/v1/authad/profile/permissions') !== -1) {
                this.alertSrv.clear();
                return;
              }

              if (err.url.indexOf('/api/v1/authad/profile/license') !== -1) {
                this.alertSrv.clear();
                return;
              }
            }
            this.alertSrv.clear();
            if (isObject) {
              this.alertSrv.errorForm(Object.entries(message));
            } else {
              this.alertSrv.error(message ? message : err.error && err.error.error || err.error.message ? err.error.message : 'Ошибка', errorText);
            }

          } catch (e) {
            console.warn(e);
            // ignored
          }
        }
      }
    ));
  }
}
