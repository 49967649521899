import {DossierT2000, DossierT250, DossierDate, DossierBool} from "./base.model";

export class LicenseType {
  type: DossierT2000 = '';
  number: DossierT250 = '';
  dateVid: DossierDate = '';
  dateStart: DossierDate = '';
  unlimited?: DossierBool = false;
  dateEnd?: DossierDate = '';
  action: DossierT2000 = '';
  row: number;
}
