import {Component, OnInit, ViewChild} from '@angular/core';
import {CryptCertObject, CryptographService} from "../../../cryptography";
import {UserService} from "@amlCore/services";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import {ProfileService} from "../../services";
import {CertificateUsers} from "@amlCore/interfaces";
import {AlertService} from "@amlCore/components";
import {Utils} from "@amlCore/utils";


@Component({
  selector: 'app-cert-container',
  templateUrl: './certificateContainer.component.html'
})
export class CertificateContainerComponent implements OnInit {
  preloaderCrypt = true;
  showSelect = false;
  signVerifyInProcess = false;
  certs: CryptCertObject[] = null;
  certsTable: CertificateUsers[] = [];
  textError = '';
  stateCrypt = null;
  icons = {
    spinner: faSpinner
  }
  ngOnInit(): void {
    this.loadCertificates()
  }

  get getActiveCert() {
    return this.certsTable.find(c => c.active);
  }

  initCerts() {
    this.cryptographService.isCryptEnable.subscribe((data) => {
      this.stateCrypt = data;
      if (data.state) {
        this.cryptographService.crypt.getCertsStore().then(result => {
          this.certs = result;
          this.preloaderCrypt = false;
        }, (error => {
          this.textError = error.message;
          this.preloaderCrypt = false;
          this.showSelect = false;
          this.errorCatchCert(error);
        }))
      } else {
        this.textError = data.textError;
        this.preloaderCrypt = false;
      }
    }, this.errorCatchCert);
  }

  showMyCerts() {
    this.showSelect = true;
    setTimeout(() => {
      this.initCerts();
    }, 1000)
  }

  errorCatchCert = (error) => {
    if (error.message?.indexOf('0x8010006E') > 0) {
      this.alertSrv.error(`Отсутствует закрытый ключ`);
    } else if (error.message?.indexOf('0x000004C7') > 0) {
      this.alertSrv.error(`Операцию с ключами или сертификатами была отменена пользователем`);
    } else if (error.message === 'Не найдено сертификатов') {
      this.alertSrv.error(`На рабочем месте в хранилище "Личные" не найдено ни одного действующего сертификата. Для получения сертификата обратитесь в Удостоверяющий Центр.`);
    } else {
      this.alertSrv.error(`В профиле пользователя не назначен сертификат ключа проверки электронной подписи, или выбранный сертификат не валиден!`);
    }
    this.signVerifyInProcess = false;
  }

  verifySignature() {
    this.alertSrv.clear();
    const currentUser = this.userSrv.getCurrentUser();
    const curCert = currentUser.cert?.certThumb;
    if (!curCert) {
      this.alertSrv.warn('У вас нет сертификата для подписи!');
      return;
    }
    this.signVerifyInProcess = true;
    this.cryptographService.crypt.findCertificate(curCert).then(result => {
      this.profileService.verifyCertSignature('text', result).then(data => {
        this.loadCertificates();
        this.alertSrv.success(`Проверка прошла успешно!`);
      }, this.errorCatchCert);
    }, this.errorCatchCert)
  }

  addCert(selectCert) {
    this.cryptographService.crypt.getCertBase64(selectCert.thumb).then(cert => {
      this.profSrv.addCertificateProfile(cert).subscribe((data) => {
        const auth = this.userSrv.changeActiveCert(data.find(c => c.active));
        this.userSrv.setSelectedCert(auth);
        this.loadCertificates();
        this.showSelect = false;
      })
    })
  }

  loadCertificates() {
    this.profileService.getCertificatesProfile().subscribe((data) => {
      this.certsTable = data;
      this.signVerifyInProcess = false;
    })
  }

  constructor(private cryptographService: CryptographService, public profileService: ProfileService,
              private userSrv: UserService, private profSrv: ProfileService, private alertSrv: AlertService) {
  }


}
