import {Component, OnInit, ViewChild, OnDestroy} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "@amlCore/services";
import {ClientsTransactionsFilter} from "@amlCore/models";
import {TableService, VisitedService} from "@amlCore/services";
import {TableReactiveUrlController} from "@amlCore/tableReactive";
import {DossierService} from "../../services";
import {Transaction} from "../../models";
import {AlertPanelService, AlertPanelComponent} from "@amlCore/components";
import {IDossierPage} from "../../interfaces";

@Component({
  selector: "app-clients-transactions",
  templateUrl: "./clientsTransactions.component.html",
})

export class CleintsTransactionsComponent extends TableReactiveUrlController implements OnInit, OnDestroy, IDossierPage {

  @ViewChild("alertObjSave") alertObjToSave: AlertPanelComponent;

  isShowVisited = true;
  transactionsList = []; // Клиентские операции

  transactionPanelShow = false;
  loadingCheckTransactions = false;
  checkTransactionCount = 0;
  isDossierPart = false; // Признак, что раздел в досье
  idDossier: string; // id досье если isDossierPart === true

  constructor(protected activatedRoute: ActivatedRoute,
              protected router: Router,
              protected visitedService: VisitedService,
              protected tableService: TableService,
              protected alertPanelSrv: AlertPanelService,
              private dossierSrv: DossierService,
              private userSrv: UserService) {
    super(router, activatedRoute, visitedService, tableService, {sort: 'created', dir: 'desc'});
  }

  ngOnInit(): void {
    this.isDossierPart = this.activatedRoute.snapshot.data.isDossierPart;
    this.idDossier = this.activatedRoute.snapshot.parent.params.id;
    this.activatedRoute.queryParams.subscribe((data: ClientsTransactionsFilter) => {
      this.loadList(this.syncParams(data));
    });
  }

  onPageChange(params){
    this.navigate({...params, ...this.getParams(this.filter)} as ClientsTransactionsFilter);
  }

  onSearch(): void {
    const params = this.getSearchPage(this.filter) as ClientsTransactionsFilter;
    this.onPageChange(params);
  }

  private loadList(params?: ClientsTransactionsFilter) {
    const queryParams = {...this.params, ...this.getParamsv2(params)};
    if (this.isDossierPart) {
      this.dossierSrv.getTransactionsByClientId(queryParams, this.idDossier).subscribe(
        (data) => {
          this.initTransactionsList(data);
        });
    } else {
      this.dossierSrv.getClientsTransactions(queryParams).subscribe((data) => {
         this.initTransactionsList(data);
      });
    }
  }

  private initTransactionsList(data) {
    this.isSelectedAll = false;
    this.transactionsList = data.content;
    this.total = data.totalElements;
    this.scrollRun();
  }

  openDossierRoot(id: string): void {
    window.open(`dossier/${id}/svedClient`, '_blank');
  }

  /**
   * метод получения объекта плоского фильтра
   * @param data объект фильтра с полями
   */
  getFilterObject(data) {
    const filter = this.tableService.getFilterSubject(data);
    return this.getFilterPlanObject(filter);
  }

  // @ovveride метод кастомной обработки плоского объекта фильтра
  // содержащего параметры запроса в виде объекта
  customPatchPlanObject(result, filter, key) {
    /* currency: код - наменование валюты
     * type: код - вид операции
     * opeartionCode: код - вид операции
     * issuerCountryKode: код - код страны
    */
    const listFieldException = ['currency', 'type','currCashType', 'operationCode', 'issuerCountryKod', 'bankCountryKod', 'countryKod'];
    if (listFieldException.includes(key) && filter[key]) {
      result[key] = filter[key].code;
    }
  }

  /**
   * Обработка импорта транзакции
   */
  onImportTransaction(): void {
    // Подмешиваем случайную переменную для реализации обновления при импорте
    this.onPageChange({$refresh: Math.random()});
  }

  /**
   * Метод открытия формы для создания транзакции
   * без id
   */
  addTransaction(): void {
    this.router.navigate(["clientTransactionForm", {whereOpenUrl: this.router.url}]);
  }

  /**
   * Метод открытия существующей транзакции с id
   */
  openTransaction(item: Transaction): void {
    this.tableService.saveScrollPosition(this.commonKeyStore);
    this.router.navigate(["clientTransactionForm", item.id, {whereOpenUrl: this.router.url}]);
  }

  /**
   * Метод удаления транзакции
   */
  deleteTransaction(id: string, index: number): void {
    this.dossierSrv.deleteTransaction(id).subscribe(() => {
      this.transactionsList.splice(index, 1);
      this.total--;
      this.alertObjToSave.open(this.alertPanelSrv.getSuccessMsg("Данные успешно удалены"));
    });
  }

  /**
   * Метод копирования транзакции
   */
  copyTransaction(id: string): void {
    this.dossierSrv.copyTransaction(id).subscribe((data) => {
      const index = this.transactionsList.findIndex(item => item.id === id);
      this.transactionsList.splice(index + 1, 0, data);
      this.alertObjToSave.open(this.alertPanelSrv.getSuccessMsg("Данные успешно скопированы"));
    });
  }

  /**
   * Метод проверки доступа к элементу формы на основе
   * permission transtionslist
   */
  isDisableElement(): boolean {
    const accessObject = this.userSrv.getAccess("transactionslist");
    return accessObject && accessObject.readOnly;
  }

  checkAccess115FZ() {
    return this.userSrv.getAccess("startinganalysis115fz").readOnly;
  }

  checkTransations(): void {
    this.transactionPanelShow = false;
    this.loadingCheckTransactions = true;
    // Получаем список выбранных досье - формируем список объектов с checkObjectId = id досье
    const selectedTransactionList = this.getSelectedModel(this.transactionsList).map((item: any) => {
      return {checkObjectId: item.id};
    });

    this.dossierSrv.checkTransactions(selectedTransactionList).subscribe(data => {
      if (data) {
        this.checkTransactionCount = 0;
        this.transactionPanelShow = true;
        (data as Array<any>).forEach(item => {
          this.checkTransactionCount += item.totalCount;
        });
      }
      this.loadingCheckTransactions = false;
    }, (error) => {
      this.loadingCheckTransactions = true;
    });
  }

  setAlertPanel(alertObjToSave: AlertPanelComponent): void {
  }

  ngOnDestroy() {
    this.checkScrollOptions();
  }

}
