import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {DossierAccessEnum} from "@amlCore/enums";
import {ErrorFLKModel} from "@amlCore/models";

@Injectable()
export class DossierFLKResolver implements Resolve<any> {
  constructor() {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | any {
    const typePage: DossierAccessEnum = route.data.typePage;
    /**
     * TODO: получаем при каждом открытии вкладки. Надо добавить сохранение в сервис?
     * TODO: Временно пока не заработает сервер
     */
    const svedClientError: Array<ErrorFLKModel> = [
      {
        path: 'svedClient.svedUL.inn',
        msg: 'P101020.Поле "Инн" заполнено неверно: "7813061041".',
        reason: 'Поле должно быть заполнено, должно содержать 5 или 10 символов и содержать корректные данные.'
      },
      {
        path: 'svedClient.svedUL.okved.okved',
        items: {
          1: [
            {
              path: 'code',
              msg: 'P101061.Поле "ОКВЕД" заполнено неверно: "72.99.99".',
              reason: 'Значение поля должно соответствовать справочнику.'
            }
          ],
          2: [
            {
              path: 'code',
              msg: 'P101061.Поле "ОКВЕД" заполнено неверно: "55.55.55".',
              reason: 'Значение поля должно соответствовать справочнику.'
            }
          ]
        }
      },
      {
        path: 'svedClient.svedUL.okved.okved2',
        items: {
          1: [
            {
              path: 'code',
              msg: 'P101061.Поле "ОКВЕД2" заполнено неверно: "72.99.99".',
              reason: 'Значение поля должно соответствовать справочнику.'
            }
          ]
        }
      },
      {
        path: 'svedClient.svedUL.licenseInfo',
        items: {
          0: [
            {
              path: 'dateStart',
              msg: 'P204501.Поле "Дата начала действия лицензии" заполнено неверно: "11.11.1999"',
              reason: 'Поле должно быть заполнено в формате dd.MM.yyyy и не может быть больше даты†выдачи лицензии.'
            },
            {
              path: 'dateEnd',
              msg: 'P204601.Поле "Дата окончания действия лицензии" заполнено неверно.',
              reason: 'Поле должно быть заполнено в формате dd.MM.yyyy и не может быть больше даты††начала действи€ лицензии.'
            }
          ],
          2: [
            {
              path: 'dateStart',
              msg: 'P204501.Поле "Дата начала действия лицензии" заполнено неверно: "22.12.1990".',
              reason: 'Поле должно быть заполнено в формате dd.MM.yyyy и не может быть больше даты†выдачи лицензии.'
            },
            {
              path: 'dateEnd',
              msg: 'P204601.Поле "Дата окончания действия лицензии" заполнено неверно.',
              reason: 'Поле должно быть заполнено в формате dd.MM.yyyy и не может быть больше даты††начала действи€ лицензии.'
            }
          ]
        }
      }
    ];
    const bankAccountsError = {
      path: 'bankAccounts',
      items: {
        0: [{
          path: 'currency',
          msg: 'Текст ошибки'
        }]
      }
    } as ErrorFLKModel;
    const flkError = {
      svedClient: svedClientError,
      bankAccounts: bankAccountsError
    };
    // TODO: ожидание добавления метода для загрузки ошибок по вкладки при открытии досье
    // return flkError[typePage] ? flkError[typePage] : null;
    return null;
  }
}
