import {Access, User} from "@amlCore/models";

export class AccessKey {
  access: Access;
  user: User;

  constructor() {
  }
}

