import {Component, Input} from "@angular/core";
import {SetupFLKModel} from "../../models";
import {DossierService} from "../../services";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";

@Component({
  selector: 'app-setup-flk',
  templateUrl: './setupFLK.component.html'
})
export class SetupFLKComponent {
  @Input() setupInfo: SetupFLKModel[];
  @Input() level = 0;
  sizePaddingLeft = 10;

  public icons = {
    spinner: faSpinner
  };

  constructor(private dossierService: DossierService) {
  }

  /**
   * Метод отключения/включения проверки
   * @param item - проверка
   */
  enableCheck(item: SetupFLKModel) {
    if (item.$load) {
      return;
    }
    item.$load = true;
    const enable = !item.enable;
    const params = {
      enable
    };
    if (item.partType) {
      params["partName"] = item.partType;
    }
    if (item.clientType) {
      params["clientType"] = item.clientType;
    }
    this.dossierService.enableCheck(item.code, params).subscribe(response => {
      item.enable = enable;
      item.$load = false;
    }, reject => {
      item.$load = false;
    });
  }

  /**
   * Передаем новый уровень отступа в дочерний компонент
   */
  newLevel(): number {
    return this.level + 1;
  }
}
