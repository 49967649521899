import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {Observable} from "rxjs";
import {map, take} from "rxjs/operators";
import {UserService} from "./user.service";

@Injectable()
export class AccessRouteGuard implements CanActivate {
  constructor(private router: Router,
              private userService: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.accessList.pipe(take(1), map(data => {
      const access = route.data.access;
      if (!access) {
        return true;
      }
      return this.userService.checkAccess(access);
    }));


  }
}
