import {Component, Input} from '@angular/core';
import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons/faChevronUp";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";

@Component({
  selector: 'app-accord-caret',
  template: `
    <fa-icon [hidden]="opened" [icon]="icons.down"></fa-icon>
    <fa-icon [hidden]="!opened" [icon]="right ? icons.right : icons.up"></fa-icon>
  `
})
export class AccordionCaretComponent {
  @Input() opened = false;
  @Input() right = false;
  icons = {
    down: faChevronDown,
    right: faChevronRight,
    up: faChevronUp
  };
}
