import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'firstWord'
})
// TODO переименовать класс в GetFirstWordPipe и соотв. имя файла
export class GetFirstWord implements PipeTransform {
  transform(value: string, args?: any[]): string {
    if (!value || args) {
      return value;
    }
    const words = value.split(' ');
    return words.length > 0 ? words[0] : value;
  }
}
