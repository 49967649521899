import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {UserService} from "../services";

/**
 * Выставляет видимость шаблона по заданным параметрам в директиве
 * Доступ зависит от прав назначенным пользователям
 */
@Directive({selector: '[appShowElByAccess]'})
export class ShowElByAccessDirective implements OnInit {
  constructor(private templateRef: TemplateRef<any>,
              private userService: UserService,
              private viewContainer: ViewContainerRef) {
  }

  typeAccess: string | Array<string>;

  ngOnInit() {
    this.viewContainer.clear();
    if (this.userService.checkAccess(this.typeAccess)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  @Input() set appShowElByAccess(type: string | Array<string>) {
    this.typeAccess = type;
  }
}
