import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DossierService } from "src/app/client/services";
import { AlertPanelService, ClientTypeEnum, DictionaryService } from "src/app/core";
import { DossierBaseModel } from "../../dossierBaseModel";
import { DossierBaseSubComponent } from "../dossierBaseSub.component";

@Component({
  selector: 'date-from-date-end-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './dateFromDateEndDossier.component.html'
})
export class DateFromDateEndDossierComponent extends DossierBaseSubComponent {
  @Input() titleStart = '';
  @Input() titleEnd = '';
  @Input() focusObject;
  constructor(protected dossierService: DossierService,
    protected dictSrv: DictionaryService,
    protected dossierBaseModel: DossierBaseModel,
    protected alertPanelService: AlertPanelService) {
super(dossierService, dictSrv, alertPanelService);
}

}

