import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {DossierService} from "../../../../../services";
import {DictType, SvedVPRType} from "../../../../../models/dossier";
import {AlertPanelService, ITableEditorItem, ITableItem} from "@amlCore/components";
import {DictionaryService} from "@amlCore/services";
import {Subject, Unsubscribable} from "rxjs";
import {DossierBaseModel} from "../../dossierBaseModel";
import {FormGroup} from "@angular/forms";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {ClientTypeEnum} from "@amlCore/enums";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-vpr-info-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './vprInfo.component.html'
})
export class VprInfoComponent extends DossierBaseSubComponent implements OnInit, OnDestroy, ITableItem {
  @Input() params: { clientType: () => ClientTypeEnum };
  titlePanel = 'Сведения о выгодоприобретателях';
  showTitle = true;
  bfTypeList: DictType[] = [];
  isTableItem = false;
  clientType: ClientTypeEnum; // Тип клиента из SvedClient
  destroy$ = new Subject();
  constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  /**
 * Описание формы
 */
  getFormDescription(): FormGroup {
    return this.dossierBaseModel.getSvedVPR();
  }

  /**
   * Инициализация FormGroup
   */
  initForm(): void {
    if (!this.isTableItem) {
      this.formGroupApp = this.getFormDescription();
    }
  }

  ngOnInit(): void  {
    this.focusObject = this.generateFocusObject(new SvedVPRType());
    this.clientType = this.params.clientType();
    this.bfTypeList = this.dictSrv.getStaticData("bfTypeList");
    this.f('vprProfit').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.f('vprOther')[value == 3 ? 'enable' : 'disable']();
    });
  }


  setSubmitted(value: boolean): void  {
    this.submitted = value;
  }


  getTitle(): string {
    return this.titlePanel;
  }

  /**
   * Инициализируем компонент как элемент формы просмотра таблицы
   * @param param.form - форма для связи
   * @param param.params - дополнительные параметры
   */
  initTableItem(param: ITableEditorItem): FormGroup {
    this.initForm();
    this.isTableItem = true;
    if (param.model) {
      this.formGroupApp.patchValue(param.model);
    }
    this.showTitle = false;
    if (param.params && param.params.typePage) {
      this.typePage = param.params.typePage;
    } else {
      console.log('Необходимо указать typePage у компонента');
    }
    this.isReadOnly = param.isReadOnly;
    if (this.isReadOnly){
      this.formGroupApp.disable();
    }
    return this.formGroupApp;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
