import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject, Subject, throwError} from 'rxjs';
import {ClientTypeEnum, DossierAccessEnum} from "@amlCore/enums";
import {ApiService, UserService} from "@amlCore/services";
import {AccessDossierTypeModel, CheckClients, ClientInfo, DocumentDossier, DossierRequest, FIOType} from "../models";
import {IDossierPanelInfo, IVersionModel} from "../interfaces";
import {Utils} from "@amlCore/utils";
import {ErrorFLKModel} from "@amlCore/models";
import {map, tap} from "rxjs/operators";
import {ImportDossierListResultModel} from "../models/importDossierListResult.model";

@Injectable()
export class DossierService {
  partIdCache = new ReplaySubject(1);
  deleteItemSub = new Subject<number>();
  private _dossierId: string;
  private _acitvePart: DossierAccessEnum;
  currentClientType: ClientTypeEnum;
  public clientTypeEmit = new EventEmitter<number>();
  public saveButtonIsDisabled: EventEmitter<boolean> = new EventEmitter();
  /**
   * Если true - отображаем историю всего досье
   */
  isShowHistory = false;
  /**
   * Версия истории всего досье
   */
  historyVersion = 0;
  /**
   * id вкладки для открытия из вне досье
   */
  pageId = "";
  /**
   * Разрешение переинициализировать компонент после перезагрузки данных
   * TODO: временное решение - необходимо поправить инициализацию формы после установки версии
   */
  accessInitAfterRefresh = false;
  /**
   * Переменная для передачи ошибки между компонентами (при открытии ошибки в таблцице)
   * TODO: возможно стоит пееделать на объект "ключ-значение" при открытии многоуровневых таблиц
   */
  errorFLKTable: ErrorFLKModel = null;

  svedClientDataSubject = new Subject();

  /**
   * Информация по вкладке
   * isLoadedData - Признак что данные по вкладке уже загружены
   * isReadOnly - Признак вкладка доступна только для просмотра
   * isErrorFLK - Признак наличия ошибок по ФЛК
   * isShowHistory - Признак вкладка в режиме просмотра истории версий
   * version - версия истории
   * isSaveData - Признак вкладка сохранена
   * formDataCache - данные по вкладке для механизма проверки сохранения данных
   */
  panelInfo: { [name: string]: IDossierPanelInfo; } = {
    svedClient: {
      isLoadedData: false,
      isShowHistory: false,
      version: 0,
      versionList: [],
      isErrorFLK: new BehaviorSubject<boolean>(false),
      isReadOnly: new BehaviorSubject<boolean>(false),
      isSaveData: new BehaviorSubject<boolean>(true),
      isFormCacheSave: false,
      formDataCache: ''
    } as IDossierPanelInfo,
    svedAU: {
      isLoadedData: false,
      isShowHistory: false,
      version: 0,
      versionList: [],
      isErrorFLK: new BehaviorSubject<boolean>(false),
      isReadOnly: new BehaviorSubject<boolean>(false),
      isSaveData: new BehaviorSubject<boolean>(true),
      isFormCacheSave: false,
      formDataCache: ''
    } as IDossierPanelInfo,
    svedUDS: {
      isLoadedData: false,
      isShowHistory: false,
      version: 0,
      versionList: [],
      isErrorFLK: new BehaviorSubject<boolean>(false),
      isReadOnly: new BehaviorSubject<boolean>(false),
      isSaveData: new BehaviorSubject<boolean>(true),
      isFormCacheSave: false,
      formDataCache: ''
    } as IDossierPanelInfo,
    svedIOU: {
      isLoadedData: false,
      isShowHistory: false,
      version: 0,
      versionList: [],
      isErrorFLK: new BehaviorSubject<boolean>(false),
      isReadOnly: new BehaviorSubject<boolean>(false),
      isSaveData: new BehaviorSubject<boolean>(true),
      isFormCacheSave: false,
      formDataCache: ''
    } as IDossierPanelInfo,
    svedPredst: {
      isLoadedData: false,
      isShowHistory: false,
      version: 0,
      versionList: [],
      isErrorFLK: new BehaviorSubject<boolean>(false),
      isReadOnly: new BehaviorSubject<boolean>(false),
      isSaveData: new BehaviorSubject<boolean>(true),
      isFormCacheSave: false,
      formDataCache: ''
    } as IDossierPanelInfo,
    svedVPR: {
      isLoadedData: false,
      isShowHistory: false,
      version: 0,
      versionList: [],
      isErrorFLK: new BehaviorSubject<boolean>(false),
      isReadOnly: new BehaviorSubject<boolean>(false),
      isSaveData: new BehaviorSubject<boolean>(true),
      isFormCacheSave: false,
      formDataCache: ''
    } as IDossierPanelInfo,
    svedBV: {
      isLoadedData: false,
      isShowHistory: false,
      version: 0,
      versionList: [],
      isErrorFLK: new BehaviorSubject<boolean>(false),
      isReadOnly: new BehaviorSubject<boolean>(false),
      isSaveData: new BehaviorSubject<boolean>(true),
      isFormCacheSave: false,
      formDataCache: ''
    } as IDossierPanelInfo,
    svedTarget: {
      isLoadedData: false,
      isShowHistory: false,
      version: 0,
      versionList: [],
      isErrorFLK: new BehaviorSubject<boolean>(false),
      isReadOnly: new BehaviorSubject<boolean>(false),
      isSaveData: new BehaviorSubject<boolean>(true),
      isFormCacheSave: false,
      formDataCache: ''
    } as IDossierPanelInfo,
    bankAccounts: {
      isLoadedData: false,
      isShowHistory: false,
      version: 0,
      versionList: [],
      isErrorFLK: new BehaviorSubject<boolean>(false),
      isReadOnly: new BehaviorSubject<boolean>(false),
      isSaveData: new BehaviorSubject<boolean>(true),
      isFormCacheSave: false,
      formDataCache: ''
    } as IDossierPanelInfo,
    dopInfo: {
      isLoadedData: false,
      isShowHistory: false,
      version: 0,
      versionList: [],
      isErrorFLK: new BehaviorSubject<boolean>(false),
      isReadOnly: new BehaviorSubject<boolean>(false),
      isSaveData: new BehaviorSubject<boolean>(true),
      isFormCacheSave: false,
      formDataCache: ''
    } as IDossierPanelInfo,
    otherAccounts: {
      isLoadedData: false,
      isShowHistory: false,
      version: 0,
      versionList: [],
      isErrorFLK: new BehaviorSubject<boolean>(false),
      isReadOnly: new BehaviorSubject<boolean>(false),
      isSaveData: new BehaviorSubject<boolean>(true),
      isFormCacheSave: false,
      formDataCache: ''
    } as IDossierPanelInfo,
    attributes: {
      isLoadedData: false,
      isShowHistory: false,
      version: 0,
      versionList: [],
      isErrorFLK: new BehaviorSubject<boolean>(false),
      isReadOnly: new BehaviorSubject<boolean>(false),
      isSaveData: new BehaviorSubject<boolean>(true),
      isFormCacheSave: false,
      formDataCache: ''
    } as IDossierPanelInfo,
    riskAssess: {
      isLoadedData: false,
      isShowHistory: false,
      version: 0,
      versionList: [],
      isErrorFLK: new BehaviorSubject<boolean>(false),
      isReadOnly: new BehaviorSubject<boolean>(false),
      isSaveData: new BehaviorSubject<boolean>(true),
      isFormCacheSave: false,
      formDataCache: ''
    } as IDossierPanelInfo,
    dokReestr: {
      isLoadedData: false,
      isShowHistory: false,
      version: 0,
      versionList: [],
      isErrorFLK: new BehaviorSubject<boolean>(false),
      isReadOnly: new BehaviorSubject<boolean>(false),
      isSaveData: new BehaviorSubject<boolean>(true),
      isFormCacheSave: false,
      formDataCache: ''
    } as IDossierPanelInfo,
    partner: {
      isLoadedData: false,
      isShowHistory: false,
      version: 0,
      versionList: [],
      isErrorFLK: new BehaviorSubject<boolean>(false),
      isReadOnly: new BehaviorSubject<boolean>(false),
      isSaveData: new BehaviorSubject<boolean>(true),
      isFormCacheSave: false,
      formDataCache: ''
    } as IDossierPanelInfo,
    dossierAccess: {
      isLoadedData: false,
      isShowHistory: false,
      version: 0,
      versionList: [],
      isErrorFLK: new BehaviorSubject<boolean>(false),
      isReadOnly: new BehaviorSubject<boolean>(false),
      isSaveData: new BehaviorSubject<boolean>(true),
      isFormCacheSave: false,
      formDataCache: ''
    } as IDossierPanelInfo
  };

  getReadOnly(typePage: DossierAccessEnum): BehaviorSubject<boolean> {
    return this.panelInfo[typePage].isReadOnly;
  }

  setReadOnly(typePage: DossierAccessEnum, value: boolean): void {
    this.panelInfo[typePage].isReadOnly.next(value);
  }

  getDossierId(): string {
    return this._dossierId;
  }

  saveDossierId(id: string): void {
    this._dossierId = id;
  }

  getErrorFLK(typePage: DossierAccessEnum): BehaviorSubject<boolean> {
    return this.panelInfo[typePage].isErrorFLK;
  }

  setErrorFLK(typePage: DossierAccessEnum, value: boolean): void {
    this.panelInfo[typePage].isErrorFLK.next(value);
  }

  saveActivePartCode(type: DossierAccessEnum): void {
    this._acitvePart = type;
  }

  getActivePartCode(): DossierAccessEnum {
    return this._acitvePart;
  }

  /**
   * Заюлокировать все досье
   */
  setAllPageReadOnly(value: boolean): void {
    Object.keys(this.panelInfo).forEach(typePage => {
      this.setReadOnly(typePage as DossierAccessEnum, value);
    });
  }
  setAllSaveData(value: boolean): void {
    Object.keys(this.panelInfo).forEach(typePage => {
      this.setSaveData(typePage as DossierAccessEnum, value);
    });
  }

  getPanelInfo(typePage: DossierAccessEnum): IDossierPanelInfo {
    return this.panelInfo[typePage];
  }

  setSaveData(typePage: DossierAccessEnum, value: boolean): void {
    this.panelInfo[typePage].isSaveData.next(value);
  }

  constructor(private api: ApiService,
              private userService: UserService) {
  }

  /**
   * Получить раздел досье по его типу
   */
  getDossierByType(params: DossierRequest): Observable<any> {
    if (params.partName === DossierAccessEnum.DOSSIERACCESS) {
      return this.api.get(`/api/v1/docsaccess/${params.id}`);
    }
    if (params.version) {
      return this.getDocumentByVersion(params.id, params.partName, params.version);
    } else {
      return this.api.get(`/api/v1/docs/${params.id}/${params.partName}`);
    }

  }

  /**
   * Сохранение информация о части досье
   * @param params - данные модели для сохранения {id, partName, model};
   */
  saveDossierByType(params: DossierRequest): Observable<any> {
    if (params.partName === DossierAccessEnum.DOSSIERACCESS) {
      return this.api.post(`/api/v1/docsaccess/${params.id}`, params.model);
    }
    if (params.id) {
      return this.api.post(`/api/v1/docs/${params.id}/${params.partName}`, params.model);
    } else if (params.partName === DossierAccessEnum.SVEDCLIENT) {
      return this.api.post(`/api/v1/docs/${params.partName}`, params.model);
    } else {
      return throwError("При создании нового досье необходимо первым сохранить Сведения о клиенте");
    }
  }

  // Патчит структуру описания ошибок ФЛК для otherAccounts, bankAccounts
  getPatchAccountsDataError(data) {
    const [{items}] = data;
    const [rowNum] = Object.keys(items);
    return items[parseInt(rowNum, 10)];
  }

  /**
   * Метод проверки по ФЛК части досье
   * @param params - данные для проверки
   * partName - тип вкладки
   * model - данные для проверки
   */
  checkFLK(params: DossierRequest): Observable<any> {
    const DOSSIER_PARTS_WHITE_LIST = [DossierAccessEnum.OTHERACCOUNTS, DossierAccessEnum.BANKACCOUNTS, DossierAccessEnum.RISKASSESS];

    if (params.partName && params.model) {

      if (DOSSIER_PARTS_WHITE_LIST.includes(params.partName)) {
        return this.api.post(`/api/v1/clientcheck/${params.dossierId}/${params.partName}${params.partId ? `/${params.partId}` : ''}`, [params.model]).pipe(map(data => {
          data = this.changeErrorFLK(data);
          return data && (data as any[]).length > 0 ? this.getPatchAccountsDataError(data) : data;
        }));
      }

      if (params.dossierId) {
        return this.api.post(`/api/v1/clientcheck/${params.dossierId}/${params.partName}${params.partId ? `/${params.partId}` : ''}`, params.model).pipe(tap(data => {
          data = this.changeErrorFLK(data);
          return data;
        }));
      }

      if (!params.dossierId) {
        return this.api.post(`/api/v1/clientcheck/${params.partName}${params.partId ? `/${params.partId}` : ''}`, params.model).pipe(tap(data => {
          data = this.changeErrorFLK(data);
          return data;
        }));
      }
    } else {
      return throwError("Необходимо указать тип вкладки и данные!");
    }
  }

  /**
   * Метод получения списка ошибок по части досье
   * @param params - данные для проверки
   * id - id досье
   * partName - Тип вкладки
   * partId - id части досье (Если параметр не указан, то будут переданы статусы всех субъектов указанной части)
   */
  checkPartFLK(params: DossierRequest) {
    if (params.id && params.partName) {
      let url = `/api/v1/clientcheck/${params.id}/${params.partName}`;
      if (params.partId) {
        url += '/' + params.partId;
      }
      return this.api.get(url);
    } else {
      return throwError("Необходимо указать тип вкладки, id досье и id вкладки!");
    }
  }

  /**
   * Метод получения списка проверок
   */
  clientCheck(){
    return this.api.get('/api/v1/clientcheck');
  }

  /**
   * Существуют ли проверки требующие решения (result == false)
   */
  isNeedSolutionsCheck(clientRegId = ''): Observable<boolean> {
    return this.api.get(`/api/v1/check/client/isNeedSolutions${clientRegId ? `/${clientRegId}` : ''}`);
  }

  isNeedSolutionsTransaction(clientRegId = ''): Observable<boolean> {
    return this.api.get(`/api/v1/transaction/check/isNeedSolutions${clientRegId ? `/${clientRegId}` : ''}`);
  }

  /**
   * Метод отключения/включения проверки
   * @param code - код проверки
   * @param params - данные проверки
   */
  enableCheck(code: string, params: any){
    return this.api.get(`/api/v1/clientcheck/enable/${code}`, params);
  }
  /**
   * Удалить информация о части досье
   * @param params - данные модели для удаления
   *  model - список id частей или id юзеров (для ACCESS) для удаления
   */
  deletePartDossierByType(params: DossierRequest): Observable<any> {
    if (params.id) {
      if (params.partName === DossierAccessEnum.DOSSIERACCESS) {
        return this.api.put(`/api/v1/docsaccess/${params.id}`, params.model);
      }
      return this.api.put(`/api/v1/docs/${params.id}/${params.partName}`, params.model);
    } else {
      return throwError("Необходимо указать id");
    }
  }

  /**
   * Получить часть вкладки или пользователя для ACCESS
   * @param params - данные модели для загрузки
   */
  getPartDossierByType(params: DossierRequest): Observable<any> {
    if (params.partName === DossierAccessEnum.DOSSIERACCESS) {
      return this.api.get(`/api/v1/docsaccess/${params.id}/${params.partId}`, params.model);
    }
    if (params.id && params.partName && params.partId) {
      return this.api.get(`/api/v1/docs/${params.id}/${params.partName}/${params.partId}`, params.model);
    } else {
      return throwError("Необходимо указать id, partName и partId");
    }
  }

  /*получение списка проверок анализа транзакций по текущему клиенту*/
  getTransactionCheckList(id: string, params: any): Observable<any> {
    return this.api.post(`/api/v1/transaction/check/get/${id}`, params);
  }

  /**
   * Добавить/обновить ответственных сотрудников
   * @param data - данные по сотруднику и досье
   */
  saveDossierStaff(data: AccessDossierTypeModel): Observable<any> {
    return this.api.post(`/api/v1/docsaccess`, data);
  }

  /**
   * Удалить ответственных сотрудников
   * @param data - данные по сотруднику и досье
   */
  deleteDossierStaff(data: AccessDossierTypeModel): Observable<any> {
    return this.api.put(`/api/v1/docsaccess`, data);
  }

  /**
   * Получить список досье
   */
  getDossierList(params: any): Observable<any> {
    return this.api.post("/api/v1/docs/search", params, 'id');
  }

  /**
   * Удалить досье
   */
  deleteDossier(dossierId: string): Observable<any> {
    return this.api.delete(`/api/v1/docs/${dossierId}`);
  }

  /**
   * Экспорт досье
   */
  exportDossier(dossierId: string): Observable<any> {
    return this.api.get(`/api/v1/docs/export/${dossierId}`);
  }

  /**
   * Выгрузка csv-отчета по ошибкам проверки уникальность досье
   */
  downloadUniqueReportCsv(params: DossierRequest) {
    return this.api.byte('/api/v1/docs/unique/excel', params.model);
  }

  /**
   * Выгрузка отчета по ошибкам ОКВЭД при импорте досье из xml
   */
  downloadOkwedErrorReport(okwedErrorInfo: []) {
    return this.api.byte('/api/v1/docs/export/okved', okwedErrorInfo);
  }


  /**
   * Выгрузка общего отчета по результатам импорта пакета досье
   */
  downloadDossierImportReport(result: ImportDossierListResultModel) {
    return this.api.byte('/api/v1/docs/importDossier', result);
  }

  /**
   * Сброс сервиса в первоначальное состояние
   */
  resetData() {
    Object.values(this.panelInfo).forEach(item => {
      item.isLoadedData = false;
      item.isShowHistory = false;
      item.version = 0;
      item.versionList = [];
      item.isErrorFLK.next(false);
      item.isReadOnly.next(false);
      item.isSaveData.next(true);
      item.formDataCache = '';
      item.isFormCacheSave = false;
    });
    this.isShowHistory = false;
    this.historyVersion = 0;
  }

  /**
   * Проверить загружены ли данные по вкладке
   * @param type - тип вкладки
   */
  getLoadedData(type: string): boolean {
    return this.panelInfo[type].isLoadedData;
  }

  /**
   * Установить признак загруженных данных вкладки
   * @param type - тип вкладки
   * @param value - параметр загружености
   */
  setLoadedData(type: DossierAccessEnum, value: boolean) {
    this.panelInfo[type].isLoadedData = value;
  }

  /**
   * Установить признак загруженных данных для всех вкладок
   * @param value - параметр загружености
   */
  setAllPageLoadedData(value: boolean): void {
    Object.keys(this.panelInfo).forEach(typePage => {
      this.setLoadedData(typePage as DossierAccessEnum, value);
    });
  }

  /**
   * Венрнуть признак отображения вкладки как истории
   * @param type - тип вкладки
   */
  getShowHistory(type: string): boolean {
    return this.panelInfo[type].isShowHistory;
  }

  /**
   * Установить признак отобрадения истории у вкладки
   * @param type - тип вкладки
   * @param value - признак отображения
   */
  setShowHistory(type: DossierAccessEnum, value: boolean) {
    this.panelInfo[type].isShowHistory = value;
  }
  /**
   * Венрнуть признак сохранения данных вкладки для кеша
   * @param type - тип вкладки
   */
  getFormCacheSave(type: string): boolean {
    return this.panelInfo[type].isFormCacheSave;
  }

  /**
   * Установить признак сохранения данных вкладки для кеша
   * @param type - тип вкладки
   * @param value - признак сохранения
   */
  setFormCacheSave(type: DossierAccessEnum, value: boolean) {
    this.panelInfo[type].isFormCacheSave = value;
  }

  /**
   * Сравниваем закешированные данные формы и текущие
   * @param type - тип вкладки
   * @param data - текущие данные вкладки
   *
   * клонируем данные объекта (разрываем связь)
   * удаляем пустые и системные переменные
   * сортируем ключи и записываем представление в строке
   */
  checkFormDataCache(type: string, data: any): boolean {
    data = Utils.clone(data);
    Utils.deleteEmptyObj(data);
    const dataStr = JSON.stringify(Utils.sortObjectKey(data));
    return this.panelInfo[type].formDataCache === dataStr;
  }

  /**
   * Кешируем данные вкладки
   * @param type - тип вкладки
   * @param data - данные вкладки
   *
   * клонируем данные объекта (разрываем связь)
   * удаляем пустые и системные переменные
   * сортируем ключи и записываем представление в строке
   */
  setFormDataCache(type: DossierAccessEnum, data: any): void {
    data = Utils.clone(data);
    Utils.deleteEmptyObj(data);
    this.panelInfo[type].formDataCache = JSON.stringify(Utils.sortObjectKey(data));
  }

  getFormDataCache(type: DossierAccessEnum): IDossierPanelInfo {
    try {
      return JSON.parse(this.panelInfo[type].formDataCache);
    } catch (e) {
      return null;
    }
  }

  /**
   * Венрнуть номер версии истории
   * @param type - тип вкладки
   */
  getVersion(type: string): number {
    return this.panelInfo[type].version;
  }

  /**
   * Установить версию истории
   * @param type - тип вкладки
   * @param value - номер версии
   */
  setVersion(type: DossierAccessEnum, value: number) {
    this.panelInfo[type].version = value;
  }

  /**
   * Венрнуть версии вкладки по версии досье или 0 если версии нет
   * Ищем в списке версий вкладки версию меньше текущей версии всего досье, но самую близкую к ней
   * @param type - тип вкладки
   */
  getVersionPartByVersionDossier(type: DossierAccessEnum): number {
    let result = 0;
    this.panelInfo[type].versionList.forEach(item => {
      // Ищем по версии всего досье
      if (item.versionFull <= this.historyVersion) {
        // Берем версию вкладки
        result = item.versionSummary;
      }
    });
    return result;
  }

  /**
   * Добавить в список версий истории вкладки новую версию
   * добавляем в начало списка, что бы соблюдать порядок
   * @param type - тип вкладки
   * @param version - версии
   */
  private addVersionList(type: DossierAccessEnum, version: IVersionModel) {
    this.panelInfo[type].versionList.unshift(version);
  }

  /**
   * Сбрасываем все списки вресии для всех вкладок
   */
  private resetAllVersionsList() {
    Object.values(this.panelInfo).forEach(item => {
      item.versionList = [];
    });
  }

  /**
   * Проставляем списки вресии для всех вкладок
   * @param versions - список версий всего досье
   */
  setAllVersionsList(versions: Array<IVersionModel>) {
    this.resetAllVersionsList();
    versions.forEach(item => {
      this.addVersionList(item.partName, item);
    });
  }

  /**
   * Венрнуть признак отображения историии досье
   */
  getIsShowHistory(): boolean {
    return this.isShowHistory;
  }

  /**
   * Венрнуть номер версии истории
   */
  getHistoryVersion(): number {
    return this.historyVersion;
  }

  /**
   * Установить признак отображения историии досье
   * @param value - true - отображение как история досье
   * @param version - номер версии
   */
  setIsShowHistory(value: boolean, version: number) {
    this.isShowHistory = value;
    this.historyVersion = version;
  }

  /**
   * Проверить все ли вкладки заблонированы
   * @return true если все заблокированы
   */
  checkReadOnlyDossier(): boolean {
    let result = true;
    Object.keys(this.panelInfo).forEach(typePage => {
      const access = this.userService.getAccess(typePage);
      if (access && !access.readOnly) {
        result = false;
      }
    });
    return result;
  }

  /**
   * Проверить все ли вкладки сохранены
   * @return true если все сохранено
   */
  checkSaveDataDossier(): boolean {
    let result = true;
    Object.keys(this.panelInfo).forEach(typePage => {
      // Проверка на сохранение только не заблокированных вкладок
      if (!this.panelInfo[typePage].isSaveData.value && !this.panelInfo[typePage].isReadOnly.value) {
        result = false;
      }
    });
    return result;
  }

  getPageId(): string {
    return this.pageId || (localStorage.getItem("dossierPageID") === "null" ? ""
      : localStorage.getItem("dossierPageID"));
  }

  setPageId(value: string) {
    localStorage.setItem("dossierPageID", value);
    this.pageId = value;
  }

  /**
   * Сохранение состояния фильтра все/требующие решения
   * @param fieldName наименование поля фильтра
   * по которому выполняется фильтрация
   * @param value значение указанное в фильтре
   */
  setFilterValueToStore(fieldName: string, value) {
    localStorage.setItem(fieldName, value);
  }

  /**
   * Получения значения фильтра по ключу фильтрации
   * из локального хранилища клиента
   * @param key Ключ фильтрации по которому извлекается
   * значение фильтрации
   */
  getFilterValueFormStore(key: string) {
    return localStorage.getItem(key);
  }

  /**
   * Url для скачивания вложения
   */
  downloadDocUrl(item: DocumentDossier): String {
    return `/api/v1/docfiles/${item.registryId}/file/${item.idDoc}`;
  }

  /**
   * Получить список версий
   * @param dossierId - id досье
   * @param partName - id части или full для версий всего досье
   */
  getDocumentVersionList(dossierId: string, partName: string): Observable<any> {
    return this.api.get("/api/v1/docs/history/" + dossierId + "/" + partName + "/list");
  }

  /**
   * Получить часть документа по номеру версии
   */
  getDocumentByVersion(dossierId: string, partName: string, version: number): Observable<any> {
    return this.api.get("/api/v1/docs/history/" + dossierId + "/" + partName + "/" + version);
  }

  /**
   * Получить список результатов проверок
   */
  getCheckClientsList(params: any): Observable<any> {
    return this.api.post("/api/v1/check/clients", params, 'id');
  }

  /**
   * Получить список результатов проверок
   */
  getCheckClientsListForDossier(params: any, idDossier: string): Observable<any> {
    return this.api.post(`/api/v1/check/clients/${idDossier}`, params, 'id');
  }

  /**
   * Получить список результатов проверок
   */
  getCheckClientsListForDossierByDictType(dictType: string, idDossier: string): Observable<any> {
    return this.api.get(`/api/v1/check/clients/${dictType}/${idDossier}`);
  }

  /**
   * Получение списка клиентских операций
   */
  getClientsTransactions(params: any): Observable<any> {
    return this.api.post(`/api/v1/transaction/get`, params, 'id');
  }

  // TODO тут и далее типизировать ответ от сервера, заменить везде any
  /**
   * Получение операций по id клиента
   */
  getTransactionsByClientId(params: any, clientId: string): Observable<any> {
    return this.api.post(`/api/v1/transaction/get/${clientId}`, params, 'id');
  }

  /**
   * Получение списка анализа клиентских операций
   */
  getCheckTransactions(params: any): Observable<any> {
    return this.api.post(`/api/v1/transaction/check/get`, params, 'id');
  }

  /*
   * Метод Загрузки по Id проверки по транзакции
   */
  getCheckTransactionById(transactionId: string): Observable<any> {
    return this.api.get(`/api/v1/transaction/check/${transactionId}`);
  }

  /**
   * Метод завершения проверки анализа транзакции
   */
  finishCheckTransaction(transactionId: string): Observable<any> {
    return this.api.get(`/api/v1/transaction/check/finish/${transactionId}`);
  }

  /**
   * Метод продолжения клиентской проверки
   */
  continueCheckTransaction(transactionId: string): Observable<any> {
    return this.api.get(`/api/v1/transaction/check/continue/${transactionId}`);
  }

  /**
   * Сохранение проверки по операции
   */
  saveCheckTransaction(body: any): Observable<any> {
    return this.api.post(`/api/v1/transaction/check/update`, body);
  }

  /*
   * Метод загрузки по id, созданной транзакции
   */
  getClientTransactionById(transactionId: string): Observable<any> {
    return this.api.get(`/api/v1/transaction/${transactionId}`);
  }

  /**
   * Добавление новой транзакции
   */
  addTransaction(params: any): Observable<any> {
    return this.api.post(`/api/v1/transaction/create`, params);
  }

  /**
   * обновление существующей транзакции
   */
  updateTransaction(params: any): Observable<any> {
    return this.api.post(`/api/v1/transaction/update`, params);
  }

  /**
   * удаление транзакции
   * id - унинкальный идентификатор транзакции генерируемый на сервере
   */
  deleteTransaction(id: string): Observable<any> {
    return this.api.delete(`/api/v1/transaction/${id}`);
  }

  /**
   * копирование транзакции
   * id - унинкальный идентификатор транзакции генерируемый на сервере
   */
  copyTransaction(id: string): Observable<any> {
    return this.api.get(`/api/v1/transaction/${id}/copy`);
  }

  /**
   * Экспорт списка проверок в Excel
   */
  exportCheckClientsExcel(params: any): Observable<any> {
    return this.api.byte("/api/v1/export/check/clients/xlsx", params);
  }

  /**
   * Экспорт выбранного списка проверок в Excel
   */
  exportCheckClientListExcel(checkClientsIds: string[]): Observable<any> {
    return this.api.byte("/api/v1/export/check/clients/list/xlsx", checkClientsIds);
  }
  /**
   * Получить результат проверки
   */
  getCheckClientById(id: string): Observable<any> {
    return this.api.get(`/api/v1/check/client/${id}`);
  }

  /**
   * Получить результат проверки
   */
  getCheckClientsByIdAndDictType(id: string, dictType: string): Observable<any> {
    return this.api.get(`/api/v1/check/clientsByDict/${dictType}/${id}`);
  }

  /**
   * сохранить результат проверки
   */
  saveCheckClient(id: string, item: CheckClients) {
    return this.api.post(`/api/v1/check/client/${id}`, item);
  }

  /**
   * продолжить результат проверки
   */
  continueCheckClient(id: string, item: CheckClients) {
    return this.api.get(`/api/v1/check/client/continue/${id}`);
  }

  /**
   * завершить результат проверки
   */
  finishCheckClient(id: string) {
    return this.api.get(`/api/v1/check/client/finish/${id}`);
  }

  /**
   * Получение версии досье по id вкладки
   */
  getVersionByPageId(pageId: string): Observable<any> {
    return this.api.get(`/api/v1/docs/version/${pageId}`);
  }

  /**
   * Установить ACTIVE статус досье
   * @param dosserId - id досье
   */
  activeStatus(dosserId: string): Observable<any> {
    return this.api.get(`/api/v1/docs/activeStatus/${dosserId}`);
  }

  /**
   * Получить статус досье
   * @param dosserId - id досье
   */
  getStatus(dosserId: string): Observable<any> {
    return this.api.get(`/api/v1/docs/obtainStatus/${dosserId}`);
  }

  /**
   * Блокировка досье для редактирования
   * @param dosserId - id досье
   */
  startEdit(dosserId: string): Observable<any> {
    return this.api.get(`/api/v1/docs/startEdit/${dosserId}`);
  }

  /**
   * Разблокировка досье для редактирования
   * @param dosserId - id досье
   */
  stopEdit(dossierId?: string): void {
    if(dossierId || this._dossierId)
      this.api.get(`/api/v1/docs/stopEdit/${dossierId ?? this._dossierId}`).subscribe(data => {});
  }

  /**
   * Блокировка результата проверки досье для редактирования
   * @param checkId - id результата проверки досье
   */
  startEditCheck(checkId: string): Observable<any> {
    return this.api.get(`/api/v1/check/startEdit/${checkId}`);
  }

  /**
   * Разблокировка  результата проверкидосье для редактирования
   * @param checkId - id результата проверки досье
   */
  stopEditCheck(checkId: string): void {
    if(checkId || this._dossierId)
      this.api.get(`/api/v1/check/stopEdit/${checkId}`).subscribe(data => {});
  }

  /**
   * Блокировка результата анализа операции для редактирования
   * @param id - id  результата анализа операции
   */
  startEditCheckTransaction(id: string): Observable<any> {
    return this.api.get(`/api/v1/transaction/check/startEdit/${id}`);
  }

  /**
   * Разблокировка результата анализа операции для редактирования
   * @param id - id  результата анализа операции
   */
  stopEditCheckTransaction(id: string): void {
      this.api.get(`/api/v1/transaction/check/stopEdit/${id}`).subscribe(data => {});
  }

  analyzeRisk(data): Observable<any> {
    return this.api.post(`/api/v1/check/riskfactor`, data);
  }

  checkTransactions(data): Observable<any> {
    return this.api.post(`/api/v1/transaction/check/check`, data);
  }

  /**
   * Изменение структуры ошибок ФЛК (то, что не смог изменить сервер)
   */
  changeErrorFLK(errorFLKList: Array<ErrorFLKModel>): Array<ErrorFLKModel> {
    // убираем лишнюю часть пути clientInfo
    errorFLKList.forEach(item => {
      if (item.path) {
        item.path = item.path.replace(/svedClient.clientInfo/, 'svedClient');
        item.path = item.path.replace(/svedAU./, '');
        item.path = item.path.replace(/svedVPR./, '');
        item.path = item.path.replace(/svedBV./, '');
        item.path = item.path.replace(/svedUDS./, '');
        item.path = item.path.replace(/svedIOU./, '');
        item.path = item.path.replace(/svedPredst./, '');
        item.path = item.path.replace(/svedVPR.clientInfo./, '');
        item.path = item.path.replace(/svedBV.clientInfo./, '');
        item.path = item.path.replace(/partner./, '');
        // TODO: пока что руками - ждем сервера
        item.path = item.path.replace(/OKVED/, 'okved');
      }
    });
    // Объединять нельлья с верхним (тут нужны преобразованные данные)
    errorFLKList.forEach(item => {
      if (item.path) {
        // Группируем ошибки в DictType (code, name) в одну ошибку для поля
        groupDictType(item, errorFLKList);
      }
    });
    errorFLKList = deleteKey(errorFLKList);

    return errorFLKList;
    /**
     * Группируем ошибки в DictType (code, name) в одну ошибку для поля
     * @example ищем такие объекты:
     * {
     *   path: 'svedUL.okved.code'
     *   msg: 'ошибка по code'
     * }
     * {
     *   path: 'svedUL.okved.name'
     *   msg: 'ошибка по code'
     * }
     * и Объединяем в:
     * {
     *   path: 'svedUL.okved'
     *   msg: 'ошибка по code' + 'ошибка по name'
     * }
     * или в случае наличия одной из состовляющих выносим в общий
     * @example2 ищем такие объекты:
     * {
     *   path: 'svedUL.okved.code'
     *   msg: 'ошибка по code'
     * }
     * Получаем:
     * {
     *   path: 'svedUL.okved'
     *   msg: 'ошибка по code'
     * }
     */
    function groupDictType(item: ErrorFLKModel, errorTable: Array<ErrorFLKModel>){
      // Проверяем строки с CODE на конце - ищем строки с NAME на конце и объединяем
      if (/.code$/.test(item.path)) {
        check(item, errorTable, 'code', 'name');
      }
      // Проверяем строки с NAME на конце - ищем строки с CODE на конце и объединяем
      if (/.name$/.test(item.path)) {
        check(item, errorTable, 'name', 'code');
      }
      // Проходим рекурсивно по элеметам таблицы
      if (item.items) {
        Object.values(item.items).forEach((errorTableList: Array<ErrorFLKModel>) => {
          errorTableList.forEach(error => {
            groupDictType(error, errorTableList);
          });
        });
      }
    }

    /**
     * Проверка наличия одинаковых строк с разными code и name
     * @param item - элемент ошибки
     * @param errorTable - список ошибок данного уровня
     * @param haveKey - если тут code findKey = name и наоборот
     * @param findKey - обратное haveKey
     */
    function check(item: ErrorFLKModel, errorTable: Array<ErrorFLKModel>, haveKey, findKey) {
      // Получаем путь до элемента (путь без .{haveKey})
      const newPath = item.path.substring(0, item.path.indexOf('.' + haveKey));
      // ищем ошибку по с .{findKey} на конце
      const fieldNameError = errorTable.find(error => error.path === newPath + '.' + findKey);
      if (fieldNameError) {
        // Изменяем текущую ошибку на групповую
        item.path = newPath;
        item.msg = item.msg + ' ' + fieldNameError.msg;
        item.reason = item.reason + ' ' + fieldNameError.reason;
        // Помечаем найденую строку для последущего удаления
        fieldNameError.path = '$delete';
      }else{
        /**
         * Если есть только filedName.{haveKey} ошибка, то выносим ее на уровеь ниже (из example2 описания)
         */
        item.path = newPath;
      }
    }

    /**
     * Отфилтровываем поля с path = $delete рекурсивно
     */
    function deleteKey(errorTable: Array<ErrorFLKModel>) {
      errorTable = errorTable.filter(item => item.path !== '$delete');
      errorTable.forEach((item, index) => {
        errorTable[index] = del(item);
      });
      return errorTable;
      function del(item: ErrorFLKModel): ErrorFLKModel{
        if (item.items) {
          Object.keys(item.items).forEach(key => {
            item.items[key] = item.items[key].filter(error => error.path !== '$delete');
            const errorTableList = item.items[key];
            errorTableList.forEach((error, index) => {
              errorTableList[index] = del(error);
            });
          });
        }
        return item;
      }
    }
  }

  getOrgNameByType(clientInfo: ClientInfo): string {
    const clientType = clientInfo.clientType as ClientTypeEnum;
    let orgName = '';
    switch (clientType) {
      case ClientTypeEnum.LEGAL_ENTITY:
        orgName = clientInfo.svedUL.orgName.orgNameRu || clientInfo.svedUL.orgName.orgNameFullRu;
        break;
      case ClientTypeEnum.INDIVIDUAL_PERSON:
        orgName = this.getFio(clientInfo.svedFL.fio);
        break;
      case ClientTypeEnum.PRIVATE_PRACTITIONER:
        orgName = this.getFio(clientInfo.svedFLCP.fio);
        break;
      case ClientTypeEnum.INDIVIDUAL_ENTREPRENEUR:
        orgName = this.getFio(clientInfo.svedIP.fio);
        break;
      case ClientTypeEnum.FOREIGN_STRUCTURE:
        orgName = clientInfo.svedInbUL.orgName.orgNameRu || clientInfo.svedInbUL.orgName.orgNameFullRu;
        break;
      default:
        orgName = '';
    }
    return orgName;
  }

  getFio(fioObj: FIOType): string {
    return `${fioObj.lastName} ${fioObj.firstName} ${fioObj.middleName || ''}`
  }

  public updateClientInfo(dossierId): void {
    const cacheDataSvedClient = this.getFormDataCache(DossierAccessEnum.SVEDCLIENT);
    if (cacheDataSvedClient) {
      this.svedClientDataSubject.next(cacheDataSvedClient);
    } else {
        if (dossierId && this.userService.checkAccess(DossierAccessEnum.SVEDCLIENT)) {
          this.getDossierByType({
            id: dossierId,
            partName: DossierAccessEnum.SVEDCLIENT,
            model: null
          } as DossierRequest).subscribe((data) => {
            this.svedClientDataSubject.next(data);
          })
        }
    }
  }

  /**
   * Экспорт результатов анализа операций
   * params - параметры фильтра
   * idDossier - при наличии для конкретного досье
   */
  exportCheckTransactionsExcel(params: any, idDossier?: string): Observable<any> {
    return this.api.byte(`/api/v1/export/check/transaction/xlsx${idDossier ? `/${idDossier}` : ''}`, params);
  }

  /**
   * Экспорт списока результатов анализа операций
   * checkTransactionsIds - список id результатов анализа операций
   */
  exportCheckTransactionListExcel(checkTransactionsIds: string[]): Observable<any> {
    return this.api.byte(`/api/v1/export/check/transaction/list/xlsx`, checkTransactionsIds);
  }

  /**
   * Сформировать ФЭС на основе списока результатов анализа операций
   * checkTransactionsIds - список id результатов анализа операций
   */
  generateTransactionsFes(checkTransactionsIds: string[]): Observable<any> {
    return this.api.post(`/api/v1/transaction/check/generateFes`, checkTransactionsIds);
  }

  /**
   *  Информация по формированию ФЭС
   */
  generateFesInfo(): Observable<any> {
    return this.api.get(`/api/v1/transaction/check/generateFes/info`);
  }

  /**
   * Выгрузка общего отчета по результатам генерации ФЭС
   */
  downloadGenerateFesReport() {
    return this.api.byte('/api/v1/transaction/check/generateFes/download');
  }

  getCheckDopInfoValue(checkResult: CheckClients, code: string): string {
    return checkResult?.listDopInfo.find(item => item.code === code)?.value ?? '';
  }

  /**
   * Удаление id досье из стора
   */
  removeDossierIdStore(): void {
    window.sessionStorage.removeItem('dossierId')
  }

  /**
   * Добавление id досье в стор
   */
  setDossierIdStore(dossierId: string): void {
    window.sessionStorage.setItem('dossierId', dossierId)
  }

  /**
   * Получение токена для предотвращения отправки лишних запросов
   */
  getToken(): string {
    return window.sessionStorage.getItem('Auth-Token')
  }
}

