import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
/**
 * Пайп для преобразования формата длинной даты 15.04.2024 16:23:58 в короткую версию 15.04.2024, 16:23
 */
export class DateFormatPipe implements PipeTransform {

  transform(value: string): string {
    const regex = /(\d{2}\.\d{2}\.\d{4}) (\d{2}:\d{2}):\d{2}/;
    const matchedString = value.match(regex)
    return matchedString ? `${matchedString[1]}, ${matchedString[2]}` : value
  }

}
