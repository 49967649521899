import {Injectable} from '@angular/core';

@Injectable()
export class VisitedService {
  private visitedList = localStorage;

  constructor() {
    const ID_LIST = JSON.parse(this.visitedList.getItem("visitedList") || "{}");
    if (ID_LIST && Object.keys(ID_LIST).length === 0) {
      this.visitedList.setItem("visitedList", JSON.stringify({}));
    }
  }

  get getVisitedList(): Storage {
    return this.visitedList;
  }

  setVisitedId(id: string): void {
    const ID_LIST = JSON.parse(this.visitedList.getItem("visitedList") || "{}");
    ID_LIST[id] = true;
    this.visitedList.setItem("visitedList", JSON.stringify(ID_LIST));
  }

  getVisitedId(id: string): boolean {
    const ID_LIST = JSON.parse(this.visitedList.getItem("visitedList") || "{}");
    return ID_LIST[id];
  }

  clearVisitedList(): void {
    this.visitedList.setItem("visitedList", JSON.stringify({}));
  }
}
