import {Component, OnInit} from '@angular/core';
import {BaseFilterController} from "../BaseFilterController";
import {TableService, ValidatorService} from "@amlCore/services";
import {PassportInfoFilterModel} from "@amlCore/models";
import {FormBuilder, FormGroup} from "@angular/forms";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-passport-info-filter',
  templateUrl: './passportInfoFilter.component.html',
})
export class PassportInfoFilterComponent extends BaseFilterController implements OnInit {
  filterObjectModel = new PassportInfoFilterModel();
  placeholder = '';
  filterButtonShow = true;
  disabledSearchInput = true;
  constructor(protected tableSrv: TableService,
              private fb: FormBuilder,
              private validatorSrv: ValidatorService,
              protected activatedRoute: ActivatedRoute) {
    super(tableSrv, activatedRoute);
  }

  ngOnInit(): void {
    this.init();
    // дизейблим простой фильтр
    this.disabledSimpleFilter = true;
  }

  /**
   * Получение модели фильтра
   */
  getStartFilterModel(): PassportInfoFilterModel {
    return null;
  }

  /**
   * Описания полей
   */
  getFilterDescription() {
    return {
      series: 'Серия',
      number: 'Номер'
    };
  }

  /**
   * Метод создания формы
   * @return FormGroup
   */
  createFormModel(): FormGroup {
    return this.fb.group({
      search: '',
      // Серия
      series: ['', this.validatorSrv.getValidation({
        isReq: true
      })],
      // Номер
      number: ['', this.validatorSrv.getValidation({
        isReq: true
      })],
    });
  }
}
