import {AfterContentChecked, ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, TemplateRef, ViewChild, ViewContainerRef} from "@angular/core";
import {NgbActiveModal, NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ITableItem} from "../ITableItem";
import {AlertPanelComponent, AlertPanelService} from "../../alertPanel";
import {ErrorFLKModel} from "@amlCore/models";
import {AbstractControl, FormArray, FormControl, FormGroup} from "@angular/forms";
import {TableCompComponent} from "../tableComp.component";
import {Subscription} from "rxjs";
import {first, skip} from "rxjs/operators";
import {DossierService} from "../../../../client/services/dossier.service";
import {DossierAccessEnumValue} from "@amlCore/enums";
import { GlobalDataProviderService } from "src/app/core/services/global-data-provider.service";
import { SaveErrorModalComponent } from "../../save-error-modal";
import { DocumentTypeEnum } from "src/app/arm/documentForm/enums/documentTypeEnum";
import {fillPrescribedControls} from "src/app/core/utils";
import {prescribedFields_P407_ANSWER} from "./prescribedFields";

@Component({
  selector: 'app-table-editor',
  templateUrl: './tableEditor.component.html'
})
export class TableEditorComponent  implements OnInit, AfterContentChecked {
  /**
   * Место, где будет открыта форма на редактирование
   */
  @ViewChild('viewPlace', { static: true, read: ViewContainerRef }) viewPlace: ViewContainerRef;

  templateButton: TemplateRef<any>;

  saveBtnText = 'Сохранить';
  /**
   * Объект для отображения информации по сохранению
   */
  @ViewChild('alertObjSave', { static: true }) alertObjToSave: AlertPanelComponent;
  /**
   * Компонент, который открыт в таблице
   */
  tableItem: ITableItem;
  tableComp: TableCompComponent;
  /**
   * Индекс редактируемого элемента
   */
  editIndex: number;
  /**
   * true если форма просмотра
   */
  isReadOnly: boolean;
  /**
   * Форма редактируемого элемента
   */
  form: FormGroup | FormArray;
  /**
   * Начальные данные формы для выхода без сохранения изменений
   */
  initialFormValue;

  isErrorFLKSubscribe: Subscription;

  prescribedFields: string[];

  modal: NgbModalRef;
  logInvalidFieldsResult;
  flkErrors;
  serverResponseData;
  kodVidInfCondition: boolean;
  
  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private dossierService: DossierService,
              private activeModal: NgbActiveModal,
              private readonly modalService: NgbModal,
    private alertPanelSrv: AlertPanelService,
    private globalDataProviderService: GlobalDataProviderService,
    private readonly cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if (this.tableItem?.params?.typePage === DocumentTypeEnum.P407_ANSWER) {
      const kodVidInf = (this.tableItem as any).elMsgSrv?.kodVidInf;
      this.kodVidInfCondition = kodVidInf === '06' || kodVidInf === '07' || kodVidInf === '09' || kodVidInf === '91';
    }
  }

  open(param: ITableEditorItem): void {
    this.viewPlace.clear();
    const factory = this.componentFactoryResolver.resolveComponentFactory(param.component);
    const componentRef = this.viewPlace.createComponent(factory);
    this.tableItem = (componentRef.instance as ITableItem);
    if (this.tableItem.setAlertPanel)
      this.tableItem.setAlertPanel(this.alertObjToSave);

    this.form = this.tableItem.initTableItem(param);
    this.initialFormValue = this.form.value;
    this.tableItem.params = param.params;
    this.editIndex = param.editIndex;
    this.isReadOnly = param.isReadOnly;
    this.tableComp = param.tableComp;
    if (typeof (this.tableItem.getSaveBtnText) === "function")
      this.saveBtnText = this.tableItem.getSaveBtnText(); 
    
    this.cdr.detectChanges();
  }

  showFlKError(error: ErrorFLKModel): void {
    this.tableItem.showFLKError(error);
  }

  initErrorFLK(errors: Array<ErrorFLKModel>): void {
    this.tableItem.initErrorFLK(errors);
  }

  get getTitle(): string | null {
    return this.tableItem.getTitle();
  }

  /**
   * Назад без сохранения
   * @param isConfirmBack - isConfirmBack для подтверждения выхода без сохранения
   */
  goBack(isConfirmBack: boolean): void { 
    if (isConfirmBack) {
      if (this.initialFormValue?.tipUchastnika ?? this.initialFormValue?.tipKlienta) {
        (this.form?.get('tipUchastnika') ?? this.form?.get('tipKlienta')).reset(this.initialFormValue?.tipUchastnika ?? this.initialFormValue?.tipKlienta);
      }
      this.form?.reset(this.initialFormValue);
    } 
    this.viewPlace.clear();
    // если есть isParts, то считаем что это документ
    this.tableItem.params?.isParts !== undefined
      ? this.activeModal.close(isConfirmBack)
      : this.activeModal.close(null);
  }

  /**
   * Сохранение
   */
  save(): void {
    if (typeof this.tableItem?.beforeSaveCheck === 'function') {
      const beforeSaveCheck = this.tableItem.beforeSaveCheck(this);
      if (!beforeSaveCheck) return;
    }
    
    if (this.tableItem.documentType === "P407_ANSWER") {
      this.prescribedFields = prescribedFields_P407_ANSWER;
  
      fillPrescribedControls(this.form, this.prescribedFields);
    }
    
    if (this.tableItem.documentType === "OPERATIONS") {
      const svedDragMetall = this.form.get('svedDragMetall') as FormArray
      svedDragMetall?.controls?.forEach(control => {
        if (control?.value?.kodDragMetall === null && control?.value?.naimDragMetall === null) {
          control.disable()
        } else {
          control.enable()
        }
      })
    }
    
    const setSubmittedAndReturnResult = (): null | ITableEditorItem => {
      if (!this.tableItem.isValidItem()) {
        this.tableItem.setSubmitted(true);
        this.alertObjToSave.open(this.alertPanelSrv.getErrorMsg('Необходимо исправить ошибки в форме!'));
        if (this.tableItem?.isRemoteSave && this.kodVidInfCondition) {
          this.logInvalidFieldsResult = this.logInvalidFields(this.form);
          this.flkErrors = null;
          this.openSaveErrorModal(); 
        }
        
        return null;
      }
      return {
        form: this.form,
        editIndex: this.editIndex
      } as ITableEditorItem;
    };

    const result = setSubmittedAndReturnResult();

    // предпроверка ФЛК для сохранения досье
    if (typeof this.tableItem?.checkFLKComponent === 'function') {
      this.isErrorFLKSubscribe?.unsubscribe();
      const typePage = this.tableItem.typePage;
      // если проверка ФЛК досье производится с главной страницы - модалок 0,
      // для остальных случаев только проверка ФЛК в первой модалке
      const validLengthInstanceModal = typePage === 'svedClient' || typePage === 'dopInfo' || typePage === 'attributes' ? 0 : 1;
      const stackModal = this.modalService['_modalStack'];
      if (
        stackModal['_modalRefs']?.length <= validLengthInstanceModal
        && DossierAccessEnumValue.includes(typePage)
      ) {
        this.tableItem.checkFLKComponent();
        const isErrorFLKSubj = this.dossierService.panelInfo[typePage].isErrorFLK;
        this.isErrorFLKSubscribe = isErrorFLKSubj.pipe(skip(1))
          .subscribe((isErrorFLK: boolean) => {
              if (!isErrorFLK) {
                const currentResult = setSubmittedAndReturnResult();
                if (currentResult)
                  this.activeModal.close(currentResult);
              }
              this.isErrorFLKSubscribe?.unsubscribe();
            }
          );
        return;
      } else if (result) return this.activeModal.close(result);
    }

    if (this.tableItem?.isRemoteSave) { // todo отправка на сервер
      if (result) {
        this.tableComp.save(result, true);
        this.form.markAsPristine();

        if (this.kodVidInfCondition) {
          this.globalDataProviderService.serverResponseDataObs$.pipe(first()).subscribe(data => {
            if (data) {
              this.logInvalidFieldsResult = null;
              this.flkErrors = (data as any)?.check ;
                (this.flkErrors?.errors?.length || this.flkErrors?.warnings?.length || this.flkErrors?.fatals?.length) && this.openSaveErrorModal();
              if ((data as any)?.success) {
      
                this.editIndex == null
                  ? this.alertObjToSave.open(this.alertPanelSrv.getSuccessMsg('Добавление успешно'))
                  : this.alertObjToSave.open(this.alertPanelSrv.getSuccessMsg('Сохранение успешно'));
              }
            }
          });
        }
      } else return;
    }

    // todo если есть isParts, то считаем что это документ
    if (this.tableItem.params?.isParts !== undefined && !this.kodVidInfCondition) {
      if (result) {
        this.tableComp.save(result);
        this.form.markAsPristine({onlySelf: true});
        this.editIndex == null
          ? this.alertObjToSave.open(this.alertPanelSrv.getSuccessMsg('Добавление успешно'))
          : this.alertObjToSave.open(this.alertPanelSrv.getSuccessMsg('Сохранение успешно'));
        this.editIndex == null && this.activeModal.close();
      } else return;
    }
    else if (result && !this.tableItem?.isRemoteSave && this.kodVidInfCondition ) this.activeModal.close(result);
  }

  openSaveErrorModal() {
    this.modal = this.modalService.open(SaveErrorModalComponent, {
      centered: true,
      size: 'lg',
      scrollable: true,
      backdrop: 'static',
      keyboard: false
    });
    this.modal.componentInstance.flkErrors = this.flkErrors;
    this.modal.componentInstance.logInvalidFieldsResult = this.logInvalidFieldsResult;
  }


  logInvalidFields(control: AbstractControl, controlPath = '') {
    const invalidErrorsArray = [];
    const emptyFieldErrorsArray = [];

    const invalidFieldsFunc = (control: AbstractControl, controlPath = '') => {
      if (control instanceof FormControl && control.invalid ) {
        if (control.hasError('required')) {
          emptyFieldErrorsArray.push({ controlPath, errors: control.errors });
        } else {
          invalidErrorsArray.push({ controlPath, errors: control.errors });
        }
      } else if (control instanceof FormGroup) {
        for (const key in control.controls) {
          if (control.controls.hasOwnProperty(key)) {
            const nestedControl = control.get(key);
             invalidFieldsFunc(nestedControl, controlPath ? `${controlPath}.${key}` : key);
          }
        }
      } else if (control instanceof FormArray) {
        if (control.length !== 0) {
          for (let i = 0; i < control.length; i++) {
            const arrayControl = control.at(i);
             invalidFieldsFunc(arrayControl, `${controlPath}[${i}]`);
          }
        }
      }
    }

    invalidFieldsFunc(control, controlPath);
    return { invalidErrorsArray, emptyFieldErrorsArray };
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }
}



/**
 * Интерфес для общения компонента таблиц и формы редактирования
 */
export interface ITableEditorItem {
  form: any; // форма компонента
  editIndex?: number; // Если редактирование - индекс компонента в массиве
  tableComp?: TableCompComponent; // родитель
  component?: any; // компонент для открытия
  params?: any; // Дополнительные параметры
  isReadOnly?: boolean; // Форма просмотра
  model?: any | Array<any>; // Модель
}
