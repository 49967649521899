import {Injectable} from "@angular/core";

@Injectable()
export class StoreService {
  save(key: string, value: boolean | number | string): void {
    localStorage.setItem(key, String(value));
  }

  get(key: string): any {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (error) {
      console.error(error);
    }
  }

  removeKey(key: string): void {
    localStorage.removeItem(key);
  }

  // Для работы с хранилищем сессии
  setSession(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getSession(key: string): any {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (error) {
      console.error(error);
    }
  }
}
