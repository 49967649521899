import {Component} from "@angular/core";
import {DossierService} from "../../../../../services";
import {DictionaryService} from "@amlCore/services";
import {DossierBaseModel} from "../../dossierBaseModel";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {AlertPanelService} from "@amlCore/components";

@Component({
  selector: 'app-sved-fl-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './svedFLDossier.component.html'
})
export class SvedFLDossierComponent extends DossierBaseSubComponent {


  constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

}
