import { Component, OnInit } from "@angular/core";
import { BaseFilterController } from "../BaseFilterController";
import { FormBuilder } from "@angular/forms";
import { BounceFilter } from "@amlCore/models";
import {DictionaryService, TableService} from "@amlCore/services";

@Component({
  selector: "app-bounce-register-list-filter",
  templateUrl: "./bounceRegisterListFilter.component.html"
})

export class BounceRegisterListFilterComponent extends BaseFilterController implements OnInit {

  public selectObj = {
    kodOtkaz: this.dictSrv.getStaticData("kodOtkaz"),
  };
  public filterObjectModel = new BounceFilter();
  placeholder = 'Поиск по ФИО, наименованию, ИНН';
  constructor(private fb: FormBuilder,
              protected tableSrv: TableService,
              private dictSrv: DictionaryService) {
    super(tableSrv);
  }

  ngOnInit() {
    this.init();
  }

  /**
   * Метод создания формы
   * @return FormGroup
   */
  public createFormModel() {
    return this.fb.group(new BounceFilter());
  }

  getStartFilterModel(): any {
    return {
      subject: {
        subjectType: '1'
      }
    } as BounceFilter;
  }

  /**
   * Описания полей
   */
  public getFilterDescription(): any {
    return {
      otkazKod : 'Код отказа',
      subject : {
        subjectType: 'Тип субъекта',
        legalEntity: {
          name: 'Наименование',
          inn: 'ИНН',
          ogrn: 'ОГРН',
        },
        individualPerson: {
          name: 'ФИО',
          inn: 'ИНН',
          birthDate: 'Дата рождения',
          series: 'Серия документа',
          number: 'Номер документа',
        },
        individualEntrepreneur: {
          name: 'ФИО',
          inn: 'ИНН',
          ogrn: 'ОГРН',
          birthDate: 'Дата рождения',
          series: 'Серия документа',
          number: 'Номер документа',
        },
        foreignStructure: {
          name: 'Наименование',
          inboul: 'Регистрационный номер',
        }
      }
    };
  }

}
