import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scroll-btn',
  templateUrl: './scroll-btn.component.html',
  styleUrls: ['./scroll-btn.component.scss']
})
export class ScrollBtnComponent implements OnInit {

  showBtn: boolean;

  constructor() { }

  ngOnInit() {
    window.onscroll = () => {
      this.showBtn = pageYOffset >= 200;
    };
  }

  toggle() {
    window.scrollTo({top: 0, behavior: "smooth"});
  }

}
