import {Component, OnInit} from "@angular/core";
import {SetupFLKModel} from "../../../models";
import {DossierService} from "../../../services";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";

@Component({
  selector: 'app-setup-flk-window',
  templateUrl: './setupFLKWindow.component.html'
})
export class SetupFLKWindowComponent implements OnInit {
  setupFLK: SetupFLKModel[];
  searchErrorFLK: string;

  icons = {
    spinner: faSpinner
  };

  constructor(private dossierService: DossierService, private activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    // TODO: При наличии роутинга у элемента, перенести в resolve получение ошибок
    this.dossierService.clientCheck().subscribe(response => {
      this.setupFLK = response as SetupFLKModel[];
      this.initVisibleErrorFlk();
    });
  }

  /**
   * Выставяоем признак отображения по строке поиска
   */
  initVisibleErrorFlk() {
    this.setupFLK.forEach(item => {
      this.checkFindKeyInGroup(item);
    });
  }

  checkFindKeyInGroup(item: SetupFLKModel): boolean {
    let result = false;
    // Проверяем группу
    if (item.items) {
      item.items.forEach(setup => {
        // Если хотя бы один элемент в подгруппе подходит => отображаем текущий элемент
        if (this.checkFindKeyInGroup(setup)) {
          result = true;
        }
      });
    } else {
      // Проверяе элемент
      result = this.checkFindKey(item);
    }
    item.$searchHide = !result;
    return result;
  }

  /**
   * Провека настрокий на соответстиве кода поисковой строке
   * @param item - настройка
   */
  checkFindKey(item: SetupFLKModel): boolean {
    // Если нет поиска, всегда true
    if (!this.searchErrorFLK || this.searchErrorFLK === '') {
      return true;
    } else if (this.searchErrorFLK) {
      // Поиск по коду или наименованию
      return item.code && item.code.toUpperCase().indexOf(this.searchErrorFLK.toUpperCase()) >= 0 ||
        item.name && item.name.toUpperCase().indexOf(this.searchErrorFLK.toUpperCase()) >= 0;
    }
  }

  /**
   * Закрваем модальное окно
   */
  goBack() {
    this.activeModal.close(null);
  }
}
