import { Component, OnInit } from "@angular/core";
import { BaseFilterController } from "../BaseFilterController";
import { FormBuilder } from "@angular/forms";
import { RiskFactorInfoFilter } from "@amlCore/models";
import {DictionaryService, TableService} from '../../services';
import { IDictionaryItem } from '../../interfaces';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-risk-factor-filter',
  templateUrl: './riskFactorFilter.component.html'
})

export class RiskFactorFilterComponent extends BaseFilterController implements OnInit {

  public selectObj = {
    types: [] as IDictionaryItem<{
      id: string;
      name: string;
    }>[],

    levels: [] as IDictionaryItem<{
      id: string;
      name: string;
    }>[],
    scoreDegree: [] as IDictionaryItem<{
      id: string;
      name: string;
    }>[],
    npa: [] as IDictionaryItem<{
      id: string;
      name: string;
    }>[],
  };
  public filterObjectModel = new RiskFactorInfoFilter();
  placeholder = '';
  constructor(private fb: FormBuilder,
              protected tableSrv: TableService,
              private dictSrv: DictionaryService,
              protected activatedRoute: ActivatedRoute,
              ) {
    super(tableSrv, activatedRoute);
  }

  ngOnInit() {
    this.init();
    this.dictSrv.getRemoteData('risktype').then((result) => {
      this.selectObj.types = result.map((item: any) => {return {id: item.code, name: item.name};});
    });
    this.dictSrv.getRemoteData('risklevel').then((result) => {
      this.selectObj.levels = result.map((item: any) => {return {id: item.code, name: item.name};});
    });

    this.dictSrv.getscoreDegree().subscribe((data) => {
      this.selectObj.scoreDegree = data;
    });

    this.dictSrv.getRemoteData('npa').then((result) => {
      this.selectObj.npa = result.map((item: any) => {return {id: item.code, name: item.name};});
    });
    // дизейблим простой фильтр
    this.disabledSimpleFilter = true;
  }

  /**
   * Метод создания формы
   * @return FormGroup
   */
  public createFormModel() {
    return this.fb.group(new RiskFactorInfoFilter());
  }

  getStartFilterModel(): any {
    return null;
  }
  /**
   * Описания полей
   */
  public getFilterDescription(): any {
    return {
      type: 'Тип риска',
      level: 'Уровень риска',
      npa: 'НПА',
      cause: 'Обоснование',
      scoreDegree: 'Степень риска',
    };
  }
}

