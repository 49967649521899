import {BaseFilterModel} from "./baseFilter.model";

export class AuditFilter  extends BaseFilterModel {
  public success = null; // Поле результат
  public eventDateFrom = null; // Дата и время события С
  public eventDateTo =  null; // Дата и время события По
  public userIp = ''; // Ip Адресс
  public userId = null; // Пользователь
  public eventName = null; // Событие
  public info = ''; // Доп.информация/текст ошибки
}
