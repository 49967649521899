export class Subject {
  public typePart = null;   // Тип субъекта
  public clientType = null; // Тип организации / лица
  public legalEntity = {
    orgName: '',  // Наименование организации
    ogrn: '',     // ОГРН
    inn: '',      // ИНН
    kpp: '',      // КПП
  };
  public individualPerson = {
    lastName: '',    // Фамилия
    firstName: '',   // Имя
    middleName: '',  // Отчество
    birthDate: '',   // Дата рождения
    series: '',      // Серия документа
    numDoc: '',      // Номер документа
    inn: '',         // ИНН
    ogrn: '',        // ОГРН
  };
  public foreignStructure: {
    orgName: '', // Наименование организации
    regNum: '',  // Регистрационный номер
  };
}
