import {DictType} from "./dictType.model";
import {DossierDate, DossierT250, DossierT50} from "./base.model";

export class ResidenceDocInfoType {
  docType: DictType = undefined;
  docOther: DossierT250 = '';
  series?: DossierT50 = '';
  number?: DossierT50 = '';
  dateStart: DossierDate = '';
  dateEnd: DossierDate = '';
}
