import {DossierN3} from "./base.model";
import {ClientInfo} from "./clientInfo.model";
import {PartItemArray} from "../partItemArray.model";

export class SvedAUType extends PartItemArray{
  clientInfo: ClientInfo = new ClientInfo();
  part: DossierN3 = '';
  dateFrom = false;
  dateEnd = false;
}
