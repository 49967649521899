import {ActivatedRoute, Params, Router} from '@angular/router';
import {TableReactiveController} from './TableReactiveController';
import {RoleService, TableService, VisitedService} from '@amlCore/services';
import {Utils} from '../utils';
import {map} from 'rxjs/operators';
import {
  CommonService,
  DocumentConfigService,
} from '../../arm';
import {OnInit} from "@angular/core";

export abstract class TableReactiveUrlController extends TableReactiveController implements OnInit {

  abstract isShowVisited;
  protected params: any;
  /* хранит уникальный ключ для хнанения данных скролла */
  protected commonKeyStore = '';
  /* флаг установленный в
    позцию true - первая попытка
    скроллинга возможна.
  */
  protected hasFirstScrolling = true;
  // данные из url
  public queryUrlData: any;
  /* уникальный ключ для хранения
   * значения поля все/требующие-решения
   * в LocalStorage
  */
  public keyFilterResult: string;
  public pageName: string;
  protected disabledButton = false;
  protected isReadonlyRole = sessionStorage.getItem('readOnly') === 'true';
  constructor(protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected visitedService: VisitedService,
              protected tableService: TableService,
              params?: any,
              protected commonService?: CommonService,
              protected roleService?: RoleService) {
    super(visitedService, tableService, params, null, commonService);
    this.params = params;
    // общий уникальный ключ для LS
    this.activatedRoute.snapshot.url.forEach((segment) => {
      this.commonKeyStore += segment.path;
    });
    this.tableService.sharedMenu().emit(this.activatedRoute.snapshot.data.pageTitle);
    this.pageName = this.commonKeyStore;
  }

  /**
   * Синхронизация с таблицей. Выставляем параметры в поиск и пагинатор при чтение из строки урл
   */
  syncParams(params) {
    const filter: any = this.decodeFilter(params);
    if (TableReactiveController.isValidObject(filter)) {
      this.page = filter.page ? Number(filter.page) + 1 : 1;
      this.pageSize = filter.size;
      this.sort = filter.sort;
      this.dir = filter.dir;
    }
    return {...this.getFilterObject(filter)};
  }

  ngOnInit() {
    this.isReadonlyRole = this.roleService?.getIsReadOnlyRole(this.activatedRoute.snapshot?.pathFromRoot[2].routeConfig.path) || false
  }

  onFilter() {
    this.onPageChange(this.getSearchPage(this.filter));
  }
  /**
   * Выполняем высатвление параметров в урл при смене нумерации страцины, сортировки и поиске
   */
  navigate(query) {
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        replaceUrl: true,
        queryParams: {q: Utils.encodeJSONBase64(query)} as Params // remove to replace all query params by provided
      });
  }

  /**
   * Метод декодирования url из Base64
   * @param q url строки запроса закодированной в Base64
   */
  decodeFilter({q}): object {
    if (q !== undefined) {
      try {
        this.queryUrlData = Utils.decodeJSONBase64(q);
      }
      catch (err) {
        this.queryUrlData = '';
      }
      return this.queryUrlData;
      }
    return {};
  }

  createUniqueKeyForStore() {
    this.activatedRoute.url.pipe(map((segments) => segments.join(''))).subscribe((keyCode) => {
      this.keyFilterResult = `${keyCode}Result`;
    });
  }

  /**
   * метод получения объекта фильтра,
   * реализуемый на уровне класса компонента
   * @params filter объект фильтра
   */
  public abstract getFilterObject(filter): any;

  /**
   * Метод установки расширенного поиска в
   * первоначальное состояние
   */
  public onResetFilterActive() {
    if (this.keyFilterResult !== undefined) {
      this.storeService.save(this.keyFilterResult, false);
    }
    this.onFilter();
  }

  protected scrollRun() {
    setTimeout(() => {
      if (this.hasFirstScrolling) {
        this.hasFirstScrolling = false;
        this.tableService.scrollToPosition(this.commonKeyStore);
      }
    });
  }

  protected checkScrollOptions() {
    if (!this.tableService.hasNavigateToForm(this.commonKeyStore)) {
      this.tableService.resetScrollPosition(this.commonKeyStore);
      this.visitedService.clearVisitedList();
    }
  }
  /**
   * Открытие формы просмотра
   * Передача конфигуратор в функцию. !!openDocument не использовать! Переписать на этот, по ходу переделки удалять сервисы из конструктора
   */
  openDocumentWithConfig(id, docType, pageName, config: DocumentConfigService, unitType?) {
    this.router.navigate([`/${config.unitType ?? unitType}/document/${id}/${config.url}`], { queryParams : {page: pageName} });
  }
}
