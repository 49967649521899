import {Component, OnDestroy, OnInit} from "@angular/core";
import {BaseFilterController} from "../BaseFilterController";
import {FormBuilder, FormGroup} from "@angular/forms";
import {CheckTransactionsFilter} from "@amlCore/models";
import {DictionaryService, TableService} from "@amlCore/services";
import {IDictionaryItem, IDictItemType} from "../../interfaces";
import {ResourceService} from "@amlCore/services";
import {Utils} from "@amlCore/utils";
import {ICustomSelectMapper} from '@amlCore/components';
import {Unsubscribable} from "rxjs";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-check-transactions-filter",
  templateUrl: "./checkTransactionsFilter.component.html"
})

export class CheckTransactionsFilterComponent extends BaseFilterController implements OnInit, OnDestroy {
  public filterObjectModel = new CheckTransactionsFilter();
  public currencyMapper: ICustomSelectMapper;
  unsubList: Unsubscribable[] = [];


  memberStatusShow = false; // Отображение поля Статус участника
  memberStatusShowFields = ['name', 'inn']; // Поля по котором отображать поле Статус участника + еще по clientType (своя обработка)
  /*справочники*/
  public selectObj = {
    /*тип килента*/
    clientTypeList: [] as IDictItemType[],
    /*результат проверки*/
    resultCheckList: [] as IDictionaryItem<{
      id: string;
      code: string;
      name: string;
    }>[],
    /*пользователи*/
    users: [] as IDictionaryItem<{ id: string; personName: string; }>[],
    /*наименование валют*/
    currencyList: [] as IDictionaryItem<{ code: string; name: string; }>[],
    /*тип документа*/
    docsType: [] as IDictionaryItem<{ code: string; name: string; }>[],
    /*Роль*/
    partnerRole: this.dictSrv.getStaticData("partnerRole") as IDictionaryItem<{ code: string; name: string; }>[],
    /* Статусы участника */
    participantState: [] as IDictionaryItem<{ code: string; name: string; }>[]
  };
  placeholder = 'Поиск по ФИО, наименованию, ИНН , коду проверки';

  constructor(private fb: FormBuilder,
              private dictSrv: DictionaryService,
              protected tableSrv: TableService,
              private resourceSrv: ResourceService,
              protected activatedRoute: ActivatedRoute) {
    super(tableSrv, activatedRoute);
  }

  ngOnInit(): void {
    this.init();
    this.loadUsers();
    this.loadCurrency();
    this.loadDocTypes();
    this.loadResultChecking();
    this.dictSrv.loadTypeClient().then(result => {
      this.selectObj.clientTypeList = result;
    });

    this.dictSrv.getRemoteData('participant_state').then(stateList => {
      this.selectObj.participantState = stateList;
    });

    this.currencyMapper = {
      toModel: this.currencyMapperToModel.bind(this)
    } as ICustomSelectMapper;
    const clientTypeSub = this.formModel.get('clientType').valueChanges.subscribe(() => {
      // Проверка отображения поля Статус участника
      this.initMemberStatus();
    });
    this.unsubList.push(clientTypeSub);
    /**
     * Запуск проверки для поля Статус участника по memberStatusShowFields
     */
    this.memberStatusShowFields.forEach(key => {
      const sub = this.formModel.get(key).valueChanges.subscribe(data => {
        this.initMemberStatus();
      });
      this.unsubList.push(sub);
    });
  }

  /**
   * Инициализация поля Статус участника
   * видно и доступно для заполнения только при наличии полей [memberStatusShowFields] или clientType
   */
  private initMemberStatus(): void {
    const memberStatusFiled = this.formModel.get('memberStatus');
    let result = false;
    ['clientType', ...this.memberStatusShowFields].forEach(filedKey => {
      const value = this.formModel.get(filedKey).value;
      if (!result) {
        result = value && value !== '';
      }
    });
    this.memberStatusShow = result;
    if (result) {
      memberStatusFiled.enable();
    } else {
      memberStatusFiled.disable();
    }
  }

  public createFormModel(): FormGroup {
    return this.fb.group(new CheckTransactionsFilter());
  }

  /**
   * Маппер для код валюты
   */
  private currencyMapperToModel(origin: any, isDictData: boolean): any {
    if (isDictData) {
      return {
        code: origin.iso_dig,
        name: origin.name
      };
    } else {
      return origin;
    }
  }

  /**
   * метод загрузки пользователя, под которым выполнена загрузка
   */
  private loadUsers(): void {
    const queryParams = {sort: "fullName", dir: "asc", page: "0", size: "200"};
    this.resourceSrv.getUserList(queryParams).subscribe(({content}) => {
      this.selectObj.users = (content || []);
    });
  }

  /**
   * Метод загрузки справочника валют по okv
   */
  private loadCurrency(): void {
    const russianRubCode = "810";
    const stateRubCurrency = "ПРИЗНАК РОССИЙСКОГО РУБЛЯ";
    this.dictSrv.getRemoteData("okv").then((content) => {
      this.selectObj.currencyList = (content || []).filter((item) => {
        return ((item.iso_dig === russianRubCode && item.name !== stateRubCurrency) ||
          !Utils.dateNowIsAfter(item.cb_date));
      });
    });
  }

  /**
   * Метод загрузки типов документов
   */
  private loadDocTypes(): void {
    this.dictSrv.getRemoteDictData('viddoc', {
      size: 20000,
      page: 0,
      sort: 'id',
      dir: 'ASC'
    }, 1 ).subscribe((result) => {
      this.selectObj.docsType = result;
    });
  }

  /*
   * Метод загрузки справочников результатов проверки
   */
  private loadResultChecking(): void {
    this.dictSrv.getRemoteData('resultchecking').then(result => {
      this.selectObj.resultCheckList = result;
    });
  }

  getStartFilterModel(): any {
    return null;
  }

  /**
   * Описания полей
   */
  public getFilterDescription(): any {
    return {
      checkDateFrom: 'Период проверки С',
      checkDateTo: 'Период проверки По',
      user: 'Пользователь',
      dateFrom: 'Дата операции С',
      dateTo: 'Дата операции По',
      operId: 'Уникальный номер операции',
      dateDocFrom: 'Дата документа С',
      dateDocTo: 'Дата документа По',
      typeDoc: 'Тип документа',
      numberDoc: 'Номер документа',
      amountCurrFrom: 'Сумма операции в валюте От',
      amountCurrTo: 'Сумма операции в валюте До',
      amountRubFrom: 'Сумма операции в рублях От',
      amountRubTo: 'Сумма операции в рублях До',
      purpose: 'Назначение платежа',
      account: 'Номер счета',
      clientType: 'Тип клиента',
      name: 'Наименование/ФИО',
      inn: 'ИНН (КИО)',
      checkResult: 'Результат проверки',
      memberStatus: 'Статус участника',
      vid: 'Роль',
      className: 'Код проверки'
    };
  }

  customDescFields = [{
    fieldName: 'currency',
    customDescription: () => 'Код валюты'
  }]

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.unsubList.forEach(sub => sub.unsubscribe());
  }


}
