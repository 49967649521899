import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DossierService} from "../../../../../services";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmComponent} from "@amlCore/modals";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {IVersionModel} from "../../../../../interfaces";

@Component({
  selector: 'app-document-version-select',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.css']
})
export class VersionComponent implements OnInit {

  @Input() partName: string;
  @Input() dossierId: string;
  @Input() typeView: VersionTypeView;
  @Input() isHistory; // Признак отображения досье в режиме истории версий
  @Input() versionHistory; // Версия истории

  @Output("versionData")
  selectChangeEvent = new EventEmitter<IVersionCallback>();
  // Список версий
  versionList: Array<IVersionModel> = [];
  // Номер выбранной версии (0 - актуальная)
  selectedVersionValue = 0;
  // Актуальная версия
  actualVersion;
  isActualVersion = true;
  icons = {
    waring: faExclamationTriangle,
  };
  alignItemsStyle = 'flex-end';
  flexDirectionStyle = 'column';

  constructor(private dossierSrv: DossierService,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    this.getVersionList(true);
    if (this.typeView === 'all') {
      this.alignItemsStyle = 'flex-start';
      this.flexDirectionStyle = 'row';
    }
  }

  /**
   * Загрузить список версий
   * @param refreshActualVersion - устанвить активную версию
   */
  getVersionList(refreshActualVersion?: boolean) {
    if (this.dossierId && this.partName) {
      this.dossierSrv.getDocumentVersionList(this.dossierId, this.partName).subscribe(data => {
        this.versionList = data;
        if (this.versionList && this.versionList.length > 0) {
          this.actualVersion = this.versionList[0];
        }
        this.dossierSrv.setAllVersionsList(this.versionList);
        // Устанавливаем активную версию при перезугрузке компонента
        // Если отображаем как историю, то берем из Input компонента
        if (this.isHistory) {
          this.selectedVersionValue = this.versionHistory;
        } else {
          // Иначе устанавливаем атуальную версию
          if (refreshActualVersion) {
            this.selectedVersionValue = this.getVersionNumberByType(this.actualVersion);
          }
        }
        if (this.selectedVersionValue !== 0) {
          this.isActualVersion = this.selectedVersionValue === this.getVersionNumberByType(this.actualVersion);
        }
      });
    }
  }

  /**
   * Выставляем выбраную версию
   * @param version - номер версии
   */
  public setVersion(version: number) {
    this.selectedVersionValue = version;
  }

  /**
   * Меняем версию руками
   * @param selectVersion - выбраннная версия
   */
  changeVersion(selectVersion: IVersionModel) {
    const modal = this.modalService.open(ConfirmComponent);
    modal.componentInstance.text = 'Вы уверены, что хотите сменить версию?';
    modal.result.then(data => this.success(selectVersion));
  }

  /**
   * Для всего досье - сообщаем выбраную версию
   * Для вкладок - грузим версию
   * @param selectVersion - выбранная версия
   */
  success(selectVersion: IVersionModel) {
    this.selectedVersionValue = this.getVersionNumberByType(selectVersion);
    this.isActualVersion = this.selectedVersionValue === this.getVersionNumberByType(this.actualVersion);
    if (this.typeView === 'all') {
      this.selectChangeEvent.emit({
        isActualVersion: this.isActualVersion,
        versionInfo: selectVersion
      } as IVersionCallback);
    } else if (this.typeView === 'part') {
      this.dossierSrv.getDocumentByVersion(this.dossierId, this.partName, this.selectedVersionValue).subscribe(data => {
        this.selectChangeEvent.emit({
          model: data,
          isActualVersion: this.isActualVersion,
          versionInfo: selectVersion
        } as IVersionCallback);
      });
    }
  }

  selectActualVersion() {
    this.success(this.actualVersion);
  }

  /**
   * Выводим переменную отвечающую за версию по уровню отображения
   */
  getVersionNumberByType(version: IVersionModel): number {
    switch (this.typeView) {
      case "all":
        return version.versionFull;
      case "part":
        return version.versionSummary;
      case "person":
        return version.version;
      default:
        console.log('Необходимо указать тип отображения версии');
        return 0;
    }
  }

  isSelectedVersion(version: IVersionModel) {
    return this.selectedVersionValue === this.getVersionNumberByType(version);
  }


}

/**
 * Тип формата отображения истории версии
 * person - история конкретного лица
 * part - история вкладки
 * all - история всего досье
 */
export type VersionTypeView = 'person' | 'part' | 'all';

/**
 * Ответ компонента
 */
export interface IVersionCallback {
  // Данные версии
  model: any | Array<any>;
  // Актуальная ли версия
  isActualVersion: boolean;
  // Модель выбранной версии
  versionInfo: IVersionModel;
}
