import {AbstractControl, FormArray, FormControl, FormGroup} from "@angular/forms";
import {AuthorityType, ClientInfo} from "../../client/models/dossier";
import moment from "moment";
import {pageNameEnum} from "@amlCore/enums";

export class Utils {
  static passPattern = '(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,30}';
  static emailPattern = '[А-Яа-яA-Za-z0-9._%+-]+@[А-Яа-яA-Za-z0-9.-]+\\.[А-Яа-яA-Za-z]{2,}$';
  static customPatterns = {
    'E': // символы (в том числе русские) или цифры
      {
        pattern: new RegExp('[а-яА-ЯA-Za-z0-9]')
      },
    'L': // латинские буквы, цмфры и спецсимволы (без русских букв и пробела)
      {
        pattern: new RegExp('[^а-яА-Я ]')
      }
  };
  static floatPattern = '(([1-9][0-9]{0,19})(\.[0-9]{1,2})?)';

  static padNumber(num) {
    let s = num + "";
    while (s.length < 2) {
      s = "0" + s;
    }
    return s;
  }

  /**
   * Метод позволяющий сравнить сегодняшную дату
   * с датой выхода валюты из денежного оборащения.
   * @param dateValue - строка в формате yyyymmdd
   */
  static dateNowIsAfter(dateValue: string) {
    if (!dateValue) {
      return false;
    }
    const dateCurrency = moment(dateValue, "YYYY-MM-DD").format();
    return moment().isAfter(dateCurrency);
  }

  static goBack() {
    history.back();
  }

  static scrollTopSmooth() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  /**
   * Копия объекта
   * @param obj - объект
   */
  static clone(obj): any {
    return JSON.parse(JSON.stringify(obj));
  }

  /**
   * Получить значение поля из сведений о лицах
   * @param item - элемент
   * @param fieldName - необходимое поле:
   * fioName - фио/наименование
   * inn - ИНН (КИО)
   *
   */
  static getFieldFromClientInfo(item: ClientInfo, fieldName): string {
    let result = '';
    if (!item) {
      return result;
    }
    const clientType = item.clientType;
    let person;
    switch (clientType) {
      case "1":
        // Юридическое лицо
        person = item.svedUL;
        break;
      case "2":
        // Физическое лицо
        person = item.svedFL;
        break;
      case "3":
        // Индивидуальный предприниматель
        person = item.svedIP;
        break;
      case "4":
        // Физическое лицо, занимающееся частной практикой
        person = item.svedFLCP;
        break;
      case "5":
        // Иностранная структура без образования юридического лица
        person = item.svedInbUL;
        break;
    }
    const ul = clientType === "1" || clientType === "5";

    switch (fieldName) {
      case 'fioName':
        if (ul && person.orgName) {
          result = person.orgName.orgNameRu || person.orgName.orgNameFullRu || person.orgName.orgNameIn || person.orgName.orgNameFullIn;
        }
        if (!ul && person.fio) {
          if (person.fio.lastName) {
            result = person.fio.lastName + ' ';
          }
          if (person.fio.firstName) {
            result += person.fio.firstName + ' ';
          }
          if (person.fio.middleName) {
            result += person.fio.middleName;
          }
        }
        break;
      case 'inn':
        if (ul) {
          result = person.inn;
        }
        if (!ul && person.personalData && person.personalData.inn) {
          result = person.personalData.inn;
        }
        break;
    }


    return result;
  }

  /**
   * Получить значение поля из сведений о представителе
   * @param item - элемент
   * @param fieldName - необходимое поле:
   * stopPowers - Прекращение полномочий
   *
   */
  static getFieldFromAuthority(item: AuthorityType, fieldName): string {
    let result = null;
    if (!item) {
      return result;
    }
    switch (fieldName) {
      case 'stopPowers':
        result = item.stopPowers;
    }
    return result;
  }

  static getFileNameFromContentDisposition(response: any) {
    let result: string;
    try {
      const fileName = response.headers.get('Content-Disposition');
      result = fileName.split(';')[1].trim().split('=')[1];
      result = result.replace(/"/g, '');
      result = result.replace(/\+/g, ' ');
    } catch (e) {
      console.warn(e);
      result = 'file';
    }
    return result;
  }

  /**
   * Разворачиваем дату dd/mm/yyyy => yyyy.mm.dd
   */
  static revertDate(data, sepIn, sepOut) {
    let result = '';
    if (data) {
      result = data.split(sepIn);
      result = `${ result[2] }${ sepOut }${ result[1] }${ sepOut }${ result[0] }`;
    }
    return result;
  }

  /**
   * Удаление ключей объектов
   * @param obj - объект
   * @param keyToDel - имя или список имен ключа для удаления
   */
  static removeKeysFromObj(obj: any, keyToDel: string | Array<string>) {
    Object.keys(obj).forEach(key => {
      if (Array.isArray(keyToDel) && keyToDel.includes(key) || key === keyToDel) {
        delete obj[key];
      } else {
        const value = obj[key];
        if (Array.isArray(value)) {
          value.forEach(item => {
            if (item instanceof Object) {
              this.removeKeysFromObj(item, keyToDel);
            }
          });
        } else {
          if (value instanceof Object) {
            this.removeKeysFromObj(value, keyToDel);
          }
        }
      }
    });
  }

  static deleteEmptyObj(data, isForm?: boolean): number | void {
    let amountNullFields = 0;
    Object.keys(data).forEach(key => {
      const obj = data[key];
      if (obj instanceof Object) {
        if (Array.isArray(obj)) {
          if (obj.length > 0) {
            for (let i = 0; i < obj.length;) {
              const amountFieldsBeforeEditing = Object.keys(obj[i]).length;
              const amountNullFieldsAfterEditing = this.deleteEmptyObj(obj[i], isForm);
              // Если объект списка стал пустым - удаляем
              if (obj.length === 0) {
                break;
              } else if (Object.keys(obj[i]).length === 0
                || amountFieldsBeforeEditing === amountNullFieldsAfterEditing) {
                amountNullFields++;
                obj.splice(i, 1);
              } else {
                i++;
              }
            }
          }
          // если пустой массив - удаляем
          if (obj.length === 0) {
            !isForm
              ? delete data[key]
              : amountNullFields++;
          }
        } else {
          const amountFieldsBeforeEditing = Object.keys(obj).length;
          // Запускаем проверку объекта
          const amountNullFieldsAfterEditing = this.deleteEmptyObj(obj, isForm);
          // Внутри объекта все удалилось
          if (Object.keys(obj).length === 0
            || amountFieldsBeforeEditing === amountNullFieldsAfterEditing) {
            if (!isForm) {
              delete data[key];
            } else {
              data[key] = null;
              amountNullFields++;
            }
          }
        }
      } else {
        // Если пустая строка - удаляем (для форм - подсчитываем количество нулевых полей)
        if (obj === '' || obj === null || obj === undefined || (isForm && obj === 0)) {
          !isForm
            ? delete data[key]
            : amountNullFields++;
        }
      }
    });

    if (isForm) {
      return amountNullFields;
    }
  }

  static encodeJSONBase64(data) {
    const value = JSON.stringify(data);
    return btoa(unescape(encodeURIComponent(value)));
  }

  static decodeJSONBase64(data) {
    const value = decodeURIComponent(escape(atob(data)));
    return JSON.parse(value);
  }

  /**
   * Сортировка ключей объекта
   * @param data - объект для сортировки
   */
  static sortObjectKey(data) {
    const sortObject = o => Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
    if (data instanceof Object) {
      data = sortObject(data);
    }
    Object.keys(data).forEach(key => {
      const obj = data[key];
      if (obj instanceof Object) {
        if (Array.isArray(obj)) {
          (obj as Array<any>).forEach((item, index: number) => {
            data[key][index] = this.sortObjectKey(item);
          });
        } else {
          data[key] = this.sortObjectKey(obj);
        }
      }
    });
    return data;
  }

  static checkBrowser() {
    var ua = navigator.userAgent, tem, M = ua.match(
      /(opera|yabrowser|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: (tem[1] || '') };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edg|YaBrowser)\/(\d+)/);
      if (tem != null) {
        return { name: tem[1].replace('OPR', 'Opera'), version: tem[2] };
      }
    }
    M = M[2]
      ? [M[1], M[2]]
      : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return { name: M[0], version: M[1] };
  }

  static formatBytes(bytes, decimals = 2) {
    if (!+bytes) {
      return '0 байт';
    }

    const k = 1024;
    const dm = decimals < 0
      ? 0
      : decimals;
    const sizes = ['байт', 'Кб', 'Мб', 'Гб', 'Тб'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${ parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) }${ sizes[i] }`;
  }

  static searchByCodeOrName() {
    return this.searchByFieldsFn(['code', 'name']);
  }

  static searchByFieldsFn(fields: string[]) {
    /**
     * @param term - строка поиска из select
     * @param item - элемент списка
     */
    return (term: string, item: any) => {
      if (term && item) {
        term = term.toLocaleLowerCase();
        return Array.from(fields).some(searchField => {
          return item[searchField].toLocaleLowerCase().indexOf(term) != -1;
        });
      }
      return true;
    };
  }

  static getDateAsNgbStructDate(date: Date): {year: string, month: string, day: string} {
    return {
      year: String(date.getFullYear()),
      month: String(date.getMonth() + 1).padStart(2, '0'),
      day: String(date.getDate()).padStart(2, '0'),
    };
  }

  /**
   * Функция для преобразования транслита в кириллицу
   * @param word 
   * @returns 
   */
  static translit(word): string {
    const converter = {
      'Shh': 'Щ', 'shh': 'щ',

      'Yo': 'Ё', 'yo': 'ё', 
      'Zh': 'Ж', 'zh': 'ж', 
      'Ch': 'Ч', 'ch': 'ч', 
      'Sh': 'Ш', 'sh': 'ш', 
      'Yu': 'Ю', 'yu': 'ю', 
      'Ia': 'Я', 'ia': 'я',
      'Eh': 'Э', 'э': 'я',
      'Ts': 'Ц', 'ts': 'ц',

      'A': 'А', 'a': 'а',
      'B': 'Б', 'b': 'б', 
      'V': 'В', 'v': 'в',
      'G': 'Г', 'g': 'г',
      'D': 'Д', 'd': 'д',
      'E': 'Е', 'e': 'е',
      'Z': 'З', 'z': 'з',
      'I': 'И', 'i': 'и',
      'J': 'Й', 'j': 'й',
      'K': 'К', 'k': 'к',
      'L': 'Л', 'l': 'л',
      'M': 'М', 'm': 'м', 
      'N': 'Н', 'n': 'н',
      'O': 'О', 'o': 'о',
      'P': 'П', 'p': 'п',
      'R': 'Р', 'r': 'р',
      'S': 'С', 's': 'с',
      'T': 'Т', 't': 'т',
      'U': 'У', 'u': 'у',
      'F': 'Ф', 'f': 'ф',
      'H': 'Х', 'h': 'х',
      'Q': 'Кв', 'q': 'кв'
    };

    for (const [key, value] of Object.entries(converter)) {
      word = word.replaceAll(key, value);
    }

    return word;
  }
}

/**
 * функция копирования контролла
 * @param control - откуда копируем
 */
export function cloneAbstractControl<T extends AbstractControl>(control: T): T {
  let newControl: T;
  if (control instanceof FormGroup) {
    const formGroup = new FormGroup({}, control.validator, control.asyncValidator);
    const controls = control.controls;
    Object.keys(controls).forEach(key => {
      formGroup.addControl(key, cloneAbstractControl(controls[key]));
    });
    newControl = formGroup as any;
  } else if (control instanceof FormArray) {
    const formArray = new FormArray([], control.validator, control.asyncValidator);
    control.controls.forEach(formControl => formArray.push(cloneAbstractControl(formControl)));
    newControl = formArray as any;
  } else if (control instanceof FormControl) {
    newControl = new FormControl(control.value, control.validator, control.asyncValidator) as any;
  } else {
    throw new Error('Error: unexpected control value');
  }
  if (control.disabled) {
    newControl.disable({ emitEvent: false });
  }
  return newControl;
}

/**
 * функция для создания итерируемого объекта
 * @param array - Массив данных для итерации
 */
export function iteratorObject<T>(array: Array<T>) {
  let nextIndex = 0;
  return {
    next: function (): any {
      return nextIndex < array.length ?
          {object: array[nextIndex++], done: false} :
          {done: true};
    }
  }
}

/**
   * Метод для добавления пустого значения к предписанным полям
   * @documentForm форма документа, в которой нужно внести изменения
   * @prescribedFieldsName массив путей к полям, котоорые нужно изменить
   */
export function fillPrescribedControls(documentForm: FormGroup | FormArray, prescribedFieldsName: string[] ) {
  prescribedFieldsName.forEach((fieldName: string) => {
    const field: AbstractControl = documentForm.get(fieldName);
    if (field?.value === null) {
      field.patchValue('')}
  });
}

export function getSectionByPageName(pageName: string): string {
  return pageNameEnum[pageName] || ''
}

/**
  * Функция для определения наличия проверенного документа
  * в массиве выбранных документов
  */
export function hasCheckedDoc(selectedDoc): boolean {
  return selectedDoc.some(doc => {      
    return doc.checked === true;
  })
}
