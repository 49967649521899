export class CheckDopInfo {
  id: string;
  type: string;
  code: string;
  name: string;
  value: string;

  constructor() {
  }
}
