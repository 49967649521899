import { Component, Input } from "@angular/core";
import { DossierService } from "src/app/client/services";
import { AlertPanelService, DictionaryService } from "src/app/core";
import { DossierBaseModel } from "../../dossierBaseModel";
import { DossierBaseSubComponent } from "../dossierBaseSub.component";

@Component({
  selector: 'stop-powers-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './stopPowers.component.html'
})
export class StopPowersComponent extends DossierBaseSubComponent {
  @Input() title = '';
  constructor(protected dossierService: DossierService,
    protected dictSrv: DictionaryService,
    protected dossierBaseModel: DossierBaseModel,
    protected alertPanelService: AlertPanelService) {
super(dossierService, dictSrv, alertPanelService);
}
checked() {
  this.formGroupApp.get('stopPowers').patchValue(Number(this.formGroupApp.get('stopPowers').value));
}
}

