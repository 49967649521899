import {Injectable, OnDestroy, Inject} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {BehaviorSubject, Observable, Subject, Unsubscribable} from "rxjs";
import {DOCUMENT} from "@angular/common";
import {SharedMenuService} from "./sharedMenu.service";
import {BaseFilterController} from "../filterComponents";
@Injectable()
export class TableService implements OnDestroy {
  public focusRowId = ''; // Место для хранения id для дальнейшей фокусировки
  public bufferId = ''; // Буфферная переменная для понимания был ли использован метод setFocusRowId до NavigationEnd
  private ls = localStorage;
  private used = false; // Признак использования метода
  private PREFIX_NAVIGATE = "hasNavigateToForm"; // внутренний префикс для ключа навигации
  private PREFIX_SCROLL = "scroll"; // внутренний префикс для ключа скроллирования
  routerSub: Unsubscribable;
  private baseFilter: Subject<BaseFilterController> = new Subject();
  private alertSubject$ = new Subject<{ type: 'warn' | 'error' | 'success', message: string }>()
  public alertMessage = this.alertSubject$.asObservable()

  constructor(private router: Router,
              protected sharedMenuSrv: SharedMenuService,
              @Inject(DOCUMENT) private document) {
    this.routerSub = router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (this.used) {
          this.used = this.focusRowId === '';
          // Необходимо сбрасывать флаг для всех состояний, кроме того для которого оно было установлено (вызван метод setFocusRowId)
          this.focusRowId = this.bufferId;
        } else {
          this.focusRowId = '';
        }
      }
    });

  }

  clear() {
    this.focusRowId = null;
  }

  setBaseFilter(filter: BaseFilterController) {
    this.baseFilter.next(filter);
  }

  getBaseFilter(): Subject<BaseFilterController> {
    return this.baseFilter;
  }

  setFocusRowId(id) {
    // Устанавливаем значенеи через буфферную переменную, что бы понимать при NavigationEnd был ли вызван этот метод
    this.bufferId = id;
    this.focusRowId = '';
    this.used = true;
  }
  ngOnDestroy() {
    this.routerSub.unsubscribe();
  }

  public getFilterSubject(filter) {
    return {...filter, ...(filter?.subject || {}),
            ...(() => {
                  if (filter && filter.subject) {
                    const clientMap = (() => {
                      if (filter.subject.hasOwnProperty('individualEntrepreneur')) {
                        return {
                          1: "legalEntity",
                          3: "individualEntrepreneur",
                          5: "foreignStructure",
                        };
                      }
                      return {
                          1: "legalEntity",
                          5: "foreignStructure",
                        };
                    })();
                    const typeCode = (filter.subject?.clientType || filter.subject?.type);
                    const clientType = (clientMap[parseInt(typeCode, 10)] || "individualPerson");
                    return filter.subject[clientType];
                  }
                  return {};
                })()};
  }

  /**
   * Метод сохраняющий текущую позицию скролла.
   * @param commonKeyStore ключ для LS
   */
  public saveScrollPosition(commonKeyStore: string) {
    const SCROLL_Y = this.document.documentElement.scrollTop.toString();
    // запоминаем в LS Y - ординату скроллирования
    this.ls.setItem(`${this.PREFIX_SCROLL}${commonKeyStore}`, SCROLL_Y);
    // проставляем true так как совершен переход на форму просмотра
    this.ls.setItem(`${this.PREFIX_NAVIGATE}${commonKeyStore}`, "true");
  }

  /**
   * Метод скроллирования на указанную позицию
   * @param commonKeyStore ключ для LS
   */
  public scrollToPosition(commonKeyStore: string) {
    /* проставляем в LS false так как вернулись
       или находимся не на форме просмотра данных */
    this.ls.setItem(`${this.PREFIX_NAVIGATE}${commonKeyStore}`, "false");
    /* извлекаем значение из LS Y - ординаты и
       устанавливаем переход в данную позицию окна. */
    const key = this.ls.getItem(`${this.PREFIX_SCROLL}${commonKeyStore}`);
    const SCROLL_Y = parseInt(key, 10);
    this.document.documentElement.scrollTop = SCROLL_Y;
  }

  /**
   * метод установки скролла в позицию 0
   * @param commonKeyStore ключ для считывания данных из LS
   */
  public resetScrollPosition(commonKeyStore: string) {
    this.ls.setItem(`${this.PREFIX_SCROLL}${commonKeyStore}`, "0");
  }

  /**
   * Метод проверяющий был ли переход на форму
   * просмотра данных
   * @param commonKeyStore ключ для считывания данных из LS
   */
  public hasNavigateToForm(commonKeyStore: string): boolean {
    return JSON.parse(this.ls.getItem(`${this.PREFIX_NAVIGATE}${commonKeyStore}`));
  }

  public sharedMenu() {
    const _SELF = this;
    return {
      emit(value) {
        _SELF.sharedMenuSrv.emit(value);
      },
      getSubscribe(): Observable<any> {
        return _SELF.sharedMenuSrv.getSubscribe();
      }
    };
  }
  
  public emitAlertMessage(alert: { type: 'warn' | 'error' | 'success', message: string }) {
    this.alertSubject$.next(alert)
  }
}
