import {Component, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {DossierService} from "../../../../../services";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DictionaryService, ValidatorService} from "@amlCore/services";
import {Unsubscribable} from "rxjs";
import {AlertPanelService, ICustomSelectMapper} from "@amlCore/components";
import {DossierBaseModel} from "../../dossierBaseModel";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {LicenseInfoDossierComponent} from "../licenseInfo";
import {ErrorFLKItemType} from "@amlCore/models";
import {DossierAccessEnum} from "@amlCore/enums";

@Component({
  selector: 'app-sved-ul-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './svedULDossier.component.html'
})
export class SvedULDossierComponent extends DossierBaseSubComponent implements OnInit, OnDestroy {

  @Input() rezidentRf: FormControl;
  rezidentRfValueChanges: Unsubscribable;
  isInnReq = false;
  isKppReq = false;
  isOkvedShow = false;
  isLicenseInfoShow = false;
  okfsMapper: ICustomSelectMapper;
  okopfMapper: ICustomSelectMapper;
  @ViewChild('licenseInfo') licenseInfo: LicenseInfoDossierComponent;

  constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
              private validationSrv: ValidatorService, protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  /**
   * Описание формы
   */
  getFormDescription(): FormGroup {
    return this.dossierBaseModel.getSvedULType(this.typePage);
  }

  ngOnInit(): void {
    this.initTypePage();
    this.loadRemoteData(['okopf']);
    setTimeout(() => {
      this.initRezidentRf(this.rezidentRf.value);
      this.rezidentRfValueChanges = this.rezidentRf.valueChanges.subscribe(value => {
        this.initRezidentRf(value);
      });
      this.addUnsubscribable(this.rezidentRfValueChanges);
    });
    this.okfsMapper = {
      toModel: this.okfsMapperToModel.bind(this)
    } as ICustomSelectMapper;
    this.okopfMapper = {
      toModel: this.okopfMapperToModel.bind(this)
    } as ICustomSelectMapper;
  }

  /**
   * Инициализация typePage - скрываем некоторые части формы
   */
  initTypePage(): void {
    this.loadRemoteData(['okfs']);
    if ([DossierAccessEnum.SVEDCLIENT,
      DossierAccessEnum.SVEDAU,
      DossierAccessEnum.SVEDIOU,
      DossierAccessEnum.SVEDPREDST,
      DossierAccessEnum.SVEDVPR,
      DossierAccessEnum.PARTNER].includes(this.typePage)) {
      // Отображаем поля доступные на вкладках;
      this.isOkvedShow = true;
    }
    if ([DossierAccessEnum.SVEDCLIENT,
      DossierAccessEnum.SVEDPREDST,
      DossierAccessEnum.SVEDVPR,
      DossierAccessEnum.PARTNER].includes(this.typePage)) {
      this.isLicenseInfoShow = true;
    }
  }

  initRezidentRf(value): void {
    const validatorInn = this.validationSrv.getValidation({
      max: 10,
      min: 5,
      isINN: true
    });
    const validatorKpp = this.validationSrv.getValidation({
      max: 9
    });
    this.isInnReq = false;
    this.isKppReq = false;
    if (value) {
      // Поле "ИНН" и "КПП" обязательное если rezidentRf = true
      validatorInn.push(Validators.required);
      this.isInnReq = true;
      validatorKpp.push(Validators.required);
      this.isKppReq = true;
    }
    const inn = this.f('inn') as FormControl;
    const kpp = this.f('kpp') as FormControl;
    inn.setValidators(validatorInn);
    kpp.setValidators(validatorInn);
    inn.updateValueAndValidity({onlySelf: false});
    kpp.updateValueAndValidity({onlySelf: false});
  }

  okfsMapperToModel(origin: any, isDictData: boolean): any {
    if (isDictData) {
      return {
        code: origin.code,
        name: origin.name
      };
    } else {
      return origin;
    }
  }

  okopfMapperToModel(origin: any, isDictData: boolean): any {
    if (isDictData) {
      return {
        code: origin.code,
        name: origin.name
      };
    } else {
      return origin;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribable();
  }

  openLicenseInfo(event: ErrorFLKItemType): void {
    if (event) {
      this.licenseInfo.openWindow(this.dossierService.errorFLKTable);
    }
  }

}
