import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-alert',
  template: `
    <div class="modal-header">
      <h4 class="modal-title" id="modal-title">{{title}}</h4>
    </div>
    <div class="modal-body" [innerHtml]="text">
    </div>
    <div class="modal-footer">
      <button type="button" ngbAutofocus class="btn btn-success"
              (click)="modal.close(true)">{{okBtn}}
      </button>
    </div>
  `
})
export class AlertModalComponent {
  @Input() text;
  @Input() title = "Сообщение";
  @Input() okBtn = "Ок";
  constructor(public modal: NgbActiveModal) {
  }
}
