import {Component, Input} from "@angular/core";
import {DossierService} from "../../../../../services";
import {DictionaryService} from "@amlCore/services";
import {DossierBaseModel} from "../../dossierBaseModel";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {AlertPanelService} from "@amlCore/components";

@Component({
  selector: 'app-fio-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './fioDossier.component.html'
})
export class FioDossierComponent extends DossierBaseSubComponent {

  @Input() titlePanel = "";

  constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

}
