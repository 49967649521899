/**
 * API по базовым справочникам АМЛ.
 * API по базовым справочникам АМЛ.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Данные записи справочника КГРКО.
 */
export interface QrefBanc { 
    /**
     * Регистрационный номер. Ключевое поле таблицы.
     */
    regn?: string;
    /**
     * Организационно-правовая форма.
     */
    status?: string;
    /**
     * Тип.
     */
    tip?: string;
    /**
     * Полное название.
     */
    namemax?: string;
    /**
     * Полное наименование.
     */
    namemax1?: string;
    /**
     * Краткое название.
     */
    namer?: string;
    /**
     * Поисковое название.
     */
    name?: string;
    /**
     * Дата реорганизации в форме преобразования.
     */
    datePrb?: string;
    /**
     * Код типа реорганизации в форме преобразования.
     */
    typePrb?: string;
    /**
     * Размер уставного капитала (тыс.руб.).
     */
    ustF?: number;
    /**
     * Размер первоначального уставного капитала (тыс.руб.).
     */
    ufOld?: number;
    /**
     * Взнос иностранных инвесторов в уставный капитал.
     */
    ustFi?: number;
    /**
     * Количество филиалов.
     */
    kolf?: number;
    /**
     * Количество филиалов, расположенных на других территориях РФ.
     */
    kolfd?: number;
    /**
     * Дата регистрации.
     */
    dataReg?: string;
    /**
     * Признак небанковских КО или государственных банков.
     */
    priz?: string;
    /**
     * Дата изменения наименования.
     */
    dateNam?: string;
    /**
     * Дата изменения места нахождения.
     */
    dateAdr?: string;
    /**
     * Дата изменения уставного капитала.
     */
    dataIzmud?: string;
    /**
     * Дата лицензии (драг. металлы).
     */
    licGold?: string;
    /**
     * Дата лицензии 1 (драг. металлы).
     */
    licGold1?: string;
    /**
     * Дата лицензии.
     */
    licRub?: string;
    /**
     * Признак ограничения круга банковских операций(рубли).
     */
    ogran?: string;
    /**
     * Признак ограничения круга банковских операций(валюта).
     */
    ogran1?: string;
    /**
     * Дата лицензии (валюта).
     */
    licVal?: string;
    /**
     * Признак разрешенных видов операций в иностранной валюте.
     */
    vv?: string;
    /**
     * Признак разрешенных видов операций в иностранной валюте.
     */
    rei?: string;
    /**
     * Признак разрешенных видов операций в иностранной валюте.
     */
    no?: string;
    /**
     * Признак разрешенных видов операций в иностранной валюте.
     */
    opp?: string;
    /**
     * Признак разрешенных видов операций в иностранной валюте.
     */
    ko?: string;
    /**
     * Признак установления коротношений с инобанками.
     */
    kp?: string;
    /**
     * Адрес.
     */
    adres?: string;
    /**
     * Адрес 1.
     */
    adres1?: string;
    /**
     * Телефон.
     */
    telefon?: string;
    /**
     * Факс.
     */
    fax?: string;
    /**
     * ФИО Председателя правления.
     */
    fioPrPr?: string;
    /**
     * ФИО главного бухгалтера.
     */
    fioGlB?: string;
    /**
     * Дата отзыва лицензии.
     */
    dataOtz?: string;
    /**
     * Дата приказа о приостановлении действия приказа об отзыве лицензии.
     */
    dataPri?: string;
    /**
     * Дата лицензии (физ. лица).
     */
    dateFiz?: string;
    /**
     * Номер приказа об отзыве лицензии.
     */
    pricOtz?: string;
    /**
     * Код субъекта РФ по Окато.
     */
    numsrf?: string;
    /**
     * Основной государственный регистрационный номер.
     */
    egr?: string;
}

