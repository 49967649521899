import { Injectable } from '@angular/core';
import {Auth} from "@amlCore/models";

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {
  private intervalId = null
  constructor() { }

  /**
   * Установка данных о сессии
   */
  setUserSession(data: Auth) {
    window.sessionStorage.setItem('sessionId', data.sessionId)
    window.sessionStorage.setItem('sessionTimeOut', data.sessionTimeOut)
  }

  /**
   * Установка таймера с активностью пользователя
   */
  setUserTimer() {
     this.intervalId = setInterval(() => {
      const timer = this.getUserIdle()
       if (timer) {
         window.sessionStorage.setItem('sessionIdle', `${Math.floor((Date.now() - Number(this.getUserIdleTimer())) / 1000)}`)
       } else {
         this.startUserIdleTimer(Date.now())
         window.sessionStorage.setItem('sessionIdle', '1')
       }
    }, 1000);
  }

  /**
   * Получение данных о сессии
   */
  getUserSession() {
    return {
      sessionId: window.sessionStorage.getItem('sessionId'),
      sessionTimeOut: window.sessionStorage.getItem('sessionTimeOut')
    }
  }

  /**
   * Получение данных о простое пользователя
   */
  getUserIdle() {
    return window.sessionStorage.getItem('sessionIdle')
  }
  

  /**
   * Обнуление тамймера с бездействием
   */
  destroyUserIdle() {
    clearInterval(this.intervalId)
    window.sessionStorage.removeItem('sessionIdle')
  }

  /**
   * Проверка превысил ли пользователь допустимое бездействие на сайте
   */
  checkIsUserIdle(): boolean {
    const userIdle = this.getUserIdle()
    const sessionTimeOut = this.getUserSession().sessionTimeOut
    return Number(userIdle) >= Number(sessionTimeOut);
  }

  /**
   * Установка нового таймера для пользователя
   */
  resetUserTimer() {
    this.destroyUserIdle()
    this.setUserTimer()
  }

  /**
   * Установка даты срока годности токена
   */
  setExpiresToken(expiresAt : string | null) : void {
    expiresAt && window.sessionStorage.setItem('expiresAt', expiresAt)
  }
  
  isNeedUpdateToken(): boolean {
    const timeStampExpires = +new Date(window.sessionStorage.getItem('expiresAt'))
    const timeStampNow = +new Date()
    const diffTimeMinutes = Math.floor((timeStampExpires - timeStampNow) / 1000 / 60)
    return diffTimeMinutes <= 5 && !!timeStampExpires
  }

  /**
   * Установка количества попыток переподключения для WS
   */
  setCountOfRestore(wsCountOfRestore: string) : void {
   window.sessionStorage.setItem('wsCountOfRestore', wsCountOfRestore)
  }

  /**
   * Получение количества попыток переподключения для WS
   */
  getCountOfRestore() : string {
    return window.sessionStorage.getItem('wsCountOfRestore')
  }

  /**
   * Обновление количества попыток на подключение после успешного соединения
   */
  updateCountOfRestore() : void {
    this.setCountOfRestore('5')
  }

  /**
   * Уменьшение количества попыток при потере соединения
   */
  decreaseCountOfRestore() : void {
    const decreasedCount = Number(this.getCountOfRestore()) - 1
    this.setCountOfRestore(String(decreasedCount))
  }

  /**
   * Установка точного времени простоя пользователя
   */
  startUserIdleTimer(startedTime: number): void {
    window.sessionStorage.setItem('startIdleTime', String(startedTime))
  }

  /**
   * Получение точного времени простоя пользователя
   */
  getUserIdleTimer(): string {
    return window.sessionStorage.getItem('startIdleTime')
  }
}
