import moment from "moment";
import {BaseFilterModel} from "./baseFilter.model";

export class ClientsTransactionsFilter  extends BaseFilterModel {
  state = null;             // статус операции список
  createdFrom = null;       // дата и время создания С
  createdTo = null;         // дата и время создания По
  user = null;              // Пользователь
  vid = null;               // Роль
  operId = '';              // Уникальный номер операции
  dateFrom = '';            // Дата совершения операции С
  dateTo = '';              // Дата совершения операции По
  currency = '';            // Код валюты, справочник валют
  amountCurrFrom = '';      // Сумма операции в валюте С
  amountCurrTo = '';        // Сумма операции в валюте По
  amountRubFrom = '';       // Сумма операции в рублях С
  amountRubTo = '';         // Сумма операции в рублях По
  purpose = '';             // Назначение платежа
  clientType = null;        // Тип клиента
  name = '';                // Наименование/ФИО
  inn = '';                 // ИНН (КИО)
  account = '';             // Номер счета
  typeDoc = null;           // Тип документа
  numberDoc = '';           // Номер документа
  dateDocFrom = '';         // Дата документа С
  dateDocTo = '';           // Дата документа По
  type = '';                // Вид операции (Код)
  currCashType = '';        // Вид операции с наличной валютой
  memberStatus = null;      // Статус участника
  statusPay = null;         // Статус плательщика
  basisPay = '';            // Основание платежа
  taxPeriod = '';           // Налоговый период
  issuerCountryKod = null;  // Код страны регистрации банка-эмитента платежной карты
  countryKod = null;        // Код страны участника
  bankCountryKod = null;    // Код страны банка участника
}

