import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SvedPredstInfoType} from "../../../../models/dossier";
import {DossierService} from "../../../../services";
import {IDossierSubService} from "../IDossierSub.service";
import {DossierBasePageComponent} from "../dossierBasePage.component";
import {SvedPredstDossierService} from "./svedPredstDossier.service";
import {
  AlertPanelService,
  ITable,
  ITableCallBack,
  ITableItemDescriptor,
  TableCompComponent
} from "@amlCore/components";
import {PredstInfoDossierComponent} from "../components/predstInfo";
import {DictionaryService, UserService} from "@amlCore/services";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FileSaverService} from "ngx-filesaver";


@Component({
  selector: 'app-sved-predst-dossier',
  styleUrls: ['../dossier.css'],
  templateUrl: './svedPredstDossier.component.html'
})
export class SvedPredstDossierComponent extends DossierBasePageComponent implements OnInit, ITable {

  modelPage: Array<SvedPredstInfoType>;
  itemDescriptor: ITableItemDescriptor;
  isTableTab = true;
  @ViewChild('tableComp', {static: true}) tableComp: TableCompComponent;

  constructor(protected dossierService: DossierService,
              protected route: ActivatedRoute,
              private svedPredstDossierService: SvedPredstDossierService,
              protected dictSrv: DictionaryService,
              protected router: Router,
              protected userService: UserService,
              protected alertPanelSrv: AlertPanelService,
              protected modalService: NgbModal,
              protected fSaver: FileSaverService) {
    super(dossierService, route, dictSrv, router, userService, alertPanelSrv, modalService, fSaver);
  }

  ngOnInit(): void {
    // Инициализация вкладки
    this.modelPage = this.initPage();
    // Описание элемента таблицы
    this.itemDescriptor = {
      create: this.svedPredstDossierService.getSvedPredst.bind(this.svedPredstDossierService),
      component: PredstInfoDossierComponent,
      params: {
        typePage: this.typePage
      }
    } as ITableItemDescriptor;
  }

  getService(): IDossierSubService {
    return this.svedPredstDossierService;
  }

  callbackItemClosed(event: ITableCallBack) {
    this.saveTableItem(event);
  }

  destroy(): void {
    // ТУТ писать реализацию ngOnDestroy для компонента

  }

}
