import {Component, OnInit, OnDestroy} from "@angular/core";
import {BaseFilterController} from "../BaseFilterController";
import {FormBuilder} from "@angular/forms";
import {AuditFilter} from "../../models/auditFilter.model";
import {ResourceService, TableService} from "@amlCore/services";
import {IDictionaryItem} from '../../interfaces';
import moment from "moment";
import {Unsubscribable} from 'rxjs';
import {ICustomSelectMapper} from "@amlCore/components";

@Component({
  selector: "app-audit-filter",
  templateUrl: "./auditFilter.component.html",
  styleUrls: ["./auditFilter.component.css"]
})

export class AuditFilterComponent extends BaseFilterController implements OnInit, OnDestroy {
  public filterObjectModel = new AuditFilter();
  public hasCompareDateError = false; // флаг - есть ли ошибка сравнения дат
  public isVisibleFormatErrorIP = false; // флаг отображения ошибки по не корректному IP
  public errorMessageCompareDate: string; // Ошибки валидации сравнения дат
  private dateTimeFromSubscrible: Unsubscribable; // объект отписки от контрола даты и времени С
  private dateTimeToSubscrible: Unsubscribable; // объект отписки от контрола даты и времени ПО
  private userIPSubscrible: Unsubscribable; // отписка от поля IP-АДРЕС
  placeholder = '';
  methodMapper: ICustomSelectMapper;
  public selectObj = {
    userList: [] as IDictionaryItem<{ id: string, personName: string }>[],
    successList: [{ id: true, name:'Успешно' }, { id: false, name: 'Не успешно'}]
  };

  constructor(private fb: FormBuilder,
              protected tableSrv: TableService,
              private resourceSrv: ResourceService) {
    super(tableSrv);
  }

  ngOnInit() {
    this.init();
    this.loadUserList();

    this.dateTimeFromSubscrible = this.formModel.get("eventDateFrom").valueChanges.subscribe((dateTimeFrom: string) => {
      this.compareDate();
    });
    this.dateTimeToSubscrible = this.formModel.get("eventDateTo").valueChanges.subscribe((dateTimeTo: string) => {
      this.compareDate();
    });

    this.userIPSubscrible = this.formModel.get('userIp').valueChanges.subscribe((value) => {
      if (value !== '') {
        const IP = value.split('.');
        this.isVisibleFormatErrorIP = (4 === IP.length && IP[3] === '') || (IP.length < 4);
      }
      if (value === '') { this.isVisibleFormatErrorIP = false; };
    });
    this.disabledSimpleFilter = true;
    this.methodMapper = {
      toModel: origin => origin
    } as ICustomSelectMapper;
  }


  public createFormModel() {
    return this.fb.group(this.filterObjectModel);
  }

  /**
   * Метод сравнивает две даты С и ПО
   * если дата С позже даты ПО
   * то проставляет флаг hasErrorCompareDate = true
   * и на форме выводится ошибка.
   */
  public compareDate() {
    const dateTimeFromValue = this.formModelService.getValue("eventDateFrom");
    const dateTimeToValue = this.formModelService.getValue("eventDateTo");
    if (dateTimeToValue && dateTimeFromValue) {
      if (moment(dateTimeFromValue, "DD.MM.YYYY hh:mm:ss").isAfter(moment(dateTimeToValue, "DD.MM.YYYY hh:mm:ss"))) {
        this.hasCompareDateError = true;
        this.errorMessageCompareDate = "Дата начала события, не может быть позже Даты завершения события";
      } else {
        this.hasCompareDateError = false;
        this.errorMessageCompareDate = "";
      }
    }
  }

  /**
   * Загрузка списка событий
   * @returns void
   */
  loadEventList() {
    return (params: any) => this.resourceSrv.getEventList(params);
  }

  /**
   * Загрузка списка пользователей
   * @returns void
   */
  private loadUserList() {
    const customParams = {sort: "fullName", dir: "asc", page: "0", size: "200"};
    this.resourceSrv.getUserList(customParams).subscribe(({content}) => {
      this.selectObj.userList = (content || []);
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.dateTimeFromSubscrible.unsubscribe();
    this.dateTimeToSubscrible.unsubscribe();
    this.userIPSubscrible.unsubscribe();
  }

  getStartFilterModel(): any {
    return {
      eventDateFrom: null, // Дата и время события С
      eventDateTo:  null // Дата и время события По
    } as AuditFilter;
  }

  /**
   * Описания полей
   */
  public getFilterDescription(): any {
    return {
      eventDateFrom: 'Дата и время события С',
      eventDateTo:  'Дата и время события По',
      userIp: 'IP-aдрес',
      userId: 'Пользователь',
      eventName: 'Событие',
      success: 'Результат',
      info: 'Доп.информация/текст ошибки'
    };
  }

}
