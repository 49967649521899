import { Subject } from './subject.model';
import {BaseFilterModel} from "./baseFilter.model";

export class NoticeFilter  extends BaseFilterModel {
  result = false; // флаг все / требующие-решения
  userId = null; // Поиск по исполнителю (поле user_id)
  eventDateFrom = ''; // дата с
  eventDateTo = '';  // дата по
  alert = ''; // событие
  subject = new Subject();
}
