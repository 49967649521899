import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Utils} from "@amlCore/utils";

@Component({
  selector: 'app-go-back',
  templateUrl: './go-back.component.html',
  styleUrls: ['./go-back.component.scss']
})
export class GoBackComponent implements OnInit {
  @Output() backAction = new EventEmitter<boolean>();
  btnView = true;
  @Input() btnText = "Выйти";
  @Input() confirmText: string;
  @Input() isActivate: any;
  @Input() isDisable: boolean = false;

  constructor() { }

  isDefinedBackAction(): boolean{
    return this.backAction?.observers?.length > 0;
  }

  ngOnInit() {
    if (!this.isDefinedBackAction() && history.length === 1) { this.btnView = false; }
  }

  goBack(event){
    if (this.isDefinedBackAction())
      this.backAction.emit(Boolean(!event?.data?.status));
    else Utils.goBack();
  }
}
