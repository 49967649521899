import {Component, OnInit} from "@angular/core";
import {DossierService} from "../../../../../services";
import {DictType, RiskAssessType} from "../../../../../models/dossier";
import {AlertPanelService, ITableEditorItem, ITableItem} from "@amlCore/components";
import {DictionaryService, UserService} from "@amlCore/services";
import {DossierBaseModel} from "../../dossierBaseModel";
import {FormGroup} from "@angular/forms";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";

@Component({
  selector: 'app-risk-assess-info-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './riskAssessInfoDossier.component.html'
})
export class RiskAssessInfoDossierComponent extends DossierBaseSubComponent implements OnInit, ITableItem {

  titlePanel = 'Оценка уровня риска';
  showTitle = true;
  isTableItem = false;
  riskAssess: Array<DictType> = [];
  riskLevelList: Array<DictType> = [];
  riskGroupList: Array<DictType> = [];
  constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService,
              private userService: UserService,) {
    super(dossierService, dictSrv, alertPanelService);
  }
  /**
   * Описание формы
   */
  getFormDescription(): FormGroup {
    return this.dossierBaseModel.getRiskAssess();
  }

  /**
   * Инициализация FormGroup
   */
  initForm(): void {
    if (!this.isTableItem) {
      this.formGroupApp = this.getFormDescription();
    }
  }

  ngOnInit() {
    this.focusObject = this.generateFocusObject(new RiskAssessType());
     // Загрузка справочников
    this.dictSrv.getRemoteData('risklevel').then((result) => {
      this.riskLevelList = result.sort((a, b) => a.code - b.code);
    });
    // подставляем данные текущего пользователя
    this.userService.currentUser.subscribe((userData) => {
      this.formGroupApp.patchValue({userId: userData.id, fio: userData.fio});
    });
  }

  setSubmitted(value: boolean): void {
    this.submitted = value;
  }


  getTitle(): string {
    return this.titlePanel;
  }

  /**
   * Инициализируем компонент как элемент формы просмотра таблицы
   * @param param.form - форма для связи
   * @param param.params - дополнительные параметры
   */
  initTableItem(param: ITableEditorItem): FormGroup {
    this.initForm();
    this.isTableItem = true;
    if (param.model) {
      this.formGroupApp.patchValue(param.model);
    }
    this.showTitle = false;
    if (param.params && param.params.typePage) {
      this.typePage = param.params.typePage;
    } else {
      console.log('Необходимо указать typePage у компонента');
    }
    this.isReadOnly = param.isReadOnly;
    if (this.isReadOnly){
      this.formGroupApp.disable();
    }
    return this.formGroupApp;
  }
}
