import { DossierDate, DossierINNType, DossierPOLICYOMCType, DossierSNILSType, DossierT11, DossierT2000 } from "./base.model";
import {DictType} from "./dictType.model";

export class PersonalDataType {
  inn?: DossierINNType = '';
  snils?: DossierSNILSType = '';
  policyOMC?: DossierPOLICYOMCType = '';
  oktmo?: DossierT11 = '';
  country: DictType = new DictType();
  birthDate: DossierDate = '';
  birthPlace: DossierT2000 = '';
}
