export class QrefTuCbModel {
  id: number;
  tuCode: number;
  okato: number;
  tuName: string;
  bankomId: string;
  foCode: string;

  constructor() {
  }
}

