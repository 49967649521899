import {Component, OnDestroy, OnInit} from "@angular/core";
import {DossierService} from "../../../../../services";
import {AlertPanelService, ITableEditorItem, ITableItem} from "@amlCore/components";
import {DictType, DocumentDossier} from "../../../../../models/dossier";
import {Unsubscribable} from "rxjs";
import {DictionaryService} from "@amlCore/services";
import {Utils} from "@amlCore/utils";
import {FileSaverService} from "ngx-filesaver";
import {FormGroup} from "@angular/forms";
import {DossierBaseModel} from "../../dossierBaseModel";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";

@Component({
  selector: 'app-dok-reestr-info-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './dokReestrInfoDossier.component.html'
})
export class DokReestrInfoDossierComponent extends DossierBaseSubComponent implements OnInit, OnDestroy, ITableItem {

  titlePanel = 'Документ';
  showTitle = true;
  copyDocClientList: Array<DictType> = [];
  typeDocValueChanges: Unsubscribable;
  dossierId;
  isTableItem = false;


  constructor(protected dossierService: DossierService, private fSaver: FileSaverService, protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }
  /**
   * Описание формы
   */
  getFormDescription(): FormGroup {
    return this.dossierBaseModel.getDocumentDossier();
  }

  /**
   * Инициализация FormGroup
   */
  initForm() {
    if (!this.isTableItem) {
      this.formGroupApp = this.getFormDescription();
    }
  }

  ngOnInit() {
    this.copyDocClientList = this.dictSrv.getStaticData('copyDocClient');
    this.initTypeDoc(this.f('typeDoc').value);
    this.typeDocValueChanges = this.f('typeDoc').valueChanges.subscribe(val => {
      this.initTypeDoc(val);
    });
    this.addUnsubscribable(this.typeDocValueChanges);
  }

  initTypeDoc(val) {
    if (!this.formGroupApp.disabled) {
      this.f('typeInDoc').disable();
      if (val) {
        const type = val.code;
        if (type) {
          if (type === "25") {
            this.f('typeInDoc').enable();
          }
        }
      }
    }
  }

  loadFile(file) {
    const values = {
      nameDoc: file.nameDoc,
      idDoc: file.id,
      registryId: this.dossierId
    };
    this.formGroupApp.patchValue(values);
    this.formGroupApp.markAsDirty();
  }

  setSubmitted(value: boolean) {
    this.submitted = value;
  }


  getTitle(): string {
    return this.titlePanel;
  }

  /**
   * Инициализируем компонент как элемент формы просмотра таблицы
   * @param param.form - форма для связи
   * @param param.params - дополнительные параметры
   */
  initTableItem(param: ITableEditorItem): FormGroup {
    this.initForm();
    this.isTableItem = true;
    if (param.model) {
      this.formGroupApp.patchValue(param.model);
    }
    this.showTitle = false;
    if (param.params) {
      this.dossierId = param.params.dossierId;
      if (param.params.typePage) {
        this.typePage = param.params.typePage;
      } else {
        console.log('Необходимо указать typePage у компонента');
      }
    }
    this.isReadOnly = param.isReadOnly;
    if (this.isReadOnly){
      this.formGroupApp.disable();
    }
    return this.formGroupApp;
  }

  /**
   * URL для скачивания вложения
   */
  downloadDocUrl(): String {
    const param = this.formGroupApp.value;
    return this.dossierService.downloadDocUrl(param as DocumentDossier);
  }

  ngOnDestroy() {
    this.unsubscribable();
  }
}
