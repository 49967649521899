import {Component, OnInit, ViewChild} from "@angular/core";
import {DossierService} from "../../../../../services";
import {AlertPanelService, ITable, ITableCallBack, ITableItemDescriptor, TableCompComponent} from "@amlCore/components";
import {DossierBaseModel} from "../../dossierBaseModel";
import {LicenseDossierComponent} from "../license";
import {DictionaryService} from "@amlCore/services";
import {FormBuilder} from "@angular/forms";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {ErrorFLKItemType, ErrorFLKModel} from "@amlCore/models";
import {LicenseType} from "../../../../../models/dossier";
import {DossierAccessEnum} from "@amlCore/enums";

@Component({
  selector: 'app-license-info-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './licenseInfoDossier.component.html',
  exportAs: 'licenseInfo'
})
export class LicenseInfoDossierComponent extends DossierBaseSubComponent implements OnInit, ITable {
  dossierAccessEnum = DossierAccessEnum;
  itemDescriptor: ITableItemDescriptor;

  @ViewChild('tableComp') tableComp: TableCompComponent;

  constructor(private dossierBaseModel: DossierBaseModel, protected dossierService: DossierService,
              protected dictSrv: DictionaryService, protected formBuilder: FormBuilder,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  ngOnInit() {
    // Описание элемента таблицы
    this.itemDescriptor = {
      create: this.dossierBaseModel.getLicenseType.bind(this.dossierBaseModel),
      component: LicenseDossierComponent,
      params: {
        typePage: this.typePage,
        getListError:  this.getListError.bind(this)
      }
    } as ITableItemDescriptor;
  }
  callbackItemClosed(event: ITableCallBack) {
    if (event.editIndex !== null && event.editIndex !== -1) {
      // При закрытие элемента думаем, что ошибки поправлены
      const row = (this.formGroupApp.value[event.editIndex] as LicenseType).row;
      this.setValidFlkError(row);
    }
  }
  openWindow(error: ErrorFLKModel){
    let index = 0;
    // Считываем row и ищем по нему index
    if (error && error.row !== null) {
      (this.formGroupApp.value as Array<LicenseType>).forEach((value, i) => {
        if (value.row === error.row) {
          index = i;
        }
      });
    }
    this.tableComp.showFlKError(index, error);
  }

  /**
   * Вернуть в элементы таблиц актуалььный список ошибок компонента
   */
  getListError(): ErrorFLKItemType{
    return this.errorFLKData;
  }
}
