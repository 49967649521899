import {
  Component,
  ComponentFactoryResolver,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import {ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {UserService, ValidatorService} from "@amlCore/services";
import {ITableItemDescriptor} from "./ITableItemDescriptor.interface";
import {ITableCallBack} from "./ITableCallBack.interface";
import {NgbModal, NgbModalConfig, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ITableEditorItem, TableEditorComponent} from "./tableEditor";
import {Utils} from "@amlCore/utils";
import {ErrorFLKModel} from "@amlCore/models";
import {DossierAccessEnum} from "@amlCore/enums";
import {AutoUnsub} from "../../decorators";

@Component({
  selector: 'app-table-comp',
  templateUrl: './tableComp.component.html',
  styles: [
    `.tr-hover:hover {
      cursor: pointer;
      background: #F6F6F6;
    }

    .text-center {
      text-align: center !important;
    }

    .row-bottom-border {
      border-bottom: 1px solid #DEE2E6;
    }`
  ],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TableCompComponent),
    multi: true,
  }]
})
@AutoUnsub()
export class TableCompComponent implements OnInit, ControlValueAccessor, OnDestroy {
  /**
   * Массив таблицы
   */
  formArrayApp: Array<any>;
  /**
   * По #target внутри блока компонента перечислить столбца таблицы для вывода
   * Элемент получаеть по let-item будет доступен в item
   * Шапку таблицы поместить внутри компонента в блоке tr ()
   */
  @ContentChild('target') templateVariable: TemplateRef<any>;
  @ContentChild('buttons') templateButton: TemplateRef<any>;

  @Output() callbackItemOpened: any = new EventEmitter<any[]>();
  @Output() callbackItemClosed: any = new EventEmitter<any[]>();
  @Output() callbackItemDelete: any = new EventEmitter<any[]>();
  @Output() callbackImport: any = new EventEmitter<any[]>();
  @Input() canEditItem: (index: number) => boolean; // Метод проверки доступа к редактирования элемента по индексу
  @Input() itemDescriptor: ITableItemDescriptor;
  @Input() acceptFiles = '.xml, .pdf';
  @Input() selectedIndex: number;
  @Input() isReadyEditing = true; // Признак что данные загружены (возможно редактирование)
  @Input() isReadOnly = false;
  @Input() modelWithRow = false; // Признак строки с авторасчитанным row
  @Input() isDocument = false; // Признак стилей, открытия записи в табл. по dblclick и т.д. для DocumentForm
  @Input() isEditBtn = false; // Признак наличия кнопки "редактировать"
  @Input() isCalculateRow = true; // Признак авторасчета порядкового номера row в таблице
  @Input() isImport = false; // Признак наличия кнопки импорт
  @Input() topModalEditor = false; // Окно редактирования над всеми другими окнами (в том числе над окном ошибок ФЛК)
  @Input() showAddButton = true; // Признак показа кнопки добавления
  @Input() permissionDisabledBtn: DossierAccessEnum;
  @Input() formGroupApp?: FormGroup | FormArray;
  isDisabledBtn = false;
  private isModelOpenIndex = -1; // Номер строки, открытой на редактирование
  private tableEditor: TableEditorComponent;
  private modalRef: NgbModalRef;
  page = 1;
  pageSize = 5;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly validationSrv: ValidatorService,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly modalService: NgbModal,
    private readonly usersService: UserService
  ) {
  }

  onChange = (result: any) => {
  }

  onTouch = () => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnInit(): void {
    if (!this.itemDescriptor) {
      console.log("Необходимо указать itemDescriptor");
    }
    // this.deleteItemSub = this.dossierService.deleteItemSub.subscribe((index: number) => this.deleteItem(index));
    if (this.permissionDisabledBtn) {
      this.isDisabledBtn = !this.usersService.checkAccess(this.permissionDisabledBtn);
    }

  }

  writeValue(outsideValue) {
    this.formArrayApp = [];
    if (outsideValue) {
      Array.isArray(outsideValue)
        ? outsideValue.forEach(item => this.formArrayApp.push(item))
        : this.formArrayApp.push(outsideValue);
      if (this.modelWithRow)
        this.formArrayApp.sort((item1, item2) => item1.row - item2.row);
    }
  }

  /**
   * Обновляем
   */
  updateValue(insideValue) {
    // Сообщаем Forms API
    this.onChange(insideValue);
  }

  /**
   * Конфигурирование окна редактирования элемента
   */
  initConfigModalWindow(): NgbModalConfig {
    const config = {
      backdrop: 'static',
      size: 'xl',
      scrollable: true
    } as NgbModalConfig;

    if (this.topModalEditor) {
      config.windowClass = "top-modal-editor";
      config.backdropClass = "top-modal-editor";
    }
    return config;
  }

  /**
   * Добавить новый элемент
   */
  addNewItem() {
    if (this.modelWithRow) {
      let findRow = 0;
      this.formArrayApp.forEach(item => {
        if (item.row > findRow) {
          findRow = item.row;
        }
      });
      if (this.formArrayApp.length > 0) {
        findRow++;
      }
      this.openModalWindow({ row: findRow }, null);
    } else {
      this.openModalWindow(null);
    }
  }

  /**
   * Открыть элемент на редактировани
   * @param index - индекс элемента в formArray.controls
   * @param event - если это таблица с чекбоксом, то используем двойной клик
   */
  editItem(index, event?: Event) {
    const currentIndex = index + (this.page * this.pageSize) - this.pageSize;
    if (this.isEditBtn && (event?.type === 'click' || event?.type === 'dblclick'))
      return;

    if (this.isDocument && event?.type === 'click')
      return;
    /**
     * Проверка доступа на редактирования элемента
     */
    if (this.canEditItem) {
      const canEdit = this.canEditItem(currentIndex);
      if (!canEdit) {
        return;
      }
    }
    const item = this.formArrayApp[currentIndex];
    this.openModalWindow(item, currentIndex);
    this.callbackItemOpened.emit({
      editIndex: currentIndex
    } as ITableCallBack);
  }

  /**
   * Отображение ошибок ФЛК
   * @param index - индекс в массиве
   * @param error - ошибка по ФЛК
   */
  showFlKError(index, error: ErrorFLKModel) {
    // Если форма просмотра не открыта - открываем
    if (this.isModelOpenIndex != index) {
      this.editItem(index);
    }
    setTimeout(() => this.tableEditor.showFlKError(error));
  }

  /**
   * Отображение ошибок ФЛК
   * @param errors - ошибки по ФЛК
   */
  initErrorFLK(errors: Array<ErrorFLKModel>): void {
    this.tableEditor.initErrorFLK(errors);
  }

  /**
   * Сохранение/ обновление элемента
   */
  save(item: ITableEditorItem, withRemoteSave?: boolean) {
    const form = item.form;
    const editIndex = item.editIndex;
    const isNullEditIndex = editIndex == null;

    // Возвращаем индекс отредатированного элемента
    // Если новый - индекс последнего элемента
    const close = () =>
      this.close({
        editIndex: isNullEditIndex
          ? this.formArrayApp.length - 1
          : editIndex,
        isNewItem: isNullEditIndex,
        form
      } as ITableCallBack);



    if (form)
      isNullEditIndex
        ? this.formArrayApp.push(form.value) // При создании - добавляем новый элемент
        : this.formArrayApp[editIndex] = form.value; // При редактировании - обновляем данные элемента

    if (withRemoteSave) return close();
    this.updateValue(this.formArrayApp);
    close();
  }

  /**
   * Удалить элемент
   * @param index - индекс элемента
   */
  deleteItem(index: number) {
    const data = Utils.clone(this.formArrayApp[index]);
    this.formArrayApp.splice(index, 1);
    // TODO в будущем избавимся от флага isCalculate.
    if (this.modelWithRow && this.isCalculateRow) {
      this.formArrayApp.forEach((item, indexRow) => {
        item.row = indexRow;
      });
    }
    this.updateValue(this.formArrayApp);
    this.callbackItemDelete.emit({
      editIndex: index,
      deleteData: data // todo можно отказаться от использования (для форм нужен только индекс)
    } as ITableCallBack);
    // Закрываем все мод. окна, если они открыты
    // this.modalService.dismissAll(); // todo
  }

  private formatDate(date) {
    const ngbStructDate = Utils.getDateAsNgbStructDate(date);
    return `${ngbStructDate.day}/${ngbStructDate.month}/${ngbStructDate.year}`;
  }

  private formatDateTime(date) {
    const hh = String(date.getHours()).padStart(2, '0');
    const mm = String(date.getMinutes()).padStart(2, '0');
    const ss = String(date.getSeconds()).padStart(2, '0');
    return `${hh}:${mm}:${ss}`;
  }

  /**
   * Установить данные элемента по индексу
   * @param index - индекс элемента
   * @param data - данные элемента
   */
  setItemByIndex(index: number, data: any) {
    this.formArrayApp[index] = data;
    this.updateValue(this.formArrayApp);
  }

  /**
   * Закрываем редактируемы элемент
   */
  close(callbackInfo: ITableCallBack) {
    // Сообщаем о закрытии редактирования элемента
    this.callbackItemClosed.emit(callbackInfo);
  }

  ngOnDestroy(): void {
  }

  /**
   * Открываем модальную форму для редактирования элемента
   * @param model - модель
   * @param index - индекс элемента в массиве таблицы, необходим для обновления элемента при редактировании
   */
  private openModalWindow(model: any | Array<any>, index?: number) {
    if (this.modalRef) {
      this.modalRef.close();
    }
    this.modalRef = this.modalService.open(TableEditorComponent, this.initConfigModalWindow());
    this.tableEditor = this.modalRef.componentInstance as TableEditorComponent;
    this.tableEditor.templateButton = this.templateButton;

    this.itemDescriptor.form?.at(index)?.markAsPristine();
    this.itemDescriptor.form?.at(index)?.markAsUntouched();
    
    // Передаем данные формы и компонента для создания
    this.tableEditor.open({
      component: this.itemDescriptor.component,
      editIndex: index,
      params: this.itemDescriptor.params,
      isReadOnly: this.isReadOnly,
      form: this.itemDescriptor.form?.at(index),
      tableComp: this,
      model
    } as ITableEditorItem);
    this.isModelOpenIndex = index;
    // Получаем ответ от формы
    this.modalRef.result.then(result => {
      this.isModelOpenIndex = -1;
      if (result && typeof result !== 'boolean') {
        this.save(result);
      } else {
        this.isDocument
          ? this.close({ isConfirmBack: result } as ITableCallBack)
          : this.close({ editIndex: null } as ITableCallBack);
      }
    }, () => {});
  }

  onImport() {
    this.callbackImport.emit();
  }
}
