/**
 * API по базовым справочникам АМЛ.
 * API по базовым справочникам АМЛ.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Данные записи справочника для проверки SWIFT.
 */
export interface QrefSwift { 
    /**
     * Двузначный буквенный код. Ключевое поле таблицы.
     */
    alfa2?: string;
    /**
     * Цифровой код страны. Ключевое поле таблицы.
     */
    alfa3?: string;
    /**
     * Цифровой код страны.
     */
    kokcm?: string;
    /**
     * Страна.
     */
    ncmShort?: string;
    /**
     * Полное наименование.
     */
    ncm?: string;
}

