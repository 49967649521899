import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DossierService} from '../../../../../services';
import {DictionaryService} from '@amlCore/services';
import {AlertPanelService, ITableEditorItem, ITableItem} from '@amlCore/components';
import {DictType, SvedBVType} from '../../../../../models/dossier';
import {AbstractControl, FormGroup} from '@angular/forms';
import {DossierBaseModel} from '../../dossierBaseModel';
import {DossierBaseSubComponent} from '../dossierBaseSub.component';
import {ClientTypeEnum} from '@amlCore/enums';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-bv-info-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './bvInfoDossier.component.html'
})
export class BvInfoDossierComponent extends DossierBaseSubComponent implements OnInit, OnDestroy, ITableItem {
  @Input() params: { clientType: () => ClientTypeEnum };
  private readonly _titlePanel = 'Сведения о бенефициарном владельце';
  private readonly _stateFounderValue = '1';
  clientType: ClientTypeEnum; // Тип клиента из SvedClient
  showTitle = true;
  showState = false; // Признак отображения поля Статус
  showPart = false; // Признак отображения поля Доля участия
  isTableItem = false;
  bfTypeList: DictType[] = [];
  destroy$ = new Subject();
  constructor(protected dossierService: DossierService,
              protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  /**
   * Описание формы
   */
  getFormDescription(): FormGroup {
    return this.dossierBaseModel.getSvedBv();
  }

  /**
   * Инициализация FormGroup
   */
  initForm(): void {
    if (!this.isTableItem) {
      this.formGroupApp = this.getFormDescription();
    }
  }

  ngOnInit(): void {
    this.focusObject = this.generateFocusObject(new SvedBVType());
    this.clientType = this.params.clientType();
    this.initBfType();
    this.f('bfOwner').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.f('bfOther')[value == 3 ? 'enable' : 'disable']();
    });
  }

  getTitle(): string {
    return this._titlePanel;
  }

  /**
   * Инициализируем компонент как элемент формы просмотра таблицы
   * @param param.form - форма для связи
   * @param param.params - дополнительные параметры
   */
  initTableItem(param: ITableEditorItem): FormGroup {
    this.initForm();
    this.isTableItem = true;
    if (param.model) {
      this.formGroupApp.patchValue(param.model);
    }
    this.showTitle = false;
    if (param.params && param.params.typePage) {
      this.typePage = param.params.typePage;
    } else {
      console.log('Необходимо указать typePage у компонента');
    }
    this.isReadOnly = param.isReadOnly;
    if (this.isReadOnly) {
      this.formGroupApp.disable();
    }
    return this.formGroupApp;
  }

  /**
   * Инициализация доступности признака и вида идентификации бенефициара
   */
   initBfType(): void {
    this.bfTypeList = this.dictSrv.getStaticData("bfTypeList");
    this.showBfType = true;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
