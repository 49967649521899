import {AccessJson} from "@amlCore/models";
import {CertificateUsers} from "@amlCore/interfaces";

export class Auth {
  fio: string;
  roles: Array<AccessJson>;
  id: string;
  tokenId: string;
  position: string;
  needChangePassword: boolean;
  login: string;
  cert: CertificateUsers;
  sessionId: string;
  sessionTimeOut: string;
  refreshToken: string | null;
  expiresAt: string | null;

  constructor() {
  }
}

