import { Component, OnInit } from '@angular/core';
import {getDossierAccessList, getRegistriesAccessList, RegistriesAccessEnum, DossierAccessEnum} from '@amlCore/enums';
import {faUserTag} from '@fortawesome/free-solid-svg-icons/faUserTag';
import {faBook} from '@fortawesome/free-solid-svg-icons/faBook';
import {faUser} from '@fortawesome/free-solid-svg-icons';
import {faUserCheck} from '@fortawesome/free-solid-svg-icons/faUserCheck';
import {faAddressCard} from '@fortawesome/free-solid-svg-icons/faAddressCard';
import {faEye} from '@fortawesome/free-solid-svg-icons/faEye';
import {faMailBulk} from '@fortawesome/free-solid-svg-icons/faMailBulk';
import {faClipboardList} from '@fortawesome/free-solid-svg-icons/faClipboardList';
import {faClipboardCheck} from '@fortawesome/free-solid-svg-icons/faClipboardCheck';
import {faCreditCard} from '@fortawesome/free-solid-svg-icons/faCreditCard';
import {faPaperclip} from '@fortawesome/free-solid-svg-icons/faPaperclip';
import {faThumbtack} from '@fortawesome/free-solid-svg-icons/faThumbtack';
import {NavCabService} from './nav.service';
import {SharedMenuService, UserService} from '@amlCore/services';
import {NoticeService} from '../notice';
import {DossierService} from '../../services';
import {GroupMenuTypes} from "../../models/types/Navigation";
import {getRiskAccessList, RiskAccessEnum} from "../../../core/enum/riskAccessEnum";
import {OtherAccessEnum} from "../../../core/enum/otherAccessEnum";

@Component({
  selector: 'app-cabinet-nav',
  templateUrl: './nav.component.html',
  styleUrls:['./nav.component.scss']
})
export class NavCabComponent implements OnInit {
  private readonly PINNED_NAV_CLASS = 'thumbtack-pinned white-thumbtack';
  private readonly UNPINNED_NAV_CLASS = 'thumbtack-unpinned white-thumbtack';
  private _login = '';
  pinnedNavClass = "";
  hasNoticeToBeResolve: boolean;
  hasCheckClientResolve: boolean;
  hasCheckTransactionResolve: boolean;
  registriesAccess = RegistriesAccessEnum;
  dossierAccess = DossierAccessEnum;
  riskAccess = RiskAccessEnum;
  otherAccess = OtherAccessEnum;
  registriesAccessIds = getRegistriesAccessList();
  dossierAccessIds = getDossierAccessList();
  riskAccessIds = getRiskAccessList();
  icons = {
    user: faUser,
    role: faUserTag,
    spkCheck: faUserCheck,
    userCheck: faUserCheck,
    book: faBook,
    addressCard: faAddressCard,
    eye: faEye,
    alerts: faMailBulk,
    transactionsList: faClipboardList,
    transactionsCheck: faClipboardCheck,
    paperClip: faPaperclip,
    thumbTack: faThumbtack,
    creditCard: faCreditCard
  };

  constructor(private _navCabService: NavCabService,
              private _noticeSrv: NoticeService,
              private _menuSrv: SharedMenuService,
              private _userSrv: UserService,
              private _dossierService: DossierService) {
  }

  ngOnInit(): void {
    const elem = document.getElementById('nav');
    setTimeout(() => {
      elem.scrollTop = +localStorage.getItem('nav-scroll');
    }, 0);
    elem.addEventListener('scroll', () => {
      localStorage.setItem('nav-scroll', String(elem.scrollTop));
    });
    this._login = this._userSrv.getAuthenticateUser.login;
    this._navCabService.setUniqIdent(this._login);
    this._navCabService.fillVisibleMenuFromLS();
    /* проверяем есть ли доступ к разделу оповещений
     * если есть то отправляем запрос на получения признака наличия
     * хотя бы одного оповещния требующего разрешения
     */
    if (this._userSrv.checkAccess(this.dossierAccess.NOTICES)) {
      this._noticeSrv.checkNoticesToBeResolve().subscribe((data) => {
        this.hasNoticeToBeResolve = data?.hasResolveNotice;
      });
    }

    if (this._userSrv.checkAccess(this.dossierAccess.RISKVIEW)) {
      this._dossierService.isNeedSolutionsCheck().subscribe((value: boolean) => this.hasCheckClientResolve = value);
    }

    if (this._userSrv.checkAccess(this.dossierAccess.TRANSACTIONCHECKLIST)) {
      this._dossierService.isNeedSolutionsTransaction().subscribe((value: boolean) => this.hasCheckTransactionResolve = value);
    }

    if (JSON.parse(localStorage.getItem(`isPinnedMenuNav-${this._login}`))) {
      this.pinnedNavClass = this.PINNED_NAV_CLASS;
      this._menuSrv.emit(true);
    } else {
      this.pinnedNavClass = this.UNPINNED_NAV_CLASS;
      localStorage.setItem(`isPinnedMenuNav-${this._login}`, "false");
      this._menuSrv.emit(false);
    }
  }

  onPinnedMenuNav(): void {
    const isPinned = !Boolean(JSON.parse(localStorage.getItem(`isPinnedMenuNav-${this._login}`)));
    if (isPinned) {
      this.pinnedNavClass = this.PINNED_NAV_CLASS;
      localStorage.setItem(`isPinnedMenuNav-${this._login}`, "true");
      this._menuSrv.emit(true);
    } else {
      this.pinnedNavClass = this.UNPINNED_NAV_CLASS;
      localStorage.setItem(`isPinnedMenuNav-${this._login}`, "false");
      this._menuSrv.emit(false);
    }
  }

  isNavMenuVisible(type: GroupMenuTypes): boolean {
    return this._navCabService.isVisible(type)
  }

  changeVisible(type: GroupMenuTypes): void {
    this._navCabService.changeVisible(type);
  }
}


