import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DossierService} from "../../../../services";
import {IDossierSubService} from "../IDossierSub.service";
import {DossierBasePageComponent} from "../dossierBasePage.component";
import {DokReestrDossierService} from "./dokReestrDossier.service";
import {AlertPanelService, ITable, ITableCallBack, ITableItemDescriptor} from "@amlCore/components";
import {DokReestrInfoDossierComponent} from "../components/dokReestrInfo";
import {DictionaryService, UserService} from "@amlCore/services";
import {DocumentDossier} from "../../../../models/dossier";
import {Utils} from "@amlCore/utils";
import {FileSaverService} from "ngx-filesaver";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: 'app-dok-reestr-dossier',
  styleUrls: ['../dossier.css'],
  templateUrl: './dokReestrDossier.component.html'
})
export class DokReestrDossierComponent extends DossierBasePageComponent implements OnInit, ITable {

  modelPage: Array<DocumentDossier>;
  itemDescriptor: ITableItemDescriptor;
  isTableTab = true;

  constructor(protected dossierService: DossierService,
              protected route: ActivatedRoute,
              protected fSaver: FileSaverService,
              private dokReestrDossierService: DokReestrDossierService,
              protected dictSrv: DictionaryService,
              protected router: Router,
              protected userService: UserService,
              protected alertPanelSrv: AlertPanelService,
              protected modalService: NgbModal) {
    super(dossierService, route, dictSrv, router, userService, alertPanelSrv, modalService, fSaver);
  }

  ngOnInit() {
    // Инициализация вкладки
    this.modelPage = this.initPage();
    // Описание элемента таблицы
    this.itemDescriptor = {
      create: this.dokReestrDossierService.getDocumentDossier.bind(this.dokReestrDossierService),
      component: DokReestrInfoDossierComponent,
      params: {
        typePage: this.typePage,
        dossierId: this.dossierId
      }
    } as ITableItemDescriptor;
  }

  /**
   * Инициализация модели
   * Преобразуем в нужный формат
   * @param model - данные сервера
   */
  initModel(model) {
    let newModel;
    this.partId = null;
    if (model) {
      const partIdList: string[] = Object.keys(model);
      newModel = model[partIdList[0]];
      Utils.deleteEmptyObj(newModel);
    } else {
      newModel = [];
    }
    const formData = {};
    formData[this.typePage] = newModel;
    return formData;
  }


  /**
   * URL для скачивания вложения
   */
  downloadDocUrl(item: DocumentDossier): String {
    return this.dossierService.downloadDocUrl(item as DocumentDossier);
  }

  getService(): IDossierSubService {
    return this.dokReestrDossierService;
  }

  callbackItemClosed(event: ITableCallBack) {
    if (event.editIndex != null) {
      this.saveDossierPage();
    }
  }

  deleteItem(event: ITableCallBack) {
    this.saveDossierPage();
  }

  destroy() {
    // ТУТ писать реализацию ngOnDestroy для компонента

  }

}
