import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AttributesType, DictType} from '../../../../models/dossier';
import {DossierService} from '../../../../services';
import {DossierBasePageComponent} from '../dossierBasePage.component';
import {IDossierSubService} from '../IDossierSub.service';
import {AttributesDossierService} from './attributesDossier.service';
import {DictionaryService, UserService} from '@amlCore/services';
import {AlertPanelService} from '@amlCore/components';
import {Utils} from '@amlCore/utils';
import {ClientTypeEnum} from "@amlCore/enums";
import {DossierPageResolver} from "../dossierPageResolver";
import {Validators} from "@angular/forms";
import {UpdateCardDossierComponent} from "../components/updateCard";
import {ErrorFLKItemType} from "@amlCore/models";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FileSaverService} from "ngx-filesaver";

@Component({
  selector: 'app-attributes-dossier',
  styleUrls: ['../dossier.css'],
  templateUrl: './attributesDossier.component.html'
})
export class AttributesDossierComponent extends DossierBasePageComponent implements OnInit {

  modelPage: AttributesType;
  isTableTab = false;
  identificationTypeList: Array<DictType> = [];
  identificationLevelList: Array<DictType> = [];
  isRequiredField = true;
  @ViewChild('updateCard') updateCard: UpdateCardDossierComponent;

  constructor(protected dossierService: DossierService,
              protected route: ActivatedRoute,
              private attributesDossierService: AttributesDossierService,
              private _dossierPageResolver: DossierPageResolver,
              protected dictSrv: DictionaryService,
              protected router: Router,
              protected userService: UserService,
              protected alertPanelSrv: AlertPanelService,
              protected modalService: NgbModal,
              protected fSaver: FileSaverService) {
    super(dossierService, route, dictSrv, router, userService, alertPanelSrv, modalService, fSaver);
  }

  ngOnInit() {
    // Инициализация вкладки
    this.modelPage = this.initPage();
    // Загрузка справочников
    this.dictSrv.getRemoteData('typeidentification').then(data => {
      this.identificationTypeList = data;
    });
    this.identificationLevelList = this.dictSrv.getStaticData('identificationLevel');
    this.focusObject = {
      attributes: this.generateFocusObject(new AttributesType())
    };
    // TODO убрать потом setTimeout()
    setTimeout(() => {
      const dateEndSign = this.f('attributes.dateEndSign');
      if (![ClientTypeEnum.INDIVIDUAL_PERSON, ClientTypeEnum.PRIVATE_PRACTITIONER].includes(this.clientType)) {
        dateEndSign.setValidators([Validators.required])
      } else {
        this.isRequiredField = false;
        dateEndSign.setValidators([])
      }
      dateEndSign.updateValueAndValidity({onlySelf: true})
    });
  }

  /**
   * Инициализация модели
   * Преобразуем в нужный формат
   * @param model - данные сервера
   */
  initModel(model) {
    let newModel;
    this.partId = null;
    if (model) {
      const partIdList: string[] = Object.keys(model);
      newModel = model[partIdList[0]];
      Utils.deleteEmptyObj(newModel);
    } else {
      newModel = null;
    }
    const formData = {};
    formData[this.typePage] = newModel;
    return formData;
  }

  openUpdateCard(event: ErrorFLKItemType) {
    if (event) {
      this.updateCard.openWindow(this.dossierService.errorFLKTable);
    }
  }

  getService(): IDossierSubService {
    return this.attributesDossierService;
  }

  /**
   * Своя обработка фокусировки на ошибках для раздела
   * @param key - путь до поля с ошибкой
   */
  public isFocus(key: string): boolean {
    return this.focusObject.attributes ? this.focusObject.attributes[key] : false;
  }

  destroy() {
    // ТУТ писать реализацию ngOnDestroy для компонента

  }
}
