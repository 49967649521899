import { Component, OnInit, OnDestroy } from "@angular/core";
import { BaseFilterController } from "../BaseFilterController";
import { FormBuilder, Validators } from "@angular/forms";
import { MvkFilter } from "@amlCore/models";
import { IDictItemType } from "../../interfaces";
import { Unsubscribable } from "rxjs";
import {DictionaryService, TableService, ValidatorService} from "@amlCore/services";
import {ClientTypeEnum} from "@amlCore/enums";

@Component({
  selector: "app-mvk-list-filter",
  templateUrl: "./mvkListFilter.component.html",
})

export class MvkListFilterComponent extends BaseFilterController implements OnInit, OnDestroy {
  public selectObj = {
    typeSubjectList: [] as IDictItemType[]
  };
  public filterObjectModel = new MvkFilter();
  private subjectTypeUnsubscrible: Unsubscribable;
  placeholder = 'Поиск по ФИО, наименованию, ИНН';

  constructor(private fb: FormBuilder,
              protected tableSrv: TableService,
              private dictSrv: DictionaryService,
              protected validationSrv: ValidatorService) {
    super(tableSrv);
  }

  ngOnInit() {
    this.init();
    this.dictSrv.loadTypeClient().then(result => {
      this.selectObj.typeSubjectList = result.filter((item) => [ClientTypeEnum.LEGAL_ENTITY, ClientTypeEnum.INDIVIDUAL_PERSON].includes(item.code));
    });
    this.subjectTypeUnsubscrible = this.formModel.get("subjectType").valueChanges.subscribe((subjectType: string) => {
      this.selectedCleintType({ type: (subjectType || '') });
      
      this.formModel.get("inn").reset('');
      if (subjectType === ClientTypeEnum.LEGAL_ENTITY) {
        this.formModel.get("inn").setValidators(Validators.maxLength(10));
      } else {
        this.formModel.get("inn").setValidators(Validators.maxLength(12));
      }
    });
  }

  public selectedCleintType(data) {
    if (data.type === '') {
      return;
    }
    const currentSubjectTypeCode: string = data.type;
    const subjectTypeMap = {
      1: ["name", "inn", "ogrn", "idSubject"],
      2: ["name", "birthDate", "inn", "series", "number", 'sname', 'gname', 'pname', 'idSubject'],
    };
    const [disableSubjectTypeCode] = Object.keys(subjectTypeMap).filter((subjectType) => subjectType !== currentSubjectTypeCode);

    subjectTypeMap[disableSubjectTypeCode].forEach((field: string) => {
      this.formModel.get(field).disable();
    });
    subjectTypeMap[currentSubjectTypeCode].forEach((field: string) => {
      this.formModel.get(field).enable();
    });
  }

  /**
   * Метод создания формы
   * @return FormGroup
   */
  public createFormModel() {
    const formConfig =  new MvkFilter();
    formConfig.birthDate = [null, this.validationSrv.getValidation({
      isDate: true
    })];
    return this.fb.group(formConfig);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subjectTypeUnsubscrible.unsubscribe();
  }

  getStartFilterModel(): any {
    return {
      subjectType: ClientTypeEnum.LEGAL_ENTITY // тип субъекта
    } as MvkFilter;
  }
  /**
   * Описания полей
   */
  public getFilterDescription(): any {
    return {
      subjectType: 'Тип субъекта',
      name: 'Наименование',
      sname: 'Фамилия',
      gname: 'Имя',
      pname: 'Отчество',
      birthDate: 'Дата рождения',
      inn: 'ИНН',
      series: 'Серия документа',
      number: 'Номер документа',
      ogrn: 'ОГРН',
      idSubject: 'Идентификатор'
    };
  }
}
