import {User} from "@amlCore/models";
import {DossierFilter} from "@amlCore/models";
import {PartItemArray} from "../partItemArray.model";

export class AccessDossierTypeModel extends PartItemArray {
  id;
  user: User;
  reason;
  dateFrom;
  dateEnd;
  state;
  replacement;
  requestDocs?: DossierFilter | any;
  dossierIds?: Array<string>;
  active: boolean;
}
